import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
export default class PopMsg extends Component {
  render() {
    var w = window.innerWidth;

    return (
      <div className="pop">
        <div
          className={
            w > 768
              ? "popinnernormal oneFieldPop"
              : "popinnernormal threeFieldPopMobile"
          }
          style={w > 768 ? { padding: "2%" } : { padding: "4%" }}
        >
          <form autoComplete="off">
            <h5 style={{ color: "rgb(36 66 42)", textAlign: "center" }}>
              Are you sure you want to enter your Personal Info?{" "}
            </h5>
            <br />
            <Link to="/AdminProfileGrid" className="btn">
              Yes
            </Link>{" "}
            <Link href onClick={this.props.close} className="btn">
              No
            </Link>
          </form>
        </div>
      </div>
    );
  }
}
