import $ from "jquery";
import React, { Component } from "react";
import SideBar from "../Common/Sidebar";
import "../Css/Sign-Up.css";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../Config/Base";
import "react-toastify/dist/ReactToastify.css";

$(function () {
  $("#noSpace").on("keypress", function (e) {
    if (e.which === 32) {
      return false;
    }
  });
});

$(document).ready(function () {
  $("#reguser").hide();
});
export default class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      OPwd: "",
      NPwd: "",
      CPwd: "",
      formErrors: {},
      Profile_Pic: [],
      Notification: [],
      Eye: false,
      Eye1: false,
      Eye2: false,
      ButtonDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleFormValidation() {
    let formErrors = {};
    let formIsValid = true;
    var OPwd = document.getElementById("OPwd").value;
    var NPwd = document.getElementById("NPwd").value;
    var CPwd = document.getElementById("CPwd").value;
    if (!OPwd) {
      formIsValid = false;
      formErrors["OPwdErr"] = "Current Password is required";
    }
    if (!NPwd) {
      formIsValid = false;
      formErrors["NPwdErr"] = "New Password is required";
    } else if (OPwd === NPwd) {
      formIsValid = false;

      formErrors["NPwdErr"] = "New Password and Current Password is Same";
    } else if (!/^(?=.*[A-Z])/.test(NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one Capital letter required";
    } else if (!/^(?=.*[a-z])/.test(NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one small letter required";
    } else if (!/\d/.test(NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one number letter required";
    } else if (!/^(?=.*[-+_!@#$%^&*., ?])/.test(NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one Symbol letter required";
    } else if (NPwd.length < 8) {
      formIsValid = false;
      formErrors["NPwdErr"] = "A Minimum of  8  characters are required";
    }
    else if (NPwd.includes(" ")) {
      formIsValid = false;
      formErrors["NPwdErr"] = "Spaces are not allowed in the New Password";
    }
    if (!CPwd) {
      formIsValid = false;
      formErrors["CPwdErr"] = "Confirm New Password is required";
    } else if (CPwd !== NPwd) {
      formIsValid = false;
      formErrors["CPwdErr"] =
        "New Password and Confirm  Password doesn't match";
    }
    else if (CPwd.includes(" ")) {
      formIsValid = false;
      formErrors["CPwdErr"] = "Spaces are not allowed in the New Password";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.handleFormValidation()) {
      this.setState({ ButtonDisabled: true });
      const data = new FormData(event.target);
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      const url = `${BASE_URLs}/ChangePwd/ChangePwd`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };

      const response = await fetch(url, options);

      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          this.props.history.push("/Login_form");
        }, 4000);
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setTimeout(() => {
        this.setState({ ButtonDisabled: false });
      }, 4000);
    }
  };

  togglePassword = () => {
    this.setState({ Eye: !this.state.Eye });
  };

  togglePassword1 = () => {
    this.setState({ Eye1: !this.state.Eye1 });
  };

  togglePassword2 = () => {
    this.setState({ Eye2: !this.state.Eye2 });
  };

  handleChange = (e) => {
    let formErrors = {};
    let formIsValid = true;
    const { name, value } = e.target;
    this.setState({ [name]: value });
    //  const { OPwd, NPwd, CPwd } = this.state;
    var OPwd = document.getElementById("OPwd").value;
    var NPwd = document.getElementById("NPwd").value;
    var CPwd = document.getElementById("CPwd").value;
    if (!OPwd) {
      formIsValid = false;
      formErrors["OPwdErr"] = "Current Password is required";
    } else if (!NPwd) {
      formIsValid = false;
      formErrors["NPwdErr"] = "New Password is required";
    } else if (OPwd === NPwd) {
      formIsValid = false;

      formErrors["NPwdErr"] = "New Password and Current Password is Same";
    } else if (!/^(?=.*[A-Z])/.test(NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one Capital letter required";
    } else if (!/^(?=.*[a-z])/.test(NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one small letter required";
    } else if (!/\d/.test(NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one number letter required";
    }
    else if (NPwd.includes(" ")) {
      formIsValid = false;
      formErrors["NPwdErr"] = "Spaces are not allowed in the New Password";
    }
    
    else if (!/^(?=.*[-+_!@#$%^&*., ?])/.test(NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one Symbol letter required";
    }
    
    
    else if (NPwd.length < 8) {
      formIsValid = false;
      formErrors["NPwdErr"] = "A Minimum of 8  characters are required";
    } 
    else if (CPwd.includes(" ")) {
      formIsValid = false;
      formErrors["CPwdErr"] = "Spaces are not allowed in the Confirm Password";
    }
    
    else if (!CPwd) {
      formIsValid = false;
      formErrors["CPwdErr"] = "Confirm New Password is required";
    } else if (CPwd !== NPwd) {
      formIsValid = false;
      formErrors["CPwdErr"] = "New Password and Confirm  Password doesn't match";
    }
   
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  render() {
    const { OPwdErr, NPwdErr, CPwdErr } = this.state.formErrors;

    return (
      <>
        <SideBar active={window.location.pathname} />
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="glowBox">
                        <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          <h4 className="title">Change Password</h4>
                        </div>

                        <div className="card-block2">
                          <form onSubmit={this.handleSubmit}>
                            <div className="container">
                              <div className="row" id="skill-form">
                                <div className="col-lg-4">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <>
                                        <div style={{ display: "flex" }}>
                                          <input
                                            type={
                                              !this.state.Eye
                                                ? "password"
                                                : "text"
                                            }
                                            className="form"
                                            onChange={this.handleChange}
                                            name="OPwd"
                                            id="OPwd"
                                            maxLength="20"
                                            autoComplete="off"
                                          />
                                          <span
                                            className={
                                              !this.state.Eye
                                                ? "icofont icofont-eye eyelogo"
                                                : "icofont icofont-eye-blocked eyelogo"
                                            }
                                            id="eye"
                                            onClick={this.togglePassword}
                                          ></span>
                                          {/* eye-blocked */}
                                        </div>
                                        {!OPwdErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Current Password
                                          </span>
                                        ) : (
                                          <div
                                            style={{
                                              color: "red",
                                              float: "left",
                                            }}
                                          >
                                            {OPwdErr}
                                          </div>
                                        )}
                                        <span className="underline" />
                                      </>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <>
                                        <div style={{ display: "flex" }}>
                                          <input
                                            type={
                                              !this.state.Eye1
                                                ? "password"
                                                : "text"
                                            }
                                            className="form"
                                            onChange={this.handleChange}
                                            name="NPwd"
                                            id="NPwd"
                                            maxLength="20"
                                            autoComplete="off"
                                          />
                                          <span
                                            className={
                                              !this.state.Eye1
                                                ? "icofont icofont-eye eyelogo"
                                                : "icofont icofont-eye-blocked eyelogo"
                                            }
                                            id="eye"
                                            onClick={this.togglePassword1}
                                          ></span>
                                          {/* eye-blocked */}
                                        </div>
                                        <span
                                          className={
                                            NPwdErr ? " showError" : ""
                                          }
                                        ></span>
                                        {!NPwdErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            New Password
                                          </span>
                                        ) : (
                                          <div
                                            style={{
                                              color: "red",
                                              float: "left",
                                            }}
                                          >
                                            {NPwdErr}
                                          </div>
                                        )}
                                        <span className="underline" />
                                      </>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <>
                                        <div style={{ display: "flex" }}>
                                          <input
                                            type={
                                              !this.state.Eye2
                                                ? "password"
                                                : "text"
                                            }
                                            className="form"
                                            onChange={this.handleChange}
                                            id="CPwd"
                                            name="CPwd"
                                            maxLength="20"
                                            autoComplete="off"
                                          />
                                          <span
                                            className={
                                              !this.state.Eye2
                                                ? "icofont icofont-eye eyelogo"
                                                : "icofont icofont-eye-blocked eyelogo"
                                            }
                                            id="eye"
                                            onClick={this.togglePassword2}
                                          ></span>
                                          {/* eye-blocked */}
                                        </div>
                                        {!CPwdErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Confirm New Password
                                          </span>
                                        ) : (
                                          <div
                                            style={{
                                              color: "red",
                                              float: "left",
                                            }}
                                          >
                                            {CPwdErr}
                                          </div>
                                        )}
                                        <span className="underline" />
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-12">
                                      <div className="row  justify-content-center">
                                      <br /> <br />
                                  <button
                                    type="submit"
                                    className="but"
                                    style={{ width: "83.36px" }}
                                    disabled={this.state.ButtonDisabled}
                                  >
                                    Submit
                                  </button>
                                  <button
                                          type="reset"
                                          className=" btn-Secondary"
                                          style={{
                                            marginLeft: "1%",
                                            height: "39.5px",
                                          }}
                                          onClick={() =>
                                            this.setState({ formErrors: "" })
                                          }
                                        >
                                          Cancel
                                        </button>
                                
                                </div>
                              </div>
                            </div>
                          </form>
                          <br /> <br /> <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
