import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base";
import CloseIcon from "@material-ui/icons/Close";
export default class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Notify: [],
      AllNotify: [],
      Active: [],
      InActive: [],
      Pending: [],
      All: [],
      allNotify: [],
      User: [],
      renewel: [],
      Notification: [],
      Unreadcount: "0",
      ContactMessages: [],
      NoRequest: [],
      Request: [],
      ContactMessagesCount: "",
      NotifyCount: "",
      Filterpop: false,
      letters: [],
      delay: false,
    };
    this.Noticount = this.Noticount.bind(this);
  }

  GetallNotify() {
    fetch(`${BASE_URLs}/Notification/GetallNotify`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ allNotify: data });
      });
  }

  Noticount = (notify_Id, company_Id, contact_Id) => {
    fetch(`${BASE_URLs}/Notification/ChangeSeen`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Seen: 1,
        notify_Id: notify_Id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("new");
        },
        (error) => {}
      );

    this.GetNotification();
    if (company_Id !== null) {
      var page = {
        pathname: "/OrganizationDetail",
        state: {
          Id: company_Id,
        },
      };

      this.props.history.push(page);
    }
  };
  Contactcount = (notify_Id, contact_Id) => {
    fetch(`${BASE_URLs}/Notification/ChangeSeen`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Seen: 1,
        notify_Id: notify_Id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {},
        (error) => {}
      );

    if (contact_Id !== null) {
      var page = {
        pathname: "/Contactmsg",
        state: {
          Id: contact_Id,
        },
      };

      this.props.history.push(page);
    }
    this.GetNotification();
  };

  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    fetch(`${BASE_URLs}/AddOrganization/GetOrg`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          User: data,
        });
      });
  }

  renewel() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/Renewel`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ renewel: data });
      });
  }

  detailData(company_Id) {
    // localStorage.setItem("status","Active")
    var page = {
      pathname: "/OrganizationDetail",
      state: {
        Id: company_Id,
        Status: "Pending",
      },
    };

    this.props.history.push(page);
  }
  detailData1(company_Id) {
    localStorage.setItem("Id", company_Id);
    localStorage.setItem("status", "Inactive");
  }

  componentDidMount() {
    this.GetNotification();
    this.GetallNotify();
    setTimeout(() => {
      this.setState({
        delay: true
      })
    }, 500);
    
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  GetNotification() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Notification/GetNotification`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Notify: data.filter((Tasks) => Tasks.type !== "c") });
        this.setState({ AllNotify: data.filter((Tasks) => Tasks.type !== "c") });
        this.setState({
          NotifyCount: data
            .filter((Tasks) => Tasks.type !== "c")
            .filter((Tasks) => Tasks.seen === false).length,
        });

        this.setState({
          ContactMessages: data.filter((Tasks) => Tasks.type === "c"),
        });
        this.setState({
          ContactMessagesCount: data
            .filter((Tasks) => Tasks.type === "c")
            .filter((Tasks) => Tasks.seen === false).length,
        });

        if (data.length !== 0) {
          this.setState({ Unreadcount: data[0].count });
        }
      });
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  filterfunction = (e) => {
    const Notify = this.state.AllNotify.filter(
      (item) =>
      item.description?.toLowerCase().includes(e.target.value.toLowerCase()) 
    );
    this.setState({ Notify, showResults: true });

  }
  handleFilter = (letter, event) => {
    event.preventDefault();
    const  Notify = this.state.AllNotify.filter((item) => {
    return (
      item.description?.toLowerCase().startsWith(letter.toLowerCase())
    );
  });
    this.setState({ Notify, showResults: true });
   
  };

  render() {
    const {
      Notify,
      NoRequest,
      Request,
      ContactMessages,
      NotifyCount,
      ContactMessagesCount,
      Filterpop,delay
    } = this.state;

    return (
      <>
        <SideBar active={window.location.pathname} />
        {/* start */}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                        <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          <h4 className="title" style={{marginRight:'45px'}}>Notifications </h4>
                        </div>

                        {/* <div className="card-block" >
               
                      {Notify.map(Notify=>
                      <div>
                         {(() => { 
                           if ( Notify.seen === false) {
                             
                             return(
                              <div>
                              <a href  type="button"    
                            onClick={()=>this.Noticount(Notify.notify_Id,Notify.company_Id)}
                              style={{color:"black",cursor:"pointer"} }>
                              <b style={{color:"red"}}>{Notify.description}</b> &nbsp;&nbsp;&nbsp;{Notify.time}  
                                </a>   <br/> <br/>
                           </div>  )
                          }

                          if(Notify.seen === true){
                            return(
                        <div>
                           <a href type="button"   
                         onClick={()=>this.detailData(Notify.company_Id)}
                           style={{color:"#807f7f",cursor:"pointer"} }>
                           <b>{Notify.description}</b> &nbsp;&nbsp;&nbsp;{Notify.time}  
                             </a>   <br/> <br/>
                        </div>
                            )
                          }
                        })()}


                        </div>)}
                      
                 </div> */}
                         <div className="card-block">
                        <div className="j-tabs-container md-tabs">
                          <input
                            id="tab1"
                            type="radio"
                            name="tabs"
                            defaultChecked
                          />
                          <label
                            id="supertab"
                            className="j-tabs-label"
                            htmlFor="tab1"
                            title="Info"
                            style={{marginLeft:'23px'}}
                          >
                            {/* {this.state.Notify.length === 0 ? (
                              <span>
                                &nbsp;&nbsp;No Notifications&nbsp;&nbsp;
                              </span>
                            ) : ( */}
                              <span>
                                &nbsp;&nbsp;Notifications
                                {NotifyCount === 0
                                  ? null
                                  : " (" + NotifyCount + ")"}
                                &nbsp;&nbsp;
                              </span>
                            {/* )} */}
                          </label>
                          <input
                          type="text"
                          className="Searchbox"
                          id="CNSearch"
                          placeholder="Search"
                          onClick={() => {
                            this.setState({
                            Filterpop: true,
                            Filterpop: !this.state.Filterpop,
                            });
                            }}
                            readOnly
                        
                          style={{ float: 'right'}}

                        />
                          <div id="tabs-section-1" className="j-tabs-section">
                            <div
                              className="tab-pane"
                              id="notes"
                              role="tabpanel"
                            >
                              <div className="card-block">
                              <div className="dt-responsive table-responsive"
                               id={this.state.Notify.length <= 6 ? "NoAnnLength" : "Above10"}
                              >
                              {this.state.Notify.length===0 && delay  ? 
                                <center>
                             
                            <p>No Record</p>
                          </center> 
                                          : null}
                              {this.state.Notify.length!==0 && delay  ? <table
                              id="add-row-table3"   className="table nowrap">
                                <thead id='tablepadding'><tr>
                                 <th style={{color:'#7367f0'}}> Notification Details</th>
                                
                                 </tr>
                                 </thead>
                                 <tbody id='Notitablepadding'>
                                 <tr> <td>
                                 {Notify.map((Notify) => (
                                      <>
                                        {Notify.seen === false ? (
                                          // Unreaded noti
                                          <>
                                            {Notify.type === "o" ? (
                                              <>
                                                <Link
                                                  to="/OrganizationDetail"
                                                  id="btnSkill"
                                                  onClick={() =>
                                                    this.Noticount(
                                                      Notify.notify_Id,
                                                      Notify.company_Id
                                                    )
                                                  }
                                                  style={{
                                                    padding: "0px",
                                                    color: "#000100",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {" "}
                                                  <span className="hoverable-span">
                                                    {Notify.description}{" "}
                                                    </span>{" "}
                                                </Link>
                                                <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                  {Notify.time}
                                                </span>
                                                &nbsp;&nbsp;  <hr id="NotiHR"></hr>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          // Readed noti
                                          <>
                                            {Notify.type === "o" ? (
                                              <>
                                                <Link
                                                  to="/OrganizationDetail"
                                                  onClick={() =>
                                                    this.Noticount(
                                                      Notify.notify_Id,
                                                      Notify.company_Id
                                                    )
                                                  }
                                                  style={{
                                                    padding: "0px",
                                                    color: "gray",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {" "}
                                                  <span className="hoverable-span">
                                                    {Notify.description}{" "}
                                                  </span>{" "}
                                                </Link>
                                                <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                  {Notify.time}
                                                </span>
                                                <hr id="NotiHR"></hr>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ))}

                                 </td></tr>
                                 </tbody>


                                    </table>:null}

                              </div>
                                {/* <ul
                                  className="show-notification"
                                  style={{
                                    height: "300px",
                                    overflow: "scroll",
                                  }}
                                >
                                  <li>
                                    {Notify.map((Notify) => (
                                      <>
                                        {Notify.seen === false ? (
                                          // Unreaded noti
                                          <>
                                            {Notify.type === "o" ? (
                                              <>
                                                <Link
                                                  to="/OrganizationDetail"
                                                  id="btnSkill"
                                                  onClick={() =>
                                                    this.Noticount(
                                                      Notify.notify_Id,
                                                      Notify.company_Id
                                                    )
                                                  }
                                                  style={{
                                                    padding: "0px",
                                                    color: "#000100",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {" "}
                                                  <b>
                                                    {Notify.description}{" "}
                                                  </b>{" "}
                                                </Link>
                                                <span className="notification-time">
                                                  {Notify.time}
                                                </span>
                                                &nbsp;&nbsp; <br />
                                                <br />
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          // Readed noti
                                          <>
                                            {Notify.type === "o" ? (
                                              <>
                                                <Link
                                                  to="/OrganizationDetail"
                                                  onClick={() =>
                                                    this.Noticount(
                                                      Notify.notify_Id,
                                                      Notify.company_Id
                                                    )
                                                  }
                                                  style={{
                                                    padding: "0px",
                                                    color: "gray",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {" "}
                                                  <b>
                                                    {Notify.description}{" "}
                                                  </b>{" "}
                                                </Link>
                                                <span className="notification-time">
                                                  {Notify.time}
                                                </span>
                                                <br />
                                                <br />
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ))}
                                  </li>
                                </ul> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {Filterpop && (
                              <div className="popSearchTeam">
                                <div className="popSearchinnernormalTeam">
                                  <div
                                    className="popSearchinnertitle"
                                    id="popSearchinnernormal"
                                  >
                                    <button
                                      id="close"
                                      style={{
                                        float: "right",
                                        backgroundColor: "#a0a3a305",
                                        color: "#797070",
                                        border: "1px solid #a3a5a505",
                                        marginTop: "-20px",
                                        marginRight: "-20px",
                                      }}
                                      onClick={() => {
                                        this.setState({ Filterpop: false });
                                         this.GetNotification();
                                      }}
                                    >
                                      <CloseIcon />
                                    </button>

                                    <div className="checkboxes">
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "20px",
                                          color: "gray",
                                          marginLeft: "-1%",
                                          position: "absolute",
                                          marginTop: "0.5%",
                                        }}
                                      ></i>
                                      <input
                                        className="Searchbox"
                                        placeholder="Search"
                                        onChange={this.filterfunction}
                                        style={{ float: "right", width: "80%" }}
                                      />
                                      <br></br>
                                      {this.state.letters.map(
                                        (letter, index) => (
                                          <button
                                            className="filter"
                                            key={index}
                                            onClick={(event) =>
                                              this.handleFilter(
                                                letter,
                                                event
                                              )
                                            }
                                          >
                                            {letter}
                                          </button>
                                        )
                                      )}
                                      <br></br>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
              </div>
            </div>
          </div>
        </div>

        {/* End */}
      </>
    );
  }
}
