import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class EditFamilyModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RelationshipDrop: [],
      formErrors: {},
      isChrome: false,
    };
  }

  EmergencySubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    if (this.Validation()) {
      const url = `${BASE_URLs}/Family/EditUserEmergecny`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };

      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          document.getElementById("close").click();
        }, 4000);
      }
    }
  };
  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;

    var Firstname = document.getElementById("Firstname").value;
    var Lastname = document.getElementById("Lastname").value;
    var EmeContact = document.getElementById("EmeContact").value;
    var Email = document.getElementById("Email").value; /**/
    var Relationship = document.getElementById("Relationship").value;

    if (!Firstname) {
      formIsValid = false;
      formErrors["Firstname"] = "Firstname  is required";
    }
    if (!Lastname) {
      formIsValid = false;
      formErrors["Lastname"] = "Lastname  is required";
    }
    if (!EmeContact) {
      formIsValid = false;
      formErrors["EmeContact"] = "Contact  is required";
    } else if (!mobPattern.test(EmeContact)) {
      formIsValid = false;
      formErrors["EmeContact"] = "Enter valid Contact!.";
    }
    if (!Email) {
      formIsValid = false;
      formErrors["Email"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["Email"] = "Enter valid Email!.";
    }
    if (!Relationship) {
      formIsValid = false;
      formErrors["Relationship"] = "Relationship is required";
    }

    this.setState({ formErrors: formErrors });

    //document.getElementById("add-row").style.display = "none";
    return formIsValid;
  }

  RelationshipDropList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Family/RelationshipDropdown`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ RelationshipDrop: data });
      });
  }
  componentDidMount() {
    this.RelationshipDropList();
    const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    this.setState({ isChrome });
  }
  render() {
    const { Firstname, Lastname, EmeContact, Email, Relationship } =
      this.state.formErrors;
    const { RelationshipDrop } = this.state;
    const { isChrome } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div className="popinnernormal">
            <h1 className="popinnertitle">Edit Emergency Contact</h1>
            <form id="Emergencyform" onSubmit={this.EmergencySubmit.bind(this)}>
              <div id="add-rowEmergency">
                <div>
                  {" "}
                  <table className="table  table-striped table-bordered nowrap">
                    <tr>
                      <td>
                        <input type="hidden" name="Id" value={this.props.id} />
                        <input
                          className="form"
                          name="FirstName"
                          type="text"
                          id="Firstname"
                          defaultValue={this.props.value.FirstName}
                          /* onChange={this.handleChange} */ placeholder="First Name"
                        />
                        {Firstname == null ? (
                          <span className="underline">Firstname</span>
                        ) : (
                          <div style={{ color: "red" }}>{Firstname}</div>
                        )}
                      </td>

                      <td>
                        <input
                          className="form"
                          name="LastName"
                          type="text"
                          id="Lastname"
                          defaultValue={this.props.value.LastName}
                          /* onChange={this.handleChange} */ placeholder="Last Name"
                        />
                        {Lastname == null ? (
                          <span className="underline">Lastname</span>
                        ) : (
                          <div style={{ color: "red" }}>{Lastname}</div>
                        )}
                      </td>
                      <td>
                        <input
                          className="form"
                          name="Contact"
                          type="text"
                          id="EmeContact"
                          defaultValue={this.props.value.Contact}
                          /* onChange={this.handleChange} */ placeholder="Contact"
                          onKeyPress={(event) => {
                            const isLetter = /[a-zA-Z]/i.test(event.key);
                            if (isLetter) {
                              event.preventDefault();
                            }
                          }}
                          onInputCapture={(event) => {
                            if (!/^[0-9]*$/.test(event.target.value)) {
                              event.target.value = event.target.value.replace(/[^0-9]/g, '');
                            }
                          }}
                        />
                        {EmeContact == null ? (
                          <span className="underline">Contact</span>
                        ) : (
                          <div style={{ color: "red" }}>{EmeContact}</div>
                        )}
                      </td>

                      <td>
                        <input
                          className="form"
                          name="Email"
                          type="text"
                          id="Email"
                          defaultValue={this.props.value.Email}
                          /* onChange={this.handleChange} */ placeholder="Email"
                        />
                        {Email == null ? (
                          <span className="underline">Email</span>
                        ) : (
                          <div style={{ color: "red" }}>{Email}</div>
                        )}
                      </td>

                      <td>
                        <select
                          className="form"
                          name="Relationship"
                          id="Relationship"
                          Value={this.props.value.Relationship}
                          
                          /*  onChange={this.handleChange} */ type="text"
                          placeholder="City"
                          style={{
                            color: "darkgray",
                            ...(isChrome && { marginLeft: '-3px' })
                          }}
                        >
                          <option value=""> Relationship</option>{" "}
                          {RelationshipDrop.map((RelationshipDrop) => (
                            <option Value={RelationshipDrop.relationship}>
                              {RelationshipDrop.relationship}
                            </option>
                          ))}
                        </select>

                        {Relationship == null ? (
                          <span className="underline">Relationship</span>
                        ) : (
                          <div style={{ color: "red" }}>{Relationship}</div>
                        )}
                      </td>
                    </tr>
                  </table>
                  <button
                    type="submit"
                    id="saverow3"
                    className=" btn-Secondary"
                    style={{ height: "40.4px", width: "83.36px" }}
                  >
                    Update
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    id="close"
                    style={{ height: "40.4px", width: "83.36px" }}
                    onClick={this.props.close}
                  >
                    Cancel{" "}
                  </button>
                </div>
              </div>
            </form>
            {/*       <button className="popupok" onClick={this.props.close} >Yes</button>
             */}{" "}
          </div>
        </div>
      </>
    );
  }
}
