import React, { Component } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import SideBar from "../../Common/Sidebar";
import $ from "jquery";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddCoursePop from "../../Common/AddCoursePop";
import EditCourse from "../../Common/EditCoursePop";
import CourseNameExl from "../../Assets/Files/CourseNameExl.xlsx";
import CloseIcon from "@material-ui/icons/Close";
import "../../Css/excel.css";
import { BASE_URLs } from "../../Config/Base";

var XLSX = require("xlsx");
$(document).ready(function () {
  $("#add-Educat").hide();
});
$(document).ready(function () {
  /*   $("#saverow2").click(function(){
      $("#add-row2").hide();
    }); */
  $("#Btn_edu").click(function () {
    $("#add-Educat").show();
  });
});
export default class SACourse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      AddCoursePop: [],
      AddCoursePopModel: false,
      EditCourseModel: false,
      Id: "",
      currentSheet: {},
      Category: "",
      formErrors: {},
      allNotify: [],
      Notification: [],
      CourseName: [],
      EditCourse: [],
      rows: [],
      cols: [],
      letters: [],
      Filterpop: false,
      FilterData: "",
      showExcel: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.state;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.Edit = this.Edit.bind(this);
  }
  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("skillsheet");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    workbook.Sheets.Sheet1.A1.s = { font: { bold: true } };
    // Process Data (add a new row)
    var ws = workbook.Sheets["Sheet1"];
    //XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }
  handleFormValidation() {
    //const {SkillName,SkillDescription,Category}= this.state;
    var SkillName = document.getElementById("SkillName").value;
    var SkillDescription = document.getElementById("SkillDescription").value;
    var Category = document.getElementById("Category").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "SkillName is required";
    }
    if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "SkillDescription is required";
    }
    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}/SuperAdmin/SACourseExcel`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert("0  Records  inserted ");
          }
          if (resp === 0) {
            alert("Records added Successfully");
          } else {
            alert(+resp + " Records not inserted ");
          }
          this.GetCourseName();
          this.setState({ initialData: undefined });
          this.setState({ currentSheet: undefined });
        });
      var file = document.getElementById("files");
      var emptyFile = document.createElement("input");
      emptyFile.type = "file";
      file.files = emptyFile.files;
    } catch (error) {}

    this.GetCourseName();
  };

  GetCourseName() {
    let url = `${BASE_URLs}/Course/GetCourseName`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          CourseName: data,
          Course: data,
        });
        //
      });
  }

  Edit(Id, category, skillName, skillDescription) {
    var page = {
      pathname: "/EditMasterSkill",
      state: {
        Id: Id,
        category: category,
        skillName: skillName,
        skillDescription: skillDescription,
      },
    };
    this.props.history.push(page);
  }

  deleteSkill(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}/Skill/DeleteSuperEMPS?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          toast.success(resp, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.GetCourseName();
          document.getElementById("Eduform").reset();
        });
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    var SkillName = document.getElementById("SkillName").value;
    var SkillDescription = document.getElementById("SkillDescription").value;
    var Category = document.getElementById("Category").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    } else if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "SkillName is required";
    } else if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "SkillDescription is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  componentDidMount() {
    this.GetCourseName();

    $(document).ready(function () {
      $("#add-Educat").hide();
    });
    $(document).ready(function () {
      /*   $("#saverow2").click(function(){
              $("#add-row2").hide();
            }); */
      $("#Btn_edu").click(function () {
        $("#add-Educat").show();
      });
    });
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  onKeyUp = (e) => {
    const CourseName = this.state.Course.filter((item) =>
      item.courseName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ CourseName });
  };
  handleCourseFilter = (letter, event) => {
    event.preventDefault();
    const CourseName = this.state.Course.filter((item) => {
      return item.courseName.toLowerCase().startsWith(letter.toLowerCase());
    });
    this.setState({ CourseName, showResults: true, FilterData: CourseName });
  };
  checkAll(e) {
    var checkboxes = document.getElementsByName("deletecourse");

    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;
      if (document.getElementById("selectall").checked) {
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      } else {
        msg.innerHTML =
          checkboxes.length === 1
            ? "<span> " + checkboxes.length + " Record is selected </span> "
            : "<span> " + checkboxes.length + " Records are selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }

  AddCoursePopModel = () => {
    this.setState((prevState) => ({
      AddCoursePopModel: !prevState.AddCoursePopModel,
    }));
    this.GetCourseName();
  };

  EditCourseModel = () => {
    this.setState((prevState) => ({
      EditCourseModel: !prevState.EditCourseModel,
    }));
    this.GetCourseName();
  };

  handleUpload = (event) => {
    this.setState({ showExcel: true });

    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document.getElementById("delete").setAttribute("style", "visibility: hidden;");
        document.getElementById("deletecount").setAttribute("style", "visibility: hidden;");
      }
    }

    // Get the uploaded file
    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx|\.xlsm)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);

    // Check if the file is allowed
    if (isAllowed) {
      // Read the file using FileReader
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        // Check if the parsed data is empty
        if (parsedData.length === 0) {
          toast.error("The uploaded Excel file is empty.",{ autoClose: 3200 });
          this.setState({ initialData: [], showExcel: false });
        } else {
          this.setState({ initialData: parsedData });
        }
      };
      reader.onerror = (error) => {
        // console.error("Error reading file:", error);
      };
      reader.readAsBinaryString(files[0]);

      return true; // Indicate successful file upload
    } else {
      alert("Invalid File Type.");
      return false; // Indicate failed file upload
    }
  };

  Multidelete = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    var options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    fetch(`${BASE_URLs}/Course/Multidelete`, options)
      .then((response) => response.json())
      .then((data) => {
        this.GetCourseName();
        toast.success(data.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
        var checkboxes = document.querySelectorAll(
          'input[type="checkbox"]:checked'
        );

        for (var i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false;
        }
      });
    this.GetCourseName();
  };

  EditCourseModel = (Id, CourseName) => {
    this.setState({
      EditCourse: {
        id: Id,
        CourseName: CourseName,
      },
    });

    this.setState((prevState) => ({
      EditCourseModel: !prevState.EditCourseModel,
    }));

    // this.Getcategory();
  };
  cancel = () => {

    var inputElement = document.getElementById("files");
    inputElement.value = "";

    this.setState({ showExcel: false });

  };
  render() {
    var username = JSON.parse(localStorage.uname);
    var filename = `O_${username}_CourseName`;
    const { CourseName, Filterpop,FilterData,showExcel } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.pathname} />
        {this.state.AddCoursePopModel ? (
          <AddCoursePop id="Success" close={() => this.AddCoursePopModel()} />
        ) : null}
        {this.state.EditCourseModel ? (
          <EditCourse
            values={this.state.EditCourse}
            close={() => this.EditCourseModel("", "")}
          />
        ) : null}
        {/* <EditCourse/>             */}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          </div>
                          <h4 className="title" style={{ marginRight: "50px" }}>
                            {" "}
                            Coursename{" "}
                          </h4>
                        </div>

                        <div className="add-rowEduform">
                          <form
                            autoComplete="on"
                            id="Eduform"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="card-block">
                              <div className="row">
                                {/* <div className="col-lg-12"> */}
                                {/* <div className="row"> */}
                                <div className="col btnalign">
                                  <button
                                    onClick={() => this.AddCoursePopModel()}
                                    onKeyPress={this.handleKeyPress}
                                    type="button"
                                    style={{
                                      width: "150px",
                                      background: "white",
                                    }}
                                    id="addRow"
                                    className=" btn dropdown_item-2"
                                    // className="btn "
                                  >
                                    Add{" "}
                                  </button>
                                </div>
                                <div className="col btnalign">
                                  <label
                                    htmlFor="files"
                                    className="btn"
                                    id="addBtn"
                                    name="Courses"
                                  >
                                    Import From Excel
                                  </label>
                                  <input
                                    id="files"
                                    type="file"
                                    accept=".xlsx"
                                    style={{ display: "none" }}
                                    onChange={this.handleUpload}
                                    name="Courses"
                                  />
                                </div>
                                <div className="col btnalign">
                                  <button
                                    className="btn"
                                    type="button"
                                    onClick={() => this.exportXlsx(filename)}
                                    onKeyPress={this.handleKeyPress}
                                  >
                                    Export to excel
                                  </button>

                                  {/* <ReactHTMLTableToExcel                  
           className="btn"  
           table="skillsheet"  
           filename={filename}
           sheet="Sheet"  
           buttonText="Export to Excel " id="exportxlUG" /> */}
                                </div>
                                {/* <div className="col-lg-2 btnalign"></div> */}

                                <div
                                  className="col btnalign"
                                  onKeyDown={this.handleKeyPress}
                                >
                                  <a
                                    className="btn"
                                    href={CourseNameExl}
                                    type="button"
                                    style={{ color: "#5035ce" }}
                                  >
                                    <i
                                      title="Download Template"
                                      className="icofont icofont-download"
                                    ></i>{" "}
                                    Download
                                  </a>
                                </div>

                                {/* </div>  */}
                                
                                <div className="col-lg-4">
                                  <input
                                    className="Searchbox"
                                    placeholder="Search"
                                    onKeyUp={this.onKeyUp}
                                    style={{ float: "right" }}
                                    onClick={() => {
                                      this.setState({
                                        // Filterpop: true,
                                        Filterpop: !this.state.Filterpop,
                                      });
                                    }}
                                    readOnly
                                  />
                                </div>
                                {/* </div>         */}
                              </div>
                              <center>
                              {showExcel && this.state.initialData !== undefined && (
  <table className="table" id="tableDesign" style={{marginTop:'30px'}}>
    <thead>
      <tr>
        {Object.keys(this.state.initialData[0]).map((key) => (
          <th key={key}>{key}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {this.state.initialData.map((row, index) => (
        <tr key={index}>
          {Object.values(row).map((value, index) => (
            <td key={index} id="pdesign">{value}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
) }
</center>
                            {showExcel && this.state.initialData !== undefined && (
                                <div className="savebtndiv">
                                  <button
                                    className="btn"
                                    id=""
                                    type="submit"
                                    style={{ width: "150px" }}
                                  >
                                    Save
                                  </button>
                                  <span
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px" }}
                                  onClick={this.cancel}
                                >
                                  Cancel
                                </span>
                            </div>
                              ) }

                              <OutTable
                                data={this.state.rows}
                                columns={this.state.cols}
                                tableClassName="ExcelTable2007"
                                tableHeaderRowclassName="heading"
                              />
                            </div>
                          </form>
                        </div>
                        <br />

                        <div>
                          <form
                            onSubmit={this.Multidelete}
                            onKeyPress={this.handleKeyPress}
                          >
                            <div className="card-block2">
                              <div
                                id="selectalldiv"
                                style={{ visibility: "hidden" }}
                              />
                              &nbsp;&nbsp;
                            </div>
                            <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: "hidden" }}
                            >
                              <i
                                className="icofont icofont-delete-alt"
                                style={{ color: "darkgray" }}
                              ></i>
                            </button>
                            <span
                              id="deletecount"
                              className="multitrash"
                              style={{ visibility: "hidden", fontSize: "17px" }}
                            ></span>
                            <div className="dt-responsive table-responsive">
                              <table
                                id="skillsheet"
                                className="table nowrap"
                                style={{ display: "none" }}
                              >
                                <thead>
                                  <tr>
                                    <th>Coursename</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {CourseName.map((CourseName) => (
                                    <tr>
                                      <td>{CourseName.courseName}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div className="row">
                                <div className="col-lg-1"></div>
                                <div className="col-lg-10 skillname">
                                  {/* <p>Coursename</p> */}
                                </div>
                                <div className="col-lg-1"></div>
                              </div>
                              <br />
                              <div className="row" style={{ padding: "15px" }}>
                                {CourseName.map((CourseName) => (
                                  <div className="col-lg-3">
                                    <p>{CourseName.courseName}</p>
                                  </div>
                                ))}
                              </div>
                              <center>
                          {Filterpop === true && FilterData.length === 0 && CourseName.length === 0 ? (
                                       <div>No Record</div>
                                    ) : null}
                                    </center>

                              <br />
                            </div>{" "}
                          </form>
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {Filterpop && (
                                  <div className="popSearchTeam">
                                    <div className="popSearchinnernormalTeam">
                                      <div
                                        className="popSearchinnertitle"
                                        id="popSearchinnernormal"
                                      >
                                        <button
                                          id="close"
                                          style={{
                                            float: "right",
                                            backgroundColor: "#a0a3a305",
                                            color: "#797070",
                                            border: "1px solid #a3a5a505",
                                            marginTop: "-20px",
                                            marginRight: "-20px",
                                          }}
                                          onClick={() => {
                                            this.setState({ Filterpop: false });
                                            this.GetCourseName();
                                          }}
                                        >
                                          <CloseIcon />
                                        </button>

                                        <div className="checkboxes">
                                          <i
                                            className="fa fa-search"
                                            aria-hidden="true"
                                            style={{
                                              fontSize: "20px",
                                              color: "gray",
                                              marginLeft: "-1%",
                                              position: "absolute",
                                              marginTop: "0.5%",
                                            }}
                                          ></i>
                                          <input
                                            className="Searchbox"
                                            placeholder="Search"
                                            onKeyUp={this.onKeyUp}
                                            style={{
                                              float: "right",
                                              width: "80%",
                                            }}
                                          />
                                          <br></br>
                                          {this.state.letters.map(
                                            (letter, index) => (
                                              <button
                                                className="filter"
                                                key={index}
                                                onClick={(event) =>
                                                  this.handleCourseFilter(
                                                    letter,
                                                    event
                                                  )
                                                }
                                              >
                                                {letter}
                                              </button>
                                            )
                                          )}
                                          <br></br>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
            </div>
          </div>
        </div>

        {/*  */}
      </>
    );
  }
}
