import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
import { Image_URLs } from "../Config/Base";
export default class EditDocumentModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      ButtonDisabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.Validation()) {
      this.setState({
        ButtonDisabled: true,
      });
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}/AdminOfficeDocuments/EditDocument`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
              ButtonDisabled: true,
            });
            document.getElementById("EditPop").style.display = "none";
            setTimeout(function () {
              document.getElementById("close").click();
            }, 4000);
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("Editpop").style.display = "none";
            setTimeout(function () {
              document.getElementById("close").click();
            }, 2000);
          }
        });
    }
  }


  Validation() {
    let formErrors = {};
    let formIsValid = true;
    // var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    var OfficeDocumentTitle = document.getElementById(
      "OfficeDocumentTitle"
    ).value;
    var OfficeDocumentFile =
      document.getElementById("OfficeDocumentFile").value;
    var Category = document.getElementById("CategoryA").value;
    var OtherCategory = document.getElementById("ugtext").value;
    if (!OfficeDocumentTitle) {
      formIsValid = false;
      formErrors["OfficeDocumentTitleerr"] = "Document Title is required";
    }
    if (!OfficeDocumentFile) {
      formIsValid = false;
      formErrors["OfficeDocumentFileErr"] = "Document File is required";
    }
    if (!Category) {
      formIsValid = false;
      formErrors["Categoryerr"] = "Category is required";
    }
    if (Category === "others") {
      if (!OtherCategory || OtherCategory.trim() == "") {
        formIsValid = false;
        formErrors["OtherCategoryerr"] = "Category is required";
      }
    }

    this.setState({ formErrors: formErrors });

    //document.getElementById("add-row").style.display = "none";
    return formIsValid;
  }

  HandleUg() {
    var UG = document.getElementById("CategoryA").value;

    if (UG === "others") {
      document.getElementById("ugtext").value = "";
      document.getElementById("ugtext").type = "text";
    } else {
      document.getElementById("ugtext").value = "None";
      document.getElementById("ugtext").type = "hidden";
    }
  }

  render() {
    var w = window.innerWidth;

    const {
      OtherCategoryerr,
      OfficeDocumentTitleerr,
      OfficeDocumentFileErr,
      Categoryerr,
    } = this.state.formErrors;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal threeFieldPop"
                : "popinnernormal threeFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="EditPop"
          >
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>
            <br />
            <br />
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <div className="row" style={{ padding: "8px" }}>
                <div className="col-lg-4">
                  <input
                    type="hidden"
                    name="Id"
                    defaultValue={this.props.values.id}
                  />
                  <select
                    className="form"
                    id="CategoryA"
                    name="Category"
                    defaultValue={this.props.values.category}
                    onInputCapture={this.HandleUg}
                    placeholder="Location Type"
                    style={{ color: "#868b8f" }}
                  >
                    <option value={this.props.values.category} hidden>{this.props.values.category}</option>
                    <option value="">Select Category</option>
                    <option value="HR">HR</option>
                    <option value="others">others</option>
                  </select>{" "}
                  {Categoryerr == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Category
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Categoryerr}
                    </div>
                  )}
                  <input
                    maxLength="50"
                    type="hidden"
                    className="form"
                    id="ugtext"
                    name="OtherCategory"
                  />{" "}
                  {OtherCategoryerr == null ? null : (
                    <div style={{ color: "red", float: "left" }}>
                      {OtherCategoryerr}
                    </div>
                  )}
                  <span className="underline" />
                </div>

                <div className="col-lg-4">
                  <input
                    type="hidden"
                    name="Id"
                    defaultValue={this.props.values.id}
                  />
                  <input
                    className="form"
                    maxLength="50"
                    defaultValue={this.props.values.officeDocumentTitle}
                    id="OfficeDocumentTitle"
                    name="OfficeDocumentTitle"
                    type="text"
                  />
                  {OfficeDocumentTitleerr == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Document Title{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {OfficeDocumentTitleerr}
                    </div>
                  )}{" "}
                  <span className="underline" />
                </div>

                <div className="col-lg-4">
                  <input
                    type="hidden"
                    name="Id"
                    defaultValue={this.props.values.id}
                  />
                  <input
                    type="hidden"
                    name="OldOfficeDocumentFile"
                    defaultValue={this.props.values.officeDocument.replace(
                      `${Image_URLs}/Images/OfficeDocument_`,
                      ""
                    )}
                  />
                  <input
                    className="form"
                    onFocus={(e) => (e.target.type = "file")}
                    defaultValue={this.props.values.officeDocument.replace(
                      `${Image_URLs}/Images/OfficeDocument_`,
                      ""
                    )}
                    maxLength="25"
                    id="OfficeDocumentFile"
                    name="OfficeDocumentFile"
                    multiple
                    type="text"
                  />
                  {OfficeDocumentFileErr == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Document File{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {OfficeDocumentFileErr}
                    </div>
                  )}{" "}
                  <span className="underline" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    disabled={this.state.ButtonDisabled}
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
