import React, { Component } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base";
export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      skills: [],
      Id: "",
      SkillName: "",
      SkillDescription: "",
      Category: "",
      formErrors: {},
      Notification: [],
      Pending: [],
      allNotify: [],
      edus: [],
      PG: [],
      UG: [],
      PHD: [],
      UGbtn: true,
      PGbtn: false,
      PHDbtn: false,
    };
    this.Multidelete = this.Multidelete.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.state;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.Edit = this.Edit.bind(this);
  }

  componentWillMount() {
    $(document).ready(function () {
      $("#add-SAEducat").hide();
    });
    $(document).ready(function () {
      $("#Btn_SAEdu").click(function () {
        $("#add-SAEducat").show();
      });
    });

    $(document).ready(function () {
      $("#myInput").click(function () {
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      });
    });

    $(document).ready(function () {
      $("#myInput1").click(function () {
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      });
    });

    $(document).ready(function () {
      $("#myInput2").click(function () {
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      });
    });
  }

  handleFormValidation() {
    var Degree = document.getElementById("ug").value;
    var CourseName = document.getElementById("CourseName").value;
    var Specialization = document.getElementById("Specialization").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!Degree) {
      formIsValid = false;
      formErrors["UGErr"] = "Degree is required";
    }
    if (!CourseName) {
      formIsValid = false;
      formErrors["PGErr"] = "Course Name is required";
    }

    if (!Specialization) {
      formIsValid = false;
      formErrors["SpecializationErr"] = "Specialization is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.handleFormValidation()) {
      const data = new FormData(event.target);
      let user = JSON.parse(localStorage.getItem("user"));

      const accessToken = user;
      const url = `${BASE_URLs}/Education/MasterUG`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };
      this.setState(this.initialState);
      const response = await fetch(url, options);
      var result = await response.json();
      if (result.status === "Success") {
        alert(result.message);
      }

      document.getElementById("SAEduform").reset();
      document.getElementById("add-SAEducat").style.display = "none";
    }
    this.GetMasterUG();
    this.GetMasterPG();
    this.GetMasterPHD();
  };

  GetMasterUG() {
    let url = `${BASE_URLs}/Education/GetMasterUG`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          edus: data,
          UG: data,
        });
      });
  }
  MasterUGFilter = (e) => {
    const edus = this.state.UG.filter(
      (item) =>
        item.courseName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.specialization.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ edus });
  };

  GetMasterPG() {
    let url = `${BASE_URLs}/Education/GetMasterPG`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          PG: data,
          EduPG: data,
        });
      });
  }

  MasterPGFilter = (e) => {
    const PG = this.state.EduPG.filter(
      (item) =>
        item.courseName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.specialization.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ PG });
  };

  GetMasterPHD() {
    let url = `${BASE_URLs}/Education/GetMasterPHD`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          PHD: data,
          EduPHD: data,
        });
      });
  }

  MasterPHDFilter = (e) => {
    const PHD = this.state.EduPHD.filter(
      (item) =>
        item.courseName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.specialization.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ PHD });
  };

  EditUG(Id, SName, desc) {
    var page = {
      pathname: "/EditMasterUG",
      state: {
        Id: Id,
        SName: SName,
        desc: desc,
      },
    };
    this.props.history.push(page);
  }

  EditPG(Id, SName, desc) {
    var page = {
      pathname: "/EditMasterPG",
      state: {
        Id: Id,
        SName: SName,
        desc: desc,
      },
    };
    this.props.history.push(page);
  }

  EditPHD(Id, SName, desc) {
    var page = {
      pathname: "/EditMasterphd",
      state: {
        Id: Id,
        SName: SName,
        desc: desc,
      },
    };
    this.props.history.push(page);
  }

  DeleteMasterUG(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}/Education/DeleteMasterUG?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
          document.getElementById("SAEduform").reset();
        });
      });
      this.GetMasterUG();
      this.GetMasterPG();
      this.GetMasterPHD();
    }
  }

  DeleteMasterPG(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}/Education/DeleteMasterPG?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
          document.getElementById("SAEduform").reset();
        });
      });
      this.GetMasterUG();
      this.GetMasterPG();
      this.GetMasterPHD();
    }
  }

  DeleteMasterPHD(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}/Education/DeleteMasterPHD?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
          document.getElementById("SAEduform").reset();
        });
      });
      this.GetMasterUG();
      this.GetMasterPG();
      this.GetMasterPHD();
    }
  }

  Edit(Id) {
    //localStorage.setItem("status","Pending")
    var page = {
      pathname: "/EditSuperEducation",
      state: {
        Id: Id,
      },
    };

    this.props.history.push(page);
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    var Degree = document.getElementById("ug").value;
    var CourseName = document.getElementById("CourseName").value;
    var Specialization = document.getElementById("Specialization").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!Degree) {
      formIsValid = false;
      formErrors["UGErr"] = "Degree is required";
    } else if (!CourseName) {
      formIsValid = false;
      formErrors["PGErr"] = "Course Name is required";
    } else if (!Specialization) {
      formIsValid = false;
      formErrors["SpecializationErr"] = "Specialization is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  componentDidMount() {
    this.GetMasterUG();
    this.GetMasterPG();
    this.GetMasterPHD();
  }

  checkAll(event) {
    var checkboxes = document.getElementsByName("UgID");
    let count = checkboxes.length;
    if (event.target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Education selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }
  checkAllPG(event) {
    var checkboxes = document.getElementsByName("PgID");
    let count = checkboxes.length;
    if (event.target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Education selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  checkAllPHD(event) {
    var checkboxes = document.getElementsByName("PhdID");
    let count = checkboxes.length;
    if (event.target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Education selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  Multidelete(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    fetch(`${BASE_URLs}/Education/Multidelete`, options)
      .then((response) => response.json())
      .then((data) => {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.GetMasterUG();
        this.GetMasterPG();
        this.GetMasterPHD();
      });
    // this.Team();
    //    document.getElementById("delete").setAttribute('style', 'visibility: hidden;');
    //        document.getElementById("deletecount").setAttribute('style', 'visibility: hidden;');
    var checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );

    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  }
  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;

      if (document.getElementById("selectall").checked) {
        msg.innerHTML = "<span> " + count + " Education selected </span> ";
      } else {
        msg.innerHTML =
          "<span> " + checkboxes.length + " Education selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }

  render() {
    var username = JSON.parse(localStorage.uname);
    const { UGErr, PGErr, SpecializationErr } = this.state.formErrors;
    const { edus, PG, PHD } = this.state;
    var UGfilename = `O_${username}_SA_UG`;
    var PGfilename = `O_${username}_SA_PG`;
    var PHDfilename = `O_${username}_SA_PHD`;

    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="card">
                    <div className="glowBox">
                      <div style={{ float: "left" }}>
                        <Link to onClick={() => this.props.history.goBack()}>
                          <i
                            className="icofont icofont-arrow-left"
                            style={{ fontSize: "45px", color: "white" }}
                          />
                        </Link>{" "}
                      </div>
                      <h4 className="title">Education</h4>
                    </div>
                    <div className="card-header-right"></div>
                    <form
                      autoComplete="on"
                      id="SAEduform"
                      encType="multipart/form-data"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="card-block">
                        <div className="row">
                          <div className="col-lg-2">
                            <ul className="menu">
                              <li
                                className="dropdown dropdown-1"
                                style={{ height: "40px", width: "200px" }}
                              >
                                Education Menus
                                <ul className="dropdown_menu dropdown_menu-1">
                                  <li className="dropdown_item-1">
                                    <button
                                      className="btn"
                                      id="Btn_SAEdu"
                                      type="button"
                                      style={{ background: "white" }}
                                    >
                                      Add{" "}
                                    </button>
                                    {/* <Link to="AddEducation"   type="button" style={{width:"125px",background:"white"}} id="addRow"  className="btn ">Add  </Link> */}
                                  </li>

                                  <li className="dropdown_item-2">
                                    <input
                                      id="myInput"
                                      onFocus={(e) => {
                                        this.setState({
                                          UGbtn: true,
                                          PGbtn: false,
                                          PHDbtn: false,
                                        });
                                      }}
                                      className="btn"
                                      value="UG"
                                      type="button"
                                      style={{
                                        borderColor: "white",
                                        height: "42px",
                                        padding: "inherit",
                                        textAlign: "center",
                                        width: "125px",
                                        background: "white",
                                      }}
                                    />
                                  </li>

                                  <li className="dropdown_item-3">
                                    <input
                                      name="SkillName"
                                      value="PG"
                                      className="btn"
                                      type="button"
                                      onFocus={(e) => {
                                        this.setState({
                                          UGbtn: false,
                                          PGbtn: true,
                                          PHDbtn: false,
                                        });
                                      }}
                                      id="myInput1"
                                      style={{
                                        borderColor: "white",
                                        height: "42px",
                                        padding: "inherit",
                                        textAlign: "center",
                                        width: "125px",
                                        background: "white",
                                      }}
                                    />
                                  </li>

                                  <li className="dropdown_item-4">
                                    <input
                                      name="SkillName"
                                      type="button"
                                      className="btn"
                                      value="PHD"
                                      id="myInput2"
                                      onFocus={(e) => {
                                        this.setState({
                                          UGbtn: false,
                                          PGbtn: false,
                                          PHDbtn: true,
                                        });
                                      }}
                                      style={{
                                        borderColor: "white",
                                        height: "42px",
                                        padding: "inherit",
                                        textAlign: "center",
                                        width: "125px",
                                        background: "white",
                                      }}
                                    />
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-2">
                            {this.state.UGbtn ? (
                              <ReactHTMLTableToExcel
                                className="btn"
                                table="add-row-Ugtable"
                                filename={UGfilename}
                                sheet="Sheet"
                                buttonText="Export To Excel"
                                id="exportxlUG"
                                style={{ display: "none", color: "white" }}
                              />
                            ) : null}

                            {this.state.PGbtn ? (
                              <ReactHTMLTableToExcel
                                className="btn"
                                table="add-row-Pgtable"
                                filename={PGfilename}
                                sheet="Sheet"
                                buttonText="Export To Excel "
                                id="exportxlPG"
                                style={{ display: "none", color: "white" }}
                              />
                            ) : null}

                            {this.state.PHDbtn ? (
                              <ReactHTMLTableToExcel
                                className="btn"
                                table="add-row-Phdtable"
                                filename={PHDfilename}
                                sheet="Sheet"
                                buttonText="Export To Excel "
                                id="exportxlPHD"
                                style={{ display: "none", color: "white" }}
                              />
                            ) : null}
                          </div>
                          <div className="col-lg-8">
                            {this.state.UGbtn ? (
                              <input
                                id="searchug"
                                className="Searchbox"
                                placeholder="Search "
                                onKeyUp={this.MasterUGFilter}
                                style={{ float: "right", padding: "5px" }}
                              />
                            ) : null}
                            {this.state.PGbtn ? (
                              <input
                                id="searchpg"
                                className="Searchbox"
                                placeholder="Search "
                                onKeyUp={this.MasterPGFilter}
                                style={{ float: "right", padding: "5px" }}
                              />
                            ) : null}
                            {this.state.PHDbtn ? (
                              <input
                                id="searchphd"
                                className="Searchbox"
                                placeholder="Search "
                                onKeyUp={this.MasterPHDFilter}
                                style={{ float: "right", padding: "5px" }}
                              />
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-12" id="add-SAEducat">
                          <br />
                          <br />
                          <div className="row">
                            <div className="col-lg-4">
                              <select
                                className="form"
                                name="Degree"
                                id="ug"
                                onInputCapture={this.HandleUg}
                                onChange={this.handleChange}
                                type="text"
                              >
                                <option value=""> Select </option>
                                <option value="UG" name="UG">
                                  {" "}
                                  UG{" "}
                                </option>
                                <option value="PG" name="PG">
                                  PG
                                </option>
                                <option value="PHD" name="PHD">
                                  PHD
                                </option>
                              </select>
                              <span
                                className={UGErr ? " showError" : ""}
                              ></span>
                              {UGErr && (
                                <div
                                  style={{ color: "red", paddingBottom: 10 }}
                                >
                                  {UGErr}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4">
                              <input
                                className="form"
                                name="CourseName"
                                type="text"
                                id="CourseName"
                                onChange={this.handleChange}
                                placeholder="CourseName"
                              />
                              <span
                                className={PGErr ? " showError" : ""}
                              ></span>
                              {PGErr && (
                                <div
                                  style={{ color: "red", paddingBottom: 10 }}
                                >
                                  {PGErr}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4">
                              <input
                                className="form"
                                name="Specialization"
                                id="Specialization"
                                type="text"
                                onChange={this.handleChange}
                                placeholder="Specialization"
                              />
                              <span
                                className={
                                  SpecializationErr ? " showError" : ""
                                }
                              ></span>
                              {SpecializationErr && (
                                <div
                                  style={{ color: "red", paddingBottom: 10 }}
                                >
                                  {SpecializationErr}
                                </div>
                              )}
                              <span className="underline" />
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="col-lg-1">
                              <button
                                type="submit"
                                id="saverow2"
                                className="btn"
                                style={{
                                  float: "right",
                                  height: "40.4px",
                                  width: "83.36px",
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <form
                      autoComplete="on"
                      encType="multipart/form-data"
                      onSubmit={this.Multidelete}
                    >
                      <br />
                      <br />
                      <div className="card-block2">
                        {" "}
                        <div
                          id="selectalldiv"
                          style={{ visibility: "hidden" }}
                        ></div>
                        <button
                          type="submit"
                          className="multitrash"
                          id="delete"
                          style={{ float: "right", visibility: "hidden" }}
                        >
                          <i
                            className="icofont icofont-delete-alt"
                            style={{ color: "darkgray" }}
                          ></i>
                        </button>
                        <span
                          id="deletecount"
                          className="multitrash"
                          style={{ visibility: "hidden", fontSize: "17px" }}
                        ></span>
                        <br />
                        <div className="dt-responsive table-responsive Centergrid">
                          {this.state.UGbtn ? (
                            <table
                              id="add-row-Ugtable"
                              className="table nowrap"
                            >
                              <input
                                name="status"
                                value="ug"
                                style={{ display: "none" }}
                              />

                              <thead>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      id="selectall"
                                      name="ugselect"
                                      onClick={this.checkAll}
                                    />
                                  </th>

                                  <th>CourseName</th>
                                  <th>Specialization</th>
                                  <th>Actions </th>
                                </tr>
                              </thead>
                              <tbody>
                                {edus.map((edu) => (
                                  <tr>
                                    <td>
                                      <input
                                        defaultValue={edu.id}
                                        onClick={this.handlecheckbox}
                                        name="UgID"
                                        type="checkbox"
                                      />
                                    </td>
                                    <td>{edu.courseName}</td>
                                    <td>{edu.specialization}</td>
                                    <td>
                                      <img
                                        alt="edit"
                                        onClick={() =>
                                          this.EditUG(
                                            edu.id,
                                            edu.courseName,
                                            edu.specialization
                                          )
                                        }
                                        src="https://img.icons8.com/android/22/000000/pencil.png"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : null}
                          {this.state.PGbtn ? (
                            <table
                              id="add-row-Pgtable"
                              className="table nowrap"
                            >
                              <input
                                name="status"
                                value="pg"
                                style={{ display: "none" }}
                              />

                              <thead>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      id="selectall"
                                      name="ugselect"
                                      onClick={this.checkAllPG}
                                    />
                                  </th>

                                  <th>CourseName</th>
                                  <th>Specialization</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {PG.map((PG) => (
                                  <tr>
                                    <td>
                                      <input
                                        defaultValue={PG.id}
                                        onClick={this.handlecheckbox}
                                        name="PgID"
                                        type="checkbox"
                                      />
                                    </td>
                                    <td>{PG.courseName}</td>
                                    <td>{PG.specialization}</td>
                                    <td>
                                      <img
                                        alt="edit"
                                        onClick={() =>
                                          this.EditPG(
                                            PG.id,
                                            PG.courseName,
                                            PG.specialization
                                          )
                                        }
                                        src="https://img.icons8.com/android/22/000000/pencil.png"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : null}
                          {this.state.PHDbtn ? (
                            <table
                              id="add-row-Phdtable"
                              className="table nowrap"
                            >
                              <input
                                name="status"
                                value="phd"
                                style={{ display: "none" }}
                              />

                              <thead>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      id="selectall"
                                      onClick={this.handlecheckbox}
                                      name="phdselect"
                                      onClickCapture={this.checkAllPHD}
                                    />
                                  </th>
                                  <th>CourseName</th>
                                  <th>Actions </th>
                                </tr>
                              </thead>
                              <tbody>
                                {PHD.map((PHD) => (
                                  <tr>
                                    <td>
                                      <input
                                        name="PhdID"
                                        onClick={this.handlecheckbox}
                                        defaultValue={PHD.id}
                                        type="checkbox"
                                      />
                                    </td>
                                    <td>{PHD.specialization}</td>
                                    <td>
                                      <img
                                        alt="edit"
                                        onClick={() =>
                                          this.EditPHD(
                                            PHD.id,
                                            PHD.courseName,
                                            PHD.specialization
                                          )
                                        }
                                        src="https://img.icons8.com/android/22/000000/pencil.png"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : null}

                          <br />
                        </div>
                      </div>
                    </form>{" "}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
