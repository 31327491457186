import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base"
import SuccesAnnouncement from "../../Common/SuccesAnnouncement";
export default class AdminAnnouncmentpop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Announcement: [],
    };
  }

  GetAnnouncement() {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    var Id = this.props.location.state.Id;
    fetch(`${BASE_URLs}/Announcement/GetActiveAnnounceid?Id=` + Id, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcement: data,
        });

        // console.log("Announcemt", data);

        //
      });
  }

  componentDidMount() {
    this.GetAnnouncement();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  render() {
    var w = window.innerWidth;
    const { Announcement } = this.state;

    return (
      <div>
        <SideBar active={window.location.pathname} />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          </div>
                          <h4 className="title">Announcements</h4>
                        </div>

                        <div className="card-block">
                          {Announcement &&
                            Announcement.map &&
                            Announcement.map((Announcement) => (
                              <>
                                {Announcement.image === "-" &&
                                Announcement.video === "-" ? (
                                  <div className="col-lg-12">
                                    <div
                                      className="card-Announcement"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        padding: "2%",
                                        background: "white",
                                      }}
                                    >
                                      <h6
                                        key={Announcement.id}
                                        style={{ margin: "0%" }}
                                      >
                                        <center>
                                          {" "}
                                          <b> {Announcement.message}</b>
                                        </center>
                                      </h6>
                                      <br />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {Announcement.image !== "-" &&
                                    Announcement.video !== "-" ? (
                                      <div className="col-lg-12">
                                        <div
                                          className="card-Announcement"
                                          style={{
                                            position: "relative",
                                            overflow: "hidden",
                                            background: "white",
                                          }}
                                        >
                                          <h6 key={Announcement.id}>
                                            <center>
                                              {" "}
                                              <b>{Announcement.message}</b>
                                            </center>
                                          </h6>
                                          <br />
                                          <div className="row">
                                            <div className="col-lg-6">
                                              <img
                                                alt="announceimg"
                                                width="320"
                                                height="240"
                                                src={Announcement.image}
                                                style={{ width: "inherit" }}
                                              />
                                            </div>
                                            <div className="col-lg-6">
                                              <video
                                                width="320"
                                                height="240"
                                                controls
                                                style={{ width: "inherit" }}
                                              >
                                                <source
                                                  src={Announcement.video}
                                                  type="video/mp4"
                                                />
                                                <source
                                                  src={Announcement.video}
                                                  type="video/ogg"
                                                />
                                              </video>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        {Announcement.image === "-" &&
                                        Announcement.video !== "-" ? (
                                          <div className="col-lg-12">
                                            <div
                                              className="card-Announcement"
                                              style={{
                                                position: "relative",
                                                overflow: "hidden",
                                                background: "white",
                                              }}
                                            >
                                              <div className="col-lg-12">
                                                <h6 key={Announcement.id}>
                                                  <center>
                                                    <b>
                                                      {" "}
                                                      {Announcement.message}
                                                    </b>
                                                  </center>
                                                </h6>
                                                <br />
                                                <video
                                                  width="320"
                                                  height="240"
                                                  controls
                                                  style={{
                                                    width: "inherit",
                                                    padding: "0% 20%",
                                                  }}
                                                >
                                                  <source
                                                    src={Announcement.video}
                                                    type="video/mp4"
                                                  />
                                                  <source
                                                    src={Announcement.video}
                                                    type="video/ogg"
                                                  />
                                                </video>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="col-lg-12">
                                            <div
                                              className="card-Announcement"
                                              style={{
                                                position: "relative",
                                                overflow: "hidden",
                                                background: "white",
                                              }}
                                            >
                                              <h6 key={Announcement.id}>
                                                <center>
                                                  {" "}
                                                  <b> {Announcement.message}</b>
                                                </center>
                                              </h6>
                                              <br />
                                              <div className="row">
                                                <div
                                                  className="col-lg-12"
                                                  style={{ padding: "0% 28%" }}
                                                >
                                                  <img
                                                    alt="announceimg"
                                                    width="320"
                                                    height="240"
                                                    src={Announcement.image}
                                                    style={{ width: "inherit" }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ))}
                          <div className="col-lg-12">
                            <div style={{ textAlign: "center" }}>
                              <button
                                href
                                onClick={() => this.props.history.goBack()}
                                type="button"
                                style={{
                                  width: "130px",
                                  backgroundColor: "#7f63efde",
                                  color: "white",
                                }}
                                className="dropdown_item-2"
                                className="btn "
                              >
                                Back
                              </button>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
