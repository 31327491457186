import React, { Component } from "react";
import { BASE_URLs } from "../../Config/Base";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@material-ui/icons/Close";
import LifeStyle from "./Lifestyle";
import { Category } from "@material-ui/icons";

export default class AddLifestylePop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lifestyle: [],
            formErrors: {},
            ButtonDisabled: false,
        }
    }



    GetLifestyle() {

    const tableName = "MasterLifeStyle"; // Replace with the actual table name needed
    const url = `${BASE_URLs}/AdminProfile/getmasterdata?TableName=${tableName}`;
    let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;
        fetch(url, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
                Authorization: "Bearer " + accessToken,
            },
            //body:JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    lifestyle: data,

                });

            });
    }

    componentDidMount() {
        this.GetLifestyle();
    }
    handleSubmit = async (e) => {
        var Name = document.getElementById("LStyleName").value;
        var categoryA = document.getElementById("category").value;
        var OtherCategoryA = document.getElementById("ugtext").value;

        e.preventDefault();

        if (this.Validation()) {
            this.setState({
                ButtonDisabled: true,
            });
            const data = new FormData();
             data.append("Category", categoryA);
            data.append("LifeStyleName", Name);
            data.append("OtherCategory", OtherCategoryA);

            var role = JSON.parse(localStorage.Role);
            const url = `${BASE_URLs}/SuperAdmin/AddLifeStyle`;
            // role.toString() === "Platform Admin"
            //   ? `${BASE_URLs}/SuperAdmin/SASkill`
            //   : `${BASE_URLs}/Skill/AddSkillName`;

            const options = {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
                    Accept: "application/json",
                    Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
                },
                body: data,
            };
            const response = await fetch(url, options);
            const result = await response.json();

            if (result.status === "Success") {
                toast.success(result.message, {
                    position: "top-center",
                    autoClose: 3200,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    ButtonDisabled: false,
                });
                document.getElementById("Lifestylepop").style.display = "none";
                setTimeout(function () {
                    if (document.getElementById("close")) {
                        document.getElementById("close").click();
                    }
                }, 4000);
            } else {
                toast.error(result.message, {
                    position: "top-center",
                    autoClose: 3200,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };
    handlechange = (e) => {
        let formErrors = {};
        let formIsValid = true;
        var Name = document.getElementById("LStyleName").value;
        var CategoryName = document.getElementById("category").value;
        var OtherCategory = document.getElementById("ugtext").value;
        if (!Name) {
            formIsValid = false;
            formErrors["NameErr"] = "LifeStyleName  is required";
        }
        else if (!CategoryName) {
            formIsValid = false;
            formErrors["category"] = "Category is required";
        }
        else if (CategoryName === "others") {
            if (!OtherCategory || OtherCategory.trim() == "") {
                formIsValid = false;
                formErrors["OtherCategoryerr"] = "Category is required";
            }
        }
  
    
        this.setState({ formErrors: formErrors });
    
        return formIsValid;
      };

    Validation() {
        var Name = document.getElementById("LStyleName").value;
        var CategoryName = document.getElementById("category").value;
        var OtherCategory = document.getElementById("ugtext").value;
        let formErrors = {};
        let formIsValid = true;
        //Email
        if (!Name) {
            formIsValid = false;
            formErrors["NameErr"] = "LifeStyleName  is required";
        }
        if (!CategoryName) {
            formIsValid = false;
            formErrors["category"] = "Category is required";
        }
        if (CategoryName === "others") {
            if (!OtherCategory || OtherCategory.trim() == "") {
              formIsValid = false;
              formErrors["OtherCategoryerr"] = "Category is required";
            }
          }

        this.setState({ formErrors: formErrors });
        return formIsValid;
    }
    AutoCaps(e) {
        let value =
            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
        e.target.value = value;
    }

    Handlecategory() {
        var value = document.getElementById("category").value;
    
        if (value === "others") {
          document.getElementById("ugtext").value = "";
          document.getElementById("ugtext").type = "text";
        } else {
          document.getElementById("ugtext").value = "None";
          document.getElementById("ugtext").type = "hidden";
        }
      }
    render() {
        var w = window.innerWidth;
        const { NameErr, category,OtherCategoryerr } = this.state.formErrors;
        const uniqueCategories = [...new Set(this.state.lifestyle.map(item => item.category))];
        return (
            <>

                <ToastContainer
                    position="top-center"
                    autoClose={1500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <div className="pop">
                    <div
                        className="popinnernormal"
                        style={{ padding: "1%" }}
                        id="Lifestylepop"
                    >
                        <button
                            id="close"
                            style={{
                                float: "right",
                                backgroundColor: "#a0a3a305",
                                color: "#797070",
                                border: "1px solid #a3a5a505",
                            }}
                            onClick={this.props.close}
                        >
                            <CloseIcon />
                        </button><br />
                        <form autoComplete="off" encType="multipart/form-data"    onSubmit={this.handleSubmit}>

                            <div className="row" style={{ padding: "8px" }}>
                                <div className="col-sm-6" style={{ zIndex: "1000", display: "block" }}>
                                    <select
                                        className="effect-16 selectleftalign"
                                        id="category"
                                        name="Category"
                                        onChange={this.handlechange}
                                        onInputCapture={this.Handlecategory}
                                        type="text"
                                        placeholder="Select"
                                    >
                                        <option value=""> Select</option>

                                        {uniqueCategories.map((category) => (
                                            <option

                                                value={category}
                                            >
                                                {category}
                                            </option>
                                        ))}
                                        <option value="others">Others</option>

                                    </select>

                                    {!category ? (
                                        <div style={{ color: "#a29d9d", textAlign: "left" }}>

                                            Category
                                        </div>
                                    ) : (
                                        <div style={{ color: "red", textAlign: "left" }}>
                                            {category}
                                        </div>
                                    )}{" "}
                                    <input
                    type="hidden"
                    className="form"
                    id="ugtext"
                    name="OtherCategory"
                    onChange={this.handlechange}
                  />{" "}
                  {OtherCategoryerr == null ? null : (
                    <div style={{ color: "red", float: "left" }}>
                      {OtherCategoryerr}
                    </div>
                  )}
                                    <span className="underline" />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        className="form"
                                        maxLength="50"
                                        id="LStyleName"
                                        onChange={(e) => {
                                            this.AutoCaps(e);
                                            this.handlechange();
                                        }}
                                        name="LifeStyleName"
                                        type="text"

                                    />

                                    {!NameErr ? (
                                        <div style={{ color: "#a29d9d", textAlign: "left" }}>
                                            LifeStyleName{" "}
                                        </div>
                                    ) : (
                                        <div style={{ color: "red", textAlign: "left" }}>
                                            {NameErr}
                                        </div>
                                    )}
                                    <span className="underline" />
                                </div>
                            </div>

                            {/* <div className="row" style={{ padding: "8px" }}>
                               
                                <div className="col-sm-6">
                                    <input
                                        className="form"
                                        maxLength="50"
                                        id="LStyleName"
                                        onChange={(e) => {
                                            this.AutoCaps(e);
                                        }}
                                        name="SkillName"
                                        type="text"
                                    // onKeyPress={this.handleKeyPress}
                                    // onKeyDown={this._handleKeyDown}
                                    />
                                    {!NameErr ? (
                                        <div style={{ color: "#a29d9d", textAlign: "left" }}>
                                            Add LifeStyle{" "}
                                        </div>
                                    ) : (
                                        <div style={{ color: "red", textAlign: "left" }}>
                                            {NameErr}
                                        </div>
                                    )}
                                    <span className="underline" />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        className="form"
                                        maxLength="50"
                                        id="LStyleName"
                                        onChange={(e) => {
                                            this.AutoCaps(e);
                                        }}
                                        name="SkillName"
                                        type="text"
                                    // onKeyPress={this.handleKeyPress}
                                    // onKeyDown={this._handleKeyDown}
                                    />
                                    {!NameErr ? (
                                        <div style={{ color: "#a29d9d", textAlign: "left" }}>
                                            Add LifeStyle{" "}
                                        </div>
                                    ) : (
                                        <div style={{ color: "red", textAlign: "left" }}>
                                            {NameErr}
                                        </div>
                                    )}
                                    <span className="underline" />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-sm-12" style={{ textAlign: "center" }}>
                                    <button
                                        type="submit"
                                        disabled={this.state.ButtonDisabled}
                                        onClick={this.handleSubmit}
                                        className="but"
                                        style={{
                                            height: "39.5px",
                                            width: "86.36px",
                                            marginTop: "-0.3%",
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                            <br />
                        </form>
                    </div>
                </div>
            </>
        )
    }
}