import { Announcement, Title } from "@material-ui/icons";
import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import SuccesAnnouncement from "../../Common/SuccesAnnouncement";
import { BASE_URLs } from "../../Config/Base";
export default class AllAnnouncement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcement: [],
      formErrors: {},
      SuccessModel: false,
      imagePreviewUrl: "",
      image: "",
      imagedisplay: null,
      files: [],
      ButtonDisabled:false
    };
    this.initialState = this.state;
  }

  handleFormValidation() {
    var roles = document.getElementById("roles").value;
    var Message = document.getElementById("Message").value;
    var Title = document.getElementById("Title").value;
    var fileName = document.getElementById("Image").value;
    // var VdoName = document.getElementById("Video").value;
    let formErrors = {};
    let formIsValid = true;
    var reg = /(.*?)\.(jpg|jpeg|png|)$/;
    var vdo = /(.*?)\.(mp4|x-m4v|mkv|)$/;

    //Email

    if (!Message) {
      formIsValid = false;
      formErrors["MessageErr"] = "Description is required";
    }
    if (!Title) {
      formIsValid = false;
      formErrors["TitleErr"] = "Title is required";
    }
    if (!roles) {
      formIsValid = false;
      formErrors["roleerr"] = "Category is required";
    }
    // if (fileName) {
    //   if (!fileName.match(reg)) {
    //     formIsValid = false;
    //     formErrors["ImageErr"] = " Only Image can be Added";
    //   }
    // }
    // if (VdoName) {
    //   if (!VdoName.match(vdo)) {
    //     formIsValid = false;
    //     formErrors["VdoErr"] = "Only Video can be Added";
    //   }
    // }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "Image") {
        const fileNames = Array.from(files).map(file => URL.createObjectURL(file)); // Get file paths
        const concatenatedFileNames = fileNames.join(', '); // Join file paths with commas
        this.setState({ files: concatenatedFileNames });
    } else {
        this.setState({ [name]: value });
    }

    var roles = document.getElementById("roles").value;
    var Message = document.getElementById("Message").value;
    var Title = document.getElementById("Title").value;

    let formErrors = {};
    let formIsValid = true;
    var reg = /(.*?)\.(jpg|jpeg|png|)$/;
    var vdo = /(.*?)\.(mp4|x-m4v|mkv|)$/;

    const maxFileSizeMB = 10; // Set your desired maximum file size limit in megabytes
    const selectedFile = files && files.length > 0 ? files[0] : null;

    //Email

    if (!Message) {
      formIsValid = false;
      formErrors["MessageErr"] = "Description is required";
    }
    if (!Title) {
      formIsValid = false;
      formErrors["TitleErr"] = "Title is required";
    }
    if (!roles) {
      formIsValid = false;
      formErrors["roleerr"] = "Category is required";
    }

    // File size validation
    if (selectedFile) {
      const fileSizeMB = selectedFile.size / (1024 * 1024); // Convert to megabytes
      if (fileSizeMB > maxFileSizeMB) {
        // alert(`File size exceeds the maximum limit of ${maxFileSizeMB} MB.`);
        formIsValid = false;
        formErrors[
          "ImageErr"
        ] = `File size exceeds the maximum limit of ${maxFileSizeMB} MB.`;
        e.target.value = null;
      }
    }
    
    this.setState({ formErrors: formErrors });

    return formIsValid;
};

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleFormValidation()) {
        const data = new FormData(event.target);
        this.setState({
          ButtonDisabled: true,
        });
        const { files } = this.state;
        
        if (files.length !== 0 ) {
            const fileNamesArray = files.split(', ');

            fileNamesArray.forEach((filePath, index) => {
                fetch(filePath)
                    .then(response => response.blob())
                    .then(blob => {
                        const file = new File([blob], `file${index}.png`, { type: blob.type }); // Create a new File object
                        data.append(`file${index}`, file); // Append the file object to FormData
                    })
                    .catch(error => 
                      console.error('Error fetching file:', error)
                    );
            });
        }

        fetch(`${BASE_URLs}/Announcement/AdminAnnouncement`, {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
                Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            },
            body: data,
        })
            .then((res) => res.json())
            .then((resp) => {
                this.SuccessModel();
                this.setState({
                  ButtonDisabled: true,
                });
                event.target.reset();
                event.target.querySelector('input[type="file"]').value = '';
                this.setState({ files: '' });
            })
            .catch(error => console.error('Error submitting form:', error));

        // console.log("New", data);
        this.setState({ imagedisplay: null });


        // setTimeout(() => {
        //   var page = {
        //     pathname: "/AnnouncementPreview",
            
        //   };
        //   this.props.history.push(page);
        // }, 2000);
    } 
};

  GetAnnouncement() {
    let url = `${BASE_URLs}/Announcement/GetActiveAnnounce`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcement: data,
        });

        // console.log("Announce", data);

        //
      });
  }

  onImageChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
    if (event.target.files && event.target.files[1]) {
      this.setState({ image: URL.createObjectURL(event.target.files[1]) });
    }

    var result = value.substring(value.lastIndexOf("\\") + 1);
    this.setState({ imagedisplay: result });

   
  };

  componentDidMount() {
    this.GetAnnouncement();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  handleChangeA = (event) => {
    const files = event.target.files;
    // Process each file
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log(file.name);
        // Handle the file (e.g., upload to server or display preview)
    }
  }
  
  handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const fileNames = files.map(file => URL.createObjectURL(file)); // Get file paths
    const concatenatedFileNames = fileNames.join(', '); // Join file paths with commas
    this.setState({ files: concatenatedFileNames });
  };

  render() {
    var w = window.innerWidth;
    const { MessageErr, roleerr, ImageErr, VdoErr, TitleErr } =
      this.state.formErrors;
    return (
      <div>
        <SideBar active={window.location.pathname} />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row" >
                    <div className="col-lg-12">
                      <div className="card" id="AdminAnnFullcontainer">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title" style={{marginRight:'56px'}}>Create New Announcements</h4>
                        </div>

                        {this.state.SuccessModel ? (
                          <SuccesAnnouncement
                            id="Success"
                            close={() => this.SuccessModel()}
                          />
                        ) : null}
                        <div className="card-block">
                          <form
                            id="Announceform"
                            className="contactForm"
                            autoComplete="on"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="container">
                              {/* <b>
                                {" "}
                                <Link
                                  style={{ cursor: "pointer", float: "right" }}
                                  to="AnnouncementPreview"
                                >
                                  View Previous Announcements
                                </Link>
                              </b>
                              <br /> <br /> */}
                              {/* <center style={{fontSize:'15px',color:'#5035ce'}}>Create New Announcements</center>
                              <br /> */}
                              <div
                                className="row"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop:'50px'
                                }}
                              >
                                <div className="col-lg-4 input-effect">
                                  <textarea
                                    className="form"
                                    id="Title"
                                    style={{ height: "37px" }}
                                    name="Title"
                                    onChange={this.handleChange}
                                    placeholder="Subject"
                                    // maxLength={50}
                                  />
                                
                                  {/* <span style={{color:'rgb(162, 157, 157)'}}>Title</span>
                                  {TitleErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                        position: "absolute",
                                      }}
                                    >
                                      {TitleErr}
                                    </div>
                                  )} */}
                                  {!TitleErr ? (
                                    <span style={{color:'rgb(162, 157, 157)'}}>Subject</span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {TitleErr}
                                          </div>
                                        )}
                                  <span className="underline" />
                                </div>
                                <div className="col-lg-4 input-effect">
                                  <textarea
                                    className="form"
                                    id="Message"
                                    style={{ height: "37px" }}
                                    name="Message"
                                    onChange={this.handleChange}
                                    placeholder="Description"
                                  />
                                  {/* {MessageErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                        position: "absolute",
                                      }}
                                    >
                                      {MessageErr}
                                    </div>
                                  )} */}
                                  {!MessageErr ? (
                                    <span style={{color:'rgb(162, 157, 157)'}}>Description</span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {MessageErr}
                                          </div>
                                        )}
                                  <span className="underline" />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="col-lg-4 input-effect">
                                  <select
                                    className="form selectleftalign"
                                    id="roles"
                                    name="Category"
                                    onChange={this.handleChange}
                                  >
                                    <option value="">Select</option>
                                    <option value="General">
                                      General
                                    </option>
                                    <option value="Software Updates">
                                      Software Updates
                                    </option>
                                    <option value="HR">HR </option>
                                    <option value="Company">Company</option>
                                    <option value="Foundations">
                                      Foundations
                                    </option>
                                    <option value="Events">Events</option>
                                  </select>
                                  {/* {roleerr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                        position: "absolute",
                                      }}
                                    >
                                      {roleerr}
                                    </div>
                                  )} */}
                                  {!roleerr ? (
                                    <span style={{color:'rgb(162, 157, 157)'}}>Category</span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {roleerr}
                                          </div>
                                        )}

                                  <span className="underline" />
                                </div>

                                <div className="col-lg-4 ">
                                {/* <input type='file' name='files[]' multiple /> */}
                                   <input
                                    type="file"                    
                                    name="Image"
                                    id="Image"
                                    onFocus={(e) => (e.target.type = "file")}
                                    style={{ width: "100%" }}
                                    className="form"
                                    onChange={this.handleChange}
                                    placeholder="Image"
                                    multiple
                                    accept=".jpg, .jpeg, .pdf, .xlsx, .xls, .mp4, .avi, .mov"
                                  />
                                
                                  {/* {ImageErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {ImageErr}
                                    </div>
                                  )} */}
                                  {!ImageErr ? (
                                    <span style={{color:'rgb(162, 157, 157)'}}>Upload Related Files&nbsp;(.jpg/.jpeg./.png/.mp4/.xlsx/.pdf)</span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {ImageErr}
                                          </div>
                                        )}

                                  <span className="underline" />

                              
                                </div>
                              </div>
                              {/* <div
                                className="row"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="col-lg-4 input-effect">
                                  <input
                                    type="file"
                                    className="form"
                                    onFocus={(e) => (e.target.type = "file")}
                                    style={{ width: "100%" }}
                                    id="Video"
                                    name="Video"
                                    onChange={this.handleChange}
                                    placeholder="Video" //style={{width:"123%",marginLeft:"-22%"}}
                                  />
                                 
                                  {!VdoErr ? (
                                    <span style={{color:'rgb(162, 157, 157)'}}>Video</span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {VdoErr}
                                          </div>
                                        )}
                                  <span className="underline" />
                                </div>
                                <div className="col-lg-4 input-effect">
                                 
                                </div>
                              </div> */}
                            </div>
                            <div className="col-lg-12">
                                      <div className="row  justify-content-center" style={{marginTop:'20px'}}>
                                        <button
                                          type="submit"
                                          disabled={this.state.ButtonDisabled}
                                          className="but"
                                          style={{
                                            height: "39.5px",
                                            width: "83.36px",
                                          }}
                                        >
                                          Submit
                                        </button>
                                        <button
                                          type="reset"
                                          className=" btn-Secondary"
                                          style={{
                                            marginLeft: "1%",
                                            height: "39.5px",
                                          }}
                                          onClick={() =>
                                            this.setState({ formErrors: "" })
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                            {/* <div
                              className="row"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                             
                                <button
                                  type="submit"
                                  title="Send Message"
                                  className="but"
                                >
                                  Submit
                                </button>
                              
                             
                            </div> */}
                          </form>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
