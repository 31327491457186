import React, { Component } from "react";
import LogoCompo from "../Common/LogoCompo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class Forgot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: "",
      value: this.props.location.state,
      formErrors: {},
      Counter: "60",
      ButtonDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleFormValidation() {
    // const {otp}= this.state;
    var otp = document.getElementById("Otp").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!otp) {
      formIsValid = false;
      formErrors["otpErr"] = "OTP is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    var otp = document.getElementById("Otp").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!otp || otp.trim() === "") {
      formIsValid = false;
      formErrors["otpErr"] = "OTP is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const myDecipher = this.decipher("mySecretSalt");
    if (this.handleFormValidation()) {
      this.setState({ ButtonDisabled: true });
      var Otpmail = localStorage.getItem("mlcode");
      Otpmail = JSON.parse(myDecipher(Otpmail).toString());
      if (Otpmail === this.state.otp) {
        sessionStorage.clear();
        toast.success("OTP Verified Successfully", {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          this.props.history.push("/NewPassword");
        }, 4000);

        
      } else {
        toast.error("Invalid OTP !!", {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    
    }
    setTimeout(() => {
      this.setState({ ButtonDisabled: false });
    }, 4000);
     
  };

  Reset = async (e) => {
    
    e.preventDefault();
    var mail = localStorage.rml;
    const myDecipher = this.decipher("mySecretSalt");
    mail = myDecipher(mail).toString();
    mail = JSON.parse(mail);
    document.getElementById("rrr").setAttribute("style", "display:none ;");
    document
      .getElementById("timer")
      .setAttribute("style", "display:contents ;");

    const v = setInterval(() => {
      this.setState({ Counter: this.state.Counter - 1 });

      if (this.state.Counter === 0) {
        clearInterval(v);
        document
          .getElementById("rrr")
          .setAttribute("style", "display:initial ; ");
        document
          .getElementById("timer")
          .setAttribute("style", "display:none ;");

        this.setState({ Counter: "60" });
      }
    }, 1000);

    const url =
      `${BASE_URLs}/Forgot/ResendOtp?Email=` +
      mail +
      "";
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
      },
    };

    const response = await fetch(url, options);
    const result = await response.json();
    if (result.status === "Success") {
      sessionStorage.clear();
      toast.success("Resended OTP Successfully", {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        window.location.reload(true)
      }, 3200);
      
    } 
    let myCipher = this.cipher("mySecretSalt");
    localStorage.setItem("mlcode", myCipher(JSON.stringify(result.otp)));
  };

  decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
  };

  cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split("")

        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
  };
  render() {
    var w = window.innerWidth;

    const { otpErr } = this.state.formErrors;
    return (
      <div>
        <div>
          <div className="bgImg" />
          <div className="content">
            {" "}
            <br />
            <LogoCompo />
            <ToastContainer
              position="top-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div
              className={
                w < 992
                  ? "  h-100 align-items-center justify-content-center"
                  : " d-flex h-100 align-items-center justify-content-center"
              }
            >
              <div className="container">
                <div className="row">
                  <div
                    className={
                      w < 992
                        ? " col-sm-12 ml-auto mr-auto"
                        : "col-md-4 col-sm-6 ml-auto mr-auto"
                    }
                  >
                    <div className="card-copy">
                      <div className="card-body">
                        <form id="myForm" onSubmit={this.handleSubmit}>
                          <br />
                          <br />
                          <br />
                          <div>
                            <h4 className="title1">
                              <span className="shadows">OTP</span>
                            </h4>
                          </div>

                          <div className="signUp">
                            <span>
                              <input
                                className="form"
                                maxLength="6"
                                id="Otp"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                name="otp"
                                type="text"
                                onChange={this.handleChange}
                                placeholder="Enter OTP"
                              />
                              {otpErr && (
                                <span
                                  style={{ color: "red", paddingBottom: 10 }}
                                >
                                  {otpErr}
                                </span>
                              )}
                              <span className="underline" />
                            </span>
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn"
                              style={{ width: "100.36px" }}
                              disabled={this.state.ButtonDisabled}
                            >
                              Submit
                            </button>
                            &nbsp;&nbsp;{" "}
                            <button
                              onClick={this.Reset}
                              id="rrr"
                              className="btn"
                            >
                              {" "}
                              Resend OTP
                            </button>
                            <span id="timer" style={{ color: "gray" }}>
                              Resend in{" "}
                              <span style={{ color: "green" }}>
                                <b>{this.state.Counter}</b>
                              </span>{" "}
                              Seconds{" "}
                            </span>
                          </div>
                          <br />
                          <br />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
