import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactTimeAgo from "react-time-ago";
import EditMessagepop from "../../Common/EditMessagepop";
import { BASE_URLs } from "../../Config/Base"
import fileImg from "../../Assets/images/document.png";
import CloseIcon from "@material-ui/icons/Close";
import { Image_URLs } from "../../Config/Base";
export default class AnnouncementPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Messages: [],
      AllMessages: [],
      EditBusinessValues: [],
      EditModel: false,
      status: false,
      isOpen: false,
      MessageDetails: [],
      Filterpop: false,
      letters: [],
      clickedContents:
        JSON.parse(localStorage.getItem("clickedContents")) || {},
    };
  }

  GetMessages() {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(`${BASE_URLs}/Contact/GetAdminContactmsg`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Messages: data,
          AllMessages: data,
        });

        // console.log("Value", data);

        //
      });
  }

  EditModel = (e, fn, msg, img) => {
    this.setState({
      EditBusinessValues: {
        contact_Id: e,
        cSubject: fn,
        cMsg: msg,
        image: img
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    this.GetMessages();
  };

  Details(contact_Id) {
    var page = {
      pathname: "/MessagePrev",
      state: {
        Contact_Id: contact_Id,
      },
    };
    this.props.history.push(page);
  }

  GetUserProfile() {
    let url = `${BASE_URLs}/Profile/Userprofile`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Profile_Pic: data,
        });

        // //
      });
  }
  componentDidMount() {
    this.GetMessages();
    this.GetUserProfile();

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });

    //  var role =JSON.parse(localStorage.Role);
  }
  //   DeleteButtons=(id)=>{

  //     const data = new FormData();

  //      if (window.confirm("Are you sure you want to delete this record permenently?")){

  //       var options = {

  //       method: 'POST',

  //       headers:{

  //           Authorization: `Bearer ${JSON.parse(localStorage.user)}`,

  //       },
  //       body: data

  //   };
  //   fetch(`${BASE_URLs}/Announcement/DeletePreviewannounce?Id="+id,options)

  //   .then(response => response.json())
  //   .then(data => {

  //     toast.success(
  //       data.message,{
  //       position: "top-center",
  //       autoClose: 3200,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       })

  //   }
  //   );
  //   this.GetAnnouncement();
  // }

  //   }

  // DeleteGroup= (Id) =>{
  //   var x = window.confirm('Are you sure you want to delete this record permenently?');
  //   if (x){
  //   fetch('${BASE_URLs}/Announcement/DeletePreviewannounce?Id='+Id,
  //    { method: 'POST' })

  //   .then(() => this.setState({ status: 'Delete successful' }));

  //   window.location.reload();
  // }
  // else{
  //   return false;
  // }
  // // this.GetAnnouncement();
  // }

  DeleteButton = (Contact_Id) => {

    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        // body: data
      };
      fetch(`${BASE_URLs}/Contact/DeletePreviewMsg?Contact_Id=` + Contact_Id, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetMessages();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

    }
  };
  handleContentClick(contact_Id) {
    const clickedContents = { ...this.state.clickedContents };
    clickedContents[contact_Id] = true;
    localStorage.setItem("clickedContents", JSON.stringify(clickedContents));
    this.setState({ clickedContents });
  }
  bothonclick(contact_Id) {
    this.handleContentClick(contact_Id);
    this.Details(contact_Id);
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  truncateTitle(subject, maxLength) {
    if (subject.length > maxLength) {
      return subject.substring(0, maxLength) + "...";
    }
    return subject;
  }

  GetMessagesDetails(contact_Id) {
    let user = JSON.parse(localStorage.getItem("user"));
    // var Contact_Id = this.props.location.state.Contact_Id;
    this.setState({
      isOpen: true
    })
    const accessToken = user;
    fetch(`${BASE_URLs}/Contact/AdminMessageid?Contact_Id=` + contact_Id, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          MessageDetails: data,
        });

        // console.log("Value", data);

        //
      });
  }
  popupclose = () => {
    this.setState({
      isOpen: false
    })
  }

  getFileType(path) {
    const extension = path.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'avi', 'mov', 'wmv', 'mkv'].includes(extension)) {
      return 'video';
    } else if (['pdf', 'xls', 'xlsx'].includes(extension)) {
      return 'document';
    } else {
      return 'file';
    }
  }

  filterfunction = (e) => {
    const Messages = this.state.AllMessages.filter(
      (item) =>
        item.cSubject.toLowerCase().includes(e.target.value.toLowerCase()) 
    );
    this.setState({ Messages, showResults: true, });
  }

  handleFilter = (letter, event) => {
    event.preventDefault();
    const Messages = this.state.AllMessages.filter((item) => {
      return (    
        item.cSubject.toLowerCase().startsWith(letter.toLowerCase())
      );
    });
    this.setState({ Messages, showResults: true,  });
  };

  handleImageClick = (url) => {
    window.open(url, '_blank');
     };
  render() {
    const { Filterpop,MessageDetails, Messages, status, isOpen } = this.state;
    return (
      <div>

        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          </div>
                          <h4 className="title" style={{ marginRight: "50px" }}>
                            Contact Admin
                          </h4>
                          {this.state.EditModel ? (
                            <EditMessagepop
                              values={this.state.EditBusinessValues}
                              close={() => this.EditModel()}
                            />
                          ) : null}
                        </div>

                        {isOpen ?
                          <div className="col-lg-12">
                            <div className="popup" style={{ textAlign: 'left' }}>
                              <div className="popup-content">
                                <div className="Announcedetailspop" >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                    style={{
                                      color: "gray",
                                      marginLeft: "90%",
                                      fontSize: "17px",
                                      cursor: 'pointer'
                                    }}
                                    onClick={this.popupclose}
                                  ></i>
                                  <br />
                                  <center><h6><b>Message Details</b></h6></center>
                                  <div className="card-block" style={{ overflow: "scroll" }}>
                                    <div>
                                      {MessageDetails.map((Message) => (
                                        <>
                                          {Message.image === "-" ? (
                                            <div>
                                            <div>
                                              <span
                                                key={Message.contact_Id}
                                                style={{ margin: "0%", whiteSpace: "pre-wrap" }}
                                              >
                                                <b>Subject:</b>&nbsp;
                                                <span style={{ overflowWrap: "break-word", width: "50%" }}>
                                                  {Message.cSubject}
                                                </span>
                                                <br /><br />
                                                <b>Message:</b>&nbsp;
                                                <span style={{ width: "50%" }}>
                                                  {Message.cMsg}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                          ) : (
                                            <div style={{ width:"97%",height: "237px", overflow: "scroll"}}>
                                            {Message.image.split(',').map((imageUrl, index) => (
                                              <div style={{ display: "inline-block", marign: "10px", whiteSpace: "pre-wrap",}} key={index}>
                                                {index === 0 && (
                                                  <>
                                                  <span style={{ margin: "40px" }}>
                                                  <b>Subject:</b>&nbsp;
                                                  <span style={{ overflowWrap: "break-word", width: "50%", }} > {Message.cSubject}</span></span><br /><br />
                                                  <span style={{ margin: "40px" }}>
                                                  <b>Message:</b>&nbsp;<span style={{ overflowWrap: "break-word", width: "50%", }}>{Message.cMsg}</span></span><br /><br />
                                                  <span style={{ margin: "40px" }}>
                                                  <b>Attachment:</b>&nbsp;</span>
                                                  <br />
                                                  
                                                  </>
                                                )}

                                                <div style={{margin:"10px"}}>
                                                {this.getFileType(imageUrl) === 'image' ? (
                                                  
                                                  <span style={{ margin:"30px" }}>
                                                  <b>Image:&nbsp;</b>

{imageUrl.split(',').map((Url, index) => (
<img
key={index}
alt={`Image ${index}`}
src={imageUrl.trim()} 
style={{ width: "100px", height: "100px" }}
onClick={() => this.handleImageClick(Url.trim())}
/>
))}
                                                  </span>
                                                ) : this.getFileType(imageUrl) === 'video' ? (
                                                  <span style={{ display: "flex", margin:"30px" }}>
                                                    <span><b>video:</b></span>
                                                    <video
                                                      controls
                                                      style={{ width: "125px", height: '118px', marginTop: '-45px', marginLeft:"8px" }}
                                                    >
                                                      <source src={imageUrl} type="video/mp4" />
                                                      <source src={imageUrl} type="video/ogg" />
                                                    </video>
                                                  </span>
                                                ) : (
                                                  <span style={{margin:"30px"}}>
                                                    <b>File:</b> 
                                                    <img
                                                      src={fileImg}
                                                      style={{ height: "30px", width: "30px" }}
                                                    />
                                                    &nbsp;
                                                    <a
                                                      href={imageUrl}
                                                      name="picture"
                                                      readOnly
                                                      value={imageUrl}
                                                      title=""
                                                      target="_blank"
                                                    >
                                                      <span>
                                                      
                                                        {imageUrl.replace(
                                                    `${Image_URLs}/Images/`,
                                                    " "
                                                  )}
                                                      </span>
                                                    </a>
                                                  </span>
                                                )}
                                                </div>

                                              </div>
                                            ))}
                                              {/* {Message.image !== "-" ? (


                                                <div>

                                                <span
                                                  key={Message.contact_Id}
                                                style={{ margin: "0%" }}
                                              >

                                                <b>Subject:</b> &nbsp; <span
                                                  style={{ overflowWrap: "break-word", width: "50%", }}
                                                > {Message.cSubject}</span><br /><br />
                                                <b>Message:</b> &nbsp; <span
                                                  style={{ overflowWrap: "break-word", width: "50%", }}
                                                > {Message.cMsg}</span><br /><br />
                                                     <b>Attachment:</b> &nbsp;                                                     
                                              </span>
                                             
                                                  <div
                                                    className="card-Announcement"
                                                    style={{
                                                      position: "relative",
                                                      overflow: "hidden",
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    <h6 key={Message.contact_Id} style={{}}>
                                                      <center>
                                                        {" "}
                                                        <span>Message:</span>&nbsp;
                                                        <span>{Message.cMsg}</span>
                                                      </center>
                                                    </h6>
                                                    <div className="row">
                                                      <div className="col-lg-6">
                                                        <img
                                                          alt="announceimg"
                                                          width="320"
                                                          height="240"
                                                          src={Message.image}
                                                          style={{ width: "inherit" }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <>
                                                  {Message.image === "-" ? (
                                                    <div className="col-lg-12">
                                                      <div
                                                        className="card-Announcement"
                                                        style={{
                                                          position: "relative",
                                                          overflow: "hidden",
                                                          backgroundColor: "white",
                                                        }}
                                                      >
                                                        <div className="col-lg-12">
                                                          <h6
                                                            key={Message.contact_Id}
                                                            style={{}}
                                                          >
                                                            <center>
                                                              {" "}
                                                              <span>Message:</span>&nbsp;
                                                              <span>
                                                                {" "}
                                                                {Message.cMsg}
                                                              </span>
                                                            </center>
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="col-lg-12">
                                                      <div
                                                        className="card-Announcement"
                                                        style={{
                                                          position: "relative",
                                                          overflow: "hidden",
                                                          backgroundColor: "white",
                                                        }}
                                                      >
                                                        <h6
                                                          key={Message.contact_Id}
                                                          style={{}}
                                                        >
                                                          <center>
                                                            {" "}
                                                            <span>Message:</span>&nbsp;
                                                            <span> {Message.cMsg}</span>
                                                          </center>
                                                        </h6>
                                                        <div className="row">
                                                          <div
                                                            className="col-lg-12"
                                                            style={{ padding: "0% 28%" }}
                                                          >
                                                            <img
                                                              alt="announceimg"
                                                              width="320"
                                                              height="240"
                                                              src={Message.image}
                                                              style={{ width: "inherit" }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )} */}
                                            </div>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                </div> </div> </div></div> : null}


                         <div className="card-block">

                        <Link
                            to="/Contact"
                            style={{
                              textAlign: "center", marginLeft: "20px",
                              width: "200px", background: "white"
                            }}
                            className="btn"
                          >
                            {" "}
                            <i className="fa-solid fa-plus"></i><span id='buttonlabel'>Create New Message</span>
                          </Link>
                          

                         
                            <input
                              type="text"
                              className="Searchbox usersearch"
                              placeholder="Search"
                              style={{ float: 'right' }}
                              //  onKeyPress={this.handleKeyPress}
                               onClick={() => {
                                                        this.setState({
                                                        Filterpop: true,
                                                        Filterpop: !this.state.Filterpop,
                                                        });
                                                        }}
                              readOnly

                            />
                        </div>
               

                        <div className="card-block">
                          <div className="dt-responsive table-responsive"
                            id={Messages.length <= 5? "UserNoAnnLength" : "Above10"}
                          //  style={Filterpop?{}:{
                          //   marginTop:'-45px'
                          //  }}
                          >
                            {Messages.length === 0 ?
                              <center>

                                <p>No Record</p>
                              </center>
                              : null}
                            {Messages.length !== 0 ? (<table
                              id="add-row-table3"
                              className="table nowrap"
                              style={Filterpop?{ marginTop: "48px" }:{marginTop:'0px'}}
                            >
                              <thead id='tablepadding'><tr>
                                <th></th>
                                <th>Message Details</th>
                                <th><span style={{ marginLeft: "12px" }}>Actions</span></th>
                              </tr>
                              </thead>

                              <tbody id='tablepadding'>

                                <>
                                  {Messages.map((Message) => (
                                    <>

                                      <tr>

                                        <td></td>
                                        <td>
                                          {/* <Link
                                        onClick={() =>
                                          this.bothonclick(Message.contact_Id)
                                        }
                                        style={{
                                          color: this.state.clickedContents[
                                            Message.contact_Id
                                          ]
                                            ? "gray"
                                            : "black",
                                          cursor: "pointer",
                                        }}
                                      > */}
                                          <span>Subject:&nbsp;</span>
                                          <a href="#" onClick={(event) => {
                                            event.preventDefault();
                                            this.GetMessagesDetails(Message.contact_Id)
                                          }}>{this.truncateTitle(Message.cSubject, 85)}</a>
                                          {/* <span>Subject:</span>&nbsp;
                                        <span>{Message.cSubject}</span> */}
                                          &nbsp;<span
                                            style={{ cursor: 'pointer', fontSize: "12px" }}
                                            onClick={() =>

                                              this.GetMessagesDetails(Message.contact_Id)
                                            }
                                          >See More</span>&nbsp;&nbsp;&nbsp;
                                          <ReactTimeAgo
                                        date={Message.createdDateTime}
                                        locale="en-US"
                                        style={{ color: "gray", fontSize: "12px" }}
                                      />
                                          {/* </Link> */}

                                        </td>

                                        <td>

                                          <button
                                            className="Annbuttons sk-del"
                                            type="button"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.DeleteButton(Message.contact_Id);
                                            }}
                                          >
                                            <i
                                              className="fa fa-minus"
                                              aria-hidden="true"
                                            ></i>
                                          </button> &nbsp;&nbsp;
                                          <button
                                            className="Annbuttons sk-edit"
                                            type="button"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.EditModel(
                                                Message.contact_Id,
                                                Message.cSubject,
                                                Message.cMsg,
                                                Message.image
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              aria-hidden="true"
                                            ></i>{" "}
                                          </button>
                                        </td>

                                      </tr>
                                    </>
                                  ))}
                                </>
                              </tbody>
                            </table>) : null}
                          </div>
                          {/* <ul
                            className="show-notifications"
                            style={{ overflow: "scroll" }}
                          >
                            {Messages.map((Message) => (
                              <>
                                <div className="col-lg-12">
                                  <div
                                    className="card-Announcements"
                                    style={{
                                      position: "relative",
                                      overflow: "hidden",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <li>
                                      <Link
                                        onClick={() =>
                                          this.bothonclick(Message.contact_Id)
                                        }
                                        style={{
                                          color: this.state.clickedContents[
                                            Message.contact_Id
                                          ]
                                            ? "gray"
                                            : "black",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <span>Subject:</span>&nbsp;
                                        <span>{Message.cSubject}</span>
                                        &nbsp;&nbsp;&nbsp;
                                      
                                        &nbsp;&nbsp;&nbsp; See More{" "}
                                      </Link>
                                      &nbsp;&nbsp;&nbsp;{" "}
                                      <button
                                        className="button-30 sk-edit"
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.EditModel(
                                            Message.contact_Id,
                                            Message.cSubject,
                                            Message.cMsg,
                                            Message.image
                                          );
                                        }}
                                      >
                                        Edit&nbsp;
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </button>
                                      &nbsp;&nbsp;&nbsp;
                                      <button
                                        className="button-30 sk-del"
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.DeleteButton(Message.contact_Id);
                                        }}
                                      >
                                        Delete&nbsp;
                                        <i
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </li>
                                  </div>
                                </div>
                              </>
                            ))}
                          </ul> */}
                        </div>
                        {/* <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <button
                              href
                              onClick={() => this.props.history.goBack()}
                              type="button"
                              style={{
                                width: "130px",
                                backgroundColor: "#7f63efde",
                                color: "white",
                              }}
                              className="dropdown_item-2"
                              className="btn "
                            >
                              Back
                            </button>{" "}
                          </div>
                        </div>
                        <br /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {Filterpop && (
                <div className="popSearchTeam">
                  <div className="popSearchinnernormalTeam">
                    <div
                      className="popSearchinnertitle"
                      id="popSearchinnernormal"
                    >
                      <button
                        id="close"
                        style={{
                          float: "right",
                          backgroundColor: "#a0a3a305",
                          color: "#797070",
                          border: "1px solid #a3a5a505",
                          marginTop: "-20px",
                          marginRight: "-20px",
                        }}
                        onClick={() => {
                          this.setState({ Filterpop: false });
                          this.GetMessages();
                        }}
                      >
                        <CloseIcon />
                      </button>

                      <div className="checkboxes">
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{
                            fontSize: "20px",
                            color: "gray",
                            marginLeft: "-1%",
                            position: "absolute",
                            marginTop: "0.5%",
                          }}
                        ></i>
                        <input
                          className="Searchbox"
                          placeholder="Search"
                          onChange={this.filterfunction}
                          style={{ float: "right", width: "80%" }}
                        />
                        <br></br>
                        {this.state.letters.map(
                          (letter, index) => (
                            <button
                              className="filter"
                              key={index}
                              onClick={(event) =>
                                this.handleFilter(
                                  letter,
                                  event
                                )
                              }
                            >
                              {letter}
                            </button>
                          )
                        )}
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
