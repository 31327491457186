import React, { Component } from "react";
import $ from "jquery";
import SideBar from "../Common/Sidebar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../Config/Base";
import "react-toastify/dist/ReactToastify.css";

$(document).ready(function () {
  $(".notfound").hide();

  // Search User columns

  $("#searchorg").keyup(function () {
    // Search Text
    var search = $(this).val();

    // Hide all table tbody rows
    $("table tbody tr").hide();

    // Count total search result
    var len = $(
      'table tbody tr:not(.notfound) td:contains("' + search + '")'
    ).length;

    if (len > 0) {
      // Searching text in columns and show match row
      $('table tbody tr:not(.notfound) td:contains("' + search + '")').each(
        function () {
          $(this).closest("tr").show();
        }
      );
    } else {
      $(".notfound").show();
    }
  });

  $.expr[":"].contains = $.expr.createPseudo(function (arg) {
    return function (elem) {
      return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
  });
});
export default class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: [],
      CName: "",
      CEmail: "",
      CSubject: "",
      CMsg: "",
      formErrors: {},
      Notification: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.state;
  }

  handleFormValidation() {
    var CSubject = document.getElementById("CSubject").value;
    var CMsg = document.getElementById("CMsg").value;
    let formErrors = {};
    let formIsValid = true;

    if (!CSubject || CSubject.trim() == "") {
      formIsValid = false;
      formErrors["CSubjectErr"] = "Subject is required";
    }

    if (!CMsg || CMsg.trim() == "") {
      formIsValid = false;
      formErrors["CMsgErr"] = "Message is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.handleFormValidation()) {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}/Contact/AddContact`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState(this.initialState);
        });
      document.getElementById("contactForm").reset();

       setTimeout(() => {
        var page = {
          pathname: "/ContactMessages",
          
        };
        this.props.history.push(page);
       }, 4000);
    }
  }

  handleChange = (e) => {

    const { name, value, files } = e.target;

    if (name === "Image") {
        const fileNames = Array.from(files).map(file => URL.createObjectURL(file)); // Get file paths
        const concatenatedFileNames = fileNames.join(', '); // Join file paths with commas
        this.setState({ files: concatenatedFileNames });
    } else {
        this.setState({ [name]: value });
    }
    var CSubject = document.getElementById("CSubject").value;
    var CMsg = document.getElementById("CMsg").value;
    let formErrors = {};
    let formIsValid = true;


    const maxFileSizeMB = 10; 
    const selectedFile = files && files.length > 0 ? files[0] : null;

    if (!CSubject || CSubject.trim() == "") {
      formIsValid = false;
      formErrors["CSubjectErr"] = "Subject is required";
    } else if (!CMsg || CMsg.trim() == "") {
      formIsValid = false;
      formErrors["CMsgErr"] = "Message is required";
    }

    if (selectedFile) {
      const fileSizeMB = selectedFile.size / (1024 * 1024); // Convert to megabytes
      if (fileSizeMB > maxFileSizeMB) {
        // alert(`File size exceeds the maximum limit of ${maxFileSizeMB} MB.`);
        formIsValid = false;
        formErrors[
          "ImageErr"
        ] = `File size exceeds the maximum limit of ${maxFileSizeMB} MB.`;
        e.target.value = null;
      }
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };

  render() {
    const { CSubjectErr, CMsgErr,ImageErr } = this.state.formErrors;
    return (
      <div>
        <SideBar active={window.location.pathname} />
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header"></div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="glowBox">
                        <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          <h4 className="title"> Create New Message</h4>
                        </div>
                        <div className="card-block">
                          <br />
                          <form
                            id="contactForm"
                            className="contactForm"
                            autoComplete="on"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="container">
                            {/* <b>
                                {" "}
                                <Link
                                  style={{ cursor: "pointer", float: "right" }}
                                  to="ContactMessages"
                                >
                                  View Previous Messages
                                </Link>
                              </b>
                              <br /> <br />
                              <div id="snackbarSuccess">
                                Some text some message..
                              </div> */}

                              <div className="row">
                                <div className="col-lg-3  "></div>

                                <div className="col-lg-6  ">
                                  <textarea
                                    type="text"
                                    className="form"
                                    id="CSubject"
                                    name="CSubject"
                                    placeholder="Subject"
                                    onChange={this.handleChange}
                                  />
                                  {/* {CSubjectErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                        position: "absolute",
                                      }}
                                    >
                                      {CSubjectErr}
                                    </div>
                                  )}{" "} */}

                                  {!CSubjectErr ? (
                                    <span style={{color:'rgb(162, 157, 157)'}}>Subject</span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {CSubjectErr}
                                          </div>
                                        )}
                                  <span className="underline" />
                                </div>
                                <div className="col-lg-3  "></div>
                              </div>

                              <div className="row">
                                <div className="col-lg-3  "></div>
                                <div className="col-lg-6">
                                  <textarea
                                    className="form"
                                    name="CMsg"
                                    Id="CMsg"
                                    placeholder="Message"
                                    onChange={this.handleChange}
                                  />
                                  {/* {CMsgErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                        position: "absolute",
                                      }}
                                    >
                                      {CMsgErr}
                                    </div>
                                  )} */}
                                  {!CMsgErr ? (
                                    <span style={{color:'rgb(162, 157, 157)'}}>Message</span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {CMsgErr}
                                          </div>
                                        )}
                                  <span className="underline" />
                                </div>
                                <div className="col-lg-3  "></div>
                              </div>

                              <div className="row">
                                <div className="col-lg-3  "></div>
                                <div className="col-lg-6 ">
                                  <input
                                    type="file"
                                    name="Image"
                                    onFocus={(e) => (e.target.type = "file")}
                                    className="form"
                                    id=" "
                                    placeholder="Attachments"
                                    multiple
                                    onChange={this.handleChange}
                                    accept=".jpg, .jpeg, .pdf, .xlsx, .xls, .mp4, .avi, .mov"
                                  />
                                   {!ImageErr ? (
                                    <span style={{color:'rgb(162, 157, 157)',wordBreak:"break-word"}}>Upload Related Files&nbsp;(.jpg/.jpeg./.png/.mp4/.xlsx/.pdf)</span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {ImageErr}
                                          </div>
                                        )}
                                  <span className="underline" />
                                </div>
                                <div className="col-lg-3  "></div>
                              </div>
                              <div className="col-lg-12">
                                      <div className="row  justify-content-center" style={{marginTop:'20px'}}>
                                        <button
                                          type="submit"
                                 
                                  className="but"
                                          style={{
                                            height: "39.5px",
                                            width: "83.36px",
                                          }}
                                        >
                                          Send
                                        </button>
                                        <button
                                          type="reset"
                                          className=" btn-Secondary"
                                          style={{
                                            marginLeft: "1%",
                                            height: "39.5px",
                                          }}
                                          onClick={() =>
                                            this.setState({ formErrors: "" })
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                              {/* <div className="row">
                                <div className="col-lg-4"></div>
                                <div
                                  className="col-lg-4"
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <button
                                    type="submit"
                                    className="but"
                                    style={{ width: "120.36px" }}
                                  >
                                    Send
                                  </button>
                                </div>
                                <div className="col-lg-4"></div>
                              </div> */}
                            </div>
                          </form>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
