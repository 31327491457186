import { React, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

const Filterpopstatustopic = ({ data, FilteredData, TogglePop }) => {
  const [Alpha, SetAlpha] = useState([
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ]);
  const [TapFilterValue, setTapFilterValue] = useState("");

  const SearchFilter = (e) => {
    // console.log("bjdfjkdfb", data);
    const filteredData = data.filter((obj) =>
      Object.keys(obj).some((key) =>
        String(obj[key]).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    FilteredData(filteredData);
  };

  const TapFiltering = (v, i) => {
    // this.setState({ Learn : this.state.Learning });

    setTapFilterValue(v);

    const filteredData = data
      // .filter((obj) => !Object.values(obj).every((value) => value === null))
      .filter((item) =>
        item.trainingitem.charAt(0).toLowerCase().includes(v.toLowerCase())
      );
    // console.log("vvvvvvvvvvvvvv", filteredData);
    FilteredData(filteredData);
  };

  return (
    <div className="popSearch" style={{ width: "92%", height: "105%" }}>
      <div
        className="popSearchinnernormal"
        style={{ width: "29%", height: "19%", marginTop: "0%" }}
      >
        <div className="popSearchinnertitle" id="popSearchinnernormal">
          <button
            id="close"
            style={{
              float: "right",
              backgroundColor: "#a0a3a305",
              color: "#797070",
              border: "1px solid #a3a5a505",
              marginTop: "-20px",
              marginRight: "-20px",
            }}
            onClick={() => TogglePop()}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="form-group has-search">
          <span
            className="fa fa-search form-control-feedback"
            id="search-icon"
          ></span>
          <input
            className="Searchbox"
            placeholder="Search"
            style={{ float: "right" }}
            onKeyUp={SearchFilter}
          />
          {/* <input type="text" className="Searchbox" placeholder="Search" onKeyUp={SearchFilter}/> */}
        </div>

        {/* <div className="input-group">
    <input type="text" className="form-control" placeholder="Search"  onKeyUp={SearchFilter}/>
    <div className="input-group-append">
      <button className="btn btn-secondary" type="button">
        <i className="fa fa-search"></i>
      </button>
    </div>
  </div> */}
        {/* <input
          className="Searchbox"placeholder="Search"style={{ float:"right" }}onKeyUp={SearchFilter}/> */}

        {Alpha.map((e, i) => (
          <span
            style={
              TapFilterValue === e
                ? {
                    color: "#8662ee",
                    fontSize: "17px",
                    borderBottom: "3px solid #8662ee",
                    fontWeight: "20px",
                    textAlign: "center",
                  }
                : { color: "black", fontSize: "17px", textAlign: "center" }
            }
            onClick={() => TapFiltering(e, i)}
          >
            &nbsp; {e} &nbsp;
          </span>
        ))}
      </div>
    </div>
  );
};
export default Filterpopstatustopic;
