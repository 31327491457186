import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";

const EditPreAnnouncement = (props) => {
  const [formErrors, setFormErrors] = useState({});
  const [TitleErr, setTitleErr] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);

    fetch(`${BASE_URLs}/Announcement/EditPreAnnouncement`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === "Success") {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          document.getElementById("EditAnnpop").style.display = "none";
          setTimeout(function () {
            document.getElementById("close").click();
          }, 4000);
        }
      });
  };

  const handleChange = () => {
    var Title = document.getElementById("title").value;
    var Description = document.getElementById("desc").value;
    var Category = document.getElementById("category").value;
    if (!Title) {
      document.getElementById('TitleErr').innerHTML = "Title is required"
    }
    else {
      document.getElementById('TitleErr').innerHTML = ""
    }
    if (!Description) {
      document.getElementById('DescErr').innerHTML = "Description is required"
    }
    else {
      document.getElementById('DescErr').innerHTML = ""
    }
    if (!Category) {
      document.getElementById('CatgErr').innerHTML = "Category is required"
    }
    else {
      document.getElementById('CatgErr').innerHTML = ""
    }
  };

  const w = window.innerWidth;
  const { BusinessUnit } = formErrors;

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="pop">
        <div
          className={
            w > 768
              ? "popinnernormal AnnoneFieldPop"
              : "popinnernormal oneFieldPopMobile"
          }
          style={{ padding: "1%" }}
          id="EditAnnpop"
        >
          <button
            id="close"
            style={{
              float: "right",
              backgroundColor: "#a0a3a305",
              color: "#797070",
              border: "1px solid #a3a5a505",
            }}
            onClick={props.close}
          >
            <CloseIcon />
          </button><br />
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="container">
              {/* <b>
                                {" "}
                                <Link
                                  style={{ cursor: "pointer", float: "right" }}
                                  to="AnnouncementPreview"
                                >
                                  View Previous Announcements
                                </Link>
                              </b>
                              <br /> <br /> */}
              {/* <center style={{fontSize:'15px',color:'#5035ce'}}>Create New Announcements</center>
                              <br /> */}
              <div
                className="row"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                
                }}
              >
                <div className="col-lg-5 input-effect">
                  <input
                    type="hidden"
                    name="CreatedDate"
                    defaultValue={props.values.date}
                  />
                  <input
                    type="hidden"
                    name="UserId"
                    defaultValue={props.values.userid}
                  />
                  <textarea
                    className="form"
                    defaultValue={props.values.title}
                    id="title"
                    name="title"
                    type="text"
                    onChange={handleChange}
                    placeholder=""
                  // maxLength={30}
                  />
                  {/* {BusinessUnit == null ? (
                  <span style={{ color: "#a29d9d", float: "left" }}>
                    {" "}
                    Title<span style={{ color: "red", float: "left" }}></span>
                  </span>
                ) : (
                  <span
                    style={{ color: "red", float: "left" }}
                    className={BusinessUnit ? " showError" : ""}
                  >
                    {BusinessUnit}
                  </span>
                )} */}

                  <span style={{ color: 'rgb(162, 157, 157)', float: "left" }}>Subject</span>
                  <br />

                  <span id="TitleErr" style={{ color: 'red', float: "left" }}></span>
                  <span className="underline" />


                </div>
                <div className="col-lg-5 input-effect">
                <input
                    type="hidden"
                    name="CreatedDate"
                    defaultValue={props.values.date}
                  />
                  <input
                    type="hidden"
                    name="UserId"
                    defaultValue={props.values.userid}
                  />
                  <textarea
                    className="form"
                    defaultValue={props.values.message}
                    id="desc"
                    name="message"
                    type="text"
                    onChange={handleChange}
                    placeholder=""
                  // maxLength={30}
                  />

                  <span style={{ color: 'rgb(162, 157, 157)', float: "left" }}>Description</span>
                  <br />

                  <span id="DescErr" style={{ color: 'red', float: "left" }}></span>

                  <span className="underline" />

                </div>
                <div className="col-lg-5 input-effect">
                <input
                    type="hidden"
                    name="CreatedDate"
                    defaultValue={props.values.date}
                  />
                  <input
                    type="hidden"
                    name="UserId"
                    defaultValue={props.values.userid}
                  />
                  <select
                    className="form selectleftalign"
                    defaultValue={props.values.category}
                    id="category"
                    name="category"
                  // style={{marginLeft:'-3px'}}
                  >
                    <option value="">Select</option>
                    <option value="General">
                      General
                    </option>
                    <option value="Software Updates">
                      Software Updates
                    </option>
                    <option value="HR">HR </option>
                    <option value="Company">Company</option>
                    <option value="Foundations">
                      Foundations
                    </option>
                    <option value="Events">Events</option>
                  </select>
                  {/* {BusinessUnit == null ? (
                  <span style={{ color: "#a29d9d", float: "left" }}>
                    {" "}
                    Title<span style={{ color: "red", float: "left" }}></span>
                  </span>
                ) : (
                  <span
                    style={{ color: "red", float: "left" }}
                    className={BusinessUnit ? " showError" : ""}
                  >
                    {BusinessUnit}
                  </span>
                )} */}

                  <span style={{ color: 'rgb(162, 157, 157)', float: "left" }}>Category</span>
                  <br />

                  <span id="CatgErr" style={{ color: 'red', float: "left" }}></span>

                  <span className="underline" />

                </div>

                <div className="col-lg-5">
                <input
                    type="hidden"
                    name="CreatedDate"
                    defaultValue={props.values.date}
                  />
                  <input
                    type="hidden"
                    name="UserId"
                    defaultValue={props.values.userid}
                  />
                  <input
                    type="hidden"
                    name="oldimage"
                    defaultValue={
                      props.values.image
                    }
                  />
                  <input
                    className="form"
                    defaultValue={props.values.image}
                    id="image"
                    name="image"
                    onFocus={(e) => (e.target.type = "file")}
                    onChange={handleChange}
                    placeholder=""
                    multiple
                  />

                  <span style={{ color: 'rgb(162, 157, 157)', float: "left" }}>Choose Related files</span>
                  <span className="underline" />

                </div>
              </div>
              {/* <div
                className="row"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width:'98%'
                }}
              >
                <div className="col-lg-5 input-effect">
                  <input
                    type="hidden"
                    name="Id"
                    defaultValue={props.values.id}
                  />
                  <select
                    className="form selectleftalign"
                    defaultValue={props.values.category}
                    id="category"
                    name="category"
                  // style={{marginLeft:'-3px'}}
                  >
                    <option value="">Select</option>
                    <option value="General">
                      General
                    </option>
                    <option value="Software Updates">
                      Software Updates
                    </option>
                    <option value="HR">HR </option>
                    <option value="Company">Company</option>
                    <option value="Foundations">
                      Foundations
                    </option>
                    <option value="Events">Events</option>
                  </select>
                 
                  <span style={{ color: 'rgb(162, 157, 157)', float: "left" }}>Category</span>
                  <br />

                  <span id="CatgErr" style={{ color: 'red', float: "left" }}></span>

                  <span className="underline" />

                </div>

                <div className="col-lg-5">
                  <input
                    type="hidden"
                    name="Id"
                    defaultValue={props.values.id}
                  />
                  <input
                    type="hidden"
                    name="oldimage"
                    defaultValue={
                      props.values.image
                    }
                  />
                  <input
                    className="form"
                    defaultValue={props.values.image}
                    id="image"
                    name="image"
                    onFocus={(e) => (e.target.type = "file")}
                    onChange={handleChange}
                    placeholder=""
                    multiple
                  // maxLength={30}
                  />

                  <span style={{ color: 'rgb(162, 157, 157)', float: "left" }}>Choose Related files</span>
                  <span className="underline" />

                </div>
              </div> */}
            </div>


            <div className="col-lg-12">
              <div className="row  justify-content-center" >
                <button
                  type="submit"

                  className="but"
                  style={{
                    height: "39.5px",
                    width: "83.36px",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditPreAnnouncement;