import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base";
// import AdminSkillPop from '../../Common/AdminSkillPop';
export default class AdminNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Notify: [],
      Active: [],
      InActive: [],
      Pending: [],
      All: [],
      allNotify: [],
      Notification: [],
      User: [],
      renewel: [],
      CategoryModel: false,
      Unreadcount: "0",
      Request: [],
      NoRequest: [],
      Organization: "",
      Message: [],
      messages: [],
    };
    this.Noticount = this.Noticount.bind(this);
    this.clickNoticount = this.clickNoticount.bind(this);
  }

  Noticount = (Id) => {
    fetch(`${BASE_URLs}/Notification/Messagecount`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Seen: true,
        Notification_Id: Id,
      }),
    });

    this.GetAdNotification();
    if (Id !== null) {
      var page = {
        pathname: "/Chatbox",
        state: {
          Id: Id,
        },
      };

      this.props.history.push(page);
    }
  };

  clickNoticount = (Id) => {
    fetch(`${BASE_URLs}/Notification/Messagecount`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Seen: 1,
        Notification_Id: Id,
      }),
    });

    this.GetAdNotification();
    if (Id !== null) {
      var page = {
        pathname: "/Chatbox",
        state: {
          Id: Id,
        },
      };

      this.props.history.push(page);
    }
  };

  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    fetch(`${BASE_URLs}/AddOrganization/GetOrg`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          User: data,
        });
        // console.log("newdata", data);
      });
  }

  renewel() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/Renewel`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ renewel: data });
      });
  }

  detailData(company_Id) {
    // localStorage.setItem("status","Active")
    var page = {
      pathname: "/OrganizationDetail",
      state: {
        Id: company_Id,
        Status: "Pending",
      },
    };

    this.props.history.push(page);
  }
  detailData1(company_Id) {
    localStorage.setItem("Id", company_Id);
    localStorage.setItem("status", "Inactive");
  }

  GetAdNotification() {
    const { Notification } = this.state;
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Notification/GetMessageAdmin`, options)
      .then((response) => response.json())
      .then((data) => {
        //  this.setState({ Notification: data[0].message });

        //  // console.log(data);
        //  // console.log("Message",data);

        this.setState({
          // Message: data.filter(Tasks => Tasks.Seen === true)
          Message: data,
        });

        // console.log(data);
        // // console.log("NotificationID",data[0].id);
        // console.log("Messages", this.state.Message);
        //  this.setState({ NotifyCount: data.filter(Tasks => (Tasks.seen === true)).length
        //  });
      });
  }

  markMessageAsRead = async (id, email) => {
    await fetch(`${BASE_URLs}/Notification/MsgcountSeenAdmin`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Seen: true,
        Id: id,
        Email: "",
        Message: "",
        SenderId: "",
        Type: "",
        UserName: "",
      }),
    });

    const updatedMessages = this.state.Message.map((message) =>
      message.id === id ? { ...message, Seen: true } : message
    );
    this.setState({ Message: updatedMessages });
  };

  RedirectSkill() {
    this.props.history.push("/MasterSkill");
  }
  RedirectEdu() {
    this.props.history.push("/Education");
  }
  CategoryModel = () => {
    this.setState((prevState) => ({
      CategoryModel: !prevState.CategoryModel,
    }));
  };
  getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  componentDidMount() {
    this.GetAdNotification();
    // this.GetallNotify();+
    var crole = this.getCookie("" + JSON.parse(localStorage.uname) + "role");

    var corg = this.getCookie(
      "" + JSON.parse(localStorage.uname) + "organization"
    );

    this.setState({
      User: [{ role: crole, organization: parseInt(corg) }],
      Organization: corg,
    });
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  render() {
    const { Notify } = this.state;
    const { Notification, Request, NoRequest, NotifyCount, Message, messages } =
      this.state;

    return (
      <>
        <SideBar active={window.location.pathname} />
        {/* {this.state.CategoryModel ? <AdminSkillPop id="Success" close={() => this.CategoryModel()} /> : null} */}
        {/* start */}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                        <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title">Notifications </h4>
                        </div>

                        <div
                          className="j-tabs-container md-tabs"
                          style={{ height: "40vh", overflow: "scroll" }}
                        >
                          <input
                            id="tab1"
                            type="radio"
                            name="tabs"
                            defaultChecked
                          />
                          <label
                            id="supertab"
                            className="j-tabs-label"
                            htmlFor="tab1"
                            title="Info"
                          >
                            {this.state.Message.length === 0 ? (
                              <span>&nbsp;&nbsp;No Message&nbsp;&nbsp;</span>
                            ) : (
                              <span>&nbsp;&nbsp;Message&nbsp;&nbsp;</span>
                            )}
                          </label>

                          {this.state.Message.map((message) => (
                            <div
                              key={message.id}
                              onClick={() => this.markMessageAsRead(message.id)}
                              style={{ padding: "5px", margin: "5px" }}
                            >
                              <Link to="/UserChatPopUp">
                                <span>
                                  You have New Message {message.document_Path}{" "}
                                  {message.message} {message.picture_Path} from
                                  "{message.displayName}"
                                </span>
                              </Link>
                            </div>
                          ))}

                          {/* <div>
      {messages.map(message => (
        <div key={message.id}>
          <span>{message.message}</span>
          {!message.Seen && (
            <button onClick={() => this.markAsRead(message.id)}>Mark as Read</button>
          )}
        </div>
      ))}
    </div> */}
                          {/* {Notification.map((notify)=>{
    <p>{notify.message}</p>
})} */}
                          {/* <input value={Notification}>{Notification}</input> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End */}
      </>
    );
  }
}
