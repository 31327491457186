import React, { Component } from "react";
import AddTrainerpop from "../../Common/AddTrainerpop";
import EditTrainerpop from "../../Common/EditTrainerpop";
import SideBar from "../../Common/Sidebar";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import { OutTable } from "react-excel-renderer";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TrainerExl from "../../Assets/Files/TrainerExl.xlsx";
import FilterPop from "../../Common/FilterPop";
import Filterpopstatustopic from "../../Common/Filterpopstatustopic";

import CloseIcon from "@material-ui/icons/Close";
import { get } from "jquery";
import { BASE_URLs } from "../../Config/Base";
var XLSX = require("xlsx");
export default class TrainerSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      DepartmentName: "",
      DepartModel: false,
      comlogo: [],
      Department: [],
      Depart: [],
      topic: [],
      DepartmentGrouped: [],
      DepartGrouped: [],
      formErrors: {},
      EditModel: false,
      EditDesigntionValues: [],
      Notification: [],
      rows: [],
      cols: [],
      trainerid1: "",
      trainerid: [],
      Filterpopstatus: false,
      Filterpopstatus1: false,
      Filterpopstatusadd: false,
      Trainers: [],
      design: "",
      AllRoles: [],
      DesignSearch: [],
      Search: [],
      dept: "",
      isOpen: false,
      selectedOptions: [],
      deptValue: [],
      Id: [],
      firstName: [],
      selectedCheckboxValues: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDesign = this.handleChangeDesign.bind(this);
  }

  DeleteTopicButton = (id, desigId) => {
    // console.log("------------", id, desigId);
    const data = new FormData();
    data.append("TrainingTopicId", desigId);
    data.append("UserId", id);

    for (var pair of data.entries()) {
      // console.log(pair[0] + ", " + pair[1]);
    }

    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}/Trainer/TrainerTopicDelete`, options)
        .then((response) => response.json())
        .then((data) => {
          this.GetDepartment();
          toast.success(data.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  changeState = (storingUserID) => {
    // console.log("storingUserID", this.state.storingUserID);

    // if (this.state.storingUserID !== "undefined") {
    let url = `${BASE_URLs}/Trainer/UpdateTrainer?TrainerID=` + storingUserID;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data", data);
        toast.success(data.message, {
          position: "top-center",
          autoClose: 2200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        this.GetDepartment();
      });
    // }
  };
  checkChangeDesignation = (event) => {
    // this.handlecheckbox();
    const { name, checked, value } = event.target;

    // value1.indexOf(name) === -1

    //   ? value1.push(name)

    //   : value1.splice(value1.indexOf(name), 1);

    this.setState((prevState) => {
      const selectedCheckboxValues = checked
        ? [...prevState.selectedCheckboxValues, value]
        : prevState.selectedCheckboxValues.filter((val) => val !== value);

      // console.log("selectedCheckboxValues", selectedCheckboxValues);

      return { selectedCheckboxValues };
    });
  };
  handleSelectedRows = () => {
    // Do something with this.state.selectedRows

    // console.log(this.state.selectedRows);
  };

  fetchData = async (firstname, TrainerId, TopicId) => {
    // console.log("TrainerId and Topic", TrainerId);

    // console.log("TrainerId and Topic", TrainerId);

    // console.log("TrainerId and Topic", TopicId);

    const data = new FormData();
    if (document.getElementById("selectedall").checked == true) {
      // document.getElementById("checkedall").checked = false;
      // console.log("ggjgj", this.state.Search);
      //  alert("false");
      const data = new FormData();
      for (var i = 0; i < this.state.Search.length; i++) {
        // console.log("ggjgj====", this.state.Search[i].training_Id);
        data.append("TrainingId", this.state.Search[i].training_Id);
        data.append("FirstName", this.state.DepartmentGrouped[0].trainerName);
        data.append("TrainerId", this.state.DepartmentGrouped[0].TrainerUserId);
      }
      // console.log("ggjgj====", this.state.DepartmentGrouped[0].trainerName);
      // console.log("ggjgj====", this.state.DepartmentGrouped[0].TrainerUserId);

      for (const [key, value] of data.entries()) {
        // console.log("FormData", key, value);
      }
      const url = `${BASE_URLs}/Training/AssignedTrainers`;

      //// console.log(url);
      // const url = `${BASE_URLs}/Training/AssignedTrainers?FirstName=${firstname}&TrainingId=${this.props.location.state.training_Id}`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      this.GetDepartment();

      this.setState({ isOpen: false });
    } else {
      let dd = this.state.selectedCheckboxValues.forEach((e) => {
        let split = e.split("$$$");
        // [5,''vjvd487348543, 'Arunachalam']
        //[]
        // console.log("hi", split[0]);
        // console.log("hi", split[1]);
        // console.log("hi", split[2]);
        data.append("FirstName", split[1]);
        data.append("TrainerId", split[2]);
        data.append("TrainingId", split[0]);
      });

      for (const [key, value] of data.entries()) {
        // console.log("FormData", key, value);
      }
      var is_selected = [];

      const url = `${BASE_URLs}/Training/AssignedTrainers`;

      // console.log(url);
      // const url = `${BASE_URLs}/Training/AssignedTrainers?FirstName=${firstname}&TrainingId=${this.props.location.state.training_Id}`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      this.GetDepartment();

      this.setState({ isOpen: false });
    }
  };

  GetAllRole() {
    let url = `${BASE_URLs}/Designation/GetMasterDesignation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          AllRoles: data,
        });
        // console.log("gygygygy", data);
      });
  }

  openThePopup = (department, userId, trainerName) => {
    this.setState({ Id: userId });
    // console.log("fvfvfvf", userId);

    this.setState({ firstName: trainerName });
    // console.log("fvfvfvf", trainerName);

    this.setState({ isOpen: true });
    this.GetTrainingItems(department, userId);
    this.setState({ Search: [] });
  };

  closePopup = () => {
    this.setState({ design: "" });
    this.setState({ dept: "" });
    this.setState({ isOpen: false });
  };

  GetTrainingItems = (Department, userId) => {
    const checking = Department;

    // console.log("passdept", checking);
    let url =
      `${BASE_URLs}/Training/GetTrainingItems?TrainingId=` +
      //  Department +
      //  "&TrainingId=" +
      userId;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data", data);
        this.setState({
          Search: data,
        });
        // // console.log("89898989", data[0].trainingitem);
        for (var i = 0; i < data.length; i++) {
          this.setState({ topic: data[i].trainingitem });
          // console.log("ropic", this.state.topic);
        }
      });
  };

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("DepartSheet");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  handleChange(event) {
    // console.log("dept", event.target.value);
    this.setState({ dept: event.target.value });
    // console.log("Clicked", event.target.dept);
    // console.log("value", this.state.Id);
    var TrainingId = document.getElementById("trainer_UserID").value;

    var dd = this.state.Trainers.forEach((e) => {
      var split = e.userId;
      // [5,''vjvd487348543, 'Arunachalam']
      //[]
      this.setState({ trainerid1: split });

      // // console.log("hi",split[1])
      // // console.log("hi",split[2])
      // data.append("FirstName",split[1]);
      // data.append("TrainerId",split[2]);
      // data.append("TrainingId",split[0]);
    });
    // console.log("deptt==", this.state.dept);
    // if (event.target.dept == 0) {
    //   // console.log("clicked");
    //   window.location.reload();
    // }
    // alert("Before");
    // this.DefaultMenu();

    // this.setState({
    //   catval: event.target.value
    // })
    let url = `${BASE_URLs}/Training/GetDeptbased?Department=${event.target.value}&UserId=${this.state.Id}`;
    // "https://api.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=0" +
    // // this.state.Sort.value +
    // //"&Sort=" +
    // //  this.state.Years.value +
    // "&CategoryID=" +
    // event.target.value;

    // let user = JSON.parse(localStorage.getItem("user"));
    // const accessToken = user;
    // // console.log(accessToken);

    //// console.log("hi");
    //alert("After");
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },

      //body:JSON.stringify(data)
    })
      // alert("After0")
      .then((response) => response.json())
      .then((data) => {
        //   this.DefaultMenu();
        //alert(data);

        this.setState({
          Search: data,
        });
        // alert("After1");
        //  this.DefaultMenu();
        // console.log("Filter", data);
        for (var i = 0; i < data.length; i++) {
          this.setState({ topic: data[i].trainingitem });
          // console.log("ropic", this.state.topic);
        }
        // // console.log(dets.profile_dateOfAnniversary);
      });
  }

  handleChangeDesign(event) {
    // console.log("design", event.target.value);
    this.setState({ design: event.target.value });
    // console.log("Clicked", event.target.dept);
    // console.log("value", this.state.Trainers);
    var TrainingId = document.getElementById("trainer_UserID").value;

    var dd = this.state.Trainers.forEach((e) => {
      var split = e.userId;
      // [5,''vjvd487348543, 'Arunachalam']
      //[]
      this.setState({ trainerid1: split });

      // // console.log("hi",split[1])
      // // console.log("hi",split[2])
      // data.append("FirstName",split[1]);
      // data.append("TrainerId",split[2]);
      // data.append("TrainingId",split[0]);
    });
    // console.log("hi---------", this.state.dept);
    if (this.state.dept == "") {
      // console.log("yes empty");
      let url = `${BASE_URLs}/Training/Getdesignonlybased?Design=${event.target.value}&UserId=${this.state.Id}`;
      // "https://api.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=0" +
      // // this.state.Sort.value +
      // //"&Sort=" +
      // //  this.state.Years.value +
      // "&CategoryID=" +
      // event.target.value;

      // let user = JSON.parse(localStorage.getItem("user"));
      // const accessToken = user;
      // // console.log(accessToken);

      //// console.log("hi");
      //alert("After");
      fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },

        //body:JSON.stringify(data)
      })
        // alert("After0")
        .then((response) => response.json())
        .then((data) => {
          //   this.DefaultMenu();
          //alert(data);

          this.setState({
            Search: data,
          });
          // alert("After1");
          //  this.DefaultMenu();
          // console.log("Filter", data);
          for (var i = 0; i < data.length; i++) {
            this.setState({ topic: data[i].trainingitem });
            // console.log("ropic", this.state.topic);
          }
          // // console.log(dets.profile_dateOfAnniversary);
        });
    } else {
      // if (event.target.dept == 0) {
      //   // console.log("clicked");
      //   window.location.reload();
      // }
      // alert("Before");
      // this.DefaultMenu();

      // this.setState({
      //   catval: event.target.value
      // })
      let url = `${BASE_URLs}/Training/Getdesignbased?Department=${this.state.dept}&Design=${event.target.value}&UserId=${this.state.Id}`;
      // "https://api.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=0" +
      // // this.state.Sort.value +
      // //"&Sort=" +
      // //  this.state.Years.value +
      // "&CategoryID=" +
      // event.target.value;

      // let user = JSON.parse(localStorage.getItem("user"));
      // const accessToken = user;
      // // console.log(accessToken);

      //// console.log("hi");
      //alert("After");
      fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },

        //body:JSON.stringify(data)
      })
        // alert("After0")
        .then((response) => response.json())
        .then((data) => {
          //   this.DefaultMenu();
          //alert(data);

          this.setState({
            Search: data,
          });
          // alert("After1");
          //  this.DefaultMenu();
          // console.log("Filter", data);
          for (var i = 0; i < data.length; i++) {
            this.setState({ topic: data[i].trainingitem });
            // console.log("ropic", this.state.topic);
          }
          // // console.log(dets.profile_dateOfAnniversary);
        });
    }
  }

  GetAllDepartment() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    // fetch('${BASE_URLs}/Team/GetTeam', options)
    fetch(`${BASE_URLs}/Department/DepartmentGet`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });
        // console.log("-----------------------------", filteredData);
        this.setState({
          department: filteredData,
        });
      });
  }
  GetDepartment() {
    // let url = `${BASE_URLs}/Department/DepartmentGet";
    let url = `${BASE_URLs}/Trainer/GetAllTrainers`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("_-------------------------------", data);
        let GroupedbyName = data
          .sort((a, b) => {
            return a.trainerName < b.trainerName ? -1 : 1;
          })
          .reduce((r, e) => {
            const { department, trainerName, role, trainersince, active } = e;
            // let trainerName = e.trainerName;

            if (!r[trainerName])
              r[trainerName] = {
                trainerName,
                department,
                role,
                trainersince,
                active,
                record: [e],
              };
            else r[trainerName].record.push(e);

            return r;
          }, {});

        let TopicMerged = Object.values(GroupedbyName).map((e) => {
          return {
            trainerName: e.trainerName,
            department: e.department,
            role: e.role,
            trainersince: e.trainersince,
            TrainerUserId: e.record[0].trainer_UserId,
            active: e.active,

            record:
              e.record[0].trainingTopics === null
                ? []
                : e.record[0].trainingTopics.split(",,").map((ee) => {
                    return {
                      TopicId: ee.split("$$$")[1],
                      TraingItem: ee.split("$$$")[0],
                      Description: ee.split("$$$")[2],
                      Department: ee.split("$$$")[3],
                      Duration: ee.split("$$$")[4],
                      TrainerUserId: e.record[0].trainer_UserId,
                    };
                  }),
          };
        });

        this.setState({
          Department: data.sort((a, b) => {
            return a.trainerName < b.trainerName ? -1 : 1;
          }),
          Depart: data.sort((a, b) => {
            // console.log("datasory========", a.trainerName, b.trainerName);
            return a.trainerName < b.trainerName ? -1 : 1;
          }),
          DepartmentGrouped: TopicMerged,
          DepartGrouped: TopicMerged,
        });

        //
      });
  }

  DepartModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("trainer_UserID")) {
      var checkboxes = document.getElementsByName("trainer_UserID");
      // console.log("deete", checkboxes);
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    this.setState((prevState) => ({
      DepartModel: !prevState.DepartModel,
    }));
    this.GetDepartment();
  };
  EditModel = (e, fn) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("trainer_UserID")) {
      var checkboxes = document.getElementsByName("trainer_UserID");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditDesigntionValues: {
        id: e,
        DepartmentName: fn,
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));
    this.GetDepartment();
  };

  componentDidMount() {
    this.GetOrgLogo();
    this.GetDepartment();
    this.GetAllDepartment();
    this.GetAllRole();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  // handleChange = (e) => {
  //   const { name, value } = e.target;
  //   this.setState({ [name]: value });
  // };
  handleFormValidation() {
    const {
      FirstName,
      LastName,
      Email,
      Mobile,
      Department,
      Designation,
      RoleName,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required";
    }
    if (!LastName) {
      formIsValid = false;
      formErrors["LastNameErr"] = "Last Name is required";
    }
    if (!Email) {
      formIsValid = false;
      formErrors["EmailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["EmailErr"] = "Invalid Email id.";
    }
    if (!Mobile) {
      formIsValid = false;
      formErrors["MobileErr"] = "Mobile is required";
    }
    // else if (!Mobile >= 10) {
    //   formIsValid = false;
    //   formErrors["MobileErr"] = "Invalid Mobile number.";
    // }
    else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
      if (!mobPattern.test(Mobile)) {
        formIsValid = false;
        formErrors["MobileErr"] = "Invalid phone number.";
      }
    }
    if (!Department) {
      formIsValid = false;
      formErrors["departmentErr"] = "department is required";
    }
    if (!Designation) {
      formIsValid = false;
      formErrors["designationErr"] = "designation is required";
    }
    if (!RoleName) {
      formIsValid = false;
      formErrors["rolesErr"] = "Role is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}/Trainer/TrainerExcel`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert("0  Records  inserted ");
          } else if (resp === 0) {
            alert("Records added successfully");
          } else {
            alert(+resp + " Records not inserted ");
          }
          this.GetDepartment();
          this.setState({ initialData: undefined });
        });
      var file = document.getElementById("files");
      var emptyFile = document.createElement("input");
      emptyFile.type = "file";
      file.files = emptyFile.files;
    } catch (error) {}
    this.GetDepartment();
  };

  handleUpload = (event) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("trainer_UserID")) {
      var checkboxes = document.getElementsByName("trainer_UserID");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);
    if (isAllowed) {
      const file = event.target.files[0];
      readFile(file).then((readedData) =>
        this.setState({ initialData: readedData })
      );
      /* file upload logic goes here... */
      return true;
    } else {
      alert("Invalid File Type.");
      return false;
    }
  };

  Noticount = async (Id) => {
    await fetch(`${BASE_URLs}/Notification/Noticount`, {
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Notification_Id: Id,
      }),
    }).then((result) => {
      result.json().then((resp) => {
        localStorage.setItem("success", JSON.stringify(resp.status));
        var a = localStorage.getItem("success");
        var b = JSON.parse(a);
        if (b === "Success") {
          window.location.href = "/Organization";
          localStorage.removeItem("success");
        }
      });
    });
  };

  Handledepartment() {
    var department = document.getElementById("department").value;

    if (department === "others") {
      document.getElementById("departmenttext").value = "";
      document.getElementById("departmenttext").type = "text";
    } else {
      document.getElementById("departmenttext").value = "None";
      document.getElementById("departmenttext").type = "hidden";
    }
  }

  checkAll(e) {
    var checkboxes = document.getElementsByName("trainer_UserID");
    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + "  selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      // document
      //   .getElementById("selectalldiv")
      //   .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;

      if (document.getElementById("selectall").checked) {
        msg.innerHTML = "<span> " + count + " selected </span> ";
      } else {
        msg.innerHTML = "<span> " + checkboxes.length + " selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }

  onKeyUp = (e) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("trainer_UserID")) {
      var checkboxes = document.getElementsByName("trainer_UserID");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    const Department = this.state.Depart.filter((item) =>
      item.department.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Department });
  };

  Multidelete = (event) => {
    event.preventDefault();
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    // if(document.getElementsByName('trainer_UserID')){
    // var checkboxes = document.getElementsByName('trainer_UserID');
    // for (var i = 0; i < checkboxes.length; i++) {
    //   checkboxes[i].checked = false;
    //   document.getElementById("delete").setAttribute('style', 'visibility: hidden;');
    //   document.getElementById("deletecount").setAttribute('style', 'visibility: hidden;');
    // }}
    const data = new FormData(event.target);

    if (
      window.confirm("Are you sure you want to delete all record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}/Trainer/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetDepartment();
          alert(resp.message);
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };

  checkedAll(e) {
    // alert("hi");

    var checkboxes = document.getElementsByName("design");

    var sa = document.getElementById("selectedall");

    let count = checkboxes.length;

    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;

        // document

        //   .getElementById("delete")

        //   .setAttribute("style", "visibility:visible ;");

        // document

        //   .getElementById("deletecount")

        //   .setAttribute(

        //     "style",

        //     "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"

        //   );

        // var msg = document.getElementById("deletecount");

        // msg.innerHTML = "<span> " + count + "  selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;

        document

          .getElementById("delete")

          .setAttribute("style", "visibility: hidden;");

        document

          .getElementById("deletecount")

          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  DeleteButton = (id) => {
    const data = new FormData();
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    // if(document.getElementsByName('trainer_UserID')){
    // var checkboxes = document.getElementsByName('trainer_UserID');
    // for (var i = 0; i < checkboxes.length; i++) {
    //   checkboxes[i].checked = false;
    //   document.getElementById("delete").setAttribute('style', 'visibility: hidden;');
    //   document.getElementById("deletecount").setAttribute('style', 'visibility: hidden;');
    // }}
    data.append("trainer_UserID", id);
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}/Department/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetDepartment();
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          this.GetDepartment();
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };
  render() {
    var username = JSON.parse(localStorage.uname);
    var filename = `O_${username}_Department`;

    const {
      Department,
      Filterpopstatus,
      Filterpopstatus1,
      DepartmentGrouped,
      Trainers,
      Search,
      DesignSearch,
      Filterpopstatusadd,
      isOpen,
    } = this.state;
    // console.log("======", DepartmentGrouped);
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {Filterpopstatus ? (
          <FilterPop
            data={this.state.Depart}
            FilteredData={(newValue) => {
              this.setState({ Department: newValue });
            }}
            TogglePop={(e) => {
              this.setState({ Filterpopstatus: !Filterpopstatus });
            }}
          />
        ) : null}
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: "left" }}>
                              <Link
                                to
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </Link>{" "}
                            </div>
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            {" "}
                            Trainers List{" "}
                          </h4>
                        </div>
                        {this.state.EditModel ? (
                          <EditTrainerpop
                            values={this.state.EditDesigntionValues}
                            close={() => this.EditModel()}
                          />
                        ) : null}
                        {this.state.DepartModel ? (
                          <AddTrainerpop close={() => this.DepartModel()} />
                        ) : null}

                        <div className="card-block">
                          <form
                            // style={{ marginTop: "20px" }}
                            onSubmit={this.handleSubmit}
                          >
                            <div className="">
                              <div className="row">
                                <div className="col-lg-2">
                                  <Link
                                    to="/AddTrainer"
                                    type="button"
                                    style={{
                                      width: "150px",
                                      background: "white",
                                    }}
                                    id="addRow"
                                    className="dropdown_item-2"
                                    className="btn "
                                  >
                                    Add{" "}
                                  </Link>
                                </div>
                                {/* <div className="col btnalign">
                                  <label
                                    htmlFor="files"
                                    className="btn"
                                    id="addBtn"
                                    name="Trainer_Info"
                                  >
                                    Import From Excel
                                  </label>
                                  <input
                                    id="files"
                                    type="file"
                                    accept=".xlsx"
                                    style={{ display: "none" }}
                                    onChange={this.handleUpload}
                                    name="Trainer_Info"
                                  />
                                </div> */}
                                <div className="col-lg-2">
                                  <button
                                    className="btn"
                                    type="button"
                                    onClick={() => this.exportXlsx(filename)}
                                  >
                                    Export to excel
                                  </button>
                                </div>

                                {/* <div
                                  className="col btnalign"
                                  clas
                                  style={{
                                    width: "100px",
                                  }}
                                >
                                  <a
                                    className="btn icofont icofont-download"
                                    type="button"
                                    href={TrainerExl}
                                  >
                                    &nbsp; Download
                                  </a>
                                </div> */}

                                {/* <div className="col btnalign">
                                  {/* <a href={TrainerExl} className=" download"  style={{padding:"2px 15px 2px 15px"}}><i title="Download Template" className="icofont icofont-cloud-download"></i></a> */}
                                {/* <a
                                    href={TrainerExl}
                                    className="download"
                                    style={{
                                      padding: "2px 15px",
                                      boxShadow:
                                        " -2px  -2px 16px -2px #5f7ae14a",
                                    }}
                                  >
                                    <i
                                      title="Download Template"
                                      className="icofont icofont-cloud-download"
                                    ></i>
                                  </a> */}
                                {/* </div> */}
                                <div className="col-lg-4"></div>
                                <div className="col-lg-4">
                                  <input
                                    className="Searchbox"
                                    placeholder="Search"
                                    onClick={() => {
                                      this.setState({ Filterpopstatus: true });
                                    }}
                                    //onKeyUp={this.myFunction}
                                    style={{ float: "right" }}
                                  />
                                </div>
                              </div>
                              {this.state.initialData !== undefined ? (
                                <ReactExcel
                                  initialData={this.state.initialData}
                                  onSheetUpdate={(currentSheet) =>
                                    this.setState({
                                      CurrentSheet: currentSheet,
                                    })
                                  }
                                  activeSheetClassName="active-sheet"
                                  reactExcelClassName="react-excel"
                                  name="Skills"
                                />
                              ) : null}

                              {this.state.initialData !== undefined ? (
                                <div className="savebtndiv">
                                  <button
                                    className="btn"
                                    id=""
                                    type="submit"
                                    style={{ width: "150px" }}
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : null}
                              <OutTable
                                data={this.state.rows}
                                columns={this.state.cols}
                                tableClassName="ExcelTable2007"
                                tableHeaderRowclassName="heading"
                              />
                            </div>
                          </form>
                        </div>

                        <div className="card-block2">
                          <form onSubmit={this.Multidelete}>
                            <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: "hidden" }}
                            >
                              <i
                                className="icofont icofont-delete-alt"
                                style={{ color: "darkgray" }}
                              ></i>
                            </button>
                            <div>
                              {" "}
                              <span
                                id="deletecount"
                                className="multitrash"
                                style={{
                                  visibility: "hidden",
                                  fontSize: "17px",
                                }}
                              ></span>
                            </div>
                            <div
                              id="selectalldiv"
                              style={{ visibility: "hidden" }}
                            ></div>

                            <div
                              className="dt-responsive table-responsive table-res"
                              // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                              {this.state.Department.length === 0 ? null : (
                                <table
                                  id="DepSheet"
                                  className="table nowrap"
                                  style={{ maxWidth: "100%" }}
                                >
                                  {/* <tr style={{ verticalAlign: "middle" }}>
                                    <th className="gg">
                                      <input
                                        type="checkbox"
                                        id="selectall"
                                        onClick={() => {
                                          this.checkAll(this);
                                        }}
                                      />
                                    </th>
                                    Select All
                                  </tr> */}
                                  {DepartmentGrouped.map(
                                    (DepartmentGrouped, i) => (
                                      <>
                                        <tr
                                          style={{ verticalAlign: "middle" }}
                                          className="designationMenu"
                                        >
                                          <th
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            {/* <input
                                              type="checkbox"
                                              name="trainer_UserID"
                                              id="trainer_UserID"
                                              onChange={this.handlecheckbox}
                                              defaultValue={
                                                DepartmentGrouped.TrainerUserId
                                              }
                                            /> */}
                                          </th>

                                          <th
                                            className="gg"
                                            style={{
                                              verticalAlign: "middle",
                                              textAlign: "left",
                                            }}
                                          >
                                            Name :{" "}
                                            {DepartmentGrouped.trainerName}
                                          </th>
                                          <th
                                            className="gg"
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            Department :{" "}
                                            {DepartmentGrouped.department}
                                          </th>
                                          <th
                                            className="gg"
                                            style={{
                                              textAlign: "left",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            {DepartmentGrouped.role}
                                          </th>
                                          <th
                                            className="gg"
                                            style={{
                                              textAlign: "left",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            {/* {DepartmentGrouped.trainersince.slice(
                                              0,
                                              10
                                            )} */}
                                          </th>
                                          <th
                                            className="gg"
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                          ></th>

                                          {DepartmentGrouped.active ? (
                                            <th
                                              className="gg"
                                              style={{
                                                verticalAlign: "middle",
                                                color: "#80e939",
                                              }}
                                            >
                                              Active{" "}
                                            </th>
                                          ) : (
                                            <th
                                              className="gg"
                                              style={{
                                                verticalAlign: "middle",
                                                color: "#ea5a5a",
                                              }}
                                            >
                                              Deactive
                                            </th>
                                          )}

                                          <th
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                            className="gg"
                                          >
                                            &nbsp;&nbsp;&nbsp;
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                checked={
                                                  DepartmentGrouped.active
                                                    ? true
                                                    : false
                                                }
                                                onClick={() =>
                                                  this.changeState(
                                                    DepartmentGrouped.TrainerUserId
                                                  )
                                                }
                                              />
                                              <span className="slider round" />
                                            </label>
                                          </th>
                                          {/* <th></th> */}
                                        </tr>
                                        {/* {DepartmentGrouped.record.length !==
                                        0 ? ( */}
                                        <tr className="designationMenu1">
                                          {/* <th>Trainer's Name</th> */}
                                          <th></th>
                                          <th
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            Department
                                          </th>
                                          <th
                                            style={{
                                              textAlign: "left",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            Topic
                                          </th>
                                          <th
                                            style={{
                                              textAlign: "left",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            Description
                                          </th>
                                          <th
                                            style={{
                                              textAlign: "left",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            Duration
                                          </th>
                                          <th></th>
                                          <th
                                            style={{
                                              textAlign: "left",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            {(() => {
                                              if (
                                                DepartmentGrouped.active == true
                                              ) {
                                                return (
                                                  <div className="col-sm-6">
                                                    <button
                                                      type="button"
                                                      className="button-30 sk-edit"
                                                      onClick={() =>
                                                        this.openThePopup(
                                                          DepartmentGrouped.department,
                                                          DepartmentGrouped.TrainerUserId,
                                                          DepartmentGrouped.trainerName
                                                        )
                                                      }
                                                    >
                                                      {/* <i
                                                        className="icofont icofont-plus"
                                                        aria-hidden="true"
                                                      ></i> */}{" "}
                                                      <b> &nbsp;Add Topic</b>
                                                    </button>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="col-sm-6">
                                                    <button
                                                      type="button"
                                                      className="button-30 sk-edit"
                                                      disabled
                                                      onClick={() =>
                                                        this.openThePopup(
                                                          DepartmentGrouped.department,
                                                          DepartmentGrouped.TrainerUserId,
                                                          DepartmentGrouped.trainerName
                                                        )
                                                      }
                                                    >
                                                      <b> Add Topic</b>
                                                    </button>
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </th>
                                          {/* <th>Active Training Session</th> */}
                                          {/* <th>Assign topics</th> */}

                                          <th
                                            style={{ textAlign: "center" }}
                                          ></th>
                                        </tr>
                                        {/*   ) : null} */}
                                        {DepartmentGrouped.record.map(
                                          (Department, i) => (
                                            // <tbody
                                            //   style={{ textAlign: "center" }}
                                            // >
                                            <tr style={{ border: "hidden" }}>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {Department.Department}
                                              </td>
                                              <td>{Department.TraingItem}</td>

                                              <td>{Department.Description}</td>
                                              <td>{Department.Duration}</td>
                                              <td></td>
                                              <td>
                                                &nbsp; &nbsp; &nbsp; &nbsp;
                                                &nbsp; &nbsp;
                                                <button
                                                  className="button-30"
                                                  type="button"
                                                  style={{
                                                    color: "rgb(255, 0, 0)",
                                                  }}
                                                  onClick={() =>
                                                    this.DeleteTopicButton(
                                                      DepartmentGrouped.TrainerUserId,
                                                      Department.TopicId
                                                    )
                                                  }
                                                >
                                                  <i className="icofont icofont-minus"></i>
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                          // </tbody>
                                        )}
                                      </>
                                    )
                                  )}
                                </table>
                              )}
                            </div>
                          </form>
                        </div>
                        <br />
                        <br />

                        <table
                          id="DepartSheet"
                          hidden
                          className="table nowrap"
                          style={{ maxWidth: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>Trainer's Name</th>
                              <th>Department</th>
                              <th>Role</th>
                              <th>Trainer Since</th>
                              <th>No.of.Trainers</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Department.map((Department) => (
                              <tr>
                                <td>{Department.trainerName}</td>
                                <td>{Department.department}</td>
                                <td>{Department.role}</td>
                                <td>{Department.trainersince}</td>
                                <td>{Department.trainerName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End */}
            </div>
          </div>
        </div>
          {" "}
        {isOpen && (
          <div className="pop">
            <div
              className="popinnernormal"
              style={{
                width: "61%",
                height: "80vh",
              }}
            >
              {" "}
              <form
                autoComplete="off"
                encType="multipart/form-data"
                style={{ width: "135%" }}
              >
                {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}} onClick={this.props.close}  ><CloseIcon/></button> */}

                {/* {Filterpopstatus1 ? (
          <Topic_FilterPop
            data={this.state.Depart}
            FilteredData={(newValue) => {
              this.setState({ Department: newValue });
            }}
            TogglePop={(e) => {
              this.setState({ Filterpopstatus1: !Filterpopstatus1 });
            }}
          />
        ) : null}
              <div className="col-lg-4">
                                  <input
                                    className="Searchbox"
                                    placeholder="Search"
                                    onClick={() => {
                                      this.setState({ Filterpopstatus1: true });
                                    }}
                                    //onKeyUp={this.myFunction}
                                    style={{ float: "right" }}
                                  />
                                </div> */}

                <div className="row" tyle={{ padding: "8px" }}>
                  {/* {(() => {
                    if (this.state.Search.length === 0) {
                      return null;
                    } else {
                      return ( */}
                  <div className="col-sm-3">
                    <select
                      className="effect-16"
                      type="text"
                      name="Department"
                      value={this.state.dept}
                      id="Department"
                      onChange={this.handleChange}
                      //style={{width:'72%'}}
                    >
                      <option value="">Department</option>
                      {this.state.department.map((Departmentdrop) => (
                        <option value={Departmentdrop.department}>
                          {Departmentdrop.department}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* );
                    }
                  })()} */}

                  {/* {(() => {
                    if (this.state.Search.length === 0) {
                      return null;
                    } else {
                      return ( */}
                  <div className="col-sm-3">
                    <select
                      className="effect-16"
                      type="text"
                      value={this.state.selectedJobRole}
                      name="JobRole"
                      // style={{width:'88%', marginLeft:'-30%'}}
                      id="JobRole"
                      onChange={this.handleChangeDesign}
                    >
                      <option value="">Designation</option>
                      {this.state.AllRoles.map((JobDrop) => (
                        <option value={JobDrop.designation}>
                          {JobDrop.designation}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* );
                    }
                  })()} */}

                  {/* {(() => {
                    if (this.state.Search.length === 0) {
                      return null;
                    } else {
                      return ( */}
                  <div className="col-sm-3">
                    <input
                      className="Searchbox"
                      placeholder="Search"
                      onClick={() => {
                        this.setState({ Filterpopstatusadd: true });
                      }}

                      //onKeyUp={this.myFunction}
                      //  style={{ marginLeft: "0%" }}
                    />
                    <button
                      style={{
                        //  marginLeft:'223%',
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={this.closePopup}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  {/* );
                    }
                  })()} */}
                  <div className="col-sm-8" style={{ maxWidth: "100.666667%" }}>
                    <div
                      className="dt-responsive table-responsive table-res"
                      style={{ overflowX: "hidden" }}
                    >
                      {(() => {
                        if (this.state.Search.length === 0) {
                          return (
                            <div>
                              <br />
                              <br />
                              {/* <button
                          style={{
                              marginLeft:'223%',
                            backgroundColor: "#a0a3a305",
                            color: "#797070",
                            border: "1px solid #a3a5a505",
                          }}
                          onClick={this.closePopup}
                        >
                          <CloseIcon />
                        </button> */}
                              "There are no ' Topics '";
                            </div>
                          );
                        }
                        //                       else if (Search.length > 0) {
                        //                         return (
                        //                           <table
                        //                           id="DepSheet"
                        //                           className="table nowrap"
                        //                           style={{ maxWidth: "90%" }}
                        //                         >
                        //                           <thead>
                        //                             <tr>
                        //                               <th>
                        //                                 <input type="checkbox" hidden />
                        //                               </th>

                        //                               <th>Department</th>
                        //                               <th>Designation</th>
                        //                               <th>Topics</th>
                        //                               <th>Description</th>
                        //                               {/* <th>Action</th> */}
                        //                             </tr>
                        //                           </thead>
                        //                           <tbody>
                        //                           {/* {DepartmentGrouped.map(
                        //                                     (DepartmentGrouped, i) => (
                        //                                       <div>
                        //                                         <input type="text" value={DepartmentGrouped.TrainerUserId} />
                        //                                       </div>
                        //                                     )
                        //                           )} */}
                        //                             {Search.map((checkbox) => (
                        //                               <tr key={checkbox.training_Id}>
                        //                                   {/* <input type="text" value={checkbox.userId} id="TrainingId" /> */}
                        //                                  <td>
                        //                                  <input type="checkbox"

                        // value={checkbox.training_Id + "$$$" +  this.state.firstName  + "$$$" + this.state.Id}

                        //  onChange={this.checkChangeDesignation}

                        // />

                        //                                 </td>
                        //                             <td>
                        //                             {checkbox.department}
                        //                             </td>
                        //                             <td>
                        //                             {checkbox.designation}
                        //                             </td>
                        //                                 <td>{checkbox.trainingitem}</td>
                        //                                 <td>
                        //                             {checkbox.description}
                        //                             </td>

                        //                                 {/* <td>

                        //                                   <button
                        //                                     className="button-30"
                        //                                     type="button"
                        //                                     style={{ color: "rgb(94, 217, 135)" }}
                        //                                     onClick={() =>
                        //                                       this.fetchData(
                        //                                         this.state.firstName,
                        //                                         this.state.Id,
                        //                                         Trainers.training_Id
                        //                                       )
                        //                                     }
                        //                                   >
                        //                                     Assign&nbsp;
                        //                                     <i className="icofont icofont-edit"></i>
                        //                                   </button>
                        //                                 </td> */}
                        //                                 {/* <td>
                        //                                 <button onClick={() => this.handleSelectedRows()}>Perform Action</button>
                        //                                 </td> */}
                        //                               </tr>
                        //                             ))}
                        //                           </tbody>
                        //                         </table>
                        //                         );
                        //                       }
                        //                       else if (Search.length === 0 && this.state.Trainers.length === 0) {
                        //                         return (
                        //                          "No Results found"
                        //                         );
                        //                       }
                        else {
                          return (
                            <table
                              id="DepSheet"
                              className="table nowrap"
                              style={{ maxWidth: "90%" }}
                            >
                              <thead>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      id="selectedall"
                                      onClick={() => {
                                        this.checkedAll(this);
                                      }}
                                    />
                                  </th>

                                  <th>Department</th>
                                  <th>Designation</th>
                                  <th>Topics</th>
                                  <th>Description</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {/* {DepartmentGrouped.map(
                                                    (DepartmentGrouped, i) => (
                                                      <div>
                                                        <input type="text" value={DepartmentGrouped.TrainerUserId} />
                                                      </div>
                                                    )
                                          )} */}
                                {Search.map((checkbox) => (
                                  <tr key={checkbox.training_Id}>
                                    {/* <input type="text" value={checkbox.userId} id="TrainingId" /> */}
                                    <td>
                                      <input
                                        type="checkbox"
                                        name="design"
                                        id="checkedall"
                                        value={
                                          checkbox.training_Id +
                                          "$$$" +
                                          this.state.firstName +
                                          "$$$" +
                                          this.state.Id
                                        }
                                        onChange={this.checkChangeDesignation}
                                      />
                                    </td>
                                    <td>{checkbox.department}</td>
                                    <td>{checkbox.designationcount}</td>
                                    <td>{checkbox.trainingitem}</td>
                                    <td>{checkbox.description}</td>

                                    {/* <td>
                                                 
                                                  <button
                                                    className="button-30"
                                                    type="button"
                                                    style={{ color: "rgb(94, 217, 135)" }}
                                                    onClick={() =>
                                                      this.fetchData(
                                                        this.state.firstName,
                                                        this.state.Id,
                                                        Trainers.training_Id
                                                      )
                                                    }
                                                  >
                                                    Assign&nbsp;
                                                    <i className="icofont icofont-edit"></i>
                                                  </button>
                                                </td> */}
                                    {/* <td>
                                                <button onClick={() => this.handleSelectedRows()}>Perform Action</button>
                                                </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          );
                        }
                      })()}
                      {this.state.selectedOptions.map((option, index) => (
                        <li key={index}>{option}</li>
                      ))}
                    </div>
                  </div>
                </div>
                {Filterpopstatusadd ? (
                  <Filterpopstatustopic
                    data={this.state.Search}
                    FilteredData={(newValue) => {
                      this.setState({ Search: newValue });
                    }}
                    TogglePop={(e) => {
                      this.setState({
                        Filterpopstatusadd: !Filterpopstatusadd,
                      });
                    }}
                  />
                ) : null}
                <div className="row">
                  <div
                    className="col-sm-12"
                    style={{ textAlign: "center" }}
                  ></div>
                </div>
                {(() => {
                  if (this.state.Search.length === 0) {
                    return null;
                  } else {
                    return (
                      <div className="col-sm-4">
                        <button
                          className="button-30"
                          type="button"
                          style={{
                            color: "rgb(83, 81, 171)",
                            marginLeft: "98%",
                          }}
                          onClick={() =>
                            this.fetchData(
                              this.state.firstName,
                              this.state.Id,
                              Trainers.training_Id
                            )
                          }
                        >
                          Save&nbsp;
                        </button>
                      </div>
                    );
                  }
                })()}
              </form>
              <br />
              {/* <button onClick={this.closePopup}><CloseIcon/></button> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}
