import React from 'react';
import '../Css/Chat.css';

const ImageModal = ({ isOpen, onClose, src, isVideo }) => {
  if (!isOpen) return null;

  return (
    <div className="ImageModal-overlay" onClick={onClose}>
      <div className="ImageModal-content" onClick={e => e.stopPropagation()}>
        {isVideo ? (
          <video controls autoPlay style={{ width: '100%', height: '100%' }}>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={src} alt="Modal content" style={{ width: '100%', height: '100%' }} />
        )}
        <button className="ImageModal-close" onClick={onClose}>x</button>
      </div>
    </div>
  );
};

export default ImageModal;
