import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base"
import onlinedot from "../../Assets/images/online-dot.png";
// import AdminSkillPop from '../../Common/AdminSkillPop';
import CloseIcon from "@material-ui/icons/Close";
export default class AdminNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Notify: [],
      Active: [],
      InActive: [],
      Pending: [],
      All: [],
      allNotify: [],
      Notification: [],
      User: [],
      renewel: [],
      CategoryModel: false,
      Unreadcount: "0",
      Request: [],
      NoRequest: [],
      AllRequest: [],
      AllNoRequest: [],
      Organization: "",
      Team: [],
      Entity: [],
      Notifigreencount: false,
      Notificount: [],
      delay: false,
      Filterpop: false,
      letters: [],
      FilterData: "",
      isOpen: false,
      isAddTrainerOpen: false,
      NotificationId: "",
      showTabs: false,
      SeenCount: []

    };
    this.Noticount = this.Noticount.bind(this);
    this.NoticountDesc = this.NoticountDesc.bind(this);
    this.AccessNotification = this.AccessNotification.bind(this);
  }

  GetallNotify() {
    fetch(`${BASE_URLs}/Notification/GetallNotify`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ allNotify: data });
      });
  }

  displaypop = (id) => {
    this.setState({
      isOpen: !this.state.isOpen,
      NotificationId: id
    })
    this.GetSeenCount();

  }

  Addtrainerpop = (id) => {
    this.setState({
      isAddTrainerOpen: !this.state.isAddTrainerOpen,
      NotificationId: id
    })
  }

  Noticount = (notify_Id, company_Id) => {
    fetch(`${BASE_URLs}/Notification/Noticount`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Seen: 1,
        MessageNoty: 4,
        Notification_Id: notify_Id,
      }),
    });

    this.GetAdNotification();
    if (company_Id !== null) {
      var page = {
        pathname: "/AdminNotification",
        state: {
          Id: company_Id,
        },
      };

      this.props.history.push(page);
    }
  };

  NoticountDesc = (teamId, Id) => {
    fetch(`${BASE_URLs}/Notification/NoticountDesc`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Seen: 1,
        // "MessageNoty":4,
        teamId: teamId,
      }),
    });
    var page = {
      pathname: "/Teamdetail",
      state: {
        Id: Id,
      },
    };
    this.props.history.push(page);
    // this.setState(window.location.reload(true))

    this.GetAdDesc();
    //   if (company_Id !== null) {
    //     var page = {
    //       pathname: "/AdminNotification",
    //       state: {
    //         Id: company_Id
    //       }
    //     }

    // this.props.history.push(page);
    //   }
  };

  AccessNotification = (id) => {
    fetch(`${BASE_URLs}/Notification/AccessNotification`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Seen: 1,
        // "MessageNoty":4,
        id: id,
      }),
    });

    var page = {
      pathname: "/Settings",
      // state: {
      //   id: id,
      // },
    };

    this.props.history.push(page);

    this.GetEntityNotification();
    // var page = {
    //   pathname: "/Teamdetail",
    //   state: {
    //     Id: Id,
    //   },
    // };
    // this.props.history.push(page);
    // this.setState(window.location.reload(true))

    // this.GetAdDesc();
    //   if (company_Id !== null) {
    //     var page = {
    //       pathname: "/AdminNotification",
    //       state: {
    //         Id: company_Id
    //       }
    //     }

    // this.props.history.push(page);
    //   }
  };

  clickNoticount = (notify_Id, company_Id) => {
    fetch(`${BASE_URLs}/Notification/Noticount`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Seen: 1,
        Notification_Id: notify_Id,
      }),
    });

    this.GetAdNotification();
    if (company_Id !== null) {
      var page = {
        pathname: "/AdminNotification",
        state: {
          Id: company_Id,
        },
      };

      this.props.history.push(page);
    }
  };

  clickNoticountforTrainerforNo = (notify_Id, company_Id) => {
    fetch(`${BASE_URLs}/Notification/Noticount`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Seen: 1,
        Notification_Id: notify_Id,
      }),
    });
    this.GetAdNotification();
    this.setState({
      isAddTrainerOpen: !this.state.isAddTrainerOpen,
    })
  }

  clickNoticountforTrainer = (notify_Id, userId, company_Id) => {
    fetch(`${BASE_URLs}/Notification/Noticount`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Seen: 1,
        Notification_Id: notify_Id,
        UserId: userId
      }),
    });

    this.GetAdNotification();
    if (notify_Id !== null) {
      var page = {
        pathname: "/AddTrainer",
        state: {
          Id: company_Id,
        },
      };

      this.props.history.push(page);
    }
  }

  Request = (notify_Id, status, requestId) => {
    fetch(`${BASE_URLs}/Notification/RequestStatus`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: status,
        Notification_Id: notify_Id,
        RequestId: requestId
      }),
    });
    this.GetAdNotification();
  };

  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    fetch(`${BASE_URLs}/AddOrganization/GetOrg`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          User: data,
        });
        // console.log("newdata", data);
      });
  }

  renewel() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/Renewel`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ renewel: data });
      });
  }

  detailData(company_Id) {
    // localStorage.setItem("status","Active")
    var page = {
      pathname: "/AdminNotification",
      state: {
        Id: company_Id,
        Status: "Pending",
      },
    };

    this.props.history.push(page);
  }
  detailData1(company_Id) {
    localStorage.setItem("Id", company_Id);
    localStorage.setItem("status", "Inactive");
  }

  // GetAdDesc() {
  //   const options = {
  //     method: "GET",
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
  //     },
  //   };
  //   fetch(`${BASE_URLs}/Team/GetTeam", options)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if(data.status === 1){
  //       this.setState({
  //         Team: data,
  //         Teams: data,
  //       });
  //     }
  //       // console.log("hess", data);
  //     });
  // }

  GetAdDesc() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
        const filteredData = [];

        data.forEach((item) => {
          if (item.status === 1) {
            filteredData.push(item);
          }
        });

        this.setState({
          Team: filteredData,
          Teams: filteredData,
        });

        // console.log("hess", filteredData);
      });
  }

  GetEntityNotification() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetEntityNotification`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Entity: data,
          Entity: data,
        });

        // console.log("hess21", data);
      });
  }

  GetAdNotification() {

    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/GetNotification`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Notification: data });
        // console.log("Accessing", data);

        this.setState({
          Request: data.filter((Tasks) => Tasks.isRequest === true),
        });
        this.setState({
          NoRequest: data.filter((Tasks) => Tasks.isRequest === false),
        });

        this.setState({
          AllRequest: data.filter((Tasks) => Tasks.isRequest === true),
          AllNoRequest: data.filter((Tasks) => Tasks.isRequest === false)
        });
        if (this.state.Notification.length !== 0) {
          this.setState({
            Noticount: this.state.Notification[0].noticount,
          });
        }

        if (this.state.Notification.length !== 0) {

          this.state.Notification.forEach((d) => {
            if (d.isRequest === true) {
              if (d.seen === false) {
                this.setState({
                  Notificount: d
                });
              }
            }
          })

        }
      });
  }

  RedirectSkill() {
    this.props.history.push("/MasterSkills");
  }
  RedirectEdu() {
    this.props.history.push("/Education");
  }
  CategoryModel = () => {
    this.setState((prevState) => ({
      CategoryModel: !prevState.CategoryModel,
    }));
  };
  getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  componentDidMount() {
    this.GetAdNotification();
    this.GetAdDesc();
    this.GetEntityNotification();
    this.GetallNotify();
    this.GetSeenCount();
    var crole = this.getCookie("" + JSON.parse(localStorage.uname) + "role");

    var corg = this.getCookie(
      "" + JSON.parse(localStorage.uname) + "organization"
    );

    this.setState({
      User: [{ role: crole, organization: parseInt(corg) }],
      Organization: corg,
    });

    setTimeout(() => {
      this.setState({
        delay: true
      })
    }, 500);

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });

    //     var checkboxA = document.getElementById('tab1')

    //     if(checkboxA.checked){
    // document.getElementById('secondtabs').style.display = 'none';
    //     }

  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  Detail(teamId) {
    var page = {
      pathname: "/Teamdetail",
      state: {
        Id: teamId,
      },
    };
    this.props.history.push(page);
  }

  filterfunction = (e) => {
    const NoRequest = this.state.AllNoRequest.filter(
      (item) =>
        item.description.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ NoRequest, showResults: true });

    const Request = this.state.AllRequest.filter(
      (item) =>
        item.description.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Request, showResults: true });
  }
  handleFilter = (letter, event) => {
    event.preventDefault();
    const NoRequest = this.state.AllNoRequest.filter((item) => {
      return (
        item.description.toLowerCase().startsWith(letter.toLowerCase())
      );
    });
    this.setState({ NoRequest, showResults: true });
    const Request = this.state.AllRequest.filter((item) => {
      return (
        item.description.toLowerCase().startsWith(letter.toLowerCase())
      );
    });
    this.setState({ Request, showResults: true });
  };
  handleShowTabsClick = () => {
    // Update the state to show the tabs
    this.setState({ showTabs: true });
    document.getElementById("Requests").style.backgroundColor = "#8e75ef";
    document.getElementById("req").style.color = "white";
  };
  handleShowTabsClick1 = () => {
    // Update the state to show the tabs
    this.setState({ showTabs: false });
    document.getElementById("Requests").style.backgroundColor = "white";
    document.getElementById("req").style.color = "black";
  };
  truncateTitle(description, maxLength) {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  }

  GetSeenCount() {
    let url = `${BASE_URLs}/AddOrganization/GetAdminNotifyCount`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          SeenCount: data,

        });

      });
  }

  render() {
    const { Notify } = this.state;
    const { delay, Filterpop, Notification, Request, NoRequest, Team, Entity, Noticount, isOpen, isAddTrainerOpen, NotificationId, showTabs } = this.state;

    return (
      <>
        <SideBar active={window.location.pathname} />
        {/* {this.state.CategoryModel ? <AdminSkillPop id="Success" close={() => this.CategoryModel()} /> : null} */}
        {/* start */}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a
                              href
                              onClick={() => this.props.history.goBack()}
                            >
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title" style={{ marginRight: '45px' }}>Notifications </h4>
                        </div>
                        {/* <div className="col-xl-12 "
                    >

                      <div className="col-lg-3" style={{ float: 'right'}}>
                        <input
                          type="text"
                          className="Searchbox"
                          id="CNSearch"
                          placeholder="Search"
                          onClick={() => {
                            this.setState({
                            Filterpop: true,
                            Filterpop: !this.state.Filterpop,
                            });
                            }}
                            readOnly
                           

                        /></div>
                    </div> */}
                        <div className="card-block">
                          <div className="j-tabs-container md-tabs">

                            <input
                              id="tab1"
                              type="radio"
                              name="tabs"
                              defaultChecked
                              onClick={this.handleShowTabsClick1}
                            />
                            <label
                              id="supertab"
                              className="j-tabs-label"
                              htmlFor="tab1"
                              title="Info"
                              style={{ marginLeft: '23px' }}
                            >
                              {/* {this.state.NoRequest.length === 0 ? (
                              <span>
                                &nbsp;&nbsp;No Notifications&nbsp;&nbsp;
                              </span>
                            ) : ( */}
                              <span>&nbsp;&nbsp;Notifications&nbsp;&nbsp;</span>
                              {/* )} */}
                            </label>

                            <input
                              type="text"
                              className="Searchbox"
                              placeholder="Search"
                              onClick={() => {
                                this.setState({
                                  Filterpop: true,
                                  Filterpop: !this.state.Filterpop,
                                });
                              }}
                              readOnly
                              style={{ float: 'right' }}

                            />

                            <input id="tab2" type="radio" name="tabs" onClick={this.handleShowTabsClick} />
                            <label
                              id="Requests"
                              className="j-tabs-label"
                              htmlFor="tab2"
                              title="Requests"
                            >

                              {/* {this.state.Request.length === 0 ? (
                              <span>&nbsp;&nbsp;No Requests&nbsp;&nbsp;</span>
                            ) : ( */}

                              <span id="req">&nbsp;&nbsp;Requests&nbsp;&nbsp;
                                {this.state.SeenCount.length !== 0 ? <img
                                  src={onlinedot}
                                  className="media-object img-circle"
                                  style={{
                                    marginTop: "0px",
                                    width: "10px",
                                  }}
                                  title="Unread"
                                /> : null}
                                {/* { Noticount === 0 ?(
                            <span
                              className="badge"
                              id="size"
                              style={{
                                fontSize: "13px",
                                backgroundColor: "none",
                                marginLeft: "-10%",
                              }}
                            ></span>):(<img
                              src={onlinedot}
                              className="media-object img-circle"
                              style={{
                                marginTop: "0px",
                                width: "10px",
                              }}
                              title="Online"
                            />)} */}


                              </span>
                              {/* )} */}
                            </label>

                            <div id="tabs-section-1" className="j-tabs-section">
                              <div
                                className="tab-pane"
                                id="notes"
                                role="tabpanel"
                              >
                                <div className="card-block">
                                  <div className="dt-responsive table-responsive"
                                    id={NoRequest.length <= 6 ? "NoAnnLength" : "Above10"}
                                  >
                                    {NoRequest.length === 0 && delay ?
                                      <center>

                                        <p>No Record</p>
                                      </center>
                                      : null}
                                    {NoRequest.length !== 0 && delay ? <table
                                      id="add-row-table3" className="table nowrap">
                                      <thead id='tablepadding'><tr>
                                        <th style={{ color: '#7367f0' }}> Notification Details</th>

                                      </tr>
                                      </thead>
                                      <tbody id='Notitablepadding'>
                                        <tr>
                                          <td>
                                            {NoRequest.map((NoRequest) => (
                                              <>
                                                {NoRequest.seen === false ? (
                                                  // Unreaded noti
                                                  <>
                                                    {NoRequest.type === "ASkill" ? (
                                                      <>
                                                        <Link
                                                          to="/MasterSkills"
                                                          id="btnSkill"
                                                          onClick={() =>
                                                            this.Noticount(
                                                              NoRequest.notification_Id
                                                            )
                                                          }
                                                          style={{
                                                            padding: "0px",
                                                            color: "#000100",
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          {" "}
                                                          <span className="hoverable-span">

                                                            {this.truncateTitle(NoRequest.description, 100)}
                                                          </span>{" "}
                                                        </Link>
                                                        <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                          {NoRequest.time}
                                                        </span>
                                                        &nbsp;&nbsp;
                                                        <hr id="NotiHR"></hr>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {NoRequest.type ===
                                                          "AEducation" ? (
                                                          <>
                                                            <Link
                                                              to="/Education"
                                                              id="btnEdu"
                                                              onClick={() =>
                                                                this.Noticount(
                                                                  NoRequest.notification_Id
                                                                )
                                                              }
                                                              style={{
                                                                padding: "0px",
                                                                color: "#000100",
                                                                cursor: "pointer",
                                                              }}
                                                            >
                                                              {" "}
                                                              <span className="hoverable-span">
                                                                {this.truncateTitle(NoRequest.description, 100)}
                                                              </span>{" "}
                                                            </Link>
                                                            <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                              {NoRequest.time}
                                                            </span>
                                                            &nbsp;&nbsp; <br />
                                                            <hr id="NotiHR"></hr>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {NoRequest.type ===
                                                              "o" ? (
                                                              <>
                                                                <Link
                                                                  to={
                                                                    this.state
                                                                      .Organization ===
                                                                      "4"
                                                                      ? "/AddOrganization"
                                                                      : "/Organization"
                                                                  }
                                                                  onClick={() =>
                                                                    this.Noticount(
                                                                      NoRequest.notification_Id
                                                                    )
                                                                  }
                                                                  style={{
                                                                    padding: "0px",
                                                                    color: "#000100",
                                                                    cursor: "pointer",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <span className="hoverable-span" style={{wordWrap:"break-word"}}>
                                                                    {NoRequest.description}
                                                                  </span>{" "}
                                                                </Link>
                                                                <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                  {NoRequest.time}
                                                                </span>
                                                                <hr id="NotiHR"></hr>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {NoRequest.type ===
                                                                  "s" ? (
                                                                  <>
                                                                    <Link
                                                                      to="/MasterSkills"
                                                                      onClick={() =>
                                                                        this.Noticount(
                                                                          NoRequest.notification_Id
                                                                        )
                                                                      }
                                                                      style={{
                                                                        padding:
                                                                          "0px",
                                                                        color:
                                                                          "#000100",
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      <span className="hoverable-span">
                                                                        {this.truncateTitle(NoRequest.description, 100)}
                                                                      </span>{" "}
                                                                    </Link>
                                                                    <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                      {NoRequest.time}
                                                                    </span>

                                                                    <hr id="NotiHR"></hr>
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {NoRequest.type ===
                                                                      "Access" ? (
                                                                      <>
                                                                        <Link
                                                                          to="/Settings"
                                                                          onClick={() =>
                                                                            this.Noticount(
                                                                              NoRequest.notification_Id
                                                                            )
                                                                          }
                                                                          style={{
                                                                            padding:
                                                                              "0px",
                                                                            color:
                                                                              "#000100",
                                                                            cursor:
                                                                              "pointer",
                                                                          }}
                                                                        >
                                                                          {" "}
                                                                          <span className="hoverable-span">
                                                                            {this.truncateTitle(NoRequest.description, 100)}
                                                                          </span>{" "}
                                                                        </Link>
                                                                        <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                          {
                                                                            NoRequest.time
                                                                          }
                                                                        </span>

                                                                        <hr id="NotiHR"></hr>
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {NoRequest.type ===
                                                                          "Turned Off" ? (
                                                                          <>
                                                                            <Link
                                                                              to="/Settings"
                                                                              onClick={() =>
                                                                                this.Noticount(
                                                                                  NoRequest.notification_Id
                                                                                )
                                                                              }
                                                                              style={{
                                                                                padding:
                                                                                  "0px",
                                                                                color:
                                                                                  "#000100",
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <span className="hoverable-span">
                                                                                {this.truncateTitle(NoRequest.description, 100)}
                                                                              </span>{" "}
                                                                            </Link>
                                                                            <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                              {
                                                                                NoRequest.time
                                                                              }
                                                                            </span>
                                                                            <hr id="NotiHR"></hr>
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            {NoRequest.type ===
                                                                              "InviteMail" ? (
                                                                              <>
                                                                                <Link
                                                                                  to="/Team"
                                                                                  onClick={() =>
                                                                                    this.Noticount(
                                                                                      NoRequest.notification_Id
                                                                                    )
                                                                                  }
                                                                                  style={{
                                                                                    padding:
                                                                                      "0px",
                                                                                    color:
                                                                                      "#000100",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                >
                                                                                  {" "}
                                                                                  <span className="hoverable-span">
                                                                                    {this.truncateTitle(NoRequest.description, 100)}
                                                                                  </span>{" "}
                                                                                </Link>
                                                                                <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                  {
                                                                                    NoRequest.time
                                                                                  }
                                                                                </span>
                                                                                <hr id="NotiHR"></hr>
                                                                              </>
                                                                            ) : (
                                                                              <>
                                                                                {NoRequest.type ===
                                                                                  "Training" ? (
                                                                                  <>
                                                                                    <Link
                                                                                      to="/LearningSchedule"
                                                                                      onClick={() =>
                                                                                        this.Noticount(
                                                                                          NoRequest.notification_Id
                                                                                        )
                                                                                      }
                                                                                      style={{
                                                                                        padding:
                                                                                          "0px",
                                                                                        color:
                                                                                          "#000100",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      }}
                                                                                    >
                                                                                      {" "}
                                                                                      <span className="hoverable-span">
                                                                                        {this.truncateTitle(NoRequest.description, 100)}
                                                                                      </span>{" "}
                                                                                    </Link>
                                                                                    <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                      {
                                                                                        NoRequest.time
                                                                                      }
                                                                                    </span>

                                                                                    <hr id="NotiHR"></hr>
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    {NoRequest.type ===
                                                                                      "Ageneral" ? (
                                                                                      <>
                                                                                        <span
                                                                                          onClick={() =>
                                                                                            this.clickNoticount(
                                                                                              Request.notification_Id
                                                                                            )
                                                                                          }
                                                                                          style={{
                                                                                            padding:
                                                                                              "0px",
                                                                                            color:
                                                                                              "gray",
                                                                                            cursor:
                                                                                              "pointer",
                                                                                          }}
                                                                                        >
                                                                                          {" "}
                                                                                          <span>
                                                                                            {
                                                                                              Request.description
                                                                                            }{" "}
                                                                                          </span>{" "}
                                                                                        </span>
                                                                                        &nbsp;&nbsp;{" "}
                                                                                        {Request.requestStatus ? (
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "green",
                                                                                            }}
                                                                                          >
                                                                                            Agreed
                                                                                          </span>
                                                                                        ) : (
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "Red",
                                                                                            }}
                                                                                          >
                                                                                            Disagreed
                                                                                          </span>
                                                                                        )}
                                                                                        &nbsp;&nbsp;{" "}
                                                                                        <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                          {
                                                                                            Request.time
                                                                                          }
                                                                                        </span>
                                                                                        <hr id="NotiHR"></hr>
                                                                                      </>
                                                                                    ) : (
                                                                                      <>
                                                                                        {NoRequest.type ===
                                                                                          "Aothers" ? (
                                                                                          <>
                                                                                            <span
                                                                                              onClick={() =>
                                                                                                this.clickNoticount(
                                                                                                  Request.notification_Id
                                                                                                )
                                                                                              }
                                                                                              style={{
                                                                                                padding:
                                                                                                  "0px",
                                                                                                color:
                                                                                                  "gray",
                                                                                                cursor:
                                                                                                  "pointer",
                                                                                              }}
                                                                                            >
                                                                                              {" "}
                                                                                              <span>
                                                                                                {
                                                                                                  Request.description
                                                                                                }{" "}
                                                                                              </span>{" "}
                                                                                            </span>
                                                                                            &nbsp;&nbsp;{" "}
                                                                                            {Request.requestStatus ? (
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "green",
                                                                                                }}
                                                                                              >
                                                                                                Agreed
                                                                                              </span>
                                                                                            ) : (
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "Red",
                                                                                                }}
                                                                                              >
                                                                                                Disagreed
                                                                                              </span>
                                                                                            )}
                                                                                            &nbsp;&nbsp;{" "}
                                                                                            <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                              {
                                                                                                Request.time
                                                                                              }
                                                                                            </span>
                                                                                            <hr id="NotiHR"></hr>
                                                                                          </>
                                                                                        ) : (
                                                                                          <>

                                                                                          </>
                                                                                        )}
                                                                                      </>
                                                                                    )}
                                                                                  </>
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )}
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  // Readed noti
                                                  <>
                                                    {NoRequest.type === "ASkill" ? (
                                                      <>
                                                        <Link
                                                          to="/MasterSkills"
                                                          onClick={() =>
                                                            this.Noticount(
                                                              NoRequest.notification_Id
                                                            )
                                                          }
                                                          style={{
                                                            padding: "0px",
                                                            color: "gray",
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          {" "}
                                                          <span className="hoverable-span">
                                                            {this.truncateTitle(NoRequest.description, 100)}
                                                          </span>{" "}
                                                        </Link>
                                                        <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                          {NoRequest.time}
                                                        </span>
                                                        &nbsp;&nbsp;
                                                        <button
                                                          onClick={() =>
                                                            this.CategoryModel()
                                                          }
                                                          type="button"
                                                          id="addRow"
                                                        >
                                                          Agree{" "}
                                                        </button>{" "}
                                                        &nbsp;&nbsp;{" "}
                                                        <button type="button">
                                                          Disagree
                                                        </button>
                                                        <hr id="NotiHR"></hr>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {NoRequest.type ===
                                                          "AEducation" ? (
                                                          <>
                                                            <Link
                                                              to="/Education"
                                                              onClick={() =>
                                                                this.Noticount(
                                                                  NoRequest.notification_Id
                                                                )
                                                              }
                                                              style={{
                                                                padding: "0px",
                                                                color: "gray",
                                                                cursor: "pointer",
                                                              }}
                                                            >
                                                              {" "}
                                                              <span className="hoverable-span">
                                                                {this.truncateTitle(NoRequest.description, 100)}
                                                              </span>{" "}
                                                            </Link>
                                                            <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                              {NoRequest.time}
                                                            </span>
                                                            <hr id="NotiHR"></hr>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {NoRequest.type ===
                                                              "o" ? (
                                                              <>
                                                                <Link
                                                                  to={
                                                                    this.state
                                                                      .Organization ===
                                                                      "4"
                                                                      ? "/AddOrganization"
                                                                      : "/Organization"
                                                                  }
                                                                  onClick={() =>
                                                                    this.Noticount(
                                                                      NoRequest.notification_Id
                                                                    )
                                                                  }
                                                                  style={{
                                                                    padding: "0px",
                                                                    color: "gray",
                                                                    cursor: "pointer",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <span className="hoverable-span" style={{wordWrap:"break-word"}}>
                                                                    {NoRequest.description}
                                                                  </span>{" "}
                                                                </Link>
                                                                <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                  {NoRequest.time}
                                                                </span>
                                                                <hr id="NotiHR"></hr>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {NoRequest.type ===
                                                                  "s" ? (
                                                                  <>
                                                                    <Link
                                                                      to="/MasterSkills"
                                                                      onClick={() =>
                                                                        this.Noticount(
                                                                          NoRequest.notification_Id
                                                                        )
                                                                      }
                                                                      style={{
                                                                        padding:
                                                                          "0px",
                                                                        color: "gray",
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      <span className="hoverable-span">
                                                                        {this.truncateTitle(NoRequest.description, 100)}
                                                                      </span>{" "}
                                                                    </Link>
                                                                    <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                      {NoRequest.time}
                                                                    </span>

                                                                    <hr id="NotiHR"></hr>
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {NoRequest.type ===
                                                                      "Access" ? (
                                                                      <>
                                                                        <Link
                                                                          to="/Settings"
                                                                          onClick={() =>
                                                                            this.Noticount(
                                                                              NoRequest.notification_Id
                                                                            )
                                                                          }
                                                                          style={{
                                                                            padding:
                                                                              "0px",
                                                                            color:
                                                                              "gray",
                                                                            cursor:
                                                                              "pointer",
                                                                          }}
                                                                        >
                                                                          {" "}
                                                                          <span className="hoverable-span">
                                                                            {this.truncateTitle(NoRequest.description, 100)}
                                                                          </span>{" "}
                                                                        </Link>
                                                                        <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                          {
                                                                            NoRequest.time
                                                                          }
                                                                        </span>
                                                                        <hr id="NotiHR"></hr>
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {Request.type ===
                                                                          "Turned Off" ? (
                                                                          <>
                                                                            <Link
                                                                              to="/Settings"
                                                                              onClick={() =>
                                                                                this.Noticount(
                                                                                  Request.notification_Id
                                                                                )
                                                                              }
                                                                              style={{
                                                                                padding:
                                                                                  "0px",
                                                                                color:
                                                                                  "gray",
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <span className="hoverable-span"
                                                                              //                                                                          style={{ display: "inline-block",
                                                                              // width: "800px",
                                                                              // whiteSpace: "nowrap",
                                                                              // overflow: "hidden",
                                                                              // textOverflow: "ellipsis"}}
                                                                              >
                                                                                {this.truncateTitle(Request.description, 100)}
                                                                              </span>{" "}
                                                                            </Link>
                                                                            <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                              {
                                                                                Request.time
                                                                              }
                                                                            </span>

                                                                            <br />
                                                                            <br />
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            {NoRequest.type ===
                                                                              "InviteMail" ? (
                                                                              <>
                                                                                <Link
                                                                                  to="/Team"
                                                                                  onClick={() =>
                                                                                    this.Noticount(
                                                                                      NoRequest.notification_Id
                                                                                    )
                                                                                  }
                                                                                  style={{
                                                                                    padding:
                                                                                      "0px",
                                                                                    color:
                                                                                      "gray",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                >
                                                                                  {" "}
                                                                                  <span className="hoverable-span">
                                                                                    {this.truncateTitle(NoRequest.description, 100)}
                                                                                  </span>{" "}
                                                                                </Link>
                                                                                <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                  {
                                                                                    NoRequest.time
                                                                                  }
                                                                                </span>

                                                                                <hr id="NotiHR"></hr>
                                                                              </>
                                                                            ) : (
                                                                              <>
                                                                                {NoRequest.type ===
                                                                                  "Training" ? (
                                                                                  <>
                                                                                    <Link
                                                                                      to="/MasterSkills"
                                                                                      onClick={() =>
                                                                                        this.Noticount(
                                                                                          NoRequest.notification_Id
                                                                                        )
                                                                                      }
                                                                                      style={{
                                                                                        padding:
                                                                                          "0px",
                                                                                        color:
                                                                                          "gray",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      }}
                                                                                    >
                                                                                      {" "}
                                                                                      <span className="hoverable-span">
                                                                                        {this.truncateTitle(NoRequest.description, 100)}
                                                                                      </span>{" "}
                                                                                    </Link>
                                                                                    <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                      {
                                                                                        NoRequest.time
                                                                                      }
                                                                                    </span>

                                                                                    <hr id="NotiHR"></hr>
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    {NoRequest.type ===
                                                                                      "Ageneral" ? (
                                                                                      <>
                                                                                        <span
                                                                                          onClick={() =>
                                                                                            this.clickNoticount(
                                                                                              NoRequest.notification_Id
                                                                                            )
                                                                                          }
                                                                                          style={{
                                                                                            padding:
                                                                                              "0px",
                                                                                            color:
                                                                                              "gray",
                                                                                            cursor:
                                                                                              "pointer",
                                                                                          }}
                                                                                        >
                                                                                          {" "}
                                                                                          <span>
                                                                                            {
                                                                                              Request.description
                                                                                            }{" "}
                                                                                          </span>{" "}
                                                                                        </span>
                                                                                        &nbsp;&nbsp;{" "}
                                                                                        {Request.requestStatus ? (
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "green",
                                                                                            }}
                                                                                          >
                                                                                            Agreed
                                                                                          </span>
                                                                                        ) : (
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "Red",
                                                                                            }}
                                                                                          >
                                                                                            Disagreed
                                                                                          </span>
                                                                                        )}
                                                                                        &nbsp;&nbsp;{" "}
                                                                                        <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                          {
                                                                                            Request.time
                                                                                          }
                                                                                        </span>
                                                                                        <br />
                                                                                      </>
                                                                                    ) : (
                                                                                      <>
                                                                                        {NoRequest.type ===
                                                                                          "Aothers" ? (
                                                                                          <>
                                                                                            <span
                                                                                              onClick={() =>
                                                                                                this.clickNoticount(
                                                                                                  NoRequest.notification_Id
                                                                                                )
                                                                                              }
                                                                                              style={{
                                                                                                padding:
                                                                                                  "0px",
                                                                                                color:
                                                                                                  "gray",
                                                                                                cursor:
                                                                                                  "pointer",
                                                                                              }}
                                                                                            >
                                                                                              {" "}
                                                                                              <span>
                                                                                                {
                                                                                                  Request.description
                                                                                                }{" "}
                                                                                              </span>{" "}
                                                                                            </span>
                                                                                            &nbsp;&nbsp;{" "}
                                                                                            {Request.requestStatus ? (
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "green",
                                                                                                }}
                                                                                              >
                                                                                                Agreed
                                                                                              </span>
                                                                                            ) : (
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "Red",
                                                                                                }}
                                                                                              >
                                                                                                Disagreed
                                                                                              </span>
                                                                                            )}
                                                                                            &nbsp;&nbsp;{" "}
                                                                                            <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                              {
                                                                                                Request.time
                                                                                              }
                                                                                            </span>
                                                                                            <br />
                                                                                          </>
                                                                                        ) : (
                                                                                          <>

                                                                                          </>
                                                                                        )}
                                                                                      </>
                                                                                    )}
                                                                                  </>
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )}
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ))}
                                          </td>

                                        </tr>
                                      </tbody>
                                    </table> : null}

                                  </div></div>
                              </div>
                            </div>
                            <br /><br />

                            <div className="j-tabs-container md-tabs">

                              {showTabs ? (
                                <>
                                  <input
                                    id="tab3"
                                    type="radio"
                                    name="tabs"
                                  />
                                  <label
                                    id="pending"
                                    className="j-tabs-label"
                                    htmlFor="tab3"
                                    title="Pending"
                                    style={{ padding: '12px 14px', marginLeft: '23px' }}
                                  >

                                    <span>&nbsp;&nbsp;Pending&nbsp;&nbsp;</span>
                                    {this.state.SeenCount.length !== 0 ? <img
                                      src={onlinedot}
                                      className="media-object img-circle"
                                      style={{
                                        marginTop: "0px",
                                        width: "10px",
                                      }}
                                      title="Unread"
                                    /> : null}
                                  </label>

                                  <input id="tab4" type="radio" name="tabs" />
                                  <label
                                    id="closed"
                                    className="j-tabs-label"
                                    htmlFor="tab4"
                                    title="Closed"
                                    style={{ padding: '12px 18px' }}
                                  >

                                    <span>&nbsp;&nbsp;Closed&nbsp;&nbsp;
                                    </span>

                                  </label>
                                </>
                              ) : null}
                              <div id="tabs-section-3" className="j-tabs-section">
                                <div
                                  className="tab-pane"
                                  id="notes"
                                  role="tabpanel"
                                >
                                  <div className="card-block">
                                    <div className="dt-responsive table-responsive"
                                      id={Request.length <= 6 ? "AdminNotiNoAnnLength" : "Above10"}
                                    >
                                      {Request.length === 0 && delay ?
                                        <center>

                                          <p>No Record</p>
                                        </center>
                                        : null}
                                      {Request.length !== 0 ?
                                        <table
                                          id="add-row-table3" className="table nowrap">
                                          <thead id='tablepadding'><tr>

                                            <th style={{ color: '#7367f0' }}> Pending Details</th>

                                          </tr>
                                          </thead>
                                          <tbody id='Notitablepadding'>

                                            <tr>

                                              <td>

                                                {Request.map((Request) => (
                                                  <>
                                                    {Request.seen === false ? (
                                                      // Unreaded noti
                                                      <>
                                                        {Request.type === "ASkill" ? (
                                                          <>
                                                            <span
                                                              // to="/MasterSkills"
                                                              id="btnSkillLink"
                                                              style={{
                                                                padding: "0px",
                                                                color: "#000100",
                                                                cursor: "pointer",

                                                              }}
                                                              onClick={() => this.displaypop(Request.notification_Id)}
                                                            >
                                                              {" "}
                                                              <span className="hoverable-span"
                                                              //                                                       style={{ display: "inline-block",
                                                              // width: "800px",
                                                              // whiteSpace: "nowrap",
                                                              // overflow: "hidden",
                                                              // textOverflow: "ellipsis"}}
                                                              >
                                                                {this.truncateTitle(Request.description, 100)}
                                                              </span>{" "}
                                                            </span>
                                                            <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                              {Request.time}
                                                            </span>
                                                            &nbsp;&nbsp;
                                                            <hr id="NotiHR"></hr>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {Request.type ===
                                                              "AEducation" ? (
                                                              <>
                                                                <span
                                                                  id="btnEduLink"
                                                                  onClick={() => {
                                                                    // this.Noticount(
                                                                    //   Request.notification_Id
                                                                    // )
                                                                    this.displaypop(Request.notification_Id)
                                                                  }

                                                                  }
                                                                  style={{
                                                                    padding: "0px",
                                                                    color: "#000100",
                                                                    cursor: "pointer",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <span className="hoverable-span"
                                                                  //                                                           style={{ display: "inline-block",
                                                                  // width: "800px",
                                                                  // whiteSpace: "nowrap",
                                                                  // overflow: "hidden",
                                                                  // textOverflow: "ellipsis"}}
                                                                  >
                                                                    {this.truncateTitle(Request.description, 100)}
                                                                  </span>{" "}
                                                                </span>
                                                                <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                  {Request.time}
                                                                </span>
                                                                &nbsp;&nbsp;
                                                                <hr id="NotiHR"></hr>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {Request.type ===
                                                                  "Aothers" ? (
                                                                  <>
                                                                    <span
                                                                      onClick={() => {
                                                                        // this.Noticount(
                                                                        //   Request.notification_Id
                                                                        // )
                                                                        this.displaypop(Request.notification_Id)
                                                                      }

                                                                      }
                                                                      style={{
                                                                        padding:
                                                                          "0px",
                                                                        color:
                                                                          "#000100",
                                                                        cursor:
                                                                          "pointer",

                                                                      }}

                                                                    >
                                                                      {" "}
                                                                      <span className="hoverable-span"
                                                                      //                                                                             style={{ display: "inline-block",
                                                                      // width: "800px",
                                                                      // whiteSpace: "nowrap",
                                                                      // overflow: "hidden",
                                                                      // textOverflow: "ellipsis"}}
                                                                      >
                                                                        {this.truncateTitle(Request.description, 100)}
                                                                      </span>{" "}
                                                                    </span>
                                                                    <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                      {Request.time}
                                                                    </span>
                                                                    <hr id="NotiHR"></hr>
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {" "}
                                                                    {Request.type ===
                                                                      "Ageneral" ? (
                                                                      <>
                                                                        <span
                                                                          onClick={() => {
                                                                            // this.clickNoticount(
                                                                            //   Request.notification_Id
                                                                            // )
                                                                            this.displaypop(Request.notification_Id)
                                                                          }

                                                                          }
                                                                          style={{
                                                                            padding:
                                                                              "0px",
                                                                            color:
                                                                              "#000100",
                                                                            cursor:
                                                                              "pointer",
                                                                          }}
                                                                        >
                                                                          {" "}
                                                                          <span className="hoverable-span"
                                                                          //                                                                   style={{ display: "inline-block",
                                                                          // width: "800px",
                                                                          // whiteSpace: "nowrap",
                                                                          // overflow: "hidden",
                                                                          // textOverflow: "ellipsis"}}
                                                                          >
                                                                            {this.truncateTitle(Request.description, 100)}
                                                                          </span>{" "}
                                                                        </span>
                                                                        <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                          {Request.time}
                                                                        </span>
                                                                        <hr id="NotiHR"></hr>
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {Request.type ===
                                                                          "ATrainer" ? (
                                                                          <>
                                                                            <span
                                                                              onClick={() =>
                                                                                // this.clickNoticountforTrainer(
                                                                                //   Request.notification_Id
                                                                                // )
                                                                                this.Addtrainerpop(Request.notification_Id)
                                                                              }
                                                                              style={{
                                                                                padding:
                                                                                  "0px",
                                                                                color:
                                                                                  "#000100",
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <span className="hoverable-span"
                                                                              //                                                                         style={{ display: "inline-block",
                                                                              // width: "800px",
                                                                              // whiteSpace: "nowrap",
                                                                              // overflow: "hidden",
                                                                              // textOverflow: "ellipsis"}}
                                                                              >
                                                                                {this.truncateTitle(Request.description, 100)}
                                                                              </span>{" "}
                                                                            </span>
                                                                            <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                              {Request.time}
                                                                            </span>
                                                                            <hr id="NotiHR"></hr>
                                                                          </>

                                                                        ) :
                                                                          (
                                                                            <></>
                                                                          )}
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>) : (
                                                      // Readed noti
                                                      <>
                                                      </>
                                                    )}
                                                  </>
                                                ))}
                                              </td>

                                            </tr>
                                          </tbody>
                                        </table> : null}
                                    </div></div></div>
                              </div>
                              <div id="tabs-section-4" className="j-tabs-section">
                                <div
                                  className="tab-pane"
                                  id="notes"
                                  role="tabpanel"
                                >
                                  <div className="card-block">
                                    <div className="dt-responsive table-responsive"
                                      id={Request.length <= 6 ? "AdminNotiNoAnnLength" : "Above10"}
                                    >
                                      {Request.length === 0 && delay ?
                                        <center>

                                          <p>No Record</p>
                                        </center>
                                        : null}
                                      {Request.length !== 0 ?
                                        <table
                                          id="add-row-table3" className="table nowrap">
                                          <thead id='tablepadding'><tr>

                                            <th style={{ color: '#7367f0' }}> Closed Details</th>

                                          </tr>
                                          </thead>
                                          <tbody id='Notitablepadding'>

                                            <tr>

                                              <td>

                                                {Request.map((Request) => (
                                                  <>
                                                    {Request.seen === true ? (
                                                      // Unreaded noti
                                                      <>
                                                        {Request.type === "ASkill" ? (
                                                          <>
                                                            {/* <Link
                                                    to="/MasterSkills"
                                                    onClick={() =>
                                                      this.Noticount(
                                                        Request.notification_Id
                                                      )
                                                    }
                                                    style={{
                                                      padding: "0px",
                                                      color: "gray",
                                                      cursor: "pointer",
                                                    }}
                                                  > */}
                                                            <span style={{
                                                              padding: "0px",
                                                              color: "gray",
                                                              cursor: "pointer",
                                                            }} onClick={() => this.displaypop(Request.notification_Id)}>

                                                              {" "}
                                                              <span className="hoverable-span"
                                                              //                                                       style={{ display: "inline-block",
                                                              // width: "800px",
                                                              // whiteSpace: "nowrap",
                                                              // overflow: "hidden",
                                                              // textOverflow: "ellipsis"}}
                                                              >
                                                                {this.truncateTitle(Request.description, 100)}
                                                              </span>{" "}
                                                              {/* </Link> */}
                                                              &nbsp;&nbsp;{" "}
                                                              <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                {Request.time}
                                                              </span>
                                                            </span>
                                                            &nbsp;&nbsp;{" "}
                                                            <hr id="NotiHR"></hr>
                                                          </>
                                                        ) : (
                                                          ///readed request
                                                          <>
                                                            {Request.type ===
                                                              "AEducation" ? (
                                                              <>
                                                                <span
                                                                  onClick={() =>
                                                                    this.displaypop(Request.notification_Id)
                                                                  }
                                                                  style={{
                                                                    padding: "0px",
                                                                    color: "gray",
                                                                    cursor: "pointer",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <span className="hoverable-span"
                                                                  //                                                            style={{ display: "inline-block",
                                                                  // width: "800px",
                                                                  // whiteSpace: "nowrap",
                                                                  // overflow: "hidden",
                                                                  // textOverflow: "ellipsis"}}
                                                                  >
                                                                    {this.truncateTitle(Request.description, 100)}
                                                                  </span>{" "}
                                                                </span>
                                                                &nbsp;&nbsp;{" "}
                                                                <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                  {Request.time}
                                                                </span>{" "}
                                                                &nbsp;&nbsp;{" "}
                                                                <hr id="NotiHR"></hr>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {Request.type ===
                                                                  "Aothers" ? (
                                                                  <>
                                                                    <span
                                                                      onClick={() => {
                                                                        this.clickNoticount(
                                                                          Request.notification_Id
                                                                        )
                                                                        this.displaypop(
                                                                          Request.notification_Id
                                                                        )

                                                                      }

                                                                      }
                                                                      style={{
                                                                        padding:
                                                                          "0px",
                                                                        color:
                                                                          "gray",
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      <span className="hoverable-span"
                                                                      //                                                                                   style={{ display: "inline-block",
                                                                      // width: "800px",
                                                                      // whiteSpace: "nowrap",
                                                                      // overflow: "hidden",
                                                                      // textOverflow: "ellipsis"}}
                                                                      >
                                                                        {this.truncateTitle(Request.description, 100)}
                                                                      </span>{" "}
                                                                    </span>
                                                                    &nbsp;&nbsp;{" "}
                                                                    <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                      {
                                                                        Request.time
                                                                      }
                                                                    </span>
                                                                    <hr id="NotiHR"></hr>
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {Request.type ===
                                                                      "Ageneral" ? (
                                                                      <>
                                                                        <span
                                                                          onClick={() => {
                                                                            this.clickNoticount(
                                                                              Request.notification_Id
                                                                            )
                                                                            this.displaypop(Request.notification_Id)
                                                                          }

                                                                          }
                                                                          style={{
                                                                            padding:
                                                                              "0px",
                                                                            color: "gray",
                                                                            cursor:
                                                                              "pointer",
                                                                          }}
                                                                        >
                                                                          {" "}
                                                                          <span className="hoverable-span"
                                                                          //                                                                   style={{ display: "inline-block",
                                                                          // width: "800px",
                                                                          // whiteSpace: "nowrap",
                                                                          // overflow: "hidden",
                                                                          // textOverflow: "ellipsis"}}
                                                                          >
                                                                            {this.truncateTitle(Request.description, 100)}
                                                                          </span>{" "}
                                                                        </span>
                                                                        &nbsp;&nbsp;{" "}
                                                                        <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                          {Request.time}
                                                                        </span>
                                                                        &nbsp;&nbsp;
                                                                        <hr id="NotiHR"></hr>
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {Request.type ===
                                                                          "ATrainer" ? (
                                                                          <>
                                                                            <span
                                                                              onClick={() =>
                                                                                // this.clickNoticountforTrainer(
                                                                                //   Request.notification_Id
                                                                                // )
                                                                                this.Addtrainerpop(Request.notification_Id)
                                                                              }
                                                                              style={{
                                                                                padding:
                                                                                  "0px",
                                                                                color:
                                                                                  "gray",
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <span className="hoverable-span"
                                                                              //                                                                                   style={{ display: "inline-block",
                                                                              // width: "800px",
                                                                              // whiteSpace: "nowrap",
                                                                              // overflow: "hidden",
                                                                              // textOverflow: "ellipsis"}}
                                                                              >
                                                                                {this.truncateTitle(Request.description, 100)}
                                                                              </span>{" "}
                                                                            </span>
                                                                            &nbsp;&nbsp;{" "}
                                                                            <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                              {
                                                                                Request.time
                                                                              }
                                                                            </span>
                                                                            <hr id="NotiHR"></hr>
                                                                          </>
                                                                        ) : (
                                                                          <>


                                                                          </>


                                                                        )}


                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>) : null}
                                                  </>
                                                ))}
                                              </td>

                                            </tr>
                                          </tbody>
                                        </table> : null}
                                    </div></div></div>
                              </div>
                            </div>

                            <div id="tabs-section-2" className="j-tabs-section">
                              <div
                                className="tab-pane"
                                id="notes"
                                role="tabpanel"
                              >

                                <div className="card-block">
                                  <div className="dt-responsive table-responsive"
                                    id={Request.length <= 6 ? "AdminNotiNoAnnLength" : "Above10"}
                                  >
                                    {Request.length === 0 && delay ?
                                      <center>

                                        <p>No Record</p>
                                      </center>
                                      : null}
                                    {Request.length !== 0 && delay ?
                                      <table
                                        id="add-row-table3" className="table nowrap">
                                        <thead id='tablepadding'><tr>
                                          <th style={{ color: '#7367f0' }}> Request Details</th>

                                        </tr>
                                        </thead>
                                        <tbody id='Notitablepadding'>
                                          <tr>
                                            <td>
                                              {Request.map((Request) => (
                                                <>
                                                  {Request.seen === false ? (
                                                    // Unreaded noti
                                                    <>
                                                      {Request.type === "ASkill" ? (
                                                        <>
                                                          <span
                                                            // to="/MasterSkills"
                                                            id="btnSkillLink"
                                                            style={{
                                                              padding: "0px",
                                                              color: "#000100",
                                                              cursor: "pointer",

                                                            }}
                                                            onClick={() => this.displaypop(Request.notification_Id)}
                                                          >
                                                            {" "}
                                                            <span className="hoverable-span"
                                                            //                                                        style={{ display: "inline-block",
                                                            // width: "800px",
                                                            // whiteSpace: "nowrap",
                                                            // overflow: "hidden",
                                                            // textOverflow: "ellipsis"}}
                                                            >
                                                              {this.truncateTitle(Request.description, 100)}
                                                              {/* {Request.description}{" "} */}
                                                            </span>{" "}
                                                          </span>
                                                          <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                            {Request.time}
                                                          </span>
                                                          &nbsp;&nbsp;
                                                          <hr id="NotiHR"></hr>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {Request.type ===
                                                            "AEducation" ? (
                                                            <>
                                                              <span
                                                                id="btnEduLink"
                                                                onClick={() => {
                                                                  // this.Noticount(
                                                                  //   Request.notification_Id
                                                                  // )
                                                                  this.displaypop(Request.notification_Id)
                                                                }

                                                                }
                                                                style={{
                                                                  padding: "0px",
                                                                  color: "#000100",
                                                                  cursor: "pointer",
                                                                }}
                                                              >
                                                                {" "}
                                                                <span className="hoverable-span"
                                                                //                                                           style={{ display: "inline-block",
                                                                // width: "800px",
                                                                // whiteSpace: "nowrap",
                                                                // overflow: "hidden",
                                                                // textOverflow: "ellipsis"}}
                                                                >
                                                                  {this.truncateTitle(Request.description, 100)}
                                                                </span>{" "}
                                                              </span>
                                                              <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                {Request.time}
                                                              </span>
                                                              &nbsp;&nbsp;
                                                              <hr id="NotiHR"></hr>
                                                            </>
                                                          ) : (
                                                            <>
                                                              {Request.type === "o" ? (
                                                                <>
                                                                  <Link
                                                                    to={
                                                                      this.state
                                                                        .Organization ===
                                                                        "4"
                                                                        ? "/AddOrganization"
                                                                        : "/Organization"
                                                                    }
                                                                    onClick={() =>
                                                                      this.Noticount(
                                                                        Request.notification_Id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      padding: "0px",
                                                                      color: "#000100",
                                                                      cursor: "pointer",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    <span className="hoverable-span"
                                                                    //                                                               style={{ display: "inline-block",
                                                                    // width: "800px",
                                                                    // whiteSpace: "nowrap",
                                                                    // overflow: "hidden",
                                                                    // textOverflow: "ellipsis"}}
                                                                    >
                                                                      {this.truncateTitle(Request.description, 100)}
                                                                    </span>{" "}
                                                                  </Link>
                                                                  <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                    {Request.time}
                                                                  </span>
                                                                  <hr id="NotiHR"></hr>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  {" "}
                                                                  {Request.type ===
                                                                    "Ageneral" ? (
                                                                    <>
                                                                      <span
                                                                        onClick={() => {
                                                                          // this.clickNoticount(
                                                                          //   Request.notification_Id
                                                                          // )
                                                                          this.displaypop(Request.notification_Id)
                                                                        }

                                                                        }
                                                                        style={{
                                                                          padding:
                                                                            "0px",
                                                                          color:
                                                                            "#000100",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        <span className="hoverable-span"
                                                                        //                                                                    style={{ display: "inline-block",
                                                                        // width: "800px",
                                                                        // whiteSpace: "nowrap",
                                                                        // overflow: "hidden",
                                                                        // textOverflow: "ellipsis"}}
                                                                        >
                                                                          {this.truncateTitle(Request.description, 100)}
                                                                        </span>{" "}
                                                                      </span>
                                                                      <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                        {Request.time}
                                                                      </span>
                                                                      <hr id="NotiHR"></hr>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      {Request.type ===
                                                                        "Access" ? (
                                                                        <>
                                                                          <Link
                                                                            to="/Settings"
                                                                            onClick={() =>
                                                                              this.Noticount(
                                                                                Request.notification_Id
                                                                              )
                                                                            }
                                                                            style={{
                                                                              padding:
                                                                                "0px",
                                                                              color:
                                                                                "#000100",
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                          >
                                                                            {" "}
                                                                            <span className="hoverable-span"
                                                                            //                                                                       style={{ display: "inline-block",
                                                                            // width: "800px",
                                                                            // whiteSpace: "nowrap",
                                                                            // overflow: "hidden",
                                                                            // textOverflow: "ellipsis"}}
                                                                            >
                                                                              {this.truncateTitle(Request.description, 100)}
                                                                            </span>{" "}
                                                                          </Link>
                                                                          <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                            {
                                                                              Request.time
                                                                            }
                                                                          </span>
                                                                          <hr id="NotiHR"></hr>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {Request.type ===
                                                                            "Accessed" ? (
                                                                            <>
                                                                              <Link
                                                                                to="/Settings"
                                                                                onClick={() =>
                                                                                  this.Noticount(
                                                                                    Request.notification_Id
                                                                                  )
                                                                                }
                                                                                style={{
                                                                                  padding:
                                                                                    "0px",
                                                                                  color:
                                                                                    "#000100",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <span className="hoverable-span"
                                                                                //                                                                            style={{ display: "inline-block",
                                                                                // width: "800px",
                                                                                // whiteSpace: "nowrap",
                                                                                // overflow: "hidden",
                                                                                // textOverflow: "ellipsis"}}
                                                                                >
                                                                                  {this.truncateTitle(Request.description, 100)}
                                                                                </span>{" "}
                                                                              </Link>
                                                                              <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                {
                                                                                  Request.time
                                                                                }
                                                                              </span>

                                                                              <hr id="NotiHR"></hr>
                                                                            </>
                                                                          ) : (
                                                                            <></>
                                                                          )}

                                                                          <>
                                                                            {Request.type ===
                                                                              "Aothers" ? (
                                                                              <>
                                                                                <span
                                                                                  onClick={() => {
                                                                                    // this.Noticount(
                                                                                    //   Request.notification_Id
                                                                                    // )
                                                                                    this.displaypop(Request.notification_Id)
                                                                                  }

                                                                                  }
                                                                                  style={{
                                                                                    padding:
                                                                                      "0px",
                                                                                    color:
                                                                                      "#000100",
                                                                                    cursor:
                                                                                      "pointer",

                                                                                  }}

                                                                                >
                                                                                  {" "}
                                                                                  <span className="hoverable-span"
                                                                                  //                                                                             style={{ display: "inline-block",
                                                                                  // width: "800px",
                                                                                  // whiteSpace: "nowrap",
                                                                                  // overflow: "hidden",
                                                                                  // textOverflow: "ellipsis"}}
                                                                                  >
                                                                                    {this.truncateTitle(Request.description, 100)}
                                                                                  </span>{" "}
                                                                                </span>
                                                                                <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                  {Request.time}
                                                                                </span>
                                                                                <hr id="NotiHR"></hr>
                                                                              </>
                                                                            ) : (
                                                                              <></>
                                                                            )}

                                                                          </>

                                                                          <>
                                                                            {Request.type ===
                                                                              "ATrainer" ? (
                                                                              <>
                                                                                <span
                                                                                  onClick={() =>
                                                                                    // this.clickNoticountforTrainer(
                                                                                    //   Request.notification_Id
                                                                                    // )
                                                                                    this.Addtrainerpop(Request.notification_Id)
                                                                                  }
                                                                                  style={{
                                                                                    padding:
                                                                                      "0px",
                                                                                    color:
                                                                                      "#000100",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                >
                                                                                  {" "}
                                                                                  <span className="hoverable-span"
                                                                                  //                                                                             style={{ display: "inline-block",
                                                                                  // width: "800px",
                                                                                  // whiteSpace: "nowrap",
                                                                                  // overflow: "hidden",
                                                                                  // textOverflow: "ellipsis"}}
                                                                                  >
                                                                                    {this.truncateTitle(Request.description, 100)}
                                                                                  </span>{" "}
                                                                                </span>
                                                                                <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                  {Request.time}
                                                                                </span>
                                                                                <hr id="NotiHR"></hr>
                                                                              </>
                                                                            ) : (
                                                                              <></>
                                                                            )}

                                                                          </>

                                                                        </>
                                                                      )}

                                                                    </>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}

                                                    </>
                                                  ) : (
                                                    // Readed noti
                                                    <>
                                                      {Request.type === "ASkill" ? (
                                                        <>
                                                          {/* <Link
                                                    to="/MasterSkills"
                                                    onClick={() =>
                                                      this.Noticount(
                                                        Request.notification_Id
                                                      )
                                                    }
                                                    style={{
                                                      padding: "0px",
                                                      color: "gray",
                                                      cursor: "pointer",
                                                    }}
                                                  > */}
                                                          <span style={{
                                                            padding: "0px",
                                                            color: "gray",
                                                            cursor: "pointer",
                                                          }} onClick={() => this.displaypop(Request.notification_Id)}>

                                                            {" "}
                                                            <span className="hoverable-span"
                                                            //                                                        style={{ display: "inline-block",
                                                            // width: "800px",
                                                            // whiteSpace: "nowrap",
                                                            // overflow: "hidden",
                                                            // textOverflow: "ellipsis"}}
                                                            >
                                                              {this.truncateTitle(Request.description, 100)}
                                                            </span>{" "}
                                                            {/* </Link> */}
                                                            &nbsp;&nbsp;{" "}
                                                            <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                              {Request.time}
                                                            </span>
                                                          </span>
                                                          &nbsp;&nbsp;{" "}
                                                          <hr id="NotiHR"></hr>
                                                        </>
                                                      ) : (
                                                        ///readed request
                                                        <>
                                                          {Request.type ===
                                                            "AEducation" ? (
                                                            <>
                                                              <span
                                                                onClick={() =>
                                                                  this.displaypop(Request.notification_Id)
                                                                }
                                                                style={{
                                                                  padding: "0px",
                                                                  color: "gray",
                                                                  cursor: "pointer",
                                                                }}
                                                              >
                                                                {" "}
                                                                <span className="hoverable-span"
                                                                //                                                           style={{ display: "inline-block",
                                                                // width: "800px",
                                                                // whiteSpace: "nowrap",
                                                                // overflow: "hidden",
                                                                // textOverflow: "ellipsis"}}
                                                                >
                                                                  {this.truncateTitle(Request.description, 100)}
                                                                </span>{" "}
                                                              </span>
                                                              &nbsp;&nbsp;{" "}
                                                              <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                {Request.time}
                                                              </span>{" "}
                                                              &nbsp;&nbsp;{" "}
                                                              <hr id="NotiHR"></hr>
                                                            </>
                                                          ) : (
                                                            <>
                                                              {Request.type === "o" ? (
                                                                <>
                                                                  <Link
                                                                    to="/Organization"
                                                                    onClick={() =>
                                                                      this.Noticount(
                                                                        Request.notification_Id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      padding: "0px",
                                                                      color: "gray",
                                                                      cursor: "pointer",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    <span className="hoverable-span"
                                                                    //                                                               style={{ display: "inline-block",
                                                                    // width: "800px",
                                                                    // whiteSpace: "nowrap",
                                                                    // overflow: "hidden",
                                                                    // textOverflow: "ellipsis"}}
                                                                    >
                                                                      {this.truncateTitle(Request.description, 100)}
                                                                    </span>{" "}
                                                                  </Link>
                                                                  &nbsp;&nbsp;{" "}
                                                                  {Request.requestStatus ? (
                                                                    <span
                                                                      style={{
                                                                        color: "green",
                                                                      }}
                                                                    >
                                                                      Agreed
                                                                    </span>
                                                                  ) : (
                                                                    <span
                                                                      style={{
                                                                        color: "Red",
                                                                      }}
                                                                    >
                                                                      Disagreed
                                                                    </span>
                                                                  )}
                                                                  &nbsp;&nbsp;{" "}
                                                                  <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                    {Request.time}
                                                                  </span>
                                                                  <hr id="NotiHR"></hr>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  {Request.type ===
                                                                    "Ageneral" ? (
                                                                    <>
                                                                      <span
                                                                        onClick={() => {
                                                                          // this.clickNoticount(
                                                                          //   Request.notification_Id
                                                                          // )
                                                                          this.displaypop(Request.notification_Id)
                                                                        }

                                                                        }
                                                                        style={{
                                                                          padding:
                                                                            "0px",
                                                                          color: "gray",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        <span className="hoverable-span"
                                                                        //                                                                   style={{ display: "inline-block",
                                                                        // width: "800px",
                                                                        // whiteSpace: "nowrap",
                                                                        // overflow: "hidden",
                                                                        // textOverflow: "ellipsis"}}
                                                                        >
                                                                          {this.truncateTitle(Request.description, 100)}
                                                                        </span>{" "}
                                                                      </span>
                                                                      &nbsp;&nbsp;{" "}
                                                                      <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                        {Request.time}
                                                                      </span>
                                                                      &nbsp;&nbsp;
                                                                      <hr id="NotiHR"></hr>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      {Request.type ===
                                                                        "Access" ? (
                                                                        <>
                                                                          <Link
                                                                            to="/Settings"
                                                                            onClick={() =>
                                                                              this.Noticount(
                                                                                Request.notification_Id
                                                                              )
                                                                            }
                                                                            style={{
                                                                              padding:
                                                                                "0px",
                                                                              color:
                                                                                "gray",
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                          >
                                                                            {" "}
                                                                            <span className="hoverable-span"
                                                                            //                                                                       style={{ display: "inline-block",
                                                                            // width: "800px",
                                                                            // whiteSpace: "nowrap",
                                                                            // overflow: "hidden",
                                                                            // textOverflow: "ellipsis"}}
                                                                            >
                                                                              {this.truncateTitle(Request.description, 100)}
                                                                            </span>{" "}
                                                                          </Link>
                                                                          <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                            {
                                                                              Request.time
                                                                            }
                                                                          </span>
                                                                          <hr id="NotiHR"></hr>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {Request.type ===
                                                                            "Turned Off" ? (
                                                                            <>
                                                                              <Link
                                                                                to="/Settings"
                                                                                onClick={() =>
                                                                                  this.Noticount(
                                                                                    Request.notification_Id
                                                                                  )
                                                                                }
                                                                                style={{
                                                                                  padding:
                                                                                    "0px",
                                                                                  color:
                                                                                    "gray",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <span className="hoverable-span"
                                                                                //                                                                            style={{ display: "inline-block",
                                                                                // width: "800px",
                                                                                // whiteSpace: "nowrap",
                                                                                // overflow: "hidden",
                                                                                // textOverflow: "ellipsis"}}
                                                                                >
                                                                                  {this.truncateTitle(Request.description, 100)}
                                                                                </span>{" "}
                                                                              </Link>
                                                                              <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                {
                                                                                  Request.time
                                                                                }
                                                                              </span>

                                                                              <hr id="NotiHR"></hr>
                                                                            </>
                                                                          ) : (
                                                                            <>
                                                                              {Request.type ===
                                                                                "Accessed" ? (
                                                                                <>
                                                                                  <Link
                                                                                    to="/Settings"
                                                                                    onClick={() =>
                                                                                      this.Noticount(
                                                                                        Request.notification_Id
                                                                                      )
                                                                                    }
                                                                                    style={{
                                                                                      padding:
                                                                                        "0px",
                                                                                      color:
                                                                                        "gray",
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                  >
                                                                                    {" "}
                                                                                    <span className="hoverable-span"
                                                                                    //                                                                                style={{ display: "inline-block",
                                                                                    // width: "800px",
                                                                                    // whiteSpace: "nowrap",
                                                                                    // overflow: "hidden",
                                                                                    // textOverflow: "ellipsis"}}
                                                                                    >
                                                                                      {this.truncateTitle(Request.description, 100)}
                                                                                    </span>{" "}
                                                                                  </Link>
                                                                                  <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                    {
                                                                                      Request.time
                                                                                    }
                                                                                  </span>

                                                                                  <hr id="NotiHR"></hr>
                                                                                </>
                                                                              ) : (
                                                                                <>
                                                                                  {Request.type ===
                                                                                    "Aothers" ? (
                                                                                    <>
                                                                                      <span
                                                                                        onClick={() => {
                                                                                          // this.clickNoticount(
                                                                                          //   Request.notification_Id
                                                                                          // )
                                                                                          this.displaypop(
                                                                                            Request.notification_Id
                                                                                          )

                                                                                        }

                                                                                        }
                                                                                        style={{
                                                                                          padding:
                                                                                            "0px",
                                                                                          color:
                                                                                            "gray",
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        }}
                                                                                      >
                                                                                        {" "}
                                                                                        <span className="hoverable-span"
                                                                                        //                                                                                   style={{ display: "inline-block",
                                                                                        // width: "800px",
                                                                                        // whiteSpace: "nowrap",
                                                                                        // overflow: "hidden",
                                                                                        // textOverflow: "ellipsis"}}
                                                                                        >
                                                                                          {this.truncateTitle(Request.description, 100)}
                                                                                        </span>{" "}
                                                                                      </span>
                                                                                      &nbsp;&nbsp;{" "}
                                                                                      <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                        {
                                                                                          Request.time
                                                                                        }
                                                                                      </span>
                                                                                      <hr id="NotiHR"></hr>
                                                                                    </>
                                                                                  ) : (
                                                                                    <></>
                                                                                  )}

                                                                                  {Request.type ===
                                                                                    "ATrainer" ? (
                                                                                    <>
                                                                                      <span
                                                                                        onClick={() =>
                                                                                          // this.clickNoticountforTrainer(
                                                                                          //   Request.notification_Id
                                                                                          // )
                                                                                          this.Addtrainerpop(Request.notification_Id)
                                                                                        }
                                                                                        style={{
                                                                                          padding:
                                                                                            "0px",
                                                                                          color:
                                                                                            "gray",
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        }}
                                                                                      >
                                                                                        {" "}
                                                                                        <span className="hoverable-span"
                                                                                        //                                                                                    style={{ display: "inline-block",
                                                                                        // width: "800px",
                                                                                        // whiteSpace: "nowrap",
                                                                                        // overflow: "hidden",
                                                                                        // textOverflow: "ellipsis"}}
                                                                                        >
                                                                                          {this.truncateTitle(Request.description, 100)}
                                                                                        </span>{" "}
                                                                                      </span>
                                                                                      &nbsp;&nbsp;{" "}
                                                                                      <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                                                        {
                                                                                          Request.time
                                                                                        }
                                                                                      </span>
                                                                                      <hr id="NotiHR"></hr>
                                                                                    </>
                                                                                  ) : (
                                                                                    <></>
                                                                                  )}
                                                                                </>

                                                                              )


                                                                              }
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                      {/* //<Link to="/Organization" onClick={() => this.Noticount(Request.notification_Id)} style={{ padding: "0px", color: "#000100", cursor: "pointer" }}> <b>{Request.description} </b> </Link><span className="notification-time">{Request.time}</span> */}
                                                    </>
                                                  )}
                                                </>
                                              ))}
                                            </td>

                                          </tr>
                                        </tbody>
                                      </table> : null}

                                  </div></div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {Filterpop && (
                  <div className="popSearchTeam">
                    <div className="popSearchinnernormalTeam">
                      <div
                        className="popSearchinnertitle"
                        id="popSearchinnernormal"
                      >
                        <button
                          id="close"
                          style={{
                            float: "right",
                            backgroundColor: "#a0a3a305",
                            color: "#797070",
                            border: "1px solid #a3a5a505",
                            marginTop: "-20px",
                            marginRight: "-20px",
                          }}
                          onClick={() => {
                            this.setState({ Filterpop: false });
                            this.GetAdNotification();
                          }}
                        >
                          <CloseIcon />
                        </button>

                        <div className="checkboxes">
                          <i
                            className="fa fa-search"
                            aria-hidden="true"
                            style={{
                              fontSize: "20px",
                              color: "gray",
                              marginLeft: "-1%",
                              position: "absolute",
                              marginTop: "0.5%",
                            }}
                          ></i>
                          <input
                            className="Searchbox"
                            placeholder="Search"
                            onChange={this.filterfunction}
                            style={{ float: "right", width: "80%" }}
                          />
                          <br></br>
                          {this.state.letters.map(
                            (letter, index) => (
                              <button
                                className="filter"
                                key={index}
                                onClick={(event) =>
                                  this.handleFilter(
                                    letter,
                                    event
                                  )
                                }
                              >
                                {letter}
                              </button>
                            )
                          )}
                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isOpen ?
                  <div className="col-lg-12">
                    <div className="popup" style={{ textAlign: 'left' }}>
                      <div className="popup-content">
                        <div className="Announcedetailspop" >
                          <i
                            className="fa fa-times"
                            aria-hidden="true"
                            style={{
                              color: "gray",
                              marginLeft: "90%",
                              fontSize: "17px",
                              cursor: 'pointer'
                            }}
                            onClick={this.displaypop}
                          ></i>
                          {Request.map((Request) => {
                            if (Request.notification_Id === NotificationId)
                              return <>
                                <br />
                                <center><h6><b>Request Details</b></h6></center>

                                <div className="card-block">

                                  <div
                                  >
                                    <span
                                      // key={Announcement.id}
                                      style={{ margin: "0%" }}
                                    >

                                      <b>Subject:</b> &nbsp; <span
                                        style={{ overflowWrap: "break-word", width: "50%", }}
                                      > {Request.title}</span><br /><br />
                                      <b>Description:</b> &nbsp; <span
                                        style={{ overflowWrap: "break-word", width: "50%", }}>{Request.description.split("sent an Request as ")[1].replace(/"/g, '')}</span>
                                      <br /><br />
                                      <b>Request Type:</b> &nbsp; <span>{Request.type.slice(1)}</span>
                                      <br /><br />
                                      <b>Sent by:</b> &nbsp; <span>{Request.description.split("sent an Request as")[0]}</span>
                                    </span>
                                  </div>

                                  {/* <table>
                                    <tbody id='tablepadding'>
                                      <tr>
                                        <td>Subject&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</td>
                                        <td>{Request.title}</td>
                                      </tr>
                                      <tr>
                                        <td>Description&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</td>
                                        <td style={{ whiteSpace: 'pre-wrap', minWidth: '420px', maxWidth: '420px' }}>
  {Request.description.split("sent an Request as ")[1].replace(/"/g, '')}
</td>
                                      </tr>
                                      <tr>
                                        <td>Request Type&nbsp;&nbsp;:</td>
                                        <td>{Request.type.slice(1)}</td>
                                      </tr>
                                      <tr>
                                        <td>Sent by&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</td>
                                        <td>{Request.description.split("sent an Request as")[0]}</td>
                                      </tr>
                                      <tr>
                                      </tr>
                                    </tbody>
                                  </table> */}
                                </div>
                                {Request.seen === true && Request.type != "Aothers" && Request.type != "Ageneral" ?
                                  Request.requestStatus === true ?
                                    <>
                                      <center>

                                        <span
                                          style={{ color: "green" }}
                                        >
                                          Agreed
                                        </span>
                                      </center>
                                    </>
                                    :
                                    <>
                                      <center>
                                        <span
                                          style={{
                                            color: "Red",
                                          }}
                                        >
                                          Disagreed
                                        </span>
                                      </center>
                                    </>
                                  :

                                  Request.type === "ASkill" ? <center>

                                    <Link to="/MasterSkills">
                                      <button
                                        className="button-30 "
                                        onClick={() => {
                                          this.Request(
                                            Request.notification_Id,
                                            1, Request.requestId
                                          );
                                          this.Noticount(
                                            Request.notification_Id
                                          );
                                        }}
                                        type="button"
                                        style={{ color: "green" }}
                                        id="addRow"
                                      >
                                        Agree&nbsp;
                                      </button>{" "}
                                      &nbsp;&nbsp;
                                    </Link>
                                    <button
                                      type="button"
                                      style={{ color: "red" }}
                                      className="button-30"
                                      onClick={() => {
                                        this.Request(
                                          Request.notification_Id,
                                          0, Request.requestId
                                        );
                                        this.Noticount(
                                          Request.notification_Id
                                        );
                                      }}
                                    >
                                      Disagree
                                    </button>
                                  </center> :
                                    Request.type === "AEducation" ?
                                      <>
                                        <center>
                                          <Link
                                            to="/Education">
                                            <button
                                              className="button-30 "
                                              style={{
                                                color: "green",
                                              }}
                                              onClick={() => {
                                                this.Request(
                                                  Request.notification_Id,
                                                  1, Request.requestId
                                                );
                                                this.Noticount(
                                                  Request.notification_Id
                                                );
                                              }}
                                            >
                                              Agree&nbsp;
                                            </button>{" "}
                                            &nbsp;&nbsp;
                                          </Link>
                                          <button
                                            className="button-30"
                                            style={{ color: "red" }}
                                            onClick={() => {
                                              this.Request(
                                                Request.notification_Id,
                                                0, Request.requestId
                                              );
                                              this.Noticount(
                                                Request.notification_Id
                                              );
                                            }}
                                          >
                                            Disagree
                                          </button>
                                        </center>
                                      </>
                                      : Request.type === "Ageneral" ?
                                        <>
                                          <center>
                                            {Request.seen === false ?
                                              <button
                                                className="button-30 "
                                                style={{
                                                  color: "green",
                                                }}
                                                onClick={() => {
                                                  this.Request(
                                                    Request.notification_Id,
                                                    1, Request.requestId
                                                  );
                                                  this.Noticount(
                                                    Request.notification_Id
                                                  );
                                                  this.GetSeenCount();
                                                  this.setState({
                                                    isOpen: false
                                                  })
                                                }}
                                              >
                                                OK&nbsp;
                                              </button> : null}{" "}
                                            &nbsp;&nbsp;
                                          </center>
                                        </> : Request.type === "Aothers" ?
                                          <>
                                            <center>
                                              {Request.seen === false ?
                                                <button
                                                  className="button-30 "
                                                  style={{
                                                    color: "green",
                                                  }}
                                                  onClick={() => {
                                                    this.Request(
                                                      Request.notification_Id,
                                                      1, Request.requestId
                                                    );
                                                    this.Noticount(
                                                      Request.notification_Id
                                                    );
                                                    this.GetSeenCount();
                                                    this.setState({
                                                      isOpen: false
                                                    })
                                                  }}
                                                >
                                                  OK&nbsp;
                                                </button> : null}

                                              {" "}
                                              &nbsp;&nbsp;
                                            </center>
                                          </> : null

                                }

                              </>
                          })}
                        </div> </div> </div></div> : null}

                {isAddTrainerOpen ?
                  <div className="col-lg-12">
                    <div className="popup" style={{ textAlign: 'left' }}>
                      <div className="popup-content">
                        <div className="Announcedetailspop" >
                          <i
                            className="fa fa-times"
                            aria-hidden="true"
                            style={{
                              color: "gray",
                              marginLeft: "90%",
                              fontSize: "17px",
                              cursor: 'pointer'
                            }}
                            onClick={this.Addtrainerpop}
                          ></i>
                          {Request.map((Request) => {
                            if (Request.notification_Id === NotificationId)
                              return <>
                                <br />
                                <center><h6><b>Request Details</b></h6></center>

                                <div className="card-block">



                                  <table>
                                    <tbody id='tablepadding'>
                                      <tr>
                                        <td>Description&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{" "}{" "}{" "}{" "}{Request.description.split("wants to be a trainer for")[1]}</td>
                                      </tr>
                                      {" "}
                                      <tr>
                                        <td>Request Type&nbsp;&nbsp;:&nbsp;&nbsp;{Request.type.slice(1)}</td>
                                      </tr>
                                      {" "}
                                      <tr>
                                        <td>Sent by&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{Request.description.split("wants to be a trainer for")[0]}</td>
                                      </tr>
                                      <tr>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                {
                                  Request.seen === false ?
                                    <center>

                                      <Link to="/MasterSkills">
                                        <button
                                          className="button-30 "
                                          onClick={() => {
                                            this.clickNoticountforTrainer(
                                              Request.notification_Id,
                                              Request.emtId
                                            )
                                          }}
                                          type="button"
                                          style={{ color: "green" }}
                                          id="addRow"
                                        >
                                          Yes&nbsp;
                                        </button>{" "}
                                        &nbsp;&nbsp;
                                      </Link>
                                      <button
                                        type="button"
                                        style={{ color: "red" }}
                                        className="button-30"
                                        onClick={() => {
                                          this.clickNoticountforTrainerforNo(
                                            Request.notification_Id
                                          )
                                        }}
                                      >
                                        No
                                      </button>
                                    </center> : null
                                }


                              </>
                          })}
                        </div> </div> </div></div> : null}
              </div>
            </div>
          </div>
        </div>

        {/* End */}
      </>
    );
  }
}
