import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPopup1 from "./SuccessPopup1";
import LastChatpop from "./LastChatpop";
import { Button } from "@material-ui/core";
import $ from "jquery";
import { Announcement } from "@material-ui/icons";
import { Link } from "react-router-dom";
import SearchPopup from "./SearchPopup";
import { BASE_URLs } from "../Config/Base";
export default class EntityList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcement: [],
      AllAnnouncement: [],
      showing: true,
      profile: [],
      EditSkillModel: false,
      LastChatModel: false,
      EditSkillValues: [],
      SearchModel: false,
      SearchValues: [],
      Id: "",
      firstName: "",
      isButton1Visible: true,
      isButton2Visible: true,
      allmessage: "",
      usermsgId: "",
      email: "",
      displayName: "",
      receivemsg: "",
      Skillname: "",
      company_Name: "",
      company_Id: "",
      User: [],
      Alluser: [],
      childVisible: false,
    };
    this.initialState = this.state;
  }
  onClick() {
    this.setState({ childVisible: !this.state.childVisible });
  }
  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/status1`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          User: data,
          Alluser: data,
        });
        // // console.log("user data", data);
      });
  }

  handleButtonClick1 = () => {
    this.setState((prevState) => ({
      isButton2Visible: !prevState.isButton2Visible,
      isButton1Visible: true,
    }));
    if (this.state.LastChatModel) {
      // alert("hai")
      // document.getElementById("Chat-MainA").style.marginTop = "6vh";
    }
  };
  handleButtonClick2 = () => {
    document.getElementById("EntityList").style.display = "none";

    document.getElementById("searchpop").style.display = "inline-flex";
  };

  EditSkillModel = (e, fn, al, uid, rmsg, em, mb) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditSkillValues: {
        id: e,
        firstName: fn,
        allmessage: al,
        usermsgId: uid,
        receivemsg: rmsg,
        email: em,
        displayName: mb,
      },
    });
    this.setState((prevState) => ({
      EditSkillModel: !prevState.EditSkillModel,
    }));
  };
  LastChatModel = () => {
    this.setState((prevState) => ({
      LastChatModel: !prevState.LastChatModel,
    }));
  };
  SearchModel = (id, uid, cid, em, cn) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      SearchValues: {
        id: id,
        userId: uid,
        company_Id: cid,
        email: em,
        company_Name: cn,
      },
    });
    this.setState((prevState) => ({
      SearchModel: !prevState.SearchModel,
    }));
  };
  GetAnnouncement(Id) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/UserBank/GetAllUser?Id=` + Id, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcement: data,
          AllAnnouncement: data,
        });
        // console.log("ChatListUser", data);
      });
  }
  myFunction = (e) => {
    const Announcement = this.state.AllAnnouncement.filter(
      (item) =>
        item.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.adminName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Announcement });
  };

  OATSearch = (e) => {
    const User = this.state.Alluser.filter((item) =>
      item.company_Name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ User });
  };
  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }
  componentDidMount() {
    this.GetAnnouncement();
    this.UserOrg();
  }

  render() {
    var w = window.innerWidth;
    var username = JSON.parse(localStorage.uname);

    const { Announcement, User, isButton1Visible, isButton2Visible } =
      this.state;
    return (
      <>
        <div>
          {this.state.EditSkillModel ? (
            <div className="row">
              <div className="col-lg-6">
                <SuccessPopup1
                  id=""
                  values={this.state.EditSkillValues}
                  close={() => this.EditSkillModel()}
                />
              </div>
            </div>
          ) : null}
          {this.state.LastChatModel ? (
            <div className="row" id="Chat-MainA">
              <div className="col-lg-6">
                <LastChatpop
                  id=""
                  // values={this.state.EditSkillValues}
                  close={() => this.LastChatModel()}
                />
              </div>
            </div>
          ) : null}
          {this.state.SearchModel ? (
            <SearchPopup
              id=""
              values={this.state.SearchValues}
              close={() => this.SearchModel()}
            />
          ) : null}

          <div className="pcoded-content" id="EntityList">
            <div className="pcoded-inner-content">
              <div className="main-body chat-bg">
                <div className="">
                  <div id="" className="">
                    <div className="page-body">
                      <div className="row">
                        {isButton1Visible && (
                          <div className="chat-box">
                            <div id="sidebar" className="users p-chat-user">
                              <div className="had-container">
                                <div
                                  className="card card_main p-fixed users-main "
                                  style={{ overflow: "scroll" }}
                                >
                                  <div className="user-box">
                                    <div id="tsum-tabs">
                                      <main id="maintab">
                                        <input
                                          id="tab1"
                                          type="radio"
                                          name="tabs"
                                          defaultChecked
                                        />
                                        <label
                                          id="supertab"
                                          className="j-tabs-label"
                                          htmlFor="tab1"
                                          title="In Entity"
                                          color="white"
                                        >
                                          In Entity
                                        </label>
                                        <input
                                          id="tab2"
                                          type="radio"
                                          name="tabs"
                                        />
                                        <label
                                          id="activetab"
                                          className="j-tabs-label"
                                          htmlFor="tab2"
                                          title="In OAT"
                                          color="white"
                                        >
                                          In OAT
                                        </label>

                                        {/* <input id="tab3" type="radio" name="tabs"/>
    <label for="tab3">Invite</label>  */}
                                        <br />
                                        <br />
                                        <div
                                          id="tabs-section-1"
                                          className="j-tabs-section"
                                        >
                                          <div className="right-icon-control">
                                            <input
                                              type="text"
                                              className="form-control  search-text"
                                              placeholder="Search"
                                              onChange={this.myFunction}
                                            />
                                            <div className="form-icon">
                                              <i className="icofont icofont-search"></i>
                                            </div>
                                          </div>
                                          <br />
                                          {/* {Announcement.length > 0 && ( */}

                                          <>
                                            {Announcement.map(
                                              (Announcement) => (
                                                <>
                                                  <div
                                                    className="media userlist-box"
                                                    data-id="1"
                                                    data-status="online"
                                                    data-toggle="tooltip"
                                                    data-placement="left"
                                                    key={
                                                      (Announcement.id,
                                                      Announcement.usermsgId)
                                                    }
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.EditSkillModel(
                                                        Announcement.id,
                                                        Announcement.firstName,
                                                        Announcement.allmessage,
                                                        Announcement.usermsgId,
                                                        Announcement.receivemsg,
                                                        Announcement.email,
                                                        Announcement.displayName
                                                      );
                                                      this.handleButtonClick1();
                                                      // this.LastChatModel();
                                                    }}
                                                  >
                                                    <a className="media-left">
                                                      <img
                                                        className="media-object img-circle"
                                                        src="assets/images/avatar-1.png"
                                                        alt="Generic placeholder image"
                                                      />
                                                    </a>
                                                    <div
                                                      className="media-body"
                                                      style={{
                                                        textAlign: "initial",
                                                      }}
                                                    >
                                                      <div className="f-13 chat-header">
                                                        {" "}
                                                        {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            whiteSpace:
                                                              "nowrap",
                                                          }}
                                                        >
                                                          {
                                                            Announcement.firstName
                                                          }
                                                        </span>
                                                        <br />
                                                        <span
                                                          style={{
                                                            color: "Gray",
                                                            whiteSpace:
                                                              "nowrap",
                                                          }}
                                                        >
                                                          {username}
                                                        </span>
                                                        <br />
                                                        <span
                                                          style={{
                                                            color: "Gray",
                                                            whiteSpace:
                                                              "nowrap",
                                                          }}
                                                        >
                                                          {Announcement.email}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                            )}
                                          </>
                                        </div>

                                        <div
                                          id="tabs-section-2"
                                          className="j-tabs-section"
                                        >
                                          <div className="right-icon-control">
                                            <input
                                              type="text"
                                              className="form-control  search-text"
                                              placeholder="Search"
                                              onChange={this.OATSearch}
                                            />
                                            <div className="form-icon">
                                              <i className="icofont icofont-search"></i>
                                            </div>
                                          </div>
                                          <br />

                                          {User.map((user) => (
                                            <div
                                              className="media userlist-box"
                                              data-id="2"
                                              data-status="online"
                                              data-username="Josephin Doe"
                                              data-toggle="tooltip"
                                              data-placement="left"
                                              title="Josephin Doe"
                                            >
                                              <a className="media-left">
                                                <img
                                                  className="media-object img-circle"
                                                  src="assets/images/avatar-1.png"
                                                  alt="Generic placeholder image"
                                                />
                                              </a>
                                              <div
                                                className="media-body"
                                                style={{ textAlign: "initial" }}
                                              >
                                                <div
                                                  className="f-13 chat-header"
                                                  style={{ color: "black" }}
                                                >
                                                  <div>
                                                    <div
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.SearchModel(
                                                          user.id,
                                                          user.userId,
                                                          user.company_Id,
                                                          user.email,
                                                          user.company_Name
                                                        );
                                                        setTimeout(() => {
                                                          this.handleButtonClick2();
                                                        }, 5);
                                                      }}
                                                    >
                                                      {user.company_Name}
                                                    </div>

                                                    {/* {Announcement.map((Announcement)=>(   <>
        {
         
          this.state.childVisible
            ? 
            <div className="row">
              <div className="col-lg-8"><li style={{listStyleType:"none",fontSize:"larger",fontWeight:"100"}}>{Announcement.firstName}</li></div>
              <div className="col-lg-4"><Link onClick={(e) => {
              e.preventDefault();
              this.EditSkillModel(
                Announcement.id
              );
            }}>Add</Link></div>
              </div>
            : null
        }
        </>
        ))} */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </main>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="styleSelector"></div>
            </div>
          </div>
        </div>
        {/* <div className="pop" style={{backgroundColor:"transparent"}}>
       <div className= {w>768 ?"popinnernormal oneFieldPop":"popinnernormal oneFieldPopMobile"} style={{padding:"1%"}}> 
      <form  autoComplete="off" encType="multipart/form-data"  >
 

           
 
   
      
    


   
  <br/>

           </form>
      </div> 
   </div>   */}
      </>
    );
  }
}
