import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
export default class EditDesignationModel extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    this.state = {
      formErrors: {},
      EducationDrop: [],
      EducationCourseName: [],
      isButtonDisabled: false,
      isChrome: false,
      maxDateS: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      isChrome1: /Chrome/.test(navigator.userAgent),
      isFirefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
      DisFire: false,
      DisFireStart: false,
      startDate: this.props.value.CourseStartDate ? new Date(this.props.value.CourseStartDate) : null,
      endDate: this.props.value.CourseEndDate ? new Date(this.props.value.CourseEndDate) : null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UserEducationDropdownList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/UserEducationDetails/GetQualificationtype`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ EducationDrop: data });
      });
  }
  componentDidMount() {
    this.UserEducationDropdownList();
    const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    this.setState({ isChrome });
  }


  handlevalueChange = (e) => {
    e.preventDefault();
    var value = e.target.value;
    //e.target.value

    if (
      e.target.value === "Graduation" ||
      e.target.value === "Post Graduation" ||
      e.target.value === "Doctorate"
    ) {
      var flag =
        value === "Graduation"
          ? "1"
          : value === "Post Graduation"
            ? "2"
            : value === "Doctorate"
              ? "3"
              : null;

      const options = {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      };
      fetch(
        ` ${BASE_URLs}/UserEducationDetails/GetAdminEducationcoursename?flag=${flag}`,
        options
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({ EducationCourseName: data });
        });
    } else {
      this.setState({ EducationCourseName: [] });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    if (this.Validation()) {

      const url = `${BASE_URLs}/UserEducationDetails/EditAdminEducation`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          document.getElementById("close").click();
        }, 4000);
        this.setState({ isButtonDisabled: true });
      }
      else if (result.status === "Error") {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({ isButtonDisabled: false });
        // setTimeout(function () {
        //   document.getElementById("close").click();
        // }, 4000);
      }


    }
  };

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var Qualification = document.getElementById("Qualification").value;
    var St_date = document.getElementById("St_date").value;
    var Coursename = document.getElementById("Coursename").value;
    var End_date = document.getElementById("End_date").value;
    var Coursetype = document.getElementById("Coursetype").value;
    var clg_name = document.getElementById("clg_name").value;
    var stream = document.getElementById("stream").value;
    var unisty_name = document.getElementById("unisty_name").value;
    var OthersQualification = document.getElementById("othersQualification").value;

    if (!Qualification) {
      formIsValid = false;
      formErrors["Qualification"] = "Qualification  is required";
    }
    if (Qualification === 'others' && !OthersQualification) {
      formIsValid = false;
      formErrors["Qualification"] = "Qualification  is required";
    } else if (OthersQualification !== "" && OthersQualification.length < 3) {
      formIsValid = false;
      formErrors["Qualification"] = "A Minimum of 3 characters are required";
    } 

    if (!St_date) {
      formIsValid = false;
      formErrors["St_date"] = "Start From is required";
    }
    // else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(PersonalEmail)))   {
    //   formIsValid = false;
    //   formErrors["PersonalEmailerr"] = "Enter valid Email!.";
    //}
    if (!Coursename) {
      formIsValid = false;
      formErrors["Coursename"] = "Course Name is required";
    }
    else if (Coursename.length < 2) {
      formIsValid = false;
      formErrors["Coursename"] = "A Minimum of 2 characters is required";
    }
    // if (Qualification === 'others' && Coursename.length < 3) {
    //   formIsValid = false;
    //   formErrors["Coursename"] = "A Minimum of 3 characters are required";
    // }
    // else if (Qualification !== "Graduation" && Qualification !== "Post Graduation" && Qualification !== "Doctorate" && Coursename.length < 3 && Coursename !== "-") {
    //   formIsValid = false;
    //   formErrors["Coursename"] = <div style={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>A Minimum of 3 characters are required</div>;
    // }

    if (!End_date) {
      formIsValid = false;
      formErrors["End_date"] = "End is required";
    } else if (St_date != null && End_date != null) {
      if (St_date === End_date) {
        formIsValid = false;
        formErrors["End_date"] = <div style={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>Start Date and End Date  is Same</div>;
      }
      if (new Date(St_date).getTime() > new Date(End_date).getTime()) {
        formIsValid = false;

        formErrors["End_date"] = <div style={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>Start Date Should less then  End Date</div>;
      }
    }

    if (!Coursetype) {
      formIsValid = false;
      formErrors["Coursetype"] = "Course Type is required";
    }

    if (clg_name.trim() !== "" && clg_name !== "-" && clg_name.length < 3) {
      formIsValid = false;
      formErrors['clg_name'] = (
        <div style={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>
          A minimum of 3 characters is required
        </div>
      );
    }

    if (stream.trim() !== "" && stream !== "-" && stream.length < 3) {
      formIsValid = false;
      formErrors['stream'] = (
        <div style={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>
          A minimum of 3 characters is required
        </div>
      );
    }

    if (!unisty_name) {
      formIsValid = false;
      formErrors["unisty_name"] = "University Name is required";
    } else if (unisty_name.length < 3 && unisty_name !== "-") {
      formIsValid = false;
      formErrors["unisty_name"] = <div style={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>A Minimum of 3 characters are required</div>;
    }

    this.setState({ formErrors: formErrors });

    //document.getElementById("add-row").style.display = "none";
    return formIsValid;
  }

  handleEducationChange = (e) => {
    let formErrors = {};
    let formIsValid = true;

    var Qualification = document.getElementById("Qualification").value;
    var St_date = document.getElementById("St_date").value;
    var Coursename = document.getElementById("Coursename").value;
    var End_date = document.getElementById("End_date").value;
    var Coursetype = document.getElementById("Coursetype").value;
    var clg_name = document.getElementById("clg_name").value;
    var stream = document.getElementById("stream").value;
    var unisty_name = document.getElementById("unisty_name").value;
    var OthersQualification = document.getElementById("othersQualification").value;

    if (!Qualification) {
      formIsValid = false;
      formErrors["Qualification"] = "Qualification  is required";
    } else if (Qualification === 'others' && !OthersQualification) {
      formIsValid = false;
      formErrors["Qualification"] = "Qualification  is required";
    } else  if ( OthersQualification !== "" && OthersQualification.length < 3) {
      formIsValid = false;
      formErrors["Qualification"] = "A Minimum of 3 characters are required";
    }else if (!Coursename) {
      formIsValid = false;
      formErrors["Coursename"] = "Course Name is required";
    }
    else if (Coursename.length < 2) {
      formIsValid = false;
      formErrors["Coursename"] = "A Minimum of 2 characters is required";
    }
    else if (!Coursename) {
      formIsValid = false;
      formErrors["Coursename"] = "Course Name is required";
    } else if (!Coursetype) {
      formIsValid = false;
      formErrors["Coursetype"] = "Course Type is required";
    }
    // else if (!stream) {

    //   formIsValid = false;
    //   // formErrors["stream"] = "Specialization is required";
    // }
    // else if (stream.length < 3) {
    //   formIsValid = false;
    //   // formErrors["stream"] = "A Minimum of 3 characters are required";
    // }
    else if (!St_date) {
      formIsValid = false;
      formErrors["St_date"] = "Start From is required";
    } else if (!End_date) {
      formIsValid = false;
      formErrors["End_date"] = "End is required";

      if (St_date !== null && End_date !== null) {
        if (St_date === End_date) {
          formIsValid = false;
          formErrors["End_date"] = " Start From and End is Same";
        }
        if (new Date(St_date).getTime() > new Date(End_date).getTime()) {
          formIsValid = false;

          formErrors["End_date"] = " Start From Should less then End";
        }
      }
    } else if (!unisty_name) {
      formIsValid = false;
      formErrors["unisty_name"] = "University Name is required";
    } else if (unisty_name.length < 3 && unisty_name !== "-") {
      formIsValid = false;
      formErrors["unisty_name"] = <div style={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>A Minimum of 3 characters are required</div>;
    }

    // else if (!clg_name) {
    //   formIsValid = false;
    //   // formErrors["clg_name"] = "College Name  is required";
    // }
    // else if (clg_name.length < 3) {
    //   formIsValid = false;
    //   // formErrors["clg_name"] = "A Minimum of 3 characters are required";
    // }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };
  handleDateChange = (date, name) => {
    this.setState({
      [name]: date,
      [`${name}_formatted`]: date ? moment(date).format('YYYY-MM') : ''
    }, () => this.handleEducationChange());
  };

  handleChangeD = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.handleEducationChange());
  };


  handleFocus = (e) => {
    const calElement = document.getElementById('Cal');
    if (calElement) {
      calElement.style.display = 'block';
    }
  };

  HandleQualification = () => {
    var QualificationType = document.getElementById("Qualification").value;
    if (QualificationType === "others") {
      document.getElementById("othersQualification").value = "";
      document.getElementById("othersQualification").type = "text";
    } else {
      document.getElementById("othersQualification").value = "None";
      document.getElementById("othersQualification").type = "hidden";
    }
  }

  render() {
    const { EducationDrop, EducationCourseName, startDate, endDate, maxDateS, isFirefox } = this.state;
    const { isChrome } = this.state;
    const {
      Qualification,
      St_date,
      Coursename,
      End_date,
      Coursetype,
      clg_name,
      stream,
      unisty_name,
    } = this.state.formErrors;
    return (
      <>
        {" "}
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />{" "}
        <div className="pop">
          <div className="popinnernormal">
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>
            <h1 className="popinnertitle">Edit</h1>
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <div
                className="row justify-content-center"
                style={{ width: "101%" }}
              >
                <input
                  style={{ display: "none" }}
                  type="hidden"
                  name="CreatedDate"
                  value={this.props.value.Created_Date}
                />
                <div className="col-xl-5">
                  <select
                    className="effect-16"
                    id="Qualification"
                    defaultValue={this.props.value.QualificationType}
                    name="QualificationType"
                    onChange={(e) => {
                      // this.handleChange();
                      this.handleChangeD(e);
                      this.handlevalueChange(e);
                    }}
                    onInputCapture={this.HandleQualification}
                    type="text"
                    placeholder="Qualification Type "
                    style={{
                      color: "darkgray",
                      ...(isChrome && { marginLeft: '-8px' })
                    }}
                  >
                    <option value="">Select</option>
                    <option hidden>{this.props.value.QualificationType}</option>
                    {EducationDrop.map((EducationDrop) => (
                      <option value={EducationDrop.qualificationType}>
                        {EducationDrop.qualificationType}
                      </option>
                    ))}
                    <option value="others">Others</option>
                  </select>
                  <input
                    type="hidden"
                    name="otherQualificationType"
                    placeholder="Others Type"
                    className="form"
                    id="othersQualification"
                    maxLength={30}
                    onChange={(e)=>{this.handleChangeD(e)}}
                  />
                  {Qualification == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Qualification Type{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Qualification}
                    </div>
                  )}
                </div>
                <div className="col-xl-5">
                  <input
                    type="hidden"
                    name="OldCoursename"
                    defaultValue={
                      this.props.value.Coursename
                    }
                  />

                  <input
                    className="effect-16"
                    maxLength="40"
                    type="text"
                    list="browsers"
                    id="Coursename"
                    name="Coursename"
                    onChange={(e) => {
                      this.handleChangeD(e);
                    }}
                    defaultValue={this.props.value.Coursename}
                    placeholder="Course Name"
                  />
                  {Coursename == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Course Name
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Coursename}
                    </div>
                  )}
                  <datalist id="browsers">
                    {EducationCourseName.map(
                      (EducationCourseName) => (
                        <option
                          value={
                            EducationCourseName.coursename
                          }
                        ></option>
                      )
                    )}
                  </datalist>

                  {/* <span style={{color:'#a29d9d',float:"left"}}>Course Name</span> */}
                </div>
              </div>
              <br />
              <div
                className="row justify-content-center"
                style={{ width: "101%" }}
              >
                <div className="col-xl-5">

                  <input
                    className="effect-16"
                    maxLength="40"
                    type="text"
                    id="stream"
                    name="Stream"
                    defaultValue={this.props.value.Stream}
                    placeholder="Specialization"
                  />
                  {stream == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Specialization
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>{stream}</div>
                  )}
                </div>

                <div className="col-xl-5">
                  <select
                    className="effect-16"
                    type="text"
                    id="Coursetype"
                    name="Coursetype"
                    defaultValue={this.props.value.Coursetype}
                    onChange={(e) => {
                      this.handleChangeD(e);
                    }}
                    placeholder="Course Type "
                    style={{
                      color: "darkgray",
                      ...(isChrome && { marginLeft: '-3px' })
                    }}
                  >
                    <option value=""> Course Type </option>
                    <option value="Part Time">Part Time</option>
                    <option value="Full Time">Full Time</option>
                  </select>
                  {Coursetype == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Course Type
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Coursetype}
                    </div>
                  )}
                </div>
              </div>
              <br />
              <div
                className="row justify-content-center"
                style={{ width: "101%" }}
              >
                <div className="col-xl-5">
                  {/* <input className="form" name="SkillStartdate" defaultValue={this.props.value.startdate}  id="skillCoursestartdate" type="text" onFocus={(e) => e.target.type = 'month'}   onChange={this.handleChange} placeholder="Start Date" /> */}
                  <ReactDatePicker
                    className="effect-16"
                    onFocus={(e) => {
                      this.setState({ DisFireStart: true }, () => this.handleFocus(e));
                    }}
                    onChange={(date) => this.handleDateChange(date, 'startDate')}
                    showMonthYearPicker
                    dateFormat="yyyy-MM"
                    maxDate={maxDateS}
                    selected={startDate}
                    minDate={new Date(new Date().getFullYear()
                      - 60, new Date().getMonth(), 1)}
                    type="text"
                    id="St_date"
                    name="CourseStartDate"
                    defaultValue={this.props.value.CourseStartDate}
                    placeholder="Course Start Date "
                  />
                  {this.state.DisFireStart && (
                    <i
                      id="Cal"
                      className="fa fa-calendar-o"
                      aria-hidden="true"
                      style={{
                        float: 'right',
                        marginTop: '-20px',
                      }}
                    ></i>
                  )}
                  {St_date == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Start From
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>{St_date}</div>
                  )}
                </div>
                <div className="col-xl-5">
                  <ReactDatePicker
                    selected={endDate}
                    showMonthYearPicker
                    dateFormat="yyyy-MM"
                    className="effect-16"
                    onFocus={(e) => {
                      this.setState({ DisFire: true }, () => this.handleFocus(e));
                    }}
                    max={new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1
                    )
                      .toJSON()
                      .slice(0, 7)}
                    min={new Date(
                      new Date().getFullYear() - 60,
                      new Date().getMonth() + 1
                    )
                      .toJSON()
                      .slice(0, 7)}
                    onChange={(date) => this.handleDateChange(date, 'endDate')}
                    type="text"
                    id="End_date"
                    name="CourseEndDate"
                    defaultValue={this.props.value.CourseEndDate}
                    placeholder="Course End Date "
                  />
                  {this.state.DisFire && (
                    <i
                      id="Cal"
                      className="fa fa-calendar-o"
                      aria-hidden="true"
                      style={{
                        float: 'right',
                        marginTop: '-20px',
                      }}
                    ></i>
                  )}
                  {End_date == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      End
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {End_date}
                    </div>
                  )}
                </div>
              </div>
              <br />

              <div
                className="row justify-content-center"
                style={{ width: "101%" }}
              >
                <div className="col-xl-5">
                  <input
                    className="effect-16"
                    maxLength="250"
                    type="text"
                    id="unisty_name"
                    name="UniversityName"
                    onChange={(e)=>{this.handleChangeD(e)}}
                    defaultValue={this.props.value.UniversityName}
                    placeholder="University Name"
                  />
                  {unisty_name == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      University Name
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {unisty_name}
                    </div>
                  )}
                </div>
                <div className="col-xl-5">
                  <input
                    className="effect-16"
                    maxLength="250"
                    type="text"
                    id="clg_name"
                    name="CollegeName"
                    defaultValue={this.props.value.CollegeName}
                    placeholder="College Name "
                  />
                  {clg_name == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      College Name
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {clg_name}
                    </div>
                  )}
                </div>
              </div>

              <br />
              <div className="col-lg-12">
                <div className="row  justify-content-center">
                  <button
                    type="submit"
                    className="button-30 sk-edit"
                    style={{
                      color: "#8e75ef",
                      fontWeight: "bold",
                      height: "39.5px",
                      width: "83.36px",
                    }}
                    disabled={this.state.isButtonDisabled}
                  >
                    Update
                  </button>
                  <button
                    type="reset"
                    className="button-30 sk-edit"
                    style={{
                      color: "#716b6b",
                      fontWeight: "bold",
                      marginLeft: "2%",
                      height: "39.5px",
                    }}
                    onClick={() => this.setState({
                      formErrors: "",
                      startDate: this.props.value.CourseStartDate ? new Date(this.props.value.CourseStartDate) : null,
                      endDate: this.props.value.CourseEndDate ? new Date(this.props.value.CourseEndDate) : null,
                    })}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}