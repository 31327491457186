import { React, useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import PlusOne from "@material-ui/icons/Add";
import AddTrainingAddTrainee from "./AddTrainingAddTrainee";

const TrainingEditTrainerPop = ({
  closeThePopup,
  array,
  state,
  third,
  LearnId,
  add,
}) => {
  const [Trainers, setTrainers] = useState(array);
  const [Train, setTrain] = useState([]);
  const [Selectall, setSelectall] = useState(false);
  // console.log("www", array);

  //   useEffect(() => {
  //       GetTrainer()

  //   }, [])

  const handleChange = (arr, i, event) => {
    let change = Trainers.map((e, index) => {
      if (i === index) return { ...e, ...{ check: event.target.checked } };
      return { ...e };
    });
    state(change);
    change.length === change.filter((e) => e.check === true).length
      ? setSelectall(true)
      : setSelectall(false);
    // console.log("change=====", change);

    setTrainers(change);
  };

  const handleSelectAll = (event) => {
    setSelectall(!Selectall);
    let change = Trainers.map((e, index) => {
      return { ...e, ...{ check: event.target.checked } };
    });
    change.length === change.filter((e) => e.check === true).length
      ? setSelectall(true)
      : setSelectall(false);
    setTrainers(change);
    state(change);
  };

  return (
    <div>
      <div className="pop">
        <div className="popinnernormal">
          <form autoComplete="off" encType="multipart/form-data">
            {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}} onClick={this.close}  ><CloseIcon/></button> */}
            <button
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={closeThePopup}
            >
              <CloseIcon />
            </button>

            <div className="row" tyle={{ padding: "8px" }}>
              <div className="col-sm-3"></div>
              <div className="col-sm-8">
                <div className="dt-responsive table-responsive table-res">
                  {Trainers.length === 0 ? (
                    "There are no ' Trainers '"
                  ) : (
                    <table
                      id="DepSheet"
                      className="table nowrap"
                      style={{ maxWidth: "90%" }}
                    >
                      <thead>
                        <tr>
                          <th colSpan="2" style={{ textAlign: "left" }}>
                            <button
                              style={{
                                marginRight: "-30px",
                                color: "rgb(94, 217, 135)",
                              }}
                              onClick={closeThePopup}
                            >
                              Add
                            </button>
                          </th>
                        </tr>

                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={Selectall}
                              onChange={(e) => handleSelectAll(e)}
                            />
                          </th>
                          <th>Trainers</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {Trainers.map((Trainers, i) => (
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                value={
                                  Trainers.trainerName +
                                  "$$$" +
                                  Trainers.trainer_UserId
                                }
                                checked={Trainers.check}
                                onChange={(e) => handleChange(Trainers, i, e)}
                              />
                            </td>
                            <td>
                              {Trainers.assigned_date !== null
                                ? Trainers.trainerName +
                                  " (" +
                                  Trainers.assigned_date +
                                  ") "
                                : Trainers.trainerName}
                            </td>
                            {/* <td>
                          <button
                            className="button-30"
                            type="button"
                            style={{ color: "rgb(94, 217, 135)" }}
                            onClick={() =>
                              this.fetchData(
                                Trainers.trainerName,
                                Trainers.trainer_UserId
                              )
                            }
                          >
                            Assign&nbsp;
                            <i className="icofont icofont-edit"></i>
                          </button>
                        </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
              <div className="col-sm-4"></div>
            </div>

            <div className="row">
              <div className="col-sm-12" style={{ textAlign: "center" }}></div>
            </div>
          </form>
          <br />
          {/* <button onClick={this.closePopup}><CloseIcon/></button> */}
        </div>
      </div>
    </div>
  );
};
export default TrainingEditTrainerPop;