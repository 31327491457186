import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import { OutTable } from "react-excel-renderer";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FilterPop from "../../Common/FilterPop";
import LearningExl from "../../Assets/Files/LearningExl.xlsx";
import { BASE_URLs } from "../../Config/Base";
var XLSX = require("xlsx");
export default class LearningSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      skills: [],
      Addskills: [],
      Id: "",
      SkillName: "",
      SkillDescription: "",
      Category: "",
      Notification: [],
      Learning: [],
      Learn: [],
      rows: [],
      cols: [],
      currentSheet: {},
      category: [],
      comlogo: [],
      Skillname: [],
      Sort: [],
      Skillfilt: [],
      AddSkillModel: false,
      AddLearning: false,
      EditSkillModel: false,
      post: [],
      number: "1",
      postPerPage: "10",
      EditSkillValues: [],
      TapFilter: [],
      TapFilterValue: "",
      TapFilterIndex: "",
      Filterpopstatus: false,
      Department: [],
      Depart: [],
    };

    this.initialState = this.state;
  }

  GetDepartment() {
    // let url = `${BASE_URLs}/Department/DepartmentGet";
    let url = `${BASE_URLs}/Department/DepartmentGet`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Department: data,
          Depart: data,
        });
        // console.log("Training", data);

        //
      });
  }

  handleUpload = (event) => {
    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);
    if (isAllowed) {
      const file = event.target.files[0];
      readFile(file).then((readedData) =>
        this.setState({ initialData: readedData })
      );
      /* file upload logic goes here... */
      return true;
    } else {
      alert("Invalid File Type.");
      return false;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}/Learning/LearningExcel`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert("0  Records  inserted ");
          } else if (resp === 0) {
            alert("Records added Successfully");
          } else {
            alert(+resp + " Records not inserted ");
          }
          this.GetLearning();
          this.setState({ initialData: undefined });
        });
      var file = document.getElementById("files");
      var emptyFile = document.createElement("input");
      emptyFile.type = "file";
      file.files = emptyFile.files;
    } catch (error) {}
    this.GetLearning();
  };

  componentDidMount() {
    this.GetLearning();
  }

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("LearningSheet");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  onKeyUp = (e) => {
    const Learning = this.state.Learn.filter(
      (item) =>
        item.department.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.trainingitem
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.startDate.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.schedulingDuration
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );

    this.setState({ Learning });
  };

  DetailEdit(
    Id,
    department,
    startDate,
    endDate,
    trainingitem,
    schedulingDuration,
    trainerName,
    trainees
  ) {
    var page = {
      pathname: "/EditSchedule",
      state: {
        Id: Id,
        department: department,
        startDate: startDate,
        endDate: endDate,
        trainingitem: trainingitem,
        schedulingDuration: schedulingDuration,
        trainerName: trainerName,
        trainees: trainees,
      },
    };
    this.props.history.push(page);
  }

  GetLearning() {
    let url = `${BASE_URLs}/Learning/GetLearning?Department=0`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Learning: data.sort((a, b) => {
            return a.trainingitem < b.trainingitem ? -1 : 1;
          }),
          Learn: data.sort((a, b) => {
            return a.trainingitem < b.trainingitem ? -1 : 1;
          }),
        });
        let array = data
          .filter((e) => e.trainingitem !== null)
          .map((e) => e.trainingitem.slice(0, 1));
        let uniq = [
          ...new Set(
            array.map((x) => (typeof x === "string" ? x.toUpperCase() : x))
          ),
        ];
        this.setState({ TapFilter: uniq.sort() });
        // // console.log("Learning-----------------",uniq.sort() )
      });
  }

  InviteTraining = (Id) => {
    this.setState({ loading: true });
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Training/InviteTraining?Id=${Id}`, options)
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === "Error") {
          toast.error(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ loading: false });
        } else {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ loading: false });
        }
      });

    this.GetLearning();
  };

  TapFiltering = (v, i) => {
    // this.setState({ Learn : this.state.Learning });
    this.setState({ TapFilterValue: v });
    const Learning = this.state.Learn.filter(
      (e) => e.trainingitem !== null
    ).filter(
      (obj) => obj.trainingitem.charAt(0).toLowerCase() === v.toLowerCase()
    );
    //  // console.log(v.toLowerCase(),"Learning",Learning);
    this.setState({ Learning });
  };

  render() {
    const { Learning, TapFilter, TapFilterValue, Filterpopstatus } = this.state;
    var Entityname = JSON.parse(localStorage.entityName);
    var filename = `O_${Entityname}_Learning`;
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {Filterpopstatus ? (
          <FilterPop
            data={this.state.Learn}
            FilteredData={(newValue) => {
              this.setState({ Learning: newValue });
            }}
            TogglePop={(e) => {
              this.setState({ Filterpopstatus: !Filterpopstatus });
            }}
          />
        ) : null}
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title">Training Class</h4>
                        </div>

                        <form onSubmit={this.handleSubmit}>
                          <div className="card-block">
                            <div className="row">
                              <div className="col btnalign">
                                <Link
                                  to="/AddLearning"
                                  type="button"
                                  style={{
                                    width: "150px",
                                    background: "white",
                                  }}
                                  id="addRow"
                                  className="dropdown_item-2"
                                  className="btn "
                                >
                                  Add{" "}
                                </Link>
                              </div>
                              <div className="col btnalign">
                                <label
                                  htmlFor="files"
                                  className="btn"
                                  id="addBtn"
                                  name="LearningFile"
                                >
                                  Import From Excel
                                </label>
                                <input
                                  id="files"
                                  type="file"
                                  accept=".xlsx"
                                  style={{ display: "none" }}
                                  onChange={this.handleUpload}
                                  name="LearningFile"
                                />
                              </div>
                              <div className="col btnalign">
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={() => this.exportXlsx(filename)}
                                >
                                  Export to excel
                                </button>
                              </div>
                              <div className="col btnalign">
                                <a
                                  className="btn"
                                  href={LearningExl}
                                  type="button"
                                  style={{ color: "#5035ce" }}
                                >
                                  <i
                                    title="Download Template"
                                    className="icofont icofont-download"
                                  ></i>{" "}
                                  Download
                                </a>
                              </div>
                              {/* <div className="col btnalign">
                                <a
                                  href={LearningExl}
                                  className="download"
                                  style={{
                                    padding: "2px 15px",
                                    boxShadow:
                                      " -2px  -2px 16px -2px #5f7ae14a",
                                    color: "#5035ce",
                                  }}
                                >
                                  <i
                                    title="Download Template"
                                    className="icofont icofont-cloud-download"
                                  ></i>
                                </a>
                              </div> */}
                              <div
                                className="col-lg-1"
                                style={{
                                  overflow: "scroll",
                                  height: "73px",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {TapFilter.map((e, i) => (
                                  <div
                                    style={
                                      TapFilterValue === e
                                        ? {
                                            color: "black",
                                            fontSize: "13px",
                                            borderRight: "2px solid black",
                                          }
                                        : { color: "gray", fontSize: "13px" }
                                    }
                                    onClick={() => this.TapFiltering(e, i)}
                                  >
                                    {e}
                                  </div>
                                ))}
                              </div>

                              <div className="col-lg-3">
                                <input
                                  className="Searchbox"
                                  onClick={() => {
                                    this.setState({ Filterpopstatus: true });
                                  }}
                                  placeholder="Search"
                                  style={{ float: "right" }}
                                />
                              </div>

                              {this.state.initialData !== undefined ? (
                                <ReactExcel
                                  initialData={this.state.initialData}
                                  onSheetUpdate={(currentSheet) =>
                                    this.setState({
                                      CurrentSheet: currentSheet,
                                    })
                                  }
                                  activeSheetClassName="active-sheet"
                                  reactExcelClassName="react-excel"
                                  name="LearningFile"
                                />
                              ) : null}

                              {this.state.initialData !== undefined ? (
                                <div className="savebtndiv">
                                  <button
                                    className="btn"
                                    id=""
                                    type="submit"
                                    style={{ width: "150px" }}
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : null}
                              <OutTable
                                data={this.state.rows}
                                columns={this.state.cols}
                                tableClassName="ExcelTable2007"
                                tableHeaderRowclassName="heading"
                              />
                            </div>
                          </div>
                        </form>
                        <div className="card-block2">
                          <div className="dt-responsive table-responsive tab-res">
                            <table id="add-row-table3" className="table nowrap">
                              <thead>
                                <tr>
                                  {/* <th><input type="checkbox" id="selectall" /> </th> */}
                                  <th>Department</th>
                                  <th>Training Topics</th>
                                  <th>Start Date</th>
                                  <th>Duration</th>
                                  {/* <th>End Date</th> */}
                                  <th>Trainers</th>
                                  {/* <th>Attendies</th> */}
                                  <th>Invite</th>
                                  <th> </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Learning.map((Learning) => (
                                  <tr>
                                    {/* <td><input type="checkbox" id="selectall" /></td> */}
                                    <td>{Learning.department}</td>
                                    <td>{Learning.trainingitem}</td>
                                    <td>
                                      {Learning.startDate
                                        ? Learning.startDate.slice(0, 10)
                                        : null}
                                    </td>
                                    <td>{Learning.schedulingDuration}</td>
                                    {/* <td>
                                    {Learning.endDate
                                      ? Learning.endDate.slice(0, 10)
                                      : null}
                                  </td> */}
                                    <td style={{ textAlign: "center" }}>
                                      {Learning.trainercount}
                                    </td>
                                    {/* <td style={{ textAlign: "center" }}>
                                    {Learning.traineecount}
                                  </td> */}
                                    <td>
                                      {" "}
                                      {Learning.invitesStatus === 0 ||
                                      Learning.invitesStatus === null ? (
                                        <td style={{ color: "gray" }}>
                                          <img
                                            alt="INVITE"
                                            src="../Assets/images/invite.png"
                                            onClick={() =>
                                              this.InviteTraining(Learning.id)
                                            }
                                            style={{ height: "30px" }}
                                          />
                                        </td>
                                      ) : (
                                        <b>Invited</b>
                                      )}
                                    </td>

                                    <td>
                                      <Link to="/EditSchedule">
                                        <button
                                          className="button-30 sk-edit"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.DetailEdit(
                                              Learning.id,
                                              Learning.department,
                                              Learning.startDate,
                                              Learning.endDate,
                                              Learning.trainingitem,
                                              Learning.schedulingDuration,
                                              Learning.trainerName,
                                              Learning.trainees
                                            );
                                          }}
                                          type="button"
                                          style={{ color: "" }}
                                        >
                                          <i className="icofont icofont-info"></i>
                                        </button>
                                        &nbsp;&nbsp;
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <table
                          id="LearningSheet"
                          hidden
                          className="table nowrap"
                        >
                          <thead>
                            <tr>
                              <th>Department</th>
                              <th>Training Topics</th>
                              <th>Start Date</th>
                              <th>Duration</th>
                              {/* <th>End Date</th> */}
                              <th>Trainer Name</th>
                              {/* <th>Team Member</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {Learning.map((Learning) => (
                              <tr>
                                {/* <td><input type="checkbox" id="selectall" /></td> */}
                                <td>{Learning.department}</td>
                                <td>{Learning.trainingitem}</td>
                                <td>{Learning.startDate}</td>
                                <td>{Learning.schedulingDuration}</td>
                                {/* <td>{Learning.endDate}</td> */}
                                <td>{Learning.trainerName}</td>
                                {/* <td>{Learning.trainees}</td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
