import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import CommonSuccess from "../../Common/CommonSuccess";
import { Link } from "react-router-dom";
import AddDepartmentpop from "../../Common/AddDepartmentpop";
import AddDesignationModel from "../../Common/AddDesignationModel";
import AddManagerPop from "../../Common/AddManagerPop";
import AddLocationModel from "../../Common/AddLocationModel";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base";
import "../../Css/Addskills.css";
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      FirstName: "",
      LastName: "",
      Email: "",
      Mobile: "",
      Department: "",
      Designation: "",
      RoleName: "",
      comlogo: [],
      formErrors: {},
      Notification: [],
      Departmentdrop: [],
      Designationdrop: [],
      ManagerDrop: [],
      LocationDrop: [],
      message: "",
      status: "",
      DepartModel: false,
      DesignationModel: false,
      ManagerModel: false,
      LocationModel: false,
      ButtonDisabled: false,
      Experience: false,
      checkBox1: false,
      checkBox2: false,
      ManagerNamedrop: [],
      Mname: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.SelectDepartment = this.SelectDepartment.bind(this);
  }

  GetLocation() {
    let url = `${BASE_URLs}/AdminLocation/GetLocation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          LocationDrop: data,
        });
        // console.log("location", data);

        //
      });
  }
  GetManager() {
    let url = `${BASE_URLs}/Manager/ManagerGet`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          ManagerDrop: data,
        });
      });
  }

  GetDesignation() {
    let url = `${BASE_URLs}/Designation/GetMasterDesignation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Designationdrop: data,
          Desi: data,
        });
      });
  }

  GetDepartment() {
    let url = `${BASE_URLs}/Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }

  GetMangerNameDrop(Department) {
    let url = `${BASE_URLs}/Manager/GetManagerName?Department=` + Department;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          ManagerNamedrop: data,
        });
        // console.log('nameeeee', data)
      });
  }
  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }
  componentDidMount() {
    this.GetOrgLogo();
    this.GetDepartment();
    this.GetDesignation();
    this.GetManager();
    this.GetLocation();
    this.selectExperience();

    var role = JSON.parse(localStorage.Role);

    if (role.toString() === "Platform Admin") {
      document.getElementById("navright").setAttribute("style", " display:;");
      document.getElementById("mainorg").setAttribute("style", "  display:;");
    }
  }

  Getallactive() {
    fetch(`${BASE_URLs}/AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}/AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}/AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }

  DepthandleChange() {
    var Department = document.getElementById("Department").value;
    if (this.state.Mname !== 'Add' && this.state.Mname !== 'None') {
      this.GetMangerNameDrop(Department);
    }

  }
  ManagerNamehandleChange() {

    //  alert(this.state.Mname)
    // this.GetMangerNameDrop(this.state.Mname);

  }

  handleChange() {
    var TeamId = document.getElementById("TeamId").value;

    var DisplayName = document.getElementById("DisplayName").value;
    var FirstName = document.getElementById("FirstName").value;
    var Email = document.getElementById("Email").value;
    var Mobile = document.getElementById("Mobile").value;
    var Department = document.getElementById("Department").value;
    var Designation = document.getElementById("Designation").value;
    var RoleName = document.getElementById("RoleName").value;
    var Manager = document.getElementById("Manager").value;
    var JoiningDate = document.getElementById("JoiningDate").value;
    let formErrors = {};
    let formIsValid = true;
    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;

    //Email
    if (!TeamId || TeamId.trim() == "") {
      formIsValid = false;
      formErrors["TeamIdErr"] = "User Id is required";
    } else if (TeamId.length < 3) {
      formIsValid = false;
      formErrors["TeamIdErr"] = " A Minimum of 3  characters are required";
    } else if (!DisplayName) {
      formIsValid = false;
      formErrors["DisplayNameErr"] = "Display Name is required";
    } else if (DisplayName.length < 2) {
      formIsValid = false;
      formErrors["DisplayNameErr"] = "A Minimum of  2 characters are required";
    } else if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required";
    } else if (FirstName.length < 3) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "A Minimum of 3 characters are required";
    } else if (!Email) {
      formIsValid = false;
      formErrors["EmailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["EmailErr"] = "Invalid Email Id";
    } else if (!Mobile) {
      formIsValid = false;
      formErrors["MobileErr"] = "Mobile Number is required";
    }
    // else if (!Mobile >= 10) {
    //   formIsValid = false;
    //   formErrors["MobileErr"] = "Invalid Mobile number";
    // }
    else if (!mobPattern.test(Mobile)) {
      formIsValid = false;
      formErrors["MobileErr"] = "Invalid Mobile Number";
    } else if (!Department) {
      formIsValid = false;
      formErrors["departmentErr"] = "Department is required";
    } else if (!Designation) {
      formIsValid = false;
      formErrors["designationErr"] = "Designation is required";
    } else if (!RoleName) {
      formIsValid = false;
      formErrors["rolesErr"] = "Role is required";
    } else if (!Manager) {
      formIsValid = false;
      formErrors["ManagerErr"] = "Manager is required";
    } else if (!JoiningDate) {
      formIsValid = false;
      formErrors["JoiningDateErr"] = "Join date is required";
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  handleFormValidation() {
    var TeamId = document.getElementById("TeamId").value;
    var DisplayName = document.getElementById("DisplayName").value;
    var FirstName = document.getElementById("FirstName").value;

    var Email = document.getElementById("Email").value;
    var Mobile = document.getElementById("Mobile").value;
    var Department = document.getElementById("Department").value;
    var Designation = document.getElementById("Designation").value;
    var RoleName = document.getElementById("RoleName").value;
    var Manager = document.getElementById("Manager").value;
    var JoiningDate = document.getElementById("JoiningDate").value;
    let formErrors = {};
    let formIsValid = true;
    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;

    //Email
    if (!TeamId || TeamId.trim() == "") {
      formIsValid = false;
      formErrors["TeamIdErr"] = "User Id is required";
    } else if (TeamId.length < 3) {
      formIsValid = false;
      formErrors["TeamIdErr"] = " A Minimum of 3  characters are required";
    }
    if (!DisplayName) {
      formIsValid = false;
      formErrors["DisplayNameErr"] = "Display Name is required";
    } else if (DisplayName.length < 2) {
      formIsValid = false;
      formErrors["DisplayNameErr"] = "A Minimum of 2 characters are required";
    }
    if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required";
    } else if (FirstName.length < 3) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "A Minimum of 3 characters are required";
    }

    if (!Email) {
      formIsValid = false;
      formErrors["EmailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["EmailErr"] = "Invalid Email Id";
    }
    if (!Mobile) {
      formIsValid = false;
      formErrors["MobileErr"] = "Mobile Number is required";
    }
    // else if (!Mobile >= 10) {
    //   formIsValid = false;
    //   formErrors["MobileErr"] = "Invalid Mobile number";
    // }
    else if (!mobPattern.test(Mobile)) {
      formIsValid = false;
      formErrors["MobileErr"] = "Invalid Mobile Number";
    }

    if (!Department) {
      formIsValid = false;
      formErrors["departmentErr"] = "Department is required";
    }

    if (!Designation) {
      formIsValid = false;
      formErrors["designationErr"] = "Designation is required";
    }

    if (!RoleName) {
      formIsValid = false;
      formErrors["rolesErr"] = "Role is required";
    }

    if (!Manager) {
      formIsValid = false;
      formErrors["ManagerErr"] = "Manager is required";
    }
    if (!JoiningDate) {
      formIsValid = false;
      formErrors["JoiningDateErr"] = "Join date is required";
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
  };

  SelectManager = (e) => {
    let dept = document.getElementById("Manager").value;

    const departmentEditValue = document.getElementById('Department').value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        ManagerModel: !prevState.ManagerModel,
      }));
      document.getElementById("Manager").value = "";

    }

    if (dept !== 'Add' && dept !== 'None') {
      this.GetMangerNameDrop(departmentEditValue)
    }
  };

  SelectLocation = (e) => {
    let dept = document.getElementById("Locations").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        LocationModel: !prevState.LocationModel,
      }));
      document.getElementById("Locations").value = "";
    }
  };

  SelectDepartment = (e) => {
    let dept = document.getElementById("Department").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        DepartModel: !prevState.DepartModel,
      }));
      this.setState({ Mname: dept })
      document.getElementById("Department").value = "";
      //this.props.history.push('/Department')
    }
  };
  SelectDesignation = (e) => {
    let dept = document.getElementById("Designation").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        DesignationModel: !prevState.DesignationModel,
      }));
      document.getElementById("Designation").value = "";
    }
    // switch(document.getElementById('Department').value)
    // {
    // case "Add":
    //   window.location.href="#/Department"
    // break;
    // default:
    // window.location="../";
    // break;
    // }
  };

  handleSubmit(event) {
    event.preventDefault();

    const data = new FormData(event.target);

    // console.log("data********************", data);

    if (this.handleFormValidation()) {
      this.setState({
        ButtonDisabled: true,
      });
      fetch(`${BASE_URLs}/Team/AddTeamnext`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          this.setState({
            ButtonDisabled: false,
          });
          this.setState({ status: resp.status });
          this.setState({ message: resp.message });
          this.SuccessModel();
          this.selectExperience();
        });
    }
  }
  DepartModel = () => {
    this.setState((prevState) => ({
      DepartModel: !prevState.DepartModel,
    }));
    this.GetDepartment();
  };
  DesignationModel = () => {
    this.setState((prevState) => ({
      DesignationModel: !prevState.DesignationModel,
    }));

    this.GetDesignation();
  };

  ManagerModel = () => {
    const departmentEditValue = document.getElementById('Department').value;

    this.setState((prevState) => ({
      ManagerModel: !prevState.ManagerModel,
    }));
    this.GetManager();
    if (departmentEditValue !== '') {
      this.GetMangerNameDrop(departmentEditValue)
    }
  };

  LocationModel = () => {
    this.setState((prevState) => ({
      LocationModel: !prevState.LocationModel,
    }));

    this.GetLocation();
  };

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() +
      e.target.value.slice(1).toLowerCase();
    e.target.value = value;
  }

  selectExperience() {
    this.setState((prevState) => ({
      Experience: !prevState.Experience,
    }));
  }

  checkbox() {
    // document.getElementById("Yes").checked = true
    document.getElementById("No").checked = false;
    this.setState({
      Experience: !this.state.Experience,
    });
  }

  handleCheckBox1Change() {
    this.setState({
      checkBox1: true,
      checkBox2: false,
    });
  }

  handleCheckBox2Change() {
    this.setState({
      checkBox1: false,
      checkBox2: true,
    });
  }

  render() {
    const {
      TeamIdErr,

      FirstNameErr,
      LastNameErr,
      DisplayNameErr,
      EmailErr,
      MobileErr,
      rolesErr,
      departmentErr,
      designationErr,
      ManagerErr,
      LocationErr,

      JoiningDateErr,
    } = this.state.formErrors;

    const { Departmentdrop, Designationdrop, ManagerDrop, LocationDrop } =
      this.state;
    const currentDate = new Date().toISOString().split("T")[0];

    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.pathname} />
        {this.state.DepartModel ? (
          <AddDepartmentpop close={() => this.DepartModel()} />
        ) : null}
        {this.state.DesignationModel ? (
          <AddDesignationModel close={() => this.DesignationModel()} />
        ) : null}
        {this.state.ManagerModel ? (
          <AddManagerPop close={() => this.ManagerModel()} />
        ) : null}
        {this.state.LocationModel ? (
          <AddLocationModel close={() => this.LocationModel()} />
        ) : null}

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            New User
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        {this.state.SuccessModel ? (
                          <CommonSuccess
                            id="Success"
                            message={this.state.message}
                            status={this.state.status}
                            close={() => this.SuccessModel()}
                          />
                        ) : null}
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row" style={{ marginTop: "3%" }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="container"
                                      style={{ maxWidth: "765px" }}
                                    >
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            minLength="3"
                                            maxLength="8"
                                            onInputCapture={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            name="TeamId"
                                            type="text"
                                            id="TeamId"
                                            onChange={this.handleChange}
                                          />
                                          {!TeamIdErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              Employee Id
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {TeamIdErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            maxLength="15"
                                            onKeyPress={(event) => {
                                              if (
                                                !/[a-zA-Z\s ]+/.test(event.key)
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                            name="DisplayName"
                                            id="DisplayName"
                                            type="text"
                                            onChange={this.handleChange}
                                          />
                                          {!DisplayNameErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              Display Name
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {DisplayNameErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>
                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            onKeyPress={(event) => {
                                              if (
                                                !/[a-zA-Z\s ]+/.test(event.key)
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                            name="FirstName"
                                            type="text"
                                            id="FirstName"
                                            onChange={this.handleChange}
                                            maxLength={15}
                                          />
                                          {!FirstNameErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              First Name
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {FirstNameErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            maxLength="20"
                                            onKeyPress={(event) => {
                                              if (
                                                !/[a-zA-Z\s ]+/.test(event.key)
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                            name="LastName"
                                            id="LastName"
                                            type="text"
                                            onChange={this.handleChange}
                                          />
                                          <span style={{ color: "#a29d9d" }}>
                                            Last Name
                                          </span>{" "}
                                          {LastNameErr && (
                                            <div
                                              style={{
                                                color: "red",
                                                paddingBottom: 10,
                                              }}
                                            >
                                              {LastNameErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            name="Email"
                                            id="Email"
                                            type="text"
                                            onChange={this.handleChange}
                                            maxLength="50"
                                          />
                                          {!EmailErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              Email
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {EmailErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            name="Mobile"
                                            type="text"
                                            id="Mobile"
                                            onChange={this.handleChange}
                                            onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                            }}
                                            maxLength="10"
                                          />
                                          {!MobileErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              Mobile Number
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {MobileErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            onInputCapture={
                                              this.SelectDepartment
                                            }
                                            name="Department"
                                            id="Department"
                                            onChange={() => {
                                              this.handleChange();
                                              this.DepthandleChange();
                                            }}
                                          >
                                            <option value="">Select</option>
                                            <option id="Add" value="Add">
                                              Add
                                            </option>
                                            {Departmentdrop.map(
                                              (Departmentdrop) => (
                                                <option
                                                  value={
                                                    Departmentdrop.department
                                                  }
                                                >
                                                  {Departmentdrop.department}{" "}
                                                </option>
                                              )
                                            )}
                                            <option value="None">None</option>
                                          </select>
                                          {!departmentErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              Department
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {departmentErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            name="Designation"
                                            onInputCapture={
                                              this.SelectDesignation
                                            }
                                            onChange={this.handleChange}
                                            id="Designation"
                                            type="text"
                                          >
                                            <option value="">Select</option>
                                            <option id="Add" value="Add">
                                              Add
                                            </option>
                                            {Designationdrop.map(
                                              (Designationdrop) => (
                                                <option
                                                  value={
                                                    Designationdrop.designation
                                                  }
                                                >
                                                  {Designationdrop.designation}
                                                </option>
                                              )
                                            )}
                                            <option value="None">None</option>{" "}
                                          </select>
                                          {!designationErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              Designation
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {designationErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            name="RoleName"
                                            onChange={this.handleChange}
                                            defaultValue={"User"}
                                            type="text"
                                            id="RoleName"
                                          >
                                            <option value="">Select</option>
                                            <option value="Admin">Admin</option>
                                            <option value="HR Admin">
                                              HR Admin
                                            </option>
                                            <option value="Team Admin">
                                              Team Admin
                                            </option>
                                            <option value="Skill Admin">
                                              Skill Admin
                                            </option>
                                            <option value="Education Admin">
                                              Education Admin
                                            </option>
                                            <option value="User">User</option>
                                          </select>
                                          {!rolesErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              Role Name
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {rolesErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>
                                        {/* "Report_Manager" */}

                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            name="Report_Manager"
                                            onInputCapture={this.SelectManager}
                                             defaultValue={"None"}
                                            id="Manager"
                                            onChange={() => {
                                              this.handleChange();
                                              //  this.ManagerNamehandleChange();
                                            }}
                                            //  onFocus={this.ManagerNamehandleChange}
                                            type="text"
                                          >
                                            <option value="">Select</option>
                                            <option id="Add" value="Add">
                                              Add
                                            </option>

                                            {this.state.ManagerNamedrop.map((ManagerDrop) => (
                                              <option
                                                value={ManagerDrop.manager_Name}
                                              >
                                                {ManagerDrop.manager_Name}
                                              </option>
                                            ))}

                                            <option value="None">None</option>{" "}
                                          </select>
                                          {!ManagerErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              Reporting Manager
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {ManagerErr}
                                            </div>
                                          )}

                                          <span className="underline" />
                                        </div>

                                        {/* LocationDrop */}
                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            name="Primary_Ofc"
                                            onInputCapture={this.SelectLocation}
                                             defaultValue={"None"}
                                            id="Locations"
                                            onChange={this.handleChange}
                                            type="text"
                                          >
                                            <option value="">Select</option>
                                            {/* <option id="Add" value="Add">
                                              Add
                                            </option> */}
                                            <option id="" value= "Primary Location">
                                            Primary Location
                                            </option>
                                            <option id="" value= "Secondary Location">
                                            Secondary Location
                                            </option>
                                            {/* {LocationDrop.map(
                                              (LocationDrop) => (
                                                <option
                                                  selected={
                                                    LocationDrop.locationType ===
                                                    "Primary Location"
                                                  }
                                                  value={
                                                    LocationDrop.locationType
                                                  }
                                                >
                                                  {LocationDrop.locationType}
                                                </option>
                                              )
                                            )} */}
                                            {/* <option
                                              value="Primary Location"
                                              hidden
                                            >
                                              Primary Location
                                            </option> */}
                                            <option value="None">None</option>{" "}
                                          </select>
                                          {!LocationErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              Locations
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {LocationErr}
                                            </div>
                                          )}

                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            style={{ marginTop: "-6px" }}
                                            name="JoiningDate"
                                            id="JoiningDate"
                                            defaultValue={new Date(
                                              new Date().getFullYear(),
                                              new Date().getMonth(),
                                              new Date().getDate() + 1
                                            )
                                              .toJSON()
                                              .slice(0, 10)}
                                            type="date"
                                            max={new Date(
                                              new Date().getFullYear(),
                                              new Date().getMonth(),
                                              new Date().getDate() + 30
                                            )
                                              .toJSON()
                                              .slice(0, 10)}
                                            min={new Date(
                                              new Date().getFullYear() - 20,
                                              new Date().getMonth(),
                                              new Date().getDate()
                                            )
                                              .toJSON()
                                              .slice(0, 10)}
                                            onChange={this.handleChange}
                                            maxLength="50"
                                          />
                                          {!JoiningDateErr ? (
                                            <span style={{ color: "#a29d9d" }}>
                                              Join date
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {JoiningDateErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>
                                        <div className="col-sm-12" style={{ marginBottom: "10px" }}>
                                          <h4>Assigned as trainer</h4>
                                        </div>
                                        <div className="col-sm-6">
                                          {/* <span style={{color:'#a29d9d'}} >Trainer</span>&nbsp;&nbsp;&nbsp;&nbsp; */}
                                          <label>
                                            <input
                                              type="checkbox"
                                              id="Yes"
                                              name="Yes"
                                              checked={this.state.checkBox1}
                                              onChange={() =>
                                                this.handleCheckBox1Change()
                                              }
                                            />
                                          </label>
                                          &nbsp;&nbsp;
                                          <span>Yes</span>
                                          &nbsp;&nbsp;
                                          <label id="No">
                                            <input
                                              type="checkbox"
                                              id="No"
                                              name="No"
                                              checked={this.state.checkBox2}
                                              onChange={() =>
                                                this.handleCheckBox2Change()
                                              }
                                            />
                                          </label>
                                          &nbsp;&nbsp;
                                          <span>No</span>
                                          &nbsp;&nbsp;
                                          {/* {this.state.Experience ?<select className="effect-16" type="text" id="Experience" onClick={this.selectExperience}  > */}
                                          {/* {this.state.Experience ?<select className="effect-16" type="text" name="TrainerExper" id="Experience" onClick={this.selectExperience}  >
    <option value="" >Select</option> 
          <option value="0-1"></option>
          <option value="1-2">1-2 year</option>
          <option value="2-3">2-3 year</option>
          <option value="3-4">3-4 year</option>
          <option value="4-5">4-5 year</option>
          <option value="More">More</option>
          </select>:null} */}
                                          {this.state.checkBox1 === true ? (
                                            <>
                                              <input
                                                className="form"
                                                onFocus={(e) =>{
                                                  e.target.select();
                                                  (e.target.type = "date");
                                                }}
                                                name="TrainerExperience"
                                                id="Experience"
                                                type="text"
                                                // onFocus={(e) =>
                                                //   (e.target.type = "date")
                                                // }
                                                // max={this.state.maxDate}
                                                max={currentDate}
                                              />
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Trainer Experience
                                              </span>
                                            </>
                                          ) : null}
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="col-sm-12"
                                    style={{ textAlign: "center" }}
                                  >
                                    <button
                                      type="submit"
                                      disabled={this.state.ButtonDisabled}
                                      className="but "
                                      style={{
                                        height: "39.5px",
                                        width: "86.36px",
                                        marginTop: "-0.3%",
                                      }}
                                    >
                                      Save
                                    </button>
                                    &nbsp; &nbsp; &nbsp;
                                    <button
                                      type="reset"
                                      className=" btn-Secondary"
                                      style={{ height: "39.5px" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                                <br /> <br />
                                <br /> <br />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
