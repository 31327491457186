import React, { useState } from "react";
import LogoCompo from "../Common/LogoCompo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";

const Forgot = (props) => {
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [value] = useState(props.location.state);
  const [ButtonDisabled, setButtonDisabled] = useState(false);

  const handleFormValidation = () => {
    let errors = {};
    let isValid = true;

    if (!email || email.trim() === "") {
      isValid = false;
      errors["emailErr"] = "Registered Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      isValid = false;
      errors["emailErr"] = "Invalid Email Id";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);

    let errors = {};
    if (!value || value.trim() === "") {
      errors["emailErr"] = "Registered Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      errors["emailErr"] = "Invalid Email Id";
    }

    setFormErrors(errors);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (handleFormValidation()) {
      setButtonDisabled(true)
      const data = new FormData(event.target);
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      const url = `${BASE_URLs}/Forgot/SendEmailOTP`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };

      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success("OTP Sent Successfully,Check Your Email", {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          props.history.push("/OTP");
        }, 4000);

        let myCipher = cipher("mySecretSalt");
        localStorage.setItem("rml", myCipher(JSON.stringify(result.email)));
        localStorage.setItem("mlcode", myCipher(JSON.stringify(result.otp)));
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setButtonDisabled(false);
    }
  };

  const cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
  };

  var w = window.innerWidth;
  const { emailErr } = formErrors;

  return (
    <div>
      <div>
        <div className="bgImg" />
        <div className="content">
          {" "}
          <br />
          <ToastContainer
            position="top-center"
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <LogoCompo />
          <div
            className={
              w < 992
                ? "  h-100 align-items-center justify-content-center"
                : " d-flex h-100 align-items-center justify-content-center"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-sm-6 ml-auto mr-auto">
                  <div className="card-copy">
                    <div className="card-body">
                      <form id="myForm" onSubmit={handleSubmit}>
                        <br />
                        <br />
                        <br />{" "}
                        <div>
                          <h4 className="title1">
                            <span className="shadows">Forgot Password</span>
                          </h4>
                        </div>
                        <div className="signUp">
                          <span>
                            <input
                              className="form"
                              maxLength="50"
                              name="email"
                              type="text"
                              id="Email"
                              onChange={handleChange}
                              placeholder="Enter Your Registered Email"
                              autoComplete="off"
                            />
                            {emailErr && (
                              <span style={{ color: "red", paddingBottom: 10 }}>
                                {emailErr}
                              </span>
                            )}
                            <span className="underline" />
                          </span>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="but"
                            style={{
                              width: "90.36px",
                              height: "39.5px",
                              margin: "auto",
                            }}
                            disabled={ButtonDisabled}
                          >
                            Send OTP
                          </button>
                          &nbsp;&nbsp;&nbsp;
                          <button
                            onClick={() => props.history.goBack()}
                            className="btn-Secondary"
                            style={{ width: "83.36px", height: "39.5px" }}
                          >
                            Back
                          </button>
                        </div>
                        <br></br>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Forgot;
