import React, { Component } from "react";
import AddManagerPop from "../../Common/AddManagerPop";
import EditManagerPop from "../../Common/EditManagerPop";
import SideBar from "../../Common/Sidebar";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import CloseIcon from "@material-ui/icons/Close";
import { BASE_URLs } from "../../Config/Base";
import Managerexl from "../../Assets/Files/Managersexl.xlsx";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import { OutTable } from "react-excel-renderer";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import "../../Css/excel.css";
var XLSX = require("xlsx");
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      DepartmentName: "",
      ManagerModel: false,
      comlogo: [],
      Manager: [],
      Man: [],
      rows: [],
      cols: [],
      EditModel: false,
      EditManagerValues: [],
      formErrors: {},
      letters: [],
      Filterpop: false,
      Notification: [],
      FilterData: "",
      showExcel: false,
      showSelectAll: false,
      showDelete: false,
      showDeleteCount: false,
      selectAllChecked: false,
      delay: false,
      clearSelected: false,
      selectedNames: [],
      selectedRows:[],
    };
    //this.handleSubmit=this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
  }

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("ManagerExport");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }


  handleUpload = (event) => {
    this.setState({ showExcel: true });

    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document.getElementById("delete").setAttribute("style", "visibility: hidden;");
        document.getElementById("deletecount").setAttribute("style", "visibility: hidden;");
      }
    }

    // Get the uploaded file
    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx|\.xlsm)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);

    // Check if the file is allowed
    if (isAllowed) {
      // Read the file using FileReader
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        // Check if the parsed data is empty
        if (parsedData.length === 0) {
          toast.error("The uploaded Excel file is empty.", { autoClose: 3200 });
          this.setState({ initialData: [], showExcel: false });
        } else {
          this.setState({ initialData: parsedData });
        }
      };
      reader.onerror = (error) => {
        // console.error("Error reading file:", error);
      };
      reader.readAsBinaryString(files[0]);

      return true; // Indicate successful file upload
    } else {
      alert("Invalid File Type.");
      return false; // Indicate failed file upload
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}/Manager/ManagerExcel`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert("0  Records  inserted ");
          }
          if (resp === 0) {
            alert("Records added Successfully");
          } else {
            alert(+resp + " Records not inserted ");
          }
          this.GetManager();
          this.setState({ initialData: undefined });
        });
      var file = document.getElementById("files");
      var emptyFile = document.createElement("input");
      emptyFile.type = "file";
      file.files = emptyFile.files;
    } catch (error) { }
  };


  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  GetManager() {
    let url = `${BASE_URLs}/Manager/ManagerGet`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Manager: data,
          Man: data,
        });

        //
      });
  }

  onKeyUp = (e) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    const Manager = this.state.Man.filter(
      (item) =>
        item.manager_Name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.department_Name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    this.setState({ Manager });
  };

  handleManagerFilter = (letter, event) => {
    event.preventDefault();
    const Manager = this.state.Man.filter((item) => {
      return (
        item.manager_Name.toLowerCase().startsWith(letter.toLowerCase()) ||
        item.department_Name.toLowerCase().startsWith(letter.toLowerCase())
      );
    });
    this.setState({ Manager, showResults: true, FilterData: Manager });
  };

  ManagerModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState((prevState) => ({
      ManagerModel: !prevState.ManagerModel,
    }));
    this.clearSelection();
    this.GetManager();
  };
  
  EditModel = (e, fn, ff) => {
    this.clearSelection();
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditManagerValues: {
        id: e,
        ManagerName: fn,
        department: ff,
      },
      showDelete: false,
      showDeleteCount: false,
      SelectedNames: [],
      selectAllChecked: false, 
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    this.GetManager();
  };
  componentDidMount() {
    this.GetOrgLogo();
    this.GetManager();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
    setTimeout(() => {
      this.setState({ delay: true })
    }, 2000);
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}/AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}/AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}/AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  cancel = () => {
    var inputElement = document.getElementById("files");
    inputElement.value = "";

    this.setState({ showExcel: false });
  };

  handleFormValidation() {
    const {
      FirstName,
      LastName,
      Email,
      Mobile,
      Department,
      Designation,
      RoleName,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required.";
    }
    if (!LastName) {
      formIsValid = false;
      formErrors["LastNameErr"] = "Last Name is required.";
    }
    if (!Email) {
      formIsValid = false;
      formErrors["EmailErr"] = "Email is required.";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["EmailErr"] = "Invalid Email id.";
    }
    if (!Mobile) {
      formIsValid = false;
      formErrors["MobileErr"] = "Mobile is required.";
    }
    // else if (!Mobile >= 10) {
    //   formIsValid = false;
    //   formErrors["MobileErr"] = "Invalid Mobile number.";
    // }
    else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
      if (!mobPattern.test(Mobile)) {
        formIsValid = false;
        formErrors["MobileErr"] = "Invalid phone number.";
      }
    }
    if (!Department) {
      formIsValid = false;
      formErrors["departmentErr"] = "department is required.";
    }
    if (!Designation) {
      formIsValid = false;
      formErrors["designationErr"] = "designation is required.";
    }
    if (!RoleName) {
      formIsValid = false;
      formErrors["rolesErr"] = "Role is required.";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  Noticount = async (Id) => {
    await fetch(`${BASE_URLs}/Notification/Noticount`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Notification_Id: Id,
      }),
    }).then((result) => {
      result.json().then((resp) => {
        localStorage.setItem("success", JSON.stringify(resp.status));
        var a = localStorage.getItem("success");
        var b = JSON.parse(a);
        if (b === "Success") {
          window.location.href = "/Organization";
          localStorage.removeItem("success");
        }
      });
    });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  checkAll(e) {
    var checkboxes = document.getElementsByName("Id_delete");

    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;margin-top: 30px;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + "  selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  selectableRowSelected = (row) => {
    const { SelectedNames } = this.state;
    if (!Array.isArray(SelectedNames)) {
      return false;
    }
    return SelectedNames.some(selectedRow => selectedRow.managerId === row.managerId);
  };
  
  handlecheckbox = (state) => {
    const selectedRows = state.selectedRows;
    const { SelectedNames } = this.state;
    const currentSelectedNames = Array.isArray(SelectedNames) ? SelectedNames : [];
    const uniqueManagerIds = new Set([
      ...currentSelectedNames.map(row => row && row.managerId),
      ...selectedRows.map(row => row && row.managerId)
    ]);
    const updatedSelectedNames = Array.from(uniqueManagerIds)
      .filter(managerId => managerId !== undefined && managerId !== null)
      .map(managerId => {
        const existingRow = currentSelectedNames.find(row => row && row.managerId === managerId);
        if (existingRow) {
          return existingRow;
        }
        return selectedRows.find(row => row && row.managerId === managerId);
      });
    const updatedSelectedRows = selectedRows.filter(row =>
      uniqueManagerIds.has(row.managerId)
    );
    this.setState({
      SelectedNames: updatedSelectedNames,
      selectedRows: updatedSelectedRows,
      showSelectAll: updatedSelectedRows.length >= 2,
      showDelete: updatedSelectedRows.length >= 1,
      showDeleteCount: updatedSelectedRows.length > 0,
      NewState: updatedSelectedNames,
    });
  };
  // handlecheckbox() {
  //   let checkboxes = document.querySelectorAll(
  //     '#tablepadding input[type="checkbox"]:checked'
  //   );
  //   let Total = document.querySelectorAll('input[type="checkbox"]');
  //   // selectalldiv
  //   if (checkboxes.length >= 2) {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:visible ;");
  //   } else {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:hidden ;");
  //   }
  //   if (checkboxes.length >= 1) {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility:visible ;margin-top: 30px;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute(
  //         "style",
  //         "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
  //       );
  //     var msg = document.getElementById("deletecount");
  //     let count = checkboxes.length - 1;

  //     if (document.getElementById("tablepadding").checked) {
  //       msg.innerHTML = "<span> " + count + " selected </span> ";
  //     } else {
  //       msg.innerHTML = "<span> " + checkboxes.length + " selected </span> ";
  //     }
  //   } else {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility: hidden;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute("style", "visibility: hidden;");
  //   }
  //   if (document.getElementById("selectall").checked === false) {
  //     if (checkboxes.length === Total.length - 1) {
  //       //
  //       document.getElementById("selectall").checked = true;
  //     }
  //   } else {
  //     if (document.getElementById("selectall").checked) {
  //       if (checkboxes.length - 2 !== Total.length) {
  //         document.getElementById("selectall").checked = false;
  //       }
  //     }
  //   }
  // }

  // Multidelete=(event)=>{
  //   event.preventDefault();
  //   if(document.getElementById("selectall")){
  //     document.getElementById("selectall").checked = false; }
  //     if(document.getElementsByName('Id_delete')){
  //     var checkboxes = document.getElementsByName('Id_delete');
  //     for (var i = 0; i < checkboxes.length; i++) {
  //       checkboxes[i].checked = false;
  //       document.getElementById("delete").setAttribute('style', 'visibility: hidden;');
  //       document.getElementById("deletecount").setAttribute('style', 'visibility: hidden;');
  //     }}

  //   const data = new FormData(event.target);
  //   if (window.confirm("Are you sure you want to delete this record permenently?")){
  //   var options = {
  //     method: 'POST',
  //     headers: {
  //         'Accept': 'application/json',
  //     },
  //     body: data

  //     };
  //     fetch('${BASE_URLs}/Manager/Multidelete',options)
  //     .then(res => res.json()).then((resp) => {
  //     alert( resp.message);

  //     })

  //     document.getElementById("delete").setAttribute('style', 'visibility: hidden;');
  //     document.getElementById("deletecount").setAttribute('style', 'visibility: hidden;');
  //     var checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');

  //       for (var   i = 0; i < checkboxes.length; i++) {
  //         checkboxes[i].checked = false;
  //       }
  //        document.querySelectorAll('input[type="checkbox"]').checked=false;
  //         }
  //         this.GetManager();
  //         }
  Multidelete = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    this.state.selectedRows.forEach((row) => {
      data.append("Id_delete", row.managerId);
    });
    let value = this.state.selectedRows.length === 1 ? this.state.selectedRows[0].manager_Name : this.state.selectedRows.length;
    if (
      this.state.selectedRows.length === 1 ? window.confirm("Are you sure you want to delete this " + `"${value}"` + " record permanently?") :
      window.confirm("Are you sure you want to delete these " + value + " records permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}/Manager/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.setState({ initialData: undefined });
          this.GetManager();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
    this.clearSelection();
    this.GetManager();
  };

  DeleteButton = (managerId,manager_Name) => {
    const data = new FormData();
    data.append("Id_delete", managerId);
    if (
      window.confirm("Are you sure you want to delete this " + `"${manager_Name}"` + " record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}/Manager/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetManager();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
      this.clearSelection();
    }
  };
  handleSelectAllChange = (event) => {
    const { checked } = event.target;
    this.setState({ selectAllChecked: checked });
  };

  clearSelection() {
    this.setState({ clearSelected: true }, () => {
      this.setState({ 
        clearSelected: false, 
        showDelete: false,
        showDeleteCount: false,
        SelectedNames: [],
        selectedRows:[],
        selectAllChecked: false,  });
    });
  }

  render() {
    var username = JSON.parse(localStorage.uname);
    const { delay, Manager, Filterpop, FilterData, showExcel ,SelectedNames,selectedRows} = this.state;
    var filename = `O_${username}_Manager`;
    const customStyles = {
      headCells: {
        style: {
          flex: "0.5"
        }
      },
      header: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0"
        },
      },
      headCells: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0",
          borderBottom: "1px solid #ccc",

        },
      },

      columns: {
        idHeader: {
          style: {
            fontWeight: 'bold',
            fontSize: "14px",
            flex: "0"
          },
        },
      },
    };

    const columns = [
      {
        name: 'Name',
        selector: row => row.manager_Name,
        id: "isheader",
        grow: 3.5,
        sortable: false,
      },
      {
        name: 'Department',
        selector: row => row.department_Name,
        id: "isheader",
        grow: 3.5,
        sortable: false,
      },

      {
        name: 'Actions',
        grow: 0,
        cell: row => (
          <div>
            <button
              className="button-30 sk-del"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ Filterpop: false });
                this.DeleteButton(row.managerId,row.manager_Name);
              }}
              title="Delete"
              style={{ marginLeft: "-10px" }}
            >
              <i className="fa fa-minus" aria-hidden="true"></i>
            </button>
            &nbsp;&nbsp;
            {" "}
            <button
              className="Annbuttons sk-edit"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ Filterpop: false });
                this.EditModel(
                  row.managerId,
                  row.manager_Name,
                  row.department_Name,
                );
              }}
              title="Edit"
            >
              <i className="fa fa-edit" aria-hidden="true"></i>
            </button>
          </div>
        )
      }
    ];
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.pathname} />
        {this.state.EditModel ? (
          <EditManagerPop
            values={this.state.EditManagerValues}
            close={() => this.EditModel("")}
          />
        ) : null}
        {this.state.ManagerModel ? (
          <AddManagerPop close={() => this.ManagerModel()} />
        ) : null}

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            Managers{" "}
                          </h4>
                        </div>

                        <div className="card-block">
                          <form onSubmit={this.handleSubmit}>
                            <div className="row">
                              <div className="col btnalign">
                                <h5 className="card-header-text">
                                  <Link
                                    onClick={this.ManagerModel}
                                    onKeyPress={this.handleKeyPress}
                                    className="btn"
                                    style={{ width: "150px", background: "white" }}
                                  >
                                    Add
                                  </Link>
                                </h5>{" "}
                              </div>
                              <div className="col btnalign">
                                <label
                                  htmlFor="files"
                                  className="btn"
                                  id="addBtn"
                                  name="Managers"
                                >
                                  Import From Excel
                                </label>
                                <input
                                  id="files"
                                  type="file"
                                  accept=".xlsx"
                                  style={{ display: "none" }}
                                  onChange={this.handleUpload}
                                  name="Managers"
                                />
                              </div>
                              <div style={{ padding: "7px" }} ></div>
                              <div className="col btnalign">
                                <button
                                  className="btn"
                                  type="button"
                                  onKeyPress={this.handleKeyPress}
                                  onClick={() => this.exportXlsx(filename)}
                                >
                                  Export to excel
                                </button>
                                {/*<ReactHTMLTableToExcel                  
           className="btn"  
           table="ManagerSheet"  
           filename={filename}
           sheet="Sheet"  
           buttonText="Export to Excel " id="exportxlUG"
            /></div>
             <div className="col btnalign">*/}
                              </div>
                              <div
                                className="col btnalign"
                                onKeyDown={this.handleKeyPress}
                              >
                                <a
                                  className="btn"
                                  href={Managerexl}
                                  type="button"
                                  style={{ color: "#5035ce" }}
                                >
                                  <i
                                    title="Download Template"
                                    className="icofont icofont-download"
                                  ></i>{" "}
                                  Download a template
                                </a>
                              </div>
                              <OutTable
                                data={this.state.rows}
                                columns={this.state.cols}
                                tableClassName="ExcelTable2007"
                                tableHeaderRowclassName="heading"
                              />
                              <div
                                className="col-lg-1"
                                style={{
                                  height: "73px",
                                  textAlign: "center",
                                  cursor: "pointer",
                                  visibility:"hidden",
                                }}
                              >
                                {/* Just for design */}
                              </div>
                              <div>
                                <input
                                  onKeyUp={this.onKeyUp}
                                  className="Searchbox"
                                  id="CNSearch"
                                  placeholder="Search"
                                  onClick={ () => {
                                    const { selectedRows, SelectedNames } = this.state;
                                    if (selectedRows.length === 0) {
                                      this.setState({
                                        Filterpop: true,
                                      });
                                    } else {
                                      if (SelectedNames.length !== selectedRows.length) {
                                        this.setState({
                                          SelectedNames: selectedRows,
                                          selectedRows: selectedRows,
                                          Filterpop: true,
                                        });
                                      } else {
                                        this.setState({
                                          Filterpop: true,
                                          selectedRows: SelectedNames,
                                        });
                                      }
                                      
                                    }
                                  }}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div
                            >
                              <input
                                className="Searchbox"
                                id="CNSearch1"
                                placeholder="Search"
                                onKeyPress={this.handleKeyPress}
                                onKeyUp={this.myFunction}
                                // value={filterValue}
                                onClick={ () => {
                                  const { selectedRows, SelectedNames } = this.state;
                                  if (selectedRows.length === 0) {
                                    this.setState({
                                      Filterpop: true,
                                    });
                                  } else {
                                    if (SelectedNames.length !== selectedRows.length) {
                                      this.setState({
                                        SelectedNames: selectedRows,
                                        selectedRows: selectedRows,
                                        Filterpop: true,
                                      });
                                    } else {
                                      this.setState({
                                        Filterpop: true,
                                        selectedRows: SelectedNames,
                                      });
                                    }
                                    
                                  }
                                }}
                                readOnly
                              />
                            </div>
                            <center>
                              {showExcel && this.state.initialData !== undefined && (
                                <table className="table" id="tableDesign">
                                  <thead >
                                    <tr>
                                      {Object.keys(this.state.initialData[0]).map((key) => (
                                        <th key={key} id="team1design">{key}</th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.initialData.map((row, index) => (
                                      <tr key={index}>
                                        {Object.values(row).map((value, index) => (
                                          <td key={index} id="teamdesign">{value}</td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </center>
                            {showExcel && this.state.initialData !== undefined && (
                              <div className="savebtndiv">
                                <button
                                  className="btn"
                                  id=""
                                  type="submit"
                                  style={{ width: "150px" }}
                                >
                                  Save
                                </button>
                                <span
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px" }}
                                  onClick={this.cancel}
                                >
                                  Cancel
                                </span>

                              </div>)}
                            <br />
                          </form>
                        </div>



                        <form
                          onSubmit={this.Multidelete}
                          onKeyPress={this.handleKeyPress}
                          style={{ marginTop: "-37px" }}
                        >
                          <button
                            type="submit"
                            className="multitrash"
                            id="delete"
                            style={{ float: "right", visibility: this.state.showDelete ? 'visible' : 'hidden' }}
                          >
                            <i
                              className="icofont icofont-delete-alt"
                              style={{ color: "darkgray" }}
                            ></i>
                          </button>
                          <span
                            id="deletecount"
                            className="multitrash"
                            style={{ visibility: this.state.showDeleteCount ? 'visible' : 'hidden', fontSize: "17px" }}
                          >
                            {this.state.showDeleteCount && (
                              <span style={{ backgroundColor: "#007bff", background: "linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)" }}>{this.state.selectedRows.length} selected</span>
                            )}
                          </span>
                          <div id="selectalldiv" style={{ visibility: this.state.showSelectAll ? 'hidden' : 'hidden' }}>
                            <input type="checkbox" id="selectall" checked={this.state.selectAllChecked} onChange={this.handleSelectAllChange} />&nbsp;&nbsp;
                          </div>
                          <center>
                            <div className="dt-responsive table-responsive" style={{ maxWidth: "60%" }}>
                            <DataTable
  columns={columns}
  data={Manager}
  keyField="managerId"
  selectableRows
  selectableRowSelected={!this.state.Filterpop ? this.selectableRowSelected : undefined}
  onSelectedRowsChange={this.handlecheckbox}
  pagination
  customStyles={customStyles}
  clearSelectedRows={this.state.clearSelected}
  noDataComponent={Manager.length === 0 && delay ? <div style={{ textAlign: 'center' }}>No Records</div> : null}
/>
                            </div>
                          </center>
                          {/* 
                            <div
                              className="dt-responsive table-responsive table-res"

                              //  style={{display:"flex",alignItems:"center",justifyContent:"center"}}
                            >
                              {this.state.Man.length === 0 ? null : (
                                <table
                                  id="ManagerSheet"
                                  className="table nowrap"
                                  style={{ maxWidth: "85%" }}
                                >
                                  <thead id="tablepaddingA">
                                    <tr>
                                      <th>
                                        <input
                                          type="checkbox"
                                          id="selectall"
                                          onClick={() => {
                                            this.checkAll(this);
                                          }}
                                        />{" "}
                                      </th>
                                      <th>Name</th>
                                      <th>Department</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody id="tablepadding">
                                  {FilterData.length === 0 && Manager.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                    {Manager.map((Manager) => (
                                      <tr>
                                        <td>
                                          <input
                                            type="checkbox"
                                            name="Id_delete"
                                            onChange={this.handlecheckbox}
                                            defaultValue={Manager.managerId}
                                          />
                                        </td>
                                        <td>{Manager.manager_Name}</td>
                                        <td>{Manager.department_Name}</td>
                                        <td>
                                        <button
                                            className="button-30 sk-del"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.DeleteButton(Manager.managerId);
                                            }}
                                            style={{marginLeft:"-15px"}}
                                            title="Delete"
                                          >
                                            {/* Delete&nbsp; */}
                          {/* <i
                                              className="fa fa-minus"
                                              aria-hidden="true"
                                            ></i>
                                          </button>{" "}
                                          &nbsp;&nbsp;&nbsp;
                                          <button
                                            className="button-30 sk-edit"
                                            title="Edit"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.EditModel(
                                                Manager.managerId,
                                                Manager.manager_Name,
                                                Manager.department_Name
                                              );
                                            }}
                                          >
                                          
                                            <i
                                              className="fa fa-edit"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                          &nbsp;&nbsp;&nbsp;
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div> */}
                        </form>
                      </div>
<br /><br />

<table
                              id="ManagerExport"
                              className="ManagerExport"
                              hidden
                            >
                              <thead>
                                <tr>
                                <th>Name</th>
                                      <th>Department</th>
                        
                                </tr>
                              </thead>
                              <tbody>
                                {Manager.map((Manager) => (
                                  <tr>
                                  <td>{Manager.manager_Name}</td>
                                  
                                  <td>{Manager.department_Name}</td>
                            
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                    </div>
                  </div>
                </div>
              </div>
              {Filterpop && (
                <div className="popSearchTeam">
                  <div className="popSearchinnernormalTeam">
                    <div
                      className="popSearchinnertitle"
                      id="popSearchinnernormal"
                    >
                      <button
                        id="close"
                        style={{
                          float: "right",
                          backgroundColor: "#a0a3a305",
                          color: "#797070",
                          border: "1px solid #a3a5a505",
                          marginTop: "-20px",
                          marginRight: "-20px",
                        }}
                        onClick={() => {
                          this.setState({ Filterpop: false,
                          SelectedNames:selectedRows
                          });
                          this.GetManager();
                        }}
                      >
                        <CloseIcon />
                      </button>

                      <div className="checkboxes">
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{
                            fontSize: "20px",
                            color: "gray",
                            marginLeft: "-1%",
                            position: "absolute",
                            marginTop: "0.5%",
                          }}
                        ></i>
                        <input
                          className="Searchbox"
                          placeholder="Search"
                          onKeyUp={this.onKeyUp}
                          style={{ float: "right", width: "80%" }}
                        />
                        <br></br>
                        {this.state.letters.map(
                          (letter, index) => (
                            <button
                              className="filter"
                              key={index}
                              onClick={(event) =>
                                this.handleManagerFilter(
                                  letter,
                                  event
                                )
                              }
                            >
                              {letter}
                            </button>
                          )
                        )}
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
