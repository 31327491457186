import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { BASE_URLs } from "../Config/Base";
export default class EditTrainerpop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Training: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var TrainingName = document.getElementById("TrainingName").value;

    if (!TrainingName) {
      formIsValid = false;
      formErrors["TrainingName"] = "Training Name  is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.Validation()) {
      const data = new FormData(event.target);

      fetch(`${BASE_URLs}/Training/EditTraining`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept': 'application/json',
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 1200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(function () {
              if (document.getElementById("close")) {
                document.getElementById("close").click();
              }
            }, 4000);
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      // document.getElementById("close").click();
    }
  }
  GetTraining() {
    let url = `${BASE_URLs}/Training/GetMasterTraining`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Training: data,
        });
      });
  }
  componentDidMount() {
    // this.RelationshipDropList();
    this.GetTraining();
  }
  render() {
    var w = window.innerWidth;
    const { Training } = this.state;
    const { TrainingName } = this.state.formErrors;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />{" "}
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
          >
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <button
                id="close"
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={this.props.close}
              >
                <CloseIcon />
              </button>

              <div className="row">
                <div className="col-lg-3  "></div>
                <div className="col-lg-8 ">
                  <select className="form">
                    <option value="fruit">Trainer's Name</option>
                    <option value="fruit">Arul</option>
                    <option value="vegetable">Vimal</option>
                    <option value="meat">Sharik</option>
                    <option value="meat">Azaar</option>
                  </select>
                  <span style={{ color: "#a29d9d", float: "left" }}>
                    Choose Trainer Name
                  </span>
                  <span className="underline" />
                </div>
                {/* <div className="col-lg-3  "></div> */}
              </div>

              <div className="row" style={{ padding: "3px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    className="form"
                    maxLength="50"
                    id="Department"
                    name="Department"
                    type="text"
                  />

                  <span style={{ color: "#a29d9d", float: "left" }}>
                    {" "}
                    Department
                  </span>

                  <span className="underline" />
                </div>
              </div>

              <div className="row" style={{ padding: "3px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    className="form"
                    maxLength="50"
                    id="Role"
                    name="Role"
                    type="text"
                  />

                  <span style={{ color: "#a29d9d", float: "left" }}> Role</span>

                  <span className="underline" />
                </div>
              </div>

              <div className="row" style={{ padding: "3px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    className="form"
                    maxLength="50"
                    id="TrainingName"
                    name="TrainingName"
                    type="text"
                  />

                  <span style={{ color: "#a29d9d", float: "left" }}>
                    {" "}
                    TrainingName
                  </span>

                  <span className="underline" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
