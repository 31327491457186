import React, { Component } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SideBar from "../../Common/Sidebar";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import $ from "jquery";
import { BASE_URLs } from "../../Config/Base";
export default class SkillMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edus: [],
      edusdrop: [],
      Addedus: [],
      Id: "",
      UG: "",
      Specialization: "",
      Univ: [],
      InActive: [],
      Active: [],
      All: [],
      Notification: [],
      PG: [],
      PHD: [],
    };

    this.initialState = this.state;
  }
  componentWillMount() {
    $(document).ready(function () {
      $("#exportxlPG").hide();
      $("#exportxlPHD").hide();
      $("#myInput").click(function () {
        $("#UGSheet").show();
        $("#PGSheet").hide();
        $("#PHDSheet").hide();
        $("#exportxlUG").show();
        $("#exportxlPG").hide();
        $("#exportxlPHD").hide();
      });
    });

    $(document).ready(function () {
      $("#myInput1").click(function () {
        $("#PGSheet").show();
        $("#UGSheet").hide();
        $("#PHDSheet").hide();
        $("#exportxlPG").show();
        $("#exportxlUG").hide();
        $("#exportxlPHD").hide();
      });
    });

    $(document).ready(function () {
      $("#myInput2").click(function () {
        $("#PHDSheet").show();
        $("#UGSheet").hide();
        $("#PGSheet").hide();
        $("#exportxlPHD").show();
        $("#exportxlPG").hide();
        $("#exportxlUG").hide();
      });
    });
  }

  handleFormValidation() {
    const { SkillName, SkillDescription, Category } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "SkillName is required";
    }
    if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "SkillDescription is required";
    }

    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  GetAdminUG() {
    let url = `${BASE_URLs}/Education/GetAdminUG`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          edus: data,
        });

        //
      });
  }

  GetAdminPG() {
    let url = `${BASE_URLs}/Education/GetAdminPG`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          PG: data,
        });

        //
      });
  }
  GetAdminPHD() {
    let url = `${BASE_URLs}/Education/GetAdminPHD`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          PHD: data,
        });
      });
  }

  GetEdudrop() {
    let url = `${BASE_URLs}/Education/getMasterEducationdrop`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          edusdrop: data,
        });
        //
      });
  }

  deleteSkill(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}/Skill/Delete?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
        });
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  componentDidMount() {
    this.GetEdudrop();
    this.GetAdminPHD();
    this.GetAdminPG();
    this.GetAdminUG();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  Edit1(Id) {
    var page = {
      pathname: "/EditUserEducation",
      state: {
        Id: Id,
      },
    };
    this.props.history.push(page);
  }

  EditPG(Id) {
    var page = {
      pathname: "/EditPG",
      state: {
        Id: Id,
      },
    };
    this.props.history.push(page);
  }

  EditPHD(Id) {
    var page = {
      pathname: "/EditPHD",
      state: {
        Id: Id,
      },
    };
    this.props.history.push(page);
  }

  handlecheckbox() {
    var checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: visible;");
      var msg = document.getElementById("deletecount");
      msg.innerHTML = "<span> " + checkboxes.length + " rows selected </span> ";
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      //msg.innerHTML = '<span> </span> '
    }
  }
  checkAll(e) {
    var checkboxes = document.getElementsByName("Id_delete");
    var sa = document.getElementById("selectall");
    let count = checkboxes.length + 1;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: visible;");
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " rows selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  Multidelete(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",

        //Authorization: `Bearer ${JSON.parse(localStorage.user)}`
      },
      body: data,
    };
    fetch(`${BASE_URLs}/Education/Multidelete`, options)
      .then((response) => response.json())
      .then((data) => {});
    document
      .getElementById("delete")
      .setAttribute("style", "visibility: hidden;");
    document
      .getElementById("deletecount")
      .setAttribute("style", "visibility: hidden;");
    var checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );

    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  }

  render() {
    var username = JSON.parse(localStorage.uname);

    const { edus, PHD, PG } = this.state;

    var filename = `O_${username}_AdminUG`;
    var filename1 = `O_${username}_AdminPG`;
    var filename2 = `O_${username}_AdminPHD`;

    return (
      <div>
        <SideBar active={window.location.pathname} />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header"></div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title">Education</h4>
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block">
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                              id="myInput"
                              className=" btn"
                              style={{
                                borderColor: "white",
                                height: "42px",
                                padding: "inherit",
                                color: "white",
                                textAlign: "center",
                                width: "125px",
                              }}
                            >
                              UG
                            </button>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                              name="SkillName"
                              className="btn"
                              id="myInput1"
                              onChange={this.myFunction1}
                              style={{
                                borderColor: "white",
                                height: "42px",
                                padding: "inherit",
                                color: "white",
                                textAlign: "center",
                                width: "125px",
                              }}
                            >
                              PG
                            </button>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                              name="SkillName"
                              className="btn"
                              id="myInput2"
                              onChange={this.myFunction2}
                              style={{
                                borderColor: "white",
                                height: "42px",
                                padding: "inherit",
                                color: "white",
                                textAlign: "center",
                                width: "125px",
                              }}
                            >
                              PHD
                            </button>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {edus.length === 0 ? null : (
                              <ReactHTMLTableToExcel
                                className="btn"
                                table="UGSheet"
                                filename={filename}
                                sheet="Sheet"
                                buttonText="Export UG"
                                id="exportxlUG"
                                style={{ display: "none" }}
                              />
                            )}
                            {PG.length === 0 ? null : (
                              <ReactHTMLTableToExcel
                                className="btn"
                                table="PGSheet"
                                filename={filename1}
                                sheet="Sheet"
                                buttonText="Export PG"
                                id="exportxlPG"
                                style={{ display: "none" }}
                              />
                            )}
                            {PHD.length === 0 ? null : (
                              <ReactHTMLTableToExcel
                                className="btn"
                                table="PHDSheet"
                                filename={filename2}
                                sheet="Sheet"
                                buttonText="Export PHD"
                                id="exportxlPHD"
                                style={{ display: "none" }}
                              />
                            )}
                          </div>
                        </div>

                        <div
                          id="base-style_filter"
                          className="dataTables_filter"
                        >
                          <label
                            style={{
                              float: "right",
                              marginTop: " -4%",
                              width: "13%",
                              marginRight: "5%",
                            }}
                          >
                            {" "}
                            <input
                              type="search"
                              id="searchorg"
                              placeholder="Search..."
                              className="form-control input-sm"
                              aria-controls="base-style"
                            />
                          </label>{" "}
                        </div>
                        <br />
                        <div className="card-block2">
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <form onSubmit={this.Multidelete}>
                            <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: "hidden" }}
                            >
                              <i
                                className="icofont icofont-delete-alt"
                                style={{
                                  color: "darkgray",
                                  marginLeft: "-52px",
                                }}
                              ></i>
                            </button>
                            <span
                              id="deletecount"
                              className="multitrash"
                              style={{ visibility: "hidden", fontSize: "17px" }}
                            ></span>

                            {edus.length === 0 ? null : (
                              <table id="UGSheet" className="table nowrap">
                                <thead>
                                  <tr>
                                    <th>
                                      <input
                                        type="checkbox"
                                        id="selectall"
                                        onClick={() => {
                                          this.checkAll(this);
                                        }}
                                      />{" "}
                                    </th>
                                    <th>Under Graduate </th>
                                    <th>Actions </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {edus.map((edu) => (
                                    <tr>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="Id_delete"
                                          onChange={this.handlecheckbox}
                                          defaultValue={edu.id}
                                        />
                                      </td>
                                      <td>{edu.courseName}</td>
                                      <td>
                                        <div className="dropdown">
                                          <MoreVertIcon />
                                          <div className="dropdown-content">
                                            <a
                                              href
                                              onClick={() => this.Edit1(edu.id)}
                                            >
                                              Edit{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                            {PG.length === 0 ? null : (
                              <table
                                id="PGSheet"
                                className="table nowrap"
                                style={{ display: "none" }}
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <input
                                        type="checkbox"
                                        id="selectall"
                                        onClick={() => {
                                          this.checkAll(this);
                                        }}
                                      />{" "}
                                    </th>
                                    <th>Post Graduate</th>
                                    <th>Actions </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {PG.map((PG) => (
                                    <tr>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="Id_delete"
                                          onChange={this.handlecheckbox}
                                          defaultValue={PG.id}
                                        />
                                      </td>
                                      <td>{PG.courseName}</td>
                                      <td>
                                        <div className="dropdown">
                                          <MoreVertIcon />
                                          <div className="dropdown-content">
                                            <a
                                              href
                                              onClick={() => this.EditPG(PG.id)}
                                            >
                                              Edit{" "}
                                            </a>
                                            <span style={{ cursor: "cursor" }}>
                                              {" "}
                                              Delete{" "}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                            {PHD.length === 0 ? null : (
                              <table
                                id="PHDSheet"
                                className="table nowrap"
                                style={{ display: "none" }}
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <input
                                        type="checkbox"
                                        id="selectall"
                                        onClick={() => {
                                          this.checkAll(this);
                                        }}
                                      />{" "}
                                    </th>
                                    <th>PHD Courses</th>
                                    <th>Actions </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {PHD.map((PHD) => (
                                    <tr>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="Id_delete"
                                          onChange={this.handlecheckbox}
                                          defaultValue={PHD.id}
                                        />
                                      </td>
                                      <td>{PHD.courseName}</td>
                                      <td>
                                        <div className="dropdown">
                                          <MoreVertIcon />
                                          <div className="dropdown-content">
                                            <a
                                              href
                                              onClick={() =>
                                                this.EditPHD(PHD.id)
                                              }
                                            >
                                              Edit{" "}
                                            </a>
                                            <span style={{ cursor: "cursor" }}>
                                              {" "}
                                              Delete{" "}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                            <br />
                            <br />
                            <br />
                            <br />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
