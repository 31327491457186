//Live
export const BASE_URLs = "https://apitest.oneawesometeam.com/api";
export const Image_URLs = "https://apitest.oneawesometeam.com/";

// Local
// export const BASE_URLs = "https://localhost:44369/api/";
// export const Image_URLs = "https://localhost:44369/";

// Sub Domain
// export const BASE_URLs = "https://testingapi.oneawesometeam.com/api";
// export const Image_URLs = "https://testingapi.oneawesometeam.com/";