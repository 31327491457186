import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
import CloseIcon from "@material-ui/icons/Close";
import AddPop from "../../Screen/SuperAdmin/AddIntrestPop";
import Excel from "../../Assets/Files/IntrestExl.xlsx";
import { ToastContainer, toast } from "react-toastify";
var XLSX = require("xlsx");

export default class Intrested extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Intrest: [],
            AddIntrest: [],
            letters: [],
            Filterpop: false,
            FilterData: "",
            AddIntrestModel:false,
            showExcel: false,

        }
    }


    componentDidMount() {
        this.GetIntrest();
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
      }

    GetIntrest() {
      const tableName = "MasterInterests"; // Replace with the actual table name needed
      const url = `${BASE_URLs}/AdminProfile/getmasterdata?TableName=${tableName}`;
      let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
            Authorization: "Bearer " + accessToken,
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
                Intrest: data,
                AddIntrest: data,
            
            });
       
          });
      }

      onKeyUp = (e) => {
        const Intrest = this.state.AddIntrest.filter((item) =>
          item.interestName.toLowerCase().includes(e.target.value.toLowerCase())
        );
        this.setState({ Intrest });
      };
      AddIntrestModel = () => {
        this.setState((prevState) => ({
          AddIntrestModel: !prevState.AddIntrestModel,
        }));
        this.GetIntrest();
      };
      handleIntrestfilter = (letter, event) => {
        event.preventDefault();
        const Intrest = this.state.AddIntrest.filter((item) => {
          return item.interestName.toLowerCase().startsWith(letter.toLowerCase());
        });
        this.setState({ Intrest, showResults: true, FilterData: Intrest });
      };

      exportXlsx(filename) {
        // Acquire Data (reference to the HTML table)
        var table_elt = document.getElementById("sheet");
        // Extract Data (create a workbook object from the table)
        var workbook = XLSX.utils.table_to_book(table_elt);
        workbook.Sheets.Sheet1.A1.s = { font: { bold: true } };
        // Process Data (add a new row)
        var ws = workbook.Sheets["Sheet1"];
        //XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});
        // Package and Release Data (`writeFile` tries to write and save an XLSX file)
        XLSX.writeFile(workbook, "" + filename + ".xlsx");
      }

      handleUpload = (event) => {
        this.setState({ showExcel: true });
    
        // if (document.getElementById("selectall")) {
        //   document.getElementById("selectall").checked = false;
        // }
        // if (document.getElementsByName("Id_delete")) {
        //   var checkboxes = document.getElementsByName("Id_delete");
        //   for (var i = 0; i < checkboxes.length; i++) {
        //     checkboxes[i].checked = false;
        //     document.getElementById("delete").setAttribute("style", "visibility: hidden;");
        //     document.getElementById("deletecount").setAttribute("style", "visibility: hidden;");
        //   }
        // }
    
        var inputElement = document.getElementById("files");
        var files = inputElement.files;
        var filename = files[0].name;
        var extension = filename.substr(filename.lastIndexOf("."));
        var allowedExtensionsRegx = /(\.xlsx|\.xlsm)$/i;
        var isAllowed = allowedExtensionsRegx.test(extension);
    
        // Check if the file is allowed
        if (isAllowed) {
          // Read the file using FileReader
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
    
            // Check if the parsed data is empty
            if (parsedData.length === 0) {
              toast.error("The uploaded Excel file is empty.", { autoClose: 3200 });
              this.setState({ initialData: [], showExcel: false });
            } else {
              this.setState({ initialData: parsedData });
            }
          };
          reader.onerror = (error) => {
            // console.error("Error reading file:", error);
          };
          reader.readAsBinaryString(files[0]);
    
          return true; // Indicate successful file upload
        } else {
          alert("Invalid File Type.");
          return false; // Indicate failed file upload
        }
      };
      cancel = () => {
    
        var inputElement = document.getElementById("files");
        inputElement.value = "";
    
        this.setState({ showExcel: false });
    
      };
    
      handleSubmit = (event) => {
        event.preventDefault();
        try {
          const data = new FormData(event.target);
          fetch(`${BASE_URLs}/SuperAdmin/IntrestExcel`, {
            method: "POST",
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            },
            body: data,
          })
            .then((res) => res.json())
            .then((resp) => {
              if (resp === undefined) {
                alert("0  Records  inserted ");
              }
              if (resp === 0) {
                alert("Records added Successfully");
              } else {
                alert(+resp + " Records not inserted ");
              }
              this.GetIntrest();
              this.setState({ initialData: undefined });
            });
          var file = document.getElementById("files");
          var emptyFile = document.createElement("input");
          emptyFile.type = "file";
          file.files = emptyFile.files;
        } catch (error) { }
      };
    render(){
      var username = JSON.parse(localStorage.uname);
      var filename = `O_${username}_Intrestsheet`;
        const { Intrest,Filterpop, FilterData, showExcel} = this.state;
        return(
<>
<SideBar active={window.location.pathname} />

{this.state.AddIntrestModel ? (
          <AddPop id="Success" close={() => this.AddIntrestModel()} />
        ) : null}
<div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="card">
                    <div className="glowBox">
                      <div>
                        <div style={{ float: "left" }}>
                          <a href onClick={() => this.props.history.goBack()}>
                            <i
                              className="icofont icofont-arrow-left"
                              style={{ fontSize: "45px", color: "white" }}
                            />
                          </a>{" "}
                        </div>
                      </div>
                      <h4 className="title" style={{ marginRight: "50px" }}>
                        Intrest {" "}
                      </h4>
                     
                    </div>
                    <br />
                    <div className="add-rowEduform">
                    <form
                        autoComplete="on"
                        id="Eduform"
                        encType="multipart/form-data"
                       onSubmit={this.handleSubmit}
                      >
                        <div className="card-block">
                        <div className="row">
                            <div className="col btnalign">
                            <button
                              onClick={() => this.AddIntrestModel()}
                                // onKeyPress={this.handleKeyPress}
                                type="button"
                                style={{ width: "150px", background: "white" }}
                                id="addRow"
                                className="btn dropdown_item-2"
                              >
                                Add{" "}
                              </button>
                        </div>
                        <div className="col btnalign">
                              <label
                                htmlFor="files"
                                className="btn"
                                id="addBtn"
                                name="Intrest"
                              >
                                Import From Excel
                              </label>
                              <input
                                id="files"
                                type="file"
                                accept=".xlsx"
                                style={{ display: "none" }}
                                 onChange={this.handleUpload}
                                name="Intrest"
                              />
                            </div>
                            <div className="col btnalign">
                              <button
                                className="btn"
                                type="button"
                               onClick={() => this.exportXlsx(filename)}
                                // onKeyPress={this.handleKeyPress}
                              >
                                Export to excel
                              </button>
                            </div>

                            <div
                              className="col btnalign"
                            //   onKeyDown={this.handleKeyPress}
                            >
                              <a
                                className="btn"
                                 href={Excel}
                                type="button"
                                style={{ color: "#5035ce" }}
                              >
                                <i
                                  title="Download Template"
                                  className="icofont icofont-download"
                                ></i>{" "}
                                Download
                              </a>
                            </div>

                            <div className="col-lg-4">
                              <input
                                className="Searchbox"
                                placeholder="Search"
                                // onKeyUp={this.onKeyUp}
                                // onKeyPress={this.handleKeyPress}
                                style={{ float: "right" }}
                                onClick={() => {
                                  this.setState({
                                    // Filterpop: true,
                                    Filterpop: !this.state.Filterpop,
                                  });
                                }}
                                readOnly
                              />
                            </div>
                         </div> 
                         <center>
                          {showExcel && this.state.initialData !== undefined && (
  <table className="table" id="tableDesign" style={{marginTop:'30px'}}>
    <thead>
      <tr>
        {Object.keys(this.state.initialData[0]).map((key) => (
          <th key={key}>{key}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {this.state.initialData.map((row, index) => (
        <tr key={index}>
          {Object.values(row).map((value, index) => (
            <td key={index} id="pdesign">{value}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)}
</center>

                          {showExcel && this.state.initialData !== undefined && (
                            <div className="savebtndiv">
                              <button
                                className="btn"
                                id=""
                                type="submit"
                                style={{ width: "150px" }}
                              >
                                Save
                              </button>
                              <span
                                className="btn"
                                id="add-row"
                                style={{ width: "150px" }}
                                onClick={this.cancel}
                              >
                                Cancel
                              </span>
                            </div>
                          )}
                         </div>
                      </form>
                    </div>
                    <br />

                    <div>
                      <form
                        // onSubmit={this.Multidelete}
                        // onKeyPress={this.handleKeyPress}
                      >
                        <div className="card-block2">
                          <div
                            id="selectalldiv"
                            style={{ visibility: "hidden" }}
                          />
                          &nbsp;&nbsp;
                        </div>
                        <button
                          type="submit"
                          className="multitrash"
                          id="delete"
                          style={{ float: "right", visibility: "hidden" }}
                        >
                          <i
                            className="icofont icofont-delete-alt"
                            style={{ color: "darkgray" }}
                          ></i>
                        </button>
                        <span
                          id="deletecount"
                          className="multitrash"
                          style={{ visibility: "hidden", fontSize: "17px" }}
                        ></span>
                        <div className="dt-responsive table-responsive">
                          <table
                            id="sheet"
                            className="table nowrap"
                            style={{ display: "none" }}
                          >
                            <thead>
                              <tr>
                              <th> Category</th>
                                <th>Intrest Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Intrest.map((skill) => (
                                <tr>
                                <td>{skill.category}</td>
                                  <td>{skill.interestName}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-10 skillname">
                          
                            </div>
                            <div className="col-lg-1"></div>
                          </div>
                          <br />
                          <div className="row" style={{ padding: "15px" }}>
                            {Intrest.map((skill) => (
                              <div className="col-lg-3">
                                <p>{skill.interestName}</p>
                              </div>
                            ))}
                          </div>
                          <center>
                          {Filterpop === true && FilterData.length === 0 && Intrest.length === 0 ? (
                                       <div>No Record</div>
                                    ) : null}
                                    </center>

                          <br />
                        </div>
                      </form>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                    </div>
                    </div>
                    </div>

                    {Filterpop && (
                              <div className="popSearchTeam">
                                <div className="popSearchinnernormalTeam">
                                  <div
                                    className="popSearchinnertitle"
                                    id="popSearchinnernormal"
                                  >
                                    <button
                                      id="close"
                                      style={{
                                        float: "right",
                                        backgroundColor: "#a0a3a305",
                                        color: "#797070",
                                        border: "1px solid #a3a5a505",
                                        marginTop: "-20px",
                                        marginRight: "-20px",
                                      }}
                                      onClick={() => {
                                        this.setState({ Filterpop: false });
                                        this.GetIntrest();
                                      }}
                                    >
                                      <CloseIcon />
                                    </button>

                                    <div className="checkboxes">
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "20px",
                                          color: "gray",
                                          marginLeft: "-1%",
                                          position: "absolute",
                                          marginTop: "0.5%",
                                        }}
                                      ></i>
                                      <input
                                        className="Searchbox"
                                        placeholder="Search"
                                        onKeyUp={this.onKeyUp}
                                        onKeyPress={this.handleKeyPress}
                                        style={{ float: "right", width: "80%" }}
                                      />
                                      <br></br>
                                      {this.state.letters.map(
                                        (letter, index) => (
                                          <button
                                            className="filter"
                                            key={index}
                                            onClick={(event) =>
                                              this.handleIntrestfilter(
                                                letter,
                                                event
                                              )
                                            }
                                          >
                                            {letter}
                                          </button>
                                        )
                                      )}
                                      <br></br>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                    </div>
                    </div>
                    </div>

</>
        )
    }
}