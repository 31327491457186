import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base";
import ReactTimeAgo from "react-time-ago";
import CloseIcon from "@material-ui/icons/Close";
export default class UserNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Notify: [],
      AllNotify: [],
      Active: [],
      InActive: [],
      Pending: [],
      All: [],
      allNotify: [],
      User: [],
      renewel: [],
      Notification: [],
      Unreadcount: "0",
      ContactMessagesCount: "",
      NotifyCount: "",
      ContactMessages: [],
      NoRequest: [],
      Request: [],
      delay: false,
      letters: [],
      Filterpop: false,
    };
    this.Noticount = this.Noticount.bind(this);
  }

  GetallNotify() {
    fetch(`${BASE_URLs}/Notification/GetallNotify`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ allNotify: data });
      });
  }

  Noticount = (notify_Id, company_Id) => {
    fetch(`${BASE_URLs}/Notification/UserChangeSeen`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Seen: 1,
        notify_Id: notify_Id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => { },
        (error) => { }
      );
    this.props.history.push("/Profile");
    this.GetNotification();
  };
  componentDidMount() {
    this.GetNotification();
    this.GetallNotify();
    setTimeout(() => {
      this.setState({
        delay: true
      })
    }, 500);

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  GetNotification() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Notification/GetUserNotification`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Notify: data.filter((Tasks) => Tasks.type == "r") });
        this.setState({ AllNotify: data.filter((Tasks) => Tasks.type == "r") });
        this.setState({
          NotifyCount: data
            .filter((Tasks) => Tasks.type !== "c")
            .filter((Tasks) => Tasks.seen === false).length,
        });

        if (data.length !== 0) {
          this.setState({ Unreadcountuser: data[0].unreadcount });
        }

        // console.log('notifiiiiii', data)
      });

  }

  refresh = () => {
    window.location.reload();
  };

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  Contactcount = (notify_Id) => {
    fetch(`${BASE_URLs}/Notification/ChangeSeen`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Seen: 1,
        notify_Id: notify_Id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => { },
        (error) => { }
      );
    this.props.history.push("/Profile");
    // if(contact_Id!==null){
    //     var page={
    //  pathname: "/Contactmsg",
    //   state: {
    //       Id:contact_Id
    //      }
    //  }

    //  this.props.history.push(page);
    // }
    this.GetNotification();
  };
  truncateTitle(description, maxLength) {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  }

  filterfunction = (e) => {
    const Notify = this.state.AllNotify.filter(
      (item) =>
        item.description.toLowerCase().includes(e.target.value.toLowerCase())

    );
    this.setState({ Notify, showResults: true });
  }

  handleFilter = (letter, event) => {
    event.preventDefault();
    const Notify = this.state.AllNotify.filter((item) => {
      const categoryIsString = typeof item.description === 'string';
      const categoryStartsWithLetter = categoryIsString && item.description.toLowerCase().startsWith(letter.toLowerCase());
    
      return  categoryStartsWithLetter;
 
    });
    this.setState({ Notify, showResults: true });
  };

  render() {
    const {
      Notify,
      NoRequest,
      Request,
      ContactMessages,
      NotifyCount,
      ContactMessagesCount,
      delay,
      Filterpop
    } = this.state;
    return (
      <>
        <SideBar active={window.location.pathname} />
        {/* start */}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title">Notifications </h4>
                        </div>
                        {/* {this.state.Notify.length === 0 ? <span >&nbsp;&nbsp;No Notifications&nbsp;&nbsp;</span> :
                             
                             
                             <span >&nbsp;&nbsp;Notifications{NotifyCount===0?null:" ("+NotifyCount+")"}&nbsp;&nbsp;</span>
                              
                              
                              
                              
                              } */}

                        {/* <div className="card-block" >
               
                      {Notify.map(Notify=>
                      <div>
                         {(() => { 
                           if ( Notify.seen === false) {
                             
                             return(
                              <div>
                              <a href  type="button"    
                            onClick={()=>this.Noticount(Notify.notify_Id,Notify.company_Id)}
                              style={{color:"black",cursor:"pointer"} }>
                              <b style={{color:"red"}}>{Notify.description}</b> &nbsp;&nbsp;&nbsp;{Notify.time}  
                                </a>   <br/> <br/>
                           </div>  )
                          }

                          if(Notify.seen === true){
                            return(
                        <div>
                           <a href type="button"   
                         onClick={()=>this.detailData(Notify.company_Id)}
                           style={{color:"#807f7f",cursor:"pointer"} }>
                           <b>{Notify.description}</b> &nbsp;&nbsp;&nbsp;{Notify.time}  
                             </a>   <br/> <br/>
                        </div>
                            )
                          }
                        })()}


                        </div>)}
                      
                 </div> */}


                        <div className="card-block">
                          <div>
                            <input
                              type="text"
                              className="Searchbox usersearch"
                              //  id="CNSearch"
                              placeholder="Search"
                              onKeyPress={this.handleKeyPress}
                              style={{ float: 'right' }}
                              onClick={() => {
                                this.setState({
                                  Filterpop: true,
                                  Filterpop: !this.state.Filterpop,
                                });
                              }}
                              readOnly

                            /></div>
                          <div className="dt-responsive table-responsive"
                            id={Notify.length <= 6 ? "UserNoAnnLength" : "Above10"}>

                            {Notify.length === 0 && delay ?
                              <center>

                                <p>No Record</p>
                              </center>
                              : null}
                            {Notify.length !== 0 ? (<table
                              id="add-row-table3"
                              className="table nowrap"

                            >
                              <thead id='tablepadding'><tr>

                                <th style={{ color: 'rgb(115, 103, 240)' }}>Notification Details</th>

                              </tr>
                              </thead>

                              <tbody id='tablepadding'>

                                <>
                                  {Notify.map((Notify) => (
                                    <>

                                      <tr>


                                        <td>


                                          {Notify.seen === false ? (
                                            // Unreaded noti

                                            <>
                                              <Link
                                                id="btnEdu"
                                                onClick={() => {
                                                  this.Noticount(Notify.notify_Id);
                                                  this.refresh();
                                                }}
                                                style={{
                                                  padding: "0px",
                                                  color: "#000100",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {" "}
                                                Subject:&nbsp;<span>{this.truncateTitle(Notify.description, 100)} </span>{" "}
                                              </Link>{" "}
                                              &nbsp;&nbsp;&nbsp;
                                              <ReactTimeAgo
                                                date={Notify.created_Date}
                                                locale="en-US"
                                                style={{ color: "gray", fontSize: '12px' }}
                                              />

                                            </>
                                          ) : (
                                            // Readed noti
                                            <>
                                              <Link
                                                onClick={() => {
                                                  this.Contactcount(Notify.notify_Id);
                                                  this.refresh();
                                                }}
                                                style={{
                                                  padding: "0px",
                                                  color: "gray",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {" "}
                                                Subject:&nbsp;<span>{this.truncateTitle(Notify.description, 100)} </span>{" "}
                                              </Link> &nbsp;&nbsp;&nbsp;
                                              <ReactTimeAgo
                                                date={Notify.created_Date}
                                                locale="en-US"
                                                style={{ color: "gray", fontSize: '12px' }}
                                              />

                                            </>
                                          )}



                                        </td>


                                      </tr>
                                    </>
                                  ))}
                                </>
                              </tbody>
                            </table>) : null}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {Filterpop && (
                  <div className="popSearchTeam">
                    <div className="popSearchinnernormalTeam">
                      <div
                        className="popSearchinnertitle"
                        id="popSearchinnernormal"
                      >
                        <button
                          id="close"
                          style={{
                            float: "right",
                            backgroundColor: "#a0a3a305",
                            color: "#797070",
                            border: "1px solid #a3a5a505",
                            marginTop: "-20px",
                            marginRight: "-20px",
                          }}
                          onClick={() => {
                            this.setState({ Filterpop: false, selectedOption: '' });
                            this.GetNotification();
                            this.GetallNotify();
                          }}
                        >
                          <CloseIcon />
                        </button>

                        <div className="checkboxes">
                          <i
                            className="fa fa-search"
                            aria-hidden="true"
                            style={{
                              fontSize: "20px",
                              color: "gray",
                              marginLeft: "-1%",
                              position: "absolute",
                              marginTop: "0.5%",
                            }}
                          ></i>
                          <input
                            className="Searchbox"
                            placeholder="Search"
                            onChange={this.filterfunction}
                            style={{ float: "right", width: "80%" }}
                          />
                          <br></br>
                          {this.state.letters.map(
                            (letter, index) => (
                              <button
                                className="filter"
                                key={index}
                                onClick={(event) =>
                                  this.handleFilter(
                                    letter,
                                    event
                                  )
                                }
                              >
                                {letter}
                              </button>
                            )
                          )}
                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* End */}
      </>
    );
  }
}
