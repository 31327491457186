import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import ReactTimeAgo from "react-time-ago";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base";
import CloseIcon from "@material-ui/icons/Close";
import fileImg from "../../Assets/images/document.png";
export default class AllAnnouncement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcement: [],
      Profile_Pic: [],
      Notification: [],
      Announce: [],
      category: [],
      AddAnnounce: [],
      SaAnnouncement:[],
      AllAnnouncement:[],
      letters: [],
      FilterData: "",
      delay: false,
      Filterpop: false,
      clickedContents:
        JSON.parse(localStorage.getItem("clickedContents")) || {},
      length: "",
      isOpen:false,
      selectedOption: ''
    
    };
  }
  popupclose=()=>{
    this.setState({
      isOpen:false
    })

    // this.GetAnnouncement();
    this.setState({ Filterpop: false,
      // selectedOption: ''
    });
  }
  myFunction = (e) => {
    e.preventDefault();

    const Announcement = this.state.AddAnnounce.filter((item) =>
      item.category.toLowerCase().includes(e.target.value.toLowerCase())
    );

    this.setState({ Announcement });

    this.setState({ selectedOption: e.target.value });
  };

  GetUserAnnouncement(id) {
    this.setState({
      isOpen:true
    })
    // var Id = this.props.location.state.Id;
    let url = `${BASE_URLs}/Announcement/GetUserAnnouncementID?Id=` + id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          SaAnnouncement: data,
        });
        // console.log("user", data);
      });
  }


  GetAnnouncement() {
    let url = `${BASE_URLs}/Announcement/GetUserAnnouncement`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        let notification = data
          .map((d) => {
            if (d.email === localStorage.getItem("email").split(`"`)[1]) {
              return d;
            }
          })
          .filter(function (x) {
            return x !== undefined;
          });
        this.setState({
          AddAnnounce: notification,
          Sort: notification,
          Announcement: notification,
          AllAnnouncement:notification
        });
      });
  }

  GetUserProfile() {
    let url = `${BASE_URLs}/Profile/Userprofile`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Profile_Pic: data,
        });

        // //
      });
  }

  handleFilter = (letter, event) => {
    event.preventDefault();
    const Announcement = this.state.AllAnnouncement.filter((item) => {
      const titleStartsWithLetter = item.title.charAt(0).toLowerCase() === letter.toLowerCase();
      const categoryIsString = typeof item.category === 'string';
      const categoryStartsWithLetter = categoryIsString && item.category.toLowerCase().startsWith(letter.toLowerCase());
    
      return titleStartsWithLetter || categoryStartsWithLetter;
  });
    this.setState({ Announcement, showResults: true, FilterData: Announcement });
  };
  componentDidMount() {
    this.GetAnnouncement();
    this.GetUserProfile();
    setTimeout(() => {
      this.setState({
        delay: true
      })
    }, 500);
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
    //  var role =JSON.parse(localStorage.Role);
  }
  Details(Id) {
    fetch(`${BASE_URLs}/Notification/UserAnnounceChangeSeen`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Seen: 1,
        Id: Id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {},
        (error) => {}
      );

    var page = {
      pathname: "/UserAnnouncementpopup",
      state: {
        Id: Id,
      },
    };
    this.props.history.push(page);
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  handleContentClick(id) {
    const clickedContents = { ...this.state.clickedContents };
    clickedContents[id] = true;
    localStorage.setItem("clickedContents", JSON.stringify(clickedContents));
    this.setState({ clickedContents });
  }
  bothonclick(id) {
    this.handleContentClick(id);
    // this.Details(id);
    this.GetUserAnnouncement(id)
  }

  
  truncateTitle(title, maxLength) {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  }

  filterfunction = (e) => {
    const Announcement = this.state.AllAnnouncement.filter(
      (item) =>
        item.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.category.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Announcement, showResults: true  });
  }

 SeenUpdate = (id) => {
  
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      // body: data
    };
    fetch(
      `${BASE_URLs}/Announcement/AnnounceUserSeen?id=` + id,
      options
    )
      .then((res) => res.json())
      .then((resp) => {

        const options = {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        };
        fetch(`${BASE_URLs}/Notification/GetUserAnnoucementNoti`, options)
          .then((response) => response.json())
          .then((data) => {
          });
      });
      

};

getFileType(path) {
  const extension = path.split('.').pop().toLowerCase();
  if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
    return 'image';
  } else if (['mp4', 'avi', 'mov', 'wmv', 'mkv'].includes(extension)) {
    return 'video';
  } else if (['pdf', 'xls', 'xlsx'].includes(extension)) {
    return 'document';
} else {
    return 'file';
}
}

handleImageClick = (url) => {
  window.open(url, '_blank');
   };
  render() {
    const { Announcement,isOpen,SaAnnouncement,Filterpop,delay } = this.state;
    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>

                <div className="page-body">
                  {/* <select name="Category" onChange={this.myFunction} id="myInput" className="btn" style={{ borderColor: "white", textAlign: "left", width: "150px", height: "42px", padding: "inherit"}}>

                    <option className="drop-options" value="">Category </option>
                    <option className="drop-options" value="Software Updates">Software Updates</option>
                    <option className="drop-options" value="HR">HR </option>
                    <option className="drop-options" value="Company">Company</option>
                    <option className="drop-options" value="Foundations">Foundations</option>
                    <option className="drop-options" value="Events">Events</option>

                  </select>  &nbsp;&nbsp; */}

                  <div className="col-sm-12">
                    <div className="card" >
                      <div className="glowBox">
                      <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                        <h4 className="title" style={{marginRight:'34px'}}>Announcements </h4>
                      </div>
                          <div className="card-block">
                          <div>
                      <select
                        name="Category"
                        onChange={this.myFunction}
                        id="myInput"
                        className="btn"
                        style={{
                          borderColor: "white",
                          textAlign: "left",
                          width: "180px",
                          height: "42px",
                          padding: "inherit",
                          // marginLeft: "20px",
                       color: 'rgb(115, 103, 240)'
                        }}
                        value={this.state.selectedOption}
                      >
                        <option className="" value="">
                           All Category{" "}
                        </option>
                        <option
                          className=""
                          value="General"
                        >
                         General
                        </option>
                        <option
                          className=""
                          value="Software Updates"
                        >
                          Software Updates
                        </option>
                        <option className="" value="HR">
                          HR{" "}
                        </option>
                        <option className="" value="Company">
                          Company
                        </option>
                        <option className="" value="Foundations">
                          Foundations
                        </option>
                        <option className="" value="Events">
                          Events
                        </option>
                      </select>
 
 <input
   type="text"
   className="Searchbox usersearch"
  //  id="CNSearch"
   placeholder="Search"
   onKeyPress={this.handleKeyPress}
   style={{float:'right'}}
   onClick={() => {
                            this.setState({
                            Filterpop: true,
                            Filterpop: !this.state.Filterpop,
                            });
                            }}
                            readOnly

 />
</div>
                          {/* {alldata.length === 0 && delay ? <center>
                            <p>No Announcements</p>
                          </center> : null} */}
                          <div className="dt-responsive table-responsive"
                           id={Announcement.length <= 6? "UserNoAnnLength" : "Above10"}
                           style={Filterpop?{  marginTop:'30px'}:{
                            // marginTop:'10px'
                           }}
                          >
                           {Announcement.length === 0 && delay? 
                                <center>
                             
                            <p>No Record</p>
                          </center> 
                                          : null}
                                          {Announcement.length !== 0 ? (<table
                              id="add-row-table3"
                              className="table nowrap"
                            
                            >
                              <thead id='tablepadding'><tr>
                            
                                <th style={{color: 'rgb(115, 103, 240)'}}>Announcement Details</th>
                                <th>Category </th>
                                </tr>
                              </thead>

                              <tbody id='tablepadding'>

                                  <>
                                    {Announcement.map((Announcement) => (
                                      <>

                                        <tr>
                                        

                                          <td>
                                          <Link
                                              onClick={() =>{
                                                this.bothonclick(
                                                  Announcement.id )
                                                 this.SeenUpdate(Announcement.id)
                                              }}
                                              style={{
                                                color: this.state
                                                  .clickedContents[
                                                  Announcement.id
                                                ]
                                                  ? "gray"
                                                  : "black",
                                                cursor: "pointer",
                                              }}
                                              id='SAannLists'
                                            >
                                                Subject:&nbsp;<span>{this.truncateTitle(Announcement.title, 85)}</span>
                                             &nbsp;<span style={{fontSize: "12px"}}>See More</span>
                                            
                                              &nbsp;&nbsp;&nbsp; 
                                              <ReactTimeAgo
                                                date={Announcement.created_Date}
                                                locale="en-US"
                                                style={{ color: "gray",fontSize:'12px' }}
                                              />
                                            </Link>

                                          </td>
                                          <td>{Announcement.category}</td>
                                   
                                        </tr>
                                      </>
                                    ))}
                                  </>
                              </tbody>
                            </table>) : null}
                          </div> </div>

                    </div>
                  </div>
                  {isOpen ?
                      <div className="col-lg-12">
                        <div className="popup" style={{ textAlign: 'left' }}>
                          <div className="popup-content">
                            <div className="Announcedetailspop" >
                              <i
                                className="fa fa-times"
                                aria-hidden="true"
                                style={{
                                  color: "gray",
                                  marginLeft: "90%",
                                  fontSize: "17px",
                                  cursor: 'pointer'
                                }}
                                onClick={this.popupclose}
                              ></i>
                              <br />
                              <center><h6><b>Announcement Details</b></h6></center>
                              <div className="card-block">
                                <div>
                                  {SaAnnouncement &&
                                    SaAnnouncement.map &&
                                    SaAnnouncement.map((Announcement) => (
                                      <>
                                        {Announcement.image === "-" &&
                                          Announcement.video === "-" ? (
                                          <div >
                                            <div
                                            >
                                              <span
                                                key={Announcement.id}
                                                style={{ margin: "0%" }}
                                              >

                                                <b>Subject:</b> &nbsp; <span
                                                  style={{ overflowWrap: "break-word", width: "50%", }}
                                                > {Announcement.title}</span><br /><br />
                                                <b>Description:</b> &nbsp; <span
                                                  style={{ overflowWrap: "break-word", width: "50%", }}> {Announcement.message}</span>

                                              </span>
                                            </div>
                                          </div>
                                        ) : (

                                          <>
                                          <div style={{ width:"97%",height: "237px", overflow: "scroll"}}>
                                            {Announcement.image.split(',').map((imageUrl, index) => (
                                              <div style={{ display: "inline-block", marign: "10px" }} key={index}>
                                                {index === 0 && (
    <>
    <span style={{ margin: "38px" }}>
      <b>Subject:</b> &nbsp;
      <span style={{ overflowWrap: "break-word", width: "50%" }}>
        {Announcement.title}
      </span>
    </span>
    <br /><br />
    <span style={{ margin: "38px" }}>
      <b>Description:</b> &nbsp;
      <span style={{ overflowWrap: "break-word", width: "50%" }}>
        {Announcement.message}
      </span>
    </span>
    <br /><br />
    </>
)}
                                                <div style={{margin:"10px"}}>
                                                {this.getFileType(imageUrl) === 'image' ? (

                                                  <span style={{ margin:"30px" }}>
                                                  <b>Image:&nbsp;</b>

{imageUrl.split(',').map((Url, index) => (
<img
key={index}
alt={`Image ${index}`}
src={imageUrl.trim()} 
style={{ width: "100px", height: "100px" }}
onClick={() => this.handleImageClick(Url.trim())}
/>
))}
                                                  </span>
                                                ) : this.getFileType(imageUrl) === 'video' ? (
                                                  <span style={{ display: "flex", margin:"30px" }}>
                                                    <span><b>video:</b></span>
                                                    <video
                                                      controls
                                                      style={{ width: "125px", height: '118px', marginTop: '-61px' }}
                                                    >
                                                      <source src={imageUrl} type="video/mp4" />
                                                      <source src={imageUrl} type="video/ogg" />
                                                    </video>
                                                  </span>
                                                ) : (
                                                  <span style={{margin:"30px"}}>
                                                    <b>File:</b> 
                                                    <img
                                                      src={fileImg}
                                                      style={{ height: "30px", width: "30px" }}
                                                    />
                                                    &nbsp;
                                                    <a
                                                      href={imageUrl}
                                                      name="picture"
                                                      readOnly
                                                      value={imageUrl}
                                                      title=""
                                                      target="_blank"
                                                    >
                                                      <span>
                                                        {imageUrl.slice("https://localhost:44369/Images/Announcement_Image_".length)}
                                                      </span>
                                                    </a>
                                                  </span>

                                                )}
                                               </div>
                                              </div>
                                            ))}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ))}
                                </div>
                              </div>
                            </div> </div> </div></div> : null}
                </div>
              </div>

              {Filterpop && (
                              <div className="popSearchTeam">
                                <div className="popSearchinnernormalTeam">
                                  <div
                                    className="popSearchinnertitle"
                                    id="popSearchinnernormal"
                                  >
                                    <button
                                      id="close"
                                      style={{
                                        float: "right",
                                        backgroundColor: "#a0a3a305",
                                        color: "#797070",
                                        border: "1px solid #a3a5a505",
                                        marginTop: "-20px",
                                        marginRight: "-20px",
                                      }}
                                      onClick={() => {
                                        this.setState({ Filterpop: false,selectedOption: ''});
                                        this.GetAnnouncement();
                                   
                                      }}
                                    >
                                      <CloseIcon />
                                    </button>

                                    <div className="checkboxes">
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "20px",
                                          color: "gray",
                                          marginLeft: "-1%",
                                          position: "absolute",
                                          marginTop: "0.5%",
                                        }}
                                      ></i>
                                      <input
                                        className="Searchbox"
                                        placeholder="Search"
                                        onChange={this.filterfunction}
                                        style={{ float: "right", width: "80%" }}
                                      />
                                      <br></br>
                                      {this.state.letters.map(
                                        (letter, index) => (
                                          <button
                                            className="filter"
                                            key={index}
                                            onClick={(event) =>
                                              this.handleFilter(
                                                letter,
                                                event
                                              )
                                            }
                                          >
                                            {letter}
                                          </button>
                                        )
                                      )}
                                      <br></br>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
