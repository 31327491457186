import { React, useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import PlusOne from "@material-ui/icons/Add";
import { add } from "date-fns";
import { BASE_URLs } from "../Config/Base";
const TrainingEditTraineesPop = ({ closeThePopup, array, state, third }) => {
  const [Trainers, setTrainers] = useState(array);
  const [Train, setTrain] = useState([]);
  const [Selectall, setSelectall] = useState(false);

  // console.log("fYYYYYYYYYYYYYYYYYY", array);

  const add = (e) => {
    e.preventDefault();
    state(Trainers.filter((e) => e.check));

    third();
  };

  const handleChange = (arr, i, event) => {
    let change = Trainers.map((e, index) => {
      if (i === index) return { ...e, ...{ check: event.target.checked } };
      return { ...e };
    });
    state(change);
    change.length === change.filter((e) => e.check === true).length
      ? setSelectall(true)
      : setSelectall(false);
    // console.log("change=====", change);

    setTrainers(change);
  };

  const handleSelectAll = (event) => {
    setSelectall(!Selectall);
    let change = Trainers.map((e, index) => {
      return { ...e, ...{ check: event.target.checked } };
    });
    change.length === change.filter((e) => e.check === true).length
      ? setSelectall(true)
      : setSelectall(false);
    setTrainers(change);
  };
  return (
    <div className="pop">
      <div className="popinnernormal">
        <form autoComplete="off" encType="multipart/form-data">
          {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}} onClick={this.close}  ><CloseIcon/></button> */}
          <button
            style={{
              float: "right",
              backgroundColor: "#a0a3a305",
              color: "#797070",
              border: "1px solid #a3a5a505",
            }}
            onClick={third}
          >
            <CloseIcon />
          </button>

          <div className="row" tyle={{ padding: "8px" }}>
            <div className="col-sm-3"></div>
            <div className="col-sm-8">
              <div className="dt-responsive table-responsive table-res">
                {Trainers.length === 0 ? (
                  "There are no ' Trainees '"
                ) : (
                  <table
                    id="DepSheet"
                    className="table nowrap"
                    style={{ maxWidth: "90%" }}
                  >
                    <thead>
                      <tr>
                        <th colSpan="2" style={{ textAlign: "left" }}>
                          <button
                            type="button"
                            style={{
                              marginRight: "-30px",
                              color: "rgb(94, 217, 135)",
                            }}
                            onClick={(e) => add(e)}
                          >
                            Add
                          </button>
                        </th>
                      </tr>

                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={Selectall}
                            onChange={(e) => handleSelectAll(e)}
                          />
                        </th>
                        <th>Trainees</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {Trainers.map((Trainers, i) => (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              value={
                                Trainers.department + "$$$" + Trainers.User_Id
                              }
                              checked={Trainers.check}
                              onChange={(e) => handleChange(Trainers, i, e)}
                            />
                          </td>
                          <td>
                            {Trainers[0] +
                              "" +
                              "" +
                              "(" +
                              Trainers[1].length +
                              ")"}
                          </td>
                          {/* <td>
                          <button
                            className="button-30"
                            type="button"
                            style={{ color: "rgb(94, 217, 135)" }}
                            onClick={() =>
                              this.fetchData(
                                Trainers.trainerName,
                                Trainers.trainer_UserId
                              )
                            }
                          >
                            Assign&nbsp;
                            <i className="icofont icofont-edit"></i>
                          </button>
                        </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="col-sm-4"></div>
          </div>

          <div className="row">
            <div className="col-sm-12" style={{ textAlign: "center" }}></div>
          </div>
        </form>
        <br />
        {/* <button onClick={this.closePopup}><CloseIcon/></button> */}
      </div>
    </div>
  );
};
export default TrainingEditTraineesPop;