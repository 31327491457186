import React, { Component } from "react";
import Piechart from "./../Piechart";
import Pie from "../../Common/Pie";
import SideBar from "../../Common/Sidebar";
import image2 from "../../../images/image (2).png";
import { BASE_URLs } from "../../Config/Base";
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      Pending: [],
      comlogo: [],
      Notification: [],
    };
  }
  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  componentDidMount() {
    this.GetOrgLogo();
    this.Getpending();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}/AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}/AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}/AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }
  Getpending() {
    fetch(`${BASE_URLs}/AddOrganization/pendingcount`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Pending: data });
      });
  }

  render() {
    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                {/*  Start */}
                <div className="page-body">
                <img src={image2} alt="Description of image" />
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
