import { useState } from "react";
import { BASE_URLs } from "../Config/Base";
export default function Online() {
  const [tabHasFocus, setTabHasFocus] = useState(true);

  // useEffect(() => {
  //   const handleFocus = () => {

  //     fetch(`${BASE_URLs}/Team/Online", {
  //       method: 'POST',
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //         "Content-type": "application/json",
  //         "Accept": "application/json",
  //         "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
  //       },

  //     }).then(response => response.json())
  //       .then(data => {

  //       });

  //      setTabHasFocus(true);

  //   };

  //   const handleBlur = () => {

  //        fetch(`${BASE_URLs}/Team/Offline", {
  //       method: 'POST',
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //         "Content-type": "application/json",
  //         "Accept": "application/json",
  //         "Access-Control-Allow-Headers": "Access-Control-Request-Headers"
  //       },

  //     }).then(response => response.json())
  //       .then(data => {

  //       });

  //   setTabHasFocus(false);

  //   };

  //   window.addEventListener('focus', handleFocus);
  //   window.addEventListener('blur', handleBlur);

  //   return () => {
  //     window.removeEventListener('focus', handleFocus);
  //     window.removeEventListener('blur', handleBlur);
  //   };
  // }, []);

  function AutoCaps(e) {
    //   let value =  e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)

    //   for (var i = 0; i < arr.length; i++) {
    //     arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    // }
    var aaa = "mam am am b amam a".split(" ");
    var array = e.target.value.split(" ");

    for (var i = 0; i < array.length; i++) {
      array[i] = array[i].charAt(0).toUpperCase() + array[i].substring(1);
    }
    var finalString = array.join(" ");

    e.target.value = finalString;
  }

  return (
    <div>
      {tabHasFocus ? (
        <h2>Tab has focus ✅</h2>
      ) : (
        <h2>Tab does not have focus ⛔️</h2>
      )}

      <input type="text" onChange={AutoCaps} />
      <input type="text" onChange={AutoCaps} />
      <input type="text" onChange={AutoCaps} />
      <input type="text" onChange={AutoCaps} />
      <input type="text" onChange={AutoCaps} />
    </div>
  );
}
