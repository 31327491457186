import React, { Component } from "react";
import OATLogo from "../Assets/images/Awessome.webp";
import logo from "../Assets/images/Awessome.webp";
import { BASE_URLs } from "../Config/Base";
export default class InviteDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      comlogo: [],
      Inviteusers: [],
    };
  }
  componentDidMount() {
    this.GetOrgLogo();
    this.GetInviteUsers();
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }

  GetInviteUsers() {
    let url = `${BASE_URLs}/Profile/GetInviteUsers`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Inviteusers: data,
        });
      });
  }
  render() {
    const { Inviteusers } = this.state;
    return (
      <div>
        <h6
          style={{
            textAlign: "center",
            backgroundColor: "cornflowerblue",
            marginTop: "-2%",
          }}
        >
          <img
            alt="logo"
            src={OATLogo}
            style={{ width: "150px", height: "100px" }}
          />
        </h6>
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <img alt="logo" src={logo} style={{ height: "250px" }} />
          <br />
          <h3>Welcome To One Awesome Team</h3>
        </div>
        <br />
        <br />
        <br />
        <br />
        {Inviteusers.map((Inviteusers) => (
          <div>
            <h5 style={{ textAlign: "center" }}>
              {Inviteusers.message}
              {/* (User Name) You have been <br/><br/> Invited by (Admin Name) to join the*/}{" "}
            </h5>
          </div>
        ))}
        <br />
        <br />
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <button
            className="butn "
            style={{
              height: "43.2px",
              backgroundColor: "#ff5d11",
              color: "white",
            }}
          >
            Get Started
          </button>
        </div>
      </div>
    );
  }
}
