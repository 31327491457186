import React, { Component } from "react";
import SideBar from "../Common/Sidebar";
import "../Css/AddOrganization.css";
import CloseIcon from "@material-ui/icons/Close";
import { BASE_URLs } from "../Config/Base";
export default class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status0: [],
      status0Filt: [],
      status1: [],
      status1Filt: [],
      status2: [],
      status4: [],
      status2Filt: [],
      User: [],
      renewel: [],
      comlogo: [],
      Notification: [],
      allNotify: [],
      Filterpop: false,
      letters: [],
      statuscount1: [],
      statuscount2: [],
      statuscount3: [],
      statuscount4: [],
      FilterData: "",
    };
  }

  GetStatusTwo() {
    let url = `${BASE_URLs}/AddOrganization/InactiveOrg`;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data1) => {
        this.setState({
          status0: data1,
          status0Filt: data1,
          statuscount1: data1,
        });
      });
  }
  GetStatusOne() {
    let url = `${BASE_URLs}/AddOrganization/status1`;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data1) => {
        this.setState({
          status1: data1,
          status1Filt: data1,
          statuscount2: data1,
        });
      });
  }

  GetPending() {
    let url = `${BASE_URLs}/AddOrganization/GetPending`;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          status2: data,
          status2Filt: data,
          statuscount3: data,
        });
      });
  }
  GetRejectPending() {
    let url = `${BASE_URLs}/AddOrganization/GetRejectPending`;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          status4: data,
          status4Filt: data,
          statuscount4: data,
        });
      });
  }

  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/GetOrg`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ User: data });
      });
  }
  renewel() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/Renewel`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ renewel: data });
      });
  }
  detailData(company_Id) {
    var page = {
      pathname: "/OrganizationDetail",
      state: {
        Id: company_Id,
      },
    };

    this.props.history.push(page);
  }
  detailedit(company_Id) {
    var page = {
      pathname: "/EditOrganization",
      state: {
        Id: company_Id,
      },
    };

    this.props.history.push(page);
  }

  detaileditres(company_Id, reason) {
    var page = {
      pathname: "/EditOrganization",
      state: {
        Id: company_Id,
        Reasons: reason,
      },
    };

    this.props.history.push(page);
  }

  detailspending(company_Id) {
    var page = {
      pathname: "/OrganizationDetail",
      state: {
        Id: company_Id,
      },
    };

    this.props.history.push(page);
  }

  detailData1(company_Id) {
    var page = {
      pathname: "/OrganizationDetail",
      state: {
        Id: company_Id,
      },
    };

    this.props.history.push(page);
  }

  RejReasons(company_Id, reason) {
    // localStorage.setItem("status","Active")
    var page = {
      pathname: "/OrganizationDetail",
      state: {
        Id: company_Id,
        Reasons: reason,
      },
    };

    this.props.history.push(page);
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }

  componentDidMount() {
    this.GetStatusOne();
    this.GetStatusTwo();
    this.UserOrg();
    this.GetPending();
    this.GetRejectPending();
    this.renewel();
    this.GetOrgLogo();

    var role = JSON.parse(localStorage.Role);
    role = role.toString();
    if (role.toString() === "Platform Admin") {
      document
        .getElementById("add-row-tab")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("Userorg")
        .setAttribute("style", "  display:none;");
    } else {
      document
        .getElementById("searchlabel")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("add-row-tab1")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("SuperAdHide")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("supertab")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("activetab")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("inactivetab")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("pendingtab")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("tabs-section-2")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("tabs-section-3")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("tabs-section-4")
        .setAttribute("style", "  display:none;");
      document.getElementById("tab2").setAttribute("style", "  display:none;");
      document.getElementById("tab3").setAttribute("style", "  display:none;");
      document.getElementById("tab4").setAttribute("style", "  display:none;");
    }

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  onKeyUp = (e) => {
    const status0 = this.state.status0Filt.filter(
      (item) =>
        item.company_Name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.mobile.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        // item.adrs1.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.city.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.personname.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ status0 });

    const status1 = this.state.status1Filt.filter(
      (item) =>
        item.company_Name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.mobile.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        // item.adrs1.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.city.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.personname.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ status1 });

    const status2 = this.state.status2Filt.filter(
      (item) =>
        item.company_Name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.mobile.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        // item.adrs1.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.city.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.personname.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ status2 });
    const status4 = this.state.status4Filt.filter(
      (item) =>
        item.company_Name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.mobile.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        // item.adrs1.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.city.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.personname.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ status4 });
  };

  handleFilter = (letter, e) => {
    e.preventDefault();
    const status0 = this.state.status0Filt.filter(
      (item) =>
        item.company_Name.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.mobile.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.email.charAt(0).toLowerCase() === letter.toLowerCase() ||
        // item.adrs1.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.city.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.personname.charAt(0).toLowerCase() === letter.toLowerCase()
    );
    this.setState({ status0, FilterData: status0 });

    const status1 = this.state.status1Filt.filter(
      (item) =>
        item.company_Name.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.mobile.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.email.charAt(0).toLowerCase() === letter.toLowerCase() ||
        // item.adrs1.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.city.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.personname.charAt(0).toLowerCase() === letter.toLowerCase()
    );
    this.setState({ status1, FilterData: status1 });

    const status2 = this.state.status2Filt.filter(
      (item) =>
        item.company_Name.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.mobile.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.email.charAt(0).toLowerCase() === letter.toLowerCase() ||
        // item.adrs1.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.city.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.personname.charAt(0).toLowerCase() === letter.toLowerCase()
    );
    this.setState({ status2, FilterData: status2 });
    const status4 = this.state.status4Filt.filter(
      (item) =>
        item.company_Name.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.mobile.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.email.charAt(0).toLowerCase() === letter.toLowerCase() ||
        // item.adrs1.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.city.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.personname.charAt(0).toLowerCase() === letter.toLowerCase()
    );
    this.setState({ status4, FilterData: status4 });
  };

  render() {
    var role = JSON.parse(localStorage.Role);
    role = role.toString();
    const { status0 } = this.state;
    const { status1 } = this.state;
    const { status2 } = this.state;
    const { status4 } = this.state;
    const { statuscount1 } = this.state;
    const { statuscount2 } = this.state;
    const { statuscount3 } = this.state;
    const { statuscount4 } = this.state;
    const { User } = this.state;
    const { renewel, Filterpop, FilterData } = this.state;
    return (
      <>
        <SideBar active={window.location.pathname} />
        {/* start */}
        <div className="pcoded-content ">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                        <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          <h4 className="title">
                            {role === "Platform Admin"
                              ? "Entities"
                              : "Entity Profile"}{" "}
                          </h4>
                        </div>
                        <div className="card-block">
                          <div className="col-xs-12" style={{ float: "right" }}>
                            <div
                              id="base-style_filter"
                              className="dataTables_filter"
                            >
                              <label id="searchlabel">
                                <input
                                  type="search"
                                  id="searchorg"
                                  onKeyUp={this.onKeyUp}
                                  className="Searchbox"
                                  placeholder="Search"
                                  onClick={() => {
                                    this.setState({
                                      Filterpop: !this.state.Filterpop,
                                    });
                                  }}
                                  readOnly
                                />
                              </label>
                            </div>
                          </div>{" "}
                          <div className="j-tabs-container">
                            <input id="tab4" type="radio" name="tabs" />
                            <label
                              id="pendingtab"
                              className="j-tabs-label"
                              htmlFor="tab4"
                              title="Organizations-Pending"
                            >
                              <div>
                                {statuscount3.length === 0 &&
                                statuscount4.length === 0 ? (
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp; Pending
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                ) : (
                                  <span className="blink">
                                    &nbsp;&nbsp;&nbsp;&nbsp; Pending{" "}
                                    <span>
                                      (
                                      {statuscount3.length +
                                        statuscount4.length}
                                      )
                                    </span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                )}
                              </div>
                            </label>

                            <input
                              id="tab1"
                              type="radio"
                              name="tabs"
                              defaultChecked
                            />
                            <label
                              id="supertab"
                              className="j-tabs-label"
                              htmlFor="tab1"
                              title="All"
                            >
                              <div>
                                {parseInt(statuscount2.length) +
                                  parseInt(statuscount1.length) +
                                  parseInt(statuscount3.length) ===
                                0 ? (
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    All&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                ) : (
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All
                                    <span>
                                      (
                                      {parseInt(statuscount2.length) +
                                        parseInt(statuscount1.length) +
                                        parseInt(statuscount3.length) +
                                        parseInt(statuscount4.length)}
                                      )
                                    </span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                )}
                              </div>
                            </label>

                            <input id="tab2" type="radio" name="tabs" />
                            <label
                              id="activetab"
                              className="j-tabs-label"
                              htmlFor="tab2"
                              title="Organizations-Active"
                            >
                              <div>
                                {statuscount2.length === 0 ? (
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Active&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                ) : (
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Active
                                    <span>({statuscount2.length})</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                )}
                              </div>
                            </label>

                            <input id="tab3" type="radio" name="tabs" />
                            <label
                              id="inactivetab"
                              className="j-tabs-label"
                              htmlFor="tab3"
                              title="Organizations-Inactive"
                            >
                              <div>
                                {statuscount1.length === 0 ? (
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    Inactive&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                ) : (
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp; Inactive{" "}
                                    <span>({statuscount1.length}) </span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                )}
                              </div>
                            </label>

                            {/* TAB 1 */}

                            <div id="tabs-section-1" className="j-tabs-section">
                              <div className="dt-responsive table-responsive">
                                <table
                                  id="add-row-tab1"
                                  className="table nowrap"
                                  style={{ marginTop: Filterpop ? '50px' : '0px' }}
                                >
                                  <thead>
                                    <tr style={{ textAlign: "center" }}>
                                      <th>Entity Name</th>
                                      <th>Primary Mobile Number</th>
                                      <th>Primary Email</th>
                                      <th>Location</th>
                                      <th>Contact </th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {status2.length === 0 &&
                                    status4.length === 0 &&
                                    status0.length === 0 &&
                                    status1.length === 0 &&
                                    FilterData.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                    {status2.map((status2) => (
                                      <tr>
                                        <td>{status2.company_Name}</td>
                                        <td>{status2.mobile}</td>
                                        <td>{status2.email}</td>
                                        <td>{status2.city}</td>
                                        <td>{status2.personname}</td>
                                        <td
                                          style={{ color: "rgb(142 150 12)" }}
                                        >
                                          <b>Pending</b>
                                        </td>
                                        <td>
                                          {/* <img alt="info" src="https://img.icons8.com/ios-filled/24/000000/info.png"
                                           onClick={() => this.detailspending(status2.company_Id)} />      &nbsp;&nbsp;&nbsp; */}
                                          <button
                                            className="button-30 sk-del"
                                            name="Details"
                                            // style={{ color: "#538cbe" }}
                                            style={{
                                              color: "rgb(94, 217, 135)",
                                            }}
                                            alt="info"
                                            onClick={() =>
                                              this.detailspending(
                                                status2.company_Id
                                              )
                                            }
                                            title="Details"
                                          >
                                            {/* Details&nbsp; */}
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>{" "}
                                          &nbsp;&nbsp;&nbsp;
                                        </td>
                                      </tr>
                                    ))}

                                    {status4.map((status4) => (
                                      <tr>
                                        <td>{status4.company_Name}</td>
                                        <td>{status4.mobile}</td>
                                        <td>{status4.email}</td>
                                        <td>{status4.city}</td>
                                        <td>{status4.personname}</td>
                                        <td
                                          style={{ color: "red" }}
                                        >
                                          <b>Rejected</b>
                                        </td>
                                        <td>
                                          {/* <img alt="info" src="https://img.icons8.com/ios-filled/24/000000/info.png" 
                                          onClick={() => this.detailData1(status4.company_Id)} />      &nbsp;&nbsp;&nbsp; */}
                                          <button
                                            className="button-30 sk-del"
                                            name="Details"
                                            alt="info"
                                            style={{ color: "rgb(94 217 135)" }}
                                            onClick={() =>
                                              this.detailData1(
                                                status4.company_Id
                                              )
                                            }
                                            title="Details"
                                          >
                                            {/* Details&nbsp; */}
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}

                                    {status1.map((status1) => (
                                      <tr id="sabut">
                                        <td>{status1.company_Name}</td>
                                        <td>{status1.mobile}</td>
                                        <td>{status1.email}</td>
                                        <td>{status1.city}</td>
                                        <td>{status1.personname}</td>

                                        <td
                                          id="super"
                                          style={{ color: "rgb(36 92 38)" }}
                                        >
                                          <b>Active</b>
                                          {/*                             <button value={status1.company_Id} className=" btn-primary" onClick={this.PutStatusTozero}> Active</button> &nbsp;&nbsp;&nbsp;
                                           */}
                                        </td>
                                        <td>
                                          {/* <img alt="info" src="https://img.icons8.com/ios-filled/24/000000/info.png" 
                                          onClick={() => this.detailData(status1.company_Id)} />     &nbsp;&nbsp;&nbsp; */}

                                          <button
                                            className="button-30 sk-del"
                                            name="Details"
                                            alt="info"
                                            style={{ color: "rgb(94 217 135)" }}
                                            onClick={() =>
                                              this.detailData(
                                                status1.company_Id
                                              )
                                            }
                                            title="Details"
                                          >
                                            {/* Details&nbsp; */}
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>

                                          {/* <img src="https://img.icons8.com/glyph-neue/32/000000/trash.png" onClick={()=>this.deleteOrg(status1.company_Id)}/> */}
                                        </td>
                                      </tr>
                                    ))}

                                    {status0.map((status0) => (
                                      <tr>
                                        <td>{status0.company_Name}</td>
                                        <td>{status0.mobile}</td>
                                        <td>{status0.email}</td>
                                        <td>{status0.city}</td>
                                        <td>{status0.personname}</td>
                                        <td>
                                          <b
                                            style={{ color: "rgb(245 84 99)" }}
                                          >
                                            Inactive
                                          </b>
                                        </td>
                                        <td>
                                          {/* <img alt="info" src="https://img.icons8.com/ios-filled/24/000000/info.png"
                                           onClick={() => this.detailData1(status0.company_Id)} />       &nbsp;&nbsp;&nbsp; */}
                                          {/* changed line number 640 */}
                                          <button
                                            className="button-30 sk-del"
                                            name="Details"
                                            alt="info"
                                            style={{ color: "rgb(94 217 135)" }}
                                            onClick={() =>
                                              this.detailData1(
                                                status0.company_Id
                                              )
                                            }
                                            title="Details"
                                          >
                                            {/* Details&nbsp; */}
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>

                                <div id="SuperAdHide">
                                  <table
                                    id="add-row-tab"
                                    className="table  nowrap"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Entity Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                        <th>Contact </th>
                                        <th>Status </th>
                                        <th>Renewal Date </th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {status1.map((status1) => (
                                        <tr id="aubut">
                                          <td>{status1.company_Name}</td>
                                          <td>{status1.mobile}</td>
                                          <td>{status1.email}</td>
                                          <td>{status1.city}</td>
                                          <td>{status1.personname}</td>

                                          <td>
                                            {/* <img alt="info" src="https://img.icons8.com/emoji/32/000000/information-emoji.png"
                                           onClick={() => this.detailData(status1.company_Id)} /> 
                                                &nbsp;&nbsp;&nbsp;
                                          <img alt="trash" src="https://img.icons8.com/glyph-neue/32/000000/trash.png" 
                                          onClick={() => this.deleteOrg(status1.company_Id)} /> */}
                                            <button
                                              className="button-30 sk-edit"
                                              type="button"
                                              alt="info"
                                              style={{
                                                color: "rgb(94 217 135)",
                                              }}
                                              onClick={() =>
                                                this.detailData(
                                                  status1.company_Id
                                                )
                                              }
                                              title="Details"
                                            >
                                              {/* Details&nbsp; */}
                                              <i
                                                className="fa fa-info-circle"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button
                                              className="button-30 sk-edit"
                                              type="button"
                                              alt="trash"
                                              onClick={() =>
                                                this.deleteOrg(
                                                  status1.company_Id
                                                )
                                              }
                                              title="Delete"
                                            >
                                              {/* Delete&nbsp; */}
                                              <i
                                                className="fa fa-minus"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </button>
                                          </td>
                                        </tr>
                                      ))}

                                      {status0.map((status0) => (
                                        <tr>
                                          <td>{status0.company_Name}</td>
                                          <td>{status0.mobile}</td>
                                          <td>{status0.email}</td>
                                          <td>{status0.city}</td>
                                          <td>{status0.personname}</td>

                                          <td>
                                            {/* <img alt="edit" src="https://img.icons8.com/emoji/32/000000/information-emoji.png" 
                                            onClick={() => this.detailData(status0.company_Id)} />      &nbsp;&nbsp;&nbsp;
                                            <img alt="trash" src="https://img.icons8.com/glyph-neue/32/000000/trash.png" 
                                            onClick={() => this.deleteOrg(status0.company_Id)} /> */}
                                            <button
                                              className="button-30 sk-edit"
                                              type="button"
                                              alt="trash"
                                              onClick={() =>
                                                this.deleteOrg(
                                                  status0.company_Id
                                                )
                                              }
                                              title="Delete"
                                            >
                                              {/* Delete&nbsp; */}
                                              <i
                                                className="fa fa-minus"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button
                                              className="button-30 sk-edit"
                                              type="button"
                                              alt="edit"
                                              onClick={() =>
                                                this.detailData(
                                                  status0.company_Id
                                                )
                                              }
                                              title="Edit"
                                            >
                                              {/* Edit&nbsp; */}
                                              <i
                                                className="fa fa-edit"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </button>
                                          </td>
                                        </tr>
                                      ))}

                                      <tr>
                                        {User.map((User) => (
                                          <td>{User.company_Name}</td>
                                        ))}
                                        {User.map((User) => (
                                          <td>{User.mobile}</td>
                                        ))}
                                        {User.map((User) => (
                                          <td>{User.email}</td>
                                        ))}
                                        {User.map((User) => (
                                          <td>{User.adrs1}</td>
                                        ))}
                                        {User.map((User) => (
                                          <td>{User.personname}</td>
                                        ))}

                                        {User.map((User) => (
                                          <>
                                            {User.status === 1 ? (
                                              <td style={{ color: "green" }}>
                                                {" "}
                                                <b>Active</b>
                                              </td>
                                            ) : User.status === 2 ? (
                                              <td
                                                style={{
                                                  color: "rgb(142 150 12)",
                                                }}
                                              >
                                                <b>
                                                  {User.company_Name} profile
                                                  has been completed and waiting
                                                  for verification
                                                </b>
                                              </td>
                                            ) : User.status === 4 ? (
                                              <td style={{ color: "red" }}>
                                                <b>
                                                  profile has been rejected due
                                                  to {User.reason}
                                                </b>{" "}
                                              </td>
                                            ) : (
                                              <td style={{ color: "red" }}>
                                                <b>Inactive</b>{" "}
                                              </td>
                                            )}
                                          </>
                                        ))}
                                        {renewel.map((renewel) => (
                                          <td>{renewel.renewaldate}</td>
                                        ))}
                                        {User.map((User) => (
                                          <td>
                                            {/* <img alt="edit" src="https://img.icons8.com/android/22/000000/pencil.png"
                                           onClick={() => this.detailedit(User.company_Id)} />     &nbsp;&nbsp;&nbsp;
                                          <img alt="info" src="https://img.icons8.com/ios-filled/24/000000/info.png" 
                                          onClick={() => this.detailData(User.company_Id)} />     &nbsp;&nbsp;&nbsp; */}
                                            <button
                                              className="button-30 sk-edit"
                                              type="button"
                                              alt="edit"
                                              onClick={() =>
                                                this.detailedit(User.company_Id)
                                              }
                                              title="Edit"
                                            >
                                              {/* Edit&nbsp; */}
                                              <i
                                                className="fa fa-edit"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button
                                              className="button-30 sk-edit"
                                              type="button"
                                              style={{
                                                color: "rgb(94 217 135)",
                                              }}
                                              alt="info"
                                              onClick={() =>
                                                this.detailData(User.company_Id)
                                              }
                                              title="Details"
                                            >
                                              {/* Details&nbsp; */}
                                              <i
                                                className="fa fa-info-circle"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </button>{" "}
                                            &nbsp;&nbsp;&nbsp;
                                          </td>
                                        ))}
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                {/* Super Admin */}

                                <div id="Userorg">
                                  {User.map((User) => (
                                    <div>
                                      {(() => {
                                        if (User.status === 0) {
                                          return (
                                            <div>
                                              <table
                                                id="add-row-tab"
                                                className="table  nowrap"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Entity Name</th>
                                                    <th>Status </th>
                                                    <th>Reason </th>
                                                    <th>Action</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <td>{User.company_Name}</td>

                                                  {(() => {
                                                    if (User.status === 1) {
                                                      return (
                                                        <td
                                                          style={{
                                                            color: "green",
                                                          }}
                                                        >
                                                          {" "}
                                                          <b>Active</b>
                                                        </td>
                                                      );
                                                    }
                                                    if (User.status === 2) {
                                                      return (
                                                        <td
                                                          style={{
                                                            color:
                                                              "rgb(142 150 12)",
                                                          }}
                                                        >
                                                          <b>
                                                            {User.company_Name}{" "}
                                                            profile has been
                                                            completed and
                                                            waiting for
                                                            verification
                                                          </b>
                                                        </td>
                                                      );
                                                    }

                                                    if (User.status === 0) {
                                                      return (
                                                        <td
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          <b>Inactive</b>{" "}
                                                        </td>
                                                      );
                                                    }
                                                  })()}
                                                  <td>{User.reason}</td>

                                                  <td>
                                                    {/* <img alt="edit" src="https://img.icons8.com/android/22/000000/pencil.png" 
                                                  onClick={() => this.detaileditres(User.company_Id, User.reason)} />     &nbsp;&nbsp;&nbsp;
                                                  <img alt="info" src="https://img.icons8.com/ios-filled/24/000000/info.png" 
                                                  onClick={() => this.RejReasons(User.company_Id, User.reason)} />       &nbsp;&nbsp;&nbsp; */}
                                                    <button
                                                      className="button-30 sk-edit"
                                                      type="button"
                                                      alt="edit"
                                                      onClick={() =>
                                                        this.detaileditres(
                                                          User.company_Id,
                                                          User.reason
                                                        )
                                                      }
                                                      title="Edit"
                                                    >
                                                      {/* Edit&nbsp; */}
                                                      <i
                                                        className="fa fa-edit"
                                                        aria-hidden="true"
                                                      ></i>{" "}
                                                    </button>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <button
                                                      className="button-30 sk-edit"
                                                      type="button"
                                                      alt="info"
                                                      style={{
                                                        color:
                                                          "rgb(94 217 135)",
                                                      }}
                                                      onClick={() =>
                                                        this.RejReasons(
                                                          User.company_Id,
                                                          User.reason
                                                        )
                                                      }
                                                      title="Details"
                                                    >
                                                      {/* Details&nbsp; */}
                                                      <i
                                                        className="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>{" "}
                                                    </button>{" "}
                                                    &nbsp;&nbsp;&nbsp;
                                                  </td>
                                                </tbody>
                                              </table>
                                            </div>
                                          );
                                        }
                                        if (User.status === 1) {
                                          return (
                                            <div>
                                              <table
                                                id="add-row-tab"
                                                className="table nowrap"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Entity Name</th>
                                                    <th>Status </th>
                                                    <th>Renewal Date </th>
                                                    <th>Action</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <td>{User.company_Name}</td>

                                                  {(() => {
                                                    if (User.status === 1) {
                                                      return (
                                                        <td
                                                          style={{
                                                            color: "green",
                                                          }}
                                                        >
                                                          {" "}
                                                          <b>Active</b>
                                                        </td>
                                                      );
                                                    }
                                                    if (User.status === 2) {
                                                      return (
                                                        <td
                                                          style={{
                                                            color:
                                                              "rgb(142 150 12)",
                                                          }}
                                                        >
                                                          <b>
                                                            {User.company_Name}{" "}
                                                            profile has been
                                                            completed and
                                                            waiting for
                                                            verification
                                                          </b>
                                                        </td>
                                                      );
                                                    }

                                                    if (User.status === 0) {
                                                      return (
                                                        <td
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          <b>Inactive</b>{" "}
                                                        </td>
                                                      );
                                                    }
                                                  })()}

                                                  {renewel.map((renewel) => (
                                                    <td>
                                                      {renewel.renewaldate}
                                                    </td>
                                                  ))}
                                                  <td>
                                                    {/* <img alt="edit" src="https://img.icons8.com/android/22/000000/pencil.png" onClick={() => this.detailedit(User.company_Id)} />    &nbsp;&nbsp;&nbsp; */}
                                                    <button
                                                      className="button-30 sk-edit"
                                                      type="button"
                                                      onClick={() =>
                                                        this.detailedit(
                                                          User.company_Id
                                                        )
                                                      }
                                                      title="Edit"
                                                    >
                                                      {/* Edit&nbsp; */}
                                                      <i
                                                        className="fa fa-edit"
                                                        aria-hidden="true"
                                                      ></i>{" "}
                                                    </button>
                                                    &nbsp;&nbsp;&nbsp;
                                                    {/* <img alt="info" src="https://img.icons8.com/ios-filled/24/000000/info.png" onClick={() => this.RejReasons(User.company_Id, User.status)} />      &nbsp;&nbsp;&nbsp; */}
                                                    <button
                                                      className="button-30 sk-del"
                                                      name="Details"
                                                      style={{
                                                        color:
                                                          "rgb(94 217 135)",
                                                      }}
                                                      onClick={() =>
                                                        this.RejReasons(
                                                          User.company_Id,
                                                          User.status
                                                        )
                                                      }
                                                      title="Details"
                                                    >
                                                      {/* Details&nbsp; */}
                                                      <i
                                                        className="fa fa-info-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </button>
                                                  </td>
                                                </tbody>
                                              </table>
                                            </div>
                                          );
                                        }

                                        if (User.status === 2) {
                                          return (
                                            <div>
                                              <table
                                                id="add-row-tab"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                                //className="table table-bordered nowrap"
                                              >
                                                <thead>
                                                  <tr>
                                                    {/* <th>Entity Name</th> */}
                                                    {/* <th>Status </th> */}
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {/* <td>{User.company_Name}</td> */}

                                                  {(() => {
                                                    if (User.status === 1) {
                                                      return (
                                                        <td
                                                          style={{
                                                            color: "green",
                                                          }}
                                                        >
                                                          {" "}
                                                          <b>Active</b>
                                                        </td>
                                                      );
                                                    }
                                                    if (User.status === 2) {
                                                      return (
                                                        <td
                                                          style={{
                                                            color:
                                                              "rgb(142 150 12)",
                                                          }}
                                                        >
                                                          <b>
                                                            {User.company_Name}{" "}
                                                            profile has been
                                                            completed and
                                                            waiting for
                                                            verification
                                                          </b>
                                                        </td>
                                                      );
                                                    }
                                                    if (User.status === 4) {
                                                      return (
                                                        <td
                                                          style={{
                                                            color:
                                                              "rgb(142 150 12)",
                                                          }}
                                                        >
                                                          <b>
                                                            {User.company_Name}{" "}
                                                            profile has been
                                                            rejected due to{" "}
                                                            {User.reason}
                                                          </b>
                                                        </td>
                                                      );
                                                    }

                                                    if (User.status === 0) {
                                                      return (
                                                        <td
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          <b>Inactive</b>{" "}
                                                        </td>
                                                      );
                                                    }
                                                  })()}
                                                </tbody>
                                              </table>
                                            </div>
                                          );
                                        }
                                      })()}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            {/* TAB 2 */}

                            <div id="tabs-section-2" className="j-tabs-section">
                              <div className="dt-responsive table-responsive">
                                <table
                                  id="add-row-table2"
                                  className="table  nowrap"
                                  style={{ marginTop: Filterpop ? '50px' : '0px' }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Entity Name</th>
                                      <th>Primary Mobile Number</th>
                                      <th>Primary Email</th>
                                      <th>Location</th>
                                      <th>Contact </th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {status1.length === 0 &&
                                    FilterData.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                    {status1.map((status1) => (
                                      <tr>
                                        <td>{status1.company_Name}</td>
                                        <td>{status1.mobile}</td>
                                        <td>{status1.email}</td>
                                        <td>{status1.city}</td>
                                        <td>{status1.personname}</td>
                                        <>
                                          {(() => {
                                            if (status1.status === 1) {
                                              return (
                                                <td style={{ color: "green" }}>
                                                  {" "}
                                                  <b>Active</b>
                                                </td>
                                              );
                                            }
                                            if (
                                              status1.status === 2 ||
                                              status1.status === 4
                                            ) {
                                              return (
                                                <td
                                                  style={{
                                                    color: "rgb(142 150 12)",
                                                  }}
                                                >
                                                  <b>Pending</b>
                                                </td>
                                              );
                                            }

                                            if (status1.status === 0) {
                                              return (
                                                <td style={{ color: "red" }}>
                                                  <b>InActive</b>{" "}
                                                </td>
                                              );
                                            }
                                          })()}
                                        </>

                                        <td>
                                          {/* <img onClick={() => this.detailData(status1.company_Id)}  src="https://img.icons8.com/ios-filled/24/000000/info.png" 
                                          onClick={() => this.detailData(status1.company_Id)} />      &nbsp;&nbsp;&nbsp; */}
                                          <button
                                            className="button-30 sk-del"
                                            name="Details"
                                            alt="info"
                                            style={{ color: "rgb(94 217 135)" }}
                                            onClick={() =>
                                              this.detailData(
                                                status1.company_Id
                                              )
                                            }
                                            title="Details"
                                          >
                                            {/* Details&nbsp; */}
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            {/* TAB 3 */}

                            <div id="tabs-section-3" className="j-tabs-section">
                              <div className="dt-responsive table-responsive">
                                <table
                                  id="add-row-table3"
                                  className="table  nowrap"
                                  style={{ marginTop: Filterpop ? '50px' : '0px' }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Entity Name</th>
                                      <th>Primary Mobile Number</th>
                                      <th>Primary Email</th>
                                      <th>Location</th>
                                      <th>Contact </th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {status0.length === 0 &&
                                    FilterData.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                    {status0.map((status0) => (
                                      <tr>
                                        <td>{status0.company_Name}</td>
                                        <td>{status0.mobile}</td>
                                        <td>{status0.email}</td>
                                        <td>{status0.city}</td>
                                        <td>{status0.personname}</td>
                                        <div>
                                          {(() => {
                                            if (status0.status === 1) {
                                              return (
                                                <td style={{ color: "green" }}>
                                                  {" "}
                                                  <b>Active</b>
                                                </td>
                                              );
                                            }
                                            if (
                                              status0.status === 2 ||
                                              status0.status === 4
                                            ) {
                                              return (
                                                <td
                                                  style={{
                                                    color: "rgb(142 150 12)",
                                                  }}
                                                >
                                                  <b>Pending </b>
                                                </td>
                                              );
                                            }

                                            if (status0.status === 0) {
                                              return (
                                                <td style={{ color: "red" }}>
                                                  <b>Inactive</b>{" "}
                                                </td>
                                              );
                                            }
                                          })()}
                                        </div>
                                        <td>
                                          {/* <img alt="info" src="https://img.icons8.com/ios-filled/24/000000/info.png" 
                                          onClick={() => this.detailData1(status0.company_Id)} />    &nbsp;&nbsp;&nbsp; */}
                                          <button
                                            className="button-30 sk-del"
                                            name="Details"
                                            alt="info"
                                            style={{ color: "rgb(94 217 135)" }}
                                            onClick={() =>
                                              this.detailData1(
                                                status0.company_Id
                                              )
                                            }
                                            title="Details"
                                          >
                                            {/* Details&nbsp; */}
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            {/* TAB 4 */}

                            <div id="tabs-section-4" className="j-tabs-section">
                              <div className="dt-responsive table-responsive">
                                <table
                                  id="add-row-tab4"
                                  className="table nowrap"
                                  style={{ marginTop: Filterpop ? '50px' : '0px' }}
                                >
                                  <thead>
                                    <tr style={{ textAlign: "center" }}>
                                      <th>Entity Name</th>
                                      <th>Primary Mobile Number</th>
                                      <th>Primary Email</th>
                                      <th>Location</th>
                                      <th>Contact </th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {status2.length === 0 &&
                                    status4.length === 0 &&
                                    FilterData.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                    {status2.map((status2) => (
                                      <tr>
                                        <td>{status2.company_Name}</td>
                                        <td>{status2.mobile}</td>
                                        <td>{status2.email}</td>
                                        <td>{status2.city}</td>
                                        <td>{status2.personname}</td>
                                        <td
                                          style={{ color: "rgb(142 150 12)" }}
                                        >
                                          <b>Pending</b>
                                        </td>
                                        <td>
                                          {/* <img alt="info" src="https://img.icons8.com/ios-filled/24/000000/info.png" 
                                          onClick={() => this.detailData1(status2.company_Id)} />      &nbsp;&nbsp;&nbsp; */}
                                          <button
                                            className="button-30 sk-del"
                                            name="Details"
                                            alt="info"
                                            style={{ color: "rgb(94 217 135)" }}
                                            onClick={() =>
                                              this.detailData1(
                                                status2.company_Id
                                              )
                                            }
                                            title="Details"
                                          >
                                            {/* Details&nbsp; */}
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}

                                    {status4.map((status4) => (
                                      <tr>
                                        <td>{status4.company_Name}</td>
                                        <td>{status4.mobile}</td>
                                        <td>{status4.email}</td>
                                        <td>{status4.city}</td>
                                        <td>{status4.personname}</td>
                                        <td
                                          style={{ color: "red" }}
                                        >
                                          <b>Rejected</b>
                                        </td>
                                        <td>
                                          {/* <img alt="info" src="https://img.icons8.com/ios-filled/24/000000/info.png"
                                           onClick={() => this.detailData1(status4.company_Id)} />      &nbsp;&nbsp;&nbsp; */}
                                          <button
                                            className="button-30 sk-del"
                                            name="Details"
                                            alt="info"
                                            style={{ color: "rgb(94 217 135)" }}
                                            onClick={() =>
                                              this.detailData1(
                                                status4.company_Id
                                              )
                                            }
                                            title="Details"
                                          >
                                            {/* Details&nbsp; */}
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {Filterpop && (
                  <div className="popSearchTeam">
                    <div className="popSearchinnernormalTeam">
                      <div
                        className="popSearchinnertitle"
                        id="popSearchinnernormal"
                      >
                        <button
                          id="close"
                          style={{
                            float: "right",
                            backgroundColor: "#a0a3a305",
                            color: "#797070",
                            border: "1px solid #a3a5a505",
                            marginTop: "-20px",
                            marginRight: "-20px",
                          }}
                          onClick={() => {
                            this.setState({ Filterpop: false });
                            this.GetStatusOne();
                            this.GetStatusTwo();
                            this.UserOrg();
                            this.GetPending();
                            this.GetRejectPending();
                            this.renewel();
                            this.GetOrgLogo();
                          }}
                        >
                          <CloseIcon />
                        </button>

                        <div className="checkboxes">
                          <i
                            className="fa fa-search"
                            aria-hidden="true"
                            style={{
                              fontSize: "20px",
                              color: "gray",
                              marginLeft: "-1%",
                              position: "absolute",
                              marginTop: "0.5%",
                            }}
                          ></i>
                          <input
                            className="Searchbox"
                            placeholder="Search"
                            onKeyUp={this.onKeyUp}
                            style={{ float: "right", width: "80%" }}
                          />
                          <br></br>
                          {this.state.letters.map((letter, index) => (
                            <button
                              className="filter"
                              key={index}
                              onClick={(event) =>
                                this.handleFilter(letter, event)
                              }
                            >
                              {letter}
                            </button>
                          ))}
                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
