import React, { Component } from "react";
import SideBar from "../Common/Sidebar";
import "../Css/Addskills.css";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContactSupportOutlined } from "@material-ui/icons";
import PlusOne from "@material-ui/icons/Add";
import Add from "@material-ui/icons/Add";
import TrainingEditTrainerPop from "./TrainingEditTrainerPop";
import BookmarkAddIcon from "@material-ui/icons/PostAdd";
import TrainingEditTraineesPop from "./TrainingEditTraineesPop";
import { BASE_URLs } from "../Config/Base";
export default class EditSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Departmentdrop: [],
      seperateDept: [],
      trainer: [],
      trainerDrop: [],
      memberDrop: [],
      department: [],
      selectedDepartment: "",
      Learning: [],
      LearnerList: [],
      selectedOption: [],
      selectedLabel: [],
      selectedLabels: [],
      Selectedvalue: [],
      SelectedTrainer: [],
      SelectedTrainee: [],
      TraineePopupVisible: false,
      TrainerPopupVisible: false,
      selectedTrainingTopicId: "",
      LearnId: "",
      selectedCheckboxValues: [],
      selectedCheckboxValues2: [],
      selectedCheckboxValues3: [],
      Trainers: [],
      Train: [],
      Trainees: [],
      Trainee: [],
      SavedTrainees: [],
      SavedTrainee: [],
      SelectedTraineeEdit: [],
      startDate: this.props.location.state.startDate.slice(0, 10),
      scheduleTime: this.props.location.state.scheduleTime,
    };
    this.addItem = this.addItem.bind(this);
    this.handleChangesss = this.handleChangesss.bind(this);
  }

  handleDateTimeChange = (e) => {
    const value = e.target.value;

    const values = value.split("T");

    this.setState({
      scheduleTime: values[1],
    });
    // console.log("jjjjj", values[1]);
  };

  EditDeleteTrainer = (array, id) => {
    let changes = this.state.Trainers.map((e, index) => {
      if (e.trainer_UserId === id) return { ...e, ...{ check: false } };
      return { ...e };
    });
    this.setState({ selectedOption: changes.filter((e) => e.check === true) });
    this.setState({ Trainers: changes });
  };
  EditDeleteTrainee = (array, id) => {
    let changes = this.state.SelectedTraineeEdit.map((e, index) => {
      if (e[0] === id) return { ...e, ...{ check: false } };
      return { ...e };
    });
    this.setState({
      SelectedTraineeEdit: changes.filter((e) => e.check === true),
    });
    this.setState({ SelectedTraineeEdit: changes });
  };

  checkAll(e) {
    var checkboxes = document.getElementsByName("deletcateg");
    var selectedValues = [];

    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        selectedValues.push(checkboxes[i].value);
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    this.setState({
      selectedCheckboxValues: selectedValues,
    });
    // console.log(selectedValues);
  }

  handlecheckbox() {
    let checkboxes = document.querySelectorAll('input[id="selectall"]:checked');
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;
      if (document.getElementById("selectall").checked) {
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      } else {
        msg.innerHTML =
          checkboxes.length === 1
            ? "<span> " + checkboxes.length + " Record is selected </span> "
            : "<span> " + checkboxes.length + " Records are selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }

  checkChange = (event) => {
    this.handlecheckbox();

    const { name, checked, value } = event.target;
    // value1.indexOf(name) === -1
    //   ? value1.push(name)
    //   : value1.splice(value1.indexOf(name), 1);

    this.setState((prevState) => {
      const selectedCheckboxValues = checked
        ? [...prevState.selectedCheckboxValues, value]
        : prevState.selectedCheckboxValues.filter((val) => val !== value);

      // console.log("selectsTrainerName", selectedCheckboxValues);
      return { selectedCheckboxValues };
    });
  };

  handleTrainersCheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[id="trainerscheckid"]:checked'
    );
    checkboxes.forEach((checkbox) => {
      // console.log("CheckBoxValue", checkbox.value);
    });
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldivs")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldivs")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("deletes")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecounts")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecounts");
      let count = checkboxes.length - 1;
      if (document.getElementById("checkAllTrainers").checked) {
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      } else {
        msg.innerHTML =
          checkboxes.length === 1
            ? "<span> " + checkboxes.length + " Record is selected </span> "
            : "<span> " + checkboxes.length + " Records are selected </span> ";
      }
    } else {
      document
        .getElementById("deletes")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecounts")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("checkAllTrainers").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("checkAllTrainers").checked = true;
      }
    } else {
      if (document.getElementById("checkAllTrainers").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("checkAllTrainers").checked = false;
        }
      }
    }
  }

  checkAllTrainers() {
    var checkboxes = document.getElementsByClassName("deletcategs");
    var selectedValues = [];
    var selectNames = [];

    var sa = document.getElementById("checkAllTrainers");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        selectedValues.push(checkboxes[i].value.split(","));
        selectNames.push(checkboxes[i].name);
        document
          .getElementById("deletes")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecounts")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecounts");
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("deletes")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecounts")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      selectedCheckboxValues3: selectedValues,
    });
    this.setState({
      selectedCheckboxValues2: selectNames,
    });

    // console.log(selectedValues, selectNames);
  }

  checkChangeTrainees = (event) => {
    this.handleTrainersCheckbox();

    const { name, checked, value } = event.target;
    // value2.indexOf(value) === -1
    //   ? value2.push(value)
    //   : value2.splice(value2.indexOf(value), 1);
    // value3.indexOf(name) === -1
    //   ? value3.push(name)
    //   : value3.splice(value3.indexOf(name), 1);
    this.setState((prevState) => {
      const selectedCheckboxValues2 = checked
        ? [...prevState.selectedCheckboxValues2, name]
        : prevState.selectedCheckboxValues2.filter((value) => value !== name);

      // console.log("ValuesSelected", selectedCheckboxValues2);
      return { selectedCheckboxValues2 };
    });

    this.setState((prevState) => {
      const selectedCheckboxValues3 = checked
        ? [...prevState.selectedCheckboxValues3, value.split(",")]
        : prevState.selectedCheckboxValues2.filter((value) => value !== value);

      // console.log("SelectedTrainingId", selectedCheckboxValues3);
      return { selectedCheckboxValues3 };
    });
  };

  openAddTraineePopup = (e) => {
    e.preventDefault();

    let deptarr = this.state.Trainees.map((e) => e.department[0]);
    const { SavedTrainees } = this.state.SavedTrainees;

    let f = this.state.SavedTrainees.filter((e) => {
      if (!deptarr.includes(e[0])) {
        return e;
      }
    });
    this.setState({ SavedTrainees: f });
    this.setState({ SavedTrainee: f });

    this.setState({ TraineePopupVisible: !this.state.TraineePopupVisible });
  };

  openAddTrainerPopup = (e) => {
    e.preventDefault();
    this.setState({ TrainerPopupVisible: !this.state.TrainerPopupVisible });
  };

  toggleAddTrainerPopup = () => {
    this.setState((prevState) => ({
      TraineePopupVisible: false,
      TrainerPopupVisible: false,
    }));
  };

  GetDepartment() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });
        this.setState({
          department: filteredData,
        });
        // console.log("Department", this.state.department);
      });
  }
  GetTrainers = async (department) => {
    // alert("Hi")
    const value = department
      ? department
      : this.props.location.state.department;
    // console.log("Depadsfsdfsdfrtment", value);
    //     this.GetMembers(value);
    let url = `${BASE_URLs}/Learning/GetManagerBasedTrainer?Department=${
      this.props.location.state.department
    }&TrainerInfo=${1}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    const reqData = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    });
    const resData = await reqData.json();
    // for (let i = 0; i < resData.length; i++) {
    //        this.state.trainerDrop.push(resData[i].label);
    // }
    //   // console.log("TrainersDetailssssssssssssssssss", this.state.trainerDrop);
    this.setState({ trainerDrop: resData });
    //  this.setState({ trainerDrop: this.state.trainerDrop });
    // console.log("TrainersDetailssssssssssssssssss", this.state.trainerDrop);
  };

  GetMembers = async (department) => {
    let url = `${BASE_URLs}/Learning/GetManagerBasedMember?Department=${
      this.props.location.state.department
    }&TrainerInfo=${0}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    const reqData = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    });
    const resData = await reqData.json();
    // for (let i = 0; i < resData.length; i++) {
    //        this.state.memberDrop.push(resData[i].label);
    // }
    this.setState({ memberDrop: resData });
    // console.log("TraineesDetails", this.state.memberDrop);
  };
  handleChange = (event) => {
    const department = event.target.value;
    this.setState({ selectedDepartment: department });
    this.setState({ trainerDrop: [] });
    //   this.setState({memberDrop : []})
    this.GetTrainers(department);
  };
  handleChangesss(value, actionDetails) {
    /// alert("hi");
    let items = [...this.state.selectedOption];
    // console.log("items", ...this.state.selectedOption);

    if (actionDetails.action === "remove-value") {
      if (items) {
        var index = -1;
        items.forEach((item, i) => {
          if (
            item.label === actionDetails.removedValue.label &&
            item.value === actionDetails.removedValue.value
          ) {
            index = i;
            return;
          }
        });
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    } else {
      items.push(actionDetails.option);
    }

    this.setState({ selectedOption: items });
    // console.log("itemshandlechnagesssss", ...this.state.selectedOption);
  }

  handleChangessstrainee(value, actionDetails) {
    let items = [...this.state.selectedOption];
    // console.log("items", ...this.state.selectedOption);
    if (actionDetails.action === "remove-value") {
      if (items) {
        var index = -1;
        items.forEach((item, i) => {
          if (
            item.label === actionDetails.removedValue.label &&
            item.value === actionDetails.removedValue.value
          ) {
            index = i;
            return;
          }
        });
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    } else {
      items.push(actionDetails.option);
    }

    this.setState({ selectedOption: items });
  }

  Getdetail() {
    var Id = this.props.location.state.Id;
    var startDate = this.props.location.state.startDate;
    var upload_id = "1029";
    let url = `${BASE_URLs}/Learning/detail?Id=` + Id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((result) => result.json())
      .then((data) => {
        this.setState({
          Learning: data,
        });
        // console.log("555555555555", data);
        this.getValues(data);
      });
  }
  // LearnerList = async () => {
  //        var Id = this.props.location.state.Id
  //        let url = '${BASE_URLs}/Learning/LearningDetails?Id=' + Id;
  //        let user = JSON.parse(localStorage.getItem('user'));
  //        const accessToken = user;
  //        // console.log(accessToken);
  //        const reqData = await fetch(url, {
  //               method: 'GET',
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //                      "Content-type": "application/json",
  //                      "Accept": "application/json",
  //                       Authorization: "Bearer " + accessToken,
  //                      "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
  //               },
  //        })
  //        const resData = await reqData.json();
  //        for (let i = 0; i <= resData.length; i++) {
  //               this.state.selectedLabel.push(resData[i].trainerName);
  //        }
  //        this.setState({ selectedLabel: this.state.selectedLabel })
  //        // console.log("LearnerList", this.state.selectedLabel);
  //        // return (selectedLabel);
  // }

  LearnerList = () => {
    var Id = this.props.location.state.Id;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    let url = `${BASE_URLs}/Learning/LearningDetails?Id=` + Id;
    return fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("WholeData", data);
        //  this.getValues(data);
        for (let i = 0; i < data.length; i++) {
          this.state.selectedLabel.push(data[i].trainerName);
        }

        for (let i = 0; i < data.length; i++) {
          this.state.selectedLabels.push(data[i].trainees);
        }
        // console.log("Dataaaaaa", data[0]);
        this.setState({ selectedLabel: this.state.selectedLabel });
        // console.log("Dataaaaaa", this.state.selectedLabel);
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
  };
  GetTrainer = () => {
    //  // console.log("department", department);
    let url = `${BASE_URLs}/Training/TrainingEditGetTrainer?LearnId=${this.props.location.state.Id}`;
    // // console.log("deeee1",LearnId);

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let final = data.map((e) => {
          return { ...e, check: false };
        });
        // console.log("hhhhhhhhhhhh", final);
        this.setState({ Trainers: final });
        this.setState({ Train: final });
        // setTrainers(final.filter(e=>e.deleteStatus!==1))
        // setTrain(final)
        console.log(
          " ====fffff ",
          final.filter((e) => e.deleteStatus === 1)
        );
      });
  };
  GetTrainee = () => {
    //  // console.log("department", department);
    let url = `${BASE_URLs}/Training/Training_GetTraineesEdit?LearnId=${this.props.location.state.Id}`;
    // // console.log("deeee1",LearnId);

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let final = data.map((e) => {
          return { ...e, check: false };
        });

        // console.log("fffffffffffffffffffffffffffbgggg", final);
        let wholedept = [];
        final.filter((e) =>
          !wholedept.includes(e.department)
            ? wholedept.push(e.department)
            : null
        );

        let rr = wholedept.map((dep) => {
          let dpart = [];
          let UserId = [];
          let Name = [];

          let loop = final
            .filter((e) => e.department === dep)
            .map((ee) => {
              dpart.push(ee.department);
              UserId.push(ee.user_Id);
              Name.push(ee.firstName);
            });

          //if(ee.department)

          return {
            department: dpart,
            userId: UserId,
            Name: Name,
          };
        });

        // console.log("finaleeeeeeeeeeeeeeeeee", rr);

        this.setState({ Trainees: rr });
        this.setState({ Trainee: final });

        // setTrainers(final.filter(e=>e.deleteStatus!==1))
        // setTrain(final)
        // // console.log(" ====fffff ",final.filter(e=>e.deleteStatus===1));
      });
  };
  componentDidMount() {
    this.GetTrainee();

    this.Getdetail();
    this.GetDepartment();
    //   this.GetMembers();
    this.GetTrainer();
    this.GetTrainers();
    // this.LearnerList();
    this.GetSavedTrainee();
  }
  getValues(apiValues) {
    // console.log("apiValues", apiValues);
    const value = apiValues;

    this.setState({
      selectedTrainingTopicId: value.id,
    });
    // if (apiValues[0].trainerName != null) {
    //   let previousValue = [];
    //   let names = apiValues[0].trainerName.split(",");
    //   // console.log("jkfbskjvbdskjf", names);
    //   let ids = apiValues[0].trainerId.split(",");
    //   let index = 0;

    //   if (names.length === ids.length) {
    //     names.forEach((item) => {
    //       let data = { value: ids[index].toString(), label: item };
    //       index++;
    //       previousValue.push(data);
    //     });
    //   }

    //   this.setState({ selectedOption: previousValue });
    //   // console.log("selebndkjbk", previousValue);
    //   // console.log("selebndkjbk", this.state.selectedOption);
    // }
  }

  handleChange(value, actionDetails) {
    let items = [...this.state.selectedOption];
    if (actionDetails.action === "remove-value") {
      if (items) {
        var index = -1;
        items.forEach((item, i) => {
          if (
            item.label === actionDetails.removedValue.label &&
            item.value === actionDetails.removedValue.value
          ) {
            index = i;
            return;
          }
        });
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    } else {
      items.push(actionDetails.option);
    }

    this.setState({ selectedOption: items });
  }

  // handleselectChange(value, actionDetails) {
  //        let items = [...this.state.selectedOption];
  //        if (actionDetails.action === "remove-value") {
  //               if (items) {
  //                      var index = -1;
  //                      items.forEach((item, i) => {
  //                             if (item.label === actionDetails.removedValue.label && item.value === actionDetails.removedValue.value) {
  //                                    index = i;
  //                                    return;
  //                             }
  //                      });
  //                      if (index > -1) {
  //                             items.splice(index, 1);
  //                      }
  //               }
  //        }
  //        else {
  //               items.push(actionDetails.option);
  //        }
  //        this.setState({ selectedOption: items });
  // }

  handleSelectChange = (event) => {
    // this.setState({selectedLabel:[]})
    this.setState({ ...(this.state.selectedOptions = event) });
    // console.log("selectTrainers", ...this.state.selectedOptions);
  };

  handleSelectChangeTrainee = (event) => {
    // this.setState({memberDrop:[]})
    this.setState({ ...(this.state.SelectedTrainee = event) });
    // console.log("selectTrainee", ...this.state.SelectedTrainee);
  };

  // handleSelectChange = (selectedOptions) => {
  //        const selectedValues = selectedOptions.map(option => option);
  //        this.setState({ selectedValues });
  // };

  addItem() {
    this.selectedValues.push({ key: "Shaarik", id: "2" });
  }

  handleMultiselectChange = (event) => {
    //  alert("Handlemulti")
    this.setState({ ...(this.state.selectedOption = event) });
    console.log(
      "selectOptionin handle multisleect",
      ...this.state.selectedOption
    );
  };
  handleSubmit = (event) => {
    const {
      selectedLabel,
      memberDrop,
      SelectedTrainer,
      SelectedTrainee,
      selectedOption,
      SelectedTraineeEdit,
    } = this.state;
    event.preventDefault();

    const formData = {
      startDate: this.props.location.state.startDate.slice(0, 10),
      scheduleTime: this.state.scheduleTime,
    };
    const data = new FormData(event.target);

    data.append("LearnId", this.props.location.state.Id);
    data.append("ScheduleTime", this.state.scheduleTime);

    const values = this.state.selectedOption;

    values.forEach((e) => {
      data.append("TrainerId", e.trainer_Id);
    });
    const Traineevalues = this.state.SelectedTraineeEdit;

    Traineevalues.forEach((e) => {
      e[1].forEach((ee) => {
        data.append("TraineesId", ee);
      });
    });

    for (const [key, value] of data.entries()) {
      // console.log(key, value);
    }

    //  data.append('TrainerName',"Vijayaasri");

    //return true
    // // console.log("inputvalues", data);
    fetch(`${BASE_URLs}/Learning/EditLearning`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        //  Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        // "Content-type": "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
      // body: data,
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === "Success") {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 1200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(function () {
            if (document.getElementById("close")) {
              document.getElementById("close").click();
            }
          }, 4000);
        } else {
          toast.error(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setTimeout(() => {
          this.props.history.push("/LearningSchedule");
        }, 1000);

        // this.LearnerList()
        this.GetTrainer();
        this.GetSavedTrainee();

        this.setState({ selectedOption: [] });
        this.setState({ status: resp.Status });
        this.setState({ message: resp.Message });
        // console.log("inputvalues", data);
      });
  };

  DeleteMutipleTrainers = (event) => {
    event.preventDefault();

    const values = this.state.selectedCheckboxValues;

    const data = new FormData();
    values.forEach((e) => {
      data.append("Id_delete", e);
    });
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    fetch(`${BASE_URLs}/Learning/EditMultideleteTrainer`, options)
      .then((response) => response.json())
      .then((data) => {
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
        // this.LearnerList()
        this.GetTrainer();
        // console.log("ffffffffffffff", data);
        //  this.GetMasterUG();
        //  this.GetMasterPG();
        //  this.GetMasterPHD();
        //  toast.success(
        //    data.message, {
        //    position: "top-center",
        //    autoClose: 3200,
        //    hideProgressBar: false,
        //    closeOnClick: true,
        //    pauseOnHover: true,
        //    draggable: true,
        //    progress: undefined,
        //    })
      });
  };

  ////////////////

  DeleteMutipleTrainees = (event) => {
    event.preventDefault();

    const TraineeId = this.state.selectedCheckboxValues3;

    const data = new FormData();
    TraineeId.forEach((e) => {
      e.forEach((ee) => data.append("Id_delete", ee));
    });
    for (const [key, value] of data.entries()) {
      // console.log(key, value);
    }
    // return true
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    fetch(
      `${BASE_URLs}/Learning/EditMultideleteTrainee?learnId=${this.props.location.state.Id}`,
      options
    )
      .then((response) => response.json())
      .then((data) => {
        this.GetSavedTrainee();
        this.GetTrainee();

        document
          .getElementById("deletes")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecounts")
          .setAttribute("style", "visibility: hidden;");
        // this.LearnerList()
        this.GetTrainer();
        // console.log("ffffffffffffff", data);
        //  this.GetMasterUG();
        //  this.GetMasterPG();
        //  this.GetMasterPHD();
        //  toast.success(
        //    data.message, {
        //    position: "top-center",
        //    autoClose: 3200,
        //    hideProgressBar: false,
        //    closeOnClick: true,
        //    pauseOnHover: true,
        //    draggable: true,
        //    progress: undefined,
        //    })
      });
  };
  GetSavedTrainee = () => {
    let obj = {};
    //  // console.log("department", department);
    let url = `${BASE_URLs}/Training/GetTraineesForTrainingSchedule`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Call aguthu ");

        let uu = data.forEach((e) => {
          obj[e.department] === undefined
            ? (obj[e.department] = [e.user_Id])
            : obj[e.department].push(e.user_Id);
        });
        let final = [];
        Object.entries(obj).forEach(([key, value]) => {
          final.push([...[key], ...[value]]);
        });

        let f = final.map((e) => {
          return { ...e, ...{ check: false } };
        });
        // console.log("//////////////////", f);
        this.setState({ SavedTrainees: f });
        this.setState({ SavedTrainee: f });

        // console.log("====finalfinalfinalfinal", f);
      });
  };

  render() {
    const {
      plainArray,
      trainerDrop,
      memberDrop,
      Learning,
      LearnerList,
      selectedLabel,
      selectedLabels,
      TraineePopupVisible,
    } = this.state;
    let selectedValue = [];
    //  let selectedLabel = [];
    this.state.selectedOption &&
      this.state.selectedOption.forEach((item) => {
        selectedValue.push(item.value);
        selectedLabel.push(item.label);
      });

    this.state.selectedOptions &&
      this.state.selectedOptions.forEach((item) => {
        selectedValue.push(item.value);
        selectedLabel.push(item.label);
      });
    // let unique = [...new Set(selectedLabel)]
    // // console.log(unique);
    // let unique2 = [...new Set(selectedValue)]
    // // console.log(unique2);

    let formatOptionLabel = ({ value, label, image }) => (
      <div className="grp-flex" style={{ display: "flex" }}>
        <img className="grp-edit-img2" src={image} />
        <span>{label}</span>
      </div>
    );

    let formatOptionLabel1 = ({ value, label, image }) => (
      <div className="grp-flex" style={{ display: "flex" }}>
        <img className="grp-edit-img2" src={image} />
        <span>{label}</span>
      </div>
    );
    let options = trainerDrop.map(function (item) {
      return { value: item.id, label: item.name };
    });
    let selectedNames = this.state.selectedOption.map((e) => e.label);

    const filteredArray = this.state.trainerDrop.filter(
      (value) => !selectedNames.includes(value.label)
    );

    const colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles) => ({
        ...styles,
        textAlign: "center",
        display: "flex",
        backgroundColor: "white",
        "text-align-last": "center",
        color: "black",
      }),
      // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //  // const color = chroma(data.color);
      //   return {
      //     ...styles,
      //     backgroundColor: isDisabled ? 'white' : "red",
      //   //  color: 'white',
      //     textAlign:"left",
      //     cursor: isDisabled ? 'not-allowed' : 'default',

      //   };
      // },
    };

    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          {Learning.map((Learning) => (
                            <h4 style={{ marginRight: "3%" }} className="title">
                              {Learning.trainingitem}
                            </h4>
                          ))}
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row" style={{ marginTop: "3%" }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="container">
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        {Learning.map((Learning) => (
                                          <div>
                                            <input
                                              type="hidden"
                                              name="LearnId"
                                              Id="LearnId"
                                              value={Learning.id}
                                            />
                                          </div>
                                        ))}
                                        <div className="col-sm-6">
                                          {/* {Learning.map((Learning) => (
                                            <select
                                              className="effect-16"
                                              type="text"
                                              value={Learning.department}
                                              name="Department"
                                              id="Department"
                                              onChange={this.handleChange}
                                            >
                                              <option value="">Select</option>
                                              {this.state.department.map(
                                                (Departmentdrop) => (
                                                  <option
                                                    value={
                                                      Departmentdrop.department
                                                    }
                                                  >
                                                    {Departmentdrop.department}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          ))} */}
                                          {Learning.map((Learning) => (
                                            <input
                                              className="effect-16"
                                              name="Department"
                                              id="Department"
                                              value={Learning.department}
                                              type="text"
                                              maxLength="50"
                                            />
                                          ))}
                                          <span style={{ color: "#a29d9d" }}>
                                            Department
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          {Learning.map((Learning) => (
                                            <input
                                              className="effect-16"
                                              name="Trainingitem"
                                              id="Trainingitem"
                                              value={Learning.trainingitem}
                                              type="text"
                                              maxLength="50"
                                            />
                                          ))}
                                          <span style={{ color: "#a29d9d" }}>
                                            Training Topic
                                          </span>
                                        </div>

                                        <div className="col-sm-6">
                                          {Learning.map((Learning) => (
                                            <input
                                              className="effect-16"
                                              name="TrainingDescription"
                                              id="Trainingdescription"
                                              value={
                                                Learning.trainingDescription
                                              }
                                              type="text"
                                              //maxLength="50"
                                            />
                                          ))}
                                          <span style={{ color: "#a29d9d" }}>
                                            Training Description
                                          </span>
                                        </div>

                                        {/* <div className="col-sm-6">
                                                <input
                                                  className="effect-16"
                                                  defaultValue={(this.props.location.state.startDate)}
                                                  name="StartDate"
                                                  id="StartDate"
                                                  onFocus={(e) =>
                                                    (e.target.type = "date")
                                                  }
                                                  maxLength="50"
                                                />
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Start Date
                                                </span>
                                              </div> */}

                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            // defaultValue={`${this.props.location.state.startDate.slice(0, 10)} ${this.state.scheduleTime}`}
                                            defaultValue={`${this.state.startDate} ${this.state.scheduleTime}`}
                                            name="StartDate"
                                            id="StartDate"
                                            onChange={this.handleDateTimeChange}
                                            onFocus={(e) =>
                                              (e.target.type = "datetime-local")
                                            }
                                            maxLength="50"
                                          />
                                          <span style={{ color: "#a29d9d" }}>
                                            Start Date
                                          </span>
                                        </div>

                                        {/* <div className="col-sm-6">
                                          {Learning.map((Learning) => (
                                            <select
                                              className="effect-16"
                                              name="SchedulingDuration"
                                              defaultValue={
                                                Learning.schedulingDuration
                                              }
                                              id="SchedulingDuration"
                                              type="text"
                                              onChange={this.handleChange}
                                            >
                                              <option value="">Select</option>
                                              <option value="">Select</option>
                                              <option
                                                value="1 Hour"
                                                id="duration"
                                              >
                                                1 Hour
                                              </option>
                                              <option
                                                value="2 Hours"
                                                id="duration"
                                              >
                                                2 Hours
                                              </option>
                                              <option
                                                value="3 Hours"
                                                id="duration"
                                              >
                                                3 Hours
                                              </option>
                                              <option
                                                value="4 Hours"
                                                id="duration"
                                              >
                                                4 Hours
                                              </option>
                                              <option
                                                value="5 Hours"
                                                id="duration"
                                              >
                                                5 Hours
                                              </option>
                                              <option
                                                value="6 Hours"
                                                id="duration"
                                              >
                                                6 Hours
                                              </option>
                                              <option
                                                value="7 Hours"
                                                id="duration"
                                              >
                                                7 Hours
                                              </option>
                                              <option
                                                value="8 Hours"
                                                id="duration"
                                              >
                                                8 Hours
                                              </option>
                                            </select>
                                          ))}
                                          {/* {Learning.map(Learning => <input className="effect-16" name="duration" defaultValue={Learning.schedulingDuration} id="duration" type="text" maxLength="50" />)} */}
                                        {/* <span style={{ color: "#a29d9d" }}> */}
                                        {/* Duration of Training */}
                                        {/* </span> */}
                                        {/* </div> */}
                                        {/* <div className="col-sm-6"> */}
                                        {/* <input className="effect-16" defaultValue={this.props.location.state.endDate} name="EndDate" id="EndDate" onFocus={(e) => e.target.type = 'date'} maxLength="50" />
                                                                                                                                                   <span style={{ color: '#a29d9d' }} >End Date</span> */}
                                        {/* <Select
                                            isClearable={false}
                                            placeholder={"Select Mates"}
                                            styles={this.customStyles}
                                            menuPortalTarget={document.querySelector(
                                              "body"
                                            )}
                                            onSelect={
                                              this.handleMultiselectChange
                                            }
                                            value={this.state.selectedOption}
                                            onChange={this.handleChangesss}
                                            selectedValue={true}
                                            hideSelectedOptions={false}
                                            options={filteredArray}
                                            isMulti */}
                                        {/* theme={theme => ({ */}
                                        {/* // ...theme,
                                            // textAlign:"left",
                                            // colors: { */}
                                        {/* // ...theme.colors,
                                            // backgroundColor:"red",
                                            // textAlign:"left",
                                            // },
                                            // })}
                                            // styles={colourStyles}
                                            // style={{backgroundColor: "pink"}}
                                          //   styles={colourStyles}
                                          //   formatOptionLabel={ */}
                                        {/* //     formatOptionLabel
                                          //   }
                                          // /> */}
                                        {/* <span style={{ color: "#a29d9d" }}>
                                            Trainer Name
                                          </span>{" "}
                                          <span style={{ color: "red" }}></span>
                                        </div> */}

                                        <div className="col-sm-6">
                                          {/*                             
                                                                                                                                                   <Multiselect className="effect-16" showArrow
                                                                                                                                                         // options={this.state.selectedOption}
                                                                                                                                                          selectedValues={this.state.selectedOption}
                                                                                                                                                          value="label"
                                                                                                                                                          // onRemove={(selectedList) => this.setState({ selectedValues: selectedList })}
                                                                                                                                                          getSelectedItems={trainerDrop}
                                                                                                                                                          onSelect={this.handleChange}
                                                                                                                                                          isObject={false}
                                                                                                                                                          name="TrainerName"
                                                                                                                                                          labelledBy="Select"
                                                                                                                                                        //  value={this.state.selectedOption}
                                                                                                                                                          displayValue="label"
                                                                                                                                                   /> */}
                                          {/* {Learning.map(Learning =>
                                                                                                                                               {this.state.trainerDrop}
                                                                                                                                               )} */}
                                        </div>

                                        <div className="col-sm-6">
                                          {/* <Multiselect className="effect-16" showArrow
                                                                                                                                                          options={memberDrop}
                                                                                                                                                          selectedValues={memberValues}
                                                                                                                                                          getSelectedItems={memberDrop}
                                                                                                                                                          onSelect={this.handleSelectChangeTrainee}
                                                                                                                                                          isObject={false}
                                                                                                                                                          name="Trainees"
                                                                                                                                                   /> */}

                                          {/* <Select isClearable={false} placeholder={"Select Mates"}
                                                                                                                                            styles={this.customStyles}
                                                                                                                                            menuPortalTarget={document.querySelector('body')}
                                                                                                                                            value={this.state.selectedOptions}
                                                                                                                                            onChange={this.handleChangessstrainee}
                                                                                                                                            selectedValue={true}
                                                                                                                                            hideSelectedOptions={false}
                                                                                                                                             options={this.state.memberDrop}
                                                                                                                                            isMulti
                                                                                                                                            // theme={theme => ({
                                                                                                                                            // ...theme,
                                                                                                                                            // colors: {
                                                                                                                                            // ...theme.colors,
                                                                                                                                            // // primary25: "blue",
                                                                                                                                            // backgroundColor:"#0096fb",
                                                                                                                                            // color: "#fff",
                                                                                                                                            
                                                                                                                                            // borderRadius:"11px",
                                                                                                                                            // fontSize:"13px",
                                                                                                                                            // textAlign:"left",
                                                                                                                                            
                                                                                                                                            // borderRadius: 0
                                                                                                                                            // },
                                                                                                                                            // })}
                                                                                                                                            
                                                                                                                                           // style={{backgroundColor: "pink"}}
                                                                                                                                            formatOptionLabel={formatOptionLabel} /> */}
                                          {/* <span style={{ color: '#a29d9d' }} >Team Member</span> <span style={{ color: "red" }}>*</span> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  {/* Trainers */}
                                  <div className="col-lg-6">
                                    <div
                                      style={{
                                        margin: "10px",
                                        padding: "20px",
                                        overflow: "auto",
                                        height: "250px",
                                        backgroundColor: "white",
                                        borderTop: "5px solid whitesmoke",
                                        borderBottom: "5px solid whitesmoke",
                                        borderLeft: "5px solid whitesmoke",
                                        borderRight: "5px solid whitesmoke",
                                        boxShadow: "10px 10px 5px lightblue",
                                      }}
                                    >
                                      {/* Rest of the code */}
                                      <button
                                        style={{
                                          width: "40px",
                                          height: "30px",
                                        }}
                                        type="button"
                                        onClick={(e) =>
                                          this.openAddTrainerPopup(e)
                                        }
                                      >
                                        {" "}
                                        <Add />
                                      </button>
                                      <span
                                        id="deletecount"
                                        className="multitrash"
                                        style={{
                                          visibility: "hidden",
                                          fontSize: "17px",
                                        }}
                                      ></span>
                                      <div
                                        id="selectalldiv"
                                        style={{ visibility: "hidden" }}
                                      >
                                        &nbsp;&nbsp;
                                        <button
                                          type="button"
                                          className="multitrash"
                                          id="delete"
                                          onClick={(e) =>
                                            this.DeleteMutipleTrainers(e)
                                          }
                                          style={{
                                            float: "right",
                                            visibility: "hidden",
                                          }}
                                        >
                                          <i
                                            className="icofont icofont-delete-alt"
                                            style={{ color: "darkgray" }}
                                          ></i>
                                        </button>
                                      </div>
                                      <table
                                        id="skillsheet"
                                        className="table   nowrap SkillGrid"
                                      >
                                        <thead style={{ textAlign: "left" }}>
                                          <tr>
                                            <th>
                                              {" "}
                                              <input
                                                type="checkbox"
                                                id="selectall"
                                                onClick={() => {
                                                  this.checkAll(this);
                                                }}
                                              />{" "}
                                            </th>
                                            <th>
                                              Assign Trainers &nbsp;&nbsp;
                                            </th>

                                            {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.Trainers.filter(
                                            (e) => e.deleteStatus === 1
                                          ).map((value, index) => (
                                            <tr key={index}>
                                              <td>
                                                {" "}
                                                <input
                                                  type="checkbox"
                                                  className="deletcateg"
                                                  id="selectall"
                                                  name="deletcateg"
                                                  onChange={this.checkChange}
                                                  value={value.trainer_Id}
                                                />
                                              </td>
                                              <td> {value.trainerName} </td>
                                              {/* <td style={{ textAlign: "center" }}>
                                            <button
                                              name="delete"
                                              className="button-30 sk-del"
                                              //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                              // onClick={(e) => {
                                              //   e.preventDefault();
                                              //   this.DeleteButton(
                                              //     FileDocs.upload_id,
                                              //   );
                                              // }}
                                            >
                                              <i
                                                className="fa fa-trash-o"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </td> */}
                                            </tr>
                                          ))}

                                          {this.state.selectedOption
                                            .filter((e) => e.check === true)
                                            .map((value, index) => (
                                              <tr key={index}>
                                                <td
                                                  style={{ fontSize: "24px" }}
                                                >
                                                  {" "}
                                                  <i
                                                    className="icofont icofont-business-man-alt-3"
                                                    style={{ color: "grey" }}
                                                  ></i>{" "}
                                                </td>
                                                <td>{value.trainerName}</td>
                                                <td>
                                                  <button
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.EditDeleteTrainer(
                                                        value,
                                                        value.trainer_UserId
                                                      );
                                                    }}
                                                    type="submit"
                                                    className="multitrash"
                                                    id="deletes"
                                                  >
                                                    <i
                                                      className="icofont icofont-delete-alt"
                                                      style={{ color: "red" }}
                                                    ></i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                        {/* <tbody>

                                    </tbody> */}
                                      </table>
                                    </div>
                                  </div>

                                  {/* Trainees */}
                                  <div className="col-lg-6">
                                    <div
                                      style={{
                                        margin: "10px",
                                        padding: "20px",
                                        overflow: "auto",
                                        height: "250px",
                                        backgroundColor: "white",
                                        borderTop: "5px solid whitesmoke",
                                        borderBottom: "5px solid whitesmoke",
                                        borderLeft: "5px solid whitesmoke",
                                        borderRight: "5px solid whitesmoke",
                                        boxShadow: "10px 10px 5px lightblue",
                                      }}
                                    >
                                      {/* Rest of the code */}
                                      <button
                                        style={{
                                          width: "40px",
                                          height: "30px",
                                        }}
                                        type="button"
                                        onClick={(e) =>
                                          this.openAddTraineePopup(e)
                                        }
                                      >
                                        {" "}
                                        <Add />
                                      </button>
                                      <span
                                        id="deletecounts"
                                        className="multitrash"
                                        style={{
                                          visibility: "hidden",
                                          fontSize: "17px",
                                        }}
                                      ></span>
                                      <div
                                        id="selectalldivs"
                                        style={{ visibility: "hidden" }}
                                      >
                                        &nbsp;&nbsp;
                                        <button
                                          className="multitrash"
                                          type="button"
                                          id="deletes"
                                          onClick={this.DeleteMutipleTrainees}
                                          style={{
                                            float: "right",
                                            visibility: "hidden",
                                          }}
                                        >
                                          <i
                                            className="icofont icofont-delete-alt"
                                            style={{ color: "darkgray" }}
                                          ></i>
                                        </button>
                                      </div>
                                      <table
                                        id="skillsheet"
                                        className="table   nowrap SkillGrid"
                                      >
                                        <thead style={{ textAlign: "left" }}>
                                          <tr>
                                            <th>
                                              {" "}
                                              <input
                                                type="checkbox"
                                                id="checkAllTrainers"
                                                onClick={() => {
                                                  this.checkAllTrainers(this);
                                                }}
                                              />{" "}
                                            </th>
                                            <th>Trainees &nbsp;&nbsp;</th>

                                            {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.Trainees.map(
                                            (
                                              e //.filter(e=>e.deleteStatus===1)
                                            ) => (
                                              <tr>
                                                <td>
                                                  {" "}
                                                  <input
                                                    type="checkbox"
                                                    className="deletcategs"
                                                    name="TraineesName"
                                                    defaultValue={e.userId}
                                                    id="trainerscheckid"
                                                    onChange={
                                                      this.checkChangeTrainees
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  {" "}
                                                  {e.department[0]}
                                                  {"("}
                                                  {e.department.length}
                                                  {")"}
                                                </td>
                                                {/* <td style={{ textAlign: "center" }}>
                                            <button
                                              name="delete"
                                              className="button-30 sk-del"
                                              //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                              // onClick={(e) => {
                                              //   e.preventDefault();
                                              //   this.DeleteButton(
                                              //     FileDocs.upload_id,
                                              //   );
                                              // }}
                                            >
                                              <i
                                                className="fa fa-trash-o"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </td> */}
                                              </tr>
                                            )
                                          )}

                                          {this.state.SelectedTraineeEdit.filter(
                                            (e) => e.check === true
                                          ).map((value, index) => (
                                            <tr key={index}>
                                              <td style={{ fontSize: "24px" }}>
                                                {" "}
                                                <i
                                                  className="icofont icofont-business-man-alt-3"
                                                  style={{ color: "grey" }}
                                                ></i>{" "}
                                              </td>
                                              <td>
                                                {value[0]}
                                                {"("}
                                                {value[1].length}
                                                {")"}
                                              </td>
                                              <td>
                                                <button
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.EditDeleteTrainee(
                                                      value,
                                                      value[0]
                                                    );
                                                  }}
                                                  type="submit"
                                                  className="multitrash"
                                                  id="deletes"
                                                >
                                                  <i
                                                    className="icofont icofont-delete-alt"
                                                    style={{ color: "red" }}
                                                  ></i>
                                                </button>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <br></br>
                                <div className="row">
                                  <div
                                    className="col-sm-12"
                                    style={{ textAlign: "center" }}
                                  >
                                    <button
                                      type="submit"
                                      className="but "
                                      style={{
                                        height: "39.5px",
                                        width: "86.36px",
                                        marginTop: "-0.3%",
                                      }}
                                    >
                                      Save
                                    </button>
                                    &nbsp; &nbsp; &nbsp;
                                    <button
                                      type="reset"
                                      className=" btn-Secondary"
                                      style={{ height: "39.5px" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                                <br /> <br />
                                <br /> <br />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.TrainerPopupVisible && (
          <TrainingEditTrainerPop
            LearnId={this.props.location.state.Id}
            array={this.state.Trainers.filter((e) => e.deleteStatus == 0)}
            state={(state) => this.setState({ selectedOption: state })}
            closeThePopup={this.toggleAddTrainerPopup}
          />
        )}

        {this.state.TraineePopupVisible && (
          <TrainingEditTraineesPop
            array={this.state.SavedTrainees}
            // FinalSubmit = {(e)=>{this.handleSubmit(e)}}

            state={(state) =>
              // // console.log("[[[[[[[[[[[[[",state)

              this.setState({ SelectedTraineeEdit: state })
            }
            third={this.toggleAddTrainerPopup}
          />
        )}
      </div>
    );
  }
}
