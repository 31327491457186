import { React, useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { BASE_URLs } from "../Config/Base";

const AddTraineeExistingPop = (props) => {
  useEffect(() => {
    Getusers();
  }, []);
  const [allusers1, setAllusers1] = useState([]);

  function Getusers() {
    // // console.log("Token",token)

    fetch(`${BASE_URLs}/Trainer/GetAssignTrainee`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",

        Accept: "application/json",

        Authorization: "Bearer " + `${token}`,

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Data", data);
        setAllusers1(data);
      });
    // console.log("check", allusers1);
  }

  const token = JSON.parse(localStorage.user);

  return (
    <div>
      <div className="JoinLink">
        <div className="JoinLinkinnernormalpop">
          <form autoComplete="off" encType="multipart/form-data">
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={props.close}
            >
              <CloseIcon />
            </button>

            <div className="row" style={{ padding: "8px" }}>
              <div className="col-lg-12">
                <ul>
                  {allusers1.map((e) => {
                    return <li>{e.trainees}</li>;
                  })}
                </ul>
              </div>
            </div>
            <br />
            <div className="row">
              {/* <div className="col-lg-12" style={{textAlign:"center"}}>          
   <button type="submit"  className="but" style={{height: '39.5px',width: '100px',marginTop:"-0.3%"}}>Join Request</button>                 
</div> */}
            </div>
            <br />
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddTraineeExistingPop;
