import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { BASE_URLs } from "../../Config/Base";
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      Pending: [],
      Notification: [],
      Profile_Pic: [],
      SkillDetails: [],
      Loading: false,
      Team:[]
    };
  }

  GetUserProfile() {
    let url = `${BASE_URLs}/Profile/Userprofile`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Profile_Pic: data,
        });
      });
  }
  componentDidMount() {
    this.GetUserProfile();
    
    this.Getpending();
    this.SkillDetailList();
    this.interval = setInterval(() => {
      this.Team();  
    }, 2000);
    this.setState({ Loading: true });
    document.getElementById("maindiv").style.display = "none";
    setTimeout(() => {
      this.setState({ Loading: false });
      // console.log("Teammmmm")

      document.getElementById("UDloader").style.display = "none";
      document.getElementById("maindiv").style.display = "block";
    }, 500);
  }
  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.interval);
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}/AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
        //
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}/AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
        //
      });
  }
  Getall() {
    fetch(`${BASE_URLs}/AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
        //
      });
  }
  Getpending() {
    fetch(`${BASE_URLs}/AddOrganization/pendingcount`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Pending: data });
      });
  }

  SkillDetailList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/UserSkillDetails/GetUserSkill`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SkillDetails: data });
        // console.log(data, "Skilldetails");
      });
  }

  
  Team() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetTeamStatus`, options)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => parseInt(a.teamId) - parseInt(b.teamId));
        this.setState({
          Team: data,
          Teams: data,
        });
        // console.log("hess", data);
      });
  }

  render() {
    const { SkillDetails,Team } = this.state;
    var username = JSON.parse(localStorage.uname);
    var Email = JSON.parse(localStorage.email);

    return (
      <div>
        <SideBar active={window.location.pathname} />

        {/* new Admin  dashboard */}

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="loader" id="UDloader" style={{ top: "350px" }}>
              <MoonLoader
                color={"#123abc"}
                loading={this.props.loading}
                size={30}
              />
            </div>
            <div className="main-body" id="maindiv">
              <div className="page-wrapper">

                {
                  Team.map((Teams)=>{
                    return <>
                    {Teams.status == 1 && Teams.email == Email && SkillDetails.length == 0 ? 
                    <div
                    style={{
                      textAlign: "center",
                      marginLeft: "43px",
                      marginTop: "3%",
                    }}
                  >
                    <h3>Dear {username},Complete Your Profile</h3>
                    <br />
                    <Link to="/Profile">
                      <span>
                        <input
                          type="button"
                          className="but"
                          value="Profile"
                          style={{ height: "41.5px", width: "104.36px" }}
                        />
                      </span>
                    </Link>{" "}
                    &nbsp; &nbsp; &nbsp;
                    </div> : Teams.status == 0 && Teams.email == Email ?  <div
                    style={{
                      textAlign: "center",
                      marginLeft: "43px",
                      marginTop: "3%",
                    }}
                  >
                    <h3>Dear {username},You have been Deactivated</h3>
                    </div>: Teams.status == 3 && Teams.email == Email?<div
                    style={{
                      textAlign: "center",
                      marginLeft: "43px",
                      marginTop: "3%",
                    }}
                  >
                    <h3>Dear {username},You have been Terminated</h3>
                    </div>: Teams.status == 1 && Teams.email == Email && SkillDetails.length != 0?
                    (
                  <>
                    <div className="row">
                      <div className="col-md-12 col-xl-4">
                        <div className="card">
                          <div className="widget-profile-card-1">
                            <img
                              alt="fluid"
                              className="img-fluid"
                              src="assets/images/slider/slider7.jpg"
                            />
                            <div className="middle-user">
                              <img
                                alt="imgthumb"
                                className="img-fluid img-thumbnail"
                                src="assets/images/user-card/img-round2.jpg"
                              />
                            </div>
                          </div>
                          <div className="card-block text-center">
                            <h3>Jenny Joe</h3>
                            <p>Web Designer</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci a, rem accusantium recusandae beatae.</p> */}
                          </div>
                          <div className="card-footer bg-inverse">
                            <div className="row text-center">
                              <div className="col-xs-4 col-sm-4 col-lg-4">
                                {/* <h4></h4> */}
                                <span>Designs</span>
                              </div>
                              <div className="col-xs-4 col-sm-4 col-lg-4">
                                {/* <h4></h4> */}
                                <span>Projects</span>
                              </div>
                              <div className="col-xs-4 col-sm-4 col-lg-4">
                                {/* <h4></h4> */}
                                <span>Devlopments</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-4">
                        <div
                          className="card user-activity-card"
                          style={{ height: "94%" }}
                        >
                          <div className="card-block-big">
                            <h5>User Activities</h5>
                            {/* <h3 className="user-number"></h3> */}
                            <h6 className="m-b-15">Social Users</h6>
                            <div className="progress m-b-30">
                              <div
                                className="progress-bar progress-bar-striped progress-sm progress-bar-warning"
                                role="progressbar"
                                style={{ width: "50%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                            <div className="row m-b-40">
                              <div className="col-sm-6">
                                <p>Page Visits</p>
                                {/* <h6></h6> */}
                              </div>
                              <div className="col-sm-6">
                                <p>New Visitors</p>
                                {/* <h6></h6> */}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <p>Page Visits</p>
                                {/* <h6></h6> */}
                              </div>
                              <div className="col-sm-6">
                                <p>New Visitors</p>
                                {/* <h6></h6> */}
                              </div>
                            </div>
                            <i className="icofont icofont-link-alt link" />
                          </div>
                        </div>
                      </div>

                      <div className=" weather-card-1  text-center">
                        <div className="mob-bg-calender bg-facebook">
                          <div className="row">
                            <div className="col-sm-12">
                              <h4>Sunday</h4>
                              <h1 className="weather-temp">23</h1>
                            </div>
                            <div className="col-sm-12">
                              <svg
                                version="1.1"
                                id="cloudDrizzle"
                                className="climacon climacon_cloudDrizzle"
                                viewBox="15 15 70 70"
                              >
                                <g className="climacon_iconWrap climacon_iconWrap-cloudDrizzle">
                                  <g className="climacon_wrapperComponent climacon_wrapperComponent-drizzle">
                                    <path
                                      className="climacon_component climacon_component-stroke climacon_component-stroke_drizzle climacon_component-stroke_drizzle-left"
                                      d="M42.001,53.644c1.104,0,2,0.896,2,2v3.998c0,1.105-0.896,2-2,2c-1.105,0-2.001-0.895-2.001-2v-3.998C40,54.538,40.896,53.644,42.001,53.644z"
                                    />
                                    <path
                                      className="climacon_component climacon_component-stroke climacon_component-stroke_drizzle climacon_component-stroke_drizzle-middle"
                                      d="M49.999,53.644c1.104,0,2,0.896,2,2v4c0,1.104-0.896,2-2,2s-1.998-0.896-1.998-2v-4C48.001,54.54,48.896,53.644,49.999,53.644z"
                                    />
                                    <path
                                      className="climacon_component climacon_component-stroke climacon_component-stroke_drizzle climacon_component-stroke_drizzle-right"
                                      d="M57.999,53.644c1.104,0,2,0.896,2,2v3.998c0,1.105-0.896,2-2,2c-1.105,0-2-0.895-2-2v-3.998C55.999,54.538,56.894,53.644,57.999,53.644z"
                                    />
                                  </g>
                                  <g className="climacon_wrapperComponent climacon_wrapperComponent-cloud">
                                    <path
                                      className="climacon_component climacon_component-stroke climacon_component-stroke_cloud"
                                      d="M63.999,64.944v-4.381c2.387-1.386,3.998-3.961,3.998-6.92c0-4.418-3.58-8-7.998-8c-1.603,0-3.084,0.481-4.334,1.291c-1.232-5.316-5.973-9.29-11.664-9.29c-6.628,0-11.999,5.372-11.999,12c0,3.549,1.55,6.729,3.998,8.926v4.914c-4.776-2.769-7.998-7.922-7.998-13.84c0-8.836,7.162-15.999,15.999-15.999c6.004,0,11.229,3.312,13.965,8.203c0.664-0.113,1.336-0.205,2.033-0.205c6.627,0,11.998,5.373,11.998,12C71.997,58.864,68.655,63.296,63.999,64.944z"
                                    />
                                  </g>
                                </g>
                              </svg>
                              <span className="weather-temp">8°</span>
                              <h5>New York , NY , USA</h5>
                              <span className="d-block">6:00 AM</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 col-xl-4 m-b-30">
                        <div className="widget-main-card blur-user-card">
                          <img
                            alt="bluruser"
                            src="assets/images/widget/user-blur-bg.png"
                          />
                          <h3>Linda Fox</h3>
                          <p>UI Designer at CreativesCastle Studio</p>
                          <button className="btn btn-info">+ Follow</button>
                          <div className="blur-footer">
                            <div className="row text-center">
                              <div className="col-xs-4 col-sm-4 col-lg-4">
                                {/* <h4></h4> */}
                                <span>Shots</span>
                              </div>
                              <div className="col-xs-4 col-sm-4 col-lg-4">
                                {/* <h4></h4> */}
                                <span>Followers</span>
                              </div>
                              <div className="col-xs-4 col-sm-4 col-lg-4">
                                {/* <h4></h4> */}
                                <span>Following</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-xl-4">
                        <div className="card">
                          <div className="widget-profile-card-3">
                            <img
                              alt="fluid"
                              className="img-fluid img-thumbnail"
                              src="assets/images/user-card/img-round1.jpg"
                            />
                          </div>
                          <div className="card-block text-center">
                            <h3>John Doe</h3>
                            <p>Web Designer</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci a, rem accusantium recusandae beatae.</p> */}
                          </div>
                          <div className="card-footer bg-inverse">
                            <div className="row text-center">
                              <div className="col-sm-4">
                                {/* <h4></h4> */}
                                <span>Designs</span>
                              </div>
                              <div className="col-sm-4">
                                {/* <h4></h4> */}
                                <span>Projects</span>
                              </div>
                              <div className="col-sm-4">
                                {/* <h4></h4> */}
                                <span>Devlopments</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-xl-4 m-b-30">
                        <div className="card social-card-1">
                          <div className="card-block-big">
                            <div className="media">
                              <div className="media-left">
                                <a href>
                                  <img
                                    alt="fluidim"
                                    className="img-fluid img-thumbnail img-circle img-100"
                                    src="assets/images/user-card/img-round1.jpg"
                                  />
                                </a>
                              </div>
                              <div className="media-body">
                                <h4 className="media-heading m-b-15">
                                  John Martin
                                </h4>
                                <h6 className="f-w-300">Grphics Designer</h6>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <div className="row text-center">
                              <div className="col-sm-4">
                                <div className="social-media">
                                  <i className="icofont icofont-heart-alt" />
                                  {/* <h5 className="counter"></h5> */}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="social-media">
                                  <i className="icofont icofont-ui-user" />
                                  {/* <h5 className="counter"></h5> */}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="social-media">
                                  <i className="icofont icofont-eye-alt" />
                                  {/* <h5 className="counter"></h5> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ):null}
                    </>
                  })
                }
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>

        {/* End Admin New Dashboard */}

        {/* Start Super Admin New Dashboard */}

        {/* End Super Admin New Dashboard */}
      </div>
    );
  }
}
