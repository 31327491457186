import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddTrainingpop from "../../Common/AddTrainingpop";
import EditTrainingpop from "../../Common/EditTrainingpop";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import TrainingXL from "../../Assets/Files/Training.xlsx";
import "../../Css/Tab.css";
import { red } from "@material-ui/core/colors";
import FilterPop from "../../Common/FilterPop";
import { Train } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { event } from "jquery";
import { BASE_URLs } from "../../Config/Base";

var XLSX = require("xlsx");
export default class TrainingSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Active: [],
      InActive: [],
      All: [],
      comlogo: [],
      Training: [],
      Trainingsss: [],
      Train: [],
      formErrors: {},
      DepartModel: false,
      EditModel: false,
      EditDesigntionValues: [],
      Notification: [],
      rows: [],
      cols: [],
      toggleState: 1,
      allrolevalue: [],
      selectedroleValue: "",
      TrainingList: [],
      FilteredDesignation: [],
      UpdatedFilteredDesignation: [],
      Filterpopstatus: false,
      Filterpopstatus1: false,
      Department: [],
      AllDepartments: [],
      isOpen: false,
      popupdesignation: "",
      PopupFilteredDesignation: "",
      PopupFilteredValue: "",
      PopupDropDownFiltered: "",
      selectedIds: [],
      desigFilter: "0",
      deptFilter: "0",
      topicFilter: "0",
      descripFilter: "0",
      newIDCheckAll: [],
      selectedCheckboxes: {},
      selectAllChecked: false,
    };
  }

  removeDuplicates(data) {
    const uniqueSet = new Set();
    data.forEach((item) => {
      uniqueSet.add(JSON.stringify(item));
    });
    const uniqueArray = Array.from(uniqueSet).map((item) => JSON.parse(item));
    return uniqueArray;
  }

  removeDuplicatesByField(data, fieldName) {
    const uniqueData = [];
    const uniqueValues = new Set();

    data.forEach((item) => {
      if (!uniqueValues.has(item[fieldName])) {
        uniqueValues.add(item[fieldName]);
        uniqueData.push(item);
      }
    });

    return uniqueData;
  }

  GetTraining() {
    let url = `${BASE_URLs}/Training/GetAdminTraining`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueData = this.removeDuplicatesByField(data, "description");
        this.setState({
          Training: uniqueData,
          Trainingsss: data.filter(
            (e) => e.designation !== null && e.status !== 0
          ),
          Train: data,
          PopupFilteredDesignation: data.filter((e) => e.designation !== null),
        });
        console.log(
          "Akak---------------",
          data.filter((e) => e.designation !== null)
        );
        // console.log("shan", uniqueData);
        {
          const { Trainingsss } = this.state;

          let data = Trainingsss.reduce((r, e) => {
            let designationName = e.designation;

            if (!r[designationName])
              r[designationName] = { designationName, record: [e] };
            else r[designationName].record.push(e);

            return r;
          }, {});

          let result = Object.values(data);
          this.setState({ FilteredDesignation: result });
          this.setState({ UpdatedFilteredDesignation: result });

          // console.log("DesigkResultsyyyyyyyyyyyyyyyyyy", result);
          this.sortAlphabetically(this.state.FilteredDesignation);
          // console.log("FilteredDesignation", this.state.FilteredDesignation);
        }
      });
  }
  sortAlphabetically = (event) => {
    const { FilteredDesignation } = this.state;
    let filter = [...event].sort((a, b) => {
      return a.designationName < b.designationName ? -1 : 1;
    });

    this.setState({ FilteredDesignation: filter });
    // console.log("Alpha", FilteredDesignation);
  };

  componentDidMount() {
    this.GetTraining();
    this.GetAllRoles();
  }

  Multidelete = (event) => {
    event.preventDefault();
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    const data = new FormData(event.target);
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}/Training/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetTraining();
          alert(resp.message);
        });
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };

  DeleteButton = (id, desigId) => {
    // console.log("------------", id, desigId);
    const data = new FormData();
    data.append("Id_delete", id);
    data.append("desgId_delete", desigId);

    for (var pair of data.entries()) {
      // console.log(pair[0] + ", " + pair[1]);
    }

    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}/Training/Designationdelete`, options)
        .then((response) => response.json())
        .then((data) => {
          this.GetTraining();
          toast.success(data.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;

      if (document.getElementById("selectall").checked) {
        msg.innerHTML = "<span> " + count + " selected </span> ";
      } else {
        msg.innerHTML = "<span> " + checkboxes.length + " selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }
  handleUpload = (event) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);
    if (isAllowed) {
      const file = event.target.files[0];
      readFile(file).then((readedData) =>
        this.setState({ initialData: readedData })
      );
      /* file upload logic goes here... */
      return true;
    } else {
      alert("Invalid File Type.");
      return false;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}/Training/TrainingExcel`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          this.setState({ initialData: undefined });
          var file = document.getElementById("files");
          var emptyFile = document.createElement("input");
          emptyFile.type = "file";
          file.files = emptyFile.files;
          this.GetTraining();
          if (resp === undefined) {
            alert("0  Records  inserted ");
          }
          if (resp === 0) {
            alert("Records added Successfully");
          } else {
            alert("Some records not inserted ");
          }
          // this.GetAddSkill();
        });
    } catch (error) {}
  };

  GetAllRoles() {
    let url = `${BASE_URLs}/Training/GetAllRoles`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          allrolevalue: data,
        });
      });
  }

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("TrainingSheet");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  DepartModel = () => {
    this.setState((prevState) => ({
      DepartModel: !prevState.DepartModel,
    }));
    this.GetTraining();
  };
  EditModel = (training_Id, categoryMaster, ref_Doc) => {
    this.setState({
      EditDesigntionValues: {
        training_Id: training_Id,
        categoryMaster: categoryMaster,
        ref_Doc: ref_Doc,
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    var page = {
      pathname: "/EditTrainingpop",
      state: {
        training_Id: training_Id,
        categoryMaster: categoryMaster,
        ref_Doc: ref_Doc,
      },
    };
    this.props.history.push(page);

    // this.GetTraining();
  };
  AssignModel = (training_Id, trainingitem, department) => {
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    var page = {
      pathname: "/AssignTraining",
      state: {
        training_Id: training_Id,
        trainingitem: trainingitem,
        department: department,
      },
    };
    this.props.history.push(page);

    // this.GetTraining();
  };

  handleCheckAll = () => {
    this.setState(
      (prevState) => {
        const { selectAllChecked, PopupFilteredValue, selectedIds } = prevState;

        const updatedSelectedCheckboxes = {};

        if (!selectAllChecked) {
          PopupFilteredValue.forEach((Training) => {
            updatedSelectedCheckboxes[Training.desigId] = true;
          });
        }

        this.setState({
          selectAllChecked: !selectAllChecked,
          selectedCheckboxes: updatedSelectedCheckboxes,
        });

        const allSelected = PopupFilteredValue.every((Training) =>
          this.state.selectedIds.includes(Training.desigId)
        );

        const updatedSelectedIds = allSelected
          ? this.state.selectedIds.filter(
              (id) =>
                !PopupFilteredValue.map(
                  (Training) => Training.desigId
                ).includes(id)
            )
          : [
              ...this.state.selectedIds,
              ...PopupFilteredValue.map((Training) => Training.desigId),
            ];

        let finalSelectedIds = updatedSelectedIds.map((e) => e.toString());
        let uniqueSet = new Set(finalSelectedIds);
        let resultArray = Array.from(uniqueSet);

        if (!selectAllChecked) {
          return {
            selectedIds: resultArray,
          };
        } else {
          return {
            selectedIds: [],
          };
        }
      },
      () => {
        // console.log("CheckALL", this.state.selectedIds);
      }
    );
  };

  handleCheckboxChange = (event) => {
    const desigId = event.target.value;

    const { value, checked } = event.target;

    this.setState((prevState) => ({
      selectedCheckboxes: {
        ...prevState.selectedCheckboxes,
        [value]: checked,
      },
    }));

    this.setState(
      (prevState) => {
        const { selectedIds } = prevState;

        if (selectedIds.includes(desigId)) {
          // Deselect the individual checkbox
          return {
            selectedIds: selectedIds.filter((id) => id !== desigId),
          };
        } else {
          // Select the individual checkbox
          return {
            selectedIds: [...selectedIds, desigId],
          };
        }
      },
      () => {
        // console.log("Checkbox changed:", this.state.selectedIds);
      }
    );
  };

  Detail = (TrainingId, designationName) => {
    const desigName = designationName;
    const traID = TrainingId;
    const newID = [];

    if (traID.length >= 0) {
      traID.forEach((item) => {
        newID.push(item.training_Id);
      });
    }

    const { Training } = this.state;

    this.setState({ PopupFilteredValue: [] });

    const popupFilter = Training.filter((item) => {
      return (
        !newID.includes(item.training_Id) && item.designation !== desigName
      );
    });

    this.setState({ PopupFilteredValue: popupFilter }, () => {
      // console.log("PopUpFilterrrrrr", this.state.PopupFilteredValue);
    });

    this.setState({ PopupDropDownFiltered: popupFilter });

    this.setState({ popupdesignation: designationName });
    this.setState({ isOpen: true });
  };

  handleClick = (e) => {
    e.preventDefault();

    let url = `${BASE_URLs}/Training/DesignationAddTraining?Designation=${this.state.popupdesignation}&Desig_ID=${this.state.selectedIds}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data", data);
      });
    this.setState({ PopupDropDownFiltered: this.state.PopupFilteredValue });
    this.setState({ isOpen: false });
    window.location.reload();
  };

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));

    this.props.history.push("/TrainingSchedule");
  };

  closePopup = () => {
    this.setState({ isOpen: false });
  };
  onKeyUp = (e) => {
    const Training = this.state.Train.filter(
      (item) =>
        item.department.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.trainingitem
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.description.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Training });
  };

  Edit1(training_Id) {
    var page = {
      pathname: "/EditTrainingpop",
      state: {
        training_Id: training_Id,
      },
    };
    this.props.history.push(page);
  }

  toggleTab = (index) => {
    this.setState({ toggleState: index });
    // console.log("ToggleIndex", index);
  };

  handleChange = (event) => {
    const { Training, deptFilter, topicFilter, descripFilter } = this.state;

    const Desig = event.target.value;

    this.setState({ desigFilter: Desig });

    const popupFilter = Training.filter((item) => {
      return (
        (Desig === "0" || item.designation === Desig) &&
        (deptFilter === "0" || item.department === deptFilter) &&
        (topicFilter === "0" || item.trainingitem === topicFilter) &&
        (descripFilter === "0" || item.description === descripFilter)
      );
    });

    this.setState({ PopupDropDownFiltered: popupFilter });
  };

  handleChange1 = (event) => {
    const { Training, desigFilter, topicFilter, descripFilter } = this.state;

    const Dept = event.target.value;

    this.setState({ deptFilter: Dept });

    const popupFilter = Training.filter((item) => {
      return (
        (desigFilter === "0" || item.designation === desigFilter) &&
        (Dept === "0" || item.department.includes(Dept)) &&
        (topicFilter === "0" || item.trainingitem === topicFilter) &&
        (descripFilter === "0" || item.description === descripFilter)
      );
    });

    this.setState({ PopupDropDownFiltered: popupFilter });
  };

  handleChange2 = (event) => {
    const { Training, desigFilter, deptFilter, descripFilter } = this.state;

    const Topic = event.target.value;

    this.setState({ topicFilter: Topic });

    const popupFilter = Training.filter((item) => {
      return (
        (desigFilter === "0" || item.designation === desigFilter) &&
        (deptFilter === "0" || item.department === deptFilter) &&
        (Topic === "0" || item.trainingitem === Topic) &&
        (descripFilter === "0" || item.description === descripFilter)
      );
    });

    this.setState({ PopupDropDownFiltered: popupFilter });
  };

  handleChange3 = (event) => {
    const { Training, desigFilter, deptFilter, topicFilter } = this.state;

    const Description = event.target.value;

    this.setState({ descripFilter: Description });

    const popupFilter = Training.filter((item) => {
      return (
        (desigFilter === "0" || item.designation === desigFilter) &&
        (deptFilter === "0" || item.department === deptFilter) &&
        (topicFilter === "0" || item.trainingitem === topicFilter) &&
        (Description === "0" || item.description.includes(Description))
      );
    });

    this.setState({ PopupDropDownFiltered: popupFilter });
  };

  render() {
    const {
      Training,
      Train,
      Trainings,
      TrainingList,
      FilteredDesignation,
      isOpen,
      selectedIds,
      selectAllChecked,
      selectedCheckboxes,
    } = this.state;
    var Entityname = JSON.parse(localStorage.entityName);
    var filename = `O_${Entityname}_TrainingItem`;
    const { toggleState } = this.state;
    const { Department, Filterpopstatus, Filterpopstatus1 } = this.state;
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        {Filterpopstatus ? (
          <FilterPop
            data={this.state.UpdatedFilteredDesignation}
            FilteredData={(newValue) => {
              this.setState({ FilteredDesignation: newValue });
            }}
            TogglePop={(e) => {
              this.setState({ Filterpopstatus: !Filterpopstatus });
            }}
          />
        ) : null}

        {Filterpopstatus1 ? (
          <FilterPop
            data={Train}
            FilteredData={(newValue) => {
              this.setState({ Training: newValue });
            }}
            TogglePop={(e) => {
              this.setState({ Filterpopstatus1: !Filterpopstatus1 });
            }}
          />
        ) : null}
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title">Training Topics</h4>
                        </div>

                        {/* <div className="containerTabs">
                          <div className="bloc-tabs">
                            <button
                              className={
                                toggleState === 1 ? "tabs active-tabs" : "tabs"
                              }
                              onClick={() => this.toggleTab(1)}
                            >
                              Training Topic
                            </button>
                            <button
                              className={
                                toggleState === 2 ? "tabs active-tabs" : "tabs"
                              }
                              onClick={() => this.toggleTab(2)}
                            >
                              Designation Based Topic
                            </button>
                          </div> */}

                        <br />
                        <div className="j-tabs-container md-tabs">
                          <div style={{ marginLeft: "35px" }}>
                            <input
                              id="tab1"
                              type="radio"
                              name="tabs"
                              defaultChecked
                            />
                            <label
                              id="supertab"
                              onClick={() => this.toggleTab(1)}
                              style={{ border: "1px solid lightgray" }}
                              className="j-tabs-label"
                              htmlFor="tab1"
                              title="Training Topic"
                            >
                              <span>
                                &nbsp;&nbsp;Training Topic&nbsp;&nbsp;
                              </span>
                            </label>
                            <input id="tab9" type="radio" name="tabs" />
                            <label
                              id="pendingtab"
                              onClick={() => this.toggleTab(2)}
                              style={{ border: "1px solid lightgray" }}
                              className="j-tabs-label"
                              htmlFor="tab9"
                              title="Designation Based Topic"
                            >
                              <span>
                                &nbsp;Designation Based Topic&nbsp;&nbsp;
                              </span>
                            </label>
                          </div>
                          <br />

                          <div className="contentss-tabs">
                            <div
                              className={
                                toggleState === 1
                                  ? "contentss  active-contentss"
                                  : "contentss"
                              }
                            >
                              {this.state.DepartModel ? (
                                <AddTrainingpop
                                  close={() => this.DepartModel()}
                                />
                              ) : null}
                              <form onSubmit={this.handleSubmit}>
                                <div className="card-block">
                                  <div className="row">
                                    <div className="col-lg-2">
                                      <Link
                                        to="./AddTrainingpop"
                                        type="button"
                                        style={{
                                          width: "150px",
                                          background: "white",
                                        }}
                                        className="btn"
                                      >
                                        Add{" "}
                                      </Link>
                                    </div>
                                    <div className="col-lg-2">
                                      <label
                                        htmlFor="files"
                                        className="btn"
                                        id="addBtn"
                                        name="TrainingFile"
                                      >
                                        Import From Excel
                                      </label>
                                      <input
                                        id="files"
                                        type="file"
                                        accept=".xlsx"
                                        style={{ display: "none" }}
                                        onChange={this.handleUpload}
                                        name="TrainingFile"
                                      />
                                    </div>
                                    <div className="col-lg-2">
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() =>
                                          this.exportXlsx(filename)
                                        }
                                      >
                                        Export to excel
                                      </button>
                                    </div>

                                    <OutTable
                                      data={this.state.rows}
                                      columns={this.state.cols}
                                      tableClassName="ExcelTable2007"
                                      tableHeaderRowclassName="heading"
                                    />

                                    <div className="col-lg-2">
                                      {/* href={TrainingXL} */}

                                      <a
                                        className="btn"
                                        href={TrainingXL}
                                        type="button"
                                        style={{ color: "#5035ce" }}
                                      >
                                        <i
                                          title="Download Template"
                                          className="icofont icofont-download"
                                        ></i>{" "}
                                        Download
                                      </a>
                                    </div>
                                    <div className="col-lg-4 ">
                                      <input
                                        className="Searchbox"
                                        placeholder="Search"
                                        onClick={() => {
                                          this.setState({
                                            Filterpopstatus1: true,
                                          });
                                        }}
                                        style={{ float: "right" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {this.state.initialData !== undefined ? (
                                  <ReactExcel
                                    initialData={this.state.initialData}
                                    onSheetUpdate={(currentSheet) =>
                                      this.setState({
                                        CurrentSheet: currentSheet,
                                      })
                                    }
                                    activeSheetClassName="active-sheet"
                                    reactExcelClassName="react-excel"
                                    name="TrainingFile"
                                  />
                                ) : null}
                                {this.state.initialData !== undefined ? (
                                  <div className="col-lg-12">
                                    <div className="savebtndiv">
                                      <button
                                        className="btn"
                                        id=""
                                        type="submit"
                                        style={{ width: "150px" }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                              </form>

                              <div className="card-block2">
                                <form onSubmit={this.Multidelete}>
                                  <button
                                    type="submit"
                                    className="multitrash"
                                    id="delete"
                                    style={{
                                      float: "right",
                                      visibility: "hidden",
                                    }}
                                  >
                                    <i
                                      className="icofont icofont-delete-alt"
                                      style={{ color: "darkgray" }}
                                    ></i>
                                  </button>
                                  <div>
                                    {" "}
                                    <span
                                      id="deletecount"
                                      className="multitrash"
                                      style={{
                                        visibility: "hidden",
                                        fontSize: "17px",
                                      }}
                                    ></span>
                                  </div>
                                  <div
                                    id="selectalldiv"
                                    style={{ visibility: "hidden" }}
                                  ></div>
                                  <br />
                                  <br />
                                  <div className="dt-responsive table-responsive table-res">
                                    {this.state.Training.length === 0 ? null : (
                                      <table
                                        id="DepSheet"
                                        className="table nowrap"
                                        style={{ maxWidth: "100%" }}
                                      >
                                        <thead>
                                          <tr>
                                            <th>Department</th>
                                            <th>Training Topic</th>
                                            <th>Description</th>
                                            <th>Duration</th>
                                            <th>Documents</th>
                                            <th>Trainers</th>
                                            <th>Designation</th>
                                            <th
                                              style={{ textAlign: "center" }}
                                            ></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Training.map((Training) => (
                                            <tr>
                                              <td>{Training.department}</td>
                                              <td>{Training.trainingitem}</td>
                                              <td>{Training.description}</td>
                                              <td>{Training.duration}</td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {Training.filecount}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {Training.trainercount}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {Training.designationcount}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                <button
                                                  className="button-30 sk-edit"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.Edit1(
                                                      Training.training_Id
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-info"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>{" "}
                                                &nbsp;&nbsp;&nbsp;
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </form>
                              </div>

                              <table
                                id="TrainingSheet"
                                className="table nowrap"
                                style={{ maxWidth: "100%", display: "none" }}
                              >
                                <thead>
                                  <tr>
                                    <th>Department</th>
                                    <th>Training Item</th>
                                    <th>Description</th>
                                    <th>Documents</th>
                                    <th>Trainers</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Training.map((Training) => (
                                    <tr>
                                      <td>{Training.department}</td>
                                      <td>{Training.trainingitem}</td>
                                      <td>{Training.description}</td>
                                      <td>5</td>
                                      <td>3</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div
                              className={
                                toggleState === 2
                                  ? "contentss  active-contentss"
                                  : "contentss"
                              }
                            >
                              <div className="card-block">
                                <div className="row">
                                  <div className="col btnalign">
                                    <Link
                                      to="./AddTrainingpop"
                                      type="button"
                                      style={{
                                        width: "150px",
                                        background: "white",
                                      }}
                                      className="btn "
                                    >
                                      Add{" "}
                                    </Link>
                                  </div>
                                  <div className="col btnalign">
                                    <button
                                      className="btn"
                                      type="button"
                                      onClick={() => this.exportXlsx(filename)}
                                    >
                                      Export to excel
                                    </button>
                                  </div>

                                  <OutTable
                                    data={this.state.rows}
                                    columns={this.state.cols}
                                    tableClassName="ExcelTable2007"
                                    tableHeaderRowclassName="heading"
                                  />

                                  <div className="col btnalign">
                                    <a
                                      className="btn"
                                      href={TrainingXL}
                                      type="button"
                                      style={{ color: "#5035ce" }}
                                    >
                                      <i
                                        title="Download Template"
                                        className="icofont icofont-download"
                                      ></i>{" "}
                                      Download
                                    </a>
                                  </div>
                                  <div className="col btnalign"></div>
                                  <div className="col-lg-4">
                                    <input
                                      className="Searchbox"
                                      placeholder="Search"
                                      onClick={() => {
                                        this.setState({
                                          Filterpopstatus: true,
                                        });
                                      }}
                                      style={{ float: "right" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="card-block2">
                                <button
                                  type="submit"
                                  className="multitrash"
                                  id="delete"
                                  style={{
                                    float: "right",
                                    visibility: "hidden",
                                  }}
                                >
                                  <i
                                    className="icofont icofont-delete-alt"
                                    style={{ color: "darkgray" }}
                                  ></i>
                                </button>
                                <div>
                                  {" "}
                                  <span
                                    id="deletecount"
                                    className="multitrash"
                                    style={{
                                      visibility: "hidden",
                                      fontSize: "17px",
                                    }}
                                  ></span>
                                </div>
                                <div
                                  id="selectalldiv"
                                  style={{ visibility: "hidden" }}
                                ></div>
                                <br />
                                <br />
                                <div className="dt-responsive table-responsive table-res">
                                  <>
                                    <table
                                      id="DepSheet"
                                      className="table nowrap"
                                      style={{ maxWidth: "100%" }}
                                    >
                                      {this.state.FilteredDesignation.map(
                                        (Trainings) => (
                                          <>
                                            <tr>
                                              <th>
                                                <input
                                                  disabled
                                                  className="designationMenu"
                                                  value={
                                                    Trainings.designationName
                                                  }
                                                ></input>
                                              </th>
                                              <td>
                                                <button
                                                  className="button-20 sk-edit"
                                                  onClick={() =>
                                                    this.Detail(
                                                      Trainings.record,
                                                      Trainings.designationName
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="icofont icofont-plus"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              </td>
                                            </tr>
                                            <>
                                              <tr>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Department
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Training Topic
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Description
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Documents
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Trainers
                                                </th>
                                              </tr>
                                              {Trainings.record.map(
                                                (Training) => (
                                                  <tr>
                                                    <td>
                                                      {Training.department}
                                                    </td>
                                                    <td>
                                                      {Training.trainingitem}
                                                    </td>
                                                    <td>
                                                      {Training.description}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {Training.filecount}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {Training.trainercount}
                                                    </td>

                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <button
                                                        className="button-30"
                                                        type="button"
                                                        style={{
                                                          color:
                                                            "rgb(255, 0, 0)",
                                                        }}
                                                        onClick={() =>
                                                          this.DeleteButton(
                                                            Training.training_Id,
                                                            Training.desigId
                                                          )
                                                        }
                                                      >
                                                        <i className="icofont icofont-minus"></i>
                                                      </button>
                                                      &nbsp;
                                                      <button
                                                        className="button-30 sk-edit"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.Edit1(
                                                            Training.training_Id
                                                          );
                                                        }}
                                                      >
                                                        <i
                                                          className="fa fa-info"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </button>
                                                      &nbsp;&nbsp;&nbsp;
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </>
                                          </>
                                        )
                                      )}
                                    </table>
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="pop">
            <div
              className="popinnernormal"
              style={{
                width: "71%",
                height: "80vh",
                overflowY: "auto",
              }}
            >
              {" "}
              <div className="row" style={{ padding: "8px" }}>
                <div className="col-md-6" style={{ marginLeft: "230px" }}>
                  <b>{this.state.popupdesignation}</b>
                </div>
                <div className="col-sm-3">
                  <button
                    style={{
                      backgroundColor: "#a0a3a305",
                      color: "#797070",
                      border: "1px solid #a3a5a505",
                      marginRight: "-255px",
                    }}
                    onClick={this.closePopup}
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="col-sm-8" style={{ maxWidth: "100.666667%" }}>
                  <div
                    className="dt-responsive table-responsive table-res"
                    style={{ overflowX: "hidden" }}
                  ></div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <div className="row" style={{ padding: "8px" }}>
                    <div className="col-sm-3">
                      <select
                        className="effect-16"
                        type="text"
                        name="Department"
                        value={this.state.dept}
                        id="Department"
                        onChange={this.handleChange}
                      >
                        <option value="">Designation</option>
                        {this.state.PopupFilteredValue.map((popValue) => (
                          <option value={popValue.designation}>
                            {popValue.designation}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-sm-3">
                      <select
                        className="effect-16"
                        type="text"
                        value={this.state.selectedJobRole}
                        name="JobRole"
                        id="JobRole"
                        onChange={this.handleChange1}
                      >
                        <option value="">Department</option>
                        {this.state.PopupFilteredValue.map((popValue) => (
                          <option value={popValue.department}>
                            {popValue.department}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-sm-3">
                      <select
                        className="effect-16"
                        type="text"
                        value={this.state.selectedJobRole}
                        name="JobRole"
                        id="JobRole"
                        onChange={this.handleChange2}
                      >
                        <option value="">Training Topic</option>
                        {this.state.PopupFilteredValue.map((popValue) => (
                          <option value={popValue.trainingitem}>
                            {popValue.trainingitem}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-sm-3">
                      <select
                        className="effect-16"
                        type="text"
                        value={this.state.selectedJobRole}
                        name="JobRole"
                        id="JobRole"
                        onChange={this.handleChange3}
                      >
                        <option value="">Description</option>
                        {this.state.PopupFilteredValue.map((popValue) => (
                          <option value={popValue.description}>
                            {popValue.description}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="dt-responsive table-responsive table-res">
                    {this.state.PopupDropDownFiltered === "" ? (
                      <>
                        {this.state.PopupFilteredValue.length === 0 ? (
                          "No records"
                        ) : (
                          <table
                            id="DepSheet"
                            className="table nowrap"
                            style={{ maxWidth: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  <input
                                    type="checkbox"
                                    onChange={this.handleCheckAll}
                                    checked={selectAllChecked}
                                  />
                                </th>
                                <th>Designation</th>
                                <th>Department</th>
                                <th>Training Topic</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {this.state.PopupDropDownFiltered.map((Training) => ( */}
                              {this.state.PopupFilteredValue.map((Training) => (
                                <tr>
                                  <td>
                                    <input
                                      type="checkbox"
                                      value={Training.desigId}
                                      onChange={this.handleCheckboxChange}
                                      checked={
                                        selectedCheckboxes[Training.desigId] ||
                                        false
                                      }
                                    />
                                    {/* {Training.desigId} */}
                                  </td>
                                  <td>{Training.designation}</td>
                                  <td>{Training.department}</td>
                                  <td>{Training.trainingitem}</td>
                                  <td>{Training.description}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </>
                    ) : (
                      <>
                        {this.state.PopupFilteredValue.length === 0 ? (
                          "No records"
                        ) : (
                          <table
                            id="DepSheet"
                            className="table nowrap"
                            style={{ maxWidth: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  <input
                                    type="checkbox"
                                    onChange={this.handleCheckAll}
                                    checked={selectAllChecked}
                                  />
                                </th>
                                <th>Designation</th>
                                <th>Department</th>
                                <th>Training Topic</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {this.state.PopupDropDownFiltered.map((Training) => ( */}
                              {this.state.PopupDropDownFiltered.map(
                                (Training) => (
                                  <tr>
                                    <td>
                                      <input
                                        type="checkbox"
                                        value={Training.desigId}
                                        onChange={this.handleCheckboxChange}
                                        checked={
                                          selectedCheckboxes[
                                            Training.desigId
                                          ] || false
                                        }
                                      />
                                      {/* {Training.desigId} */}
                                    </td>
                                    <td>{Training.designation}</td>
                                    <td>{Training.department}</td>
                                    <td>{Training.trainingitem}</td>
                                    <td>{Training.description}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        )}
                      </>
                    )}
                  </div>
                  <div style={{ marginLeft: "-90px", marginTop: "15px" }}>
                    <button
                      className="btn"
                      type="button"
                      onClick={this.handleClick}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        )}
      </div>
    );
  }
}
