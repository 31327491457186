import React, { Component } from "react";
import { useLocation } from 'react-router-dom';
import SideBar from "./Sidebar";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import GroupPop from "./GroupPop";
import GroupTitlePopup from "./GroupTitlePopup";
import LastGroup from "./LastGroup";
import LastChatpop from "./LastChatpop";
import "../Css/Chat.css";
import axios from "axios";
import ReactTimeAgo from "react-time-ago";
import Picker from "emoji-picker-react";
import GroupMember from "../Assets/images/addmemgroup.png";
import Swal from "sweetalert2";
import DefaultProfile from "../Assets/images/profile.png";
import onlinedot from "../Assets/images/online-dot.png";
import offlinedot from "../Assets/images/offline-dot.png";
import onlineimg from "../Assets/images/online.png";
import offlineimg from "../Assets/images/offlineimg.png";
import delImg from "../Assets/images/deleteIconA.png";
import doubletick from "../Assets/images/double-check.webp";
import singletick from "../Assets/images/single-check.png";
import File1 from "../Assets/images/document.png";
import { BASE_URLs } from "../Config/Base";
import ChatPicturepop from "./ChatPicturepop";
import ChatDocPop from "./ChatDocPop";
import * as signalR from '@microsoft/signalr';
import ImageModal from "./ImageModel";
export class Chatbox extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      // isBox3Open: props.location.state?.isBox3Open || false,
      isButton1Visible: false,
      isButton2Visible: false,
      isButton3Visible: true,
      isBackArrowVisible: false,
      isBackArrowVisibleA: false,
      isBackArrowVisibleB: false,
      AddSkillModel: false,
      isBox1Open: false,
      SearchModel: false,
      isBox2Open: false,
      selectedUsers: [],
      selectedUsersA: [],
      EditSkillModel: false,
      EditSkillModelEntity: false,
      Lastgrpchatmodel: false,
      EntityModel: false,
      EditSkillValues: [],
      picture_Path: null,
      document_Path: null,
      EditSkillValuesEntity: [],
      Announcement: [],
      AllAnnouncement: [],
      EntityUser: [],
      EntityUserlist: [],
      checkedItems: [],
      showResults: false,
      discussionValue: "",
      discussionNameValue: "",
      LastChatModel: false,
      selectedUserIds: [],
      selectedUserName: [],
      User: [],
      OATUser: [],
      OATAlluser: [],
      message: [],
      Popmsg: [],
      ReceivepopMsg: [],
      selectedDocumentName: "",
      active: false,
      chosenEmoji: null,
      showEmoji: false,
      setShowEmoji: "",
      LastGroupopen: true,
      email: "",
      firstname: "",
      Addmembers: [],
      AllAddmembers: [],
      groupName: "",
      istitlepopOpen: false,
      privacy: "",
      Memberslist: [],
      fileUploadState: "",
      EImage: "",
      selectedOption: "",
      error: "",
      showFirstHeader: true,
      shouldResetHeader: false,
      ReceivepopMsgUser: [],
      Name: "",
      Email: "",
      Chatpop: false,
      UserId: "",
      Team: [],
      Teams: [],
      Access: "",
      checkBox1: false,
      checkBox2: false,
      checkBox3: false,
      checkBox4: false,
      externalAcc: false,
      allowAcc1: true,
      allowAcc2: "",
      Filterpop: false,
      EntityShow: false,
      EntityShowOff: false,
      Entity: [],
      EName: "",
      EId: "",
      EntityUsers: [],
      AllAccess: [],
      DName: false,
      MemberAccess: [],
      AllMemberAccess: [],
      FrdMemberAccess: [],
      FrdAllMemberAccess: [],
      NonAccess: [],
      FrdNonAccess: [],
      AllFrdNonAccess: [],
      checking: false,
      toggleStates: {},
      selectedEntities: {},
      UPic: "",
      attachMenu: false,
      Picturepopup: false,
      isdocumentpopOpen: false,
      ispicturepopOpen: false,
      OneonOneAdmin: [],
      MsgMenu: false,
      Deletepop: false,
      deletesender: "",
      delparticularId: "",
      deletedate: "",
      deleteEvery: "",
      deletemsg: "",
      Forwardpic: "",
      Forwarddoc: "",
      deleteId: "",
      Minimized: false,
      InputDisabled: false,
      hubConnection: null,
      isModalOpen: false,
      ImageSrc: []
    };

    this.inputReference = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmojiClick = this.handleEmojiClick.bind(this);
    this.myEmoji = this.myEmoji.bind(this);
    this.formRef = React.createRef();
    this.chatBoxRef = React.createRef();
    this.firstInputRef = React.createRef();
    this.searchForDiscRef = React.createRef();
    this.attachMenu = this.attachMenu.bind(this);
    this.deleteMenu = this.deleteMenu.bind(this);
  }
  closefuncdisc = () => {
    this.setState({ Filterpop: false });
    var AllMemberlist = this.state.AllMemberAccess;
    this.setState({ MemberAccess: AllMemberlist, showResults: true });
  };
  handleChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }

  formatDateAA1 = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000; 
    const oneWeekAgo = new Date(currentDate.getTime() - 7 * oneDay);

    const isToday =
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isYesterday =
      inputDate.getDate() === currentDate.getDate() - 1 &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isBeforeYesterday =
      inputDate.getTime() > oneWeekAgo.getTime() && !isYesterday;

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const options1 = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    if (isToday) {
      return "Today";
    } else if (isYesterday) {
      return "Yesterday";
    } else if (isBeforeYesterday) {
      const formattedDate = inputDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      return `${formattedDate}`;
    } else {
      return inputDate.toLocaleString("en-US", options1);
    }
  };

  GetMemberAccess() {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    axios.get(`${BASE_URLs}/Chat/GetAccessMemberUsers`, options)
      .then((response) => {
        this.setState({
          MemberAccess: response.data,
          AllMemberAccess: response.data,
          FrdMemberAccess: response.data,
          FrdAllMemberAccess: response.data,
        });
        // // // console.log("Access Admin", response.data);
      })
      .catch((error) => {
        // console.error("Error fetching MemberAccess:", error);
      });
  
    setInterval(1000);
  }
  
  GetNonAccess() {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    axios.get(`${BASE_URLs}/Team/GetAccessMembers`, options)
      .then((response) => {
        this.setState({
          NonAccess: response.data,
          AllNonAccess: response.data,
          FrdNonAccess: response.data,
          AllFrdNonAccess: response.data
        });
        // // // console.log("NonAccess Admin", response.data);
      })
      .catch((error) => {
        // console.error("Error fetching NonAccess:", error);
      });
  }


  UpdateLocalStorage() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Profile/GetUpdateAccessDetails`, options)
      .then((response) => {
        // Assuming response.data is an array of objects
        response.data.forEach((d) => {
          localStorage.setItem("access", d.access);
          localStorage.setItem("accessed_to", d.accessed_to);
        });
      })
      .catch((error) => {
        // Handle errors here
        // console.error("Error fetching update access details:", error);
      });
  }
  

  handleDocumentChange = (event) => {
    const selectedDocument = event.target.files[0];
    this.setState({
      document_Path: selectedDocument,
      selectedDocumentName: selectedDocument.name,
    });
  };

  handleChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  myEmoji = () => {
    const changeState = this.state.showEmoji;
    if (changeState) {
      this.setState({
        showEmoji: false,
      });
    } else {
      this.setState({
        showEmoji: true,
        attachMenu: false
      });
    }
  };
  handleEmojiClick = (emojiObject) => {
    this.setState((prevState) => ({
      message: prevState.message + emojiObject.emoji,
      showEmoji: false,
    }));
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  handleTextChange = (event) => {
    this.setState({
      message: event.target.value,
      deletemsg: event.target.value,
    });
  };
  handleFileChange = (event) => {
    this.setState({ picture_Path: event.target.files[0] });
  };
  handleSubmit = (data, email, firstName, event) => {
  const { message, picture_Path, document_Path } = this.state;
  if (!message.toString().trim() && !picture_Path && !document_Path) {
    event.preventDefault();
    alert("Please enter a message, select an image, or choose a document.");
  } else {
    event.preventDefault();

    const formData = new FormData();
    this.setState({
      InputDisabled: true,
    });
    formData.append('data', data);

    axios.post(`${BASE_URLs}/Contact/OneOnOne`, formData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        this.GetmsgPop(email);
        this.GetReceivemsgPop(firstName, email);
        this.setState({
          message: "",
          picture_Path: null,
          document_Path: null,
          MsgMenu: false,
          InputDisabled: false,
        });
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }
};

  scrollIntoView() {
    this.myRef.current?.scrollIntoView();
  }
  scrollToBottom = () => {
    if (this.chatBoxRef.current) {
      this.chatBoxRef.current.scrollTop = this.chatBoxRef.current.scrollHeight;
    }
  };
  GetForwardMembers = () => {
    axios({
      method: "POST",

      url: `${BASE_URLs}/Chat/GetForwardMembers`,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
      })
      .catch((error) => {
      });
  };
  GetAllMembers(Id, GroupName, Profile) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Contact/GetGroupMembers?Id=${Id}`, options)
      .then((response) => {
        const data = response.data;
  
        localStorage.setItem("AllGroupMember", JSON.stringify(data));
  
        const UserId = JSON.parse(localStorage.userId);
  
        let Member = localStorage.getItem("AllGroupMember");
        let Members = JSON.parse(Member);
        let lMembers = Members.map((e) => e.user_id);
        let Membersstr = lMembers.toString();
  
        this.setState({
          Memberslist: Membersstr,
        });
  
        this.SubmitStartMessage(Id, GroupName, Profile);
        setTimeout(() => {
          this.SubmitStartAddMessage(Id, GroupName, Profile);
        }, 10);
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  }

  handleUserSelectionChange = (event) => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    const { value, checked } = event.target;
    this.setState((prevState) => {
      let selectedUsers = [...prevState.selectedUsers];
      let selectedUserIds = [...prevState.selectedUserIds];
      let selectedUsersA = [...prevState.selectedUsersA];
      let selectedUserName = [...prevState.selectedUserName];
      if (checked) {
        const userToAdd = prevState.Addmembers.find(
          (user) => user.email === value
        );
        if (userToAdd) {
          selectedUsers.push(userToAdd);
          selectedUserIds.push(userToAdd.email);
        }

        const userNameToAdd = prevState.Addmembers.find(
          (user) => user.email === value
        );
        if (userNameToAdd) {
          let Both = userNameToAdd.name;
          selectedUsersA.push(userNameToAdd);
          selectedUserName.push(Both);
        }
      } else {
        const userNameToAdd = prevState.Addmembers.find(
          (user) => user.email === value
        );

        selectedUsers = selectedUsers.filter((user) => user.email !== value);
        selectedUserIds = selectedUserIds.filter((email) => email !== userNameToAdd.email);

        selectedUsersA = selectedUsersA.filter((user) => user.email !== value);
        selectedUserName = selectedUserName.filter(
          (name) => name !== userNameToAdd.name
        );
      }

      return { selectedUsers, selectedUserIds, selectedUserName };
    });
  };
  handleDiscussionChange = (event) => {
    const value = event.target.value;
    this.setState({
      discussionValue: value,
      discussionNameValue: value, // Update the discussion name value
    });
  };

  handleUserSelect = (id) => {
    this.setState((prevState) => {
      const selectedUserIds = prevState.selectedUsers.map((user) => user.id);
      if (selectedUserIds.includes(id)) {
        return {
          selectedUsers: prevState.selectedUsers.filter(
            (user) => user.id !== id
          ),
        };
      }
      const userToAdd = prevState.Addmembers.find((user) => user.id === id);
      return {
        selectedUsers: [...prevState.selectedUsers, userToAdd],
      };
    });
  };
  handleUserRemove = (email, name) => {
    this.setState((prevState) => {
      const updatedSelectedUsers = prevState.selectedUsers.filter(
        (user) => user.email !== email
      );
      const updatedSelectedUserIds = prevState.selectedUserIds.filter(
        (userId) => userId !== email
      );
      const updatedSelectedUserName = prevState.selectedUserName.filter(
        (userName) => userName !== name
      );

      return {
        selectedUsers: updatedSelectedUsers,
        selectedUserIds: updatedSelectedUserIds,
        selectedUserName: updatedSelectedUserName,
      };
    });
  };
  handleCheckboxChange = (event) => {
    const itemId = parseInt(event.target.value);
    const isChecked = event.target.checked;

    if (isChecked) {
      this.setState((prevState) => ({
        checkedItems: [...prevState.checkedItems, itemId],
      }));
    } else {
      this.setState((prevState) => ({
        checkedItems: prevState.checkedItems.filter((id) => id !== itemId),
      }));
    }
  };
  hideBox2 = () => {
    document.getElementById("Searchbox2").style.display = "none";
    this.setState({
      selectedUsers: [],
      selectedUserIds: [],
      selectedUserName: [],
    });
  };
  handleButton1Click = () => {
    this.setState({
      isButton1Visible: false,
      isButton2Visible: false,
      isBackArrowVisible: true,
      LastChatModel: false,
      EntityModel: true,
    });
  };
  handleButton2Click = () => {
    this.setState({
      isButton1Visible: false,
      isButton2Visible: false,
      isButton3Visible: true,
      showFirstHeader: true,
      isBackArrowVisibleA: true,
      isBackArrowVisibleB: true,
      isBackArrowVisible: false,
      LastChatModel: false,
      isBox1Open: false,
      isBox2Open: false,
      LastGroupopen: true,
    });
  };
  handleButton3Click = () => {
    this.setState({
      isButton1Visible: false,
      isButton2Visible: false,
      isButton3Visible: false,
      showFirstHeader: true,
      isBackArrowVisibleA: true,
      isBackArrowVisible: false,
      LastGroupopen: false,
      isBox3Open: true,
    });
  };

  AccessOpen = () => {
    this.setState({
      isButton1Visible: false,
      isButton2Visible: false,
      isButton3Visible: false,
      showFirstHeader: true,
      isBackArrowVisibleA: true,
      isBackArrowVisible: false,
      LastGroupopen: false,
      isBox3Open: false,
      Access: true,
    });
  };
  handleButton4Click = () => {
    this.setState({
      isButton1Visible: false,
      isButton2Visible: false,
      isButton3Visible: false,
      showFirstHeader: true,
      isBackArrowVisibleA: true,
      isBackArrowVisible: false,
      LastGroupopen: false,
      isBox3Open: true,
      isBox4Open: !this.state.isBox4Open,
      selectedUsers: [],
    });
  };

  ClearUsers = () => {
    this.setState({
      selectedUsers: [],
      selectedUserIds: [],
      selectedUserName: [],
      Addmembers: [],
    });
  };

  handleBackArrowClick = () => {
    this.setState({
      isButton1Visible: true,
      isButton2Visible: true,
      isButton3Visible: false,
      showFirstHeader: true,
      isBackArrowVisible: false,
      AddSkillModel: false,
      isBox1Open: false,
      isBox2Open: false,
      isBox3Open: false,
      isBox4Open: false,
      LastChatModel: true,
      EntityModel: false,
      SearchModel: false,
    });
  };
  CheckboxClear = () => {
    this.setState({
      selectedUsers: [],
      selectedUserIds: [],
      selectedUserName: [],
    });
  };
  handleBackArrowClick2 = () => {
    this.setState({
      selectedUsers: [],
      selectedUserIds: [],
      selectedUserName: [],
    });
    this.setState({
      shouldFirstHeader: true,
      checkBox1: false,
      checkBox2: false,
      checkBox3: false,
      checkBox4: false,
      displayForm: false,
      Chatpop: false,
    });

    this.setState({
      isButton1Visible: false,
      isButton2Visible: false,
      isButton3Visible: true,
      isBackArrowVisibleA: false,
      isBackArrowVisibleB: false,
      AddSkillModel: false,
      showResults: false,
      isBox1Open: false,
      isBox2Open: false,
      isBox3Open: false,
      isBox4Open: false,
      LastGroupopen: true,
      Access: false,
    });
  };

  handleBackArrowClick3 = () => {
    this.setState({
      isButton1Visible: false,
      isButton2Visible: false,
      isButton3Visible: true,
      showFirstHeader: true,
      isBackArrowVisibleA: true,
      isBackArrowVisibleB: true,
      isBackArrowVisible: false,
      LastChatModel: false,
      isBox1Open: false,
      isBox2Open: false,
      isBox3Open: false,
      isBox4Open: false,
      LastGroupopen: true,
      Access: false,
    });
  };
  AddSkillModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }

    this.setState((prevState) => ({
      AddSkillModel: !prevState.AddSkillModel,
    }));
  };

  AddGroupModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }

    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");

        document

          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState((prevState) => ({
      AddGroupModel: !prevState.AddGroupModel,
    })); 
  };

  GetAddmembers() {
    
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Chat/GetallMembers`, options)
      .then((response) => {
        const data = response.data;
  
        if (Array.isArray(data)) {
          this.setState({
            Addmembers: data,
            AllAddmembers: data,
          });
        } else {
        }
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  
    // setInterval(1000);
  }
  GetAccessMembers() {
    const { EName, EId } = this.state;
  
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      }
    };
  
    const data = {
      entity: EName,
      id: EId,
    };
  
    axios.post(`${BASE_URLs}/Chat/Accessmembers`, data, config)
      .then((response) => {
        // Handle response data if needed
        // this.setState({ Access: response.data });
        // // // console.log("Accessss", response.data);
        this.setState({
          EntityShow: false,
          EntityShowOff: false,
        });
        Swal.fire({
          // title: "Access Given",
          text: "Access Given Successfully",
          icon: "success",
          confirmButtonText: "Done",
        });
        this.Team1();
        window.location.reload(true);
      })
      .catch((error) => {
        // Handle error
        // console.error('Error:', error);
      });
  }

  GetAccessMembersOff(entityName, id) {
    const { EName, EId } = this.state;
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.post(`${BASE_URLs}/Chat/AccessmembersOff`, {
      entity: EName,
      id: EId,
    }, options)
    .then((response) => {
      // Handle response data here
    })
    .catch((error) => {
      // Handle errors here
    })
    .finally(() => {
      this.setState({
        EntityShow: false,
        EntityShowOff: false,
      });
      Swal.fire({
        // title: "Access Given",
        text: "Access Turned off Successfully",
        icon: "success",
        confirmButtonText: "Done",
      });
      window.location.reload(true);
    });
  }

  GetAccessMembersDenied(entityName, id) {
    const { EName, EId } = this.state;
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.post(`${BASE_URLs}/Chat/AccessmembersDenied`, {
      entity: EName,
      id: EId,
    }, options)
    .then((response) => {
      // Handle response data here
      // // console.log("AccessmembersDenied", response.data);
    })
    .catch((error) => {
      // Handle errors here
    })
    .finally(() => {
      this.setState({
        EntityShow: false,
        EntityShowOff: false,
      });
      Swal.fire({
        // title: "Access Given",
        text: "Access Denied Successfully",
        icon: "success",
        confirmButtonText: "Done",
      });
      document.getElementById('EAccessPopA1').style.display = "none";
    });
  }

  GetAccessMembers1(members_Id) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    Swal.fire({
      title: "Click Ok to Confirm",

      text: "For Giving Access",

      icon: "warning",

      showCancelButton: true,

      confirmButtonColor: "#3085d6",

      cancelButtonColor: "#d33",

      confirmButtonText: "Yes!",
    });
  }

  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/AddOrganization/status1`, options)
      .then((response) => {
        const data = response.data;
  
        this.setState({
          OATUser: data,
          OATAlluser: data,
        });
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }

  SearchModel = (id, uid, cid, em, cn) => {
    this.setState({
      EntityModel: false,
    });
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      SearchValues: {
        id: id,
        userId: uid,
        company_Id: cid,
        email: em,
        company_Name: cn,
      },
    });
    this.setState((prevState) => ({
      SearchModel: !prevState.SearchModel,
    }));
  };

  GetEntityUser(AdminEmail) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };
  
    axios.get(`${BASE_URLs}/UserBank/OatUser?AdminEmail=${AdminEmail}`, options)
      .then((response) => {
        if (!response.status === 200) {
          throw new Error("Error: " + response.status);
        }
        return response.data;
      })
      .then((data) => {
        this.setState({
          EntityUser: data,
          EntityUserlist: data,
          showResults: true,
        });
      })
      .catch((error) => {
      });
  }

  backentity = () => {
    this.setState({
      EntityModel: true,
      SearchModel: false,
    });
  };

  GetAnnouncement(Id) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/UserBank/GetAllUser?Id=${Id}`, options)
      .then((response) => {
        const data = response.data;
        this.setState({
          Announcement: data,
          AllAnnouncement: data,
        });
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }

  myFunctionAll = (e) => {
    clearInterval(this.interval);
    const MemberAccess = this.state.AllMemberAccess.filter(
      (item) =>
        item.firstName.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ MemberAccess, showResults: true });

    const NonAccess = this.state.AllNonAccess.filter(
      (item) =>
        item.firstName
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );

    this.setState({ NonAccess, showResults: true });

    if (NonAccess.length === 0 && MemberAccess.length === 0) {
      document.getElementById("DFilterErr").innerHTML = " Member Not Found";
    } else {
      document.getElementById("DFilterErr").innerHTML = "";
    }


    const Announcement = this.state.AllAnnouncement.filter(
      (item) =>
        item.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Announcement });
    const OATUser = this.state.OATAlluser.filter((item) =>
      item.company_Name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ OATUser });
  };

  LettermyFunctionAll = (letter, e) => {
    e.preventDefault();
    const Addmembers = this.state.AllAddmembers.filter(
      (item) =>
        item.name.toLowerCase().startsWith(letter.toLowerCase()) ||
        item.email.toLowerCase().startsWith(letter.toLowerCase())
    );
    this.setState({ Addmembers, showResults: true });

    if (Addmembers.length === 0) {
      document.getElementById("DFilterErr").innerHTML = " Member Not Found";
    } else {
      document.getElementById("DFilterErr").innerHTML = "";
    }

    const Announcement = this.state.AllAnnouncement.filter(
      (item) =>
        item.firstName.toLowerCase().includes(letter.toLowerCase()) ||
        item.email.toLowerCase().includes(letter.toLowerCase())
    );
    this.setState({ Announcement });
    const OATUser = this.state.OATAlluser.filter((item) =>
      item.company_Name.toLowerCase().includes(letter.toLowerCase())
    );
    this.setState({ OATUser });
  };

  myFunctionEntity = (e) => {
    const EntityUser = this.state.EntityUserlist.filter(
      (item) =>
        item.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ EntityUser });
  };
  myFunction1 = (e) => {
    const Announcement = this.state.AllAnnouncement.filter(
      (item) =>
        item.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Announcement });
  };
  EntityFilter = (e) => {
    const Announcement = this.state.AllAnnouncement.filter(
      (item) =>
        item.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.adminName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Announcement, showResults: true });
  };
  componentDidMount() {
    this.checkShouldClickButton3();
      this.GetAnnouncement();
    this.GetMessages();
    this.GetAddmembers();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    this.UserOrg();
    this.setState({ letters: alphabet });
    this.handleClick();
    this.Team();
    this.Team1();
    this.GetMemberAccess();
    this.GetNonAccess();

    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${BASE_URLs}/chatHub`) // Replace with your SignalR hub URL
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          // console.log('SignalR Connected');

          this.state.hubConnection.on('ReceiveMessage', (user, message) => {
            // console.log(`New message received: ${message} from ${user}`);
            this.setState(prevState => ({
              message: [...prevState.message, { user, message }]
            }));
          });
        })
        .catch(error => {}
          //  console.error('SignalR Connection Error:', error)
          );
    });

    this.OneononeUser();

    var Id = JSON.parse(localStorage.getItem("userId"));
    this.setState({ UserId: Id });

      if (!this.state.Filterpop) {
        Promise.all([this.GetAddmembers(), this.UpdateLocalStorage()])
          .then(results => {
          })
          .catch(error => {
          });
      }


    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.checkShouldClickButton3();
    }
  }

  checkShouldClickButton3() {
    const { location } = this.props;
    if (location.state && location.state.shouldClickButton3) {
      this.handleButton3Click();
      this.getDetails(location.state.FirstName,location.state.UserName,location.state.pic,location.state.senderId,location.state.particularId)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }


  handleClickOutside = (event) => {
    if (
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target)
    ) {
      this.setState({
        Deletepop: false,

        showEmoji: false,
        MsgMenu: false,
        attachMenu: false,
      });
    }
  };
  Team() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Team/GetActiveMembers`, options)
      .then((response) => {
        const data = response.data;
        this.setState({
          EntityMembers: data,
          AllEntityMembers: data,
        });
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }
  OneononeUser() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/UserBank/GetAllUserPop`, options)
      .then((response) => {
        const data = response.data;
        this.setState({
          OneonOne: data,
          OneonOneCount: data,
          User: [],
          Alluser1: [],
          Deleted: [],
        });
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }

  handleFilter = (letter, event) => {
    event.preventDefault();
    const Addmembers = this.state.AllAddmembers.filter((item) => {
      return item.name.charAt(0).toLowerCase() === letter.toLowerCase();
    });
    this.setState({ Addmembers, showResults: true });
    const Announcement = this.state.AllAnnouncement.filter(
      (item) =>
        item.firstName
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(event.target.value.toLowerCase())
    );
    this.setState({ Announcement });
    const OATUser = this.state.OATAlluser.filter((item) =>
      item.company_Name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    this.setState({ OATUser });
  };

  printChecked() {
    var items = document.getElementsByName("inputCheck");
    var arr = [];
    for (var i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox" && items[i].checked == true) {
        arr.push(items[i].value);
      }
    }
    localStorage.setItem("ddvalue", arr);
    var arr = localStorage.getItem("ddvalue");
    arr = arr.split(",");
    var selectedItems = "";
    for (var i = 0; i < arr.length; i++) {
      selectedItems += "<br/>" + arr[i];
      document.getElementById("result").innerHTML = selectedItems;
    }
    return true;
  }
  EditSkillModel = (e, fn) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditSkillValues: {
        id: e,
        firstName: fn,
      },
    });
    this.setState((prevState) => ({
      EditSkillModel: !prevState.EditSkillModel,
    }));
  };
  EditSkillModelEntity = (e, fn, al, uid, rmsg, em, mb) => {
    this.GetmsgPop(em);
    this.GetReceivemsgPop(fn);

    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditSkillValuesEntity: {
        id: e,
        firstName: fn,
        allmessage: al,
        usermsgId: uid,
        receivemsg: rmsg,
        email: em,
        displayName: mb,
      },
    });
    this.setState((prevState) => ({
      EditSkillModelEntity: !prevState.EditSkillModelEntity,
    }));
  };
  Lastgrpchatmodel = () => {
    this.setState((prevState) => ({
      Lastgrpchatmodel: !prevState.Lastgrpchatmodel,
    }));
  };
  LastChatModel = () => {
    this.setState((prevState) => ({
      LastChatModel: !prevState.LastChatModel,
    }));
  };

  handletitle = () => {
    var GroupName = document.getElementById("Group-Title").value;
    if (!GroupName) {
      document.getElementById("titleerr").innerHTML =
        "Discussion Name Required!";
    } else {
      document.getElementById("titleerr").innerHTML = " ";
    }
  };
  handleSubmitTitle = (event) => {
    event.preventDefault();
    let String_User = this.state.selectedUserIds.toString();
    var GroupName = document.getElementById("Group-Title").value;
    let BASE_URL = JSON.parse(localStorage.email);
    let comma = ",";
    let String_UserA = `${String_User}${comma}${BASE_URL}`;

    if (!GroupName) {
      document.getElementById("titleerr").innerHTML =
        "Discussion Name Required!";
    } else {
      document.getElementById("titleerr").innerHTML = " ";
    }

    if (!String_UserA) {
      alert("You Need to Add Atleast One Member to start Group Discussion");
    }

    if (GroupName && String_UserA) {
      const data = {
        user_id: String_UserA,
        group_Name: GroupName,
        Privacy: 0,
        ImagePath: this.state.fileUploadState,
      };

      axios({
        method: "POST",
        url: `${BASE_URLs}/Chat/CreateGroup`,
        data: data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {
          if (response.data.status === "Failure") {
            alert("Discussion Name Already Exist");
          }

          if (response.data.status === "Success") {
            Swal.fire({
              position: "center",
              icon: "success",

              text: "Discussion Created",

              showConfirmButton: false,

              timer: 3000,
              customClass: "Group-swal-container",
            });

            this.closePopup();
            this.GetGroupId(GroupName);

            this.setState({
              showResults: false,
              displayForm: false
            });
          }
        })
        .catch((error) => {
          alert("failed !");
        });
    }
  };
  SubmitStartMessage = (Id, GroupName, Profile) => {
    const data = {
      Group_Name: GroupName,
      Group_id: Id,
      GroupMember: this.state.Memberslist,
      Profile: Profile,
    };

    axios({
      method: "POST",
      url: `${BASE_URLs}/contact/GroupStartMessage`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        setTimeout(() => {
          this.setState({
            isBox1Open: false,
            isBox2Open: false,
            isBox3Open: false,
            isBox4Open: false,
            LastGroupopen: true,
            isButton3Visible: true,
            showFirstHeader: false,
            EditSkillModel: true,
            istitlepopOpen: false,
            isBackArrowVisibleA: false,
            Access: false,
            EditSkillValues: {
              groupId: Id,
              groupName: GroupName,
            },
          });
        }, 1000);
      })
      .catch((error) => {
        alert("failed !");
      });
  };

  SubmitStartAddMessage = (Id, GroupName, Profile) => {
    let String_User = this.state.selectedUserName.toString();
    const data = {
      Group_Name: GroupName,
      Group_id: Id,
      GroupMember: this.state.Memberslist,
      Message: String_User,
      Profile: Profile,
    };

    axios({
      method: "POST",
      url: `${BASE_URLs}/contact/GroupAddMemberMessage`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        this.setState({
          selectedUsers: [],
          selectedUserIds: [],
          selectedUserName: [],
        });
      })
      .catch((error) => {
        alert("failed !");
      });
  };
  GetGroupId(GroupName) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Contact/GetGroupId?GroupName=${GroupName}`, options)
      .then((response) => {
        const data = response.data;
        localStorage.setItem("GroupData", JSON.stringify(data));
        let groupdetail = localStorage.getItem("GroupData");
        let group = JSON.parse(groupdetail);
        let groupid = group.map((e) => e.group_id);
        let profile = group.map((e) => e.profile_Pic);
        let Profile = profile[0];
        let Id = groupid[0];
  
        this.GetAllMembers(Id, GroupName, Profile);
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }

  formclick() {
    document.getElementById("formbtn").click();
  }

  handleButtonClick() {
    this.setState((prevState) => ({
      isBox1Open: !prevState.isBox1Open,
      isBox2Open: !prevState.isBox2Open,
    }));

    setTimeout(() => {
      document.getElementById("Searchbox2").style.display = "none";
    }, 5);
  }
  GetmsgPop(UserName) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Contact/GetmsgPop?UserName=${UserName}`, options)
      .then((response) => {
        if (!response.status === 200) {
          throw new Error("Error: " + response.status);
        }
        return response.data;
      })
      .then((data) => {
        const DataLength = this.state.Popmsg.length;
  
        this.setState({
          Popmsg: data,
        }, () => {
          if (data.length > DataLength) {
            this.scrollToBottom();
          }
        });
  
        this.setState({
          Popmsg: data,
        });
        this.setState({
          OneonOneAdmin: UserName,
        });
      })
      .catch((error) => {
      });
  }

  EntityShowPop = (entityName, id) => {

    this.setState((prevState) => {
      const toggleStates = { ...prevState.toggleStates };
      const selectedEntities = Array.isArray(prevState.selectedEntities)
        ? [...prevState.selectedEntities]
        : [];
      toggleStates[id] = !toggleStates[id];

      if (toggleStates[id]) {
        selectedEntities.push({ id, entityName });

        const entityNames = selectedEntities.map(entity => entity.entityName).join(','); // Convert array to string
        const entityId = selectedEntities.map(entity => entity.id).join(','); // Convert array to string

        this.setState({
          EName: entityNames,
          EId: entityId,
        });
      } else {
        const indexToRemove = selectedEntities.findIndex(entity => entity.id === id);
        selectedEntities.splice(indexToRemove, 1);
        const SelectedEntitiesA = selectedEntities.length > 0;
        this.setState({
          EntityShow: SelectedEntitiesA,
          toggleStates,
          selectedEntities,
        });
      }


      return { toggleStates, selectedEntities };
    });
  };

  AllEntityShow = (entityName, id) => {

    this.setState((prevState) => {
      const toggleStates = { ...prevState.toggleStates };
      const selectedEntities = Array.isArray(prevState.selectedEntities)
        ? [...prevState.selectedEntities]
        : [];
      toggleStates[id] = !toggleStates[id];

      if (toggleStates[id]) {
        selectedEntities.push({ id, entityName });

        const entityNames = selectedEntities.map(entity => entity.entityName).join(','); // Convert array to string
        const entityId = selectedEntities.map(entity => entity.id).join(','); // Convert array to string

        this.setState({
          EntityShow: true,
          EName: entityNames,
          EId: entityId,
        });
      } else {
        const indexToRemove = selectedEntities.findIndex(entity => entity.id === id);
        selectedEntities.splice(indexToRemove, 1);
        const SelectedEntitiesA = selectedEntities.length > 0;
        this.setState({
          EntityShow: SelectedEntitiesA,
          toggleStates,
          selectedEntities,
        });
      }


      return { toggleStates, selectedEntities };
    });
  };


  GetReceivemsgPop(FirstName, UserName) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Contact/GetReceivemsgPop?FirstName=${FirstName}&Email=${UserName}`, options)
      .then((response) => {
        if (!response.status === 200) {
          throw new Error("Error: " + response.status);
        }
        return response.data;
      })
      .then((data) => {
        const DataLength = this.state.ReceivepopMsg.length;
  
        this.setState({
          ReceivepopMsg: data,
        }, () => {
          if (data.length > DataLength) {
            this.scrollToBottom();
          }
        });
      })
      .catch((error) => {
      });
  }

  GetMessages() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Contact/GetSendmsg`, options)
      .then((response) => {
        const data = response.data;
        if (Array.isArray(data)) {
          this.setState({
            Messages: data,
          });
        } else {
        }
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }
  Successpopclose = () => {
    this.setState({
      isButton1Visible: true,
      isButton2Visible: true,
      isBackArrowVisible: false,
      EntityModel: false,
      EditSkillModelEntity: false,
      LastChatModel: true,
    });
  };

  OATSearch = (e) => {
    const OATUser = this.state.OATAlluser.filter((item) =>
      item.company_Name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ OATUser });
  };

  TeamSearch = (e) => {
    const Entity = this.state.Teams.filter((item) =>
      item.entityName.toLowerCase().startsWith(e.target.value.toLowerCase())
    );

    this.setState({ Entity });
  };
  LetterTeamSearch = (letter, e) => {
    e.preventDefault();
    const Entity = this.state.Teams.filter((item) =>
      item.entityName.toLowerCase().startsWith(letter.toLowerCase())
    );
    this.setState({ Entity });
    if (Entity.length === 0) {
      document.getElementById("FilterErr").innerHTML = "Entity Not Found";
    } else {
      document.getElementById("FilterErr").innerHTML = "";
    }
  };
  toggleTitlePopupOpen = () => {
    {
      this.state.selectedUsers.length == 0
        ? alert("Select Any User")
        : this.setState({
          istitlepopOpen: true,
        });
    }
  };
  toggleTitlePopup = () => {
    this.setState({
      istitlepopOpen: false,

      EImage: DefaultProfile,
    });
  };

  publicbtn = (event) => {
    this.setState({
      selectedOption: event.target.value,
      error: "",
    });
    Swal.fire({
      title: "Are you sure",
      text: "Continue with Public chat?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
    });
    this.setState({
      privacy: 1,
    });
  };
  privatebtn = (event) => {
    this.setState({
      selectedOption: event.target.value,
      error: "",
    });

    Swal.fire({
      title: "Are you sure",
      text: "Continue with Private chat?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
    });

    this.setState({
      privacy: 0,
    });
  };
  fileUploadAction = () => this.inputReference.current.click();
  fileUploadInputChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return;
    }

    if (!(selectedFile instanceof Blob || selectedFile instanceof File)) {
      return;
    }

    this.setState({
      fileUploadState: selectedFile,
      EImage: URL.createObjectURL(selectedFile),
    });
  };
  openPopup = (e) => {
    e.preventDefault();
    var users = this.state.selectedUsers;
    if (users.length === 0) {
      alert("Choose Discussion Members");
    } else {
      this.setState({ isPopupOpen: true });
    }
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  handleClick = () => {
    this.GetMemberAccess();
    this.GetNonAccess();
    this.setState(
      (prevState) => ({
        showFirstHeader: !prevState.showFirstHeader,
      })
    );
  };

  GetReceivemsgPopUser(FirstName, UserName) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Contact/GetReceivemsgPopUser?FirstName=${FirstName}&Email=${UserName}`, options)
      .then((response) => {
        if (!response.status === 200) {
          throw new Error("Error: " + response.status);
        }
        return response.data;
      })
      .then((data) => {
        const DataLength = this.state.ReceivepopMsgUser.length;
  
        this.setState({
          ReceivepopMsgUser: data,
        }, () => {
          if (data.length > DataLength) {
            this.scrollToBottom();
          }
        });
        this.setState({
          ReceivepopMsgUser: data,
        });
  
      })
      .catch((error) => {
      });
  }
  getDetails = (FirstName, UserName, pic, senderId, particularId) => {
    if (this.state.Chatpop) {
      this.setState({
        Name: FirstName,
        Email: UserName,
        UPic: pic,
        attachMenu: false,
        showEmoji: false,
        MsgMenu: false,
        Deletepop: false,
        Picturepopup: false,
        Documentpopup: false
      }, () => {
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      });

      this.GetReceivemsgPop(FirstName, UserName);
      this.GetReceivemsgPopUser(FirstName, UserName);
      this.GetmsgPop(UserName);
      this.GetAddmembers();
      this.GetNonAccess();

      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => {
        this.GetReceivemsgPop(FirstName, UserName);
        this.GetReceivemsgPopUser(FirstName, UserName);
        this.GetmsgPop(UserName);
        this.markMessageAsSeenAdmin(
          senderId, particularId
        );
        this.markMessageAsSeenUser(senderId, particularId
        );
      }, 1000);

    } else {
      this.setState({
        Chatpop: true,
        Name: FirstName,
        Email: UserName,
        UPic: pic,
        attachMenu: false,
        showEmoji: false,
        MsgMenu: false,
        Deletepop: false,
        Picturepopup: false,
        Documentpopup: false
      }, () => {
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      });

      this.GetReceivemsgPop(FirstName, UserName);
      this.GetReceivemsgPopUser(FirstName, UserName);
      this.GetmsgPop(UserName);
      this.GetAddmembers();
      this.GetNonAccess();

      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => {
        this.GetReceivemsgPop(FirstName, UserName);
        this.GetReceivemsgPopUser(FirstName, UserName);
        this.GetmsgPop(UserName);
        this.markMessageAsSeenAdmin(
          senderId, particularId
        );
        this.markMessageAsSeenUser(senderId, particularId
        );
      }, 1000);
    }
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  hideChatpop = () => {
    this.setState({
      Chatpop: false,
      MsgMenu: false,
      Deletepop: false,
      attachMenu: false,
      showEmoji: false,
    });
    var inputvalue = document.getElementById('searchInputvalue').value

    if (inputvalue !== null) {
      document.getElementById("DFilterErr").innerHTML = "";
      document.getElementById('searchInputvalue').value = '';
      this.GetMemberAccess();
      this.GetNonAccess();
    }
    else {
    }
  };
  Formdisplay = () => {
    this.setState((prevState) => ({
      displayForm: !prevState.displayForm,
      Chatpop: false,
    }));

    var inputvalue = document.getElementById('searchInputvalue').value

    if (inputvalue !== null) {
      document.getElementById("DFilterErr").innerHTML = "";
      document.getElementById('searchInputvalue').value = '';
      this.GetMemberAccess();
      this.GetNonAccess();
    }
    else {
    }

  };
  HideFormdisplay = () => {
    this.setState((prevState) => ({
      displayForm: false,
      selectedUsers: [],
      selectedUserIds: [],
      selectedUserName: [],
      Addmembers: [],
    }));


    var inputvalue = document.getElementById('searchInputvalue').value

    if (inputvalue !== null) {
      document.getElementById("DFilterErr").innerHTML = "";
      document.getElementById('searchInputvalue').value = '';
      this.GetMemberAccess();
      this.GetNonAccess();
    }
    else {
    }

  };
  handleSubmitA = (event) => {
    const { message, picture_Path, document_Path } = this.state;
    if (!message.toString().trim() && !picture_Path && !document_Path) {
      event.preventDefault();
      alert("Please enter a message, select an image, or choose a document.");
    } else {
      event.preventDefault();
      const formData = new FormData(this.formRef.current);

      this.setState({
        InputDisabled: true,
      });
  
      // Now you can access form data values from formData
      for (const [key, value] of formData.entries()) {
        // // // console.log(`Field name: ${key}, Field value: ${value}`);
      }
  
      axios.post(`${BASE_URLs}/Contact/OneOnOne`, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        var FirstName = this.state.Name;
        var UserName = this.state.Email;
        this.GetReceivemsgPop(FirstName, UserName);
  
        this.GetmsgPop(UserName);
        this.setState({
          message: "",
          picture_Path: null,
          document_Path: null,
          InputDisabled: false,
        });
      })
      .catch((error) => {
        // console.error('Error occurred while sending data:', error);
      });
    }
  };
  

  markMessageAsSeenUser = (senderId, particularId) => {
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      data: {
        senderId: senderId,
        particularId: particularId,
      },
    };
  
    axios.post(`${BASE_URLs}/Notification/MsgSeenUser`, options)
      .then((response) => {

      })
      .catch((error) => {

      });
  };

  markMessageAsSeenAdmin = (senderId, particularId) => {
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      data: {
        senderId: senderId,
        particularId: particularId,
      },
    };
  
    axios.post(`${BASE_URLs}/Notification/MsgcountSeenAdmin`, options)
      .then((response) => {

      })
      .catch((error) => {

      });
  };

  AccessSetting = () => {
    this.setState({
      Access: !this.state.Access,
    });
  };

  Team1() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Team/GetEntity`, options)
      .then((response) => {
        const data = response.data;
        data.sort((a, b) => parseInt(a.teamId) - parseInt(b.teamId));
        this.setState({
          Entity: data,
          Teams: data,
        });
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }

  HandleAllowAccessExternal1 = () => {
    this.setState({
      allowAcc1: "0",
      allowAcc2: "1",
      checkBox1: true,
      checkBox2: false,
      checkBox3: false,
      checkBox4: false,
    });
  };

  HandleAllowAccessExternal2 = () => {
    this.setState({
      allowAcc1: "0",
      allowAcc2: "1",
      checkBox1: false,
      checkBox2: true,
      checkBox3: false,
      checkBox4: false,
    });
  };
  HandleAllowAccessExternal3 = () => {
    this.setState({
      allowAcc1: "0",
      allowAcc2: "1",
      checkBox1: false,
      checkBox2: false,
      checkBox3: true,
      checkBox4: false,
    });
  };
  HandleAllowAccessExternal4 = () => {
    this.setState({
      allowAcc1: "0",
      allowAcc2: "1",
      checkBox1: false,
      checkBox2: false,
      checkBox3: false,
      checkBox4: true,
    });
  };

  EntityShow = (entityName, id) => {
    this.setState((prevState) => {
      const toggleStates = { ...prevState.toggleStates };
      const selectedEntities = Array.isArray(prevState.selectedEntities)
        ? [...prevState.selectedEntities]
        : [];
      toggleStates[id] = !toggleStates[id];

      if (toggleStates[id]) {
        selectedEntities.push({ id, entityName });

        const entityNames = selectedEntities.map(entity => entity.entityName).join(','); // Convert array to string
        const entityId = selectedEntities.map(entity => entity.id).join(','); // Convert array to string

        this.setState({
          EntityShow: true,
          EName: entityNames,
          EId: entityId,
        });
      } else {
        const indexToRemove = selectedEntities.findIndex(entity => entity.id === id);
        selectedEntities.splice(indexToRemove, 1);
        const SelectedEntitiesA = selectedEntities.length > 0;
        this.setState({
          EntityShow: SelectedEntitiesA,
          toggleStates,
          selectedEntities,
        });
      }


      return { toggleStates, selectedEntities };
    });
  };


  EntityShowOff = (entityName, id) => {
    this.setState((prevState) => {
      const toggleStates = { ...prevState.toggleStates };
      const selectedEntities = Array.isArray(prevState.selectedEntities)
        ? [...prevState.selectedEntities]
        : [];
      toggleStates[id] = !toggleStates[id];

      if (toggleStates[id]) {
        selectedEntities.push({ id, entityName });

        const entityNames = selectedEntities.map(entity => entity.entityName).join(','); // Convert array to string
        const entityId = selectedEntities.map(entity => entity.id).join(','); // Convert array to string
        this.setState({
          EntityShowOff: true,
          EName: entityNames,
          EId: entityId,
        });

      } else {

        const indexToRemove = selectedEntities.findIndex(entity => entity.id === id);
        selectedEntities.splice(indexToRemove, 1);
        const SelectedEntitiesA = selectedEntities.length > 0;
        this.setState({
          EntityShowOff: SelectedEntitiesA,
          toggleStates,
          selectedEntities,
        });

      }

      return { toggleStates, selectedEntities };
    });
  };

  AllowAllEntity() {
    const { EName, EId } = this.state;
    Swal.fire({
      text: "Are you sure wants to give access to all Entity?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      customClass: {
        title: "swal-title",
        content: "swal-text",
        confirmButton: "swal-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
          data: {
            entity: EName,
            id: EId,
          },
        };
  
        axios.post(`${BASE_URLs}/Chat/AllowAllAccess`, options)
          .then((response) => {
            const data = response.data;
            if (data) {
              Swal.fire({
                title: "Access Given to All Entities",
                text: "Successfully",
                icon: "success",
                confirmButtonText: "Done",
              });
              // // console.log("Accessssed", data);
              this.setState({
                AllAccess: data,
              });
              window.location.reload(true);
            } else {
              // console.error("Received data:", data);
            }
          })
          .catch((error) => {
            // console.error('Error:', error);
          });
      }
    });
  }

  documentName = (dn) => {
    this.setState({
      DName: true,
      documentname: dn,
    });
  };
  documentNameL = () => {
    setTimeout(() => {
      this.setState({
        DName: false,
      });
    }, 500);
  };

  formatDateAA = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const oneWeekAgo = new Date(currentDate.getTime() - 7 * oneDay);

    const isToday =
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isYesterday =
      inputDate.getDate() === currentDate.getDate() - 1 &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isBeforeYesterday =
      inputDate.getTime() > oneWeekAgo.getTime() && !isYesterday;

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    if (isToday) {
      const timeOnly = inputDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return `${timeOnly}`;
    } else if (isYesterday) {
      return `${inputDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;
    } else if (isBeforeYesterday) {
      const formattedTime = inputDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return `${formattedTime}`;
    } else {
      return inputDate.toLocaleString("en-US", options);
    }
  };

  picturePopup = () => {
    this.setState({
      ispicturepopOpen: false,
      Picturepopup: false,
    });
    var FirstName = this.state.Name;
    var UserName = this.state.Email;
    var Email = this.state.MailId;
    this.GetmsgPop(UserName);
    this.GetReceivemsgPop(FirstName, Email);
    this.GetReceivemsgPopUser(FirstName, Email);
  };
  picturepop() {
    this.setState({ Picturepopup: !this.state.Picturepopup });
  }
  hidepicturepop = () => {
    this.setState({
      Picturepopup: false,
    });
  };
  hidedocumentpop = () => {
    this.setState({
      Documentpopup: false,
    });
  };
  documentPopup = () => {
    this.setState({
      isdocumentpopOpen: false,
      Documentpopup: false,
    });
    var FirstName = this.state.Name;
    var UserName = this.state.Email;
    var Email = this.state.MailId;
    this.GetmsgPop(UserName);
    this.GetReceivemsgPop(FirstName, Email);
    this.GetReceivemsgPopUser(FirstName, Email);
  };
  documentpop() {
    this.setState({ Documentpopup: !this.state.Documentpopup });
  }

  attachMenu() {
    this.setState({
      attachMenu: !this.state.attachMenu,
      showEmoji: false,
      Addpopup: false,
      showMenu: false,
      Deletepop: false,
      MsgMenu: false,
    });
  }

  Picturepop() {
    this.setState({
      Picturepopup: !this.state.Picturepopup,
      ispicturepopOpen: true,
      attachMenu: false,
    });

    localStorage.setItem("fileee", JSON.stringify(this.state.OneonOneAdmin));
  }

  Documentpop() {
    this.setState({
      Documentpopup: !this.state.Documentpopup,
      isdocumentpopOpen: true,
      attachMenu: false,
    });
    localStorage.setItem("fileee", JSON.stringify(this.state.OneonOneAdmin));
    localStorage.setItem("UNamee", JSON.stringify(this.state.Name));
  }

  DisplayMenu(id, Imsg, Ipic, Idoc, Isd, Icd, ParticularId, Ist) {

    document.getElementById("input-groupEdit").style.display = "none";
    document.getElementById("input-group write-msg").style.display = "flex";
    this.setState({
      MsgMenu: !this.state.MsgMenu,
      deleteId: id,
      Forwardpic: Ipic,
      Forwarddoc: Idoc,
      deletesender: Isd,
      deletedate: Icd,
      Deletepop: false,
      attachMenu: false,
      deleteEvery: id,
      deletemsg: Imsg,
      delparticularId: ParticularId,
      Time: Ist,
    });
  }

  EditOneonOneMessage = () => {
    document.getElementById("input-groupEdit").style.display = "none";
    document.getElementById("input-group write-msg").style.display = "flex";
    const data = {
      ParticularId: this.state.delparticularId,
      Message: this.state.deletemsg,
      SenderId: this.state.deletesender,
      SysDateTimeWithSeconds: this.state.Time,
    };
    axios({
      method: "POST",
      url: `${BASE_URLs}/Chat/EditOneonOneMessage`,
      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        this.setState({ message: "", MsgMenu: false, deletemsg: "" });
        var FirstName = this.state.Name;
        var UserName = this.state.Email;
        var Email = this.state.MailId;
        this.GetReceivemsgPopUser(FirstName, Email);
        this.GetmsgPop(UserName);

        this.setState({ EditInput: false })
      })

      .catch((error) => {
      });
  };

  DeleteMessageOne = () => {
    const data = {
      Id: this.state.deleteId,
      UserName: this.state.Email,
    };

    Swal.fire({
      text: "Are you sure wants to Delete Message ?",

      icon: "warning",

      showCancelButton: true,

      confirmButtonColor: "#3085d6",

      cancelButtonColor: "#d33",

      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "POST",

          url: `${BASE_URLs}/Chat/DeleteMessageOne`,

          data: data,

          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "multipart/form-data",

            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        })
          .then((response) => {

            var FirstName = this.state.Name;
            var UserName = this.state.Email;
            var Email = this.state.MailId;
            this.GetmsgPop(UserName);
            this.GetReceivemsgPop(FirstName, Email);
            this.GetReceivemsgPopUser(FirstName, Email);

            this.setState({
              Deletepop: false,
            });
          })

          .catch((error) => {
          });
      }

      if (result.isDismissed) {
        this.setState({
          Deletepop: false,
        });
      }
    });
  };

  GetForwardmembers() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}/Chat/GetallMembers`, options)
      .then((response) => {
        const data = response.data;
        if (Array.isArray(data)) {
          this.setState({
            Forwardmembers: data,
            AllForwardmembers: data,
            Addmembers: data,
            AllAddmembers: data,
          });
        } else {
        }
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }
  forwardopenpop = () => {
    this.setState({
      forwardpopup: !this.state.forwardpopup,

      MsgMenu: false,
    });
    this.GetMemberAccess();
    this.GetNonAccess();
  };

  hideForwardpop = () => {
    this.setState({
      forwardpopup: false,
      selectedUserIds: [],
    });
    this.GetMemberAccess();
    this.GetNonAccess();
  };

  deleteMenu() {
    this.setState({
      Deletepop: !this.state.Deletepop,
      MsgMenu: false,
    });
  }
  handleClose = () => {
    this.setState({
      Deletepop: false,
    });
  };
  handleClose1 = () => {
    document.getElementById('EAccessPopA1').style.display = "none"
  };
  ICDeleteMessageEveryone = () => {
    const data = {
      UserName: this.state.Email,

      SenderId: this.state.deletesender,
      DateTimeSec: this.state.Time,

      Message: this.state.deletemsg,

      PicturePath: this.state.Forwardpic,

      DocumentPath: this.state.Forwarddoc,

    };

    Swal.fire({

      text: "Are you sure wants to Delete Message?",

      icon: "warning",

      showCancelButton: true,

      confirmButtonColor: "#3085d6",

      cancelButtonColor: "#d33",

      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "POST",

          url: `${BASE_URLs}/contact/ICDelMsgEveryone`,

          data: data,

          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "multipart/form-data",

            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        })
          .then((response) => {

            var FirstName = this.state.Name;
            var UserName = this.state.Email;
            var Email = this.state.MailId;
            this.GetmsgPop(UserName);
            this.GetReceivemsgPop(FirstName, Email);
            this.GetReceivemsgPopUser(FirstName, Email);

            this.setState({
              Deletepop: false,
            });
          })

          .catch((error) => {
          });
      }

      if (result.isDismissed) {
        this.setState({
          Deletepop: false,
        });
      }
    });
  };

  ICMuteDiscpop(e) {
    e.preventDefault();
    this.setState({
      ICMuteDisc: !this.state.MuteDisc,
    });
  }

  EditMessage = () => {
    this.setState({ EditInput: true })
    document.getElementById("input-groupEdit").style.display = "flex";
    document.getElementById("input-group write-msg").style.display = "none";
  }

  CancelEdit() {
    document.getElementById("input-groupEdit").style.display = "none";
    document.getElementById("input-group write-msg").style.display = "flex";
  }

  handleKeyPressUser = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  
    if (event.charCode === 13 && !this.state.EditInput) {
      const { message, picture_Path, document_Path } = this.state;
      if (!message.toString().trim() && !picture_Path && !document_Path) {
        alert("Please enter a message, select an image, or choose a document.");
      } else {
        event.preventDefault();
  
        const formData = new FormData(this.formRef.current);

        for (const [key, value] of formData.entries()) {
        }
  
        axios.post(`${BASE_URLs}/Contact/Receiversend`, formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            var FirstName = this.state.Name;
            var UserName = this.state.Email;
            this.GetReceivemsgPopUser(FirstName, UserName);
            this.GetmsgPop(UserName);
            this.setState({
              message: "",
              picture_Path: null,
              document_Path: null,
            });
          })
          .catch((error) => {
            // console.error("Error:", error);
          });
      }
    }
  
    if (event.charCode === 13 && this.state.EditInput) {
      document.getElementById("input-groupEdit").style.display = "none";
      document.getElementById("input-group write-msg").style.display = "flex";
      const data = {
        ParticularId: this.state.delparticularId,
        Message: this.state.deletemsg,
        SenderId: this.state.deletesender,
        SysDateTimeWithSeconds: this.state.Time,
      };
  
      axios.post(`${BASE_URLs}/Chat/EditOneonOneMessage`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {
          this.setState({ message: "", MsgMenu: false, deletemsg: "" });
          var FirstName = this.state.Name;
          var UserName = this.state.Email;
          var Email = this.state.MailId;
          this.GetReceivemsgPopUser(FirstName, Email);
          this.GetmsgPop(UserName);
          this.setState({ EditInput: false });
        })
        .catch((error) => {
          // console.error("Error:", error);
        });
    }
  };

  ForwardIChatMsg = (event) => {
    event.preventDefault();

    let ParticularId = this.state.selectedUserIds.toString();

    if (!ParticularId) {
      alert("Choose Any Member");
    } else {
      const data = {
        Message: this.state.deletemsg,

        Picture_Path: this.state.Forwardpic,

        Document_Path: this.state.Forwarddoc,

        UserName: ParticularId,
      };

      axios({
        method: "POST",

        url: `${BASE_URLs}/contact/OneonOneFwdMessage`,

        data: data,

        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {

          this.setState({
            forwardpopup: false,
            selectedUserIds: [],
          });
          Swal.fire({
            position: "center",
            icon: "success",
            text: "Forwarded",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            customClass: 'IC-swal-container'
          });
          var FirstName = this.state.Name;
          var UserName = this.state.Email;
          var Email = this.state.MailId;
          this.GetmsgPop(UserName);
          this.GetReceivemsgPop(FirstName, Email);
          this.GetReceivemsgPopUser(FirstName, Email);
          this.GetMemberAccess();
          this.GetNonAccess();
        })

        .catch((error) => {

          alert("failed !");
        });
    }
  };

  toggleMinimize = () => {
    this.setState((prevState) => ({
      Minimized: !prevState.Minimized,
    }));
  };
  Addmemberfilter = (e) => {
    const MemberAccess = this.state.AllMemberAccess.filter(
      (item) =>
        item.firstName.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ MemberAccess, showResults: true });
    const NonAccess = this.state.AllNonAccess.filter(
      (item) =>
        item.firstName
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ NonAccess, showResults: true });

    if (NonAccess.length === 0 && MemberAccess.length === 0) {
      document.getElementById("ForwardFilterErr").innerHTML = " Member Not Found";
    } else {
      document.getElementById("ForwardFilterErr").innerHTML = "";
    }
  };

  Forwardmemberfilter = (e) => {
    const FrdMemberAccess = this.state.FrdAllMemberAccess.filter(
      (item) =>
        item.firstName.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ FrdMemberAccess, showResults: true });
    const FrdNonAccess = this.state.AllFrdNonAccess.filter(
      (item) =>
        item.firstName
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );

    this.setState({ FrdNonAccess, showResults: true });

  };
  handleOpenModal = (imagesrc) => {
    this.setState({
      isModalOpen: true,
      ImageSrc: imagesrc
    })
  }

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false
    })
  }
  render() {
    var username = JSON.parse(localStorage.uname);

    var EMaiL = JSON.parse(localStorage.email);

    var entity = JSON.parse(localStorage.entityName)

    const {
      isButton1Visible,
      isButton2Visible,
      isButton3Visible,
      isBackArrowVisible,
      isBackArrowVisibleA,
      EditSkillModelEntity,
      isBackArrowVisibleB,
      showFirstHeader,
      isBox1Open,
      isBox2Open,
      isBox3Open,
      isBox4Open,
      Announcement,
      EntityUser,
      users,
      showResults,
      checkedItems,
      User,
      MsgMenu,
      OATUser,
      selectedUsers,
      selectedUserIds,
      selectedUserName,
      LastGroupopen,
      EntityModel,
      error,
      Popmsg,
      message,
      picture_Path,
      document_Path,
      ReceivepopMsg,
      showEmoji,
      SearchModel,
      email,
      firstname,
      Addmembers,
      istitlepopOpen,
      groupName,
      privacy,
      Memberslist,
      displayForm,
      ReceivepopMsgUser,
      Name,
      UserId,
      Team,
      Entity,
      Access,
      externalAcc,
      Filterpop,
      EName,
      EId,
      Chatpop,
      DName,
      documentname,
      MemberAccess,
      FrdMemberAccess,
      NonAccess,
      FrdNonAccess,
      UPic,
      isdocumentpopOpen,
      ispicturepopOpen,
      Deletepop,
      Deleted,
      Minimized,
      isModalOpen
    } = this.state;

    const { discussionValue, discussionNameValue } = this.state;
    const messages = [...Popmsg, ...ReceivepopMsg, ...ReceivepopMsgUser];
    messages.sort(
      (a, b) => new Date(a.createDateTime) - new Date(b.createDateTime)
    );
    messages.sort((a, b) => a.id - b.id);

    let seenCreateDateTime = {}; // to keep track of createDateTime

    for (let i = 0; i < messages.length; i++) {
      const currentObject = messages[i];

      if (seenCreateDateTime[currentObject.createDateTime.split("T")[0]]) {
        if (
          seenCreateDateTime[currentObject.createDateTime.split("T")[0]] !==
          currentObject.displayName
        ) {
          messages[i].rowNum = 2;
        }
      } else {
        seenCreateDateTime[currentObject.createDateTime.split("T")[0]] =
          currentObject.displayName;
      }
    }

    const chatpop = {
      display: this.state.Chatpop ? "block" : "none",
      backgroundColor: '#5e616a',
      padding: '10px 5px 5px 15px',
      height: '57px'
    };

    const accesspopstyle = {
      position: "absolute",
      alignItems: "center",
      zIndex: "10",
      backgroundColor: "white",
      padding: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: 'block',
      cursor: "pointer",
      borderRadius: "9px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };

    const Accesspop = {
      position: "absolute",
      zIndex: "100",
      top: "300px",
      left: "45%",
      backgroundColor: "white",
      display: this.state.Access ? "block" : "none",
      cursor: "pointer",
      width: "20%",
      height: "25%",
      borderRadius: "5px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
    };

    const MessageMenu = {
      position: "absolute",
      top: "-30px",
      alignItems: "center",
      zIndex: "1",
      width: "140px",
      backgroundColor: "#eff3f7",
      padding: "10px",
      boxShadow: "rgb(0 0 0 / 20%) 0px 2px 5px",
      display: this.state.MsgMenu ? "block" : "none",
      cursor: "pointer",
      borderRadius: "none",
    };

    const attachStyles = {
      position: "absolute",
      marginTop: "-122px",
      alignItems: "center",
      backgroundColor: "#eff3f7",
      padding: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: this.state.attachMenu ? "block" : "none",
      cursor: "pointer",
      borderRadius: "9px",

    };

    const deleteStyles = {
      position: "absolute",
      alignItems: "center",
      zIndex: "2",
      backgroundColor: "#eff3f7",
      padding: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: this.state.Deletepop ? "block" : "none",
      cursor: "pointer",
      borderRadius: "9px",
      marginTop: '-270px',
    };

    const picturepop = {
      display: this.state.Picturepopup ? "block" : "none",
    };

    const documentpop = {
      display: this.state.Documentpopup ? "block" : "none",
    };

    var Accessed = JSON.parse(localStorage.access);
    var Accessed_to = JSON.parse(localStorage.accessed_to);

    const regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    return (
      <div>
        <SideBar active={window.location.pathname} />

        {this.state.EditSkillModelEntity ? (
          <div className="row">
            <div className="col-lg-6">

              <div
                className="pop"
                style={{ backgroundColor: "transparent", marginLeft: "28%" }}
              >
                <form
                  id="contactForm"
                  autoComplete="off"
                  encType="multipart/form-data"
                  onSubmit={(e) => {
                    e.preventDefault();
                    const data = new FormData(e.target);
                    this.handleSubmit(
                      data,
                      this.state.EditSkillValuesEntity.email,
                      this.state.EditSkillValuesEntity.firstName
                    );
                  }}
                >
                  <ul className="text-right boxs">
                    <li
                      className="chat-single-box card-shadow active"
                      data-id="1"
                      style={{ marginTop: "-128px" }}
                    >
                      <div
                        className="had-container"
                        id="successclose"
                        style={{ width: "970px", marginLeft: "-910px" }}
                      >
                        <div
                          className="chat-header p-10 bg-gray"
                          style={{ backgroundColor: "rgb(156 145 244)" }}
                        >
                          <div className="user-info d-inline-block f-left">
                            <div
                              className="Namepass"
                              style={{ marginTop: "1px" }}
                            >
         
                              <input
                                type="hidden"
                                name="UserName"
                                defaultValue={
                                  this.state.EditSkillValuesEntity.email
                                }
                              />
                              <span
                                className="userNames"
                                defaultValue={
                                  this.state.EditSkillValuesEntity.firstName
                                }
                              >
                                <a
                                  href="javascript:void(0);"
                                  title="Me"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    color: "white",
                                    textShadow: "2px 2px #6f60e7",
                                  }}
                                >
                                  <img
                                    src="assets/images/avatar-1.png"
                                    className="img-circle img-chat-profile"
                                    alt="Me"
                                  />
                                  &nbsp;&nbsp;
                                  {this.state.EditSkillValuesEntity.firstName}
                                </a>
                              </span>
                            </div>
                          </div>
                          <div className="box-tools d-inline-block">
                            <button
                              id="close"
                              style={{
                                float: "right",
                                backgroundColor: "#a0a3a305",
                                color: "#797070",
                                border: "1px solid #a3a5a505",
                              }}
                              onClick={() => {
                                this.Successpopclose();
                              }}
                            >
                              <CloseIcon />
                            </button>
                          </div>
                        </div>
                        <div
                          className="chat-body p-10"
                          style={{
                            overflowY: "scroll",
                            overflowX: "hidden",
                            height: "470px",
                          }}
                        >
                          <div className="message-scrooler">
                            <div className="messages">
                              <div
                                className="containers"
                                style={{ width: "128vh" }}
                              >
                                {messages.map((message, index) => {
                                  const isSender = Popmsg.includes(message);

                                  return (
                                    <div
                                      className={`message-${isSender ? "SendMsg" : "blue"
                                        }`}
                                      key={index}
                                      style={{
                                        height: "auto",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {message.picture_Path === "-" &&
                                        message.document_Path === "-" ? (
                                        <p
                                          className="message-content"
                                          name="Popmsg"
                                        >
                                          {message.message}
                                        </p>
                                      ) : (
                                        <>
                                          {message.picture_Path !== "-" &&
                                            message.document_Path !== "-" ? (
                                            <div>
                                              <div>
                                                <p
                                                  className="message-content"
                                                  name="Popmsg"
                                                >
                                                  {message.message !=
                                                    "-" ? (
                                                    <>
                                                      {
                                                        message.message
                                                      }
                                                    </>
                                                  ) : null}
                                                </p>

                                                <br />

                                                <div>
                                                  <img
                                                    alt="chatimgA"
                                                    width="80"
                                                    height="80"
                                                    src={message.picture_Path}
                                                  />
                                                </div>
                                                <div>
                                                  <embed
                                                    src={message.document_Path}
                                                    id="Gstiframe"
                                                    title=""
                                                    scrolling="no"
                                                    target="_blank"
                                                    style={{
                                                      width: "100px",
                                                      height: "100px",
                                                      pointerEvents: "none",
                                                    }}
                                                  ></embed>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              {message.picture_Path === "-" &&
                                                message.document_Path !== "-" ? (
                                                <div>
                                                  <p
                                                    className="message-content"
                                                    name="Popmsg"
                                                  >
                                                    {message.message !=
                                                      "-" ? (
                                                      <>
                                                        {
                                                          message.message
                                                        }
                                                      </>
                                                    ) : null}
                                                  </p>
                                                  <br />
                                                  <embed
                                                    src={message.document_Path}
                                                    id="Gstiframe"
                                                    title=""
                                                    scrolling="no"
                                                    target="_blank"
                                                    style={{
                                                      width: "100px",
                                                      height: "100px",
                                                      pointerEvents: "none",
                                                    }}
                                                  ></embed>
                                                </div>
                                              ) : (
                                                <div>
                                                  <p
                                                    className="message-content"
                                                    name="Popmsg"
                                                
                                                  >
                                                    {message.message !=
                                                      "-" ? (
                                                      <>
                                                        {
                                                          message.message
                                                        }
                                                      </>
                                                    ) : null}
                                                  </p>
                                                  <br />

                                                  <div>
                                                    <img
                                                      alt="B"
                                                      width="80"
                                                      height="80"
                                                      src={message.picture_Path}
                                                   
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                      <br />
                                      <div className="message-timestamp-right">
                                        <ReactTimeAgo
                                          date={message.createDateTime}
                                          locale="en-US"
                                          timeStyle="round-minute"
                                          style={{ color: "gray" }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                                <div ref={this.myRef} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="Chat-Input-outer">
                          <div
                            className="chat-footer b-t-muted"
                            style={{ backgroundColor: "white" }}
                          >
                            <div
                              className="input-group write-msg"
                              id="Chat-input"
                              style={{
                                border: "1px solid #bfbfbe",
                                padding: "2px 2px",
                              }}
                            >
                            
                              <input
                                type="text"
                                className="form-control input-value"
                                placeholder="Type a Message"
                                name="Message"
                                value={this.state.message}
                                onChange={this.handleTextChange}
                                onKeyPress={this.handleKeyPress}
                                style={{ border: "none" }}
                              ></input>
                              {picture_Path && (
                                <div>
                                  <img
                                    src={URL.createObjectURL(picture_Path)}
                                    alt="Preview"
                                    style={{ width: "5vh", height: "5vh" }}
                                  />
                                </div>
                              )}
                              {document_Path && (
                                <div>
                                  {document_Path.type ===
                                    "application/pdf/msword" ? (
                                    <embed
                                      src={URL.createObjectURL(document_Path)}
                                      width="50"
                                      height="60"
                                      type="application/pdf/msword"
                                    />
                                  ) : (
                                    <p>{document_Path.name}</p>
                                  )}
                                </div>
                              )}
                              <input
                                type="hidden"
                                name="UserName"
                                defaultValue={
                                  this.state.EditSkillValuesEntity.email
                                }
                              />
                              <span
                                className="fa fa-smile-o"
                                aria-hidden="true"
                                style={{ marginTop: "10px", fontSize: "18px" }}
                                onClick={this.myEmoji}
                              ></span>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                             
                              {showEmoji && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-450px",
                                    marginLeft: "300px",
                                  }}
                                >
                                  <Picker
                                    onEmojiClick={this.handleEmojiClick}
                                  />
                                </div>
                              )}
                              <input
                                type="file"
                                id="file"
                                name="Picture_Path"
                                accept="image/*"
                                onChange={this.handleFileChange}
                                onFocus={(e) => (e.target.type = "file")}
                                style={{ display: "none" }}
                              />
                              <label
                                htmlFor="file"
                                style={{ marginTop: "8px" }}
                              >
                                <span className="fa fa-picture-o"> </span>
                              </label>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <input
                                type="file"
                                id="files"
                                name="Document_Path"
                                onChange={this.handleDocumentChange}
                                accept="application/pdf,application/msword"
                                onFocus={(e) => (e.target.type = "file")}
                                style={{ display: "none" }}
                              />
                              <label
                                htmlFor="files"
                                style={{ marginTop: "8px" }}
                              >
                                <span className="fa fa-file"> </span>
                              </label>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span className="input-group-btn">
                                <button
                                  id="paper-btn"
                                  className="btn btn-secondary"
                                  type="submit"
                                  style={{
                                    border: "none",
                                    background: "#ffffff",
                                    color: "#5035ce",
                                  }}
                                >
                                  <i className="icofont icofont-paper-plane"></i>
                                </button>
                              </span>
                            </div>
                            <div
                              className="input-group write-msg"
                              id="input-groupEdit"
                              style={{
                                border: "1px solid #bfbfbe",
                                padding: "2px 2px",
                                background: "#eff3f7",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control input-value"
                                placeholder="Type a Message"
                                value={this.state.deletemsg}
                                onChange={this.handleTextChange}
                                name="Message"
                                style={{ border: "none", background: '#eff3f7' }}
                                onKeyPress={this.handleKeyPressUser}
                              />
                              <input
                                type="file"
                                id="file"
                                name="Picture_Path"
                                accept="image/*"
                                onChange={this.handleFileChange}
                                onFocus={(e) => (e.target.type = "file")}
                                style={{ display: "none" }}
                              />
                              <span
                                className="fa fa-smile-o"
                                aria-hidden="true"
                                style={{
                                  marginTop: "10px",
                                  fontSize: "18px",
                                  background: "#eff3f7",
                                }}
                                onClick={this.myEmoji}
                              ></span>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {/* <input
                          type="file"
                          id="file"
                          name="Picture_Path"
                          onFocus={(e) => (e.target.type = "file")}
                          style={{ display: "none" }}
                        /> */}
                              {showEmoji && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-395px",
                                    marginLeft: "830px",
                                  }}
                                >
                                  <Picker onEmojiClick={this.handleEmojiClick} />
                                </div>
                              )}
                              <span className="input-group-btn" >
                                <span
                                  id="paper-btn"
                                  className="btn btn-secondary"
                                  style={{ background: '#eff3f7' }}
                                >
                                  <i
                                    className="fa fa-check"
                                    onClick={this.EditOneonOneMessage}
                                  ></i>{" "}
                                  &nbsp;&nbsp;{" "}
                                  <i
                                    className="fa fa-times"
                                    onClick={this.CancelEdit}
                                  ></i>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        ) : null}




        {this.state.LastChatModel ? (
          <div className="row" id="Chat-MainA">
            <div className="col-lg-6">
              <LastChatpop
                id=""
                close={() => this.LastChatModel()}
              />
            </div>
          </div>
        ) : null}

        {this.state.SearchModel ? (
          <div
            className="pop"
            style={{
              backgroundColor: "transparent",
              left: "40%",
              marginTop: "-65px",
            }}
            id="searchpop"
          >
            <div>
              <ul className="text-right boxs">
                <li
                  className="chat-single-box card-shadow bg-white active"
                  data-id="1"
                  style={{ width: "302px" }}
                >
                  <div className="had-container">
                    <div>
                      {/* <span defaultValue={this.props.values.company_Name}>{this.props.values.company_Name}</span> */}
                    </div>
                    <div className="chat-header p-10 bg-white">
                      <div className="box-tools d-inline-block">
                        <button
                          style={{
                            float: "right",
                            backgroundColor: "#a0a3a305",
                            color: "#797070",
                            border: "1px solid #a3a5a505",
                          }}
                          onClick={this.backentity}
                        >
                          <CloseIcon />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="form-control  search-text"
                        placeholder="Search"
                        onChange={this.myFunctionEntity}
                      />
                    </div>
                    <div
                      className="chat-body p-10"
                      style={{ overflow: "scroll", height: "505px" }}
                    >
                      {EntityUser.length == 0 ? (
                        <>
                          <center>
                            <h6
                              style={{ fontWeight: "600", color: "#7f63efde" }}
                            >
                              No Active Users In This Entity
                            </h6>
                          </center>
                        </>
                      ) : null}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        ) : 
          null}
        {EntityModel && (
          <>

            <div>
              <div className="pcoded-content">
                <div className="pcoded-inner-content">
                  <div className="main-body chat-bg">
                    <div className="">
                      <div id="" className="">
                        <div className="page-body">
                          <div className="row">
                            <div className="chat-box">
                              <div id="sidebar" className="users p-chat-user">
                                <div className="had-container">
                                  <div
                                    className="card card_main p-fixed users-main "
                                    style={{ overflow: "scroll" }}
                                  >
                                    <div className="user-box">
                                      <div id="tsum-tabs">
                                        <main id="maintab">
                                          <input
                                            id="tab1"
                                            type="radio"
                                            name="tabs"
                                            defaultChecked
                                          />
                                          <label
                                            id="supertab"
                                            className="j-tabs-label"
                                            htmlFor="tab1"
                                            title="In Entity"
                                            color="white"
                                          >
                                            In Entity
                                          </label>
                                          <input
                                            id="tab2"
                                            type="radio"
                                            name="tabs"
                                          />
                                          <label
                                            id="activetab"
                                            className="j-tabs-label"
                                            htmlFor="tab2"
                                            title="In OAT"
                                            color="white"
                                          >
                                            In OAT
                                          </label>

                                          {/* <input id="tab3" type="radio" name="tabs"/>
    <label for="tab3">Invite</label>  */}
                                          <br />
                                          <br />
                                          <div
                                            id="tabs-section-1"
                                            className="j-tabs-section"
                                          >
                                            <div className="right-icon-control">
                                              <input
                                                type="text"
                                                className="form-control  search-text"
                                                placeholder="Search"
                                                onChange={this.EntityFilter}
                                              />
                                              <div className="form-icon">
                                                <i className="icofont icofont-search"></i>
                                              </div>
                                            </div>
                                            <br />
                                            {/* {Announcement.length > 0 && ( */}
                                            {Announcement.length == 0 ? (
                                              <>
                                                <center>
                                                  <h6
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#7f63efde",
                                                    }}
                                                  >
                                                    No Active Users In This
                                                    Entity
                                                  </h6>
                                                </center>
                                              </>
                                            ) : null}
                                            <>
                                              {Announcement.map(
                                                (Announcement) => (
                                                  <>
                                                    <div
                                                      className="media userlist-box"
                                                      data-id="1"
                                                      data-status="online"
                                                      data-toggle="tooltip"
                                                      data-placement="left"
                                                      key={
                                                        (Announcement.id,
                                                          Announcement.usermsgId)
                                                      }
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.EditSkillModelEntity(
                                                          Announcement.id,
                                                          Announcement.firstName,
                                                          Announcement.allmessage,
                                                          Announcement.usermsgId,
                                                          Announcement.receivemsg,
                                                          Announcement.email,
                                                          Announcement.displayName
                                                        );
                                                      }}
                                                    >
                                                      <a className="media-left">
                                                        <img
                                                          className="media-object img-circle"
                                                          src="assets/images/avatar-1.png"
                                                          alt="Generic placeholder image"
                                                        />
                                                      </a>
                                                      <div
                                                        className="media-body"
                                                        style={{
                                                          textAlign: "initial",
                                                        }}
                                                      >
                                                        <div className="f-13 chat-header">
                                                          {" "}
                                                          {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                                          <span
                                                            style={{
                                                              color: "black",
                                                              whiteSpace:
                                                                "nowrap",
                                                            }}
                                                          >
                                                            {
                                                              Announcement.fName
                                                            }
                                                          </span>
                                                          <br />
                                                          <span
                                                            style={{
                                                              color: "Gray",
                                                              whiteSpace:
                                                                "nowrap",
                                                            }}
                                                          >
                                                            {username}
                                                          </span>
                                                          <br />
                                                          <span
                                                            style={{
                                                              color: "Gray",
                                                              whiteSpace:
                                                                "nowrap",
                                                            }}
                                                          >
                                                            {Announcement.email}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              )}
                                            </>
                                          </div>

                                          <div
                                            id="tabs-section-2"
                                            className="j-tabs-section"
                                          >
                                            <div className="right-icon-control">
                                              <input
                                                type="text"
                                                className="form-control  search-text"
                                                placeholder="Search"
                                                onChange={this.OATSearch}
                                              />
                                              <div className="form-icon">
                                                <i className="icofont icofont-search"></i>
                                              </div>
                                            </div>
                                            <br />

                                            {OATUser.map((user) => (
                                              <div
                                                className="media userlist-box"
                                                data-id="2"
                                                data-status="online"
                                                data-username="Josephin Doe"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                title="Josephin Doe"
                                              >
                                                <a className="media-left">
                                                  <img
                                                    className="media-object img-circle"
                                                    src="assets/images/avatar-1.png"
                                                    alt="Generic placeholder image"
                                                  />
                                                </a>
                                                <div
                                                  className="media-body"
                                                  style={{
                                                    textAlign: "initial",
                                                  }}
                                                >
                                                  <div
                                                    className="f-13 chat-header"
                                                    style={{ color: "black" }}
                                                  >
                                                    <div>
                                                      <div
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.SearchModel(
                                                            user.id,
                                                            user.userId,
                                                            user.company_Id,
                                                            user.email,
                                                            user.company_Name
                                                          );

                                                          this.GetEntityUser(
                                                            user.email
                                                          );
                                                        }}
                                                      >
                                                        {user.company_Name}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </main>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="styleSelector"></div>
                </div>
              </div>
            </div>
           
          </>
        )}

        {this.state.EditSkillModel ? (
          <div>
            <>

              <GroupPop
                id=""
                values={this.state.EditSkillValues}
                close={() => this.EditSkillModel()}
              />
            </>
          </div>
        ) : null}

        <div className="pcoded-content ">
          <div className="pcoded-inner-content">
            <div
              className="main-body"
            >
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                 <div className="page-body ">
                  {/*  */}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card" style={{backgroundColor:"transparent",boxShadow:"none"}}
                    >
              <form 
              //  id="chbole"
              >
                <div>
                  {this.state.showFirstHeader ? null : (
                    <div className="glowBoxnew">
                      <div>
                        <div >
                          <a href onClick={() => this.props.history.goBack()}>
                            <i
                              className="icofont icofont-arrow-left"
                              style={{ fontSize: "45px", color: "white" }}
                            />
                          </a>{" "}
                        </div>
                      </div>

                      <h4 className="titlenew" style={{ marginRight: "50px" }}>
                        Town Hall

                      </h4>

                      <i
                         id="gear"
                        title="Settings"
                        className="icofont icofont-gear"
                        onClick={this.AccessOpen}
                      />
                    </div>
                  )}
                  {isButton3Visible && (
                    <div id="btnstyle">
                      <Link
                        id="Ndbtn"
                        className="btn chatbtncolor"
                        onClick={() => {
                          this.handleButton3Click();
                        }}
                      >
                        <span id="Dlabel" onClick={this.handleClick} style={{ width: "145%" }}>

                          <i className="fa-solid fa-comment-medical"></i>
                          &nbsp;<span className="disresp" id="DText">New</span>&nbsp;
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
              </form>
              <div className="row">
                <div className="">
                  &nbsp;&nbsp;
                  {isButton1Visible && (
                    <Link
                      id="addRow"
                      className="btn chatbtncolor"
                      onClick={() => {
                        this.AddSkillModel();
                        this.handleButton1Click();
                      }}
                    >
                      <span className="chatButtonTextColor">
                        {" "}
                        New Discussion
                      </span>{" "}
                      &nbsp;&nbsp;{" "}
                      <i
                        className="fa fa-comments-o chatButtonTextColor"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  )}
                  &nbsp;&nbsp;
                  {isButton2Visible && (
                    <Link
                      id="addRow"
                      className="btn chatbtncolor"
                      onClick={() => {
                        this.handleButton2Click();
                      }}
                    >
                      {" "}
                      <span className="chatButtonTextColor">
                        {" "}
                        Group Discussion{" "}
                      </span>
                      &nbsp;&nbsp;
                      <i
                        className="fa fa-users chatButtonTextColor"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  )}
                </div>
              </div>
            
          
          
        

        {LastGroupopen && (
          <div id="last">
            <LastGroup id="" close={() => this.Lastgrpchatmodel()} />
            </div>
        )}
        </div>
        </div>
        </div>
        </div>
        </div>
         </div>
         </div>
        </div>

        {isBox1Open && (
          <div className="pcoded-content" id="Searchbox1">
            <div className="pcoded-inner-content">
              <div className="main-body chat-bg">
                <div className="">
                  <div id="" className="">
                    <div className="page-body">
                      <div className="row">
                        <div className="chat-box">
                          <div id="sidebar" className="users p-chat-user">
                            <div className="had-container">
                              <form className="grupform">
                                <div
                                  className="card card_main p-fixed users-main "
                                  style={{
                                    overflow: "scroll",
                                  }}
                                >
                                  
                                  <div className="user-box">
                                    <div id="tsum-tabs">
                                      <main id="">
                                        <div id="" className="">
                                          <br />
                                          <div className="col-lg-12">
                                            <input
                                              type="text"
                                              className="Searchbox"
                                              placeholder="Search"
                                              onChange={this.myFunctionAll}
                                              style={{
                                                backgroundColor: "white",
                                              }}
                                            />
                                            {/* <div className="form-icon">
                                              <i className="icofont icofont-search"></i>
                                            </div> */}
                                          </div>

                                          <br />
                                          <div className="checkboxes">
                                            {this.state.letters.map(
                                              (letter, index) => (
                                                <button
                                                  className="alphabet"
                                                  key={index}
                                                  onClick={(event) =>
                                                    this.handleFilter(
                                                      letter,
                                                      event
                                                    )
                                                  }
                                                >
                                                  {letter}
                                                </button>
                                              )
                                            )}
                                          </div>

                                          <div className="right-icon-control"></div>
                                          <br />

                                          <>
                                            <>
                                              {showResults && (
                                                <>
                                                  <ul>
                                                    {Addmembers.map(
                                                      (Announcement) => (
                                                        <div
                                                          className="media userlist-box"
                                                          data-id="1"
                                                          data-status="online"
                                                          data-username="Josephin Doe"
                                                          data-toggle="tooltip"
                                                          data-placement="left"
                                                          style={{
                                                            padding: "5px",
                                                            height: "79px",
                                                          }}
                                                        >
                                                          &nbsp;&nbsp;&nbsp;
                                                          <a className="media-left">
                                                            <img
                                                              className="media-object img-circle"
                                                              src="assets/images/avatar-1.png"
                                                              alt="Generic placeholder image"
                                                              style={{
                                                                marginTop:
                                                                  "20px",
                                                              }}
                                                            />
                                                          </a>
                                                          <div
                                                            className="media-body"
                                                            style={{
                                                              textAlign:
                                                                "initial",
                                                            }}
                                                          >
                                                            <div
                                                              className="f-13 chat-header"
                                                              style={{
                                                                color: "black",
                                                              }}
                                                            >
                                                              {" "}
                                                              <li
                                                                key={
                                                                  Announcement.id
                                                                }
                                                                value={
                                                                  Announcement.id
                                                                }
                                                              >
                                                                <label
                                                                  style={{
                                                                    padding:
                                                                      "2px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    className="Fontsize_In_chat"
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                      whiteSpace:
                                                                        "nowrap",
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    {/* {" "}
                                                                    {
                                                                      Announcement.name
                                                                    }
                                                                    */}
                                                                  </span>
                                                                  <br />
                                                                  <span
                                                                    className="Fontsize_In_chat"
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                      whiteSpace:
                                                                        "nowrap",
                                                                    }}
                                                                  >
                                                                    {
                                                                      Announcement.name
                                                                    }
                                                                  </span>
                                                                  <br />
                                                                  <span
                                                                    className="Fontsize_In_chat"
                                                                    style={{
                                                                      color:
                                                                        "Gray",
                                                                      whiteSpace:
                                                                        "nowrap",
                                                                    }}
                                                                  >
                                                                    {
                                                                      Announcement.email
                                                                    }
                                                                  </span>
                                                                </label>
                                                              </li>
                                                            </div>
                                                          </div>
                                                          <input
                                                            type="checkbox"
                                                            title="Add"
                                                            className="check"
                                                            id="Checkboxchat"
                                                            value={
                                                              Announcement.email
                                                            }
                                                            checked={selectedUserIds.includes(
                                                              Announcement.email
                                                            )}
                                                            onChange={
                                                              this
                                                                .handleUserSelectionChange
                                                            }
                                                          />
                                                          <input
                                                            hidden
                                                            type="text"
                                                            name="User_Id"
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                  </ul>
                                                </>
                                              )}
                                            </>
                                            <></>
                                          </>
                                        </div>
                                      </main>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="styleSelector"></div>
            </div>
          </div>
        )}

        {isBox2Open && (
          <div
            style={{
              border: " 1px solid rgba(0,0,0,.125)",
              width: "40vh",
              marginLeft: "59%",
              marginTop: "-117px",
              height: "85vh",
              overflowY: "scroll",
              backgroundColor: "white",
            }}
            id="Searchbox2"
          >
            <br />
            <div id="tsum-tabs">
              <main id="maintab">
                <div id="" className="">
                  <div className="user-boxs">
                    <div id="">
                      <main id="">
                        {" "}
                        <form
                          className="grupform"
                        >
                          <div
                            className="card card_main p-fixed users-main "
                            style={{
                              overflow: "scroll",
                              marginTop: "50px",
                              height: "84%",
                            }}
                          >
                            <div className="user-box">
                              <div id="tsum-tabs">
                                <main id="">
                                  <div id="" className="">
                                    <br />
                                    <div
                                      className=""
                                      style={{
                                        marginLeft: "7px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                        style={{ float: "right" }}
                                        onClick={this.hideBox2}
                                      ></i>
                                      <br></br>
                                      <br></br>
                                    </div>
                                    <br />

                                    <>
                                      <>
                                        {selectedUsers.length == 0 ? (
                                          <>
                                            {" "}
                                            <h5>Add Members to Your Group</h5>
                                            <img
                                              src={GroupMember}
                                              style={{
                                                width: "200px",
                                                height: "200px",
                                              }}
                                            ></img>
                                          </>
                                        ) : null}
                                        <ul>
                                          {selectedUsers.map((Addmembers) => (
                                            <div
                                              className="media userlist-box"
                                              data-id="1"
                                              data-status="online"
                                              data-toggle="tooltip"
                                              data-placement="left"
                                              style={{
                                                height: "40px",
                                              }}
                                            >
                                              <a className="media-left">
                                                <img
                                                  className="media-object img-circle"
                                                  src="assets/images/avatar-1.png"
                                                  alt="Generic placeholder image"
                                                />
                                              </a>
                                              <div
                                                className="media-body"
                                                style={{
                                                  textAlign: "initial",
                                                }}
                                              >
                                                <div
                                                  className="f-13 chat-header"
                                                  style={{
                                                    display: "flex",
                                                  }}
                                                >
                                                  {" "}
                                                  <li
                                                    className="Fontsize_In_chat"
                                                    key={Addmembers.id}
                                                    style={{
                                                      color: "black",
                                                    }}
                                                  >
                                                    {Addmembers.name}{" "}
                                                  </li>
                                                  ;
                                                </div>
                                              </div>
                                              <i
                                                onClick={() => {
                                                  this.handleUserRemove(
                                                    Addmembers.email,
                                                    Addmembers.name
                                                  );
                                                }}
                                                style={{
                                                  fontSize: "20px",

                                                  marginLeft: "15vh",
                                                }}
                                              >
                                                {" "}
                                                <CloseIcon
                                                  style={{
                                                    fontSize: "20px",
                                                    color: "red",
                                                  }}
                                                />
                                              </i>
                                              <input
                                                hidden
                                                type="text"
                                                className="grupuser"
                                                value={Addmembers.name}
                                                name="FirstName"
                                              />
                                              <input
                                                hidden
                                                type="text"
                                                className="grupuser"
                                                value={Addmembers.email}
                                                name="Email"
                                              />
                                              <input
                                                hidden
                                                type="text"
                                                name="User_Id"
                                              />
                                            </div>
                                          ))}
                                        </ul>
                                      </>
                                      <></>
                                    </>
                                  </div>
                                </main>
                              </div>
                            </div>
                            <div className="footer">
                              <center>
                                <input
                                  hidden
                                  type="submit"
                                  value="submit"
                                  className="click"
                                  id="formbtn"
                                />
                                <br />
                                <button
                                  type="submit"
                                  className="but"
                                  style={{
                                    height: "39.5px",
                                    width: "86.36px",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleTitlePopupOpen();
                                  }}
                                >
                                  Continue
                                </button>
                              </center>
                            </div>
                          </div>
                        </form>
                        <br />
                      </main>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        )}
        {isBox3Open && (
          <div className="pcoded-content ">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                 <div className="page-body ">
                  {/*  */}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card" style={{backgroundColor:"transparent",boxShadow:"none"}}
                    > 
                    <div className="martop"
                      id="chatboxFullContainer"
                    >
                      <form
                        style={{ backgroundColor: "white" }}
                      >
                        <div>
                          <>
                            {showFirstHeader ? (
                              <div className="glowBox">
                                <div>
                                  <div style={{ float: "left" }}>
                                    <a
                                      href
                                      onClick={() => {
                                        this.handleBackArrowClick2();
                                        this.handleClick();
                                      }}
                                    >
                                      <i
                                        className="icofont icofont-arrow-left"
                                        style={{
                                          fontSize: "45px",
                                          color: "white",
                                        }}
                                      />
                                    </a>{" "}
                                  </div>
                                </div>
                                <h4
                                  className="title"
                                  style={{ marginRight: "50px" }}
                                >
                                  New Discussion
                                </h4>
                              </div>
                            ) : null}
                          </>
                        </div>

                        <div
                          className="container-fluid"
                          id="NDiscContainer"
                        >
                          <div className="row">
                            <span
                              className="NewDiscussionTab"
                              id="NewDiscussionTab"
                            >
                              <input
                                id="tab1"
                                type="radio"
                                name="tabs"
                                className="DNInput"
                                defaultChecked="tab1"
                              />
                              <label
                                for="tab1"
                                id="DNlabeluser"
                                onClick={this.HideFormdisplay}>
                                <span>
                                  <i
                                    className="fa fa-user"
                                    aria-hidden="true"
                                    style={{ fontSize: "13px" }}
                                    id="DNTextuser"
                                  ></i>
                                  &nbsp;&nbsp;
                                  <span id="DNTextuser">1:1 </span>
                                </span>
                              </label>

                              <input id="tab3" type="radio" name="tabs" />
                              <label
                                for="tab3"
                                id="DNlabeluser"

                                onClick={this.Formdisplay}
                              >
                                <span>
                                  <i
                                    className="fa fa-users"
                                    aria-hidden="true"
                                    style={{ fontSize: "13px" }}
                                    id="DNTextuser"
                                  ></i>
                                  &nbsp;&nbsp;
                                  <span id="DNTextuser">Create Group</span>
                                </span>
                              </label>
                            </span>
                            <br /><br /><br />
                            <div
                              className={displayForm ? "col-xl-12" : "col-xl-12"}
                              id="NDFullContainer"
                            >

                              {displayForm ? (
                                <form>
                                  <div id={displayForm ? 'NDcontainerAfter' : "NDcontainer"}>
                                    <aside id="NDaside">

                                      <header>
                                        <input type="text" placeholder="Search Member..."
                                          onChange={this.myFunctionAll}
                                          id='searchInputvalue'
                                        />
                                      </header>
                                      <center>
                                        <span
                                          id="DFilterErr"
                                          style={{ color: "red" }}
                                        ></span></center>
                                      <ul>
                                        {MemberAccess.map((Announcement) => (
                                          <>
                                            {Accessed === 1 && Accessed_to === 1 ? (<li>
                                              <div
                                                className="checkbox-wrapper-12"
                                                style={{ marginLeft: '10px' }}
                                              >
                                                <div className="cbx">
                                                  <input
                                                    type="checkbox"
                                                    title="Add"
                                                    className="check"
                                                    id={`Checkboxchat-${Announcement.email}`}
                                                    value={Announcement.email}
                                                    checked={selectedUserIds.includes(
                                                      Announcement.email
                                                    )}
                                                    onChange={
                                                      this.handleUserSelectionChange
                                                    }
                                                    style={{ background: 'rgba(0,0,0,.1)' }}
                                                  />
                                                  <label htmlFor={`Checkboxchat-${Announcement.email}`}></label>
                                                  <svg
                                                    width="15"
                                                    height="14"
                                                    viewbox="0 0 15 14"
                                                    fill="none"
                                                  >
                                                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                                                  </svg>
                                                </div>

                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  version="1.1"
                                                >
                                                  <defs>
                                                    <filter id="goo-12">
                                                      <fegaussianblur
                                                        in="SourceGraphic"
                                                        stddeviation="4"
                                                        result="blur"
                                                      ></fegaussianblur>
                                                      <fecolormatrix
                                                        in="blur"
                                                        mode="matrix"
                                                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                                                        result="goo-12"
                                                      ></fecolormatrix>
                                                      <feblend
                                                        in="SourceGraphic"
                                                        in2="goo-12"
                                                      ></feblend>
                                                    </filter>
                                                  </defs>
                                                </svg>
                                              </div>
                                              <img src={Announcement.profile_Picture} alt="" style={{
                                                width: "35px",
                                                height: "35px",
                                                marginLeft: '4px'
                                              }}
                                                id='GrpAddmembers'
                                              />

                                              <div id='NameListDiv'>
                                                <label htmlFor={`Checkboxchat-${Announcement.email}`} style={{ cursor: 'pointer', marginBottom: "0", display: 'block' }}>
                                                  <h2 id='NDNameList'>
                                                    {Announcement.userActiveStatus === 1 ?
                                                      <img
                                                        src={onlineimg}
                                                        className="media-object img-circle"
                                                        style={{
                                                          marginTop: "50px",
                                                          marginLeft: "-12px",
                                                          width: "12px",
                                                          background: "white",
                                                          animation:
                                                            "greenonline 2s infinite",
                                                        }}
                                                        title="Online"
                                                      /> : <img
                                                        src={offlineimg}
                                                        className="media-object img-circle"
                                                        style={{
                                                          marginTop: "50px",
                                                          marginLeft: "-12px",
                                                          width: "12px",
                                                          background: "white",
                                                          animation:
                                                            "offlinego 2s infinite",
                                                        }}
                                                        title={`last seen at ${Announcement.logout}`}
                                                      />
                                                    }&nbsp;{Announcement.fName}

                                                  </h2>
                                                </label>
                                              </div>
                                              <br />

                                              <span id="AddMemberMail" style={{ fontSize: '12px', color: '#fff' }}> ({Announcement.email})</span>
                                            </li>) : null}
                                          </>
                                        ))}
                                        <>
                                          {NonAccess.map((Announcement) => (
                                            <>
                                              {(Accessed === 1 &&
                                                Accessed_to === 0) ||
                                                (Accessed === 0 && Accessed_to === 0) ||
                                                (Accessed === 0 &&
                                                  Accessed_to === 1) ? (<li>
                                                    <div
                                                      className="checkbox-wrapper-12"
                                                      style={{ marginLeft: '10px' }}
                                                    >
                                                      <div className="cbx">
                                                        <input
                                                          type="checkbox"
                                                          title="Add"
                                                          className="check"
                                                          id={`Checkboxchat-${Announcement.email}`}
                                                          value={Announcement.email}
                                                          checked={selectedUserIds.includes(
                                                            Announcement.email
                                                          )}
                                                          onChange={
                                                            this.handleUserSelectionChange
                                                          }
                                                          style={{ background: 'rgba(0,0,0,.1)' }}
                                                        />
                                                        <label htmlFor={`Checkboxchat-${Announcement.email}`}></label>
                                                        <svg
                                                          width="15"
                                                          height="14"
                                                          viewbox="0 0 15 14"
                                                          fill="none"
                                                        >
                                                          <path d="M2 8.36364L6.23077 12L13 2"></path>
                                                        </svg>
                                                      </div>

                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        version="1.1"
                                                      >
                                                        <defs>
                                                          <filter id="goo-12">
                                                            <fegaussianblur
                                                              in="SourceGraphic"
                                                              stddeviation="4"
                                                              result="blur"
                                                            ></fegaussianblur>
                                                            <fecolormatrix
                                                              in="blur"
                                                              mode="matrix"
                                                              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                                                              result="goo-12"
                                                            ></fecolormatrix>
                                                            <feblend
                                                              in="SourceGraphic"
                                                              in2="goo-12"
                                                            ></feblend>
                                                          </filter>
                                                        </defs>
                                                      </svg>
                                                    </div>
                                                    <img src={Announcement.profile_Picture} alt="" style={{
                                                      width: "35px",
                                                      height: "35px",
                                                      marginLeft: '4px'
                                                    }}
                                                      id='GrpAddmembers'
                                                    />

                                                    <div id='NameListDiv'>
                                                      <label htmlFor={`Checkboxchat-${Announcement.email}`} style={{ cursor: 'pointer', marginBottom: "0", display: 'block' }}>
                                                        <h2 id='NDNameList'>
                                                          {Announcement.userActiveStatus === 1 ?
                                                            <img
                                                              src={onlineimg}
                                                              className="media-object img-circle"
                                                              style={{
                                                                marginTop: "50px",
                                                                marginLeft: "-12px",
                                                                width: "12px",
                                                                background: "white",
                                                                animation:
                                                                  "greenonline 2s infinite",
                                                              }}
                                                              title="Online"
                                                            /> : <img
                                                              src={offlineimg}
                                                              className="media-object img-circle"
                                                              style={{
                                                                marginTop: "50px",
                                                                marginLeft: "-12px",
                                                                width: "12px",
                                                                background: "white",
                                                                animation:
                                                                  "offlinego 2s infinite",
                                                              }}
                                                              title={`last seen at ${Announcement.logout}`}
                                                            />
                                                          }&nbsp;{Announcement.fName}

                                                        </h2>
                                                      </label>
                                                    </div>
                                                    <br />
                                                    <span id="AddMemberMail" style={{ fontSize: '12px', color: '#fff' }}> ({Announcement.email})</span>
                                                  </li>) : null}
                                            </>
                                          ))}
                                        </>


                                      </ul>

                                    </aside>
                                    <main id="NDmain">
                                      <center>

                                        {displayForm && selectedUsers.length !== 0 ? (

                                          <div>
                                            <center style={{ paddingTop: '15px', color: '#7367f0', fontWeight: 'bold', fontSize: '16px' }}>Selected Users</center>

                                            <form id="UserSelectPop">
                                              {selectedUsers.map((member) => (
                                                <div
                                                  className="media userlist-box"
                                                  data-id="1"
                                                  data-status="online"
                                                  data-username="Josephin Doe"
                                                  data-toggle="tooltip"
                                                  data-placement="left"
                                                  id="EntityUser"
                                                  style={{
                                                    height: "57px",
                                                    borderBottom: "none",
                                                  }}
                                                >
                                                  &nbsp;&nbsp;&nbsp;
                                                  <a className="media-left">
                                                    <img
                                                      className="media-object img-circle"
                                                      src={member.profile_Picture}
                                                      alt="Generic placeholder image"
                                                      style={{
                                                        marginTop: "20px",
                                                        width: "35px",
                                                        height: "35px",
                                                      }}

                                                    />
                                                    <img
                                                      id="delIcon"
                                                      src={delImg}
                                                      className="media-object img-circle"
                                                      onClick={() => {
                                                        this.handleUserRemove(
                                                          member.email,
                                                          member.name
                                                        );
                                                      }}
                                                    />
                                                  </a>
                                                  <div
                                                    className="media-body"
                                                    style={{
                                                      textAlign: "initial",
                                                    }}
                                                  >
                                                    <div
                                                      className="f-13 chat-header"
                                                      style={{
                                                        color: "black",
                                                      }}
                                                    >
                                                      {" "}
                                                      <a
                                                        id="lastmemberli"
                                                        key={member.id}
                                                        value={member.id}
                                                        style={{ listStyleType: "none" }}
                                                      >
                                                        <label
                                                        >
                                                          <span
                                                            className="Fontsize_In_chat"
                                                            style={{
                                                              color: "black",
                                                              whiteSpace: "nowrap",
                                                              fontWeight: "bold",
                                                            }}
                                                          ></span>
                                                          <br />
                                                          <span
                                                            className="Fontsize_In_chat"
                                                            style={{
                                                              color: "black",
                                                              whiteSpace: "nowrap",
                                                              fontWeight: "bold",
                                                              fontSize: '12px'
                                                            }}
                                                          >
                                                            {member.name}
                                                          </span>&nbsp;{" "}

                                                          <span
                                                            className="Fontsize_In_chat"
                                                            style={{
                                                              color: "Gray",
                                                              whiteSpace: "nowrap",
                                                              fontSize: '11px'
                                                            }}
                                                          >
                                                            ({member.email})
                                                          </span>
                                                         
                                                        </label>
                                                      
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <input
                                                    hidden
                                                    type="text"
                                                    name="User_Id"
                                                  />
                                                </div>
                                              ))}
                                            </form>{" "} <br />
                                            <center>
                                              {selectedUsers.length == 0 ? null : (
                                                <>

                                                  <button
                                                    type="submit"
                                                    className="but"
                                                    onClick={this.openPopup}
                                                  >
                                                    Continue
                                                  </button>
                                                </>
                                              )}
                                            </center>
                                          </div>
                                        ) : (
                                          <center>
                                            <img
                                              src='https://thumbs.dreamstime.com/b/people-talking-icon-group-symbol-bubbles-isolated-white-background-simple-abstract-discuss-vector-black-illustration-115580097.jpg'
                                              style={{
                                                height: '150px', width: '150px', borderRadius: "50%",
                                                marginTop: '160px'
                                              }}
                                            ></img>
                                            <br /><br />
                                            <h5 style={{ color: 'gray', fontWeight: 'bold' }}>Start Group Discussion </h5>
                                          </center>
                                        )}
                                      </center>
                                    </main>
                                  </div></form>
                              ) : (
                                <form>
                                  <div id={chatpop ? 'NDcontainerAfter' : "NDcontainer"}>
                                    <aside id="NDaside">
                                      <header>
                                        <input type="text" placeholder="Search Member..."
                                          onChange={this.myFunctionAll}
                                          id='searchInputvalue'
                                        />
                                      </header>
                                      <center>
                                        <span
                                          id="DFilterErr"
                                          style={{ color: "red" }}
                                        ></span></center>
                                      <ul>
                                        {MemberAccess.map((Announcement) => (
                                          <>
                                            {Accessed === 1 && Accessed_to === 1 ? (
                                              <li onClick={() => {
                                                this.getDetails(
                                                  Announcement.firstName,
                                                  Announcement.email,
                                                  Announcement.profile_Picture,
                                                  Announcement.senderId,
                                                  Announcement.particularId
                                                );
                                                this.markMessageAsSeenUser(
                                                  Announcement.senderId,
                                                  Announcement.particularId
                                                );
                                                this.markMessageAsSeenAdmin(
                                                  Announcement.senderId,
                                                  Announcement.particularId
                                                );
                                              }}>
                                                <img src={Announcement.profile_Picture} alt="" style={{
                                                  width: "35px",
                                                  height: "35px",

                                                }} />

                                                <div id='NameListDiv'>
                                                  <h2 id='NDNameList'>
                                                    {Announcement.userActiveStatus === 1 ?
                                                      <img
                                                        src={onlineimg}
                                                        className="media-object img-circle"
                                                        style={{
                                                          marginTop: "50px",
                                                          marginLeft: "-12px",
                                                          width: "12px",
                                                          background: "white",
                                                          animation:
                                                            "greenonline 2s infinite",
                                                        }}
                                                        title="Online"
                                                      /> : <img
                                                        src={offlineimg}
                                                        className="media-object img-circle"
                                                        style={{
                                                          marginTop: "50px",
                                                          marginLeft: "-12px",
                                                          width: "12px",
                                                          background: "white",
                                                          animation:
                                                            "offlinego 2s infinite",
                                                        }}
                                                        title={`last seen at ${Announcement.logout}`}
                                                      />
                                                    }&nbsp;{Announcement.fName}&nbsp; {Announcement.senderId !==
                                                      UserId ? (
                                                      <>
                                                        {Announcement.counts > 0 ? (
                                                          <p
                                                            className="badge badge-primary"
                                                            style={{
                                                              fontSize: "11px",
                                                              backgroundColor:
                                                                "#563ccf",
                                                            }}
                                                          >
                                                            {Announcement.counts}
                                                          </p>
                                                        ) : null}
                                                      </>
                                                    ) : null}

                                                  </h2>
                                                </div>
                                                <br />

                                                <span id="NDMail" style={{ fontSize: '12px', color: '#fff' }}> ({Announcement.email})</span>
                                              </li>
                                            ) : null}
                                          </>
                                        ))}
                                        <>
                                          {NonAccess.map((Announcement) => (
                                            <>
                                              {(Accessed === 1 &&
                                                Accessed_to === 0) ||
                                                (Accessed === 0 && Accessed_to === 0) ||
                                                (Accessed === 0 &&
                                                  Accessed_to === 1) ? (
                                                <li onClick={() => {
                                                  this.getDetails(
                                                    Announcement.firstName,
                                                    Announcement.email,
                                                    Announcement.profile_Picture,
                                                    Announcement.senderId,
                                                    Announcement.particularId
                                                  );
                                                  this.markMessageAsSeenUser(
                                                    Announcement.senderId,
                                                    Announcement.particularId
                                                  );
                                                  this.markMessageAsSeenAdmin(
                                                    Announcement.senderId,
                                                    Announcement.particularId
                                                  );
                                                }}>
                                                  <img src={Announcement.profile_Picture} alt="" style={{
                                                    width: "35px",
                                                    height: "35px",

                                                  }} />

                                                  <div id='NameListDiv'>
                                                    <h2 id='NDNameList'>
                                                      {Announcement.userActiveStatus === 1 ?
                                                        <img
                                                          src={onlineimg}
                                                          className="media-object img-circle"
                                                          style={{
                                                            marginTop: "50px",
                                                            marginLeft: "-12px",
                                                            width: "12px",
                                                            // position: "absolute",
                                                            background: "white",
                                                            animation:
                                                              "greenonline 2s infinite",
                                                          }}
                                                          title="Online"
                                                        /> : <img
                                                          src={offlineimg}
                                                          className="media-object img-circle"
                                                          style={{
                                                            marginTop: "50px",
                                                            marginLeft: "-12px",
                                                            width: "12px",
                                                            // position: "absolute",
                                                            background: "white",
                                                            animation:
                                                              "offlinego 2s infinite",
                                                          }}
                                                          title={`last seen at ${Announcement.logout}`}
                                                        />
                                                      }&nbsp;{Announcement.fName}&nbsp; {Announcement.senderId !==
                                                        UserId ? (
                                                        <>
                                                          {Announcement.counts > 0 ? (
                                                            <p
                                                              className="badge badge-primary"
                                                              style={{
                                                                fontSize: "11px",
                                                                backgroundColor:
                                                                  "#563ccf",
                                                              }}
                                                            >
                                                              {Announcement.counts}
                                                            </p>
                                                          ) : null}
                                                        </>
                                                      ) : null}

                                                    </h2>
                                                  </div>
                                                  <br />

                                                  <span id="NDMail" style={{ fontSize: '12px', color: '#fff' }}> ({Announcement.email})</span>
                                                </li>
                                              ) : null}
                                            </>
                                          ))}
                                        </>



                                      </ul>

                                    </aside>
                                    <main id="NDmain">

                                      {Chatpop ?
                                        <div style={chatpop}>
                                          {Addmembers.map((member) =>
                                              member.email === this.state.Email ? (
                                              <div>
                                                {member.userActiveStatus === 1 ? (
                                                  <>
                                                    <img
                                                      src={onlinedot}
                                                      className="media-object img-circle"
                                                      style={{
                                                        animation:
                                                          "greenonline 2s infinite",
                                                        marginTop: "25px",
                                                        width: "12px",
                                                        position: "absolute",
                                                        marginLeft: "25px",
                                                      }}
                                                      title="Online"
                                                    />
                                                    <img src={UPic} style={{ width: "35px", height: "35px", borderRadius: '50%' }} alt="" />
                                                    &nbsp;<span
                                                      style={{
                                                        color: "white",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",

                                                      }}
                                                    >
                                                      {" "}
                                                      {member.fName}{" "}

                                                      <p
                                                        style={{
                                                          fontSize: "10px",
                                                          color: '#2ecc71',
                                                          marginLeft: '43px',
                                                          marginTop: "-0.7%",
                                                          position: 'absolute',

                                                        }}
                                                      >
                                                        Online
                                                      </p>
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>
                                                    <img
                                                      src={offlinedot}
                                                      className="media-object img-circle"
                                                      title="Offline"
                                                      style={{
                                                        animation:
                                                          "offlinego 2s infinite",
                                                        marginTop: "25px",
                                                        width: "12px",
                                                        position: "absolute",
                                                        marginLeft: "25px",
                                                      }}
                                                    />
                                                    <img src={UPic} style={{ width: "35px", height: "35px", borderRadius: '50%' }} alt="" />
                                                    &nbsp; <span
                                                      style={{
                                                        color: "white",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {" "}
                                                      {member.fName}{" "}
                                                      <p
                                                        style={{
                                                          fontSize: "10px",
                                                          color: '#ff725d',
                                                          marginLeft: '43px',
                                                          marginTop: "-0.7%",
                                                          position: 'absolute'
                                                        }}
                                                      >
                                                        last seen at {member.logout}
                                                      </p>
                                                    </span>
                                                  </>
                                                )}
                                                <span
                                                  style={{
                                                    float: 'right', color: "white",
                                                    fontSize: "20px", marginRight: "15px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {" "}
                                                  <i
                                                    className="fa fa-times"
                                                    aria-hidden="true"
                                                    onClick={this.hideChatpop}
                                                  ></i>
                                                </span>
                                              </div>
                                            ) : null
                                          )}

                                          <form
                                            autoComplete="off"
                                            ref={this.formRef}
                                            encType="multipart/form-data"
                                          >
                                            <input
                                              type="hidden"
                                              name="UserName"
                                              defaultValue={this.state.Email}
                                            />

                                            <div>
                                              <div className="message-scrooler">
                                                <div className="messages" style={{ background: '#eff3f7' }}>
                                                  <div
                                                    ref={this.chatBoxRef}
                                                    style={{
                                                      marginTop: "20px",
                                                      overflowY: "scroll",
                                                      height: "380px",

                                                    }}
                                                  >
                                                    {messages.map(
                                                      (message, index) => {
                                                        const isSender =
                                                          Popmsg.includes(message);
                                                        let text =
                                                          message.document_Path;
                                                        let result = text.slice(
                                                          45,
                                                          85
                                                        );
                                                        let result1 =
                                                          text.slice(45);
                                                        return (
                                                          <>
                                                            {message.sysDateTimeWithSeconds && message.rowNum === 1 ? (
                                                              <>
                                                                <center style={{ marginTop: '2%', marginBottom: '10px' }}>
                                                                  <span
                                                                    style={{
                                                                      color: 'gray', backgroundColor: "#8eb6d859", height: "35px",
                                                                      padding: "8px 8px 8px 6px",
                                                                      borderRadius: "7px",
                                                                    }}
                                                                  >
                                                                    &nbsp;
                                                                    {this.formatDateAA1(
                                                                      message.createDateTime
                                                                    )}
                                                                  </span></center>
                                                              </>
                                                            ) : null}
                                                            {message.particularId === UserId ? <>
                                                              {message.displayStatus === 2 ? (
                                                                <div
                                                                  className={`message-${isSender ? "SendMsg" : "blue"
                                                                    }`}
                                                                  key={index}
                                                                  style={{
                                                                    height: "auto",
                                                                    overflowWrap: "break-word",
                                                                  }}
                                                                >
                                                                  {MsgMenu &&
                                                                    this.state.deleteId == message.id ? (
                                                                    <div
                                                                      style={MessageMenu}
                                                                      ref={this.containerRef}
                                                                    >
                                                                      <center>
                                                                        <ul>
                                                                          {isSender ? (
                                                                            <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>

                                                                              <span
                                                                                onClick={this.EditMessage}
                                                                                id="MsgOption"
                                                                                style={{ marginLeft: '-26px' }}
                                                                              >
                                                                                <i
                                                                                  className="fa fa-pencil-square-o"
                                                                                  aria-hidden="true"
                                                                                ></i>&nbsp;&nbsp;
                                                                                <b>
                                                                                  Edit
                                                                                </b>
                                                                              </span>
                                                                            </li>) : null}

                                                                          <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                                            <span
                                                                              onClick={this.deleteMenu}
                                                                              id="MsgOption"
                                                                              style={{ marginLeft: '-14px' }}
                                                                            >
                                                                              <i
                                                                                className="fa fa-trash"
                                                                                aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;

                                                                              <b>
                                                                                Delete
                                                                              </b>
                                                                            </span>
                                                                          </li>
                                                                          <li style={{ padding: '8px' }}>
                                                                            <span
                                                                              onClick={this.forwardopenpop}
                                                                              id="MsgOption"
                                                                            >
                                                                              <i
                                                                                className="fa fa-share"
                                                                                aria-hidden="true"></i>

                                                                              <b>&nbsp;
                                                                                Forward
                                                                              </b>
                                                                            </span>
                                                                          </li>

                                                                        </ul>
                                                                      </center>
                                                                    </div>
                                                                  ) : null}
                                                                  <i
                                                                    className="fa fa-ellipsis-h"
                                                                    style={{
                                                                      float: "Right",
                                                                      cursor: "pointer",
                                                                    }}
                                                                    aria-hidden="true"
                                                                    onClick={() =>
                                                                      this.DisplayMenu(
                                                                        message.id,
                                                                        message.message,
                                                                        message.picture_Path,
                                                                        message.document_Path,
                                                                        message.senderId,
                                                                        message.createDateTime,
                                                                        message.particularId,
                                                                        message.sysDateTimeWithSeconds
                                                                      )
                                                                    }
                                                                  ></i>

                                                                  {!isSender ? (
                                                                    <h6
                                                                      style={{
                                                                        fontWeight: "600",
                                                                        color: "#09679f",
                                                                      }}
                                                                    >
                                                                      {message.editStatus === 1 ? (
                                                                        <span
                                                                          style={{
                                                                            fontWeight: "200",
                                                                            color: "grey",
                                                                            fontSize: "10px",
                                                                          }}
                                                                        >
                                                                          Edited
                                                                        </span>
                                                                      ) : null}
                                                                    </h6>
                                                                  ) : (
                                                                    <>
                                                                      {message.editStatus === 1 ? (
                                                                        <h6
                                                                          style={{
                                                                            fontWeight: "200",
                                                                            color: "grey",
                                                                            fontSize: "10px",
                                                                          }}
                                                                        >
                                                                          Edited
                                                                        </h6>
                                                                      ) : null}
                                                                    </>
                                                                  )}
                                                                  {message.frwdStatus === 1 ? (
                                                                    <h6
                                                                      style={{
                                                                        fontWeight: "200",
                                                                        color: "grey",
                                                                        fontSize: "10px",
                                                                      }}
                                                                    >
                                                                      <i
                                                                        className="fa fa-share"
                                                                        aria-hidden="true"
                                                                      ></i>
                                                                      &nbsp;&nbsp;Forwarded
                                                                    </h6>
                                                                  ) : null}
                                                                  {message.picture_Path === "-" &&
                                                                    message.document_Path === "-" ? (
                                                                    <p
                                                                      className="message-content"
                                                                      name="Popmsg"
                                                                    >
                                                                      {regexp.test(message.message) ==
                                                                        true ? (
                                                                        <>
                                                                          <a
                                                                            style={{
                                                                              color: "blue",
                                                                              textDecoration: "underline",
                                                                            }}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            href={message.message}
                                                                          >
                                                                            {message.message}
                                                                          </a>
                                                                          <br />
                                                                        </>
                                                                      ) : (
                                                                        <div>
                                                                          <p>{message.message}</p>
                                                                        </div>
                                                                      )}
                                                                      {isSender && message.seen == 1 ? (
                                                                        <img
                                                                          src={doubletick}
                                                                          style={{
                                                                            // marginTop: "50px",
                                                                            marginTop: "-12px",
                                                                            marginLeft: "-2px",
                                                                            width: "20px",
                                                                            float: "right",
                                                                          }}
                                                                          title="Seen"
                                                                        />
                                                                      ) : null}
                                                                      {isSender && message.seen == 0 ? (
                                                                        <img
                                                                          src={singletick}
                                                                          style={{
                                                                            // marginTop: "50px",
                                                                            marginTop: "-12px",
                                                                            marginLeft: "-2px",
                                                                            width: "20px",
                                                                            float: "right",
                                                                          }}
                                                                          title="Unread"
                                                                        />
                                                                      ) : null}
                                                                      {/* <br /> */}
                                                                    </p>
                                                                  ) : (
                                                                    <>
                                                                      {message.picture_Path !== "-" &&
                                                                        message.document_Path !== "-" ? (
                                                                        <div>
                                                                          <div>
                                                                            <p
                                                                              className="message-content"
                                                                              name="Popmsg"
                                                                            // style={{ wordWrap: "break-word" }}
                                                                            >
                                                                              {message.message !=
                                                                                "-" ? (
                                                                                <>
                                                                                  {
                                                                                    message.message
                                                                                  }
                                                                                </>
                                                                              ) : null}
                                                                            </p>

                                                                            <br />
                                                                            <a
                                                                              href={
                                                                                message.picture_Path
                                                                              }
                                                                              name="picture"
                                                                              readOnly
                                                                              value={
                                                                                message.picture_Path
                                                                              }
                                                                              title=""
                                                                              target="_blank"
                                                                              style={{
                                                                                width:
                                                                                  "120px",
                                                                                height:
                                                                                  "200px",
                                                                              }}
                                                                            ></a>
                                                                            <div>
                                                                              <img
                                                                                alt="chatimgC"
                                                                                width="80"
                                                                                height="80"
                                                                                src={message.picture_Path}
                                                                          
                                                                              />
                                                                            </div>
                                                                            <div>
                                                                              <embed
                                                                                src={
                                                                                  message.document_Path
                                                                                }
                                                                                id="Gstiframe"
                                                                                title=""
                                                                                scrolling="no"
                                                                                target="_blank"
                                                                                style={{
                                                                                  width: "100px",
                                                                                  height: "100px",
                                                                                  pointerEvents: "none",
                                                                                }}
                                                                              ></embed>
                                                                            </div>
                                                                          </div>
                                                                          {isSender &&
                                                                            message.seen == 1 ? (
                                                                            <img
                                                                              src={doubletick}
                                                                              style={{
                                                                                // marginTop: "50px",
                                                                                marginLeft: "-2px",
                                                                                width: "20px",
                                                                                float: "right",
                                                                              }}
                                                                              title="Seen"
                                                                            />
                                                                          ) : null}
                                                                          {isSender &&
                                                                            message.seen == 0 ? (
                                                                            <img
                                                                              src={singletick}
                                                                              style={{
                                                                                // marginTop: "50px",
                                                                                marginLeft: "-2px",
                                                                                width: "20px",
                                                                                float: "right",
                                                                              }}
                                                                              title="Unread"
                                                                            />
                                                                          ) : null}
                                                                          <br />
                                                                        </div>
                                                                      ) : (
                                                                        <>
                                                                          {message.picture_Path === "-" &&
                                                                            message.document_Path !==
                                                                            "-" ? (
                                                                            <div>
                                                                              <p
                                                                                className="message-content"
                                                                                name="Popmsg"
                                                                             
                                                                              >
                                                                                {message.message !=
                                                                                  "-" ? (
                                                                                  <>
                                                                                    {
                                                                                      message.message
                                                                                    }
                                                                                  </>
                                                                                ) : null}
                                                                              </p>
                                                                              <br />
                                                                              <a
                                                                                href={
                                                                                  message.document_Path
                                                                                }
                                                                                name="picture"
                                                                                readOnly
                                                                                value={
                                                                                  message.document_Path
                                                                                }
                                                                                title=""
                                                                                scrolling="no"
                                                                                target="_blank"
                                                                                style={{
                                                                                  width: "120px",
                                                                                  height: "200px",
                                                                                }}
                                                                              >
                                                                                <div
                                                                                  className="row"
                                                                                  style={{
                                                                                    width: "180px",
                                                                                    height: "60px",
                                                                                    borderRadius: "5px",
                                                                                    boxShadow:
                                                                                      "rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px",
                                                                                    background: "#e8e8e8",
                                                                                    alignItems: "center",
                                                                                  }}
                                                                                >
                                                                                  <div className="col-lg-3">
                                                                                    <img
                                                                                      src={File1}
                                                                                      style={{
                                                                                        height: "30px",
                                                                                        width: "30px",
                                                                                      }}
                                                                                    ></img>
                                                                                  </div>
                                                                                  <div className="col-lg-9">
                                                                                    {message.document_Path
                                                                                      .length > 85 ? (
                                                                                      <>
                                                                                        <div
                                                                                          style={{
                                                                                            opacity:
                                                                                              DName &&
                                                                                                documentname ==
                                                                                                message.id
                                                                                                ? 1
                                                                                                : 0,
                                                                                            width:
                                                                                              "150px",
                                                                                            height:
                                                                                              "auto",
                                                                                            overflowWrap:
                                                                                              "break-word",
                                                                                            zIndex: "109",
                                                                                            border:
                                                                                              "1px solid black",

                                                                                            background:
                                                                                              "#0000007d",
                                                                                            color:
                                                                                              "white",
                                                                                            alignItems:
                                                                                              "center",
                                                                                            position:
                                                                                              "absolute",
                                                                                            top: "-50px",
                                                                                            left: "-50px",
                                                                                          }}
                                                                                        >
                                                                                          <p
                                                                                            style={{
                                                                                              fontSize:
                                                                                                "10px",
                                                                                            }}
                                                                                          >
                                                                                            {result1}
                                                                                          </p>
                                                                                        </div>
                                                                                        <p
                                                                                          style={{
                                                                                            marginTop:
                                                                                              "10px",
                                                                                            textAlign:
                                                                                              "center",
                                                                                            fontSize:
                                                                                              "10px",
                                                                                            fontWeight:
                                                                                              "600",
                                                                                            color:
                                                                                              "#19578b",
                                                                                          }}
                                                                                          onMouseOver={(
                                                                                            e
                                                                                          ) => {
                                                                                            e.preventDefault();
                                                                                            this.documentName(
                                                                                              message.id
                                                                                            );
                                                                                          }}
                                                                                          onMouseLeave={
                                                                                            this
                                                                                              .documentNameL
                                                                                          }
                                                                                        >
                                                                                          {result}
                                                                                          ...
                                                                                        </p>
                                                                                      </>
                                                                                    ) : (
                                                                                      <p
                                                                                        style={{
                                                                                          marginTop:
                                                                                            "10px",
                                                                                          textAlign:
                                                                                            "center",
                                                                                          fontSize:
                                                                                            "10px",
                                                                                          fontWeight:
                                                                                            "600",
                                                                                          color:
                                                                                            "#014c8c",
                                                                                        }}
                                                                                      >
                                                                                        {result}
                                                                                      </p>
                                                                                    )}
                                                                                  </div>
                                                                                </div>
                                                                              </a>
                                                                              {isSender &&
                                                                                message.seen == 1 ? (
                                                                                <img
                                                                                  src={doubletick}
                                                                                  style={{
                                                                             
                                                                                    marginLeft: "-2px",
                                                                                    width: "20px",
                                                                                    float: "right",
                                                                                  }}
                                                                                  title="Seen"
                                                                                />
                                                                              ) : null}
                                                                              {isSender &&
                                                                                message.seen == 0 ? (
                                                                                <img
                                                                                  src={singletick}
                                                                                  style={{
                                                                                    marginLeft: "-2px",
                                                                                    width: "20px",
                                                                                    float: "right",
                                                                                  }}
                                                                                  title="Unread"
                                                                                />
                                                                              ) : null}
                                                                              <br />
                                                                            </div>
                                                                          ) : (
                                                                            <div>
                                                                              <p
                                                                                className="message-content"
                                                                                name="Popmsg"
                                                                        
                                                                              >
                                                                                {message.message !=
                                                                                  "-" ? (
                                                                                  <>
                                                                                    {
                                                                                      message.message
                                                                                    }
                                                                                  </>
                                                                                ) : null}
                                                                              </p>
                                                                              <br />
                                                                              <a
                                                                                href={
                                                                                  message.picture_Path
                                                                                }
                                                                                name="picture"
                                                                                readOnly
                                                                                value={
                                                                                  message.picture_Path
                                                                                }
                                                                                title=""
                                                                                target="_blank"
                                                                                style={{
                                                                                  width:
                                                                                    "120px",
                                                                                  height:
                                                                                    "200px",
                                                                                }}
                                                                              >
                                                                                <div>
                                                                                  <img
                                                                                    alt="chatimgD"
                                                                                    width="80"
                                                                                    height="80"
                                                                                    src={
                                                                                      message.picture_Path
                                                                                    }
                                                                                    onClick={e => {
                                                                                  e.preventDefault();
                                                                                  this.handleOpenModal(message.picture_Path);
                                                                                }}
                                                                                  />
                                                                                </div>
                                                                              </a>
                                                                              {isModalOpen && (
                                                                          <div className="popup" style={{ backgroundColor: 'rgb(118 118 118  / 30%)' }}>
                                                                            <div className="popup-content">
                                                                                  <center>
                                                                                    <ImageModal
                                                                                      isOpen={isModalOpen}
                                                                                      onClose={this.handleCloseModal}
                                                                                      src={this.state.ImageSrc}
                                                                                      isVideo={this.state.ImageSrc.includes(
                                                                                        ".mp4"
                                                                                      )}
                                                                                    />
                                                                                  </center>
                                                                            </div>
                                                                          </div>
                                                                        )}
                                                                              {isSender &&
                                                                                message.seen == 1 ? (
                                                                                <img
                                                                                  src={doubletick}
                                                                                  style={{
                                                                                    // marginTop: "50px",
                                                                                    marginLeft: "-2px",
                                                                                    width: "20px",
                                                                                    float: "right",
                                                                                  }}
                                                                                  title="Seen"
                                                                                />
                                                                              ) : null}
                                                                              {isSender &&
                                                                                message.seen == 0 ? (
                                                                                <img
                                                                                  src={singletick}
                                                                                  style={{
                                                                                    // marginTop: "50px",
                                                                                    marginLeft: "-2px",
                                                                                    width: "20px",
                                                                                    float: "right",
                                                                                  }}
                                                                                  title="Unread"
                                                                                />
                                                                              ) : null}
                                                                              <br />
                                                                            </div>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  )}
                                                                  <br />
                                                                  <div className="message-timestamp-right">
                                                                    
                                                                    <span
                                                                      style={{
                                                                        color: "gray",
                                                                      }}
                                                                    >
                                                                      <i className="fa fa-clock-o"></i>
                                                                      &nbsp;
                                                                      {this.formatDateAA(
                                                                        message.createDateTime
                                                                      )}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              ) : null}
                                                            </> :
                                                              <>
                                                                {message.displayStatus === 1 ?
                                                                  <div
                                                                    className={`message-${isSender ? "SendMsg" : "blue"
                                                                      }`}
                                                                    key={index}
                                                                    style={{
                                                                      height: "auto",
                                                                      overflowWrap: "break-word",
                                                                    }}
                                                                  >
                                                                    {MsgMenu &&
                                                                      this.state.deleteId == message.id ? (
                                                                      <div
                                                                        style={MessageMenu}
                                                                        ref={this.containerRef}
                                                                      >
                                                                        <center>
                                                                          <ul>
                                                                            {isSender ? (
                                                                              <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>

                                                                                <span
                                                                                  onClick={this.EditMessage}
                                                                                  id="MsgOption"
                                                                                  style={{ marginLeft: '-26px' }}
                                                                                >
                                                                                  <i
                                                                                    className="fa fa-pencil-square-o"
                                                                                    aria-hidden="true"
                                                                                  ></i>&nbsp;&nbsp;
                                                                                  <b>
                                                                                    Edit
                                                                                  </b>
                                                                                </span>
                                                                              </li>) : null}

                                                                            <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                                              <span
                                                                                onClick={this.deleteMenu}
                                                                                id="MsgOption"
                                                                                style={{ marginLeft: '-14px' }}
                                                                              >
                                                                                <i
                                                                                  className="fa fa-trash"
                                                                                  aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;

                                                                                <b>
                                                                                  Delete
                                                                                </b>
                                                                              </span>
                                                                            </li>
                                                                            <li style={{ padding: '8px' }}>
                                                                              <span
                                                                                onClick={this.forwardopenpop}
                                                                                id="MsgOption"
                                                                              >
                                                                                <i
                                                                                  className="fa fa-share"
                                                                                  aria-hidden="true"></i>

                                                                                <b>&nbsp;
                                                                                  Forward
                                                                                </b>
                                                                              </span>
                                                                            </li>

                                                                          </ul>
                                                                        </center>
                                                                      </div>
                                                                    ) : null}
                                                                    <i
                                                                      className="fa fa-ellipsis-h"
                                                                      style={{
                                                                        float: "Right",
                                                                        cursor: "pointer",
                                                                      }}
                                                                      aria-hidden="true"
                                                                      onClick={() =>
                                                                        this.DisplayMenu(
                                                                          message.id,
                                                                          message.message,
                                                                          message.picture_Path,
                                                                          message.document_Path,
                                                                          message.senderId,
                                                                          message.createDateTime,
                                                                          message.particularId,
                                                                          message.sysDateTimeWithSeconds
                                                                        )
                                                                      }
                                                                    ></i>

                                                                    {!isSender ? (
                                                                      <h6
                                                                        style={{
                                                                          fontWeight: "600",
                                                                          color: "#09679f",
                                                                        }}
                                                                      >
                                                                        {message.editStatus === 1 ? (
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "200",
                                                                              color: "grey",
                                                                              fontSize: "10px",
                                                                            }}
                                                                          >
                                                                            Edited
                                                                          </span>
                                                                        ) : null}
                                                                      </h6>
                                                                    ) : (
                                                                      <>
                                                                        {message.editStatus === 1 ? (
                                                                          <h6
                                                                            style={{
                                                                              fontWeight: "200",
                                                                              color: "grey",
                                                                              fontSize: "10px",
                                                                            }}
                                                                          >
                                                                            Edited
                                                                          </h6>
                                                                        ) : null}
                                                                      </>
                                                                    )}
                                                                    {/* {!isSender ? (
                                                                      <> */}
                                                                    {message.frwdStatus === 1 ? (
                                                                      <h6
                                                                        style={{
                                                                          fontWeight: "200",
                                                                          color: "grey",
                                                                          fontSize: "10px",
                                                                        }}
                                                                      >
                                                                        <i
                                                                          className="fa fa-share"
                                                                          aria-hidden="true"
                                                                        ></i>
                                                                        &nbsp;&nbsp;Forwarded
                                                                      </h6>
                                                                    ) : null}
                                                                    {/* </>
                                                                    ) : null} */}
                                                                    {message.picture_Path === "-" &&
                                                                      message.document_Path === "-" ? (
                                                                      <p
                                                                        className="message-content"
                                                                        name="Popmsg"
                                                                      // style={{ wordWrap: "break-word" }}
                                                                      >
                                                                        {regexp.test(message.message) ==
                                                                          true ? (
                                                                          <>
                                                                            <a
                                                                              style={{
                                                                                color: "blue",
                                                                                textDecoration: "underline",
                                                                              }}
                                                                              target="_blank"
                                                                              rel="noopener noreferrer"
                                                                              href={message.message}
                                                                            >
                                                                              {message.message}
                                                                            </a>
                                                                            <br />
                                                                          </>
                                                                        ) : (
                                                                          <div>
                                                                            <p>{message.message}</p>
                                                                          </div>
                                                                        )}
                                                                        {isSender && message.seen == 1 ? (
                                                                          <img
                                                                            src={doubletick}
                                                                            style={{
                                                                              // marginTop: "50px",
                                                                              marginTop: "-12px",
                                                                              marginLeft: "-2px",
                                                                              width: "20px",
                                                                              float: "right",
                                                                            }}
                                                                            title="Seen"
                                                                          />
                                                                        ) : null}
                                                                        {isSender && message.seen == 0 ? (
                                                                          <img
                                                                            src={singletick}
                                                                            style={{
                                                                              // marginTop: "50px",
                                                                              marginTop: "-12px",
                                                                              marginLeft: "-2px",
                                                                              width: "20px",
                                                                              float: "right",
                                                                            }}
                                                                            title="Unread"
                                                                          />
                                                                        ) : null}
                                                                        {/* <br /> */}
                                                                      </p>
                                                                    ) : (
                                                                      <>
                                                                        {message.picture_Path !== "-" &&
                                                                          message.document_Path !== "-" ? (
                                                                          <div>
                                                                            <div>
                                                                              <p
                                                                                className="message-content"
                                                                                name="Popmsg"
                                                                              // style={{ wordWrap: "break-word" }}
                                                                              >
                                                                                {message.message !=
                                                                                  "-" ? (
                                                                                  <>
                                                                                    {
                                                                                      message.message
                                                                                    }
                                                                                  </>
                                                                                ) : null}
                                                                              </p>

                                                                              <br />
                                                                              <a
                                                                                href={
                                                                                  message.picture_Path
                                                                                }
                                                                                name="picture"
                                                                                readOnly
                                                                                value={
                                                                                  message.picture_Path
                                                                                }
                                                                                title=""
                                                                                target="_blank"
                                                                                style={{
                                                                                  width:
                                                                                    "120px",
                                                                                  height:
                                                                                    "200px",
                                                                                }}
                                                                              ></a>
                                                                              <div>
                                                                                <img
                                                                                  alt="chatimgE"
                                                                                  width="80"
                                                                                  height="80"
                                                                                  src={message.picture_Path}
                                                                             
                                                                                />
                                                                              </div>
                                                                              <div>
                                                                                <embed
                                                                                  src={
                                                                                    message.document_Path
                                                                                  }
                                                                                  id="Gstiframe"
                                                                                  title=""
                                                                                  scrolling="no"
                                                                                  target="_blank"
                                                                                  style={{
                                                                                    width: "100px",
                                                                                    height: "100px",
                                                                                    pointerEvents: "none",
                                                                                  }}
                                                                                ></embed>
                                                                              </div>
                                                                            </div>
                                                                            {isSender &&
                                                                              message.seen == 1 ? (
                                                                              <img
                                                                                src={doubletick}
                                                                                style={{
                                                                                  // marginTop: "50px",
                                                                                  marginTop: "-12px",
                                                                                  marginLeft: "-2px",
                                                                                  width: "20px",
                                                                                  float: "right",
                                                                                }}
                                                                                title="Seen"
                                                                              />
                                                                            ) : null}
                                                                            {isSender &&
                                                                              message.seen == 0 ? (
                                                                              <img
                                                                                src={singletick}
                                                                                style={{
                                                                                  // marginTop: "50px",
                                                                                  marginTop: "-12px",
                                                                                  marginLeft: "-2px",
                                                                                  width: "20px",
                                                                                  float: "right",
                                                                                }}
                                                                                title="Unread"
                                                                              />
                                                                            ) : null}
                                                                            {/* <br /> */}
                                                                          </div>
                                                                        ) : (
                                                                          <>
                                                                            {message.picture_Path === "-" &&
                                                                              message.document_Path !==
                                                                              "-" ? (
                                                                              <div>
                                                                                <p
                                                                                  className="message-content"
                                                                                  name="Popmsg"
                                                                               
                                                                                >
                                                                                  {message.message !=
                                                                                    "-" ? (
                                                                                    <>
                                                                                      {
                                                                                        message.message
                                                                                      }
                                                                                    </>
                                                                                  ) : null}
                                                                                </p>
                                                                                <br />
                                                                                <a
                                                                                  href={
                                                                                    message.document_Path
                                                                                  }
                                                                                  name="picture"
                                                                                  readOnly
                                                                                  value={
                                                                                    message.document_Path
                                                                                  }
                                                                                  title=""
                                                                                  scrolling="no"
                                                                                  target="_blank"
                                                                                  style={{
                                                                                    width: "120px",
                                                                                    height: "200px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    className="row"
                                                                                    style={{
                                                                                      width: "180px",
                                                                                      height: "60px",
                                                                                      borderRadius: "5px",
                                                                                      boxShadow:
                                                                                        "rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px",
                                                                                      background: "#e8e8e8",
                                                                                      alignItems: "center",
                                                                                    }}
                                                                                  >
                                                                                    <div className="col-lg-3">
                                                                                      <img
                                                                                        src={File1}
                                                                                        style={{
                                                                                          height: "30px",
                                                                                          width: "30px",
                                                                                        }}
                                                                                      ></img>
                                                                                    </div>
                                                                                    <div className="col-lg-9">
                                                                                      {message.document_Path
                                                                                        .length > 85 ? (
                                                                                        <>
                                                                                          <div
                                                                                            style={{
                                                                                              opacity:
                                                                                                DName &&
                                                                                                  documentname ==
                                                                                                  message.id
                                                                                                  ? 1
                                                                                                  : 0,
                                                                                              width:
                                                                                                "150px",
                                                                                              height:
                                                                                                "auto",
                                                                                              overflowWrap:
                                                                                                "break-word",
                                                                                              zIndex: "109",
                                                                                              border:
                                                                                                "1px solid black",

                                                                                              background:
                                                                                                "#0000007d",
                                                                                              color:
                                                                                                "white",
                                                                                              alignItems:
                                                                                                "center",
                                                                                              position:
                                                                                                "absolute",
                                                                                              top: "-50px",
                                                                                              left: "-50px",
                                                                                            }}
                                                                                          >
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize:
                                                                                                  "10px",
                                                                                              }}
                                                                                            >
                                                                                              {result1}
                                                                                            </p>
                                                                                          </div>
                                                                                          <p
                                                                                            style={{
                                                                                              marginTop:
                                                                                                "10px",
                                                                                              textAlign:
                                                                                                "center",
                                                                                              fontSize:
                                                                                                "10px",
                                                                                              fontWeight:
                                                                                                "600",
                                                                                              color:
                                                                                                "#19578b",
                                                                                            }}
                                                                                            onMouseOver={(
                                                                                              e
                                                                                            ) => {
                                                                                              e.preventDefault();
                                                                                              this.documentName(
                                                                                                message.id
                                                                                              );
                                                                                            }}
                                                                                            onMouseLeave={
                                                                                              this
                                                                                                .documentNameL
                                                                                            }
                                                                                          >
                                                                                            {result}
                                                                                            ...
                                                                                          </p>
                                                                                        </>
                                                                                      ) : (
                                                                                        <p
                                                                                          style={{
                                                                                            marginTop:
                                                                                              "10px",
                                                                                            textAlign:
                                                                                              "center",
                                                                                            fontSize:
                                                                                              "10px",
                                                                                            fontWeight:
                                                                                              "600",
                                                                                            color:
                                                                                              "#014c8c",
                                                                                          }}
                                                                                        >
                                                                                          {result}
                                                                                        </p>
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                </a>
                                                                                {isSender &&
                                                                                  message.seen == 1 ? (
                                                                                  <img
                                                                                    src={doubletick}
                                                                                    style={{
                                                                                      // marginTop: "50px",
                                                                                      marginLeft: "-2px",
                                                                                      width: "20px",
                                                                                      float: "right",
                                                                                    }}
                                                                                    title="Seen"
                                                                                  />
                                                                                ) : null}
                                                                                {isSender &&
                                                                                  message.seen == 0 ? (
                                                                                  <img
                                                                                    src={singletick}
                                                                                    style={{
                                                                                      // marginTop: "50px",
                                                                                      marginLeft: "-2px",
                                                                                      width: "20px",
                                                                                      float: "right",
                                                                                    }}
                                                                                    title="Unread"
                                                                                  />
                                                                                ) : null}
                                                                                <br />
                                                                              </div>
                                                                            ) : (
                                                                              <div>
                                                                                <p
                                                                                  className="message-content"
                                                                                  name="Popmsg"
                                                                             
                                                                                >
                                                                                  {message.message !=
                                                                                    "-" ? (
                                                                                    <>
                                                                                      {
                                                                                        message.message
                                                                                      }
                                                                                    </>
                                                                                  ) : null}
                                                                                </p>
                                                                                <br />
                                                                                <a
                                                                                  href={
                                                                                    message.picture_Path
                                                                                  }
                                                                                  name="picture"
                                                                                  readOnly
                                                                                  value={
                                                                                    message.picture_Path
                                                                                  }
                                                                                  title=""
                                                                                  target="_blank"
                                                                                  style={{
                                                                                    width:
                                                                                      "120px",
                                                                                    height:
                                                                                      "200px",
                                                                                  }}
                                                                                >
                                                                                  <div>
                                                                                    <img
                                                                                      alt="chatimgF"
                                                                                      width="80"
                                                                                      height="80"
                                                                                      src={
                                                                                        message.picture_Path
                                                                                      }
                                                                                      onClick={e => {
                                                                                  e.preventDefault();
                                                                                  this.handleOpenModal(message.picture_Path);
                                                                                }}
                                                                                    />
                                                                                  </div>
                                                                                </a>
                                                                                {isModalOpen && (
                                                                          <div className="popup" style={{ backgroundColor:'rgb(118 118 118  / 30%)'}}>
                                                                            <div className="popup-content">
                                                                                  <center>
                                                                                    <ImageModal
                                                                                      isOpen={isModalOpen}
                                                                                      onClose={this.handleCloseModal}
                                                                                      src={this.state.ImageSrc}
                                                                                      isVideo={this.state.ImageSrc.includes(
                                                                                        ".mp4"
                                                                                      )}
                                                                                    />
                                                                                  </center>
                                                                            </div>
                                                                          </div>
                                                                        )}
                                                                                {isSender &&
                                                                                  message.seen == 1 ? (
                                                                                  <img
                                                                                    src={doubletick}
                                                                                    style={{
                                                                                      marginTop: "-12px",
                                                                                      marginLeft: "-2px",
                                                                                      width: "20px",
                                                                                      float: "right",
                                                                                    }}
                                                                                    title="Seen"
                                                                                  />
                                                                                ) : null}
                                                                                {isSender &&
                                                                                  message.seen == 0 ? (
                                                                                  <img
                                                                                    src={singletick}
                                                                                    style={{
                                                                                      // marginTop: "50px",
                                                                                      marginTop: "-12px",
                                                                                      marginLeft: "-2px",
                                                                                      width: "20px",
                                                                                      float: "right",
                                                                                    }}
                                                                                    title="Unread"
                                                                                  />
                                                                                ) : null}
                                                                                {/* <br /> */}
                                                                              </div>
                                                                            )}
                                                                          </>
                                                                        )}
                                                                      </>
                                                                    )}
                                                                    <br />
                                                                    <div className="message-timestamp-right">
                                                                     
                                                                      <span
                                                                        style={{
                                                                          color: "gray",
                                                                        }}
                                                                      >
                                                                        <i className="fa fa-clock-o"></i>
                                                                        &nbsp;
                                                                        {this.formatDateAA(
                                                                          message.createDateTime
                                                                        )}
                                                                      </span>
                                                                    </div>
                                                                  </div> : null}
                                                              </>

                                                            }
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                    <div ref={this.myRef} />


                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            {Deletepop ? (
                                              <div style={deleteStyles} ref={this.containerRef} id="ICdelpop">

                                                <i
                                                  className="fa fa-times"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "gray",
                                                    marginLeft: "90%",
                                                    fontSize: "17px",
                                                  }}
                                                  onClick={this.handleClose}
                                                ></i>
                                                <center>
                                                  <span>
                                                    <img
                                                      src="https://static.thenounproject.com/png/659539-200.png"
                                                      style={{ height: '25px', width: '25px' }}></img>&nbsp;
                                                    <span >Delete Message?</span>
                                                    <br />
                                                  </span>
                                                  <div style={{ display: 'inline-flex', gap: '15px', padding: '20px' }} id="spandiV">

                                                    <span
                                                      id="Deletelistbtns"
                                                      onClick={this.DeleteMessageOne}
                                                      style={{ float: "right" }}
                                                    >
                                                      Delete for me
                                                    </span>


                                                    {this.state.deletesender == UserId ? (
                                                      <>
                                                        <span
                                                          id="Deletelistbtns"
                                                          onClick={this.ICDeleteMessageEveryone}
                                                          style={{ float: "right" }}
                                                        >
                                                          Delete for Everyone
                                                        </span>
                                                        <br />
                                                      </>
                                                    ) : null}

                                                  </div>
                                                </center>
                                              </div>
                                            ) : null}

                                            {this.state.forwardpopup && (
                                              <>
                                                {Minimized ? null : (
                                                  <div className="popup">
                                                    <div className="popup-content">
                                                      <center id="DiscAddPop">
                                                        <div>
                                                          <center style={{ marginTop: '-2%' }}>
                                                            <h6>
                                                              <b>Forward To</b>
                                                            </h6>
                                                          </center>
                                                          <center>
                                                            <input
                                                              className="DiscSearchbox"
                                                              placeholder="Search"
                                                              onChange={this.Forwardmemberfilter}
                                                            />

                                                          </center> <br />
                                                          <center>
                                                            <form id="ForwardMemberPopA">
                                                              {FrdNonAccess.length === 0 && FrdMemberAccess.length === 0 ?
                                                                <center>

                                                                  <span
                                                                    id="ForwardFilterErrA"
                                                                    style={{ color: "red" }}
                                                                  > Member Not Found</span>
                                                                </center> :
                                                                <>
                                                                  {FrdMemberAccess.map((Announcement) => (
                                                                    <>
                                                                      {Accessed === 1 && Accessed_to === 1 ? (
                                                                        <div
                                                                          className="media userlist-box"
                                                                          data-id="1"
                                                                          data-status="online"
                                                                          data-username="Josephin Doe"
                                                                          data-toggle="tooltip"
                                                                          data-placement="left"
                                                                          style={{
                                                                            // padding: "5px",
                                                                            height: "55px",
                                                                          }}
                                                                        >
                                                                          <div
                                                                            className="checkbox-wrapper-12"
                                                                            id="checkboxTop"
                                                                          >
                                                                            <div className="cbx">
                                                                              <input
                                                                                type="checkbox"
                                                                                title="Add"
                                                                                className="check"
                                                                                id="Checkboxchat"
                                                                                value={Announcement.email}
                                                                                checked={selectedUserIds.includes(
                                                                                  Announcement.email
                                                                                )}
                                                                                onChange={this.handleUserSelectionChange}
                                                                              />
                                                                              <label for="cbx-12"></label>
                                                                              <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewbox="0 0 15 14"
                                                                                fill="none"
                                                                              >
                                                                                <path d="M2 8.36364L6.23077 12L13 2"></path>
                                                                              </svg>
                                                                            </div>

                                                                            <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              version="1.1"
                                                                            >
                                                                              <defs>
                                                                                <filter id="goo-12">
                                                                                  <fegaussianblur
                                                                                    in="SourceGraphic"
                                                                                    stddeviation="4"
                                                                                    result="blur"
                                                                                  ></fegaussianblur>
                                                                                  <fecolormatrix
                                                                                    in="blur"
                                                                                    mode="matrix"
                                                                                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                                                                                    result="goo-12"
                                                                                  ></fecolormatrix>
                                                                                  <feblend
                                                                                    in="SourceGraphic"
                                                                                    in2="goo-12"
                                                                                  ></feblend>
                                                                                </filter>
                                                                              </defs>
                                                                            </svg>
                                                                          </div>
                                                                          &nbsp;&nbsp;&nbsp;
                                                                          <a
                                                                            className="media-left"
                                                                            style={{ marginTop: "-4%" }}
                                                                          >
                                                                            <img
                                                                              className="media-object img-circle"
                                                                              src={Announcement.profile_Picture}
                                                                              alt="Generic placeholder image"
                                                                              style={{
                                                                                marginTop: "20px",
                                                                                width: "35px",
                                                                                height: "35px",
                                                                              }}
                                                                            />
                                                                            {Announcement.userActiveStatus === 1 ? (
                                                                              <img
                                                                                src={onlineimg}
                                                                                className="media-object img-circle"
                                                                                style={{
                                                                                  marginTop: "50px",
                                                                                  marginLeft: "-12px",
                                                                                  width: "12px",
                                                                                  // position: "absolute",
                                                                                  background: "white",
                                                                                }}
                                                                                title="Online"
                                                                              />
                                                                            ) : (
                                                                              <img
                                                                                src={offlineimg}
                                                                                className="media-object img-circle"
                                                                                style={{
                                                                                  marginTop: "50px",
                                                                                  marginLeft: "-12px",
                                                                                  width: "12px",
                                                                                  // position: "absolute",
                                                                                  background: "white",
                                                                                }}
                                                                                title={`last seen at ${Announcement.logout}`}
                                                                              />
                                                                            )}
                                                                          </a>
                                                                          <div
                                                                            className="media-body"
                                                                            style={{
                                                                              textAlign: "initial",
                                                                            }}
                                                                          >
                                                                            <div
                                                                              className="f-13 chat-header"
                                                                              style={{
                                                                                color: "black",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <li
                                                                                key={Announcement.id}
                                                                                value={Announcement.id}
                                                                                style={{ listStyleType: "none" }}
                                                                              >
                                                                                <label style={{ marginTop: '-2%' }}>
                                                                                  <span
                                                                                    className="Fontsize_In_chat"
                                                                                    style={{
                                                                                      color: "black",
                                                                                      whiteSpace: "nowrap",
                                                                                      fontWeight: "bold",
                                                                                    }}
                                                                                  ></span>
                                                                                  <br />
                                                                                  <span
                                                                                    className="Fontsize_In_chat"
                                                                                    style={{
                                                                                      color: "black",
                                                                                      whiteSpace: "nowrap",
                                                                                      fontWeight: "bold",
                                                                                    }}
                                                                                  >
                                                                                    {Announcement.fName}
                                                                                  </span>
                                                                                  &nbsp;
                                                                                  <span
                                                                                    className="Fontsize_In_chat"
                                                                                    style={{
                                                                                      color: "Gray",
                                                                                      whiteSpace: "nowrap",
                                                                                    }}
                                                                                  >
                                                                                    ({Announcement.email})
                                                                                  </span>
                                                                                </label>
                                                                              </li>
                                                                            </div>
                                                                          </div>
                                                                          <input hidden type="text" name="User_Id" />
                                                                        </div>) : null}
                                                                    </>
                                                                  ))}
                                                                  {FrdNonAccess.map((Announcement) => (
                                                                    <>
                                                                      {(Accessed === 1 &&
                                                                        Accessed_to === 0) ||
                                                                        (Accessed === 0 && Accessed_to === 0) ||
                                                                        (Accessed === 0 &&
                                                                          Accessed_to === 1) ? (
                                                                        <div
                                                                          className="media userlist-box"
                                                                          data-id="1"
                                                                          data-status="online"
                                                                          data-username="Josephin Doe"
                                                                          data-toggle="tooltip"
                                                                          data-placement="left"
                                                                          style={{
                                                                            // padding: "5px",
                                                                            height: "55px",
                                                                          }}
                                                                        >
                                                                          <div
                                                                            className="checkbox-wrapper-12"
                                                                            id="checkboxTop" x
                                                                          >
                                                                            <div className="cbx">
                                                                              <input
                                                                                type="checkbox"
                                                                                title="Add"
                                                                                className="check"
                                                                                id="Checkboxchat"
                                                                                value={Announcement.email}
                                                                                checked={selectedUserIds.includes(
                                                                                  Announcement.email
                                                                                )}
                                                                                onChange={this.handleUserSelectionChange}
                                                                              />
                                                                              <label for="cbx-12"></label>
                                                                              <svg
                                                                                width="15"
                                                                                height="14"
                                                                                viewbox="0 0 15 14"
                                                                                fill="none"
                                                                              >
                                                                                <path d="M2 8.36364L6.23077 12L13 2"></path>
                                                                              </svg>
                                                                            </div>

                                                                            <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              version="1.1"
                                                                            >
                                                                              <defs>
                                                                                <filter id="goo-12">
                                                                                  <fegaussianblur
                                                                                    in="SourceGraphic"
                                                                                    stddeviation="4"
                                                                                    result="blur"
                                                                                  ></fegaussianblur>
                                                                                  <fecolormatrix
                                                                                    in="blur"
                                                                                    mode="matrix"
                                                                                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                                                                                    result="goo-12"
                                                                                  ></fecolormatrix>
                                                                                  <feblend
                                                                                    in="SourceGraphic"
                                                                                    in2="goo-12"
                                                                                  ></feblend>
                                                                                </filter>
                                                                              </defs>
                                                                            </svg>
                                                                          </div>
                                                                          &nbsp;&nbsp;&nbsp;
                                                                          <a
                                                                            className="media-left"
                                                                            style={{ marginTop: "-4%" }}
                                                                          >
                                                                            <img
                                                                              className="media-object img-circle"
                                                                              src={Announcement.profile_Picture}
                                                                              alt="Generic placeholder image"
                                                                              style={{
                                                                                marginTop: "20px",
                                                                                width: "35px",
                                                                                height: "35px",
                                                                              }}
                                                                            />
                                                                            {Announcement.userActiveStatus === 1 ? (
                                                                              <img
                                                                                src={onlineimg}
                                                                                className="media-object img-circle"
                                                                                style={{
                                                                                  marginTop: "50px",
                                                                                  marginLeft: "-12px",
                                                                                  width: "12px",
                                                                                  // position: "absolute",
                                                                                  background: "white",
                                                                                }}
                                                                                title="Online"
                                                                              />
                                                                            ) : (
                                                                              <img
                                                                                src={offlineimg}
                                                                                className="media-object img-circle"
                                                                                style={{
                                                                                  marginTop: "50px",
                                                                                  marginLeft: "-12px",
                                                                                  width: "12px",
                                                                                  // position: "absolute",
                                                                                  background: "white",
                                                                                }}
                                                                                title={`last seen at ${Announcement.logout}`}
                                                                              />
                                                                            )}
                                                                          </a>
                                                                          <div
                                                                            className="media-body"
                                                                            style={{
                                                                              textAlign: "initial",
                                                                            }}
                                                                          >
                                                                            <div
                                                                              className="f-13 chat-header"
                                                                              style={{
                                                                                color: "black",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <li
                                                                                key={Announcement.id}
                                                                                value={Announcement.id}
                                                                                style={{ listStyleType: "none" }}
                                                                              >
                                                                                <label style={{ marginTop: '-2%' }}>
                                                                                  <span
                                                                                    className="Fontsize_In_chat"
                                                                                    style={{
                                                                                      color: "black",
                                                                                      whiteSpace: "nowrap",
                                                                                      fontWeight: "bold",
                                                                                    }}
                                                                                  ></span>
                                                                                  <br />
                                                                                  <span
                                                                                    className="Fontsize_In_chat"
                                                                                    style={{
                                                                                      color: "black",
                                                                                      whiteSpace: "nowrap",
                                                                                      fontWeight: "bold",
                                                                                    }}
                                                                                  >
                                                                                    {Announcement.fName}
                                                                                  </span>
                                                                                  &nbsp;
                                                                                  <span
                                                                                    className="Fontsize_In_chat"
                                                                                    style={{
                                                                                      color: "Gray",
                                                                                      whiteSpace: "nowrap",
                                                                                    }}
                                                                                  >
                                                                                    ({Announcement.email})
                                                                                  </span>
                                                                                </label>
                                                                              </li>
                                                                            </div>
                                                                          </div>
                                                                          <input hidden type="text" name="User_Id" />
                                                                        </div>) : null}
                                                                    </>
                                                                  ))}</>}
                                                            </form>
                                                            <br />
                                                            <span
                                                              className="AddmemberpopFrdbtn"
                                                              id="addbutton"
                                                              disabled="disabled"
                                                              onClick={this.ForwardIChatMsg}
                                                            >
                                                              Forward
                                                            </span>{" "}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                                            <span
                                                              className="Addmemberpopbtns"
                                                              id="Cancelbutton"
                                                              onClick={this.hideForwardpop}
                                                            >
                                                              Cancel
                                                            </span>
                                                          </center>
                                                        </div>
                                                      </center>
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                            <div
                                              className="input-group write-msg"
                                              id="input-group write-msg"
                                              style={{
                                                border: "1px solid #bfbfbe",
                                                padding: "2px 2px",
                                                // marginTop: "367px",
                                                zIndex: "none",
                                                marginLeft: '-1%',
                                                background: '#eff3f7',
                                                width: '101%'
                                              }}
                                            >

                                              <div style={attachStyles} id="NDiscICattach">
                                                <ul id="listmenu">
                                                  <label style={{ marginTop: "8px" }}>
                                                    <span
                                                      className="fa fa-picture-o"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#5d4fd0",
                                                        marginLeft: "7px",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        this.Picturepop();

                                                      }}

                                                    ></span>
                                                  </label>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                                  <br />
                                                  <label
                                                    style={{
                                                      marginTop: "8px",
                                                      marginLeft: "23%",
                                                    }}
                                                  >
                                                    <span
                                                      className="fa fa-file"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#5d4fd0",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        this.Documentpop();
                                                      }}
                                                    ></span>
                                                  </label>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                                </ul>
                                              </div>

                                              <input
                                                type="text"
                                                className="form-control input-value"
                                                placeholder="Type a Message"
                                                value={this.state.message}
                                                onChange={this.handleTextChange}
                                                name="Message"
                                                style={{
                                                  border: "none",
                                                  zIndex: "auto",
                                                  background: '#eff3f7'
                                                }}
                                              />
                                              <span
                                                className="fa fa-smile-o"
                                                aria-hidden="true"
                                                style={{
                                                  marginTop: "10px",
                                                  fontSize: "18px",
                                                  backgroundColor: "#eff3f7",
                                                }}
                                                onClick={this.myEmoji}
                                              ></span>
                                              &nbsp;&nbsp;
                                              {showEmoji && (
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    top: "-410%",
                                                    transform: "translate(-50%, -50%)",
                                                    maxWidth: "90vw",
                                                    maxHeight: "90vh",
                                                  }}
                                                  id='PickerDiv'
                                                >
                                                  <Picker
                                                    onEmojiClick={this.handleEmojiClick}

                                                    height={350}
                                                    width={"100%"}

                                                  />
                                                </div>
                                              )}
                                              &nbsp;&nbsp;
                                              <span style={{ backgroundColor: "#eff3f7" }}>
                                                <label style={{ marginTop: "8px" }}>
                                                  <i
                                                    className="fa fa-paperclip"
                                                    style={{ fontSize: "20px" }}
                                                    aria-hidden="true"
                                                    onClick={this.attachMenu}
                                                    id="AttachIcon"
                                                  ></i>
                                                </label>
                                              </span>
                                              &nbsp;&nbsp;

                                              <span className="input-group-btn">
                                                <button
                                                  type="submit"
                                                  className="btn btn-secondary"
                                                  onClick={this.handleSubmitA}
                                                  style={{ zIndex: "auto", backgroundColor: '#eff3f7' }}
                                                >
                                                  <i className="icofont icofont-paper-plane"></i>
                                                </button>
                                              </span>
                                            </div>
                                            <div
                                              className="input-group write-msg"
                                              id="input-groupEdit"
                                              style={{
                                                border: "1px solid #bfbfbe",
                                                padding: "2px 2px",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                className="form-control input-value"
                                                placeholder="Type a Message"
                                                value={this.state.deletemsg}
                                                onChange={this.handleTextChange}
                                                name="Message"
                                                style={{ border: "none", background: '#eff3f7' }}
                                                onKeyPress={this.handleKeyPressUser}
                                              />
                                              <input
                                                type="file"
                                                id="file"
                                                name="Picture_Path"
                                                accept="image/*"
                                                onChange={this.handleFileChange}
                                                onFocus={(e) => (e.target.type = "file")}
                                                style={{ display: "none" }}
                                              />
                                              <span
                                                className="fa fa-smile-o"
                                                aria-hidden="true"
                                                style={{
                                                  marginTop: "10px",
                                                  fontSize: "18px",
                                                  background: '#eff3f7'
                                                }}
                                                onClick={this.myEmoji}
                                              ></span>
                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                        
                                              {showEmoji && (
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    top: "-400%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    maxWidth: "90vw",
                                                    maxHeight: "90vh",
                                                  }}

                                                >
                                                  <Picker
                                                    onEmojiClick={this.handleEmojiClick}

                                                    height={350}
                                                    width={"100%"}

                                                  />
                                                </div>
                                              )}
                                              <span className="input-group-btn" >
                                                <span
                                                  id="paper-btn"
                                                  className="btn btn-secondary"
                                                  // type="submit"
                                                  style={{ background: '#eff3f7' }}
                                                >
                                                  <i
                                                    className="fa fa-check"
                                                    onClick={this.EditOneonOneMessage}
                                                  ></i>{" "}
                                                  &nbsp;&nbsp;{" "}
                                                  <i
                                                    className="fa fa-times"
                                                    onClick={this.CancelEdit}
                                                  ></i>
                                                </span>
                                              </span>
                                            </div>
                                          </form>

                                        </div> :
                                        <center>
                                          <img src="https://static.vecteezy.com/system/resources/thumbnails/006/692/321/small/chatting-message-icon-template-black-color-editable-chatting-message-icon-symbol-flat-illustration-for-graphic-and-web-design-free-vector.jpg"
                                            style={{
                                              height: '150px', width: '150px', borderRadius: "50%",
                                              //  marginTop: '20%'
                                              marginTop: '160px'
                                            }}
                                          ></img>
                                          <br /><br />
                                          <h5 style={{ color: 'gray', fontWeight: 'bold' }}>Start Discussion </h5>
                                        </center>}


                                      <div style={documentpop} id="chatDocPop">
                                        <span style={{ textAlign: "center" }}>
                                          {isdocumentpopOpen && (
                                            <ChatDocPop
                                              values={this.state.OneonOneAdmin}
                                              handleClose={() => this.documentPopup()}
                                            // AllMembers={this.state.mergedUsers}
                                            />
                                          )}
                                        </span>
                                      </div>

                                      <div style={picturepop} id="chatDocPop">

                                        <span style={{ textAlign: "center" }}>

                                          {ispicturepopOpen && (

                                            <ChatPicturepop
                                    
                                              handleClose={() => this.picturePopup()

                                              }
                                            />

                                          )}
                                        </span>
                                      </div>





                                    </main>
                                  </div>

                                </form>
                              )}
                            </div>

                          </div>
                        </div>
                      </form>
                     
                    </div>

                    {this.state.isPopupOpen && (
                      <>
                        {this.state.showFirstHeader ? (
                          <div className="Annpop1" style={{ zIndex: '+10' }} >
                            <div className="Annpopinnernormalpop1">
                              <div id="NewDisc">
                                <form id="login-form">
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                    style={{
                                      color: "gray",
                                      marginLeft: "97%",
                                      fontSize: "20px",
                                    }}
                                    onClick={this.closePopup}
                                  ></i>
                                  <fieldset>
                                    <legend>Discussion Name</legend>
                                    <label className="custom-field one">
                                      <input
                                        placeholder=" "
                                        onChange={this.handletitle}
                                        list="contacts"
                                        type="text"
                                        id="Group-Title"
                                        maxLength={30}
                                      ></input>
                                    </label>
                                  </fieldset>
                                  <center>
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                    
                                      }}
                                      id="titleerr"
                                    ></span></center>
                                  <center>
                                    <span
                                      className="GTbuttons"
                                      onClick={this.handleSubmitTitle}
                                      id="color"
                                    >
                                      <b>Get Started</b>
                                    </span>
                                  </center>
                                </form>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    )}

                    {this.state.EditSkillModel ? (
                      <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                          <div className="main-body">
                            <div className="page-wrapper">
                              <GroupPop
                                id=""
                                values={this.state.EditSkillValues}
                                close={() => this.EditSkillModel()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
            </div>
          </div>
        )}

        {Access && (
          <div className="pcoded-content ">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="page-header">
                  <div className="page-header-title"></div>
                  </div>
                  <div className="page-body ">
                  <div className="row">
                    <div className="col-sm-12">
                    <div className="card" style={{backgroundColor:"transparent",boxShadow:"none"}}
                    > 
                    <div className="container" style={{ width: "100%" }}>
                      <form id="settingrespform">
                        <div>
                          <>
                            {showFirstHeader ? (
                              <div className="glowBox" id="settop">
                                <div>
                                  <div style={{ float: "left" }}>
                                    <a
                                      href
                                      onClick={() => {
                                        this.handleBackArrowClick2();
                                        this.handleClick();
                                      }}
                                    >
                                      <i
                                        className="icofont icofont-arrow-left"
                                        style={{
                                          fontSize: "45px",
                                          color: "white",
                                        }}
                                      />
                                    </a>{" "}
                                  </div>
                                </div>
                                <h4
                                  className="title"
                                  style={{ marginRight: "50px" }}
                                >
                                  Settings
                                </h4>
                              </div>
                            ) : null}
                          </>
                        </div>

                        <div className="container-fluid">
                          <div className="col-lg-12">
                            <div className="col-lg-5">
                              <form style={{ marginLeft: "3%" }}>
                                <div>
                                </div>

                                <>
                                  <div style={{ marginLeft: "-3%" }} >
                                    <span
                                      style={{
                                        color: "#292b2c",
                                        fontFamily: "sans-serif",
                                        fontSize: 'medium'
                                      }}
                                    >
                                      This are the other Entities are available for your forum.
                                    </span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <>
                                      <br></br><br></br>
                                      <input
                                        type="text"
                                        className="Searchbox"
                                        placeholder="Search"
                                        onChange={this.TeamSearch}
                                        id="settingsearch"
                                        style={{
                                          backgroundColor: "white",
                                        }}
                                        ref={this.firstInputRef}
                                        onClick={this.setFocus}
                                      />

                                      <br></br><br></br>
                                      {this.state.Entity.length === 0 && !this.state.refreshing ?
                                        <span
                                    
                                          style={{ color: "red" }}
                                        >Entity Not Found
                                        </span> :

                                        <form style={{ height: '245px', overflowY: 'scroll' }}>
                                          <ul><li style={{ padding: '7px' }}>
                                            <input
                                              type="checkbox"
                                              name="tabs1"
                                              style={{ accentColor: "#7f63efde" }}
                                              onClick={() => this.AllowAllEntity()}
                                            /> &nbsp;&nbsp;
                                            All

                                          </li>
                                          </ul>
                                          {Entity.map((Teams) => (
                                            <>
                                              <ul>
                                                <li style={{ padding: '7px' }}>
                                                  {Teams.accessed_to ==
                                                    0 ? (
                                                    <>

                                                      <input
                                                        type="checkbox"
                                                        name="tabs1"
                                                        // className="toggle-checkbox"
                                                        onChange={() =>
                                                          this.EntityShow(
                                                            Teams.entityName,
                                                            Teams.id
                                                          )
                                                        }
                                                        style={{ accentColor: "#7f63efde" }}
                                                      /> &nbsp;&nbsp;
                                                      {Teams.entityName}
                                                      &nbsp;&nbsp;

                                                    </>
                                                  ) : (
                                                    <>
                                                      {Teams.accessed_to ==
                                                        1 &&
                                                        Teams.accessed_From
                                                          .split(",")
                                                          .includes(
                                                            EMaiL
                                                          )
                                                        ? (
                                                          <>

                                                            <input
                                                              type="checkbox"
                                                              name="tabs1"
                                                              // className="toggle-checkbox"
                                                              onChange={() =>
                                                                this.EntityShowOff(
                                                                  Teams.entityName,
                                                                  Teams.id
                                                                )
                                                              }
                                                              checked={this.state.EntityShowOff === true ? null : 'checked'}
                                                              style={{ accentColor: "#7f63efde" }}
                                                            />
                                                            &nbsp;&nbsp;
                                                            {
                                                              Teams.entityName
                                                            }
                                                          </>
                                                        ) : (
                                                          <>

                                                            <input
                                                              type="checkbox"
                                                              name="tabs1"
                                                              // className="toggle-checkbox"
                                                              onChange={() =>
                                                                this.EntityShow(
                                                                  Teams.entityName,
                                                                  Teams.id
                                                                )
                                                              }
                                                              style={{ accentColor: "#7f63efde" }}
                                                            />
                                                            &nbsp;&nbsp;
                                                            {
                                                              Teams.entityName
                                                            }
                                                          </>
                                                        )}
                                                    </>
                                                  )}
                                                </li>

                                              </ul>
                                            </>
                                          ))}

                                        </form>}
                                      <br />
                                    </>
                                  </div>
                                  <div id="settingbutton">
                                    {this.state.EntityShow === true ? (
                                      <>
                                        <button
                                          type="submit"
                                          className="but"
                                          style={{
                                            height: "39.5px",
                                            width: "86.36px",
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.GetAccessMembers(
                                              EName,
                                              EId
                                            );
                                          }}
                              
                                        >
                                          OK
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button
                                          type="reset"
                                          className="Addmemberpopbtns"
                                          style={{
                                            height: "39.5px",
                                            width: "86.36px",
                                            border: 'none'
                                          }}
                                          onClick={(e) => {
                                            window.location.reload(true);

                                          }}
                                  
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    ) : <div id="settingbutton">
                                      {this.state.EntityShowOff === true ? (
                                        <>
                                          <button
                                            type="submit"
                                            className="but"
                                            style={{
                                              height: "39.5px",
                                              width: "86.36px",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.GetAccessMembersOff(
                                                EName,
                                                EId
                                              );
                                            }}
                              
                                          >
                                            OK
                                          </button>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <button
                                            type="reset"
                                            className="Addmemberpopbtns"
                                            style={{
                                              height: "39.5px",
                                              width: "86.36px",
                                              border: 'none'
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              window.location.reload(true);
                                              const toggleButton = document.getElementById('toff'); // Replace 'toggleButtonId' with the actual id of your toggle button
                                              if (toggleButton) {
                                                toggleButton.checked = false; 
                                              }
                                            }}

                                          >
                                            Cancel
                                          </button>
                                        </>
                                      ) : null}
                                    </div>}
                                  </div>
                                </>
                              </form>
                            </div>

                            <center>

                              <div>
                                {Entity.map((Teams) => {
                                  return <>
                                    {(Teams.accessed_to == 0 || !Teams.accessed_From.split(",")
                                      .includes(
                                        EMaiL
                                      )) && Teams.accessed_From != null && Teams.access == 1 && Teams.accessed_For.split(",")
                                        .includes(
                                          entity
                                        ) ?
                                      <div id="EAccessPopA1" style={accesspopstyle} >
                                        <form style={{ height: "auto" }}>
                                          {Entity.map((Teams) => (
                                            <>

                                              {(Teams.accessed_to == 0 || !Teams.accessed_From.split(",")
                                                .includes(
                                                  EMaiL
                                                )) && Teams.accessed_From != null && Teams.access == 1 && Teams.accessed_For.split(",")
                                                  .includes(
                                                    entity
                                                  ) ? (
                                                <>
                                                  <i
                                                    className="fa fa-times"
                                                    aria-hidden="true"
                                                    style={{
                                                      color: "gray",
                                                      marginLeft: "90%",
                                                      fontSize: "17px",
                                                    }}
                                                    onClick={this.handleClose1}
                                                  ></i>
                                                  <span style={{ fontSize: '17px' }}> Wants to give access to this Entity?</span>
                                                  <br />  <br />
                                                  <div

                                                    style={{
                                                      color:
                                                        "Black",
                                                    }}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      name="tabs1"

                                                      onChange={() =>
                                                        this.EntityShowPop(
                                                          Teams.entityName,
                                                          Teams.id
                                                        )
                                                      }
                                                      style={{ width: '15px', height: '15px', accentColor: "#7f63efde" }}
                                                    />
                                                    &nbsp;&nbsp;
                                                    <span style={{ fontSize: '16px' }}>{
                                                      Teams.entityName
                                                    }</span>
                                                  </div>


                                                </>
                                              ) : null}

                                            </>
                                          ))}

                                        </form>   <br />  <br />
                                        <div style={{ marginBottom: '5%' }}>
                                          <span
                                            id="GroupopYesbtn"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.GetAccessMembers(
                                                EName,
                                                EId
                                              );
                                            }}
                                          >
                                            Yes
                                          </span>{" "}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <span
                                            id='GroupopNobtn'
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.GetAccessMembersDenied(
                                                EName,
                                                EId
                                              );
                                            }}
                                          >
                                            No

                                          </span>
                                          <br /></div>

                                      </div>
                                      : null}
                                  </>
                                })}
                              </div>
                            </center>
                          </div>
                        </div>
                      </form>

                      {this.state.isPopupOpen && (
                        <>
                          {this.state.showFirstHeader ? (
                            <div className="popup">
                              <div className="popup-content">
                                <div id="NewDisc">
                                  <form id="login-form">
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                      style={{
                                        color: "gray",
                                        marginLeft: "97%",
                                        fontSize: "20px",
                                      }}
                                      onClick={this.closePopup}
                                    ></i>
                                    <fieldset>
                                      <legend>Discussion Name</legend>
                                      <label className="custom-field one">
                                        <input
                                          placeholder=" "
                                          onChange={this.handletitle}
                                          list="contacts"
                                          type="text"
                                          id="Group-Title"
                                          maxLength={30}
                                        ></input>
                                      </label>
                                    </fieldset>

                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                      }}
                                      id="titleerr"
                                    ></span>
                                    <center>
                                      <span
                                        className="GTbuttons"
                                        onClick={this.handleSubmitTitle}
                                        id="color"
                                      >
                                        <b>Get Start</b>
                                      </span>
                                    </center>
                                  </form>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}

                      {this.state.EditSkillModel ? (
                        <div className="pcoded-content">
                          <div className="pcoded-inner-content">
                            <div className="main-body">
                              <div className="page-wrapper">
                                <GroupPop
                                  id=""
                                  values={this.state.EditSkillValues}
                                  close={() => this.EditSkillModel()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {istitlepopOpen && (
          <GroupTitlePopup
            content={
              <>
                <br></br>
                <center>
                  <div>
                    <a href="javascript:void(0);" title="Add Profile">
                      <input
                        type="file"
                        hidden
                        ref={this.inputReference}
                        onChange={this.fileUploadInputChange}
                      />

                      {this.state.fileUploadState == "" ? (
                        <img
                          onClick={this.fileUploadAction}
                          id="profileImg"
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "40px",
                            cursor: "pointer",
                          }}
                          src={DefaultProfile}
                        ></img>
                      ) : (
                        <img
                          onClick={this.fileUploadAction}
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "40px",
                            cursor: "pointer",
                          }}
                          src={this.state.EImage}
                        ></img>
                      )}
                    </a>
                  </div>
                </center>
                <br />
                <div id="NameInput">
                  <input
                    type="text"
                    className="GTinput"
                    name="name"
                    placeholder="Discussion Name"
                    id="Group-Title"
                  ></input>
                  <span
                    style={{ color: "red", fontSize: "13px" }}
                    id="titleerr"
                  ></span>
                </div>
                <br />

                <center>
                  <div id="Group-Type">
                    <label>
                      <input
                        type="radio"
                        name="radioBtn"
                        onChange={this.privatebtn}
                        style={{ accentColor: "#4020bfde" }}
                        id="color"
                      />
                      &nbsp; Private
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <label>
                      <input
                        type="radio"
                        name="radioBtn"
                        onChange={this.publicbtn}
                        style={{ accentColor: "#4020bfde" }}
                        id="color1"
                      />
                      &nbsp; Public
                    </label>
                  </div>

                  {error && (
                    <div style={{ color: "red", fontSize: "13px" }}>
                      {error}
                    </div>
                  )}
                  <br />
                  <button className="GTsavebtn" onClick={this.handleSubmitTitle}>
                    Save
                  </button>
                </center>
              </>
            }
            handleClose={this.toggleTitlePopup}
          />
        )}
         
      </div>
    );
  }
}
export default Chatbox;
