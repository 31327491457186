import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
export default class SkillMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edus: [],
      Id: "",
      UG: "",
      PG: "",
      Specialization: "",

      Notification: [],
      id: this.props.match.params.id,
      value: this.props.location.state.Id,
      Details: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
    this.initialState = this.state;
  }

  handleFormValidation() {
    const { SkillName, SkillDescription, Category } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "SkillName is required";
    }
    if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "SkillDescription is required";
    }

    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleSubmitEdit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch(`${BASE_URLs}/Education/EditSuperEducation`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        alert(resp);
        this.props.history.push("/SuperEducation");
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmitEdit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  componentDidMount() {
    this.GetEducation();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  HandleUg() {
    var UG = document.getElementById("ug").value;

    if (UG === "others") {
      document.getElementById("ugtext").value = "";
      document.getElementById("ugtext").type = "text";
    } else {
      document.getElementById("ugtext").value = "None";
      document.getElementById("ugtext").type = "hidden";
    }
  }

  HandlePg() {
    var UG = document.getElementById("pg").value;

    if (UG === "others") {
      document.getElementById("pgtext").value = "";
      document.getElementById("pgtext").type = "text";
    } else {
      document.getElementById("pgtext").value = "None";
      document.getElementById("pgtext").type = "hidden";
    }
  }

  GetEducation() {
    var Id = this.props.location.state.Id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    let url = `${BASE_URLs}/Education/GetSuperEducation?Id=` + Id;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Details: data,
        });

        //
      });
  }

  render() {
    const { Details } = this.state;
    return (
      <>
        <SideBar active={window.location.pathname} />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title">Add</h4>
                        </div>
                        <div className="card-header-right"></div>

                        <div className="card-block2">
                          <form
                            style={{ marginTop: "5%" }}
                            onSubmit={this.handleSubmitEdit}
                          >
                            <div className="container">
                              <div className="row" id="skill-form">
                                <br />
                                <br />
                                <div className="col-lg-4">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      {Details.map((Details) => (
                                        <select
                                          className="form"
                                          name="UG"
                                          id="ug"
                                          onInputCapture={this.HandleUg}
                                          onChange={this.handleChange}
                                          type="text"
                                          placeholder="City"
                                        >
                                          <option value={Details.ug}>
                                            {" "}
                                            {Details.ug}{" "}
                                          </option>
                                          <option value=""> UG </option>
                                          <option value="B.A">B.A</option>
                                          <option value="B.Arch">B.Arch</option>
                                          <option value="B.C.A">B.C.A</option>
                                          <option value="B.B.A">B.B.A</option>
                                          <option value="B.Com">B.Com</option>
                                          <option value="B.Ed">B.Ed</option>
                                          <option value="B.D.S">B.D.S</option>
                                          <option value="B.H.M">B.H.M</option>
                                          <option value="B.Pharma">
                                            B.Pharma
                                          </option>
                                          <option value="B.Sc">B.Sc</option>
                                          <option value="B.Tech/B.E">
                                            B.Tech/B.E
                                          </option>
                                          <option value="B.V.Sc">B.V.Sc</option>
                                          <option value="Fashion/Designing">
                                            Fashion/Designing
                                          </option>
                                          <option value="Journalism/Mass Communication">
                                            Journalism/Mass Communication
                                          </option>
                                          <option value="PR/Advertising">
                                            PR/Advertising
                                          </option>
                                          <option value="Tourism">
                                            Tourism
                                          </option>
                                          <option value="Vocational-Training">
                                            Vocational-Training
                                          </option>
                                          <option value="LLB">LLB</option>
                                          <option value="Diploma">
                                            Diploma
                                          </option>
                                          <option value="others">Others</option>
                                        </select>
                                      ))}
                                      <span className="underline" />
                                      <input
                                        type="hidden"
                                        className="form"
                                        id="ugtext"
                                        name="OtherUG"
                                        onChange={this.handleChange}
                                        placeholder="Under graduate "
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      {Details.map((Details) => (
                                        <select
                                          className="form"
                                          id="pg"
                                          onInputCapture={this.HandlePg}
                                          name="PG"
                                          onChange={this.handleChange}
                                          type="text"
                                          placeholder="City"
                                        >
                                          <option value={Details.pg}>
                                            {Details.pg}
                                          </option>
                                          <option value=""> PG </option>
                                          <option value="C.A">C.A</option>
                                          <option value="C.S">C.S</option>
                                          <option value="ICWA">ICWA</option>
                                          <option value="L.L.M">L.L.M</option>
                                          <option value="M.A">M.A</option>
                                          <option value="M.Arch">M.Arch</option>
                                          <option value="M.Com">M.Com</option>
                                          <option value="M.Ed">M.Ed</option>
                                          <option value="B.D.S">M.D.S</option>
                                          <option value="M.Pharma">
                                            M.Pharma
                                          </option>
                                          <option value="M.Sc">M.Sc</option>
                                          <option value="M.Tech">M.Tech</option>
                                          <option value="MBA">MBA</option>
                                          <option value="M.C.A">M.C.A</option>
                                          <option value="M.S/ M.D">
                                            M.S/ M.D
                                          </option>
                                          <option value="M.C.A">M.C.A</option>
                                          <option value="M.V.Sc">M.V.Sc</option>
                                          <option value="Journalism/Mass Communication">
                                            Journalism/Mass Communication
                                          </option>
                                          <option value="PR/Advertising">
                                            PR/Advertising
                                          </option>
                                          <option value="Tourism">
                                            Tourism
                                          </option>
                                          <option value="others">Others</option>
                                        </select>
                                      ))}
                                      <span className="underline" />
                                      <input
                                        type="hidden"
                                        className="form"
                                        id="pgtext"
                                        name="OtherPG"
                                        onChange={this.handleChange}
                                        placeholder="Post graduate "
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      {Details.map((Details) => (
                                        <div>
                                          <input
                                            type="hidden"
                                            name="Id"
                                            defaultValue={Details.id}
                                          />
                                          <input
                                            className="form"
                                            name="Specialization"
                                            type="text"
                                            defaultValue={
                                              Details.specialization
                                            }
                                            onChange={this.handleChange}
                                            placeholder="Specialization"
                                          />
                                        </div>
                                      ))}
                                      <span className="underline" />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      {Details.map((Details) => (
                                        <input
                                          className="form"
                                          name="University"
                                          type="text"
                                          defaultValue={Details.university}
                                          onChange={this.handleChange}
                                          placeholder="University"
                                        />
                                      ))}
                                      <span className="underline" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    type="reset"
                                    className=" btn-Secondary"
                                    style={{ height: "39.5px" }}
                                  >
                                    Cancel
                                  </button>
                                  &nbsp; &nbsp; &nbsp;
                                  <button
                                    type="submit"
                                    className="but"
                                    style={{
                                      height: "39.5px",
                                      width: "86.36px",
                                      marginTop: "-0.3%",
                                    }}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          <br /> <br /> <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
