import React, { Component } from "react";
import { BASE_URLs } from "../Config/Base";
import $ from "jquery";

$(document).ready(function () {
  $("#chat_container").hide();
  $("chatbutton").click(function () {
    $("p").slideToggle();
  });
});

export default class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatbox: false,

      Team: [],
      Message: [],
      Id: "",
      ChatId: "",
    };
    this.handleChat = this.handleChat.bind(this);
    this.AddChat = this.AddChat.bind(this);
    this.Team = this.Team.bind(this);
  }

  handleChat = (sn, SenderId) => {
    this.setState({ Username: sn });
    this.setState({ Id: SenderId });

    this.setState({
      chatbox: !this.state.chatbox,
    });

    if (this.state.ChatId !== SenderId) {
      this.setState({ ChatId: SenderId });
      const options = {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      };
      fetch(`${BASE_URLs}/Chat/GetMessages?SenderId=` + SenderId + "", options)
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Message: data,
          });
        });
    }

    setTimeout(() => {
      if (document.getElementById("chat")) {
        const el = document.getElementById("chat");
        el.scrollTop = el.scrollHeight;
      }
    }, 30);
  };

  Team() {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    fetch(`${BASE_URLs}/Chat/GetChatList`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Team: data,
        });
      });
  }
  componentDidMount() {
    this.Team();
  }

  onKeyUp = (e) => {
    const Chatlist = this.state.Chatlist.filter((item) =>
      item.skillName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Chatlist });
  };

  AddChat = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(`${BASE_URLs}/Chat/PostChat`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        const options = {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        };
        fetch(
          `${BASE_URLs}/Chat/GetMessages?SenderId=` + this.state.Id + "",
          options
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              Message: data,
            });
            if (document.getElementById("chat")) {
              const el = document.getElementById("chat");

              el.scrollTop = el.scrollHeight;
            }
          });
      });

    const el = document.getElementById("chat");

    el.scrollTop = el.scrollHeight;
    // Chatbox.scrollTop = Chatbox.scrollHeight;
    // id of the chat container ---------- ^^^
  };

  render() {
    const { Team, Message } = this.state;
    return (
      <div>
        {this.state.chatbox === true ? (
          <div id="chat_container" className="showChat_inner">
            <div className="me dia chat-inner-header">
              <a
                className="back_chatBox"
                onClick={() =>
                  this.handleChat(this.state.Username, this.state.Id)
                }
                style={{ color: "white" }}
              >
                <i className="icofont icofont-rounded-left" />{" "}
                {this.state.Username}
              </a>
            </div>
            <div id="chat" style={{ overflow: "scroll", height: "78%" }}>
              {Message.map((Message) => (
                <>
                  <div className="media chat-messages">
                    <div
                      className={
                        Message.chatside === "me"
                          ? "media-body chat-menu-reply"
                          : "media-body chat-menu-content"
                      }
                    >
                      <p className="chat-cont"> {Message.message}</p>
                      <span
                        className="chat-time"
                        style={{ paddingBottom: "0px" }}
                      >
                        {Message.time}
                      </span>
                    </div>
                    {/* <div className="media-right photo-table">
                  <a href="#!">
                  </a> 
                </div>*/}
                  </div>

                  {/* <div className="media chat-messages">
                  <a className="media-left photo-table" href="#!">
                    <img className="media-object img-circle m-t-5" src="assets/images/avatar-1.png" alt="Generic placeholder image" />
                  </a>
                  <div className="media-body chat-menu-content">
                    <div className>
                    <p className="chat-cont"> {Message.message}</p>                   
                       <p className="chat-time">8:20 a.m.</p>
                    </div>
                  </div>
                </div> */}
                </>
              ))}
            </div>
            <form onSubmit={this.AddChat}>
              <div className="chat-reply-box ">
                <div className="right-icon-control">
                  <input
                    style={{ display: "none" }}
                    name="Receiver_Id"
                    value={this.state.Id}
                  />
                  <input
                    type="text"
                    name="Message"
                    className="form-control search-text"
                    placeholder="Type "
                  />
                  {/* //  */}
                  <div className="form-icon">
                    <button type="submit" id="butchat">
                      <i className="icofont icofont-paper-plane" />
                    </button>
                  </div>

                  {/*  */}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div id="sideba" className="users p-chat-user showChat1">
            <div className="had-container">
              <div
                className="card card_main p-fixed users-main"
                id="showchat-sidebar"
              >
                <div className="user-box">
                  <div className="card-block">
                    <div className="right-icon-control">
                      <input
                        type="text"
                        className="form-control   search-text"
                        onKeyUp={this.onKeyUp}
                        placeholder="Search Friend"
                        id="search-friends"
                      />
                      <div className="form-icon">
                        <i className="icofont icofont-search" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="slimScrollDiv"
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      width: "auto",
                    }}
                  >
                    <div
                      className="main-friend-list"
                      style={{
                        overflow: "scroll",
                        width: "auto",
                        height: "600px",
                      }}
                    >
                      {/* {/ chat list /} */}
                      {Team.map((Team) => (
                        <div
                          className="media userlist-box"
                          onClick={() =>
                            this.handleChat(Team.firstName, Team.id)
                          }
                          data-status="online"
                          data-username="Josephin Doe"
                          data-toggle="tooltip"
                          data-placement="left"
                          title="Josephin Doe"
                        >
                          <a className="media-left">
                            <img
                              className="media-object img-circle"
                              src="assets/images/avatar-1.png"
                              alt="Generic placeholder image"
                            />
                            <div className="live-status bg-success" />
                          </a>
                          <div className="media-body">
                            <div className="f-13 chat-header">
                              {Team.firstName} {Team.lastName}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      className="slimScrollBar"
                      style={{
                        background: "rgb(27, 139, 249)",
                        width: 7,
                        position: "absolute",
                        top: 0,
                        opacity: "0.4",
                        display: "none",
                        borderRadius: 7,
                        zIndex: 99,
                        right: 1,
                        height: "500.622px",
                      }}
                    />
                    <div
                      className="slimScrollRail"
                      style={{
                        width: 7,
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        display: "none",
                        borderRadius: 7,
                        background: "rgb(51, 51, 51)",
                        opacity: "0.2",
                        zIndex: 90,
                        right: 1,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}