import React, { Component } from "react";

import { Link } from "react-router-dom";

import SideBar from "../../Common/Sidebar";
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      Pending: [],
      Notification: [],
      allNotify: [],
    };
  }

  render() {
    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content ">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-body">
                  <div id="allcards" className="row">
                    <div className="column">
                      <div className="carddash">
                        <h1 className="icofont icofont-brainstorming card1"></h1>
                        {/* <Link to="/Gridskil"> */}

                        <p className="cardcontent">
                          <div
                            className="dropdown"
                            style={{ boxShadow: "none" }}
                          >
                            <h5 id="carddashboard">Skill &nbsp; <i className="fa fa-caret-down" aria-hidden="true"></i></h5>

                            <div className="dropdown-content">
                              <Link to="/Gridskill">Skill Master</Link>
                              <Link to="/SACategory">Category Master</Link>
                            </div>
                          </div>
                        </p>
                        {/* </Link> */}
                      </div>
                    </div>
                    <div className="column">
                      <div className="carddash">
                        <h1 className="icofont icofont-graduate card2"> </h1>
                        {/* <Link to="/SuperEducation"> */}
                        <p className="cardcontent">
                          <div
                            className="dropdown"
                            style={{ boxShadow: "none" }}
                          >
                            <h5 id="carddashboard" style={{ width: '100px' }}>Education &nbsp; <i className="fa fa-caret-down" aria-hidden="true"></i></h5>
                            <div className="dropdown-content" id="education">
                              <Link to="/SACourse">Coursename Master</Link>
                              <Link to="/SASpecialization">
                                Specialization Master
                              </Link>
                              <Link to="/SADegree">Degree Master</Link>
                              <Link to="/SADegreeLevel">
                                Degree Level Master
                              </Link>
                            </div>
                          </div>
                        </p>
                        {/* </Link> */}
                      </div>
                    </div>
                    <div className="column">
                      <div className="carddash">
                        <h1 className="icofont icofont-company card3 "></h1>

                        <div className="cardcontent">
                          <Link to="/SADesignation" id="carddashboard">Designation</Link>
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="carddash">
                        <h1 className="icofont icofont-chart-flow card4"> </h1>
                        <div className="cardcontent">
                          <Link to="/SADepartment" id="carddashboard">Department</Link>
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="carddash">
                        <h1 className="icofont icofont-brainstorming card1"></h1>

                        <p className="cardcontent">
                          <div
                            className="dropdown"
                            style={{ boxShadow: "none" }}
                          >
                            <h5 id="carddashboard" style={{ width: '110px' }}>Master List &nbsp; <i className="fa fa-caret-down" aria-hidden="true"></i></h5>

                            <div className="dropdown-content">
                              <Link to="/Lifestyle">LifeStyle</Link>
                              <Link to="/Personality">Personality</Link>
                              <Link to="/Intrest">Intrests</Link>
                              <Link to="/Activities">Activities</Link>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
