import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
import AddLocationModel from "../Common/AddLocationModel";
export default class EditHolidayModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Id: "",
      Date: "",
      Reason: "",
      Holiday: [],
      Holi:[],
      LocationDrop: [],
      LocationModel: false,
      isChrome: false,
      selectedLocation: props.values.LocationType || "",
      formErrors: {},
      ButtonDisabled:false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    // console.log("check2", data);
    if (this.Validation()) {
      this.setState({
        ButtonDisabled: true,
      });
      const url = `${BASE_URLs}/Holiday/EditHoliday`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        this.setState({
          ButtonDisabled: true,
        });
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        
        setTimeout(function () {
          document.getElementById("close").click();
        }, 2000);
      }else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          this.setState({
            ButtonDisabled: false,
          });
        }, 3500);
      
    }
  }
  };
  Validation() {
    //   const {Date,Reason,Holiyda}= this.state;
    let formErrors = {};
    let formIsValid = true;
    var Date = document.getElementById("Date").value;
    var Reason = document.getElementById("Reason").value;
    var Location = document.getElementById("Location").value;

    if (!Date) {
      formIsValid = false;
      formErrors["Date"] = "Date is required";
    }
    if (!Reason) {
      formIsValid = false;
      formErrors["Reason"] = "Occassion is required";
    }
    if (!Location) {
      formIsValid = false;
      formErrors["Location"] = "Branch is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  handleChange = (e) => {
    this.setState({ selectedLocation: e.target.value });
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.values.LocationType !== this.props.values.LocationType) {
      this.setState({ selectedLocation: this.props.values.LocationType });
    }
  }
  componentDidMount() {
    this.GetLocation();
    this.GetHolidays();
    const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    this.setState({ isChrome });
  }
  GetLocation() {
    let url = `${BASE_URLs}/AdminLocation/GetLocation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          LocationDrop: data,
        });
        // console.log("location", data);

        //
      });
  }
  GetHolidays() {
    let url = `${BASE_URLs}/Holiday/HolidayGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Holiday: data,
          Holi: data,
        });
      });
  }
  SelectLocation = (e) => {
    let dept = document.getElementById("Location").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        LocationModel: !prevState.LocationModel,
      }));
      document.getElementById("Location").value = "";
    }
  };

  LocationModel = () => {
    this.setState((prevState) => ({
      LocationModel: !prevState.LocationModel,
      SkillModel: false
    }));

    this.GetLocation();
  };

  getMinDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;

    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDate = `${year}-${formattedMonth}-01`;

    return formattedDate;
  };

  render() {
    const { Date, Reason, Location } = this.state.formErrors;
    const { isChrome } = this.state;
    
    const { Holiday,LocationDrop} = this.state; 
    const { selectedLocation } = this.state;
    const combinedLocations = [
      ...Holiday.map(location => ({ label: location.locationtype })),
      ...LocationDrop.map(location => ({ label: location.branch }))
    ];
    const uniqueLocations = Array.from(new Set(combinedLocations.map(location => location.label)))
      .map(label => ({ label }));
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {this.state.LocationModel ? (
          <AddLocationModel close={() => this.LocationModel()} />
        ) : null}
        <div className="pop">
          <div
            className="popinnernormal"
            style={{ width: "40%", padding: "1%" }}
          >
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>
            <br />
            <br />
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <input type="hidden" name="Id" value={this.props.values.id} />
                  <input
                    className="form"
                    name="OldDate"
                    id="OldDate"
                    defaultValue={this.props.values.Date}
                    type="hidden"
                  />
                  <input
                    className="form"
                    name="Date"
                    onFocus={(e) => (e.target.type = "date")}
                    defaultValue={this.props.values.Date}
                    id="Date"
                    type="text"
                    onChange={this.handleChange}
                    placeholder="Date"
                    // min={this.getMinDate()}
                  />
                  {Date == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Date<span style={{ color: "red", float: "left" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>{Date}</div>
                  )}
                </div>

                <div className="col-lg-5">
                <input
                    className="form"
                    name="OldReason"
                    id="OldReason"
                    defaultValue={this.props.values.Reason}
                    type="hidden"
                  />
                <input
                    className="form"
                    name="Reason"
                    id="Reason"
                    defaultValue={this.props.values.Reason}
                    type="text"
                    onChange={this.handleChange}
                    placeholder="Occassion"
                  />
                  {Reason == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      {" "}
                      Occassion
                      <span style={{ color: "red", float: "left" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>{Reason}</div>
                  )}
                </div>
              </div>
              <br />
              <div className="row justify-content-center">
                <div className="col-lg-5">
                <input
                    className="form"
                    name="OldLocationType"
                    id="OldLocationType"
                    defaultValue={this.props.values.locationtype}
                    type="hidden"
                  />
                <select
                    className="form"
                    name="LocationType" 
                    id="Location"
                    defaultValue={this.props.values.locationtype}
                    value={selectedLocation}
                    type="text"
                    onChange={this.handleChange}
                    onInputCapture={this.SelectLocation}
                    placeholder="Occassion"
                    style={{
                      // Apply the marginLeft style only if the browser is Chrome
                      ...(isChrome && { marginLeft: '-3px' })
                    }}
                  >
                      <option  hidden>
                      {this.props.values.locationtype}
                    </option>
                    {uniqueLocations.map((location, index) => (
                      
                    <option key={index} value={location.label}>
                    {location.label}
                    </option>
                      ))}
                    </select>
                  
                  {Location == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Branch
                      <span style={{ color: "red", float: "left" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Location}
                    </div>
                  )}{" "}
                 
                </div>

                <div
                  className="col-lg-5"
                  style={{
                    marginTop: "11px",
                    color: "#495057",
                    display: "flex",
                    paddingLeft: "12px",
                  }}
                >
                  <div>&nbsp;Optional &nbsp;&nbsp;</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="Holiday"
                      defaultChecked={
                        this.props.values.holiday === "Yes" ? true : false
                      }
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                <button
                    type="submit"
                    disabled={this.state.ButtonDisabled}
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>

            <br />
          </div>
        </div>
      </>
    );
  }
}
