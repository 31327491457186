import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import LoadingPage from "../../Common/LoadingPage";
import { BASE_URLs } from "../../Config/Base";
export default class RejectOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: [],
      Reasons: [],
      comlogo: [],
      reason: "",
      OtherReason: "",
      formErrors: {},
      value: this.props.location.state,
      loading: false,
    };
    this.RejectOrg = this.RejectOrg.bind(this);
  }
  handleFormValidation() {
    var Reasons = document.getElementById("ug").value;
    var otherReason = document.getElementById("ugtext").value;
    let formErrors = {};
    let formIsValid = true;

    if (!Reasons) {
      formIsValid = false;
      formErrors["ReasonsErr"] = "Reason is required";
    } else if (Reasons === "Others") {
      if (!otherReason) {
        formIsValid = false;
        formErrors["ReasonsErr"] = " Reason is required";
      }
      else if (!otherReason.trim().length) {
        // Check if otherReason contains only spaces
        formIsValid = false;
        formErrors["ReasonsErr"] = "Reason is required";
      } 
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  RejectOrg(event) {
    event.preventDefault();
    if (this.handleFormValidation()) {
      setTimeout(() => {
        this.setState({ loading: true });
      }, 500);
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: JSON.stringify({
          company_Id: this.props.location.state.Id,
          OtherReason: this.state.OtherReason,
          Reason: this.state.reason,
          Email: this.props.location.state.Email,
        }),
      };
      ///rejectentity - reject  , reject deactivate
      const url =
        this.props.location.state.status === 2 ||
        this.props.location.state.status === 4
          ? `${BASE_URLs}/RejectOrg/RejectEntity`
          : `${BASE_URLs}/RejectOrg/Reject`;
      try {
        fetch(url, options)
          .then((res) => res.json())
          .then((result) => {
            if (result.status === "Success") {
              setTimeout(() => {
                this.setState({ loading: false });
              }, 500);
              toast.success(result.message, {
                position: "top-center",
                autoClose: 3200,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setTimeout(() => {
                this.props.history.push("/Organization");
              }, 4000);
            } else {
              setTimeout(() => {
                this.setState({ loading: false });
              }, 500);
              toast.error(result.message, {
                position: "top-center",
                autoClose: 3200,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
      } catch (error) {}
    }
  }

  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        mode: "no-cors",
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/GetOrg`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ User: data });
      });
  }

  // detailData(company_Id) {
  //   localStorage.setItem("Id", company_Id);
  // }

  detailData(company_Id) {
    var page = {
      pathname: "/OrganizationDetail",
      state: {
        Id: company_Id,
      },
    };

    this.props.history.push(page);
  }
  GetReason() {
    fetch(`${BASE_URLs}/AddOrganization/Reasons`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Reasons: data });
      });
  }
  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        mode: "no-cors",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push("/DashBoard");
    }
    this.GetOrgLogo();
    this.UserOrg();
    this.GetReason();
  }

  HandleReason() {
    var UG = document.getElementById("ug").value;
    if (UG === "Others") {
      document.getElementById("ugtext").value = "";
      document.getElementById("ugtext").type = "text";
    } else {
      document.getElementById("ugtext").value = "None";
      document.getElementById("ugtext").type = "hidden";
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };

  render() {
    const { Reasons } = this.state;
    const { ReasonsErr } = this.state.formErrors;
    return (
      <>
        <SideBar active={window.location.pathname} />
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <div onClick={() => this.detailData(this.props.location.state.Id)}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </div>{" "}
                          </div>
                          {this.props.location.state.status===2 ||this.props.location.state.status===4?
                          <h4 className="title">Reject Entity</h4>:
                          <h4 className="title">Deactivate</h4>
                          }
                        </div>

                        <div className="card-block">
                          <form onSubmit={this.handleSubmit} autoComplete="off">
                            <div className="container">
                              <div
                                className="row"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="col-lg-4 input-effect">
                                  <select
                                    className="form-control"
                                    id="ug"
                                    onInputCapture={this.HandleReason}
                                    onChange={this.handleChange}
                                    name="reason"
                                  >
                                    <option value="">Select your Reason</option>

                                    {this.props.location.state.status === 2 ||
                                    this.props.location.state.status === 4 ? (
                                      <>
                                        <option value="Insufficient Document">
                                          Insufficient Document
                                        </option>
                                        {/* <option value="Request from Customer">Request from Customer</option>  */}
                                      </>
                                    ) : (
                                      <>
                                        {Reasons.map((Reasons) => (
                                          <option value={Reasons.reason}>
                                            {Reasons.reason}
                                          </option>
                                        ))}
                                      </>
                                    )}
                                    <option value="Others">Others</option>
                                  </select>
                                  <br />
                                  <input
                                    type="hidden"
                                    id="ugtext"
                                    className="form-control"
                                    name="OtherReason"
                                    onChange={this.handleChange}
                                    placeholder="Reason "
                                  />
                                  {!ReasonsErr ? (
                                    <span style={{ color: "#a29d9d" }}></span>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {ReasonsErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div>
                                <div></div>
                              </div>
                              <div></div>
                            </div>

                            <br />
                            <br />
                            <div
                              className="row"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                type="submit"
                                onClick={this.RejectOrg}
                                className="but"
                              >
                                Submit
                              </button>
                              &nbsp;&nbsp;
                              <button
                                type="submit"
                                onClick={() => this.detailData(this.props.location.state.Id)}
                                className=" btn-Secondary"
                              >
                                Cancel
                              </button>
                            </div>
                          </form>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading === true ? <LoadingPage /> : null}
      </>
    );
  }
}
