import React from 'react'
import { Link } from 'react-router-dom'
import '../../Css/Teamgriddetails.css'
import profile from '../../Assets/images/avatar-1.png'
import whatsapp from '../../Assets/images/whatsapp.jpg'
import twitter from '../../Assets/images/twitter.png'
import linkedin from '../../Assets/images/linkedin.png'
import facebook from '../../Assets/images/facebook.jpg'
import Skype from '../../Assets/images/skype.png'
import pencil from '../../Assets/images/pencil.png'
import SideBar from "../../Common/Sidebar";
import { useEffect, useState } from 'react'
import { BASE_URLs } from "../../Config/Base";
import axios from 'axios';

const AdminProfileGrid = (props) => {
    const [details, setDetails] = useState([]);
    const [visibleCount, setVisibleCount] = useState(1);
    const [EducationvisibleCount, setEducationvisibleCount] = useState(1);
    const [personalInfoDetails, setPersonalInfoDetails] = useState([]);
    const [educationDetail, setEducationDetail] = useState([]);
    const [skillDetails, setSkillDetails] = useState([]);
    const [teamDetails, setTeamDetails] = useState([]);
    const [lifeStyleDetails, setLifeStyleDetails] = useState([]);
    const [personalityDetails, setPersonalityDetails] = useState([]);
    const [interestDetails, setInterestDetails] = useState([]);
    const [activitiesDetails, setActivitiesDetails] = useState([]);
    const [familyDetails, setFamilyDetails] = useState([]);
    const [teamSts, setTeamSts] = useState('');
    const [emailDetailsForInfo, setEmailDetailsForInfo] = useState('');
    const [showAllSkills, setShowAllSkills] = useState(false);
    const initialSkillsToShow = 5;
    const displayedSkills = showAllSkills ? skillDetails : skillDetails.slice(0, initialSkillsToShow);
    var email = JSON.parse(localStorage.email);

    const toggleSkills = () => {
        setShowAllSkills(!showAllSkills);
    };
    const handleSeeMore = () => {
        setVisibleCount(visibleCount + 1);
    };

    const handleEducationSeeMore = () => {
        setEducationvisibleCount(EducationvisibleCount + 1);
    };
    const handleSeeLess = () => {
        setVisibleCount(visibleCount - 1);
    };
    const handleEducationSeeLess = () => {
        setEducationvisibleCount(EducationvisibleCount - 1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    };

    useEffect(() => {
        Getdetail();
        // fetchTeamDetails()
        fetchPersonalInfoDetails();
        fetchSkillDetails();
        fetchEducationDetail();
        fetchFamilyDetails();
        fetchFamilyDetails();
        fetchLifeStyleDetails();
        fetchPersonalityDetails();
        fetchInterestDetails();
    }, [])

    const fetchDetails = async (tableName, setterFunction) => {
        const Id = JSON.parse(localStorage.getItem("userId"));;
        const url = `${BASE_URLs}/AdminProfile/GetTabDetails?TableName=${tableName}&Id=${Id}`;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const data = await response.json();
            setterFunction(data);
        } catch (error) {
            // console.error(`Error fetching ${tableName} details:`, error);
        }
    };

    const fetchPersonalityDetails = () => {
        fetchDetails("AdminPersonality", setPersonalityDetails);
    };

    const fetchLifeStyleDetails = () => {
        fetchDetails("AdminLifeStyle", setLifeStyleDetails);
    };

    const fetchInterestDetails = () => {
        fetchDetails("AdminInterests", setInterestDetails);
    };

    const fetchActivitiesDetails = () => {
        fetchDetails("AdminActivities", setActivitiesDetails);
    };

    const Editprofile = (Id) => {
        localStorage.setItem("status", "Pending")
        var page = {
            pathname: "/EditTeam",
            state: {
                Id: Id,
            },
        };
        localStorage.setItem("TeamId", Id);
        props.history.push(page);

    }


    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const currentDate = new Date();

        let age = currentDate.getFullYear() - birthDate.getFullYear();
        const monthDifference = currentDate.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    function maskNumber(number) {
        const numberStr = number.toString();
        const maskedStr = numberStr.slice(0, -2).replace(/./g, '*') + numberStr.slice(-2);
        return maskedStr;
    }

    const fetchTeamDetails = async () => {
        const Id = props.location.state.Id;
        const options = {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
                "Content-Type": "application/json",
                Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            },
        };

        try {
            const response = await axios.get(`${BASE_URLs}/Team/GetTeam`, options);

            setTeamDetails(response.data);
        } catch (error) {
            // console.error("Error fetching personal info details", error);
        }
    };


    const fetchPersonalInfoDetails = async () => {
        const options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
                "Content-Type": "application/json",
                Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            },
        };

        try {
            const response = await axios.get(`${BASE_URLs}/Team/GetAdminPersonal`, options);
            setPersonalInfoDetails(response.data);
            // console.log("PersonalInfoDetails", response.data)
        } catch (error) {
            // console.error("Error fetching personal info details", error);
        }
    };

    const fetchEducationDetail = async () => {
        const options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
                "Content-Type": "application/json",
                Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            },
        };

        try {
            const response = await axios.get(`${BASE_URLs}/UserEducationDetails/GetAdminProfileEducation`, options);
            setEducationDetail(response.data);
        } catch (error) {
            // console.error("Error fetching education details", error);
        }
    };

    const fetchFamilyDetails = async () => {
        const options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
                "Content-Type": "application/json",
                Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            },
        };

        try {
            const response = await axios.get(`${BASE_URLs}/Family/GetAdminProfileFamily`, options);

            setFamilyDetails(response.data);

            // console.log("Family", response.data)

        } catch (error) {
            // console.error("Error fetching family details", error);
        }
    };


    const fetchSkillDetails = async () => {
        const options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
                "Content-Type": "application/json",
                Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            },
        };

        try {
            const response = await axios.get(`${BASE_URLs}/UserSkillDetails/GetAdminSkillsInfo`, options);
            setSkillDetails(response.data);
            // console.log("SkillDetails", response.data)
        } catch (error) {
            // console.error("Error fetching personal info details", error);
        }
    };


    const Getdetail = () => {
        let url = `${BASE_URLs}/Team/GetUserProfessional`;

        axios.get(url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                'Content-type': 'application/json',
                'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
                Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            },
        })
            .then((response) => {
                const data = response.data;
                setDetails(data);
                setTeamSts(data[0].status);
                setEmailDetailsForInfo(data[0].email);
            })
            .catch((error) => {
                // console.error('There was an error fetching the details!', error);
            });
    };

    return (

        <div>
            <SideBar active={window.location.pathname} />

            <div className="pcoded-content ">
                <div className="pcoded-inner-content">
                    <div className="main-body">
                        <div className="page-wrapper">

                            {/*  Start */}
                            <div className="page-body ">
                                {/*  */}
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card">

                                            {/* Main content */}
                                            <div className="grid-container">



                                                {/* Personal Profile */}
                                                <div className="grid-item1">
                                                    <div className="profile">
                                                        {personalInfoDetails.map((personal) => {
                                                            return <p className='grid-user-name'>{personal.firstName} <span className='green-dot'></span></p>
                                                        })}
                                                        {personalInfoDetails.map((personal) => {
                                                            return <img src={personal.profile_Picture} alt="Profile" className="profile-image" />
                                                        })}
                                                    </div>

                                                    <div className='newcontainer'>

                                                        {personalInfoDetails.map((personal) => {
                                                            const age = calculateAge(personal.dob);
                                                            return (
                                                                <div key={personal.id} className='biodata'>
                                                                    {/* <div className='info-row'>
        <span className='tdright'>Employee ID: </span>
        <span className='tdleft'>{personal.teamId}</span>
      </div> */}
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>AGE: </span>
                                                                        <span className='tdleft'>{age}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Gender: </span>
                                                                        <span className='tdleft'>{personal.gender}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Status: </span>
                                                                        <span className='tdleft'>{personal.maritalStatus}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Blood group: </span>
                                                                        <span className='tdleft'>{personal.bloodGroup}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Email Address: </span>
                                                                        <span className='tdleft'>{personal.email}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Mobile: </span>
                                                                        <span className='tdleft'>{personal.homeNumber}</span>
                                                                    </div>
                                                                    <div className='info-row' style={{ marginTop: "10px" }}>
                                                                        {personalInfoDetails.map((personal) => {
                                                                            return (
                                                                                <>
                                                                                    {personal.email === email ? (
                                                                                        <div className='bottom-edit-button'>
                                                                                        <Link
                                                                                            className='edit-button'
                                                                                            to={{
                                                                                                pathname: '/AdminProfile',
                                                                                                state: {
                                                                                                    ProfileClick: true,
                                                                                                },
                                                                                            }}
                                                                                        >
                                                                                            <img src={pencil} alt='Profile' className='edit-image' />
                                                                                        </Link>
                                                                                        </div>
                                                                                    ) : personal.adminEmail === email ? (
                                                                                        <div className='bottom-edit-button'>
                                                                                        <p className='edit-button'>
                                                                                            <img
                                                                                                src={pencil}
                                                                                                alt='Profile'
                                                                                                className='edit-image'
                                                                                                onClick={(e) => Editprofile(props.location.state.Id, e)}
                                                                                            />
                                                                                        </p>
                                                                                        </div>
                                                                                    ) : null}
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>

                                                {/* Bio */}
                                                {personalInfoDetails.map((personal) => {
                                                    return (
                                                        <>
                                                            {personal.email === email || personal.adminEmail === email ? <div className="grid-item">
                                                                <h4>Bio</h4>
                                                                <p className='bio-text'>{personal.bio}</p>
                                                                <div className='bottom-edit-button'>
                                                                <Link
                                                                    to={{
                                                                        pathname: '/AdminProfile',
                                                                        state: {
                                                                            BioClick: true,
                                                                        }
                                                                    }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                                                    </div>
                                                            </div> : null}</>)


                                                })}

                                                {/* Personality */}
                                                {personalInfoDetails.map((personal) => {
                                                    return (
                                                        <>
                                                            {personal.email === email || personal.adminEmail === email ? <div className="grid-item">
                                                                <h4>Personality</h4>
                                                                <div className="personality-content">
                                                                    {personalityDetails.map((personality) => {
                                                                        return <p><span className='textwithbackground'>{personality.personalityName}</span></p>
                                                                    })}
                                                                </div>
                                                                <div className='bottom-edit-button'>
                                                                <Link
                                                                    to={{
                                                                        pathname: '/AdminProfile',
                                                                        state: {
                                                                            PersonalityClick: true,
                                                                        }
                                                                    }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                                            </div>
                                                            </div> : null}</>)
                                                })}

                                                {/* Social Media */}
                                                <div className="grid-item">
                                                    <h4>Social Media</h4>

                                                    {personalInfoDetails.map((socialmedia) => {
                                                        return <>
                                                            {
                                                                socialmedia.faceBook === "-" ? null : 
                                                                <a 
                                                                href={`https://www.facebook.com/${socialmedia.faceBook}`} 
                                                                title={socialmedia.faceBook}
                                                                target="_blank" rel="noopener noreferrer">
                                                                    <img src={facebook} alt="Facebook" className="social-media-image" />
                                                                </a>


                                                            }
                                                            {
                                                                socialmedia.whatsappNumber === "-" ? null : 
                                                                <a 
                                                                title={socialmedia.whatsappNumber}
                                                                href={`https://api.whatsapp.com/send?phone=${socialmedia.whatsappNumber}`} 
                                                                target="_blank" rel="noopener noreferrer">
                                                                    <img src={whatsapp} alt="WhatsApp" className="social-media-image" />
                                                                </a>

                                                            }
                                                            {
                                                                socialmedia.linkedIn === "-" ? null : <a 
                                                                title={socialmedia.linkedIn}
                                                                href={`https://www.linkedin.com/in/${socialmedia.linkedIn}`} 
                                                                target="_blank" rel="noopener noreferrer">
                                                                    <img src={linkedin} alt="LinkedIn" className="social-media-image" />
                                                                </a>

                                                            }
                                                            {
                                                                socialmedia.twitter === "-" ? null : <a 
                                                                title={socialmedia.twitter}
                                                                href={`https://www.twitter.com/${socialmedia.twitter}`} 
                                                                target="_blank" rel="noopener noreferrer">
                                                                    <img src={twitter} alt="Twitter" className="social-media-image" />
                                                                </a>

                                                            }
                                                            {
                                                                socialmedia.skype === "-" ? null : <a 
                                                                title={socialmedia.skype}
                                                                href={`skype:${socialmedia.skype}?chat`} 
                                                                target="_blank" rel="noopener noreferrer">
                                                                    <img src={Skype} alt="Skype" className="social-media-image" />
                                                                </a>

                                                            }
                                                        </>
                                                    })}
                                                    <br /><br />
                                                    {personalInfoDetails.map((personal) => {
                                                        return (
                                                            <>
                                                                {personal.email === email ?
                                                                <div className='bottom-edit-button'>
                                                                    <Link
                                                                        to={{
                                                                            pathname: '/AdminProfile',
                                                                            state: {
                                                                                SocialClick: true,
                                                                            }
                                                                        }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                                                        </div>
                                                                    : null}</>)
                                                    })}

                                                </div>

                                                {/* Lifestyle */}
                                                {personalInfoDetails.map((personal) => {
                                                    return (
                                                        <>
                                                            {personal.email === email || personal.adminEmail === email ? <div className="grid-item">
                                                                <h4>Life style</h4>
                                                                <div className="personality-content">
                                                                    {
                                                                        lifeStyleDetails.map((life) => {
                                                                            return <div className="personality-content">
                                                                                <p><span className='textwithbackground'>{life.lifeStyle}</span></p>
                                                                            </div>

                                                                        })
                                                                    }
                                                                </div>
                                                                <div className='bottom-edit-button'>
                                                                <Link
                                                                    to={{
                                                                        pathname: '/AdminProfile',
                                                                        state: {
                                                                            LifeStyleClick: true,
                                                                        }
                                                                    }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                                                    </div>
                                                            </div> : null}</>)
                                                })}

                                                {/* Hobbies */}
                                                <div className="grid-item">
                                                    {personalInfoDetails.map((personal) => {
                                                        return (
                                                            <>
                                                                <h4>Hobbies</h4>
                                                                <p className='bio-text' style={{wordBreak:"break-word"}}>{personal.hobbies}</p>
                                                            </>)
                                                    })}
                                                    <div className='bottom-edit-button'>
                                                                <Link
                                                                    to={{
                                                                        pathname: '/AdminProfile',
                                                                        state: {
                                                                            HobbiesClick: true,
                                                                        }
                                                                    }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                                                    </div>
                                                </div>

                                                {/* Banking */}
                                                <div className="grid-item">
                                                    <h4>Banking</h4>
                                                    <div>
                                                        {personalInfoDetails.map((personal) => (
                                                            <div key={personal.id} className='biodata'>
                                                                <div className='info-row'>
                                                                    <span className='tdright'>Account Number: </span>
                                                                    <span className='tdleft'>{maskNumber(personal.acNo)}</span>
                                                                </div >
                                                                <div className='info-row'>
                                                                    <span className='tdright'>Account Type: </span>
                                                                    <span className='tdleft'>{personal.acType}</span>
                                                                </div>
                                                                <div className='info-row'>
                                                                    <span className='tdright'>IFSC CODE: </span>
                                                                    <span className='tdleft'>{personal.ifsc}</span>
                                                                </div>
                                                                <div className='info-row'>
                                                                    <span className='tdright'>Branch name: </span>
                                                                    <span className='tdleft'>{personal.branchname}</span>
                                                                </div>
                                                                <div className='info-row'style={{marginBottom:"19px"}}>
                                                                    <span className='tdright'>Branch Address: </span>
                                                                    <span className='tdleft' style={{wordBreak:"break-word"}}>{personal.branchaddress}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {personalInfoDetails.map((personal) => {
                                                            return (
                                                                <>
                                                                    {personal.email === email ?
                                                                    <div className='bottom-edit-button'>
                                                                        <Link
                                                                            to={{
                                                                                pathname: '/AdminProfile',
                                                                                state: {
                                                                                    BankClick: true,
                                                                                }
                                                                            }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                                                            </div>
                                                                        : null}</>)
                                                        })}
                                                    </div>
                                                </div>

                                                {/* Interest */}
                                                {personalInfoDetails.map((personal) => {
                                                    return (
                                                        <>
                                                            {personal.email === email || personal.adminEmail === email ? <div className="grid-item">
                                                                <h4>Interests</h4>
                                                                <div className="personality-content">
                                                                    {interestDetails.map((int) => {
                                                                        return <p><span className='textwithbackground'>{int.interests}</span></p>
                                                                    })}
                                                                </div>
                                                                <div className='bottom-edit-button'>
                                                                <Link
                                                                    to={{
                                                                        pathname: '/AdminProfile',
                                                                        state: {
                                                                            InterestClick: true,
                                                                        }
                                                                    }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                                                    </div>
                                                            </div> : null}</>)
                                                })}

                                                {/* Contact */}
                                                <div className="grid-item">
                                                    <h4>Contact</h4>
                                                    {personalInfoDetails.map((educate, index) => (
                                                        <div key={index}>
                                                            <div key={index} className='biodata'>
                                                                <div className='info-row'>
                                                                    <span className='tdright'>Official Email: </span>
                                                                    <span className='tdleft'>{educate.email}</span>
                                                                </div>
                                                                <div className='info-row'>
                                                                    <span className='tdright'>Personal Email: </span>
                                                                    <span className='tdleft'>{educate.officialEmail}</span>
                                                                </div>
                                                                <div className='info-row'>
                                                                    <span className='tdright'>Office Number: </span>
                                                                    <span className='tdleft'>{educate.phoneNumber}</span>
                                                                </div>
                                                                <div className='info-row'>
                                                                    <span className='tdright'>Mobile Number: </span>
                                                                    <span className='tdleft'>{educate.homeNumber}</span>
                                                                </div>
                                                                <div className='info-row'style={{marginBottom:"19px"}}>
                                                                    <span className='tdright'>ALT. Mobile Number: </span>
                                                                    <span className='tdleft'>{educate.alternatePhoneNo}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div className='bottom-edit-button'>
                                                            <Link
                                                                to={{
                                                                    pathname: '/AdminProfile',
                                                                    state: {
                                                                        ContactsClicks: true,
                                                                    }
                                                                }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                                        </div>
                                                </div>

                                                {/* Education */}
                                                <div className="grid-item">
                                                    <h4>Education</h4>
                                                    {educationDetail.slice(0, EducationvisibleCount).map((educate, index) => (
                                                        <div key={index} className='biodata'>
                                                            <div className='info-row'>
                                                                <span className='tdright'>Qualification Type: </span>
                                                                <span className='tdleft'>{educate.qualificationType}</span>
                                                            </div>
                                                            <div className='info-row'>
                                                                <span className='tdright'>Qualification: </span>
                                                                <span className='tdleft'>{educate.courseName}</span>
                                                            </div>
                                                            <div className='info-row'>
                                                                <span className='tdright'>Type: </span>
                                                                <span className='tdleft'>{educate.courseType}</span>
                                                            </div>
                                                            <div className='info-row'>
                                                                <span className='tdright'>Stream: </span>
                                                                <span className='tdleft'>{educate.stream}</span>
                                                            </div>
                                                            <div className='info-row'>
                                                                <span className='tdright'>Start Date: </span>
                                                                <span className='tdleft'>{educate.courseStartDate}</span>
                                                            </div>
                                                            <div className='info-row' style={{marginBottom:"19px"}}>
                                                                <span className='tdright'>END Date: </span>
                                                                <span className='tdleft'>{educate.courseEndDate}</span>
                                                            </div>
                                                            <div className='bottom-edit-button'>
                                                            <Link
                                                                to={{
                                                                    pathname: '/AdminProfile',
                                                                    state: {
                                                                        EducationClick: true,
                                                                    }
                                                                }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>

                                                        </div>
                                                        </div>
                                                    ))}
                                                    <div className="button-container">
                                                        {EducationvisibleCount < educationDetail.length && (
                                                            <button onClick={handleEducationSeeMore}>See More</button>
                                                        )}
                                                        {EducationvisibleCount > 1 && (
                                                            <button onClick={handleEducationSeeLess}>See Less</button>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Activities */}
                                                {personalInfoDetails.map((personal) => {
                                                    return (
                                                        <>
                                                            {personal.email === email || personal.adminEmail === email ? <div className="grid-item">
                                                                <h4>Activities</h4>
                                                                <div className="personality-content">
                                                                    {activitiesDetails.map((act) => {
                                                                        return <p><span className='textwithbackground'>{act.activities}</span></p>
                                                                    })}
                                                                </div>
                                                                <div className='bottom-edit-button'>
                                                                <Link
                                                                    to={{
                                                                        pathname: '/AdminProfile',
                                                                        state: {
                                                                            ActivitiesClick: true,
                                                                        }
                                                                    }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                                                    </div>
                                                            </div> : null}</>)
                                                })}

                                                {/* Empty */}
                                                <div className="grid-item-violet">
                                                </div>

                                                {/* Skills */}
                                                <div className="grid-item">
                                                    <h4>Skills</h4>
                                                    {displayedSkills.map((skill, index) => (
                                                        <ul key={index} className="skills-list">
                                                            <li>{skill.skillName}</li>
                                                        </ul>
                                                    ))}
<div className='bottom-edit-button'>
                                                    <Link
                                                        to={{
                                                            pathname: '/AdminProfile',
                                                            state: {
                                                                SkillClick: true,
                                                            }
                                                        }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
</div>
                                                    <div className="button-container">
                                                        {skillDetails.length > initialSkillsToShow && (
                                                            <button onClick={toggleSkills}>
                                                                {showAllSkills ? 'See less' : 'See more'}
                                                            </button>
                                                        )}
                                                    </div>


                                                </div>

                                                {/* Family */}
                                                <div className="grid-item">
                                                    <h4>Family</h4>
                                                    {familyDetails.slice(0, visibleCount).map((family, index) => (
                                                        <div key={index} className='biodata'>
                                                            <div className='info-row'>
                                                                <span className='tdright'>First Name: </span>
                                                                <span className='tdleft'>{family.firstName}</span>
                                                            </div>
                                                            <div className='info-row'>
                                                                <span className='tdright'>Last Name: </span>
                                                                <span className='tdleft'>{family.lastName}</span>
                                                            </div>
                                                            <div className='info-row'>
                                                                <span className='tdright'>Relationship: </span>
                                                                <span className='tdleft'>{family.relationship}</span>
                                                            </div>
                                                            <div className='info-row'>
                                                                <span className='tdright'>Contact: </span>
                                                                <span className='tdleft'>{family.contact}</span>
                                                            </div>
                                                            <div className='info-row'>
                                                                <span className='tdright'>Email: </span>
                                                                <span className='tdleft'>{family.email}</span>
                                                            </div>
                                                            {familyDetails.slice(0, visibleCount) ? <div className='bottom-edit-button'><Link
                                                                to={{
                                                                    pathname: '/AdminProfile',
                                                                    state: {
                                                                        FamilyClick: true,
                                                                    }
                                                                }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link> </div> : null}
                                                        </div>
                                                    ))}
                                                    <div className="button-container">
                                                        {visibleCount < familyDetails.length && (
                                                            <button onClick={handleSeeMore}>See More</button>
                                                        )}
                                                        {visibleCount > 1 && (
                                                            <button onClick={handleSeeLess}>See Less</button>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Empty */}
                                                <div className="grid-item-violet">
                                                </div>

                                                {/* Skills */}
                                                <div className="grid-item">
                                                    <h4>Address</h4>
                                                    {personalInfoDetails.map((educate, index) => (
                                                        <div key={index}>
                                                            {personalInfoDetails.map((educate, index) => (
                                                                <div key={index} className='biodata'>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Current Address: </span>
                                                                        <span className='tdleft'></span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>City: </span>
                                                                        <span className='tdleft'>{educate.currentAddress_City}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>State: </span>
                                                                        <span className='tdleft'>{educate.currentAddress_State}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Country: </span>
                                                                        <span className='tdleft'>{educate.currentAddress_Country}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Pin code: </span>
                                                                        <span className='tdleft'>{educate.currentAddress_Pincode}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>House Type: </span>
                                                                        <span className='tdleft'>{educate.houseType}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Staying since: </span>
                                                                        <span className='tdleft'>{educate.stayingSince.split('-')[0]}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Permanant Address: </span>
                                                                        <span className='tdleft'></span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>City: </span>
                                                                        <span className='tdleft'>{educate.permanentAddress_City}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>State: </span>
                                                                        <span className='tdleft'>{educate.permanentAddress_State}</span>
                                                                    </div>
                                                                    <div className='info-row'>
                                                                        <span className='tdright'>Country: </span>
                                                                        <span className='tdleft'>{educate.permanentAddress_Country}</span>
                                                                    </div>
                                                                    <div className='info-row' style={{marginBottom:"19px"}}>
                                                                        <span className='tdright'>Pin code: </span>
                                                                        <span className='tdleft'>{educate.permanentAddress_Pincode}</span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className='bottom-edit-button'>
                                                            <Link
                                                                to={{
                                                                    pathname: '/AdminProfile',
                                                                    state: {
                                                                        AddressClick: true,
                                                                    }
                                                                }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                                        </div>
                                                        </div>
                                                    ))}
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminProfileGrid