import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import "../../Css/Addskills.css";
import Multiselect from "multiselect-react-dropdown";
import { BASE_URLs } from "../../Config/Base";
export default class UserTrainingDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Departmentdrop: [],
      seperateDept: [],
      trainer: [],
      trainerDrop: [],
      memberDrop: [],
      selectedDepartment: "",
      department: [],
      selectedOptions: [],
      selectedTrainee: [],
      Training: [],
    };
  }
  GetDepartment() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });
        this.setState({
          department: filteredData,
        });
      });
  }

  GetTrainers = async (department) => {
    this.GetMembers(department);
    let url = `${BASE_URLs}/Trainer/GetManagerBasedTrainer?Department=${department}&TrainerInfo=${1}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    const reqData = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    });
    const resData = await reqData.json();
    for (let i = 0; i < resData.length; i++) {
      this.state.trainerDrop.push(resData[i].label);
    }
    this.setState({ trainerDrop: this.state.trainerDrop });
    // console.log("dep", department);
    // console.log("TrainersDetails", this.state.trainerDrop);
  };

  GetMembers = async (department) => {
    let url = `${BASE_URLs}/Trainer/GetManagerBasedMember?Department=${department}&TrainerInfo=${0}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    const reqData = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    });
    const resData = await reqData.json();
    for (let i = 0; i < resData.length; i++) {
      this.state.memberDrop.push(resData[i].label);
    }
    this.setState({ memberDrop: this.state.memberDrop });
    // console.log("TrainersDetails", this.state.memberDrop);
  };
  handleChange = (event) => {
    const department = event.target.value;
    this.setState({ selectedDepartment: department });
    this.setState({ selectValue: event.target.value });
    this.GetTrainers(department);
  };
  handleDateChange = (event) => {
    this.setState({ selectValue: event.target.value });
  };
  componentDidMount() {
    this.GetDepartment();
    this.GetTraining();
  }
  GetTraining() {
    let url = `${BASE_URLs}/Training/GetAdminTraining`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Training: data,
          Train: data,
        });
        // console.log(data);
      });
  }

  myDate = () => {
    const startDate = document.getElementById("StartDate").value;
    const duration = document.getElementById("duration").value;

    if (this.state.selectValue === "1 Week") {
      const date = new Date(startDate);
      // console.log(duration);

      const newDate = this.addDays1(date, duration);
      // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // console.log(dateFormat);

      document.getElementById("EndDate").value = dateFormat;
    } else if (this.state.selectValue === "2 Week") {
      const date = new Date(startDate);
      // console.log(duration);

      const newDate = this.addDays2(date, duration);
      // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // console.log(dateFormat);

      document.getElementById("EndDate").value = dateFormat;
    } else if (this.state.selectValue === "3 Week") {
      const date = new Date(startDate);
      // console.log(duration);

      const newDate = this.addDays3(date, duration);
      // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // console.log(dateFormat);

      document.getElementById("EndDate").value = dateFormat;
    } else if (this.state.selectValue === "4 Week") {
      const date = new Date(startDate);
      // console.log(duration);

      const newDate = this.addDays4(date, duration);
      // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // console.log(dateFormat);
      document.getElementById("EndDate").value = dateFormat;
    }
  };

  addDays1 = (date) => {
    date.setDate(date.getDate() + 7);
    return date;
  };

  addDays2 = (date) => {
    date.setDate(date.getDate() + 14);
    return date;
  };
  addDays3 = (date) => {
    date.setDate(date.getDate() + 21);
    return date;
  };
  addDays4 = (date) => {
    date.setDate(date.getDate() + 28);
    return date;
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const { selectedOptions } = this.state;
    const { selectedTrainee } = this.state;
    const data = new FormData(event.target);
    selectedOptions.forEach((option) => {
      data.append("TrainerName", option);
    });
    selectedTrainee.forEach((option) => {
      data.append("Trainees", option);
    });
    fetch(`${BASE_URLs}/Learning/AdminAddLearning`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        this.setState({ status: resp.Status });
        this.setState({ message: resp.Message });
        // this.SuccessModel();
        // console.log("inputvalues", data);
      });
  };
  handleMultiselectChange = (event) => {
    this.setState({ ...(this.state.selectedOptions = event) });
    // console.log("selectOptions", ...this.state.selectedOptions);
  };
  handleMultiselectTrainee = (event) => {
    this.setState({ ...(this.state.selectedTrainee = event) });
    // console.log("selectedTrainee", ...this.state.selectedTrainee);
  };
  render() {
    const {
      plainArray,
      Departmentdrop,
      trainerDrop,
      memberDrop,
      trainer,
      selectedOptions,
      Training,
    } = this.state;
    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            Add
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row" style={{ marginTop: "3%" }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="container">
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            value={
                                              this.state.selectedDepartment
                                            }
                                            name="Department"
                                            id="Department"
                                            onChange={this.handleChange}
                                          >
                                            <option value="">Select</option>
                                            {this.state.department.map(
                                              (Departmentdrop) => (
                                                <option
                                                  value={
                                                    Departmentdrop.department
                                                  }
                                                >
                                                  {Departmentdrop.department}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <span style={{ color: "#a29d9d" }}>
                                            Department
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            name="Trainingitem"
                                            id="Trainingitem"
                                          >
                                            <option value="">Select</option>
                                            {Training.map((Training) => (
                                              <option
                                                value={Training.trainingitem}
                                              >
                                                {Training.trainingitem}
                                              </option>
                                            ))}
                                          </select>
                                          <span style={{ color: "#a29d9d" }}>
                                            Training Topic
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            name="StartDate"
                                            id="StartDate"
                                            type="date"
                                            maxLength="50"
                                          />
                                          <span style={{ color: "#a29d9d" }}>
                                            Start Date
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            name="SchedulingDuration"
                                            id="duration"
                                            type="text"
                                            onChange={this.handleDateChange}
                                          >
                                            <option value="">Select</option>
                                            <option
                                              value="1 Week"
                                              id="duration"
                                            >
                                              1 Week
                                            </option>
                                            <option
                                              value="2 Week"
                                              id="duration"
                                            >
                                              2 Week
                                            </option>
                                            <option
                                              value="3 Week"
                                              id="duration"
                                            >
                                              3 Week
                                            </option>
                                            <option
                                              value="4 Week"
                                              id="duration"
                                            >
                                              4 Week
                                            </option>
                                          </select>
                                          <span style={{ color: "#a29d9d" }}>
                                            Duration of Training
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            name="EndDate"
                                            id="EndDate"
                                            onFocus={this.myDate}
                                            type="date"
                                            maxLength="50"
                                          />
                                          <span style={{ color: "#a29d9d" }}>
                                            End Date
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          <Multiselect
                                            className="effect-16"
                                            showArrow
                                            options={trainerDrop}
                                            getSelectedItems={trainerDrop}
                                            isObject={false}
                                            name="TrainerName"
                                            onSelect={
                                              this.handleMultiselectChange
                                            }

                                            // onChange={this.handleMultiselectChange}
                                            // value={this.state.selectedOptions}
                                          />
                                          <span style={{ color: "#a29d9d" }}>
                                            Trainer Name
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          {/* <input className="effect-16" name="Name" id="Name" type="text" maxLength="50" /> */}
                                          <Multiselect
                                            className="effect-16"
                                            showArrow
                                            options={memberDrop}
                                            getSelectedItems={memberDrop}
                                            name="Trainees"
                                            onSelect={
                                              this.handleMultiselectTrainee
                                            }
                                            isObject={false}
                                          />
                                          <span style={{ color: "#a29d9d" }}>
                                            Team Member
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="col-sm-12"
                                    style={{ textAlign: "center" }}
                                  >
                                    <button
                                      type="submit"
                                      className="but "
                                      style={{
                                        height: "39.5px",
                                        width: "86.36px",
                                        marginTop: "-0.3%",
                                      }}
                                    >
                                      Save
                                    </button>
                                    &nbsp;&nbsp;&nbsp;
                                    <button
                                      type="reset"
                                      className=" btn-Secondary"
                                      style={{ height: "39.5px" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
