import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base";
export default class SkillMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edus: [],
      Id: "",
      UG: "",
      PG: "",
      Specialization: "",
      InActive: [],
      Active: [],
      All: [],
      Notification: [],
      value: this.props.location.state,
      Details: [],
      formErrors: {},
      isButtonDisabled: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    if (this.props.location.state === undefined) {
      this.props.history.goBack();
    }
  }

  handleFormValidation() {
    var CourseName = document.getElementById("CourseName").value;
    var Specialization = document.getElementById("Specialization").value;
    let formErrors = {};
    let formIsValid = true;

    if (!CourseName) {
      formIsValid = false;
      formErrors["CourseNameErr"] = "CourseName is required";
    }
    if (!Specialization) {
      formIsValid = false;
      formErrors["SpecializationErr"] = "Specialization is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.handleFormValidation()) {
      this.setState({ isButtonDisabled: true });
      const data = new FormData(event.target);
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(`${BASE_URLs}/Education/EditMasterUG`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          /*  Authorization: `Bearer ${JSON.parse(localStorage.user)}` */
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          //  alert( resp);
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          if (resp.message === "Education Updated Successfully") {
            setTimeout(() => {
              if (resp.role.toLowerCase() === "platform admin") {
                this.props.history.push("/SuperEducation");
              } else {
                this.props.history.push("/Education");
              }
                this.setState({ isButtonDisabled: false });
            }, 4000);
          }
        });
    }

   
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  componentDidMount() {
    this.GetUG();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}/AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}/AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}/AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }

  HandleUg() {
    var UG = document.getElementById("ug").value;

    if (UG === "others") {
      document.getElementById("ugtext").value = "";
      document.getElementById("ugtext").type = "text";
    } else {
      document.getElementById("ugtext").value = "None";
      document.getElementById("ugtext").type = "hidden";
    }
  }

  HandlePg() {
    var UG = document.getElementById("pg").value;

    if (UG === "others") {
      document.getElementById("pgtext").value = "";
      document.getElementById("pgtext").type = "text";
    } else {
      document.getElementById("pgtext").value = "None";
      document.getElementById("pgtext").type = "hidden";
    }
  }

  GetUG() {
    var Id = this.props.location.state.Id;
    let url = `${BASE_URLs}/Education/GetEditMasterUG?Id=` + Id;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Details: data,
        });
      });
  }

  render() {
    const { Details } = this.state;
    const { CourseNameErr, SpecializationErr } = this.state.formErrors;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header"></div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title">Edit</h4>
                        </div>
                        <div className="card-header-right"></div>

                        <div className="card-block2">
                          <form
                            style={{ marginTop: "5%" }}
                            onSubmit={this.handleSubmit}
                          >
                            <div
                              className="container"
                              style={{ margin: "0 35%" }}
                            >
                              <input
                                className="form"
                                name="Degree"
                                id="ug"
                                defaultValue="PG"
                                onChange={this.handleChange}
                                type="hidden"
                              />

                              <div className="row">
                                <div className="col-3 input-effect">
                                  <input
                                    className="form"
                                    defaultValue={
                                      this.props.location.state.SName
                                    }
                                    id="CourseName"
                                    name="CourseName"
                                    onChange={this.handleChange}
                                    type="text"
                                    placeholder="CourseName"
                                  />
                                  <input
                                    className="form"
                                    name="Id"
                                    id="ug"
                                    value={this.props.location.state.Id}
                                    onChange={this.handleChange}
                                    type="hidden"
                                  />
                                  {!CourseNameErr ? (
                                    <span style={{ color: "#a29d9d" }}>
                                      CourseName
                                    </span>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {CourseNameErr}
                                    </div>
                                  )}
                                  <span className="underline" />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-3 input-effect">
                                  <input
                                    className="form"
                                    id="Specialization"
                                    name="Specialization"
                                    type="text"
                                    defaultValue={
                                      this.props.location.state.desc
                                    }
                                    onChange={this.handleChange}
                                    placeholder="Specialization"
                                  />
                                  {!SpecializationErr ? (
                                    <span style={{ color: "#a29d9d" }}>
                                      Specialization
                                    </span>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {SpecializationErr}
                                    </div>
                                  )}
                                  <span className="underline" />
                                </div>
                              </div>
                              <br />
                            </div>
                            <div
                              className="col-sm-12"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="submit"
                                className="but"
                                style={{
                                  marginTop: "-0.3%",
                                  height: "39.5px",
                                  width: "83.36px",
                                }}
                                disabled={this.state.isButtonDisabled}
                              >
                                Save
                              </button>
                              <button
                                type="reset"
                                className=" btn-Secondary"
                                style={{
                                  marginTop: "0.9%",
                                  marginLeft: "1%",
                                  height: "39.5px",
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                          <br /> <br /> <br />
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
