import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPopup1 from "./SuccessPopup1";
import { Button } from "@material-ui/core";
import $ from "jquery";
import { BASE_URLs } from "../Config/Base";
export default class Aspuserlistchat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcement: [],
      AllAnnouncementList: [],
      showing: true,
      profile: [],
      EditSkillModel: false,
      EditSkillValues: [],
      Id: "",
      SkillName: "",
      User: [],
      AlluserList: [],
    };
    this.initialState = this.state; // this.handleContentClick = this.handleContentClick.bind(this);
  }
  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/status1`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ User: data, AlluserList: data });
        // console.log("user data", data);
      });
  }
  EditSkillModel = (e, fn) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditSkillValues: {
        id: e,
        SkillName: fn,
      },
    });
    this.setState((prevState) => ({
      EditSkillModel: !prevState.EditSkillModel,
    }));
    //   this.GetAddSkill();
  };
  GetAnnouncement() {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(`${BASE_URLs}/UserBank/GetAllUser`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcement: data,
          AllAnnouncementList: data,
        });

        // console.log("New", data);

        //
      });
  }
  GroupSearch = (e) => {
    const Announcement = this.state.AllAnnouncementList.filter((item) =>
      item.firstName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Announcement });
  };

  OATGroupSearch = (e) => {
    const User = this.state.AlluserList.filter((item) =>
      item.personname.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ User });
  };
  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }
  componentDidMount() {
    this.GetAnnouncement();
    this.UserOrg();
  }
  render() {
    var w = window.innerWidth;
    const { Announcement, User } = this.state;
    $("li > input[type=checkbox]").on("change", function () {
      var $this = $(this),
        $ul = $this.parent().find("> ul");

      if ($this.is(":checked")) $ul.show();
      else $ul.hide();
    });
    return (
      <>
        <div>
          {this.state.EditSkillModel ? (
            <SuccessPopup1 id="" close={() => this.EditSkillModel()} />
          ) : null}
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body chat-bg">
                <div className="">
                  <div id="" className="">
                    <div className="page-body">
                      <div className="row">
                        <div className="chat-box">
                          <div id="sidebar" className="users p-chat-user">
                            <div className="had-container">
                              <div
                                className="card card_main p-fixed users-main "
                                style={{ overflow: "scroll" }}
                              >
                                <div className="user-box">
                                  <div id="tsum-tabs">
                                    <main id="maintab">
                                      {/* <input id="tab1" type="radio" name="tabs" checked/>
    <label for="tab1">In Entity</label>
      
    <input id="tab2" type="radio" name="tabs"/>
    <label for="tab2">In OAT</label>

    <input id="tab3" type="radio" name="tabs"/>
    <label for="tab3">Invite</label>  */}
                                      <input
                                        id="tab1"
                                        type="radio"
                                        name="tabs"
                                        defaultChecked
                                      />
                                      <label
                                        id="supertab"
                                        className="j-tabs-label"
                                        htmlFor="tab1"
                                        title="In Entity"
                                        color="white"
                                      >
                                        In Entity
                                      </label>
                                      <input
                                        id="tab2"
                                        type="radio"
                                        name="tabs"
                                      />
                                      <label
                                        id="activetab"
                                        className="j-tabs-label"
                                        htmlFor="tab2"
                                        title="In OAT"
                                        color="white"
                                      >
                                        In OAT
                                      </label>

                                      <br />
                                      <br />

                                      {/* <section id="content1" style={{minHeight:"0"}} > */}
                                      <div
                                        id="tabs-section-1"
                                        className="j-tabs-section"
                                      >
                                        <div className="right-icon-control">
                                          <input
                                            type="text"
                                            className="form-control  search-text"
                                            placeholder="Search Friend"
                                            onChange={this.GroupSearch}
                                          />
                                          <div className="form-icon">
                                            <i className="icofont icofont-search"></i>
                                          </div>
                                        </div>
                                        <br />
                                        {Announcement.map(
                                          (Announcement, id) => (
                                            <div
                                              className="media userlist-box"
                                              data-id="1"
                                              data-status="online"
                                              data-username="Josephin Doe"
                                              data-toggle="tooltip"
                                              data-placement="left"
                                              title="Josephin Doe"
                                            >
                                              <label className="checkboxChat">
                                                <input
                                                  type="checkbox"
                                                  className="checkbox__input_chat"
                                                />
                                                <span className="checkbox__inner_chat"></span>
                                              </label>
                                              <a className="media-left">
                                                <img
                                                  className="media-object img-circle"
                                                  src="assets/images/avatar-1.png"
                                                  alt="Generic placeholder image"
                                                />
                                              </a>
                                              <div
                                                className="media-body"
                                                style={{ textAlign: "initial" }}
                                              >
                                                <div
                                                  className="f-13 chat-header"
                                                  style={{ color: "black" }}
                                                >
                                                  {" "}
                                                  {Announcement.firstName}
                                                </div>
                                              </div>
                                              <i
                                                className="fa fa-user-plus"
                                                aria-hidden="true"
                                                style={{ color: "#7666f0b8" }}
                                              ></i>
                                            </div>
                                          )
                                        )}
                                      </div>

                                      <div
                                        id="tabs-section-2"
                                        className="j-tabs-section"
                                      >
                                        <div className="right-icon-control">
                                          <input
                                            type="text"
                                            className="form-control  search-text"
                                            placeholder="Search Friend"
                                            onChange={this.OATGroupSearch}
                                          />
                                          <div className="form-icon">
                                            <i className="icofont icofont-search"></i>
                                          </div>
                                        </div>
                                        <br />
                                        {User.map((user) => (
                                          <div
                                            className="media userlist-box"
                                            data-id="2"
                                            data-status="online"
                                            data-username="Josephin Doe"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title="Josephin Doe"
                                          >
                                            <a className="media-left">
                                              <img
                                                className="media-object img-circle"
                                                src="assets/images/avatar-1.png"
                                                alt="Generic placeholder image"
                                              />
                                            </a>
                                            <div
                                              className="media-body"
                                              style={{ textAlign: "initial" }}
                                            >
                                              <div
                                                className="f-13 chat-header"
                                                style={{ color: "black" }}
                                              >
                                                <ul className="product_cat_checklist">
                                                  <li id="product_cat-38">
                                                    <input
                                                      className="wcv_category_check"
                                                      value="38"
                                                      type="checkbox"
                                                      name="product_cat[]"
                                                      id="in-product_cat-38"
                                                      data-parsley-multiple="product_cat"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                    />
                                                    <label
                                                      className="selectit"
                                                      for="in-product_cat-38"
                                                      style={{
                                                        backgroundColor: "none",
                                                      }}
                                                    >
                                                      {user.company_Name}
                                                    </label>
                                                    <ul className="children">
                                                      <li id="product_cat-53">
                                                        <input
                                                          className="wcv_category_check"
                                                          value="53"
                                                          type="checkbox"
                                                          name="product_cat[]"
                                                          id="in-product_cat-53"
                                                          data-parsley-multiple="product_cat"
                                                        />
                                                        <label
                                                          className="selectit"
                                                          for="in-product_cat-53"
                                                        >
                                                          Jhon
                                                        </label>
                                                      </li>
                                                      <li id="product_cat-52">
                                                        <input
                                                          className="wcv_category_check"
                                                          value="52"
                                                          type="checkbox"
                                                          name="product_cat[]"
                                                          id="in-product_cat-52"
                                                          data-parsley-multiple="product_cat"
                                                        />
                                                        <label
                                                          className="selectit"
                                                          for="in-product_cat-52"
                                                        >
                                                          Jhon2
                                                        </label>
                                                      </li>
                                                    </ul>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                            <i
                                              className="fa fa-user-plus"
                                              aria-hidden="true"
                                              style={{ color: "#7666f0b8" }}
                                            ></i>
                                          </div>
                                        ))}
                                      </div>
                                    </main>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="styleSelector"></div>
            </div>
          </div>
        </div>
        {/* <div className="pop" style={{backgroundColor:"transparent"}}>
       <div className= {w>768 ?"popinnernormal oneFieldPop":"popinnernormal oneFieldPopMobile"} style={{padding:"1%"}}> 
      <form  autoComplete="off" encType="multipart/form-data"  >
 

           
 
   
      
    


   
  <br/>

           </form>
      </div> 
   </div>   */}
      </>
    );
  }
}
