import React, { Component } from "react";

import SideBar from "../../Common/Sidebar";
export default class DepartmentDash extends Component {
  render() {
    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                {/*  Start */}
                <div className="page-body">
                  <div className="row">
                    <div className="col-md-12 col-xl-4">
                      <div className="card counter-card-1">
                        <div className="card-block-big">
                          <div>
                            <h3>500</h3>
                            <p>people are in vacation</p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-pink"
                                role="progressbar"
                                style={{ width: "70%" }}
                                aria-valuenow={70}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-comment" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="card counter-card-2">
                        <div className="card-block-big">
                          <div>
                            <h3>2500</h3>
                            <p>Teams per Month</p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-success"
                                role="progressbar"
                                style={{ width: "50%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-coffee-mug" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="card counter-card-3">
                        <div className="card-block-big">
                          <div>
                            <h3>200</h3>
                            <p>ABC</p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-default"
                                role="progressbar"
                                style={{ width: "90%" }}
                                aria-valuenow={90}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-upload" />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-xl-8">
                      <div className="card">
                        <div className="card-block">
                          <figure className="pie-chart">
                            <figcaption>
                              FrontEnd Developer
                              <span style={{ color: "#4e79a7" }} />
                              <br />
                              Backend Developer
                              <span style={{ color: "#f28e2c" }} />
                              <br />
                              Junior software Engineers
                              <span style={{ color: "#e15759" }} />
                              <br />
                              senior software Engineers
                              <span style={{ color: "#76b7b2" }} />
                              <br />
                              Tester
                              <span style={{ color: "#59a14f" }} />
                              <br />
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-xl-4">
                      <div className="card table-card widget-primary-card">
                        <div className="row-table">
                          <div className="col-sm-3 card-block-big">
                            <i className="icofont icofont-star" />
                          </div>
                          <div className="col-sm-9">
                            <h4>4000 +</h4>
                            <h6>Ratings Received</h6>
                          </div>
                        </div>
                      </div>
                      <div className="card table-card widget-success-card">
                        <div className="row-table">
                          <div className="col-sm-3 card-block-big">
                            <i className="icofont icofont-trophy-alt" />
                          </div>
                          <div className="col-sm-9">
                            <h4>17</h4>
                            <h6>Achievements</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}

                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
