import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../Common/Sidebar";
export default class MeetingLink extends Component {
  handleClick = () => {
    window.open("https://meet.google.com/your-meeting-id", "_blank");
  };

  handleClick1 = () => {
    window.open(
      "https://www.microsoft.com/en-in/microsoft-teams/join-a-meeting",
      "_blank"
    );
  };

  render() {
    return (
      <div>
        <SideBar active={window.location.pathname} />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              {" "}
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            Active Training{" "}
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                          >
                            <br />
                            <br />
                            <div className="container">
                              <div className="col-lg-12">
                                <div className="dt-responsive table-responsive">
                                  <table
                                    id="add-row-table3"
                                    className="table nowrap"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Meeting Link </th>
                                        <th> </th>{" "}
                                        {/* <th>Office Document </th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {" "}
                                          <Link>
                                            https://meet.google.com/your-meeting-id
                                          </Link>
                                        </td>
                                        <td>
                                          <a
                                            className="plus"
                                            style={{
                                              padding: "2px 15px",
                                              boxShadow:
                                                " -2px -2px 16px -2px #5f7ae14a",
                                            }}
                                          >
                                            <i
                                              title="Download Template"
                                              className="icofont icofont-plus"
                                            >
                                              &nbsp;
                                              <Link onClick={this.handleClick}>
                                                JoinNow
                                              </Link>
                                            </i>
                                          </a>
                                        </td>{" "}
                                        {/* <td>
                                  <a id="if" title="Iframe Example" style={{ width: '200px', height: '200px' }}></a>
                                </td> */}
                                      </tr>
                                      <tr>
                                        <td>
                                          <Link>
                                            https://www.microsoft.com/en-in/microsoft-teams/join-a-meeting
                                          </Link>{" "}
                                        </td>
                                        <td>
                                          <a
                                            className="plus"
                                            style={{
                                              padding: "2px 15px",
                                              boxShadow:
                                                " -2px -2px 16px -2px #5f7ae14a",
                                            }}
                                          >
                                            <i
                                              title="plus"
                                              className="icofont icofont-plus"
                                            >
                                              {" "}
                                              &nbsp;
                                              <Link onClick={this.handleClick1}>
                                                JoinNow
                                              </Link>
                                            </i>
                                          </a>
                                        </td>{" "}
                                        {/* <td>
                                  <a id="if" title="Iframe Example" style={{ width: '200px', height: '200px' }}></a>
                                </td> */}
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <br />
                              </div>
                              <br />
                              <br />
                              <br />
                              <br />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
