import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base"
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comlogo: [],
      allNotify: [],
      Notification: [],
      SaveOrg: [],
      Reason: "",
      Status: "",
    };
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  GetSavedOrganization() {
    let url = `${BASE_URLs}/AddOrganization/GetSavedOrganization`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SaveOrg: data });
        this.setState({ Reason: data[0].reason });
        this.setState({ Status: data[0].status });
        // console.log("new", data[0].reason);
      });
  }
  componentDidMount() {
    this.GetSavedOrganization();
  }
  render() {
    var username = JSON.parse(localStorage.uname);

    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content ">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header"></div>
                <div className="container">
                  <div className="pcoded-wrapper">
                    <h1
                      style={{
                        textAlign: "center",
                        color: "#421769",
                        textShadow: "0 0 1px black",
                        fontSize: "33px",
                      }}
                    >
                      Dear {username},{" "}
                      {this.state.Reason != null && this.state.Status === 4
                        ? "Your Entity is still pending"
                        : "Welcome To One Awesome Team!!"}{" "}
                    </h1>
                    <br />{" "}
                    <h4 style={{ textAlign: "center", color: "#421769" }}>
                      {this.state.Reason != null && this.state.Status === 4
                        ? ""
                        : "To use our awesome features please complete your entity profile"}{" "}
                    </h4>
                    <div className="row">
                      <div
                        className="col-sm-11"
                        style={{
                          textAlign: "center",
                          marginLeft: "43px",
                          marginTop: "3%",
                        }}
                      >
                        <Link to="/AddOrganization">
                          <span>
                            <input
                              type="button"
                              className="but"
                              value="Entity Profile"
                              style={{ height: "41.5px", width: "104.36px" }}
                            />
                          </span>
                        </Link>{" "}
                        &nbsp; &nbsp; &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
