import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import "../Css/Chat.css";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { Cancel, CancelOutlined } from "@material-ui/icons";
import TrainingAddTrainerPop from "./TrainingAddTrainerpop";
import AddTrainingAddTrainee from "./AddTrainingAddTrainee";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class TrainingClassPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDateTime: dayjs(),
      selectedDate: "",
      selectedTime: "",
      firstPopupVisible: false,
      secondPopupVisible: false,
      thirdPopupVisible: false,
      selectedTrainee: [],
      selectedOption: [],
      selectedDepartment: "",
      selectedDescription: "",
      selectedTrainingItem: "",
      selectedTrainingTopicId: "",
      LearningDataWithHour: [],
    };
  }

  componentDidMount() {
    const value = this.props.TrainingState;

    this.setState({
      selectedDepartment: value.department,
      selectedDescription: value.description,
      selectedTrainingItem: value.trainingitem,
      selectedTrainingTopicId: value.training_Id,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      selectedOption,
      selectedOtherOption,
      filteredArray,
      selectedNames,
    } = this.state;
    const {
      selectedTrainee,
      selectedDepartment,
      selectedDescription,
      selectedTrainingItem,
      selectedDate,
      selectedTime,
    } = this.state;

    const data = new FormData();

    // console.log("dddd", selectedOption);
    // console.log("dddd", selectedTrainee);

    selectedOption.forEach((option) => {
      data.append("TrainerId", option.trainer_UserId);
      data.append("TrainerName", option.trainerName);
    });
    data.append("Department", selectedDepartment);
    data.append("Trainingitem", selectedTrainingItem);
    data.append("TrainingDescription", selectedDescription);
    data.append("ScheduleTime", selectedTime);
    data.append("StartDate", selectedDate);
    selectedTrainee.forEach((option) => {
      option[1].forEach((id) => data.append("TraineesId", id));
    });

    if (
      selectedDepartment != "" &&
      selectedTrainingItem != "" &&
      selectedDate != ""
    ) {
      alert("fetch");
      fetch(
        `${BASE_URLs}/Learning/GetLearningWithHour?Department=${selectedDepartment}&Trainingitem=${selectedTrainingItem}&Startdate=${selectedDate}`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          this.setState({ LearningDataWithHour: data });
          // console.log("Resuuulllttt", data);

          let duration;
          var newArr = data.map((e) => {
            duration = parseFloat(e.duration.replace("Hours", ""));
            const [hours, minutes] = e.scheduleTime.split(":").map(Number);

            const totalMinutes = hours * 60 + minutes + duration * 60;
            const newHours = Math.floor(totalMinutes / 60);
            const newMinutes = totalMinutes % 60;

            const resultTime = `${String(newHours).padStart(2, "0")}:${String(
              newMinutes
            ).padStart(2, "0")}`;
            return {
              starttime: e.scheduleTime,
              Endtime: resultTime,
            };
          });

          let validsts = [];
          let valid;
          let date;

          // console.log("selectedDate==", typeof selectedTime);
          // console.log("selectedDate== typeof", selectedTime);
          // console.log("selectedDate== parseInt", selectedTime);

          let user_time = selectedTime;
          // console.log(newArr);
          let check = newArr.map((e) => {
            const time1 = e.starttime;
            const time2 = e.Endtime;

            const [StartHours, Startminutes] = time1.split(":").map(Number);
            const [UserHours, Userminutes] = user_time.split(":").map(Number);
            const [EndHours, Endminutes] = time2.split(":").map(Number);
            // console.log(StartHours, UserHours, EndHours);

            if (StartHours < UserHours && UserHours < EndHours) {
              valid = false;
            } else if (StartHours === UserHours) {
              if (Userminutes > Startminutes) {
                valid = false;
              } else {
                valid = true;
              }

              valid = false;
            } else if (UserHours < StartHours) {
              let finishHour = UserHours + parseInt(duration);
              finishHour = finishHour > 24 ? finishHour - 24 : finishHour;

              if (finishHour > StartHours || finishHour > StartHours) {
                valid = false;
              } else if (finishHour === StartHours) {
                if (Userminutes > Startminutes) {
                  valid = false;
                } else {
                  valid = true;
                }
              } else {
                valid = true;
              }
            } else if (UserHours > StartHours) {
              if (UserHours < EndHours) {
                valid = false;
              } else {
                valid = true;
              }
            } else {
              valid = true;
            }

            validsts.push(valid);
          });

          // console.log(validsts);
          // console.log("ppppppppppp", validsts.indexOf(false) === -1);

          if (validsts.indexOf(false) === -1) {
            const {
              selectedOption,
              selectedOtherOption,
              filteredArray,
              selectedNames,
            } = this.state;
            const {
              selectedTrainee,
              selectedDepartment,
              selectedDescription,
              selectedTrainingItem,
              selectedDate,
              selectedTime,
            } = this.state;
            const data = new FormData();
            // console.log("dddd", selectedOption);
            // console.log("dddd", selectedTrainee);

            selectedOption.forEach((option) => {
              data.append("TrainerId", option.trainer_UserId);
              data.append("TrainerName", option.trainerName);
            });
            data.append("Department", selectedDepartment);
            data.append("Trainingitem", selectedTrainingItem);
            data.append("TrainingDescription", selectedDescription);
            data.append("ScheduleTime", selectedTime);
            data.append("StartDate", selectedDate);
            selectedTrainee.forEach((option) => {
              option[1].forEach((id) => data.append("TraineesId", id));
            });

            fetch(`${BASE_URLs}/Learning/AdminAddLearning`, {
              method: "POST",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PUT, DELETE, OPTIONS",
                Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
              },
              body: data,
            })
              .then((res) => res.json())
              .then((resp) => {
                this.setState({ status: resp.status });
                this.setState({ message: resp.Message });
                if (resp.status == "Success") {
                  this.props.togglePopupclose();
                }
              });
          } else {
            toast.error("Training Alredy Scheduled On That Time ", {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  };

  toggleAddTrainerPopup = () => {
    this.setState((prevState) => ({
      secondPopupVisible: !prevState.secondPopupVisible,
    }));
  };
  toggle_thirdPopupVisible = () => {
    this.setState((prevState) => ({
      thirdPopupVisible: !prevState.thirdPopupVisible,
    }));
  };

  handleFirstPopupOK = () => {
    const { selectedDateTime } = this.state;
    const selectedDate = selectedDateTime.format("DD-MM-YYYY");
    const selectedTime = selectedDateTime.format("HH:mm");

    this.setState({
      selectedDate,
      selectedTime,
    });
    // console.log("selectedDate", selectedDate);
    // console.log("selectedTime", selectedTime);

    this.setState({ secondPopupVisible: true });
  };

  handleDateTimeChange = (newValue) => {
    this.setState({
      selectedDateTime: newValue,
    });
    // console.log("====", newValue);
  };

  handleSelectedDateAndTime = () => {
    const { selectedDateTime } = this.state;
    const selectedDate = selectedDateTime.format("DD-MM-YYYY");
    const selectedTime = selectedDateTime.format("HH:mm");

    this.setState({
      selectedDate,
      selectedTime,
      secondPopupVisible: true,
    });
    // console.log("selectedDate", selectedDate);
    // console.log("selectedTime", selectedTime);
  };
  render() {
    const currentDateTime = dayjs();
    const maxDate = currentDateTime.add(6, "weeks");
    const { selectedDate, selectedTime, selectedDateTime } = this.state;

    return (
      <div className="pop">
        <div
          className="popinnernormal"
          style={{
            width: "49%",
            height: "90vh",
            overflowY: "auto",
          }}
        >
          <div className="row" style={{ padding: "8px" }}>
            <div className="col-md-6" style={{ marginLeft: "230px" }}>
              <b>{this.state.popupdesignation}</b>
            </div>
            <div className="col-sm-3">
              <button
                style={{
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                  marginLeft: "620px",
                  // marginRight: "-255px"
                }}
                onClick={this.props.onClose}
              >
                <CloseIcon />
              </button>
            </div>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              style={{ width: "1000px" }}
            >
              <DemoContainer
                components={[
                  "DateTimePicker",
                  "MobileDateTimePicker",
                  "DesktopDateTimePicker",
                  "StaticDateTimePicker",
                ]}
              >
                <DemoItem label="Training Schedule Time">
                  <StaticDateTimePicker
                    value={selectedDateTime}
                    onChange={this.handleDateTimeChange}
                    onCancel={this.props.onClose}
                    onAccept={this.handleSelectedDateAndTime}
                    minDateTime={currentDateTime}
                    maxDateTime={maxDate}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>

            <div className="col-sm-8" style={{ maxWidth: "100.666667%" }}>
              <div
                className="dt-responsive table-responsive table-res"
                style={{ overflowX: "hidden" }}
              ></div>
            </div>
          </div>
        </div>

        {this.state.secondPopupVisible && (
          <TrainingAddTrainerPop
            selectedTrainingTopicId={this.state.selectedTrainingTopicId}
            state={(state) => this.setState({ selectedOption: state })}
            third={this.toggle_thirdPopupVisible}
            closeThePopup={this.toggleAddTrainerPopup}
          />
        )}

        {this.state.thirdPopupVisible && (
          <AddTrainingAddTrainee
            FinalSubmit={(e) => {
              this.handleSubmit(e);
            }}
            state={(state) => this.setState({ selectedTrainee: state })}
            closeThePopup={this.toggleAddTrainerPopup}
            third={this.toggle_thirdPopupVisible}
          />
        )}
      </div>
    );
  }
}
