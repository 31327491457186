import { React, useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import PlusOne from "@material-ui/icons/Add";
import AddTrainingAddTrainee from "./AddTrainingAddTrainee";
import { BASE_URLs } from "../Config/Base";
const AddTrainingAddTrainer = ({
  closeThePopup,
  array,
  state,
  third,
  selectedTrainingTopicId,
}) => {
  const [Trainers, setTrainers] = useState([]);
  const [Train, setTrain] = useState([]);
  const [Selectall, setSelectall] = useState(false);

  const GetTrainer = () => {
    //  // console.log("department", department);
    let url = `${BASE_URLs}/Training/TrainingAdd_GetTrainer?TrainingTopicId=${selectedTrainingTopicId}`;
    // console.log("deeee1", url);

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(" ====>>>>>>>> ", data);

        let final = data.map((e) => {
          return { ...e, check: false };
        });

        setTrainers(final);
        setTrain(final);
        // console.log(" ====fffff ", final);
      });
  };

  useEffect(() => {
    GetTrainer();
  }, []);

  const handleChange = (arr, i, event) => {
    let change = Trainers.map((e, index) => {
      if (i === index) return { ...e, ...{ check: event.target.checked } };
      return { ...e };
    });
    // state(change);
    change.length === change.filter((e) => e.check === true).length
      ? setSelectall(true)
      : setSelectall(false);
    setTrainers(change);
  };

  const handleSelectAll = (event) => {
    setSelectall(!Selectall);
    let change = Trainers.map((e, index) => {
      return { ...e, ...{ check: event.target.checked } };
    });
    change.length === change.filter((e) => e.check === true).length
      ? setSelectall(true)
      : setSelectall(false);
    setTrainers(change);
  };
  const add = (event) => {
    event.preventDefault();
    state(Trainers.filter((e) => e.check));
    third();
  };
  return (
    <div className="pop">
      <div className="popinnernormal">
        <form autoComplete="off" encType="multipart/form-data">
          {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}} onClick={this.close}  ><CloseIcon/></button> */}
          <button
            style={{
              float: "right",
              backgroundColor: "#a0a3a305",
              color: "#797070",
              border: "1px solid #a3a5a505",
            }}
            onClick={closeThePopup}
          >
            <CloseIcon />
          </button>

          <div className="row" tyle={{ padding: "8px" }}>
            <div className="col-sm-3"></div>
            <div className="col-sm-8">
              <div className="dt-responsive table-responsive table-res">
                {Trainers.length === 0 ? (
                  "There are no ' Trainers '"
                ) : (
                  <table
                    id="DepSheet"
                    className="table nowrap"
                    style={{ maxWidth: "90%" }}
                  >
                    <thead>
                      <tr>
                        <th colSpan="2" style={{ textAlign: "left" }}>
                          <button
                            style={{
                              marginRight: "-30px",
                              color: "rgb(94, 217, 135)",
                            }}
                            onClick={add}
                          >
                            Add
                          </button>
                        </th>
                      </tr>

                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={Selectall}
                            onChange={(e) => handleSelectAll(e)}
                          />
                        </th>
                        <th>Trainers</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {Trainers.map((Trainers, i) => (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              value={
                                Trainers.trainerName +
                                "$$$" +
                                Trainers.trainer_UserId
                              }
                              checked={Trainers.check}
                              onChange={(e) => handleChange(Trainers, i, e)}
                            />
                          </td>
                          <td>
                            {Trainers.assigned_date !== null
                              ? Trainers.trainerName +
                                " (" +
                                Trainers.assigned_date +
                                ") "
                              : Trainers.trainerName}
                          </td>
                          {/* <td>
                          <button
                            className="button-30"
                            type="button"
                            style={{ color: "rgb(94, 217, 135)" }}
                            onClick={() =>
                              this.fetchData(
                                Trainers.trainerName,
                                Trainers.trainer_UserId
                              )
                            }
                          >
                            Assign&nbsp;
                            <i className="icofont icofont-edit"></i>
                          </button>
                        </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="col-sm-4"></div>
          </div>

          <div className="row">
            <div className="col-sm-12" style={{ textAlign: "center" }}></div>
          </div>
        </form>
        <br />
        {/* <button onClick={this.closePopup}><CloseIcon/></button> */}
      </div>
    </div>
  );
};
export default AddTrainingAddTrainer;
