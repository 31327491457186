import React, { Component } from "react";
import Piechart from "../Piechart";
import Pie from "../../Common/Pie";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      Pending: [],
      comlogo: [],
      Notification: [],
    };
  }
  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  componentDidMount() {
    this.GetOrgLogo();
    this.Getpending();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}/AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}/AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}/AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }
  Getpending() {
    fetch(`${BASE_URLs}/AddOrganization/pendingcount`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Pending: data });
      });
  }

  render() {
    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                {/*  Start */}
                <div className="page-body">
                  <div className="row">
                    <div className="col-md-12 col-xl-3">
                      <div className="card counter-card-1">
                        <div className="card-block-big">
                          <div>
                            {/* <h3></h3> */}
                            <p></p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-pink"
                                role="progressbar"
                                style={{ width: "70%" }}
                                aria-valuenow={70}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-comment" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="card counter-card-2">
                        <div className="card-block-big">
                          <div>
                            {/* <h3></h3> */}
                            <p></p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-success"
                                role="progressbar"
                                style={{ width: "50%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-coffee-mug" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="card counter-card-3">
                        <div className="card-block-big">
                          <div>
                            {/* <h3></h3> */}
                            <p></p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-default"
                                role="progressbar"
                                style={{ width: "90%" }}
                                aria-valuenow={90}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-upload" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-8">
                      <div className="card">
                        <div className="card-header">
                          <h5>Office Counts</h5>
                        </div>

                        <Piechart />

                        {/* <div id="areachart" className="p-t-40 rickshaw_graph">
          <svg width="787.45" height={200}><path className="path" d="M0,160.39603960396042Q111.11285714285714,147.1947194719472,128.20714285714286,136.63366336633663C153.84857142857143,120.79207920792079,230.77285714285713,5.544554455445546,256.4142857142857,1.980198019801982S358.98,87.12871287128714,384.62142857142857,100.99009900990099S487.1871428571429,140.59405940594058,512.8285714285714,140.59405940594058S615.3942857142857,108.9108910891089,641.0357142857143,100.99009900990099S743.6014285714285,57.42574257425744,769.2428571428571,61.3861386138614Q786.3371428571428,64.02640264026404,897.45,140.59405940594058L897.45,200Q786.3371428571428,200,769.2428571428571,200C743.6014285714285,200,666.6771428571429,200,641.0357142857143,200S538.47,200,512.8285714285714,200S410.2628571428571,200,384.62142857142857,200S282.05571428571426,200,256.4142857142857,200S153.84857142857143,200,128.20714285714286,200Q111.11285714285714,200,0,200Z" fill="#1abc9c" stroke="none" strokeWidth={2} opacity={1} /></svg></div> */}
                        <div className="card-block"></div>
                        <div className="card-footer widget-content-footer">
                          <p className="m-b-0">v</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-xl-4">
                      <Pie />

                      <div className="card table-card widget-success-card">
                        <div className="row-table">
                          <div className="col-sm-3 card-block-big">
                            <i className="icofont icofont-trophy-alt" />
                          </div>
                          <div className="col-sm-9">
                            {/* <h4></h4>
            <h6></h6> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
