import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class CategoryPop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},

      Category: "",
      ButtonDisabled: false,
    };
    // this.handleSubmit=this.handleSubmit.bind(this);
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };

  handleSubmit = async (e) => {
    var Category = document.getElementById("CategoryA").value;
    var Id = document.getElementById("Id").value;
    e.preventDefault();
    if (this.Validation()) {
      this.setState({
        ButtonDisabled: true,
      });
      const data = new FormData();
      data.append("Category", Category);
      data.append("Id", Id);

      const url = `${BASE_URLs}/Skill/EditCategory`;

      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          ButtonDisabled: true,
        });
        document.getElementById("Editpop").style.display = "none";
        setTimeout(function () {
          if (document.getElementById("close")) {
            document.getElementById("close").click();
          }
        }, 4000);
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      this.Getcategory();
    }
  };

  Getcategory() {
    let url = `${BASE_URLs}/Skill/GetAdminCategory`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          category: data,
        });
        //
      });
  }

  componentDidMount() {
    //  this.Getcategory();
  }

  Validation() {
    var Category = document.getElementById("CategoryA").value;
    let formErrors = {};
    let formIsValid = true;
    //Email

    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  render() {
    var w = window.innerWidth;
    const { CategoryErr } = this.state.formErrors;
    const { Department } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="Editpop"
          >
            <form
              autoComplete="off"
              encType="multipart/form-data"
              onSubmit={this.handleSubmit.bind(this)}
              style={{ marginTop: "-10px" }}
            >
              <button
                id="close"
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={this.props.close}
              >
                <CloseIcon />
              </button>

              <div className="row" style={{ padding: "8px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    type="hidden"
                    id="Id"
                    name="Id"
                    defaultValue={this.props.values.Id}
                    onKeyDown={this._handleKeyDown}
                  />
                  <input
                    className="form"
                    maxLength="50"
                    defaultValue={this.props.values.category}
                    id="CategoryA"
                    onChange={(e) => {
                      this.AutoCaps(e);
                    }}
                    onKeyDown={this._handleKeyDown}
                    onKeyPress={this.handleKeyPress}
                    name="Category"
                    type="text"
                  />
                  {/* {!CategoryErr ? <span style={{ color: '#a29d9d',float:"left" }}>Category Name</span>: <div style={{ color: "red" ,float:"left"}}>{CategoryErr}</div>}  */}
                  {!CategoryErr ? (
                    <div style={{ color: "#a29d9d", textAlign: "left" }}>
                      Category Name
                    </div>
                  ) : (
                    <div style={{ color: "red", textAlign: "left" }}>
                      {CategoryErr}
                    </div>
                  )}
                  <span className="underline" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
