import React, { Component } from "react";
import $ from "jquery";
import { BASE_URLs } from "../Config/Base";
export default class AnnouncementPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcement: [],
      Organization: this.getCookie(
        "" + JSON.parse(localStorage.uname) + "organization"
      ),
      role: this.getCookie("" + JSON.parse(localStorage.uname) + "role"),

      //  SeeMore:true
    };
  }
  role = JSON.parse(localStorage.Role).toString();
  SetReminderOn = async (e) => {
    e.preventDefault();

    const url = `${BASE_URLs}/Announcement/SetReminderOn`;
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    const response = await fetch(url, options);
    await response.json();

    // this.role === "User"
    //   ? this.props.history.push("/UserDashboard")
    //   : // /AdminHome.js
    //   JSON.parse(localStorage.org) === 3 && this.role === "Admin"
    //   ? this.props.history.push("/AdminHome")
    //   :
       this.props.history.push("/DashBoard");
  };

  SetReminderOff = async (e) => {
    e.preventDefault();

    const url = `${BASE_URLs}/Announcement/SetReminderOff`;
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    const response = await fetch(url, options);
    await response.json();

    // this.role === "User"
    //   ? this.props.history.push("/UserDashboard")
    //   : // /AdminHome.js
    //   JSON.parse(localStorage.org) === 2 && this.role === "Admin"
    //   ? this.props.history.push("/AdminHome")
    //   :
       this.props.history.push("/AllAnnouncement");
  };

  SetReminderOffA = async (e) => {
    e.preventDefault();
    const url = `${BASE_URLs}/Announcement/SetReminderOff`;
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    const response = await fetch(url, options);
    await response.json();

    // this.role === "User"
    //   ? this.props.history.push("/UserDashboard")
    //   : // /AdminHome.js
    //   JSON.parse(localStorage.org) === 2 && this.role === "Admin"
    //   ? this.props.history.push("/AdminHome")
    //   :
       this.props.history.push("/AllAnnouncement");
  };

  closefunction = () => {
    // this.role === "User"
    //   ? this.props.history.push("/UserDashboard")
    //   : this.role === "Admin"
    //   ?
       this.props.history.push("/DashBoard")
      // : this.props.history.push("/AllAnnouncement");
  };
  GetAnnouncement() {
    let url = `${BASE_URLs}/Announcement/GetSAdminAnnouncementpop`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcement: data,
        });
        if (this.state.Announcement.length === 0) {
          this.role === "User"
            ? this.props.history.push("/UserDashboard")
            : this.props.history.push("/DashBoard");
        }

        if (!(this.state.Announcement.length === 0)) {
          this.setState({
            SeeMore:
              this.state.Announcement[0].image === "-" &&
              this.state.Announcement[0].video === "-",
          });
        }

        // console.log("More", data);
      });
  }

  componentDidMount() {
    $(document).ready(function () {
      $("#seemore").click(function () {
        if ($("#feed").is(":hidden")) {
          $("#feed").slideDown("slow");
          $("#seemore").html("Close");
        } else {
          $("#feed").slideUp("slow");
          $("#seemore").html("See more");
        }
      });
    });

    var crole = this.getCookie("" + JSON.parse(localStorage.uname) + "role");
    var corg = this.getCookie(
      "" + JSON.parse(localStorage.uname) + "organization"
    );
    this.setState({
      role: crole,
      Organization: corg,
    });
    this.GetAnnouncement();
  }
  getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }
  truncateTitle(title, maxLength) {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  }
  render() {
    const { Announcement, Organization } = this.state;
    const { FinishedTask, pendingTask } = this.state;
    const { User } = this.state;
    var username = JSON.parse(localStorage.uname);
    return (
      <div className="Annpop">
      
        <div className="Annpopinnernormalpop" id="Annpopinnernormalpop">
          <div className="row">
            <div className="col-lg-12">
              <span style={{ float: "right" }}>
                {" "}
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  style={{ color: "gray", cursor: "pointer" }}
                  onClick={this.closefunction}
                ></i>
              </span>
              <h1 className="Annpopinnertitle">Announcement</h1>
            </div>
            <div className="col-lg-12">
              {Announcement.map((Announcement) => (
                <center>
                  {" "}
                  <b>
                    <p>Subject:&nbsp;{this.truncateTitle(Announcement.title, 20)}</p>
                  </b>
                </center>
              ))}
            </div>
          </div>
          <br />
          <div className="row">
            {this.state.Organization === "1" ? (
              <>
                <div className="col-lg-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    onClick={this.SetReminderOn}
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "153px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Remind me Later
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="submit"
                    onClick={this.SetReminderOff}
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "153px",
                      marginTop: "-0.3%",
                    }}
                  >
                    View
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    onClick={this.SetReminderOn}
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "153px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Remind me Later
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    type="submit"
                    onClick={this.SetReminderOffA}
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "153px",
                      marginTop: "-0.3%",
                    }}
                  >
                    View
                  </button>
                </div>
              </>
            )}
          </div>
          <br />
        </div>
      </div>
    );
  }
}
