import React from 'react'
import { Link } from 'react-router-dom'
import '../Css/Teamgriddetails.css';
import profile from '../Assets/images/avatar-1.png'
import whatsapp from '../Assets/images/whatsapp.jpg'
import twitter from '../Assets/images/twitter.png'
import linkedin from '../Assets/images/linkedin.png'
import facebook from '../Assets/images/facebook.jpg'
import Skype from '../Assets/images/skype.png'
import pencil from '../Assets/images/pencil.png'
import SideBar from "./Sidebar";
import { useEffect, useState, useRef } from 'react'
import { BASE_URLs } from "../Config/Base";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const Teamgriddetails = (props) => {
  var w = window.innerWidth;
  const [details, setDetails] = useState([]);
  const [profile, setProfile] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [contactopen, setContactopen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);
  const [isPopupOpen5, setIsPopupOpen5] = useState(false);
  const [isPopupOpen6, setIsPopupOpen6] = useState(false);
  const [isPopupOpen7, setIsPopupOpen7] = useState(false);
  const [isPopupOpen8, setIsPopupOpen8] = useState(false);
  const [isPopupOpen9, setIsPopupOpen9] = useState(false);
  const [isPopupOpen10, setIsPopupOpen10] = useState(false);
  const [isPopupOpen11, setIsPopupOpen11] = useState(false);
  const [HouseTypeDrop, setHouseTypeDrop] = useState([]);
  const [RelationshipDrop, setRelationshipDrop] = useState([]);
  const [SKillAdminNameDrop, setSKillAdminNameDrop] = useState([]);
  const [isSelected, setisSelected] = useState(false);
  const [isSelected1, setisSelected1] = useState(false);
  const [visibleCount, setVisibleCount] = useState(1);
  const [EducationvisibleCount, setEducationvisibleCount] = useState(1);
  const [EducationAddCount, setEducationAddCount] = useState(1);
  const [personalInfoDetails, setPersonalInfoDetails] = useState([]);
  const [educationDetail, setEducationDetail] = useState([]);
  const [educationDrop, setEducationDrop] = useState([]);
  const [educationCourseName, setEducationCourseName] = useState([]);
  const [skillDetails, setSkillDetails] = useState([]);
  const [lifeStyleDetails, setLifeStyleDetails] = useState([]);
  const [personalityDetails, setPersonalityDetails] = useState([]);
  const [interestDetails, setInterestDetails] = useState([]);
  const [activitiesDetails, setActivitiesDetails] = useState([]);
  const [teamDetails, setTeamDetails] = useState([]);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [teamSts, setTeamSts] = useState('');
  const [selectedQualification, setSelectedQualification] = useState('');
  const [emailDetailsForInfo, setEmailDetailsForInfo] = useState('');
  const [showAllSkills, setShowAllSkills] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isFirefox, setIsFirefox] = useState(false);
  const inputRef = useRef(null);
  const calRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [DisFireStart, setDisFireStart] = useState(true);
  const [DisFire, setDisFire] = useState(true);
  const [St_date, setSt_date] = useState("");
  const [startDateFormatted, setStartDateFormatted] = useState('');
  const hasMounted = useRef(false);
  const today = new Date();
  const maxDateS = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const [formValues, setFormValues] = useState({
    DOB: '',
    Email: '',
    PhoneNumber: '',
    HomeNumber: '',
    OfficialEmail: '',
    AlternatePhoneNo: '',
    endDate: null,
    endDate_formatted: ''
  });
  const initialSkillsToShow = 5;
  const fileInputRef = useRef(null);
  const displayedSkills = showAllSkills ? skillDetails : skillDetails.slice(0, initialSkillsToShow);
  var email = JSON.parse(localStorage.email);

  const handleDateChange = (date, name) => {
    const formattedDate = date ? moment(date).format('YYYY-MM') : '';
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: date,
      [`${name}_formatted`]: formattedDate,
    }));
    handleEducationChange();
  };

  const handleDateChange1 = (date, name) => {
    const formattedDate = date ? moment(date).format('YYYY-MM') : '';
    setendDate(date); // Update local state
    handleEducationChange(date, name, formattedDate); 
  };

  const handleDateChange2 = (date, name) => {
    const formattedDate = date ? moment(date).format('YYYY-MM') : '';
    setStartDate(date); // Update local state
    handleEducationChange(date, name, formattedDate); 
  };

  const handleChangeD = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    handleEducationChange();
  };
  const handleReset = () => {
    setStartDate('');  // Reset startDate state
    setendDate(''); 
    hasMounted.current = false;
    setFormValues({
      startDate: '',
      endDate: '',
    });  
  };
  const toggleSkills = () => {
    setShowAllSkills(!showAllSkills);
  };
  const handleSeeMore = () => {
    setVisibleCount(visibleCount + 1);
  };

  const calculateMaxDate = () => {
    const today = new Date();
    const maxDate = today.toISOString().split("T")[0];
    return maxDate;
  }


  const handleEducationSeeMore = () => {
    setEducationvisibleCount(EducationvisibleCount + 1);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const togglePopupClose = () => {
    setIsPopupOpen(false);
  };

  const togglePopup1 = () => {
    setIsPopupOpen1(!isPopupOpen1);
  };
  const togglePopupClose1 = () => {
    setIsPopupOpen1(false);
  };

  const togglePopup2 = () => {
    setIsPopupOpen2(!isPopupOpen2);
  };
  const togglePopupClose2 = () => {
    setIsPopupOpen2(false);
  };

  const togglePopup3 = () => {
    setIsPopupOpen3(!isPopupOpen3);
  };
  const togglePopupClose3 = () => {
    setIsPopupOpen3(false);
  };

  const togglePopup4 = () => {
    setIsPopupOpen4(!isPopupOpen4);
    // Getvalue();
  };
  const togglePopupClose4 = () => {
    setIsPopupOpen4(false);
  };

  const togglePopup5 = (id) => {
    setSelectedId(id);
    setIsPopupOpen5(!isPopupOpen5);
  };
  const togglePopupClose5 = () => {
    setSelectedId(null);
    setIsPopupOpen5(false);
    setSelectedQualification('');
    hasMounted.current = false;
    setFormValues('');
    setStartDate('');
    setendDate('');
  };
  const togglePopup6 = (id) => {
    setSelectedId(id);
    setIsPopupOpen6(!isPopupOpen6);
    setIsPopupOpen11(false);
  };
  const togglePopupClose6 = () => {
    setSelectedId(null);
    setIsPopupOpen6(false);
    setIsPopupOpen11(true);
  };
  const togglePopup7 = (id) => {
    setSelectedId(id);
    setIsPopupOpen7(!isPopupOpen7);
  };
  const togglePopupClose7 = () => {
    setSelectedId(null);
    setIsPopupOpen7(false);
    setFormErrors('');
  };

  const togglePopup8 = () => {
    setIsPopupOpen8(!isPopupOpen8);
  };
  const togglePopupClose8 = () => {
    setIsPopupOpen8(false);
    hasMounted.current = false;
    setSelectedQualification('');
    setFormValues({
      startDate: '',
      endDate: '',
    });
  };

  const togglePopup9 = () => {
    setIsPopupOpen9(!isPopupOpen9);
  };
  const togglePopupClose9 = () => {
    setIsPopupOpen9(false);
    document.getElementById('familyform').reset();
    setFormErrors('');
  };

  const togglePopup10 = () => {
    setIsPopupOpen10(!isPopupOpen10);
  };
  const togglePopupClose10 = () => {
    setIsPopupOpen10(false);
  };

  const togglePopup11 = () => {
    setIsPopupOpen11(!isPopupOpen11);
  };
  const togglePopupClose11 = () => {
    setIsPopupOpen11(false);
  };

  const OtherStyle = {
    display: selectedQualification === 'Others' ? 'flex' : 'none'
  };

  const handleAddresschange = (e) => {

    var CurrentAddress_Country = document.getElementById(
      "CurrentAddress_Country"
    ).value;

    if (CurrentAddress_Country == "India") {
      // alert(country);
      setisSelected(true)
    } else if (CurrentAddress_Country != "India") {
      setisSelected(false)

    }
  };

  const handlePermanentAddress = (e) => {

    var PermanentAddress_Country = document.getElementById(
      "PermanentAddress_Country"
    ).value;

    if (PermanentAddress_Country == "India") {
      // alert(country);
      setisSelected1(true)

    } else if (PermanentAddress_Country != "India") {
      setisSelected1(false)

    }
  };

  const handleSeeLess = () => {
    setVisibleCount(visibleCount - 1);
  };
  const handleEducationSeeLess = () => {
    setEducationvisibleCount(EducationvisibleCount - 1);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };




  const UserEducationDropdownList = () => {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/UserEducationDetails/GetQualificationtype`, options)
      .then((response) => response.json())
      .then((data) => {
        setEducationDrop(data)
      });
  }

  const handlevalueChange = (e) => {
    e.preventDefault();
    var value = e.target.value;
    const Email = props.location.state.Email;
    //e.target.value

    if (
      e.target.value === "Graduation" ||
      e.target.value === "Post Graduation" ||
      e.target.value === "Doctorate"
    ) {
      var flag =
        value === "Graduation"
          ? "1"
          : value === "Post Graduation"
            ? "2"
            : value === "Doctorate"
              ? "3"
              : null;

      const options = {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      };
      fetch(
        ` ${BASE_URLs}/UserEducationDetails/GetUserEducationCourseNameGrid?flag=${flag}&Email=${Email}`,
        options
      )
        .then((response) => response.json())
        .then((data) => {
          setEducationCourseName(data);
        });
    } else {
      setEducationCourseName([]);
    }
  };


  const RelationshipDropList = () => {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Family/RelationshipDropdown`, options)
      .then((response) => response.json())
      .then((data) => {
        setRelationshipDrop(data)
      });
  }

  const GetUserSkillName = () => {
    const Email = props.location.state.Email;
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/UserSkillDetails/GetUserSkillNameDrop?Email=${Email}`, options)
      .then((response) => response.json())
      .then((data) => {
        setSKillAdminNameDrop(data)
        // console.log("skills", data)
      });
  }


  useEffect(() => {
    Getdetail()
    fetchTeamDetails()
    fetchPersonalInfoDetails();
    fetchSkillDetails();
    fetchEducationDetail();
    fetchFamilyDetails();
    fetchLifeStyleDetails();
    fetchPersonalityDetails();
    fetchInterestDetails();
    fetchActivitiesDetails();
    profilelist();
    HouseTypeDropdownList();
    UserEducationDropdownList();
    RelationshipDropList();
    GetUserSkillName();
    const userAgent = navigator.userAgent.toLowerCase();
    setIsFirefox(userAgent.includes('firefox'));
  }, [])

  useEffect(() => {
    if (hasMounted.current) {
      handleEducationChange();
    } else {
      hasMounted.current = true;
    }
  }, [formValues.startDate, formValues.endDate]);

  const handleFocus = (e) => {
    e.target.type = 'month';
    e.target.setAttribute('placeholder', '------,---');
  };

  const HouseTypeDropdownList = () => {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/profile/HouseTypeDropdown`, options)
      .then((response) => response.json())
      .then((data) => {
        setHouseTypeDrop(data)
      });
  }

  const Editprofile = (Id) => {
    localStorage.setItem("status", "Pending")
    var page = {
      pathname: "/EditTeam",
      state: {
        Id: Id,
      },
    };
    localStorage.setItem("TeamId", Id);
    props.history.push(page);

  }


  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const reformatDate = (dateString) => {
    if (!dateString) return '';
    return dateString.split('T')[0];
  };

  function maskNumber(number) {
    const numberStr = number.toString();
    const maskedStr = numberStr.slice(0, -2).replace(/./g, '*') + numberStr.slice(-2);
    return maskedStr;
  }

  const fetchDetails = async (tableName, setterFunction) => {
    const Id = props.location.state.aspnetId;


    const url = `${BASE_URLs}/AdminProfile/GetTabDetails?TableName=${tableName}&Id=${Id}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setterFunction(data);
    } catch (error) {
      // console.error(`Error fetching ${tableName} details:`, error);
    }
  };

  const fetchPersonalityDetails = () => {
    fetchDetails("AdminPersonality", setPersonalityDetails);
  };

  const fetchLifeStyleDetails = () => {
    fetchDetails("AdminLifeStyle", setLifeStyleDetails);
  };

  const fetchInterestDetails = () => {
    fetchDetails("AdminInterests", setInterestDetails);
  };

  const fetchActivitiesDetails = () => {
    fetchDetails("AdminActivities", setActivitiesDetails);
  };



  const fetchTeamDetails = async () => {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    try {
      const response = await axios.get(`${BASE_URLs}/Team/GetTeam`, options);

      setTeamDetails(response.data);
    } catch (error) {
      // console.error("Error fetching personal info details", error);
    }
  };


  const profilelist = () => {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Profile/Get`, options)
      .then((response) => response.json())
      .then((data) => {
        setProfile(data)
        // console.log("check", data);
      });
  }


  const Skillsubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    await fetch(`${BASE_URLs}/UserSkillDetails/AddUserSkillGrid`, options)
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === "Error") {
          toast.error(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setIsPopupOpen10(false);
          fetchSkillDetails();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const EduSubmit = async (e) => {
    e.preventDefault();
    if (EducationValidation()) {

      const data = new FormData(e.target);

      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      await fetch(`${BASE_URLs}/UserEducationDetails/AddUserEducationGrid`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Error") {
            setIsPopupOpen8(true);
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setIsPopupOpen8(false);
            fetchEducationDetail();
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (resp.status !== "Error") {
          }
        })
    }

  };


 const Getvalue=()=> {
  fetchPersonalInfoDetails();
    var Pvalue = document.getElementById('PermanentAddress_City').value;
    var Cvalue = document.getElementById('CurrentAddress_City').value;
    var status = document.getElementById('SameAddress')
    if (Pvalue !== Cvalue || Pvalue === '-') {
      status.checked = false
    }
    else {
      status.checked = true
      // this.setState({
      //   isReadOnly: true,
      // });
    }

  }

  const FamlySubmit = async (e) => {
    e.preventDefault();
 

    const data = new FormData(e.target);

    if (FamilyValidation()) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      await fetch(`${BASE_URLs}/Family/AddUserFamilyGrid`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Error") {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setIsPopupOpen9(false);
            fetchFamilyDetails();
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    
  }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (ProfileValidation()) {
      const data = new FormData(e.target);
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };

      await fetch(`${BASE_URLs}/Profile/UserProfileEdit`, options)
        .then((res) => res.json())
        .then((resp) => {
          // console.log({ resp })
          if (resp.status === "Success") {
            fetchPersonalInfoDetails();
            setIsPopupOpen(false);
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (resp.status === "Error") {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  }

  const BioEdit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        // 'Accept':'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        // 'Content-Type':'application/json'
      },
      body: data,
    };

    await fetch(`${BASE_URLs}/Profile/UserBioEdit`, options)
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === "Success") {
          setIsPopupOpen1(false)
          fetchPersonalInfoDetails();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }



  const fetchPersonalInfoDetails = async () => {
    const Id = props.location.state.Id;
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    try {
      const response = await axios.get(`${BASE_URLs}/Team/GetTeamPersonal?Id=${Id}`, options);
      setPersonalInfoDetails(response.data);
        // console.log("personalInfoDetails", response.data)
    } catch (error) {
      // console.error("Error fetching personal info details", error);
    }
  };

  const fetchEducationDetail = async () => {
    const Id = props.location.state.Id;
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    try {
      const response = await axios.get(`${BASE_URLs}/UserEducationDetails/GetAdminEducationInfo?Id=${Id}`, options);
      setEducationDetail(response.data);
      // console.log("Education", response.data)
    } catch (error) {
      // console.error("Error fetching education details", error);
    }
  };

  const SocialEdit = async (e) => {

    e.preventDefault();
    if (SocialValidation()) {
      const data = new FormData(e.target);

      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept':'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          // 'Content-Type':'application/json'
        },
        body: data,
      };

      await fetch(`${BASE_URLs}/Profile/UserSocialEdit`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            setIsPopupOpen2(false)
            fetchPersonalInfoDetails();
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  }


  const ContactEdit = async (e) => {
    e.preventDefault();
    if (ContactValidation()) {
      const data = new FormData(e.target);
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept':'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          // 'Content-Type':'application/json'
        },
        body: data,
      };

      await fetch(`${BASE_URLs}/Profile/UserContactEDit`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            fetchPersonalInfoDetails();
            setIsPopupOpen3(false)

            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  }


  const AddressEdit = async (e) => {
    e.preventDefault();
    if (AddressValidation()) {
      const data = new FormData(e.target);
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept':'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          // 'Content-Type':'application/json'
        },
        body: data,
      };

      await fetch(`${BASE_URLs}/Profile/UserAddressEdit`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            setIsPopupOpen4(false);
            fetchPersonalInfoDetails();
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  }


  const EducationEdit = async (e) => {
    e.preventDefault();
    if (EducationValidation()) {
      const data = new FormData(e.target);

      const url = `${BASE_URLs}/UserEducationDetails/UserGridEditEducation`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        fetchEducationDetail();
        setIsPopupOpen5(false);
        setSelectedId(null);
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      else if (result.status === "Error") {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  const FamilyEdit = async (e) => {
    e.preventDefault();
    if (FamilyValidation()) {
      const data = new FormData(e.target);
      const url = `${BASE_URLs}/Family/EditUserFamilyGrid`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };

      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        fetchFamilyDetails();
        setIsPopupOpen7(false);
        setSelectedId(null);
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {

        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  const SkillEdit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const url = `${BASE_URLs}/UserSkillDetails/EditUserSkillGrid`;
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    const response = await fetch(url, options);
    const result = await response.json();

    if (result.status === "Success") {
      fetchSkillDetails();
      setIsPopupOpen6();
      setisSelected(null);
      toast.success(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    else if (result.status === "Error") {
      toast.error(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }


  const fetchFamilyDetails = async () => {
    const Id = props.location.state.Id;
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    try {
      const response = await axios.get(`${BASE_URLs}/Family/GetAdminFamilyInfo?Id=${Id}`, options);

      setFamilyDetails(response.data);

      // console.log("Family", response.data)

    } catch (error) {
      // console.error("Error fetching family details", error);
    }
  };


  const fetchSkillDetails = async () => {
    const Id = props.location.state.Id; // Assuming you are using this inside a class component
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    try {
      const response = await axios.get(`${BASE_URLs}/UserSkillDetails/GetAdminUserSkillsInfo?Id=${Id}`, options);
      setSkillDetails(response.data);
      // console.log("SkillDetails", response.data)
    } catch (error) {
      // console.error("Error fetching personal info details", error);
    }
  };


  const handlecheckbox = () => {
    var checkbox = document.getElementById("SameAddress").checked;

    // var PermanantAddress2 = document.getElementById('PermanantAddress2')
    var PermanentAddress_Country = document.getElementById(
      "PermanentAddress_Country"
    );
    var PermanentAddress_State = document.getElementById(
      "PermanentAddress_State"
    );
    var PermanentAddress_City = document.getElementById(
      "PermanentAddress_City"
    );
    var PermanentAddress_Pincode = document.getElementById(
      "PermanentAddress_Pincode"
    );

    // var CurrentAddress2 = document.getElementById("CurrentAddress2").value
    var CurrentAddress_Country = document.getElementById(
      "CurrentAddress_Country"
    ).value;
    var CurrentAddress_State = document.getElementById(
      "CurrentAddress_State"
    ).value;
    var CurrentAddress_City = document.getElementById(
      "CurrentAddress_City"
    ).value;
    var CurrentAddress_Pincode = document.getElementById(
      "CurrentAddress_Pincode"
    ).value;
    if (checkbox === true) {
      PermanentAddress_Country.value = CurrentAddress_Country;
      PermanentAddress_State.value = CurrentAddress_State;
      PermanentAddress_City.value = CurrentAddress_City;
      PermanentAddress_Pincode.value = CurrentAddress_Pincode;
    } else {
      PermanentAddress_Country.value = "-";
      PermanentAddress_State.value = "-";
      PermanentAddress_City.value = "-";
      PermanentAddress_Pincode.value = "-";
    }
  }


  const handleTextareaClick = (e) => {

    const isChecked = e.target.checked;
    setIsReadOnly(isChecked ? !isReadOnly : false)

  };
 const HandleQualification = (e) => {
   const value = e.target.value;
    var QualificationType = document.getElementById("Qualification").value;
    if (QualificationType === "Others") {
      document.getElementById("otherQType").value = "";
      document.getElementById("otherQType").type = "text";
    } else {
      document.getElementById("otherQType").value = "None";
      document.getElementById("otherQType").type = "hidden";
    }
    setSelectedQualification(value);
  }

  const Getdetail = () => {
    const Id = props.location.state.Id;
    // const Id = localStorage.getItem("foruserDetailId", Id);

    let url = `${BASE_URLs}/Team/GetTeamEdit?Id=` + Id;

    axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
    })
      .then((response) => {
        const data = response.data;
          // console.log({ data })
        setDetails(data);
        setTeamSts(data[0].status);
        setEmailDetailsForInfo(data[0].email);
      })
      .catch((error) => {
        // console.error('There was an error fetching the details!', error);
      });
  };


  const ProfileValidation = () => {
    let errors = {};
    let isValid = true;
    var DOB = document.getElementById("DOB").value;
    var Gender = document.getElementById("Genderinfo").value;
    var MaritalStatus = document.getElementById("MaritalStatusinfo").value;
    const myDate = new Date(DOB);
    const today = new Date();
    if (!DOB || DOB === "dd-mm-yyyy") {
      isValid = false;
      errors["DOBerr"] = "DOB is required";
    } else if (myDate > today) {
      isValid = false;
      errors["DOBerr"] = "Valid DOB is required";
    }
    if(!Gender || Gender === "-"){
      isValid = false;
      errors["Gendererr"] = "Gender is required";
    }
    if(!MaritalStatus || MaritalStatus === "-"){
      isValid = false;
      errors["MaritalStatuserr"] = "MaritalStatus is required";
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleProfileChange = () => {
    let errors = {};
    let isValid = true;
    var DOB = document.getElementById("DOB").value;
    var Gender = document.getElementById("Genderinfo").value;
    var MaritalStatus = document.getElementById("MaritalStatusinfo").value;
    const myDate = new Date(DOB);
    const today = new Date();
    if (!DOB || DOB === "dd-mm-yyyy") {
      isValid = false;
      errors["DOBerr"] = "DOB is required";
    } else if (myDate > today) {
      isValid = false;
      errors["DOBerr"] = "Valid DOB is required";
    }
    if(!Gender || Gender === "-"){
      isValid = false;
      errors["Gendererr"] = "Gender is required";
    }
    if(!MaritalStatus || MaritalStatus === "-"){
      isValid = false;
      errors["MaritalStatuserr"] = "MaritalStatus is required";
    }
    setFormErrors(errors);
    return isValid;
  };


  const ContactValidation = () => {
    let errors = {};
    let isValid = true;
    const mobPattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?(?!0+$)(?:[6-9]\d{9}|[6-9]\d{2}-\d{3}-\d{4})$/;
    const officePattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?(?!0+$)(?:[0-9]\d{9}|[0-9]\d{2}-\d{3}-\d{4})$/;
    var OfficialEmail = document.getElementById("OfficialEmail").value; //personal email
    var PhoneNumber = document.getElementById("PhoneNumber").value;
    var Email = document.getElementById("PersonalEmail").value; //auto email
    var AlternatePhoneNo = document.getElementById("AlternatePhoneNo").value;
    var HomeNumber = document.getElementById("HomeNumber").value;
    if (!HomeNumber || HomeNumber === "-") {
      isValid = false;
      errors["HomeErr"] = "Phone Number is required";
    } else if (!mobPattern.test(HomeNumber)) {
      isValid = false;
      errors["HomeErr"] = " Enter the valid Phone Number";
    } else if (OfficialEmail.trim()) {
      if (OfficialEmail === Email) {
        isValid = false;
        errors["officialEmailerr"] =
          "Personal Email and Official Email should not be same";
      } else if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(OfficialEmail)
      ) {
        isValid = false;
        errors["officialEmailerr"] = "Enter valid Personal Email";
      }
    }
    if (!OfficialEmail.trim() || OfficialEmail === "-") {
      isValid = false;
      errors["officialEmailerr"] = " Enter the Personal Email";
    }
    if (OfficialEmail === Email) {
      isValid = false;
      errors["officialEmailerr"] =
        "Personal Email and Official Email should not be same";
    }
    if (HomeNumber === AlternatePhoneNo && HomeNumber != "-" && HomeNumber != "") {
      isValid = false;
      errors["AlternatePhoneNoerr"] = "Phone Number and Alternate Phone Number should not be same";
    }
    if (PhoneNumber === AlternatePhoneNo && AlternatePhoneNo !== '-' && PhoneNumber != "") {
      isValid = false;
      errors["AlternatePhoneNoerr"] = "Office Number and Alternate Phone Number should not be same";
    }
    if (PhoneNumber === HomeNumber && HomeNumber != "-" && HomeNumber != "") {
      isValid = false;
      errors["HomeErr"] = "Phone Number and Office Number should not be same";
    }
    if (AlternatePhoneNo === HomeNumber && HomeNumber != "-" && HomeNumber != "") {
      isValid = false;
      errors["AlternatePhoneNoerr"] = "Phone Number and Alternate Phone Number should not be same";
    }
    if (PhoneNumber.trim() !== '-' && PhoneNumber.trim() !== '') {
      if (!officePattern.test(PhoneNumber)) {
        isValid = false;
        errors["contactphnerr"] = " Enter the valid Office Number";
      }
    }
    if (AlternatePhoneNo.trim() !== '-' && AlternatePhoneNo.trim() !== '') {
      if (!mobPattern.test(AlternatePhoneNo)) {
        isValid = false;
        errors["AlternatePhoneNoerr"] = " Enter the valid Alternate Phone Number";
      }
    }
    setFormErrors(errors);
    return isValid;
  };
  const SocialValidation = () => {
    let errors = {};
    let isValid = true;
    const mobPattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?(?!0+$)(?:[6-9]\d{9}|[6-9]\d{2}-\d{3}-\d{4})$/;
    var Whatsapp = document.getElementById("Whatsapp").value;
    if (Whatsapp != '-' && Whatsapp != '') {
      if (!mobPattern.test(Whatsapp)) {
        isValid = false;
        errors["WhatsappErr"] = " Enter the valid Whatsapp Number";
      }
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleAddressChange1 = () => {
    let errors = {};
    let isValid = true;

    var CurrentAddress_Country = document.getElementById(
      "CurrentAddress_Country"
    ).value;
    var CurrentAddress_State = document.getElementById(
      "CurrentAddress_State"
    ).value;
    var CurrentAddress_City = document.getElementById(
      "CurrentAddress_City"
    ).value;
    var CurrentAddress_Pincode = document.getElementById(
      "CurrentAddress_Pincode"
    ).value;
    var PermanentAddress_Country = document.getElementById(
      "PermanentAddress_Country"
    ).value;
    var PermanentAddress_State = document.getElementById(
      "PermanentAddress_State"
    ).value;
    var PermanentAddress_City = document.getElementById(
      "PermanentAddress_City"
    ).value;
    var PermanentAddress_Pincode = document.getElementById(
      "PermanentAddress_Pincode"
    ).value;
    var HouseType = document.getElementById("HouseType").value;
    var OthersHouseType = document.getElementById("HouseText").value;
    var StayingSince = document.getElementById("StayingSince").value;
    //var CurrentCitySince = document.getElementById("CurrentCitySince").value
    var myDate = new Date(StayingSince);
    var today = new Date();
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    if (
      CurrentAddress_City === "" ||
      !CurrentAddress_City ||
      CurrentAddress_City === "-"
    ) {
      isValid = false;
      errors["CurrentAddress_Cityerr"] = "City is required";
    } else if (CurrentAddress_City.length < 3) {
      isValid = false;
      errors["CurrentAddress_Cityerr"] =
        "A Minimum of 3 characters are required";
    }
    else if (
      CurrentAddress_Country === "" ||
      !CurrentAddress_Country ||
      CurrentAddress_Country === "-"
    ) {
      isValid = false;
      errors["CurrentAddress_Countryerr"] = "Country is required";
    }
   else if (
      CurrentAddress_State === "" ||
      !CurrentAddress_State ||
      CurrentAddress_State === "-"
    ) {
      isValid = false;
      errors["CurrentAddress_Stateerr"] = "State is required";
    }
    else if (
      CurrentAddress_State.length < 3
    ) {
      isValid = false;
      errors["CurrentAddress_Stateerr"] = "A Minimum of 3 characters are required";
    }


   else if (
      CurrentAddress_Pincode === "" ||
      !CurrentAddress_Pincode ||
      CurrentAddress_Pincode === "-"
    ) {
      isValid = false;
      errors["CurrentAddress_Pincodeerr"] = "Pincode is required";
    } else if (CurrentAddress_Pincode.length < 6) {
      isValid = false;
      errors["CurrentAddress_Pincodeerr"] = "A Maximum of 6 characters are required";
    }
   else if (!HouseType || HouseType == "-") {
      isValid = false;
      errors["HouseTypeerr"] = "House Type is required";
    }


    else if (HouseType === 'others' && !OthersHouseType) {
      isValid = false;
      errors["HouseTypeerr"] = "House Type is required";
    }
   else if (OthersHouseType !== "" && OthersHouseType.length < 3) {
      isValid = false;
      errors["HouseTypeerr"] = "A Minimum of 3 characters are required";
    }
    else if (!StayingSince) {
      isValid = false;
      errors["StayingSinceerr"] = "Staying Since is required";
    }
    else if (StayingSince === "dd-mm-yyyy") {
      isValid = false;

      errors["StayingSinceerr"] = "Staying Since is required";
    } else if (myDate > today) {
      isValid = false;
      errors["StayingSinceerr"] = "Valid Staying Since is required";
    }

    else if (
      PermanentAddress_City === "" ||
      !PermanentAddress_City ||
      PermanentAddress_City !== '-'
    ) {
      if (!PermanentAddress_City) {
        isValid = false;
        errors["PermanentAddress_Cityerr"] = "City is required";
      }
      else if (PermanentAddress_City.length < 3) {
        isValid = false;
        errors["PermanentAddress_Cityerr"] =
          "A Minimum of 3 characters are required";
      }
    }

    else if (PermanentAddress_Country !== '-' || PermanentAddress_Country === "Select") {
      if (!PermanentAddress_Country) {
        isValid = false;
        errors["PermanentAddress_Countryerr"] = "Country is required";
      }
    }

   else if (PermanentAddress_State === 'Select state' || PermanentAddress_State === 'State' || PermanentAddress_State !== "-" ) {
      if (!PermanentAddress_State) {
        isValid = false;
        errors["PermanentAddress_Stateerr"] = "State is required";
      }
      else if (PermanentAddress_State.length < 3) {
        isValid = false;
        errors["PermanentAddress_Stateerr"] = "A Minimum of 3 characters are required";
      }
    }


   else if (PermanentAddress_Pincode !== '-') {
      if (!PermanentAddress_Pincode) {
        isValid = false;
        errors["PermanentAddress_Pincodeerr"] = "Pincode is required";
      }
      else if (PermanentAddress_Pincode === "-" && !pincodeRegex.test(PermanentAddress_Pincode)) {
        isValid = false;
        errors["PermanentAddress_Pincodeerr"] = "Invalid Pincode";
      } else if (PermanentAddress_Pincode.length < 6) {
        isValid = false;
        errors["PermanentAddress_Pincodeerr"] = "Minimum 6 Characters Required";
      }
    }
    setFormErrors(errors);
    return isValid;
};


  const AddressValidation = () => {
    let errors = {};
    let isValid = true;
    var CurrentAddress_Country = document.getElementById(
      "CurrentAddress_Country"
    ).value;
    var CurrentAddress_State = document.getElementById(
      "CurrentAddress_State"
    ).value;
    var CurrentAddress_City = document.getElementById(
      "CurrentAddress_City"
    ).value;
    var CurrentAddress_Pincode = document.getElementById(
      "CurrentAddress_Pincode"
    ).value;
    var PermanentAddress_Country = document.getElementById(
      "PermanentAddress_Country"
    ).value;
    var PermanentAddress_State = document.getElementById(
      "PermanentAddress_State"
    ).value;
    var PermanentAddress_City = document.getElementById(
      "PermanentAddress_City"
    ).value;
    var PermanentAddress_Pincode = document.getElementById(
      "PermanentAddress_Pincode"
    ).value;
    var HouseType = document.getElementById("HouseType").value;
    var OthersHouseType = document.getElementById("HouseText").value;
    var StayingSince = document.getElementById("StayingSince").value;
    //var CurrentCitySince = document.getElementById("CurrentCitySince").value
    var myDate = new Date(StayingSince);
    var today = new Date();
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    if (
      CurrentAddress_City === "" ||
      !CurrentAddress_City ||
      CurrentAddress_City === "-"
    ) {
      isValid = false;
      errors["CurrentAddress_Cityerr"] = "City is required";
    } else if (CurrentAddress_City.length < 3) {
      isValid = false;
      errors["CurrentAddress_Cityerr"] =
        "A Minimum of 3 characters are required";
    }
    if (
      CurrentAddress_Country === "" ||
      !CurrentAddress_Country ||
      CurrentAddress_Country === "-"
    ) {
      isValid = false;
      errors["CurrentAddress_Countryerr"] = "Country is required";
    }
    if (
      CurrentAddress_State === "" ||
      !CurrentAddress_State ||
      CurrentAddress_State === "-"
    ) {
      isValid = false;
      errors["CurrentAddress_Stateerr"] = "State is required";
    }

    if (
      CurrentAddress_Pincode === "" ||
      !CurrentAddress_Pincode ||
      CurrentAddress_Pincode === "-"
    ) {
      isValid = false;
      errors["CurrentAddress_Pincodeerr"] = "Pincode is required";
    } else if (CurrentAddress_Pincode.length < 6) {
      isValid = false;
      errors["CurrentAddress_Pincodeerr"] = "A Maximum of 6 characters are required";
    }
    if (!HouseType || HouseType == "-") {
      isValid = false;
      errors["HouseTypeerr"] = "House Type is required";
    }


    if (HouseType === 'others' && !OthersHouseType) {
      isValid = false;
      errors["HouseTypeerr"] = "House Type is required";
    }
    if (OthersHouseType !== "" && OthersHouseType.length < 3) {
      isValid = false;
      errors["HouseTypeerr"] = "A Minimum of 3 characters are required";
    }
    if (!StayingSince) {
      isValid = false;
      errors["StayingSinceerr"] = "Staying Since is required";
    }
    if (StayingSince === "dd-mm-yyyy") {
      isValid = false;

      errors["StayingSinceerr"] = "Staying Since is required";
    } else if (myDate > today) {
      isValid = false;
      errors["StayingSinceerr"] = "Valid Staying Since is required";
    }

    if (
      PermanentAddress_City !== '-'
    ) {
      if (!PermanentAddress_City) {
        isValid = false;
        errors["PermanentAddress_Cityerr"] = "City is required";
      }
      else if (PermanentAddress_City.length < 3) {
        isValid = false;
        errors["PermanentAddress_Cityerr"] =
          "A Minimum of 3 characters are required";
      }
    }

    if (PermanentAddress_Country !== '-' || PermanentAddress_Country === "Select") {
      if (!PermanentAddress_Country) {
        isValid = false;
        errors["PermanentAddress_Countryerr"] = "Country is required";
      }
    }

    if (PermanentAddress_State === 'Select state' || PermanentAddress_State !== '-') {
      if (!PermanentAddress_State) {
        isValid = false;
        errors["PermanentAddress_Stateerr"] = "State is required";
      }
      else if (PermanentAddress_State.length < 3) {
        isValid = false;
        errors["PermanentAddress_Stateerr"] = "A Minimum of 3 characters are required";
      }
    }


    if (PermanentAddress_Pincode !== '-') {
      if (!PermanentAddress_Pincode) {
        isValid = false;
        errors["PermanentAddress_Pincodeerr"] = "Pincode is required";
      }
      else if (PermanentAddress_Pincode !== "-" && !pincodeRegex.test(PermanentAddress_Pincode)) {
        isValid = false;
        errors["PermanentAddress_Pincodeerr"] = "Invalid Pincode";
      } else if (PermanentAddress_Pincode.length < 6) {
        isValid = false;
        errors["PermanentAddress_Pincodeerr"] = "Minimum 6 Characters Required";
      }
    }
    setFormErrors(errors);
    return isValid;
  };

 const HandleHouse = () => {
    var HouseType = document.getElementById("HouseType").value;
    if (HouseType === "others") {
      document.getElementById("HouseText").value = "";
      document.getElementById("HouseText").type = "text";
    } else {
      document.getElementById("HouseText").value = "None";
      document.getElementById("HouseText").type = "hidden";
    }
  }

  const Familyhandlechange= ()=>{
    var FirstName = document.getElementById("Firstname").value;
    var LastName = document.getElementById("Lastname").value;
    var MiddleName = document.getElementById("MiddleName").value;
    var Contact = document.getElementById("famContact").value;
    var Email = document.getElementById("famEmail").value;
    var Relationship = document.getElementById("Relationship").value;
    var EmergencyContact = document.getElementById("EmergencyContact").checked;
    let errors = {};
    let isValid = true;

    var mobPattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?(?!0+$)(?:[6-9]\d{9}|[6-9]\d{2}-\d{3}-\d{4})$/;

    if (!FirstName) {
      isValid = false;
      errors["FirstNameErr"] = "First Name is required";
    } else if (FirstName.length < 3) {
      isValid = false;
      errors["FirstNameErr"] = "Minimum 3 Characters Required";
    }
   else if (!LastName) {
      isValid = false;
      errors["LastNameErr"] = "Last Name is required";
    }
    else if (!Relationship) {
      isValid = false;
      errors["RelationshipErr"] = "Relationship is required";
    }
   else if (EmergencyContact === true) {
      if (!Contact) {
        isValid = false;
        errors["ContactErr"] = "Contact number is required";
      } else if (!mobPattern.test(Contact)) {
        isValid = false;
        errors["ContactErr"] = " Invalid Contact Number";
      }

    }

  else  if (EmergencyContact === false) {
      if (Contact !== "" && Contact !== "-" && (Contact.length < 10 || !mobPattern.test(Contact))) {
        isValid = false;
        errors["ContactErr"] = "Enter a valid Contact Number";
      }
    }



    else if (Email.trim() && Email !== '-') {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
        isValid = false;
        errors["EmailErr"] = "Enter a valid Email";
      }
    }
    setFormErrors(errors);
    return isValid;

  }
  const FamilyValidation = () => {

      var FirstName = document.getElementById("Firstname").value;
      var LastName = document.getElementById("Lastname").value;
      // var MiddleName = document.getElementById("MiddleName").value;
      var Contact = document.getElementById("famContact").value;
      var Email = document.getElementById("famEmail").value;
      var Relationship = document.getElementById("Relationship").value;
      var EmergencyContact = document.getElementById("EmergencyContact").checked;
      let errors = {};
      let isValid = true;

      var mobPattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?(?!0+$)(?:[6-9]\d{9}|[6-9]\d{2}-\d{3}-\d{4})$/;
      //Email
      if (!FirstName) {
        isValid = false;
        errors["FirstNameErr"] = "First Name is required";
      } else if (FirstName.length < 3) {
        isValid = false;
        errors["FirstNameErr"] = "Minimum 3 Characters Required";
      }
      if (!LastName) {
        isValid = false;
        errors["LastNameErr"] = "Last Name is required";
      }
      if (EmergencyContact === true) {
        if (!Contact) {
          isValid = false;
          errors["ContactErr"] = "Contact number is required";
        } else if (!mobPattern.test(Contact)) {
          isValid = false;
          errors["ContactErr"] = " Invalid Contact Number";
        }
  
      }
  
      if (EmergencyContact === false) {
        if (Contact !== "" && Contact !== "-" && (Contact.length < 10 || !mobPattern.test(Contact))) {
          isValid = false;
          errors["ContactErr"] = "Enter a valid Contact Number";
        }
      }
      // if (MiddleName !== "") {
      //   isValid = false;
      //   errors["MiddleNameErr"] = "Middle Name is required";
      // }
      if (!Relationship) {
        isValid = false;
        errors["RelationshipErr"] = "Relationship is required";
      }
  
      if (Email.trim() && Email !== '-') {
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
          isValid = false;
          errors["EmailErr"] = "Enter a valid Email";
        }
      }
      setFormErrors(errors);
      return isValid;

  };
const messagecancel =()=>{
  setFormErrors('')
}
  const EducationValidation = () => {
    let errors = {};
    let isValid = true;

    var Qualification = document.getElementById("Qualification").value;
    var St_date = document.getElementById("Startdate").value;
    var Coursename = document.getElementById("Coursename").value;
    var End_date = document.getElementById("Enddate").value;
    var Coursetype = document.getElementById("Coursetype").value;
    var clg_name = document.getElementById("clg_name").value;
    var stream = document.getElementById("stream").value;
    var unisty_name = document.getElementById("unisty_name").value;
    var OthersQualification = document.getElementById("otherQType").value;

    if (!Qualification) {
      isValid = false;
      errors["Qualification"] = "Qualification  is required";
    }
    if (Qualification === 'Others' && !OthersQualification) {
      isValid = false;
      errors["Qualification"] = "Qualification  is required";
    } else if (OthersQualification !== "" && OthersQualification.length < 3) {
      isValid = false;
      errors["Qualification"] = "A Minimum of 3 characters are required";
    } 

    if (!St_date) {
      isValid = false;
      errors["Startdate"] = "Start From is required";
    }

    if (!Coursename) {
      isValid = false;
      errors["Coursename"] = "Course Name is required";
    }
    else if (Coursename.length < 2) {
      isValid = false;
      errors["Coursename"] = <div style={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>A Minimum of 2 characters are required</div>;
    }
    if (!End_date) {
      isValid = false;
      errors["Enddate"] = "End is required";
    }
    else if (St_date != null && End_date != null) {
      if (St_date === End_date) {
        isValid = false;
        errors["Enddate"] = "Start From and End  is Same";
      }
      if (new Date(St_date).getTime() > new Date(End_date).getTime()) {
        isValid = false;

        errors["Enddate"] = "Start From is less then End";
      }
    }

    if (!Coursetype) {
      isValid = false;
      errors["Coursetype"] = "Course Type is required";
    }
    if (clg_name.trim() !== "" && clg_name !== "-" && clg_name.length < 3) {
      isValid = false;
      errors["clg_name"] = "A minimum of 3 characters is required";
    }
    if (stream !== "" && stream !== "-" && stream.length < 3) {

      isValid = false;
      errors["stream"] = "A minimum of 3 characters is required";
    }
    if (!unisty_name) {
      isValid = false;
      errors["unisty_name"] = "University Name is required";
    } else if (unisty_name.length < 3) {
      isValid = false;
      errors["unisty_name"] = "A Minimum of 3 characters are required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleEducationChange = () => {
    let errors = {};
    let isValid = true;

    const getElementValue = (id) => {
        const element = document.getElementById(id);
        return element ? element.value : '';
    };

    const Qualification = getElementValue("Qualification");
    const St_date = getElementValue("Startdate");
    const Coursename = getElementValue("Coursename");
    const End_date = getElementValue("Enddate");
    const Coursetype = getElementValue("Coursetype");
    const clg_name = getElementValue("clg_name");
    const unisty_name = getElementValue("unisty_name");
    const OthersQualification = getElementValue("otherQType");

    if (!Qualification) {
        isValid = false;
        errors["Qualification"] = "Qualification is required";
    } else if (Qualification === 'Others' && !OthersQualification) {
        isValid = false;
        errors["Qualification"] = "Qualification is required";
    } else if (OthersQualification !== "" && OthersQualification.length < 3) {
        isValid = false;
        errors["Qualification"] = "A minimum of 3 characters are required";
    } else if (!Coursename) {
        isValid = false;
        errors["Coursename"] = "Course Name is required";
    } else if (Coursename.length < 2) {
        isValid = false;
        errors["Coursename"] = "A minimum of 2 characters are required";
    } else if (Qualification !== 'Others' && Coursename.length < 2) {
        isValid = false;
        errors["Coursename"] = "A minimum of 2 characters are required";
    } else if (Qualification === 'Others' && Coursename.length < 3) {
        isValid = false;
        errors["Coursename"] = "A minimum of 3 characters are required";
    } else if (!Coursetype) {
        isValid = false;
        errors["Coursetype"] = "Course Type is required";
    } else if (!St_date) {
        isValid = false;
        errors["Startdate"] = "Start From is required";
    } else if (!End_date) {
        isValid = false;
        errors["Enddate"] = "End is required";
        if (St_date && End_date) {
            if (St_date === End_date) {
                isValid = false;
                errors["Enddate"] = "Start From and End are the same";
            }
            if (new Date(St_date).getTime() > new Date(End_date).getTime()) {
                isValid = false;
                errors["Enddate"] = "Start From is earlier than End";
            }
        }
    } else if (!unisty_name) {
        isValid = false;
        errors["unisty_name"] = "University Name is required";
    } else if (unisty_name.length < 3) {
        isValid = false;
        errors["unisty_name"] = "A minimum of 3 characters are required";
    }
    if (clg_name) {
        if (clg_name.length <= 2) {
            isValid = false;
            errors["clg_name"] = "A minimum of 3 characters are required";
        }
    }

    setFormErrors(errors);
    return isValid;
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (

    <div>
      <SideBar active={window.location.pathname} />
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="pcoded-content ">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-header">
                <div className="page-header-title"></div>
              </div>
              {/*  Start */}
              <div className="page-body ">
                {/*  */}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="glowBox">
                        <div>
                          <div style={{ float: "left" }}>
                            <a
                              href
                              onClick={() => props.history.goBack()}
                            >
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                        </div>
                        <h4 className="title" style={{ marginRight: "83px" }}>
                          {details.map((d) => {
                            return d.firstName
                          })}
                        </h4>
                      </div>

                      {details.map((check) =>{
                        return check.invite === 0 ? <center>No Data Found</center> : 
                      <div className="grid-container">
                      {details.map((d) => {
                        return <>

                          {/* Personal Profile */}
                          <div className="grid-item1">
                            <div className="profile">


                              <p className='grid-user-name'>{d.firstName}
                                {
                                  d.status === 1 ? <span className='green-dot' title="Active"></span> : <span className='red-dot' title="Inactive"></span>
                                }


                              </p>
                              {personalInfoDetails.map((personal) => {
                                return <img src={personal.profile_Picture} alt="Profile" className="profile-image" />
                              })}
                            </div>

                            <div className='newcontainer'>
                              {personalInfoDetails.map((personal) => {
                                const age = calculateAge(personal.dob);
                                return (
                                  <div key={personal.id} className='biodata'>
                                    <div className='info-row'>
                                      <span className='tdright'>Employee ID: </span>
                                      <span className='tdleft'>{d.teamId}</span>
                                    </div>
                                    <div className='info-row'>
                                      <span className='tdright'>AGE: </span>
                                      <span className='tdleft'>{age}</span>
                                    </div>
                                    <div className='info-row'>
                                      <span className='tdright'>Gender: </span>
                                      <span className='tdleft'>{personal.gender}</span>
                                    </div>
                                    <div className='info-row'>
                                      <span className='tdright'>Status: </span>
                                      <span className='tdleft'>{personal.maritalStatus}</span>
                                    </div>
                                    <div className='info-row'>
                                      <span className='tdright'>Blood group: </span>
                                      <span className='tdleft'>{personal.bloodGroup}</span>
                                    </div>
                                    <div className='info-Profile'>
                                      <span className='tdright'>Email Address: </span>
                                      <span className='tdleft'>{personal.email}</span>
                                    </div>
                                    {/* {details.map((formobile) =>{
                                      return  */}
                                      <div className='info-row'>
                                      <span className='tdright'>Mobile: </span>
                                      <span className='tdleft'>{personal.homeNumber}</span>
                                    </div>
                                     {/* })} */}

                                    
                                    <div className='info-row' style={{ marginTop: "10px" }}>
                                      {personalInfoDetails.map((personal) => {
                                        return (
                                          <>
                                            {personal.email === email ? (
                                              <Link
                                                className='edit-button'
                                                to={{
                                                  pathname: '/Profile',
                                                  state: {
                                                    ProfileClick: true,
                                                  },
                                                }}
                                              >
                                                <img src={pencil} alt='Profile' className='edit-image' />
                                              </Link>
                                            ) : personal.adminEmail === email ? (
                                              <p className='edit-button'>
                                                <img
                                                  src={pencil}
                                                  alt='Profile'
                                                  className='edit-image'
                                                  onClick={togglePopup}
                                                />
                                              </p>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {/* Bio */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                <div className="grid-item">
                                  <h4>Bio</h4>
                                  <p className='bio-text'>{personal.bio}</p>
                                  {/* Bio Edit */}

                                  {personalInfoDetails.map((personal) => {
                                    return (
                                      <>
                                        {personal.email === email ? (
                                          <div className='bottom-edit-button'>
                                            <Link
                                              className='edit-button'
                                              to={{
                                                pathname: '/Profile',
                                                state: {
                                                  ProfileClick: true,
                                                },
                                              }}
                                            >
                                              <img src={pencil} alt='Profile' className='edit-image'

                                              />
                                            </Link>
                                          </div>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </div></>)
                          })}

                          {/* Personality */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                <div className="grid-item">
                                  <h4>Personality</h4>
                                  <div className="personality-content">
                                    {personalityDetails.map((personality) => {
                                      return <p><span className='textwithbackground'>{personality.personalityName}</span></p>
                                    })}
                                  </div>
                                </div></>)
                          })}

                          {/* Social Media */}
                          <div className="grid-item">
                            <div className='info-row1'>
                              <h4>Social Media</h4>
                              {personalInfoDetails.map((socialmedia) => {
                                return <>
                                  {
                                    socialmedia.faceBook === "-" ? null : <a
                                      href={`https://www.facebook.com/${socialmedia.faceBook}`} 
                                      title={socialmedia.faceBook}
                                      target="_blank" rel="noopener noreferrer">
                                      <img src={facebook} alt="Facebook" className="social-media-image" />
                                    </a>


                                  }
                                  {
                                    socialmedia.whatsappNumber === "-" ? null : <a
                                      href={`https://api.whatsapp.com/send?phone=${socialmedia.whatsappNumber}`} 
                                      title={socialmedia.whatsappNumber}
                                      target="_blank" rel="noopener noreferrer">
                                      <img src={whatsapp} alt="WhatsApp" className="social-media-image" />
                                    </a>

                                  }
                                  {
                                    socialmedia.linkedIn === "-" ? null : <a
                                      href={`https://www.linkedin.com/in/${socialmedia.linkedIn}`} 
                                      title={socialmedia.linkedIn}
                                      target="_blank" rel="noopener noreferrer">
                                      <img src={linkedin} alt="LinkedIn" className="social-media-image" />
                                    </a>

                                  }
                                  {
                                    socialmedia.twitter === "-" ? null : <a
                                      href={`https://www.twitter.com/${socialmedia.twitter}`} 
                                      title={socialmedia.twitter}
                                      target="_blank" rel="noopener noreferrer">
                                      <img src={twitter} alt="Twitter" className="social-media-image" />
                                    </a>

                                  }
                                  {
                                    socialmedia.skype === "-" ? null : <a
                                      href={`skype:${socialmedia.skype}?chat`} 
                                      title={socialmedia.skype}
                                      target="_blank" rel="noopener noreferrer">
                                      <img src={Skype} alt="Skype" className="social-media-image" />
                                    </a>

                                  }

                                  {/* Social Media Edit */}

                                  {personalInfoDetails.map((personal) => {
                                    return (
                                      <>
                                        {personal.email === email ?
                                          <div className='bottom-edit-button'>
                                            <Link
                                              to={{
                                                pathname: '/Profile',
                                                state: {
                                                  SocialClick: true,
                                                }
                                              }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                          </div>
                                          : null}</>)
                                  })}


                                </>
                              })}
                            </div>
                          </div>

                          {/* Lifestyle */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                <div className="grid-item">
                                  <h4>Life style</h4>
                                  {
                                    lifeStyleDetails.map((life) => {
                                      return <div className="personality-content">
                                        <p><span className='textwithbackground'>{life.lifeStyle}</span></p>
                                      </div>

                                    })
                                  }

                                </div></>)
                          })}

                          {/* Professional */}
                          <div className="grid-item">
                            <div className='newcontainer'>
                              <h4>Professional</h4>
                              <div>
                                {details.map((d, index) => {
                                  return (
                                    <div key={index} className='biodata'>
                                      <div className='info-row'>
                                        <span className='tdright'>Department: </span>
                                        <span className='tdleft'>{d.department}</span>
                                      </div>
                                      <div className='info-row'>
                                        <span className='tdright'>Designation: </span>
                                        <span className='tdleft'>{d.designation}</span>
                                      </div>
                                      <div className='info-row'>
                                        <span className='tdright'>Role: </span>
                                        <span className='tdleft'>{d.role}</span>
                                      </div>
                                      <div className='info-row'>
                                        <span className='tdright'>Reporting Manager: </span>
                                        <span className='tdleft'>{d.report_Manager}</span>
                                      </div>
                                      <div className='info-row'>
                                        <span className='tdright'>Location: </span>
                                        <span className='tdleft'>{d.primary_Ofc}</span>
                                      </div>
                                      <div className='info-row'>
                                        <span className='tdright'>Joining Date: </span>
                                        <span className='tdleft'>{formatDate(d.joining_Date)}</span>
                                      </div>
                                    </div>
                                  );
                                })}

                                {personalInfoDetails.map((personal) => {
                                  return (
                                    <>
                                      {personal.adminEmail === email ? (
                                        <div className='bottom-edit-button'>
                                          <p className='edit-button'>
                                            <img
                                              src={pencil}
                                              alt='Profile'
                                              className='edit-image'
                                              onClick={(e) => Editprofile(props.location.state.Id, e)}
                                            />
                                          </p>
                                        </div>
                                      ) : null}
                                    </>
                                  );
                                })}

                              </div>
                            </div>
                          </div>

                          {/* Admin Banking and User Education */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                {personal.email === email || personal.adminEmail === email ?
                                  // Banking
                                  <div className="grid-item">
                                    <h4>Banking</h4>
                                    <div>
                                      {personalInfoDetails.map((personal) => {
                                        return <>
                                          <div className='biodata'>
                                            <div className='info-row'>
                                              <span className='tdright'>Account Number: </span>
                                              <span className='tdleft'>{maskNumber(personal.acNo)}</span>
                                            </div >
                                            <div className='info-row'>
                                              <span className='tdright'>Account Type: </span>
                                              <span className='tdleft'>{personal.acType}</span>
                                            </div>
                                            <div className='info-row'>
                                              <span className='tdright'>IFSC CODE: </span>
                                              <span className='tdleft'>{personal.ifsc}</span>
                                            </div>
                                            <div className='info-row'>
                                              <span className='tdright'>Branch name: </span>
                                              <span className='tdleft'>{personal.branchname}</span>
                                            </div>
                                            <div className='info-row'>
                                              <span className='tdright'>Branch Address: </span>
                                              <span className='tdleft'>{personal.branchaddress}</span>
                                            </div>
                                          </div>
                                          {/* Banking Edit */}
                                          <div className='info-row' style={{ marginTop: "10px" }}>
                                            {personalInfoDetails.map((personal) => {
                                              return (
                                                <>
                                                  {personal.email === email ? (
                                                    <Link
                                                      className='edit-button'
                                                      to={{
                                                        pathname: '/Profile',
                                                        state: {
                                                          ProfileClick: true,
                                                        },
                                                      }}
                                                    >
                                                      <img src={pencil} alt='Profile' className='edit-image' />
                                                    </Link>
                                                  ) : null}
                                                </>
                                              );
                                            })}
                                          </div>
                                        </>
                                      })}
                                      {personalInfoDetails.map((personal) => {
                                        return (
                                          <>
                                            {personal.email === email ?
                                              <Link
                                                to={{
                                                  pathname: '/Profile',
                                                  state: {
                                                    BankClick: true,
                                                  }
                                                }}><img src={pencil} style={{ width: "15px" }} alt="Profile" /></Link>
                                              : null}</>)
                                      })}
                                    </div>
                                  </div> :
                                  // Education 
                                  <div className="grid-item">
                                    <h4>Education</h4>
                                    {educationDetail.slice(0, EducationvisibleCount).map((educate, index) => (
                                      <div key={index} className='biodata'>
                                        <div className='info-row'>
                                          <span className='tdright'>Qualification Type: </span>
                                          <span className='tdleft'>{educate.qualificationType}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Qualification: </span>
                                          <span className='tdleft'>{educate.courseName}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Type: </span>
                                          <span className='tdleft'>{educate.courseType}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Stream: </span>
                                          <span className='tdleft'>{educate.stream}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Start Date: </span>
                                          <span className='tdleft'>{educate.courseStartDate}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>END Date: </span>
                                          <span className='tdleft'>{educate.courseEndDate}</span>
                                        </div>
                                        {/* Education Edit */}

                                        {personalInfoDetails.map((personal) => {
                                          return (
                                            <>
                                              {personal.email === email ? (
                                                <div className='bottom-edit-button'>
                                                  <Link
                                                    className='edit-button'
                                                    to={{
                                                      pathname: '/Profile',
                                                      state: {
                                                        EducationClick: true,
                                                      },
                                                    }}
                                                  >
                                                    <img src={pencil} alt='Profile' className='edit-image' />
                                                  </Link>
                                                </div>
                                              ) : personal.adminEmail === email ? (
                                                <div className='bottom-edit-button'>
                                                  <p className='edit-button'>
                                                    <img
                                                      src={pencil}
                                                      alt='Profile'
                                                      className='edit-image'
                                                      onClick={(e) => togglePopup5(educate.id, e)}
                                                    />
                                                  </p>
                                                </div>
                                              ) : null}
                                            </>
                                          );
                                        })}

                                      </div>
                                    ))}
                                    <div className="button-container">
                                      {EducationvisibleCount < educationDetail.length && (
                                        <button onClick={handleEducationSeeMore}>See More</button>
                                      )}
                                      {EducationvisibleCount > 1 && (
                                        <button onClick={handleEducationSeeLess}>See Less</button>
                                      )}
                                    </div>

                                  </div>}</>)
                          })}

                          {/* Interest */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                <div className="grid-item">
                                  <h4>Interests</h4>

                                  <div className="personality-content">
                                    {interestDetails.map((int) => {
                                      return <p><span className='textwithbackground'>{int.interests}</span></p>
                                    })}
                                  </div>
                                </div></>)
                          })}

                          {/* Contact */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                {personal.email === email || personal.adminEmail === email ? <div className="grid-item">
                                  <h4>Contact</h4>
                                  {personalInfoDetails.map((educate, index) => (
                                    <div key={index} className='biodata'>
                                      <div className='info-row'>
                                        <span className='tdright'>Official Email: </span>
                                        <span className='tdleft'>{educate.email}</span>
                                      </div>
                                      <div className='info-row'>
                                        <span className='tdright'>Personal Email: </span>
                                        <span className='tdleft'>{educate.officialEmail}</span>
                                      </div>
                                      <div className='info-row'>
                                        <span className='tdright'>Office Number: </span>
                                        <span className='tdleft'>{educate.phoneNumber}</span>
                                      </div>
                                      <div className='info-row'>
                                        <span className='tdright'>Mobile Number: </span>
                                        <span className='tdleft'>{educate.homeNumber}</span>
                                      </div>
                                      <div className='info-row'>
                                        <span className='tdright'>ALT. Mobile Number: </span>
                                        <span className='tdleft'>{educate.alternatePhoneNo}</span>
                                      </div>
                                    </div>
                                  ))}

                                  {/* Contact Edit */}
                                  {personalInfoDetails.map((personal) => {
                                    return (
                                      <>
                                        {personal.email === email ? (
                                          <div className='bottom-edit-button'>
                                            <Link
                                              className='edit-button'
                                              to={{
                                                pathname: '/Profile',
                                                state: {
                                                  ProfileClick: true,
                                                },
                                              }}
                                            >
                                              <img src={pencil} alt='Profile' className='edit-image' />
                                            </Link>
                                          </div>
                                        ) : personal.adminEmail === email ? (
                                          <div className='bottom-edit-button'>
                                            <p className='edit-button'>
                                              <img
                                                src={pencil}
                                                alt='Profile'
                                                className='edit-image'
                                                onClick={togglePopup3}
                                              />
                                            </p>
                                          </div>
                                        ) : null}
                                      </>
                                    );
                                  })}

                                </div> : <div className="grid-item-violet">
                                </div>}</>)
                          })}

                          {/* Admin Education and User Skill */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                {personal.email === email || personal.adminEmail === email ?
                                  <div className="grid-item">
                                    <h4>Education &nbsp; <i className="icofont icofont-plus" onClick={togglePopup8} style={{ fontSize: "10px", cursor: "pointer" }}></i></h4>
                                    {educationDetail.slice(0, EducationvisibleCount).map((educate, index) => (
                                      <div key={index} className='biodata'>
                                        <div className='info-row'>
                                          <span className='tdright'>Qualification Type: </span>
                                          <span className='tdleft'>{educate.qualificationType}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Qualification: </span>
                                          <span className='tdleft'>{educate.courseName}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Type: </span>
                                          <span className='tdleft'>{educate.courseType}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Stream: </span>
                                          <span className='tdleft'>{educate.stream}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Start Date: </span>
                                          <span className='tdleft'>{educate.courseStartDate}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>END Date: </span>
                                          <span className='tdleft'>{educate.courseEndDate}</span>
                                        </div>
                                        &nbsp;
                                        {/* Education Edit */}
                                        <div className='info-row'>
                                          {personal.email === email ? (
                                            <div>
                                              <Link
                                                className='edit-button'
                                                to={{
                                                  pathname: '/Profile',
                                                  state: {
                                                    EducationClick: true,
                                                  },
                                                }}
                                              >
                                                <img src={pencil} alt='Profile' className='edit-image' />
                                              </Link>
                                            </div>
                                          ) : personal.adminEmail === email ? (
                                            <div>
                                              <p className='edit-button'>
                                                <img
                                                  src={pencil}
                                                  alt='Profile'
                                                  className='edit-image'
                                                  onClick={(e) => togglePopup5(educate.id, e)}
                                                />
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>

                                      </div>
                                    ))}
                                    <div className="button-container">
                                      {EducationvisibleCount < educationDetail.length && (
                                        <button onClick={handleEducationSeeMore}>See More</button>
                                      )}
                                      {EducationvisibleCount > 1 && (
                                        <button onClick={handleEducationSeeLess}>See Less</button>
                                      )}
                                    </div>
                                  </div> :
                                  // SKills  
                                  <div className="grid-item">
                                    <h4>Skills</h4>
                                    {displayedSkills.map((skill, index) => (
                                      <ul key={index} className="skills-list">
                                        <li>{skill.skillName}&nbsp;&nbsp;
                                          {personalInfoDetails.map((personal) => {
                                            return (
                                              <>
                                                {personal.adminEmail === email ? (<span>
                                                  <img
                                                    src={pencil}
                                                    alt='Profile'
                                                    className='edit-image'
                                                    onClick={(e) => togglePopup6(skill.id, e)}
                                                  />
                                                </span>) : null}
                                              </>
                                            );
                                          })}
                                        </li>
                                      </ul>
                                    ))}

                                    <div className="button-container">
                                      {skillDetails.length > initialSkillsToShow && (
                                        <button onClick={toggleSkills}>
                                          {showAllSkills ? 'See less' : 'See more'}
                                        </button>
                                      )}
                                    </div>
                                    {/* Skills Edit */}
                                    <div className='info-row' style={{ marginTop: "10px" }}>
                                      {personalInfoDetails.map((personal) => {
                                        return (
                                          <>
                                            {personal.email === email ? (
                                              <Link
                                                className='edit-button'
                                                to={{
                                                  pathname: '/Profile',
                                                  state: {
                                                    ProfileClick: true,
                                                  },
                                                }}
                                              >
                                                <img src={pencil} alt='Profile' className='edit-image' />
                                              </Link>
                                            ) : personal.adminEmail === email ? (
                                              null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </div>

                                  </div>
                                }
                              </>)
                          })}

                          {/* Activities */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                <div className="grid-item">
                                  <h4>Activities</h4>
                                  <div className="personality-content">
                                    {activitiesDetails.map((act) => {
                                      return <p><span className='textwithbackground'>{act.activities}</span></p>
                                    })}
                                  </div>
                                </div></>)
                          })}

                          {/* Empty */}
                          <div className="grid-item-violet">
                          </div>

                          {/* Admin Skills and user null */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                {personal.email === email || personal.adminEmail === email ?
                                  // Skills
                                  <div className="grid-item">
                                    <h4>Skills &nbsp; <i className="icofont icofont-plus" onClick={togglePopup10} style={{ fontSize: "10px", cursor: "pointer" }}></i></h4>
                                    {displayedSkills.map((skill, index) => (
                                      <ul key={index} className="skills-list">
                                        <li>{skill.skillName}&nbsp;&nbsp;
                                         
                                        </li>
                                      </ul>
                                    ))}

                                    <div className="button-container">
                                      {skillDetails.length > initialSkillsToShow && (
                                        <button onClick={toggleSkills}>
                                          {showAllSkills ? 'See less' : 'See more'}
                                        </button>
                                      )}
                                    </div>
                                    {/* Skills Edit */}
                                    <div className='info-row' style={{ marginTop: "10px" }}>
                                      {personalInfoDetails.map((personal) => {
                                        return (
                                          <>
                                            {personal.email === email ? (
                                              <Link
                                                className='edit-button'
                                                to={{
                                                  pathname: '/Profile',
                                                  state: {
                                                    ProfileClick: true,
                                                  },
                                                }}
                                              >
                                                <img src={pencil} alt='Profile' className='edit-image' />
                                              </Link>
                                            ) : personal.adminEmail === email ? (
                                              null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </div>
                                    {displayedSkills.length !== 0 ?
                                    <>
                                    {personal.adminEmail === email ? (<span>
                                              <img
                                                src={pencil}
                                                alt='Profile'
                                                className='edit-image'
                                                onClick={togglePopup11}
                                              />
                                            </span>) : null}</>:null}

                                  </div>
                                  :
                                  null
                                }
                              </>)
                          })}


                          {/* Family */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                {personal.email === email || personal.adminEmail === email ?
                                  <div className="grid-item">
                                    <h4>Family &nbsp; <i className="icofont icofont-plus" onClick={togglePopup9} style={{ fontSize: "10px", cursor: "pointer" }}></i></h4>
                                    {familyDetails.slice(0, visibleCount).map((family, index) => (
                                      <div key={family.id} className='biodata'>
                                        <div className='info-row'>
                                          <span className='tdright'>First Name: </span>
                                          <span className='tdleft'>{family.firstName}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Last Name: </span>
                                          <span className='tdleft'>{family.lastName}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Relationship: </span>
                                          <span className='tdleft'>{family.relationship}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Contact: </span>
                                          <span className='tdleft'>{family.contact}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Email: </span>
                                          <span className='tdleft'>{family.email}</span>
                                        </div>
                                        {family.emergencyContact === 'Yes' ? <div className='info-row'>
                                          <span className='tdright'>Emergency Contact</span>
                                          <span className='tdleft'>Yes</span>
                                        </div>
                                          : null} &nbsp;
                                        {/* Family Edit */}

                                        {personalInfoDetails.map((personal) => {
                                          return (
                                            <>
                                              {personal.email === email ? (
                                                <div>
                                                  <Link
                                                    className='edit-button'
                                                    to={{
                                                      pathname: '/Profile',
                                                      state: {
                                                        ProfileClick: true,
                                                      },
                                                    }}
                                                  >
                                                    <img src={pencil} alt='Profile' className='edit-image' />
                                                  </Link>
                                                </div>
                                              ) : personal.adminEmail === email ? (
                                                <div>
                                                  <p className='edit-button'>
                                                    <img
                                                      src={pencil}
                                                      alt='Profile'
                                                      className='edit-image'
                                                      onClick={(e) => {
                                                        togglePopup7(family.id, e);
                                                      }}
                                                    />
                                                  </p>
                                                </div>
                                              ) : null}
                                            </>
                                          );
                                        })}
                                      </div>

                                    ))}
                                    <div className="button-container">
                                      {visibleCount < familyDetails.length && (
                                        <button onClick={handleSeeMore}>See More</button>
                                      )}
                                      {visibleCount > 1 && (
                                        <button onClick={handleSeeLess}>See Less</button>
                                      )}
                                    </div>

                                  </div> : null}</>)
                          })}

                          {/* Empty */}
                          <div className="grid-item-violet">
                          </div>

                          {/* Address */}
                          {personalInfoDetails.map((personal) => {
                            return (
                              <>
                                {personal.email === email || personal.adminEmail === email ?
                                  <div className="grid-item">
                                    <h4>Address</h4>
                                    {personalInfoDetails.map((educate, index) => (
                                      <div key={index} className='biodata'>
                                        <div className='info-row'>
                                          <span className='tdright'>Current Address: </span>
                                          <span className='tdleft'></span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>City: </span>
                                          <span className='tdleft'>{educate.currentAddress_City}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>State: </span>
                                          <span className='tdleft'>{educate.currentAddress_State}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Country: </span>
                                          <span className='tdleft'>{educate.currentAddress_Country}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Pin code: </span>
                                          <span className='tdleft'>{educate.currentAddress_Pincode}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>House Type: </span>
                                          <span className='tdleft'>{educate.houseType}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Staying since: </span>
                                          <span className='tdleft'>{educate.stayingSince.split('-')[0]}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Permanant Address: </span>
                                          <span className='tdleft'></span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>City: </span>
                                          <span className='tdleft'>{educate.permanentAddress_City}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>State: </span>
                                          <span className='tdleft'>{educate.permanentAddress_State}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Country: </span>
                                          <span className='tdleft'>{educate.permanentAddress_Country}</span>
                                        </div>
                                        <div className='info-row'>
                                          <span className='tdright'>Pin code: </span>
                                          <span className='tdleft'>{educate.permanentAddress_Pincode}</span>
                                        </div>
                                      </div>
                                    ))}
                                    {/* Address Edit */}

                                    {personalInfoDetails.map((personal) => {
                                      return (
                                        <>
                                          {personal.email === email ? (
                                            <div className='bottom-edit-button'>
                                              <Link
                                                className='edit-button'
                                                to={{
                                                  pathname: '/Profile',
                                                  state: {
                                                    ProfileClick: true,
                                                  },
                                                }}
                                              >
                                                <img src={pencil} alt='Profile' className='edit-image' />
                                              </Link>
                                            </div>
                                          ) : personal.adminEmail === email ? (
                                            <div className='bottom-edit-button'>
                                              <p className='edit-button'>
                                                <img
                                                  src={pencil}
                                                  alt='Profile'
                                                  className='edit-image'
                                                  onClick={togglePopup4}
                                                />
                                              </p>
                                            </div>
                                          ) : null}
                                        </>
                                      );
                                    })}

                                  </div> : null}</>)
                          })}
                        </>
                      })}
                    </div>
                      })}

                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





      {
        contactopen ? <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal threeFieldPopMobile"
            }
            style={w > 768 ? { padding: "2%" } : { padding: "4%" }}
          >
            <p onClick={(e) => setContactopen(false, e)} className='cornerleft'>x</p>
            <form autoComplete="off"
              onSubmit={ContactEdit} >

              {personalInfoDetails.map((personal) => {

                return (
                  <>
                    <div className='info-row'>
                      <input
                        type="hidden"
                        name="Id"
                        defaultValue={
                          personal.userId
                        }
                      />
                    </div>
                    <div className='info-row'>
                      <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Official Email: </span>
                      <input
                        className="effect-16"
                        onFocus={(e) => e.target.select()}
                        style={{
                          width: "82%",
                          pointerEvents: "none",
                        }}
                        type="text"
                        id="PersonalEmail"
                        name="Email"
                        value={personal.email}
                        placeholder="Personal Email"
                      />

                    </div>
                    <div className='info-rowedit'>
                      <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Office Number: </span>
                      <input
                        className="effect-16"
                        onFocus={(e) => e.target.select()}
                        maxLength="10"
                        style={{ width: "82%" }}
                        type="text"
                        id="PhoneNumber"
                        name="PhoneNumber"
                        onKeyPress={(event) => {
                          const isLetter = /[a-zA-Z]/i.test(event.key);
                          if (isLetter) {
                            event.preventDefault();
                          }
                        }}
                        onInputCapture={(event) => {
                          if (!/^[0-9]*$/.test(event.target.value)) {
                            event.target.value = event.target.value.replace(/[^0-9]/g, '');
                          }
                        }}
                        defaultValue={personal.phoneNumber}
                        placeholder="Office Number "
                      />

                    </div>
                    <div className='info-row'>
                      <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Phone Number: </span>
                      <input
                        className="effect-16"
                        onFocus={(e) => e.target.select()}
                        maxLength="10"
                        style={{ width: "82%" }}
                        type="text"
                        id="HomeNumber"
                        name="HomeNumber"
                        onKeyPress={(event) => {
                          const isLetter = /[a-zA-Z]/i.test(event.key);
                          if (isLetter) {
                            event.preventDefault();
                          }
                        }}
                        onInputCapture={(event) => {
                          if (!/^[0-9]*$/.test(event.target.value)) {
                            event.target.value = event.target.value.replace(/[^0-9]/g, '');
                          }
                        }}
                        defaultValue={personal.homeNumber}
                        placeholder="Phone Number "
                      />

                    </div>
                    <div className='info-row'>
                      <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Personal Email: </span>
                      <input
                        className="effect-16"
                        onFocus={(e) => e.target.select()}
                        style={{ width: "82%" }}
                        type="text"
                        id="OfficialEmail"
                        name="OfficialEmail"
                        defaultValue={
                          personal.officialEmail
                        }
                        placeholder="-"
                      />

                    </div>
                    <div className='info-row'>
                      <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Alternate Phone Number: </span>
                      <input
                        className="effect-16"
                        onFocus={(e) => e.target.select()}
                        maxLength="10"
                        style={{ width: "82%" }}
                        type="text"
                        id="AlternatePhoneNo"
                        onKeyPress={(event) => {
                          const isLetter = /[a-zA-Z]/i.test(event.key);
                          if (isLetter) {
                            event.preventDefault();
                          }
                        }}
                        onInputCapture={(event) => {
                          if (!/^[0-9]*$/.test(event.target.value)) {
                            event.target.value = event.target.value.replace(/[^0-9]/g, '');
                          }
                        }}
                        defaultValue={
                          personal.alternatePhoneNo
                        }
                        name="AlternatePhoneNo"

                        placeholder="-"
                      />

                    </div>
                  </>)
              })}

              <br />
              <button
                type="submit"
                // disabled={this.state.ButtonDisabled}
                className="but"
                style={{
                  height: "39.5px",
                  width: "83.36px",
                }}
              >
                Update
              </button>
              <button
                type="reset"
                className=" btn-Secondary"
                style={{
                  marginLeft: "1%",
                  height: "39.5px",
                }}
              // onClick={() =>
              //   this.setState({ formErrors: "" })
              // }
              >
                Cancel
              </button>
            </form>
          </div>
        </div> : null
      }

      {/* Profile Edit */}
      <div>
        {isPopupOpen && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={()=>{
                        togglePopupClose();
                        setFormErrors("");
                      }}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%" }}
                    >
                      Profile
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <p className='grid-user-name'>{d.firstName}
                          {d.status === 1 ? (
                            <span className='green-dot' title="Active"></span>
                          ) : (
                            <span className='red-dot' title="Inactive"></span>
                          )}
                        </p>
                        {personalInfoDetails.map((personal, index) => (
                          <img key={index} src={personal.profile_Picture} alt="Profile" className="profile-image" />
                        ))}
                        {/* <i
                                                  className=" fa fa-pencil editbtn"
                                                  aria-hidden="true"
                                                  type="file" 
                                                  ref={fileInputRef} 
                                                  style={{ fontSize: "20px",
                                                  paddingTop: "8px",
                                                        paddingLeft: "4px",
                                                        marginTop:'-15px'
                                                   }}
                                                ></i> */}

                        <div className='newcontainer'>
                          {personalInfoDetails.map((personal) => {
                            const age = calculateAge(personal.dob);
                            return (
                              <div key={personal.id} className='biodata'>
                                <form autoComplete="off" onSubmit={submit}>
                                  <div className="row justify-content-center">
                                    <div className='col-lg-12'>
                                      <div className='info-row' >
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Employee ID: </span>
                                        <input
                                          className='effect-16'
                                          value={d.teamId}
                                        />
                                      </div>
                                      <div className='info-row'>
                                        <input
                                          type="hidden"
                                          name="Id"
                                          defaultValue={
                                            personal.userId
                                          }
                                        />

                                        <input
                                          className="effect-16"
                                          // onFocus={(e) => e.target.select()}
                                          maxLength="20"
                                          defaultValue={personal.firstName}
                                          name="FirstName"
                                          type="text"
                                          id="FirstNameinfo"
                                          placeholder="First Name"
                                          readOnly
                                          hidden
                                        />
                                        <input
                                          type="hidden"
                                          name="Old_middleName"
                                          defaultValue={
                                            personal.middleName
                                          }
                                        />
                                        <input
                                          type="hidden"
                                          name="LastName"
                                          defaultValue={
                                            personal.lastName
                                          }
                                        />
                                        <input
                                          accept="image/*"
                                          type="hidden"
                                          name="Old_Profile_Picture"
                                          defaultValue={
                                            personal.profile_Picture
                                          }
                                        />
                                      </div>

                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>DOB: </span>
                                        <input
                                          className='effect-16'
                                          type="date"
                                          id="DOB"
                                          max={new Date(
                                            new Date().getFullYear() - 18,
                                            new Date().getMonth(),
                                            new Date().getDate()
                                          ).toISOString().split('T')[0]}
                                          min={new Date(
                                            new Date().getFullYear() - 80,
                                            new Date().getMonth(),
                                            new Date().getDate()
                                          ).toISOString().split('T')[0]}
                                          defaultValue={reformatDate(personal.dob)}
                                          name="DOB"
                                          onChange={handleInputChange}
                                          onChangeCapture={handleProfileChange}
                                        />
                                      </div>
                                      {formErrors.DOBerr && <span className='error-messageedit' style={{ marginTop: "0px", marginLeft: "0px" }}>{formErrors.DOBerr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Gender: </span>
                                        <select
                                          className="effect-16"
                                          defaultValue={personal.gender}
                                          name="Gender"
                                          onChange={handleProfileChange}
                                          id="Genderinfo"
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-3px' })
                                          }}
                                        >
                                          <option hidden>{personal.gender}</option>
                                          <option value="">
                                            Select your Gender
                                          </option>
                                          <option value="Male">Male</option>
                                          <option value="Female">
                                            Female
                                          </option>
                                          <option value="Transgender">
                                            Transgender
                                          </option>
                                        </select>
                                      </div>
                                      {formErrors.Gendererr && <span className='error-messageedit' style={{ marginTop: "0px", marginLeft: "0px" }}>{formErrors.Gendererr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Status: </span>
                                        <select
                                          className="effect-16"
                                          defaultValue={
                                            personal.maritalStatus
                                          }
                                          name="MaritalStatus"
                                          onChange={handleProfileChange}
                                          id="MaritalStatusinfo"
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-3px' })
                                          }}
                                        >
                                          <option hidden>{personal.maritalStatus}</option>
                                          <option value="">
                                            Marital status
                                          </option>
                                          <option value="Single">
                                            Single
                                          </option>
                                          <option value="Married">
                                            Married
                                          </option>
                                          <option value="divorced">
                                            Divorced
                                          </option>
                                          <option value="widowed">
                                            Widowed
                                          </option>
                                        </select>
                                      </div>
                                      {formErrors.MaritalStatuserr && <span className='error-messageedit' style={{ marginTop: "0px", marginLeft: "0px" }}>{formErrors.MaritalStatuserr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Blood group: </span>
                                        <select
                                          className="effect-16"
                                          defaultValue={personal.bloodGroup}
                                          name="Bloodgroup"
                                          id="Bloodgroupinfo"
                                          // onChange={
                                          //   this.handleprofilechange
                                          // }
                                          type="text"
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-3px' })
                                          }}
                                        >
                                          <option hidden>{personal.bloodGroup}</option>
                                          <option value="">
                                            Blood Group
                                          </option>
                                          <option value="A+">A+</option>
                                          <option value="A-">A-</option>
                                          <option value="B+">B+</option>
                                          <option value="B-">B-</option>
                                          <option value="O+">O+</option>
                                          <option value="O-">O-</option>
                                          <option value="AB+">AB+</option>
                                          <option value="AB-">AB-</option>
                                        </select>
                                      </div>
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Email Address: </span>
                                        <input
                                          className='effect-16'
                                          defaultValue={personal.email}
                                          readOnly
                                        />
                                      </div>
                                      {personal.adminEmail === email && (
                                        <div className='info-row'>
                                          <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Mobile: </span>
                                          <input
                                            className='effect-16'
                                            name='HomeNumber'
                                            maxLength={10}
                                            readOnly
                                            defaultValue={personal.homeNumber}
                                          />
                                        </div>

                                      )}
                                      <div className="col-lg-12">
                                        <br />
                                        <div className="row  justify-content-center">
                                          <button
                                            type="submit"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#8e75ef",
                                              fontWeight: "bold",
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                          >
                                            Update
                                          </button>
                                          <button
                                            type="reset"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#716b6b",
                                              fontWeight: "bold",
                                              marginLeft: "2%",
                                              height: "39.5px",
                                            }}
                                            onClick={()=>{
                                              setFormErrors("");
                                            }}
                                          >
                                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      {/* Bio Edit */}
      <div>
        {isPopupOpen1 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={togglePopupClose1}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%" }}
                    >
                      Edit
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <div className='newcontainer'>
                          {personalInfoDetails.map((personal) => {
                            return (
                              <div key={personal.id} className='biodata'>
                                <form autoComplete="off" onSubmit={BioEdit}>
                                  <div className="row justify-content-center">

                                    <>
                                      <input
                                        type="hidden"
                                        name="Id"
                                        defaultValue={
                                          personal.userId
                                        }
                                      />
                                      <textarea
                                        className="effect-16"
                                        style={{
                                          width: "82%",
                                        }}
                                        type="text"
                                        defaultValue={personal.bio}
                                        id="Bio"
                                        name="Bio"

                                        placeholder="Description"
                                      />
                                    </>
                                    <div className="col-lg-12">
                                      <br />
                                      <div className="row  justify-content-center">
                                        <button
                                          type="submit"
                                          className="button-30 sk-edit"
                                          style={{
                                            color: "#8e75ef",
                                            fontWeight: "bold",
                                            height: "39.5px",
                                            width: "83.36px",
                                          }}
                                        >
                                          Update
                                        </button>
                                        <button
                                          type="reset"
                                          className="button-30 sk-edit"
                                          style={{
                                            color: "#716b6b",
                                            fontWeight: "bold",
                                            marginLeft: "2%",
                                            height: "39.5px",
                                          }}
                                        >
                                          &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      {/* Social Edit */}
      <div>
        {isPopupOpen2 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={togglePopupClose2}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%" }}
                    >
                      Social Media
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <div className='newcontainer'>
                          {personalInfoDetails.map((personal) => {
                            return (
                              <div key={personal.id} className='biodata'>
                                <form autoComplete="off" onSubmit={SocialEdit}>
                                  <div className="row justify-content-center">
                                    <div className='col-lg-12'>
                                      <div className='info-row'>
                                        <input
                                          type="hidden"
                                          name="Id"
                                          defaultValue={
                                            personal.userId
                                          }
                                        />
                                      </div>
                                      <div className='info-rowedit'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>LinkedIn: </span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          type="text"
                                          name="LinkedIn"
                                          defaultValue={personal.linkedIn}
                                          // onChange={this.handleSocailchange}
                                          placeholder="LinkedIn "
                                          id="linkedIn"
                                        />

                                      </div>
                                      <div className='info-rowedit'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Skype: </span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          type="text"
                                          name="Skype"
                                          defaultValue={personal.skype}
                                          // onChange={this.handleSocailchange}
                                          placeholder="Skype "
                                          id="skype"
                                        />

                                      </div>
                                      <div className='info-rowedit'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>WhatsApp: </span>
                                        <input
                                          className="effect-16"
                                          type="text"
                                          name="Whatsapp"
                                          onChange={handleInputChange}
                                          onFocus={(e) => e.target.select()}
                                          maxLength="10"
                                          defaultValue={
                                            personal.whatsappNumber
                                          }
                                          onKeyPress={(event) => {
                                            const isLetter = /[a-zA-Z]/i.test(event.key);
                                            if (isLetter) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onInputCapture={(event) => {
                                            if (!/^[0-9]*$/.test(event.target.value)) {
                                              event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                            }
                                          }}
                                          placeholder="-"
                                          id="Whatsapp"
                                        />

                                      </div>
                                      {formErrors.WhatsappErr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px"
                                      }}>{formErrors.WhatsappErr}</span>}
                                      <div className='info-rowedit'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>FaceBook: </span>
                                        <input
                                          className="effect-16"
                                          type="text"
                                          name="facebook"
                                          onFocus={(e) => e.target.select()}
                                          defaultValue={personal.faceBook}
                                          // onChange={this.handleSocailchange}
                                          placeholder="FaceBook"
                                          id='facebk'
                                        />

                                      </div>
                                      <div className='info-rowedit'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Twitter: </span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          type="text"
                                          // onChange={this.handleSocailchange}
                                          name="Twitter"
                                          defaultValue={personal.twitter}
                                          placeholder="Twitter"
                                          id="twitter"
                                        />

                                      </div>
                                      <div className="col-lg-12">
                                        <br />
                                        <div className="row  justify-content-center">
                                          <button
                                            type="submit"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#8e75ef",
                                              fontWeight: "bold",
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                          >
                                            Update
                                          </button>
                                          <button
                                            type="reset"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#716b6b",
                                              fontWeight: "bold",
                                              marginLeft: "2%",
                                              height: "39.5px",
                                            }}
                                          >
                                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      {/* Contact Edit */}
      <div>
        {isPopupOpen3 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={()=>{
                        togglePopupClose3();
                        setFormErrors("");
                      }}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%" }}
                    >
                      Contact
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <div className='newcontainer'>
                          {personalInfoDetails.map((personal) => {
                            return (
                              <div key={personal.id} className='biodata'>
                                <form autoComplete="off" onSubmit={ContactEdit}>
                                  <div className="row justify-content-center">
                                    <div className='col-lg-12'>
                                      <div className='info-row'>
                                        <input
                                          type="hidden"
                                          name="Id"
                                          defaultValue={
                                            personal.userId
                                          }
                                        />
                                      </div>
                                      <div className='info-rowedit'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Official Email: </span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          style={{
                                            width: "82%",
                                            pointerEvents: "none",
                                          }}
                                          type="text"
                                          id="PersonalEmail"
                                          name="Email"
                                          value={personal.email}
                                          placeholder="Personal Email"
                                        />

                                      </div>
                                      <div className='info-rowedit'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Office Number: </span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          onChange={handleInputChange}
                                          maxLength="10"
                                          style={{ width: "82%" }}
                                          type="text"
                                          id="PhoneNumber"
                                          name="PhoneNumber"
                                          onKeyPress={(event) => {
                                            const isLetter = /[a-zA-Z]/i.test(event.key);
                                            if (isLetter) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onInputCapture={(event) => {
                                            if (!/^[0-9]*$/.test(event.target.value)) {
                                              event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                            }
                                          }}
                                          defaultValue={personal.phoneNumber}
                                          placeholder="Office Number "
                                        />

                                      </div>
                                      {formErrors.contactphnerr && <span className="error-messageedit" style={{
                                        float: "left",
                                      }}>{formErrors.contactphnerr}</span>}
                                      <div className='info-rowedit'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Phone Number: </span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          onChange={handleInputChange}
                                          maxLength="10"
                                          style={{ width: "82%" }}
                                          type="text"
                                          id="HomeNumber"
                                          name="HomeNumber"
                                          onKeyPress={(event) => {
                                            const isLetter = /[a-zA-Z]/i.test(event.key);
                                            if (isLetter) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onInputCapture={(event) => {
                                            if (!/^[0-9]*$/.test(event.target.value)) {
                                              event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                            }
                                          }}
                                          defaultValue={personal.homeNumber}
                                          placeholder="Phone Number "
                                        />

                                      </div>
                                      {formErrors.HomeErr && <span className="error-messageedit" style={{
                                        float: "left",
                                      }}>{formErrors.HomeErr}</span>}
                                      <div className='info-rowedit'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Personal Email: </span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          onChange={handleInputChange}
                                          style={{ width: "82%" }}
                                          type="text"
                                          id="OfficialEmail"
                                          name="OfficialEmail"
                                          defaultValue={
                                            personal.officialEmail
                                          }
                                          placeholder="-"
                                        />

                                      </div>
                                      {formErrors.officialEmailerr && <span className="error-messageedit" style={{
                                        float: "left",
                                      }}>{formErrors.officialEmailerr}</span>}
                                      <div className='info-rowedit'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Alternate Phone Number: </span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          onChange={handleInputChange}
                                          maxLength="10"
                                          style={{ width: "82%" }}
                                          type="text"
                                          id="AlternatePhoneNo"
                                          onKeyPress={(event) => {
                                            const isLetter = /[a-zA-Z]/i.test(event.key);
                                            if (isLetter) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onInputCapture={(event) => {
                                            if (!/^[0-9]*$/.test(event.target.value)) {
                                              event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                            }
                                          }}
                                          defaultValue={
                                            personal.alternatePhoneNo
                                          }
                                          name="AlternatePhoneNo"

                                          placeholder="-"
                                        />

                                      </div>
                                      {formErrors.AlternatePhoneNoerr && <span className="error-messageedit" style={{
                                        float: "left",
                                      }}>{formErrors.AlternatePhoneNoerr}</span>}
                                      <div className="col-lg-12">
                                        <br />
                                        <div className="row  justify-content-center">
                                          <button
                                            type="submit"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#8e75ef",
                                              fontWeight: "bold",
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                          >
                                            Update
                                          </button>
                                          <button
                                            type="reset"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#716b6b",
                                              fontWeight: "bold",
                                              marginLeft: "2%",
                                              height: "39.5px",
                                            }}
                                            onClick={()=>{
                                              setFormErrors("");
                                            }}
                                          >
                                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      {/* Address Edit */}
      <div>
        {isPopupOpen4 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={()=>{
                        togglePopupClose4();
                        setFormErrors("")
                      }}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%" }}
                    >
                      Address
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <div className='newcontainer'>
                          {personalInfoDetails.map((personal) => {
                            return (
                              <div key={personal.id} className='biodata'>
                                <form autoComplete="off" onSubmit={AddressEdit}>
                                  <div className="row justify-content-center">
                                    <div className='col-lg-12'>
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>CURRENT ADDRESS:</span>
                                      </div>
                                      <div className='info-row'>
                                        <input
                                          type="hidden"
                                          name="Id"
                                          defaultValue={
                                            personal.userId
                                          }
                                        />
                                      </div>
                                      <div className='info-row'>
                                        <input
                                          type="hidden"
                                          defaultValue={
                                            personal.currentAddress
                                          }
                                          name="CurrentAddress"
                                          id="CurrentAddress"
                                        />
                                      </div>
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>CITY:</span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          onChange={handleInputChange}
                                          onChangeCapture={handleAddressChange1}
                                          onInputCapture={(event) => {
                                            if (
                                              !/[a-zA-Z\s ]+/.test(
                                                event.key
                                              )
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          type="text"
                                          id="CurrentAddress_City"
                                          name="CurrentAddress_City"
                                          defaultValue={
                                            personal.currentAddress_City
                                          }
                                          placeholder="City "
                                        />
                                      </div>
                                      {formErrors.CurrentAddress_Cityerr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.CurrentAddress_Cityerr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>COUNTRY:</span>
                                        <select
                                          className="effect-16"
                                          id="CurrentAddress_Country"
                                          name="CurrentAddress_Country"
                                          defaultValue={
                                            personal.currentAddress_Country
                                          }
                                          data-default-value={personal.currentAddress_Country || ""}
                                          type="text"

                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-3px' })
                                          }}
                                          onChange={
                                            handleAddresschange
                                          }
                                          onChangeCapture={handleAddressChange1}
                                        >
                                          <option hidden>{personal.currentAddress_Country}</option>
                                          <option value="">Select</option>
                                          <option value="Afghanistan">
                                            Afghanistan
                                          </option>
                                          <option value="Åland Islands">
                                            Åland Islands
                                          </option>
                                          <option value="Albania">
                                            Albania
                                          </option>
                                          <option value="Algeria">
                                            Algeria
                                          </option>
                                          <option value="American Samoa">
                                            American Samoa
                                          </option>
                                          <option value="Andorra">
                                            Andorra
                                          </option>
                                          <option value="Angola">
                                            Angola
                                          </option>
                                          <option value="Anguilla">
                                            Anguilla
                                          </option>
                                          <option value="Antarctica">
                                            Antarctica
                                          </option>
                                          <option value="Antigua and Barbuda">
                                            Antigua and Barbuda
                                          </option>
                                          <option value="Argentina">
                                            Argentina
                                          </option>
                                          <option value="Armenia">
                                            Armenia
                                          </option>
                                          <option value="Aruba">
                                            Aruba
                                          </option>
                                          <option value="Australia">
                                            Australia
                                          </option>
                                          <option value="Austria">
                                            Austria
                                          </option>
                                          <option value="Azerbaijan">
                                            Azerbaijan
                                          </option>
                                          <option value="Bahamas">
                                            Bahamas
                                          </option>
                                          <option value="Bahrain">
                                            Bahrain
                                          </option>
                                          <option value="Bangladesh">
                                            Bangladesh
                                          </option>
                                          <option value="Barbados">
                                            Barbados
                                          </option>
                                          <option value="Belarus">
                                            Belarus
                                          </option>
                                          <option value="Belgium">
                                            Belgium
                                          </option>
                                          <option value="Belize">
                                            Belize
                                          </option>
                                          <option value="Benin">
                                            Benin
                                          </option>
                                          <option value="Bermuda">
                                            Bermuda
                                          </option>
                                          <option value="Bhutan">
                                            Bhutan
                                          </option>
                                          <option value="Bolivia">
                                            Bolivia
                                          </option>
                                          <option value="Bosnia and Herzegovina">
                                            Bosnia and Herzegovina
                                          </option>
                                          <option value="Botswana">
                                            Botswana
                                          </option>
                                          <option value="Bouvet Island">
                                            Bouvet Island
                                          </option>
                                          <option value="Brazil">
                                            Brazil
                                          </option>
                                          <option value="British Indian Ocean Territory">
                                            British Indian Ocean Territory
                                          </option>
                                          <option value="Brunei Darussalam">
                                            Brunei Darussalam
                                          </option>
                                          <option value="Bulgaria">
                                            Bulgaria
                                          </option>
                                          <option value="Burkina Faso">
                                            Burkina Faso
                                          </option>
                                          <option value="Burundi">
                                            Burundi
                                          </option>
                                          <option value="Cambodia">
                                            Cambodia
                                          </option>
                                          <option value="Cameroon">
                                            Cameroon
                                          </option>
                                          <option value="Canada">
                                            Canada
                                          </option>
                                          <option value="Cape Verde">
                                            Cape Verde
                                          </option>
                                          <option value="Cayman Islands">
                                            Cayman Islands
                                          </option>
                                          <option value="Central African Republic">
                                            Central African Republic
                                          </option>
                                          <option value="Chad">Chad</option>
                                          <option value="Chile">
                                            Chile
                                          </option>
                                          <option value="China">
                                            China
                                          </option>
                                          <option value="Christmas Island">
                                            Christmas Island
                                          </option>
                                          <option value="Cocos (Keeling) Islands">
                                            Cocos (Keeling) Islands
                                          </option>
                                          <option value="Colombia">
                                            Colombia
                                          </option>
                                          <option value="Comoros">
                                            Comoros
                                          </option>
                                          <option value="Congo">
                                            Congo
                                          </option>
                                          <option value="Congo, The Democratic Republic of The">
                                            Congo, The Democratic Republic
                                            of The
                                          </option>
                                          <option value="Cook Islands">
                                            Cook Islands
                                          </option>
                                          <option value="Costa Rica">
                                            Costa Rica
                                          </option>
                                          <option value="Cote D'ivoire">
                                            Cote D'ivoire
                                          </option>
                                          <option value="Croatia">
                                            Croatia
                                          </option>
                                          <option value="Cuba">Cuba</option>
                                          <option value="Cyprus">
                                            Cyprus
                                          </option>
                                          <option value="Czech Republic">
                                            Czech Republic
                                          </option>
                                          <option value="Denmark">
                                            Denmark
                                          </option>
                                          <option value="Djibouti">
                                            Djibouti
                                          </option>
                                          <option value="Dominica">
                                            Dominica
                                          </option>
                                          <option value="Dominican Republic">
                                            Dominican Republic
                                          </option>
                                          <option value="Ecuador">
                                            Ecuador
                                          </option>
                                          <option value="Egypt">
                                            Egypt
                                          </option>
                                          <option value="El Salvador">
                                            El Salvador
                                          </option>
                                          <option value="Equatorial Guinea">
                                            Equatorial Guinea
                                          </option>
                                          <option value="Eritrea">
                                            Eritrea
                                          </option>
                                          <option value="Estonia">
                                            Estonia
                                          </option>
                                          <option value="Ethiopia">
                                            Ethiopia
                                          </option>
                                          <option value="Falkland Islands (Malvinas)">
                                            Falkland Islands (Malvinas)
                                          </option>
                                          <option value="Faroe Islands">
                                            Faroe Islands
                                          </option>
                                          <option value="Fiji">Fiji</option>
                                          <option value="Finland">
                                            Finland
                                          </option>
                                          <option value="France">
                                            France
                                          </option>
                                          <option value="French Guiana">
                                            French Guiana
                                          </option>
                                          <option value="French Polynesia">
                                            French Polynesia
                                          </option>
                                          <option value="French Southern Territories">
                                            French Southern Territories
                                          </option>
                                          <option value="Gabon">
                                            Gabon
                                          </option>
                                          <option value="Gambia">
                                            Gambia
                                          </option>
                                          <option value="Georgia">
                                            Georgia
                                          </option>
                                          <option value="Germany">
                                            Germany
                                          </option>
                                          <option value="Ghana">
                                            Ghana
                                          </option>
                                          <option value="Gibraltar">
                                            Gibraltar
                                          </option>
                                          <option value="Greece">
                                            Greece
                                          </option>
                                          <option value="Greenland">
                                            Greenland
                                          </option>
                                          <option value="Grenada">
                                            Grenada
                                          </option>
                                          <option value="Guadeloupe">
                                            Guadeloupe
                                          </option>
                                          <option value="Guam">Guam</option>
                                          <option value="Guatemala">
                                            Guatemala
                                          </option>
                                          <option value="Guernsey">
                                            Guernsey
                                          </option>
                                          <option value="Guinea">
                                            Guinea
                                          </option>
                                          <option value="Guinea-bissau">
                                            Guinea-bissau
                                          </option>
                                          <option value="Guyana">
                                            Guyana
                                          </option>
                                          <option value="Haiti">
                                            Haiti
                                          </option>
                                          <option value="Heard Island and Mcdonald Islands">
                                            Heard Island and Mcdonald
                                            Islands
                                          </option>
                                          <option value="Holy See (Vatican City State)">
                                            Holy See (Vatican City State)
                                          </option>
                                          <option value="Honduras">
                                            Honduras
                                          </option>
                                          <option value="Hong Kong">
                                            Hong Kong
                                          </option>
                                          <option value="Hungary">
                                            Hungary
                                          </option>
                                          <option value="Iceland">
                                            Iceland
                                          </option>
                                          <option value="India">
                                            India
                                          </option>
                                          <option value="Indonesia">
                                            Indonesia
                                          </option>
                                          <option value="Iran, Islamic Republic of">
                                            Iran, Islamic Republic of
                                          </option>
                                          <option value="Iraq">Iraq</option>
                                          <option value="Ireland">
                                            Ireland
                                          </option>
                                          <option value="Isle of Man">
                                            Isle of Man
                                          </option>
                                          <option value="Israel">
                                            Israel
                                          </option>
                                          <option value="Italy">
                                            Italy
                                          </option>
                                          <option value="Jamaica">
                                            Jamaica
                                          </option>
                                          <option value="Japan">
                                            Japan
                                          </option>
                                          <option value="Jersey">
                                            Jersey
                                          </option>
                                          <option value="Jordan">
                                            Jordan
                                          </option>
                                          <option value="Kazakhstan">
                                            Kazakhstan
                                          </option>
                                          <option value="Kenya">
                                            Kenya
                                          </option>
                                          <option value="Kiribati">
                                            Kiribati
                                          </option>
                                          <option value="Korea, Democratic People's Republic of">
                                            Korea, Democratic People's
                                            Republic of
                                          </option>
                                          <option value="Korea, Republic of">
                                            Korea, Republic of
                                          </option>
                                          <option value="Kuwait">
                                            Kuwait
                                          </option>
                                          <option value="Kyrgyzstan">
                                            Kyrgyzstan
                                          </option>
                                          <option value="Lao People's Democratic Republic">
                                            Lao People's Democratic Republic
                                          </option>
                                          <option value="Latvia">
                                            Latvia
                                          </option>
                                          <option value="Lebanon">
                                            Lebanon
                                          </option>
                                          <option value="Lesotho">
                                            Lesotho
                                          </option>
                                          <option value="Liberia">
                                            Liberia
                                          </option>
                                          <option value="Libyan Arab Jamahiriya">
                                            Libyan Arab Jamahiriya
                                          </option>
                                          <option value="Liechtenstein">
                                            Liechtenstein
                                          </option>
                                          <option value="Lithuania">
                                            Lithuania
                                          </option>
                                          <option value="Luxembourg">
                                            Luxembourg
                                          </option>
                                          <option value="Macao">
                                            Macao
                                          </option>
                                          <option value="Macedonia, The Former Yugoslav Republic of">
                                            Macedonia, The Former Yugoslav
                                            Republic of
                                          </option>
                                          <option value="Madagascar">
                                            Madagascar
                                          </option>
                                          <option value="Malawi">
                                            Malawi
                                          </option>
                                          <option value="Malaysia">
                                            Malaysia
                                          </option>
                                          <option value="Maldives">
                                            Maldives
                                          </option>
                                          <option value="Mali">Mali</option>
                                          <option value="Malta">
                                            Malta
                                          </option>
                                          <option value="Marshall Islands">
                                            Marshall Islands
                                          </option>
                                          <option value="Martinique">
                                            Martinique
                                          </option>
                                          <option value="Mauritania">
                                            Mauritania
                                          </option>
                                          <option value="Mauritius">
                                            Mauritius
                                          </option>
                                          <option value="Mayotte">
                                            Mayotte
                                          </option>
                                          <option value="Mexico">
                                            Mexico
                                          </option>
                                          <option value="Micronesia, Federated States of">
                                            Micronesia, Federated States of
                                          </option>
                                          <option value="Moldova, Republic of">
                                            Moldova, Republic of
                                          </option>
                                          <option value="Monaco">
                                            Monaco
                                          </option>
                                          <option value="Mongolia">
                                            Mongolia
                                          </option>
                                          <option value="Montenegro">
                                            Montenegro
                                          </option>
                                          <option value="Montserrat">
                                            Montserrat
                                          </option>
                                          <option value="Morocco">
                                            Morocco
                                          </option>
                                          <option value="Mozambique">
                                            Mozambique
                                          </option>
                                          <option value="Myanmar">
                                            Myanmar
                                          </option>
                                          <option value="Namibia">
                                            Namibia
                                          </option>
                                          <option value="Nauru">
                                            Nauru
                                          </option>
                                          <option value="Nepal">
                                            Nepal
                                          </option>
                                          <option value="Netherlands">
                                            Netherlands
                                          </option>
                                          <option value="Netherlands Antilles">
                                            Netherlands Antilles
                                          </option>
                                          <option value="New Caledonia">
                                            New Caledonia
                                          </option>
                                          <option value="New Zealand">
                                            New Zealand
                                          </option>
                                          <option value="Nicaragua">
                                            Nicaragua
                                          </option>
                                          <option value="Niger">
                                            Niger
                                          </option>
                                          <option value="Nigeria">
                                            Nigeria
                                          </option>
                                          <option value="Niue">Niue</option>
                                          <option value="Norfolk Island">
                                            Norfolk Island
                                          </option>
                                          <option value="Northern Mariana Islands">
                                            Northern Mariana Islands
                                          </option>
                                          <option value="Norway">
                                            Norway
                                          </option>
                                          <option value="Oman">Oman</option>
                                          <option value="Pakistan">
                                            Pakistan
                                          </option>
                                          <option value="Palau">
                                            Palau
                                          </option>
                                          <option value="Palestinian Territory, Occupied">
                                            Palestinian Territory, Occupied
                                          </option>
                                          <option value="Panama">
                                            Panama
                                          </option>
                                          <option value="Papua New Guinea">
                                            Papua New Guinea
                                          </option>
                                          <option value="Paraguay">
                                            Paraguay
                                          </option>
                                          <option value="Peru">Peru</option>
                                          <option value="Philippines">
                                            Philippines
                                          </option>
                                          <option value="Pitcairn">
                                            Pitcairn
                                          </option>
                                          <option value="Poland">
                                            Poland
                                          </option>
                                          <option value="Portugal">
                                            Portugal
                                          </option>
                                          <option value="Puerto Rico">
                                            Puerto Rico
                                          </option>
                                          <option value="Qatar">
                                            Qatar
                                          </option>
                                          <option value="Reunion">
                                            Reunion
                                          </option>
                                          <option value="Romania">
                                            Romania
                                          </option>
                                          <option value="Russian Federation">
                                            Russian Federation
                                          </option>
                                          <option value="Rwanda">
                                            Rwanda
                                          </option>
                                          <option value="Saint Helena">
                                            Saint Helena
                                          </option>
                                          <option value="Saint Kitts and Nevis">
                                            Saint Kitts and Nevis
                                          </option>
                                          <option value="Saint Lucia">
                                            Saint Lucia
                                          </option>
                                          <option value="Saint Pierre and Miquelon">
                                            Saint Pierre and Miquelon
                                          </option>
                                          <option value="Saint Vincent and The Grenadines">
                                            Saint Vincent and The Grenadines
                                          </option>
                                          <option value="Samoa">
                                            Samoa
                                          </option>
                                          <option value="San Marino">
                                            San Marino
                                          </option>
                                          <option value="Sao Tome and Principe">
                                            Sao Tome and Principe
                                          </option>
                                          <option value="Saudi Arabia">
                                            Saudi Arabia
                                          </option>
                                          <option value="Senegal">
                                            Senegal
                                          </option>
                                          <option value="Serbia">
                                            Serbia
                                          </option>
                                          <option value="Seychelles">
                                            Seychelles
                                          </option>
                                          <option value="Sierra Leone">
                                            Sierra Leone
                                          </option>
                                          <option value="Singapore">
                                            Singapore
                                          </option>
                                          <option value="Slovakia">
                                            Slovakia
                                          </option>
                                          <option value="Slovenia">
                                            Slovenia
                                          </option>
                                          <option value="Solomon Islands">
                                            Solomon Islands
                                          </option>
                                          <option value="Somalia">
                                            Somalia
                                          </option>
                                          <option value="South Africa">
                                            South Africa
                                          </option>
                                          <option value="South Georgia and The South Sandwich Islands">
                                            South Georgia and The South
                                            Sandwich Islands
                                          </option>
                                          <option value="Spain">
                                            Spain
                                          </option>
                                          <option value="Sri Lanka">
                                            Sri Lanka
                                          </option>
                                          <option value="Sudan">
                                            Sudan
                                          </option>
                                          <option value="Suriname">
                                            Suriname
                                          </option>
                                          <option value="Svalbard and Jan Mayen">
                                            Svalbard and Jan Mayen
                                          </option>
                                          <option value="Swaziland">
                                            Swaziland
                                          </option>
                                          <option value="Sweden">
                                            Sweden
                                          </option>
                                          <option value="Switzerland">
                                            Switzerland
                                          </option>
                                          <option value="Syrian Arab Republic">
                                            Syrian Arab Republic
                                          </option>
                                          <option value="Taiwan, Province of China">
                                            Taiwan, Province of China
                                          </option>
                                          <option value="Tajikistan">
                                            Tajikistan
                                          </option>
                                          <option value="Tanzania, United Republic of">
                                            Tanzania, United Republic of
                                          </option>
                                          <option value="Thailand">
                                            Thailand
                                          </option>
                                          <option value="Timor-leste">
                                            Timor-leste
                                          </option>
                                          <option value="Togo">Togo</option>
                                          <option value="Tokelau">
                                            Tokelau
                                          </option>
                                          <option value="Tonga">
                                            Tonga
                                          </option>
                                          <option value="Trinidad and Tobago">
                                            Trinidad and Tobago
                                          </option>
                                          <option value="Tunisia">
                                            Tunisia
                                          </option>
                                          <option value="Turkey">
                                            Turkey
                                          </option>
                                          <option value="Turkmenistan">
                                            Turkmenistan
                                          </option>
                                          <option value="Turks and Caicos Islands">
                                            Turks and Caicos Islands
                                          </option>
                                          <option value="Tuvalu">
                                            Tuvalu
                                          </option>
                                          <option value="Uganda">
                                            Uganda
                                          </option>
                                          <option value="Ukraine">
                                            Ukraine
                                          </option>
                                          <option value="United Arab Emirates">
                                            United Arab Emirates
                                          </option>
                                          <option value="United Kingdom">
                                            United Kingdom
                                          </option>
                                          <option value="United States">
                                            United States
                                          </option>
                                          <option value="United States Minor Outlying Islands">
                                            United States Minor Outlying
                                            Islands
                                          </option>
                                          <option value="Uruguay">
                                            Uruguay
                                          </option>
                                          <option value="Uzbekistan">
                                            Uzbekistan
                                          </option>
                                          <option value="Vanuatu">
                                            Vanuatu
                                          </option>
                                          <option value="Venezuela">
                                            Venezuela
                                          </option>
                                          <option value="Viet Nam">
                                            Viet Nam
                                          </option>
                                          <option value="Virgin Islands, British">
                                            Virgin Islands, British
                                          </option>
                                          <option value="Virgin Islands, U.S.">
                                            Virgin Islands, U.S.
                                          </option>
                                          <option value="Wallis and Futuna">
                                            Wallis and Futuna
                                          </option>
                                          <option value="Western Sahara">
                                            Western Sahara
                                          </option>
                                          <option value="Yemen">
                                            Yemen
                                          </option>
                                          <option value="Zambia">
                                            Zambia
                                          </option>
                                          <option value="Zimbabwe">
                                            Zimbabwe
                                          </option>
                                        </select>
                                      </div>
                                      {formErrors.CurrentAddress_Countryerr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.CurrentAddress_Countryerr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>STATE:</span>
                                        {isSelected ? (<select
                                          id="CurrentAddress_State"
                                          name="CurrentAddress_State"
                                          className="effect-16"
                                          defaultValue={
                                            personal.currentAddress_State
                                          }
                                          data-default-value={personal.currentAddress_State || ""}
                                          onKeyPress={(event) => {
                                            if (
                                              !/[a-zA-Z\s ]+/.test(
                                                event.key
                                              )
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-3px' })
                                          }}
                                          onChange={handleAddressChange1}
                                        >
                                          <option hidden>{personal.currentAddress_State}</option>
                                          <option value="">
                                            Select state
                                          </option>
                                          <option value="Andaman and Nicobar Islands">
                                            Andaman and Nicobar Islands
                                          </option>
                                          <option value="Andhra Pradesh">
                                            Andhra Pradesh
                                          </option>
                                          <option value="Arunachal Pradesh">
                                            Arunachal Pradesh
                                          </option>
                                          <option value="Assam">
                                            Assam
                                          </option>
                                          <option value="Bihar">
                                            Bihar
                                          </option>
                                          <option value="Chandigarh">
                                            Chandigarh
                                          </option>
                                          <option value="Chhattisgarh">
                                            Chhattisgarh
                                          </option>
                                          <option value="Dadra and Nagar Haveli">
                                            Dadra and Nagar Haveli
                                          </option>
                                          <option value="Daman and Diu">
                                            Daman and Diu
                                          </option>
                                          <option value="Delhi">
                                            Delhi
                                          </option>
                                          <option value="Goa">
                                            Goa
                                          </option>
                                          <option value="Gujarat">
                                            Gujarat
                                          </option>
                                          <option value="Haryana">
                                            Haryana
                                          </option>
                                          <option value="Himachal Pradesh">
                                            Himachal Pradesh
                                          </option>
                                          <option value="Jammu and Kashmir">
                                            Jammu and Kashmir
                                          </option>
                                          <option value="Jharkhand">
                                            Jharkhand
                                          </option>
                                          <option value="Karnataka">
                                            Karnataka
                                          </option>
                                          <option value="Kerala">
                                            Kerala
                                          </option>
                                          <option value="Ladakh">
                                            Ladakh
                                          </option>
                                          <option value="Lakshadweep">
                                            Lakshadweep
                                          </option>
                                          <option value="Madhya Pradesh">
                                            Madhya Pradesh
                                          </option>
                                          <option value="Maharashtra">
                                            Maharashtra
                                          </option>
                                          <option value="Manipur">
                                            Manipur
                                          </option>
                                          <option value="Meghalaya">
                                            Meghalaya
                                          </option>
                                          <option value="Mizoram">
                                            Mizoram
                                          </option>
                                          <option value="Nagaland">
                                            Nagaland
                                          </option>
                                          <option value="Odisha">
                                            Odisha
                                          </option>
                                          <option value="Puducherry">
                                            Puducherry
                                          </option>
                                          <option value="Punjab">
                                            Punjab
                                          </option>
                                          <option value="Rajasthan">
                                            Rajasthan
                                          </option>
                                          <option value="Sikkim">
                                            Sikkim
                                          </option>
                                          <option value="Tamil Nadu">
                                            Tamil Nadu
                                          </option>
                                          <option value="Telangana">
                                            Telangana
                                          </option>
                                          <option value="Tripura">
                                            Tripura
                                          </option>
                                          <option value="Uttar Pradesh">
                                            Uttar Pradesh
                                          </option>
                                          <option value="Uttarakhand">
                                            Uttarakhand
                                          </option>
                                          <option value="West Bengal">
                                            West Bengal
                                          </option>
                                        </select>
                                        ) : (
                                          <input
                                            className="effect-16"
                                            onFocus={(e) =>
                                              e.target.select()
                                            }
                                            onInputCapture={(event) => {
                                              if (
                                                !/[a-zA-Z\s ]+/.test(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                            onChange={handleAddressChange1}
                                            type="text"
                                            id="CurrentAddress_State"
                                            name="CurrentAddress_State"
                                            defaultValue={
                                              personal.currentAddress_State
                                            }
                                            placeholder="State "
                                          // onChange={
                                          //   handleAddresschange
                                          // }
                                          />)}
                                      </div>
                                      {formErrors.CurrentAddress_Stateerr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.CurrentAddress_Stateerr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>PIN CODE: </span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          type="text"
                                          id="CurrentAddress_Pincode"
                                          name="CurrentAddress_Pincode"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={handleAddressChange1}
                                          maxLength="6"
                                          defaultValue={
                                            personal.currentAddress_Pincode
                                          }
                                          placeholder="Pincode "
                                          onInputCapture={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}

                                        />
                                      </div>
                                      {formErrors.CurrentAddress_Pincodeerr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.CurrentAddress_Pincodeerr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>HOUSE TYPE: </span>
                                        <select
                                          className="effect-16"
                                          defaultValue={personal.houseType}
                                          onInputCapture={HandleHouse}
                                          data-default-value={personal.houseType || ""}
                                          id="HouseType"
                                          name="HouseType"
                                          onChange={handleAddressChange1}
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-3px' })
                                          }}
                                        >
                                          <option value={personal.houseType} hidden>{personal.houseType}</option>
                                          <option value="">Select</option>

                                          {HouseTypeDrop.map(
                                            (HouseTypeDrop) => (
                                              <option
                                                value={
                                                  HouseTypeDrop.housetype
                                                }
                                                selected={
                                                  HouseTypeDrop.housetype ===
                                                  personal.houseType
                                                }
                                              >
                                                {HouseTypeDrop.housetype}
                                              </option>
                                            )
                                          )}
                                          <option value="others">
                                            Others
                                          </option>
                                        </select>
                                      </div>
                                      <div className='info-row'>
                                      <span  className='effect-16' style={{ borderBottom: "none", textAlign: "left",visibility:'hidden' }}></span>
                                        <input
                                            type="hidden"
                                            Name="otherType"
                                            placeholder="House Type"
                                            className="effect-16"
                                            id="HouseText"
                                            maxLength={30}
                                          // onChange={this.handleAddresschange}
                                          />
                                          </div>
                                      {formErrors.HouseTypeerr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.HouseTypeerr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>STAYING SINCE:</span>
                                        <input
                                          className="effect-16"
                                          type="date"
                                          name="StayingSince"
                                          max={new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth(),
                                            new Date().getDate()
                                          )
                                            .toJSON()
                                            .slice(0, 10)}
                                          defaultValue={reformatDate(
                                            personal.stayingSince
                                          )}
                                          id="StayingSince"
                                          placeholder="Staying Since"
                                          onChange={handleInputChange}
                                          onChangeCapture={handleAddressChange1}
                                        />
                                      </div>
                                      {formErrors.StayingSinceerr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.StayingSinceerr}</span>}
                                      <br />
                                      <div className='info-row' >
                                        <div
                                          className="checkbox-fade fade-in-primary"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <label>
                                            <input
                                              type="checkbox"
                                              onClick={handlecheckbox}
                                              onChange={
                                                handleTextareaClick
                                              }
                                              defaultChecked = {(personal.currentAddress_City !== "-" && personal.currentAddress_City === personal.permanentAddress_City) && personal.currentAddress_Country === personal.permanentAddress_Country
                                              && personal.currentAddress_State === personal.permanentAddress_State && personal.currentAddress_Pincode === personal.permanentAddress_Pincode}
                                              name="SameAddress"
                                              id="SameAddress"
                                            />
                                            <span className="cr">
                                              <i className="cr-icon icofont icofont-ui-check txt-primary" />
                                            </span>{" "}
                                            <span>
                                              Permanent address is the same as
                                              current address
                                            </span>
                                          </label>
                                        </div>

                                      </div>
                                      <div className='info-row' >
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>PERMANENT ADDRESS: </span>

                                      </div>
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>CITY:</span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          onInputCapture={(event) => {
                                            if (
                                              !/[a-zA-Z\s ]+/.test(
                                                event.key
                                              )
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={handleAddressChange1}
                                          type="text"
                                          readOnly={isReadOnly}
                                          id="PermanentAddress_City"
                                          name="PermanentAddress_City"
                                          defaultValue={
                                            personal.permanentAddress_City
                                          }
                                        />
                                      </div>
                                      {formErrors.PermanentAddress_Cityerr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.PermanentAddress_Cityerr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>COUNTRY:</span>
                                        <select
                                          className="effect-16"
                                          id="PermanentAddress_Country"
                                          // disabled={this.state.isReadOnly}
                                          name="PermanentAddress_Country"
                                          readOnly={isReadOnly}
                                          defaultValue={
                                            personal.permanentAddress_Country
                                          }
                                          data-default-value={personal.permanentAddress_Country || ""}
                                          onChange={
                                            handlePermanentAddress
                                          }
                                          onChangeCapture={handleAddressChange1}
                                          type="text"
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-3px' })
                                          }}
                                        >
                                          <option hidden>{personal.permanentAddress_Country}</option>
                                          <option value="">Select</option>
                                          <option value="Afghanistan">
                                            Afghanistan
                                          </option>
                                          <option value="Åland Islands">
                                            Åland Islands
                                          </option>
                                          <option value="Albania">
                                            Albania
                                          </option>
                                          <option value="Algeria">
                                            Algeria
                                          </option>
                                          <option value="American Samoa">
                                            American Samoa
                                          </option>
                                          <option value="Andorra">
                                            Andorra
                                          </option>
                                          <option value="Angola">
                                            Angola
                                          </option>
                                          <option value="Anguilla">
                                            Anguilla
                                          </option>
                                          <option value="Antarctica">
                                            Antarctica
                                          </option>
                                          <option value="Antigua and Barbuda">
                                            Antigua and Barbuda
                                          </option>
                                          <option value="Argentina">
                                            Argentina
                                          </option>
                                          <option value="Armenia">
                                            Armenia
                                          </option>
                                          <option value="Aruba">
                                            Aruba
                                          </option>
                                          <option value="Australia">
                                            Australia
                                          </option>
                                          <option value="Austria">
                                            Austria
                                          </option>
                                          <option value="Azerbaijan">
                                            Azerbaijan
                                          </option>
                                          <option value="Bahamas">
                                            Bahamas
                                          </option>
                                          <option value="Bahrain">
                                            Bahrain
                                          </option>
                                          <option value="Bangladesh">
                                            Bangladesh
                                          </option>
                                          <option value="Barbados">
                                            Barbados
                                          </option>
                                          <option value="Belarus">
                                            Belarus
                                          </option>
                                          <option value="Belgium">
                                            Belgium
                                          </option>
                                          <option value="Belize">
                                            Belize
                                          </option>
                                          <option value="Benin">
                                            Benin
                                          </option>
                                          <option value="Bermuda">
                                            Bermuda
                                          </option>
                                          <option value="Bhutan">
                                            Bhutan
                                          </option>
                                          <option value="Bolivia">
                                            Bolivia
                                          </option>
                                          <option value="Bosnia and Herzegovina">
                                            Bosnia and Herzegovina
                                          </option>
                                          <option value="Botswana">
                                            Botswana
                                          </option>
                                          <option value="Bouvet Island">
                                            Bouvet Island
                                          </option>
                                          <option value="Brazil">
                                            Brazil
                                          </option>
                                          <option value="British Indian Ocean Territory">
                                            British Indian Ocean Territory
                                          </option>
                                          <option value="Brunei Darussalam">
                                            Brunei Darussalam
                                          </option>
                                          <option value="Bulgaria">
                                            Bulgaria
                                          </option>
                                          <option value="Burkina Faso">
                                            Burkina Faso
                                          </option>
                                          <option value="Burundi">
                                            Burundi
                                          </option>
                                          <option value="Cambodia">
                                            Cambodia
                                          </option>
                                          <option value="Cameroon">
                                            Cameroon
                                          </option>
                                          <option value="Canada">
                                            Canada
                                          </option>
                                          <option value="Cape Verde">
                                            Cape Verde
                                          </option>
                                          <option value="Cayman Islands">
                                            Cayman Islands
                                          </option>
                                          <option value="Central African Republic">
                                            Central African Republic
                                          </option>
                                          <option value="Chad">Chad</option>
                                          <option value="Chile">
                                            Chile
                                          </option>
                                          <option value="China">
                                            China
                                          </option>
                                          <option value="Christmas Island">
                                            Christmas Island
                                          </option>
                                          <option value="Cocos (Keeling) Islands">
                                            Cocos (Keeling) Islands
                                          </option>
                                          <option value="Colombia">
                                            Colombia
                                          </option>
                                          <option value="Comoros">
                                            Comoros
                                          </option>
                                          <option value="Congo">
                                            Congo
                                          </option>
                                          <option value="Congo, The Democratic Republic of The">
                                            Congo, The Democratic Republic
                                            of The
                                          </option>
                                          <option value="Cook Islands">
                                            Cook Islands
                                          </option>
                                          <option value="Costa Rica">
                                            Costa Rica
                                          </option>
                                          <option value="Cote D'ivoire">
                                            Cote D'ivoire
                                          </option>
                                          <option value="Croatia">
                                            Croatia
                                          </option>
                                          <option value="Cuba">Cuba</option>
                                          <option value="Cyprus">
                                            Cyprus
                                          </option>
                                          <option value="Czech Republic">
                                            Czech Republic
                                          </option>
                                          <option value="Denmark">
                                            Denmark
                                          </option>
                                          <option value="Djibouti">
                                            Djibouti
                                          </option>
                                          <option value="Dominica">
                                            Dominica
                                          </option>
                                          <option value="Dominican Republic">
                                            Dominican Republic
                                          </option>
                                          <option value="Ecuador">
                                            Ecuador
                                          </option>
                                          <option value="Egypt">
                                            Egypt
                                          </option>
                                          <option value="El Salvador">
                                            El Salvador
                                          </option>
                                          <option value="Equatorial Guinea">
                                            Equatorial Guinea
                                          </option>
                                          <option value="Eritrea">
                                            Eritrea
                                          </option>
                                          <option value="Estonia">
                                            Estonia
                                          </option>
                                          <option value="Ethiopia">
                                            Ethiopia
                                          </option>
                                          <option value="Falkland Islands (Malvinas)">
                                            Falkland Islands (Malvinas)
                                          </option>
                                          <option value="Faroe Islands">
                                            Faroe Islands
                                          </option>
                                          <option value="Fiji">Fiji</option>
                                          <option value="Finland">
                                            Finland
                                          </option>
                                          <option value="France">
                                            France
                                          </option>
                                          <option value="French Guiana">
                                            French Guiana
                                          </option>
                                          <option value="French Polynesia">
                                            French Polynesia
                                          </option>
                                          <option value="French Southern Territories">
                                            French Southern Territories
                                          </option>
                                          <option value="Gabon">
                                            Gabon
                                          </option>
                                          <option value="Gambia">
                                            Gambia
                                          </option>
                                          <option value="Georgia">
                                            Georgia
                                          </option>
                                          <option value="Germany">
                                            Germany
                                          </option>
                                          <option value="Ghana">
                                            Ghana
                                          </option>
                                          <option value="Gibraltar">
                                            Gibraltar
                                          </option>
                                          <option value="Greece">
                                            Greece
                                          </option>
                                          <option value="Greenland">
                                            Greenland
                                          </option>
                                          <option value="Grenada">
                                            Grenada
                                          </option>
                                          <option value="Guadeloupe">
                                            Guadeloupe
                                          </option>
                                          <option value="Guam">Guam</option>
                                          <option value="Guatemala">
                                            Guatemala
                                          </option>
                                          <option value="Guernsey">
                                            Guernsey
                                          </option>
                                          <option value="Guinea">
                                            Guinea
                                          </option>
                                          <option value="Guinea-bissau">
                                            Guinea-bissau
                                          </option>
                                          <option value="Guyana">
                                            Guyana
                                          </option>
                                          <option value="Haiti">
                                            Haiti
                                          </option>
                                          <option value="Heard Island and Mcdonald Islands">
                                            Heard Island and Mcdonald
                                            Islands
                                          </option>
                                          <option value="Holy See (Vatican City State)">
                                            Holy See (Vatican City State)
                                          </option>
                                          <option value="Honduras">
                                            Honduras
                                          </option>
                                          <option value="Hong Kong">
                                            Hong Kong
                                          </option>
                                          <option value="Hungary">
                                            Hungary
                                          </option>
                                          <option value="Iceland">
                                            Iceland
                                          </option>
                                          <option value="India">
                                            India
                                          </option>
                                          <option value="Indonesia">
                                            Indonesia
                                          </option>
                                          <option value="Iran, Islamic Republic of">
                                            Iran, Islamic Republic of
                                          </option>
                                          <option value="Iraq">Iraq</option>
                                          <option value="Ireland">
                                            Ireland
                                          </option>
                                          <option value="Isle of Man">
                                            Isle of Man
                                          </option>
                                          <option value="Israel">
                                            Israel
                                          </option>
                                          <option value="Italy">
                                            Italy
                                          </option>
                                          <option value="Jamaica">
                                            Jamaica
                                          </option>
                                          <option value="Japan">
                                            Japan
                                          </option>
                                          <option value="Jersey">
                                            Jersey
                                          </option>
                                          <option value="Jordan">
                                            Jordan
                                          </option>
                                          <option value="Kazakhstan">
                                            Kazakhstan
                                          </option>
                                          <option value="Kenya">
                                            Kenya
                                          </option>
                                          <option value="Kiribati">
                                            Kiribati
                                          </option>
                                          <option value="Korea, Democratic People's Republic of">
                                            Korea, Democratic People's
                                            Republic of
                                          </option>
                                          <option value="Korea, Republic of">
                                            Korea, Republic of
                                          </option>
                                          <option value="Kuwait">
                                            Kuwait
                                          </option>
                                          <option value="Kyrgyzstan">
                                            Kyrgyzstan
                                          </option>
                                          <option value="Lao People's Democratic Republic">
                                            Lao People's Democratic Republic
                                          </option>
                                          <option value="Latvia">
                                            Latvia
                                          </option>
                                          <option value="Lebanon">
                                            Lebanon
                                          </option>
                                          <option value="Lesotho">
                                            Lesotho
                                          </option>
                                          <option value="Liberia">
                                            Liberia
                                          </option>
                                          <option value="Libyan Arab Jamahiriya">
                                            Libyan Arab Jamahiriya
                                          </option>
                                          <option value="Liechtenstein">
                                            Liechtenstein
                                          </option>
                                          <option value="Lithuania">
                                            Lithuania
                                          </option>
                                          <option value="Luxembourg">
                                            Luxembourg
                                          </option>
                                          <option value="Macao">
                                            Macao
                                          </option>
                                          <option value="Macedonia, The Former Yugoslav Republic of">
                                            Macedonia, The Former Yugoslav
                                            Republic of
                                          </option>
                                          <option value="Madagascar">
                                            Madagascar
                                          </option>
                                          <option value="Malawi">
                                            Malawi
                                          </option>
                                          <option value="Malaysia">
                                            Malaysia
                                          </option>
                                          <option value="Maldives">
                                            Maldives
                                          </option>
                                          <option value="Mali">Mali</option>
                                          <option value="Malta">
                                            Malta
                                          </option>
                                          <option value="Marshall Islands">
                                            Marshall Islands
                                          </option>
                                          <option value="Martinique">
                                            Martinique
                                          </option>
                                          <option value="Mauritania">
                                            Mauritania
                                          </option>
                                          <option value="Mauritius">
                                            Mauritius
                                          </option>
                                          <option value="Mayotte">
                                            Mayotte
                                          </option>
                                          <option value="Mexico">
                                            Mexico
                                          </option>
                                          <option value="Micronesia, Federated States of">
                                            Micronesia, Federated States of
                                          </option>
                                          <option value="Moldova, Republic of">
                                            Moldova, Republic of
                                          </option>
                                          <option value="Monaco">
                                            Monaco
                                          </option>
                                          <option value="Mongolia">
                                            Mongolia
                                          </option>
                                          <option value="Montenegro">
                                            Montenegro
                                          </option>
                                          <option value="Montserrat">
                                            Montserrat
                                          </option>
                                          <option value="Morocco">
                                            Morocco
                                          </option>
                                          <option value="Mozambique">
                                            Mozambique
                                          </option>
                                          <option value="Myanmar">
                                            Myanmar
                                          </option>
                                          <option value="Namibia">
                                            Namibia
                                          </option>
                                          <option value="Nauru">
                                            Nauru
                                          </option>
                                          <option value="Nepal">
                                            Nepal
                                          </option>
                                          <option value="Netherlands">
                                            Netherlands
                                          </option>
                                          <option value="Netherlands Antilles">
                                            Netherlands Antilles
                                          </option>
                                          <option value="New Caledonia">
                                            New Caledonia
                                          </option>
                                          <option value="New Zealand">
                                            New Zealand
                                          </option>
                                          <option value="Nicaragua">
                                            Nicaragua
                                          </option>
                                          <option value="Niger">
                                            Niger
                                          </option>
                                          <option value="Nigeria">
                                            Nigeria
                                          </option>
                                          <option value="Niue">Niue</option>
                                          <option value="Norfolk Island">
                                            Norfolk Island
                                          </option>
                                          <option value="Northern Mariana Islands">
                                            Northern Mariana Islands
                                          </option>
                                          <option value="Norway">
                                            Norway
                                          </option>
                                          <option value="Oman">Oman</option>
                                          <option value="Pakistan">
                                            Pakistan
                                          </option>
                                          <option value="Palau">
                                            Palau
                                          </option>
                                          <option value="Palestinian Territory, Occupied">
                                            Palestinian Territory, Occupied
                                          </option>
                                          <option value="Panama">
                                            Panama
                                          </option>
                                          <option value="Papua New Guinea">
                                            Papua New Guinea
                                          </option>
                                          <option value="Paraguay">
                                            Paraguay
                                          </option>
                                          <option value="Peru">Peru</option>
                                          <option value="Philippines">
                                            Philippines
                                          </option>
                                          <option value="Pitcairn">
                                            Pitcairn
                                          </option>
                                          <option value="Poland">
                                            Poland
                                          </option>
                                          <option value="Portugal">
                                            Portugal
                                          </option>
                                          <option value="Puerto Rico">
                                            Puerto Rico
                                          </option>
                                          <option value="Qatar">
                                            Qatar
                                          </option>
                                          <option value="Reunion">
                                            Reunion
                                          </option>
                                          <option value="Romania">
                                            Romania
                                          </option>
                                          <option value="Russian Federation">
                                            Russian Federation
                                          </option>
                                          <option value="Rwanda">
                                            Rwanda
                                          </option>
                                          <option value="Saint Helena">
                                            Saint Helena
                                          </option>
                                          <option value="Saint Kitts and Nevis">
                                            Saint Kitts and Nevis
                                          </option>
                                          <option value="Saint Lucia">
                                            Saint Lucia
                                          </option>
                                          <option value="Saint Pierre and Miquelon">
                                            Saint Pierre and Miquelon
                                          </option>
                                          <option value="Saint Vincent and The Grenadines">
                                            Saint Vincent and The Grenadines
                                          </option>
                                          <option value="Samoa">
                                            Samoa
                                          </option>
                                          <option value="San Marino">
                                            San Marino
                                          </option>
                                          <option value="Sao Tome and Principe">
                                            Sao Tome and Principe
                                          </option>
                                          <option value="Saudi Arabia">
                                            Saudi Arabia
                                          </option>
                                          <option value="Senegal">
                                            Senegal
                                          </option>
                                          <option value="Serbia">
                                            Serbia
                                          </option>
                                          <option value="Seychelles">
                                            Seychelles
                                          </option>
                                          <option value="Sierra Leone">
                                            Sierra Leone
                                          </option>
                                          <option value="Singapore">
                                            Singapore
                                          </option>
                                          <option value="Slovakia">
                                            Slovakia
                                          </option>
                                          <option value="Slovenia">
                                            Slovenia
                                          </option>
                                          <option value="Solomon Islands">
                                            Solomon Islands
                                          </option>
                                          <option value="Somalia">
                                            Somalia
                                          </option>
                                          <option value="South Africa">
                                            South Africa
                                          </option>
                                          <option value="South Georgia and The South Sandwich Islands">
                                            South Georgia and The South
                                            Sandwich Islands
                                          </option>
                                          <option value="Spain">
                                            Spain
                                          </option>
                                          <option value="Sri Lanka">
                                            Sri Lanka
                                          </option>
                                          <option value="Sudan">
                                            Sudan
                                          </option>
                                          <option value="Suriname">
                                            Suriname
                                          </option>
                                          <option value="Svalbard and Jan Mayen">
                                            Svalbard and Jan Mayen
                                          </option>
                                          <option value="Swaziland">
                                            Swaziland
                                          </option>
                                          <option value="Sweden">
                                            Sweden
                                          </option>
                                          <option value="Switzerland">
                                            Switzerland
                                          </option>
                                          <option value="Syrian Arab Republic">
                                            Syrian Arab Republic
                                          </option>
                                          <option value="Taiwan, Province of China">
                                            Taiwan, Province of China
                                          </option>
                                          <option value="Tajikistan">
                                            Tajikistan
                                          </option>
                                          <option value="Tanzania, United Republic of">
                                            Tanzania, United Republic of
                                          </option>
                                          <option value="Thailand">
                                            Thailand
                                          </option>
                                          <option value="Timor-leste">
                                            Timor-leste
                                          </option>
                                          <option value="Togo">Togo</option>
                                          <option value="Tokelau">
                                            Tokelau
                                          </option>
                                          <option value="Tonga">
                                            Tonga
                                          </option>
                                          <option value="Trinidad and Tobago">
                                            Trinidad and Tobago
                                          </option>
                                          <option value="Tunisia">
                                            Tunisia
                                          </option>
                                          <option value="Turkey">
                                            Turkey
                                          </option>
                                          <option value="Turkmenistan">
                                            Turkmenistan
                                          </option>
                                          <option value="Turks and Caicos Islands">
                                            Turks and Caicos Islands
                                          </option>
                                          <option value="Tuvalu">
                                            Tuvalu
                                          </option>
                                          <option value="Uganda">
                                            Uganda
                                          </option>
                                          <option value="Ukraine">
                                            Ukraine
                                          </option>
                                          <option value="United Arab Emirates">
                                            United Arab Emirates
                                          </option>
                                          <option value="United Kingdom">
                                            United Kingdom
                                          </option>
                                          <option value="United States">
                                            United States
                                          </option>
                                          <option value="United States Minor Outlying Islands">
                                            United States Minor Outlying
                                            Islands
                                          </option>
                                          <option value="Uruguay">
                                            Uruguay
                                          </option>
                                          <option value="Uzbekistan">
                                            Uzbekistan
                                          </option>
                                          <option value="Vanuatu">
                                            Vanuatu
                                          </option>
                                          <option value="Venezuela">
                                            Venezuela
                                          </option>
                                          <option value="Viet Nam">
                                            Viet Nam
                                          </option>
                                          <option value="Virgin Islands, British">
                                            Virgin Islands, British
                                          </option>
                                          <option value="Virgin Islands, U.S.">
                                            Virgin Islands, U.S.
                                          </option>
                                          <option value="Wallis and Futuna">
                                            Wallis and Futuna
                                          </option>
                                          <option value="Western Sahara">
                                            Western Sahara
                                          </option>
                                          <option value="Yemen">
                                            Yemen
                                          </option>
                                          <option value="Zambia">
                                            Zambia
                                          </option>
                                          <option value="Zimbabwe">
                                            Zimbabwe
                                          </option>
                                        </select>
                                      </div>
                                      {formErrors.PermanentAddress_Countryerr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.PermanentAddress_Countryerr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>STATE:</span>
                                        {isSelected1 ? (
                                          <select
                                            id="PermanentAddress_State"
                                            name="PermanentAddress_State"
                                            className="effect-16"
                                            readOnly={isReadOnly}
                                            onChange={handleAddressChange1}
                                            // onFocus={this.handleFocus}
                                            defaultValue={
                                              personal.permanentAddress_State
                                            }
                                            data-default-value={personal.permanentAddress_State || ""}
                                            onKeyPress={(event) => {
                                              if (
                                                !/[a-zA-Z\s ]+/.test(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                            style={{
                                              // Apply the marginLeft style only if the browser is Chrome
                                              // ...(isChrome && { marginLeft: '-3px' })
                                            }}
                                          >
                                            <option hidden>{profile.permanentAddress_State}</option>
                                            <option value="">
                                              Select state
                                            </option>
                                            <option value="Andaman and Nicobar Islands">
                                              Andaman and Nicobar Islands
                                            </option>
                                            <option value="Andhra Pradesh">
                                              Andhra Pradesh
                                            </option>
                                            <option value="Arunachal Pradesh">
                                              Arunachal Pradesh
                                            </option>
                                            <option value="Assam">
                                              Assam
                                            </option>
                                            <option value="Bihar">
                                              Bihar
                                            </option>
                                            <option value="Chandigarh">
                                              Chandigarh
                                            </option>
                                            <option value="Chhattisgarh">
                                              Chhattisgarh
                                            </option>
                                            <option value="Dadra and Nagar Haveli">
                                              Dadra and Nagar Haveli
                                            </option>
                                            <option value="Daman and Diu">
                                              Daman and Diu
                                            </option>
                                            <option value="Delhi">
                                              Delhi
                                            </option>
                                            <option value="Goa">
                                              Goa
                                            </option>
                                            <option value="Gujarat">
                                              Gujarat
                                            </option>
                                            <option value="Haryana">
                                              Haryana
                                            </option>
                                            <option value="Himachal Pradesh">
                                              Himachal Pradesh
                                            </option>
                                            <option value="Jammu and Kashmir">
                                              Jammu and Kashmir
                                            </option>
                                            <option value="Jharkhand">
                                              Jharkhand
                                            </option>
                                            <option value="Karnataka">
                                              Karnataka
                                            </option>
                                            <option value="Kerala">
                                              Kerala
                                            </option>
                                            <option value="Ladakh">
                                              Ladakh
                                            </option>
                                            <option value="Lakshadweep">
                                              Lakshadweep
                                            </option>
                                            <option value="Madhya Pradesh">
                                              Madhya Pradesh
                                            </option>
                                            <option value="Maharashtra">
                                              Maharashtra
                                            </option>
                                            <option value="Manipur">
                                              Manipur
                                            </option>
                                            <option value="Meghalaya">
                                              Meghalaya
                                            </option>
                                            <option value="Mizoram">
                                              Mizoram
                                            </option>
                                            <option value="Nagaland">
                                              Nagaland
                                            </option>
                                            <option value="Odisha">
                                              Odisha
                                            </option>
                                            <option value="Puducherry">
                                              Puducherry
                                            </option>
                                            <option value="Punjab">
                                              Punjab
                                            </option>
                                            <option value="Rajasthan">
                                              Rajasthan
                                            </option>
                                            <option value="Sikkim">
                                              Sikkim
                                            </option>
                                            <option value="Tamil Nadu">
                                              Tamil Nadu
                                            </option>
                                            <option value="Telangana">
                                              Telangana
                                            </option>
                                            <option value="Tripura">
                                              Tripura
                                            </option>
                                            <option value="Uttar Pradesh">
                                              Uttar Pradesh
                                            </option>
                                            <option value="Uttarakhand">
                                              Uttarakhand
                                            </option>
                                            <option value="West Bengal">
                                              West Bengal
                                            </option>
                                          </select>
                                        ) : (
                                          <input
                                            className="effect-16"
                                            onFocus={(e) =>
                                              e.target.select()
                                            }
                                            onInputCapture={(event) => {
                                              if (
                                                !/[a-zA-Z\s ]+/.test(
                                                  event.key
                                                )
                                              ) {
                                                event.preventDefault();
                                              }
                                            }}
                                            onChange={handleAddressChange1}
                                            type="text"
                                            id="PermanentAddress_State"
                                            name="PermanentAddress_State"
                                            defaultValue={
                                              personal.permanentAddress_State
                                            }
                                            placeholder="State"

                                          />
                                        )}
                                      </div>
                                      {formErrors.PermanentAddress_Stateerr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.PermanentAddress_Stateerr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>PIN CODE: </span>
                                        <input
                                          className="effect-16"
                                          onFocus={(e) => e.target.select()}
                                          type="text"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          maxLength="6"
                                          id="PermanentAddress_Pincode"
                                          name="PermanentAddress_Pincode"
                                          readOnly={isReadOnly}
                                          defaultValue={
                                            personal.permanentAddress_Pincode
                                          }
                                          onChange={handleAddressChange1}
                                        />
                                      </div>
                                      {formErrors.PermanentAddress_Pincodeerr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.PermanentAddress_Pincodeerr}</span>}
                                      <div className="col-lg-12">
                                        <br />
                                        <div className="row  justify-content-center">
                                          <button
                                            type="submit"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#8e75ef",
                                              fontWeight: "bold",
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                          >
                                            Update
                                          </button>
                                          <button
                                            type="reset"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#716b6b",
                                              fontWeight: "bold",
                                              marginLeft: "2%",
                                              height: "39.5px",
                                            }}
                                            onClick={()=>{
                                              document.getElementById("HouseText").type = "hidden";
                                              setFormErrors("");
                                            }}
                                          >
                                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      {/*Add Education*/}
      <div>
        {isPopupOpen8 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={()=>{
                        togglePopupClose8();
                        setFormErrors("");
                      }}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%" }}
                    >
                      Add Education
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <div className='newcontainer'>
                              <div className='biodata'>
                                <form autoComplete="off" onSubmit={EduSubmit}>
                                  <div className="row justify-content-center">
                                    <div className='col-lg-12'>
                                      {personalInfoDetails.map((details) => {
                                        return (
                                          <div className='info-row' >
                                            <input
                                              type='hidden'
                                              id='id'
                                              name='UserId'
                                              value={details.id}
                                              hidden
                                            />
                                          </div>)
                                      })}
                                      <div className='info-row' >
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>QUALIFICATION TYPE: </span>
                                        <select
                                          className="effect-16"
                                          id="Qualification"
                                          name="QualificationType"
                                          type="text"
                                          placeholder="Qualification Type"
                                          onChange={(e) => {
                                            handlevalueChange(e);
                                            handleChangeD(e);
                                          }}
                                          onChangeCapture={handleEducationChange}
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-8px' })
                                          }}
                                          onInputCapture={HandleQualification}
                                        >
                                          <option value="">Select</option>
                                          {educationDrop.map((EducationDrop) => (
                                            <option value={EducationDrop.qualificationType}
                                            >
                                              {EducationDrop.qualificationType}
                                            </option>
                                          ))}
                                          <option value="Others">Others</option>
                                        </select>
                                      </div>
                                      <div className='info-row' id='othertypeinput' style={OtherStyle}> 
                                      <span  className='effect-16' style={{ borderBottom: "none", textAlign: "left",visibility:'hidden' }}>zzzzzzz</span>
                                       <input
                                        type="hidden"
                                        name="otherQualificationType"
                                        placeholder="Others Type"
                                        className="form"
                                        id="otherQType"
                                        maxLength={30}
                                        onChange={handleChangeD}
                                      />  </div>
                                        {formErrors.Qualification && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.Qualification}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>QUALIFICATION:</span>
                                        <input
                                          className="effect-16"
                                          maxLength="40"
                                          type="text"
                                           list="browsers"
                                          id="Coursename"
                                          name="Coursename"
                                          onChange={handleInputChange}
                                          onChangeCapture={handleChangeD}
                                          placeholder="Course Name "
                                        />
                                        <datalist id="browsers">
                                          {educationCourseName.map((EducationCourseName) => (
                                            <option value={EducationCourseName.coursename}></option>
                                          ))}
                                        </datalist>
                                      </div>
                                      {formErrors.Coursename && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.Coursename}</span>}

                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>TYPE:</span>
                                        <select
                                          className="effect-16"
                                          type="text"
                                          id="Coursetype"
                                          name="Coursetype"
                                          placeholder="Course Type "
                                          onChange={handleChangeD}
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-3px' })
                                          }}
                                        >
                                          <option value=""> Course Type </option>
                                          <option value="Part Time">Part Time</option>
                                          <option value="Full Time">Full Time</option>
                                          <option value="Distance Education">Distance Education</option>
                                        </select>
                                      </div>
                                      {formErrors.Coursetype && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.Coursetype}</span>}
                                      <div className='info-row '>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>STREAM:</span>
                                        <input
                                          className="effect-16"
                                          maxLength="100"
                                          type="text"
                                          id="stream"
                                          name="Stream"
                                          placeholder="Specialization"
                                        />
                                      </div>
                                      {formErrors.stream && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.stream}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>START DATE:</span>
                                        <ReactDatePicker
                                          className="effect-16"
                                          selected={formValues.startDate}
                                          dateFormat="yyyy-MM"
                                          showMonthYearPicker
                                          maxDate={maxDateS}
                                          onFocus={(e) => {
                                            handleFocus(e);
                                          }}
                                          minDate={new Date(new Date().getFullYear()
                                            - 60, new Date().getMonth(), 1)}
                                          type="text"
                                          id="Startdate"
                                          name="CourseStartDate"
                                          onChange={(date) => handleDateChange(date, 'startDate')}
                                        /> 
                                        {DisFireStart && (
        <i
          id="Cal"
          className="fa fa-calendar-o"
          aria-hidden="true"
          style={{
            float: 'right',
            marginTop: '25px',
            marginLeft:"-13px"
          }}
        ></i>
      )}                                
                                      </div>
                                      {formErrors.Startdate && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.Startdate}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>END DATE:</span>
                                        <ReactDatePicker
                                          className="effect-16"
                                          dateFormat="yyyy-MM"
                                          showMonthYearPicker
                                          selected={formValues.endDate}
                                          onFocus={(e) => {
                                            handleFocus(e);
                                          }}
                                          max={new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth() + 1
                                          )
                                            .toJSON()
                                            .slice(0, 7)}
                                          min={new Date(
                                            new Date().getFullYear() - 60,
                                            new Date().getMonth() + 1
                                          )
                                            .toJSON()
                                            .slice(0, 7)}
                                       
                                          id="Enddate"
                                          name="CourseEndDate"
                                          onChange={(date) => handleDateChange(date, 'endDate')}
                                        />
                                        {DisFire && (
        <i
          id="Cal"
          className="fa fa-calendar-o"
          aria-hidden="true"
          style={{
            float: 'right',
            marginTop: '25px',
            marginLeft:"-13px"
          }}
        ></i>
      )}                                
                                      </div>
                                      {formErrors.Enddate && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.Enddate}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>UNIVERSITY NAME:</span>
                                        <input
                                          className="effect-16"
                                          type="text"
                                          maxLength="80"
                                          id="unisty_name"
                                          name="UniversityName"
                                          onChange={handleChangeD}
                                          placeholder="University Name"
                                        />
                                      </div>
                                      {formErrors.unisty_name && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.unisty_name}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>COLLEGE NAME:</span>
                                        <input
                                          className="effect-16"
                                          maxLength="80"
                                          type="text"
                                          id="clg_name"
                                          name="CollegeName"
                                          onChange={handleChangeD}
                                          placeholder="College Name "
                                        />
                                      </div>
                                      {formErrors.clg_name && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.clg_name}</span>}
                                      <div className="col-lg-12">
                                        <br />
                                        <div className="row  justify-content-center">
                                          <button
                                            type="submit"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#8e75ef",
                                              fontWeight: "bold",
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                          >
                                            Save
                                          </button>
                                          <button
                                            type="reset"
                                            className="button-30 sk-edit"
                                            onClick={()=>{
                                              handleReset();
                                              setFormErrors("");
                                              setSelectedQualification('');
                                              document.getElementById("otherQType").type = "hidden";
                                            }}
                                            style={{
                                              color: "#716b6b",
                                              fontWeight: "bold",
                                              marginLeft: "2%",
                                              height: "39.5px",
                                            }}
                                          >
                                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                          </button>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </form>
                              </div>

                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      {/* Education Edit */}
      <div>
        {isPopupOpen5 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={()=>{
                        togglePopupClose5();
                        setFormErrors("");
                      }}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%" }}
                    >
                      Education
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <div className='newcontainer'>
                          {educationDetail.filter(personal => personal.id === selectedId).map((personal) => {
                            return (
                              <div key={personal.id} className='biodata'>
                                <form autoComplete="off" onSubmit={EducationEdit}>
                                  <div className="row justify-content-center">
                                    <div className='col-lg-12'>
                                      <div className='info-row'>
                                        <input
                                          style={{ display: "none" }}
                                          type="hidden"
                                          name="CreatedDate"
                                          value={personal.created_Date}
                                        />
                                      </div>
                                      <div className='info-row' >
                                        <input
                                          type='hidden'
                                          id='id'
                                          name='UserId'
                                          defaultValue={personal.user_Id}
                                          hidden
                                        />
                                      </div>
                                      <div className='info-row' >
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>QUALIFICATION TYPE: </span>
                                        <select
                                          className="effect-16"
                                          id="Qualification"
                                          defaultValue={personal.qualificationType}
                                          name="QualificationType"
                                          type="text"
                                          placeholder="Qualification Type"
                                          onChange={(e) => {
                                            handlevalueChange(e);
                                          }}
                                          onChangeCapture={handleEducationChange}
                                          onInputCapture={HandleQualification}
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-8px' })
                                          }}
                                        >
                                          <option hidden>{personal.qualificationType}</option>
                                          <option value="">Select</option>
                                          {educationDrop.map((EducationDrop) => (
                                            <option value={EducationDrop.qualificationType}
                                              selected={
                                                EducationDrop.qualificationType ===
                                                personal.qualificationType
                                              }
                                            >
                                              {EducationDrop.qualificationType}
                                            </option>
                                          ))}
                                          <option value="Others">Others</option>
                                        </select>
                                      </div>
                                      <div className='info-row' id='othertypeinput' style={OtherStyle}> 
                                      <span  className='effect-16' style={{ borderBottom: "none", textAlign: "left",visibility:'hidden' }}>zzzzzzz</span>
                                       <input
                                        type="hidden"
                                        name="otherQualificationType"
                                        placeholder="Others Type"
                                        className="form"
                                        id="otherQType"
                                        maxLength={30}
                                      />  </div>
                                        {formErrors.Qualification && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.Qualification}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>QUALIFICATION:</span>
                                        <input
                                          type="hidden"
                                          name="OldCoursename"
                                          defaultValue={
                                            personal.courseName
                                          }
                                        />
                                        <input
                                          className="effect-16"
                                          maxLength="40"
                                          type="text"
                                          list="browsers"
                                          id="Coursename"
                                          name="Coursename"
                                          onChange={handleInputChange}
                                          onChangeCapture={handleChangeD}
                                          defaultValue={personal.courseName}
                                          placeholder="Course Name "
                                        />
                                        <datalist id="browsers">
                                          {educationCourseName.map((EducationCourseName) => (
                                            <option value={EducationCourseName.coursename}></option>
                                          ))}
                                        </datalist>
                                      </div>
                                      {formErrors.Coursename && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.Coursename}</span>}

                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>TYPE:</span>
                                        <select
                                          className="effect-16"
                                          type="text"
                                          id="Coursetype"
                                          name="Coursetype"
                                          defaultValue={personal.courseType}
                                          placeholder="Course Type "
                                          onChange={handleChangeD}
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            // ...(isChrome && { marginLeft: '-3px' })
                                          }}
                                        >
                                          <option value=""> Course Type </option>
                                          <option value="Part Time">Part Time</option>
                                          <option value="Full Time">Full Time</option>
                                          <option value="Distance Education">Distance Education</option>
                                        </select>
                                      </div>
                                      {formErrors.Coursetype && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.Coursetype}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>STREAM:</span>
                                        <input
                                          className="effect-16"
                                          maxLength="100"
                                          type="text"
                                          id="stream"
                                          name="Stream"
                                          defaultValue={personal.stream}
                                          placeholder="Specialization"
                                        />
                                      </div>
                                      {formErrors.stream && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.stream}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>START DATE:</span>
                                        <ReactDatePicker
                                          className="effect-16"
                                          dateFormat="yyyy-MM"
                                          showMonthYearPicker
                                          selected={startDate || (personal.courseStartDate ? new Date(personal.courseStartDate) : null)}
                                          maxDate={maxDateS}
                                          onFocus={(e) => {
                                            handleFocus (e);
                                          }}
                                          minDate={new Date(new Date().getFullYear()
                                            - 60, new Date().getMonth(), 1)}
                                          type="text"
                                          id="Startdate"
                                          name="CourseStartDate"
                                          placeholder="Course Start Date "
                                          onChange={(date) => handleDateChange2(date, 'startDate')}
                                        />
                                        {DisFireStart && (
        <i
          id="Cal"
          className="fa fa-calendar-o"
          aria-hidden="true"
          style={{
            float: 'right',
            marginTop: '25px',
            marginLeft:"-13px"
          }}
        ></i>
      )}                                

                                      </div>
                                      {formErrors.Startdate && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.Startdate}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>END DATE:</span>
                                        <ReactDatePicker
                                          className="effect-16"
                                          dateFormat="yyyy-MM"
                                          showMonthYearPicker
                                          selected={endDate || (personal.courseEndDate ? new Date(personal.courseEndDate) : null)}
                                          onFocus={(e) => {
                                            handleFocus(e);
                                          }}
                                          type="text"
                                          id="Enddate"
                                          name="CourseEndDate"
                                          placeholder="Course End Date "
                                          onChange={(date) => handleDateChange1(date, 'endDate')}
                                        />
                                         {DisFire && (
        <i
          id="Cal"
          className="fa fa-calendar-o"
          aria-hidden="true"
          style={{
            float: 'right',
            marginTop: '25px',
            marginLeft:"-13px"
          }}
        ></i>
      )}                             
                                      </div>
                                      {formErrors.Enddate && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-1px", whiteSpace: "nowrap"
                                      }}>{formErrors.Enddate}</span>}
                                      <div className='info-row'>
                                        <input
                                          className="effect-16"
                                          maxLength="80"
                                          type='hidden'
                                          id="unisty_name"
                                          name="UniversityName"
                                          defaultValue={personal.universityName}
                                          placeholder="University Name"
                                          hidden
                                        />
                                      </div>
                                      <div className='info-row'>
                                        <input
                                          className="effect-16"
                                          maxLength="80"
                                          type='hidden'
                                          id="clg_name"
                                          name="CollegeName"
                                          defaultValue={personal.collegeName}
                                          placeholder="College Name "
                                          hidden
                                        />
                                      </div>
                                      <div className="col-lg-12">
                                        <br />
                                        <div className="row  justify-content-center">
                                          <button
                                            type="submit"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#8e75ef",
                                              fontWeight: "bold",
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                          >
                                            Update
                                          </button>
                                          <button
                                            type="reset"
                                            className="button-30 sk-edit"
                                            onClick={()=>{
                                              handleReset();
                                              setFormErrors("");
                                              setSelectedQualification('');
                                              document.getElementById("otherQType").type = "hidden";
                                            }}
                                            style={{
                                              color: "#716b6b",
                                              fontWeight: "bold",
                                              marginLeft: "2%",
                                              height: "39.5px",
                                            }}
                                          >
                                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                          </button>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </form>
                              </div>
                            );
                          })}

                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      {/* Add Skill */}
      <div>
        {isPopupOpen10 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={togglePopupClose10}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%", marginBottom: "20px" }}
                    >
                      Add Skills
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <div className='newcontainer'>
                              <div className='biodata'>
                                <form autoComplete="off" onSubmit={Skillsubmit}>
                                  <div className="row justify-content-center">
                                    <div className='col-lg-12'>
                                      {personalInfoDetails.map((details) => {
                                        return (
                                          <div className='info-row' >
                                            <input
                                              type='hidden'
                                              id='id'
                                              name='UserId'
                                              value={details.id}
                                              hidden
                                            />
                                          </div>)
                                      })}

                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Skill Name: </span>
                                        <select
                                          className="effect-16"
                                          name="SkillName"
                                          type="text"
                                          placeholder="SkillName"
                                          id="skillname"
                                        >
                                          <option value="">Name</option>
                                          {SKillAdminNameDrop.map((SKillAdminNameDrop) => (
                                            <option
                                              value={SKillAdminNameDrop.skillName}
                                            >
                                              {SKillAdminNameDrop.skillName}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Experience: </span>
                                        <input
                                          className="effect-16"
                                          id="exp"
                                          name="Experience"
                                          type="text"
                                          onFocus={(e) =>
                                            (e.target.type = "date")
                                          }
                                          max={calculateMaxDate}
                                        />
                                      </div>
                                      <div className="col-lg-12">
                                        <br />
                                        <div className="row  justify-content-center">
                                          <button
                                            type="submit"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#8e75ef",
                                              fontWeight: "bold",
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                          >
                                            Save
                                          </button>
                                          <button
                                            type="reset"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#716b6b",
                                              fontWeight: "bold",
                                              marginLeft: "2%",
                                              height: "39.5px",
                                            }}
                                          >
                                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      {/* Skill Edit */}
      <div>
        {isPopupOpen6 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={togglePopupClose6}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%", marginBottom: "20px" }}
                    >
                      SKILL NAME
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <div className='newcontainer'>
                          {displayedSkills.filter(personal => personal.id === selectedId).map((personal) => {
                            return (
                              <div key={personal.id} className='biodata'>
                                <form autoComplete="off" onSubmit={SkillEdit}>
                                  <div className="row justify-content-center">
                                    <div className='col-lg-12'>
                                      <input
                                        style={{ display: "none" }}
                                        type="hidden"
                                        name="CreatedDate"
                                        value={personal.created_Date}
                                      />
                                      <input
                                        name="Experience"
                                        id="Experience"
                                        defaultValue={personal.experience}
                                        type="hidden"
                                      />
                                      <input
                                        type="hidden"
                                        id="Trainer"
                                        defaultChecked={
                                          personal.trainer === "Yes" ? true : false
                                        }
                                        name="Trainer"
                                      />
                                      <input
                                        name="SkillCoursename"
                                        defaultValue={personal.skillCoursename}
                                        type="hidden"
                                        id="skillcourseName"
                                      />
                                      <input
                                        name="SkillStartdate"
                                        defaultValue={personal.skillStartdate}
                                        id="skillcoursestartdate"
                                        type="hidden"
                                      />
                                      <input
                                        name="SkillEnddate"
                                        defaultValue={personal.skillEnddate}
                                        id="skillCourseenddate"
                                        type="hidden"
                                      />
                                      <input
                                        name="UserId"
                                        defaultValue={personal.userId}
                                        id="UserId"
                                        type="hidden"
                                      />
                                      <div className='info-row'>
                                        <input
                                          type="hidden"
                                          name="OldSkillName"
                                          defaultValue={
                                            personal.skillName
                                          }
                                        />
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Name: </span>
                                        <select
                                          className="effect-16"
                                          name="SkillName"
                                          type="text"
                                          placeholder="SkillName"
                                          id="skillname"
                                        >
                                          <option hidden>{personal.skillName}</option>
                                          <option value=""> Skill Name</option>
                                          {SKillAdminNameDrop.map((SKillAdminNameDrop) => (
                                            <option
                                              selected={
                                                SKillAdminNameDrop.skillName ===
                                                personal.skillName
                                              }
                                              value={SKillAdminNameDrop.skillName}
                                            >
                                              {SKillAdminNameDrop.skillName}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="col-lg-12">
                                        <br />
                                        <div className="row  justify-content-center">
                                          <button
                                            type="submit"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#8e75ef",
                                              fontWeight: "bold",
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                          >
                                            Update
                                          </button>
                                          <button
                                            type="reset"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#716b6b",
                                              fontWeight: "bold",
                                              marginLeft: "2%",
                                              height: "39.5px",
                                            }}
                                          >
                                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            );
                          })}

                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      {/* Skill Display */}
      <div>
        {isPopupOpen11 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={togglePopupClose11}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%", marginBottom: "20px" }}
                    >
                      SKILL NAME
                    </h6>
                    {details.map((d, index) => (
                      <div key={index} className="profile">

                        <div className="row justify-content-center">
                                    <div className="grid-item">
                                      {displayedSkills.map((skill, index) => (
                                        <ul key={index} className="skills-list1">
                                          <li>{skill.skillName}&nbsp;&nbsp;&nbsp;&nbsp;
                                          {personalInfoDetails.map((personal) => {
                                            return (
                                              <>
                                            {personal.adminEmail === email ? (<span>
                                                <img
                                                  src={pencil}
                                                  alt='Profile'
                                                  className='edit-image'
                                                  style={{float:"right"}}
                                                  onClick={(e) => togglePopup6(skill.id, e)}
                                                />
                                              </span>):null}
                                              </>
                                            );
                                          })}
                                           
                                          </li>
                                        </ul>
                                      ))}

                                      <div className="button-container">
                                        {skillDetails.length > initialSkillsToShow && (
                                          <button onClick={toggleSkills}>
                                            {showAllSkills ? 'See less' : 'See more'}
                                          </button>
                                        )}
                                      </div>

                                    </div>
                                    </div>
                           
                      </div>
                    ))}
                            
                </div>
                
              </center>
            </div>
          </div>
        )}
      </div>

      {/*Add Family*/}
      <div>
        {isPopupOpen9 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={togglePopupClose9}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%" }}
                    >
                      Add Family
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <div className='newcontainer'>
                              <div className='biodata'>
                                <form autoComplete="off" onSubmit={FamlySubmit} id='familyform'>
                                  <div className="row justify-content-center">
                                    <div className='col-lg-12'>
                                      {personalInfoDetails.map((details) => {
                                        return (<div className='info-row' >
                                          <input
                                            type='hidden'
                                            id='id'
                                            name='UserId'
                                            value={details.id}
                                            hidden
                                          />
                                        </div>)
                                      })}
                                      <div className='info-row' >
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>First Name: </span>
                                        <input
                                          className="effect-16"
                                          name="FirstName"
                                          type="text"
                                          id="Firstname"
                                          placeholder="First Name"
                                          onChange={Familyhandlechange}
                                        />

                                      </div>
                                      {formErrors.FirstNameErr && <span className="error-messageedit-teamgrid" style={{
                                        float: "left",marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.FirstNameErr}</span>}
                                      <div className='info-row' >
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Middle Name: </span>
                                        <input
                                          className="effect-16"
                                          name="MiddleName"
                                          type="text"
                                          id="MiddleName"
                                          placeholder="Middle Name"
                                        />
                                      </div>
                                      {/* {formErrors.MiddleNameErr && <span className="error-messageedit" style={{
                                        float: "left",marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.MiddleNameErr}</span>} */}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Last Name: </span>
                                        <input
                                          className="effect-16"
                                          name="LastName"
                                          type="text"
                                          id="Lastname"
                                          placeholder="Last Name"
                                          onChange={Familyhandlechange}
                                        />
                                      </div>
                                      {formErrors.LastNameErr && <span className="error-messageedit" style={{
                                        float: "left",marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.LastNameErr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Relationship: </span>
                                        <select
                                          className="effect-16"
                                          name="Relationship"
                                          id="Relationship"
                                          placeholder="Relationship"
                                          onChange={Familyhandlechange}
                                        >
                                          <option value="">Relationship</option>
                                          {RelationshipDrop.map(
                                            (RelationshipDrop) => (
                                              <option
                                                value={
                                                  RelationshipDrop.relationship
                                                }
                                              >
                                                {RelationshipDrop.relationship}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      {formErrors.RelationshipErr && <span className="error-messageedit" style={{
                                        float: "left",marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.RelationshipErr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Contact: </span>
                                        <input
                                          className="effect-16"
                                          // onChange={handleInputChange}
                                          name="Contact"
                                          type="text"
                                          maxLength="10"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          id="famContact"
                                          placeholder="Contact"
                                          onChange={(event) => {
                                            handleInputChange(event); // Call the first handler
                                            Familyhandlechange(event); // Call the second handler
                                          }}
                                        />
                                      </div>
                                      {formErrors.ContactErr && <span className="error-messageedit" style={{
                                     float: "left",marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.ContactErr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Email: </span>
                                        <input
                                          className="effect-16"
                                          name="Email"
                                          type="text"
                                          id="famEmail"
                                          maxLength="50"
                                          placeholder="Email"
                                          onChange={(event) => {
                                            handleInputChange(event); 
                                            Familyhandlechange(event); 
                                          }}
                                        />
                                      </div>
                                      {formErrors.EmailErr && <span className="error-messageedit" style={{
                                       float: "left",marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.EmailErr}</span>}
                                      <center style={{ marginTop: "17px" }}>
                                        <span style={{ color: "#7c7c7c" }}>
                                          Emergency Contact
                                        </span>
                                        &nbsp;&nbsp;
                                        {familyDetails.length === 0
                                          ? true
                                          : false}
                                        <label
                                          style={
                                            familyDetails.length ===
                                              0
                                              ? { pointerEvents: "none" }
                                              : { pointerEvents: "all" }
                                          }
                                          className="switch1 center"
                                        >
                                          <input
                                            type="checkbox"
                                            id="EmergencyContact"
                                            defaultChecked={
                                              familyDetails
                                                .length === 0
                                                ? true
                                                : false
                                            }
                                            name="EmergencyContact"
                                          />
                                          <span className="slider1 round1" />
                                        </label>
                                      </center>
                                      <div className="col-lg-12">
                                        <br />
                                        <div className="row  justify-content-center">
                                          <button
                                            type="submit"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#8e75ef",
                                              fontWeight: "bold",
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                          >
                                            Save
                                          </button>
                                          <button
                                            type="reset"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#716b6b",
                                              fontWeight: "bold",
                                              marginLeft: "2%",
                                              height: "39.5px",
                                            }}
                                             onClick={messagecancel}
                                          >
                                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      {/* Family Edit */}
      <div>
        {isPopupOpen7 && (
          <div className="popup">
            <div className="popup-content">
              <center id="DiscAddPop">
                <div>
                  <center>

                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                      }}
                      onClick={togglePopupClose7}
                    >
                      <CloseIcon />
                    </button>

                    <h6
                      className="placeholder"
                      style={{ fontWeight: "bold", marginTop: "-2%" }}
                    >
                      Family
                    </h6>

                    {details.map((d, index) => (
                      <div key={index} className="profile">
                        <div className='newcontainer'>
                          {familyDetails.filter(family => family.id === selectedId).map((family, index) => {
                            return (
                              <div key={family.id} className='biodata'>
                                <form autoComplete="off" onSubmit={FamilyEdit}>
                                  <div className="row justify-content-center">
                                    <div className='col-lg-12'>
                                      <div className='info-row' >
                                        <input
                                          type='hidden'
                                          id='id'
                                          name='Id'
                                          defaultValue={family.id}
                                        />
                                      </div>
                                      <div className='info-row' >
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>First Name: </span>
                                        <input
                                          className="effect-16"
                                          name="FirstName"
                                          type="text"
                                          maxLength="20"
                                          id="Firstname"
                                          defaultValue={family.firstName}
                                          placeholder="First Name"
                                          onChange={Familyhandlechange}
                                        />
                                      </div>
                                      {formErrors.FirstNameErr && <span className="error-messageedit-teamgrid" style={{
                                        float: "left",marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.FirstNameErr}</span>}
                                      <div className='info-row' >
                                        <input
                                          name="MiddleName"
                                          type="hidden"
                                          id="MiddleName"
                                          defaultValue={family.middleName}
                                        />
                                      </div>
                                      <div className='info-row' >
                                      <input
  type="hidden"
  id="EmergencyContact"
  name="EmergencyContact"
  checked={family.emergencyContact}
/>

                                      </div>
                                      <div className='info-row' >
                                        <input
                                          name="CreatedDate"
                                          type="hidden"
                                          id="CreatedDate"
                                          defaultValue={family.created_Date}
                                        />
                                      </div>
                                      <div className='info-row' >
                                        <input
                                          name="UserId"
                                          type="hidden"
                                          id="UserId"
                                          defaultValue={family.userId}
                                        />
                                      </div>
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Last Name: </span>
                                        <input
                                          className="effect-16"
                                          name="LastName"
                                          type="text"
                                          maxLength="20"
                                          id="Lastname"
                                          defaultValue={family.lastName}
                                          placeholder="Last Name"
                                          onChange={Familyhandlechange}
                                        />
                                      </div>
                                      {formErrors.LastNameErr && <span className="error-messageedit" style={{
                                        float: "left",marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.LastNameErr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Relationship: </span>
                                        <input
                                          type="hidden"
                                          name="oldrelation"
                                          defaultValue={
                                            family.relationship
                                          }
                                        />
                                        <select
                                          className="effect-16"
                                          name="Relationship"
                                          id="Relationship"
                                          defaultValue={family.relationship}
                                          placeholder="Relationship"
                                          onChange={Familyhandlechange}
                                        >
                                          <option value="">Relationship</option>
                                          <option hidden>{family.relationship}</option>
                                          {RelationshipDrop.map((RelationshipDrop) =>
                                            RelationshipDrop.relationship ===
                                              family.relationship ? (
                                              <option value={RelationshipDrop.relationship} selected>
                                                {RelationshipDrop.relationship}
                                              </option>
                                            ) : (
                                              <option value={RelationshipDrop.relationship}>
                                                {RelationshipDrop.relationship}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      {formErrors.RelationshipErr && <span className="error-messageedit" style={{
                                        float: "left",marginLeft: "0px", marginTop: "-1px"
                                      }}>{formErrors.RelationshipErr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Contact: </span>
                                        <input
                                          className="effect-16"
                                          onChange={(event) => {
                                            handleInputChange(event); // Call the first handler
                                            Familyhandlechange(event); // Call the second handler
                                          }}
                                          name="Contact"
                                          type="text"
                                          maxLength="10"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          id="famContact"
                                          defaultValue={family.contact}
                                          placeholder="Contact"
                                        />
                                      </div>
                                      {formErrors.ContactErr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-2px", whiteSpace: "nowrap"
                                      }}>{formErrors.ContactErr}</span>}
                                      <div className='info-row'>
                                        <span className='effect-16' style={{ borderBottom: "none", textAlign: "left" }}>Email: </span>
                                        <input
                                          className="effect-16"
                                          name="Email"
                                          type="text"
                                          id="famEmail"
                                          maxLength="50"
                                          defaultValue={family.email}
                                          placeholder="Email"
                                          onChange={(event) => {
                                            handleInputChange(event); 
                                            Familyhandlechange(event); 
                                          }}
                                        />
                                      </div>
                                      {formErrors.EmailErr && <span className="error-messageedit" style={{
                                        float: "left", marginLeft: "0px", marginTop: "-2px", whiteSpace: "nowrap"
                                      }}>{formErrors.EmailErr}</span>}
                                      <div className="col-lg-12">
                                        <br />
                                        <div className="row  justify-content-center">
                                          <button
                                            type="submit"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#8e75ef",
                                              fontWeight: "bold",
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                          >
                                            Update
                                          </button>
                                          <button
                                            type="reset"
                                            className="button-30 sk-edit"
                                            style={{
                                              color: "#716b6b",
                                              fontWeight: "bold",
                                              marginLeft: "2%",
                                              height: "39.5px",
                                            }}
                                          >
                                            &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </center>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

    </div>
  )
}

export default Teamgriddetails