import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class AddTrainerpop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RelationshipDrop: [],
      formErrors: {},
      Training: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    if (this.Validation()) {
      var role = JSON.parse(localStorage.Role);
      const url =
        role.toString() === "Platform Admin"
          ? `${BASE_URLs}/Training/AddTraining`
          : `${BASE_URLs}/Course/AddCourseName`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          document.getElementById("close").click();
        }, 4000);

        this.GetCourseName();
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    //  this.GetCourseName();
  };

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var TrainingName = document.getElementsByName("CategoryMaster");
    var ReferenceDocument = document.getElementsByName("Ref_Doc");

    if (!TrainingName[0].value) {
      formIsValid = false;
      formErrors["TrainingName"] = "Training Name is required";
    }

    if (!ReferenceDocument[0].value) {
      formIsValid = false;
      formErrors["ReferenceDocument"] = "Document Required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  GetTraining() {
    let url = `${BASE_URLs}/Training/SAGetTraining`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Training: data,
        });
      });
  }

  componentDidMount() {
    this.GetTraining();
  }
  render() {
    var w = window.innerWidth;

    const { ReferenceDocument, TrainingName } = this.state.formErrors;
    const { Training } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
          >
            {/* encType="multipart/form-data" */}

            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <button
                id="close"
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={this.props.close}
              >
                <CloseIcon />
              </button>

              <div className="row">
                <div className="col-lg-3  "></div>
                <div className="col-lg-8 ">
                  <select className="form">
                    <option value="fruit">Trainer's Name</option>
                    <option value="fruit">Arul</option>
                    <option value="vegetable">Vimal</option>
                    <option value="meat">Sharik</option>
                    <option value="meat">Azaar</option>
                  </select>
                  <span style={{ color: "#a29d9d", float: "left" }}>
                    Choose Trainer Name
                  </span>
                  <span className="underline" />
                </div>
                {/* <div className="col-lg-3  "></div> */}
              </div>

              <div className="row" style={{ padding: "3px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    className="form"
                    maxLength="50"
                    id="Department"
                    name="Department"
                    type="text"
                  />

                  <span style={{ color: "#a29d9d", float: "left" }}>
                    {" "}
                    Department
                  </span>

                  <span className="underline" />
                </div>
              </div>

              <div className="row" style={{ padding: "3px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    className="form"
                    maxLength="50"
                    id="Role"
                    name="Role"
                    type="text"
                  />

                  <span style={{ color: "#a29d9d", float: "left" }}> Role</span>

                  <span className="underline" />
                </div>
              </div>

              <div className="row" style={{ padding: "3px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    className="form"
                    maxLength="50"
                    id="TrainingName"
                    name="TrainingName"
                    type="text"
                  />

                  <span style={{ color: "#a29d9d", float: "left" }}>
                    {" "}
                    TrainingName
                  </span>

                  <span className="underline" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
