import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import $ from "jquery";
import CloseIcon from "@material-ui/icons/Close";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { ToastContainer, toast } from "react-toastify";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import Educationxl from "../../Assets/Files/Educationxl.xlsx";
import { BASE_URLs } from "../../Config/Base";
import "../../Css/excel.css";
var XLSX = require("xlsx");

export default class SkillMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edus: [],
      edusdrop: [],
      Addedus: [],
      Id: "",
      Specialization: "",
      Univ: [],
      InActive: [],
      Active: [],
      All: [],
      currentSheet: {},
      rows: [],
      cols: [],
      comlogo: [],
      Notification: [],
      PG: [],
      EduPG: [],
      UG: [],
      EduPhd: [],
      PHD: [],
      UGbtn: true,
      PGbtn: false,
      PHDbtn: false,
      PHDdrop: [],
      UGdrop: [],
      PGdrop: [],
      initialData: undefined,
      Degree: "UG",
      Filterpop: false,
      Filterpop1: false,
      Filterpop2: false,
      FilterData:"",
      showExcel: false,
    };

    this.initialState = this.state;
    // Multidelete
    this.Multidelete = this.Multidelete.bind(this);
  }

  changeHandler = (event) => {
    let fileObj = event.target.files[0];

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
      } else {
        this.setState({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  componentWillMount() {
    $(document).ready(function () {
      $("#add-row").click(function () {
        $("#add-row").hide();
      });

      $("#addBtn").click(function () {
        $("#add-row").show();
      });
    });

    $(document).ready(function () {
      $("#add-row").hide();
    });
    $(document).ready(function () {
      $("#myInput").click(function () {
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      });
    });

    $(document).ready(function () {
      $("#myInput1").click(function () {
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      });
    });

    $(document).ready(function () {
      $("#myInput2").click(function () {
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      });
    });
  }
  handleFormValidation() {
    const { SkillName, SkillDescription, Category } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "SkillName is required";
    }
    if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "SkillDescription is required";
    }

    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  GetEdudrop() {
    let url = `${BASE_URLs}/Education/getMasterEducationdrop`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          edusdrop: data,
        });
      });
  }

  deleteSkill(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}/Skill/Delete?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
        });
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };

  GetMasterUG() {
    let url = `${BASE_URLs}/Education/GetMasterUG`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          edus: data,
          UG: data,
        });
        var drop = this.RemoveDuplicate(data);

        this.setState({ UGdrop: drop });
      });
  }

  MasterUGFilter = (e) => {
    const edus = this.state.UG.filter(
      (item) =>
        item.courseName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.specialization.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ edus });
  };
  LetterUGFilter = (letter, e) => {
    e.preventDefault();
    const edus = this.state.UG.filter(
      (item) =>
        item.courseName.toLowerCase().includes(letter.toLowerCase()) ||
        item.specialization.toLowerCase().includes(letter.toLowerCase())
    );
    this.setState({ edus , showResults: true, FilterData: edus});
  };

  GetMasterPG() {
    let url = `${BASE_URLs}/Education/GetMasterPG`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          PG: data,
          EduPG: data,
        });
        var drop = this.RemoveDuplicate(data);

        this.setState({ PGdrop: drop });
      });
  }

  MasterPGFilter = (e) => {
    const PG = this.state.EduPG.filter(
      (item) =>
        item.courseName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.specialization.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ PG });
  };

  LetterPGFilter = (letter, e) => {
    e.preventDefault();
    const PG = this.state.EduPG.filter(
      (item) =>
        item.courseName.toLowerCase().includes(letter.toLowerCase()) ||
        item.specialization.toLowerCase().includes(letter.toLowerCase())
    );
    this.setState({ PG , showResults: true, FilterData: PG});
  };

  GetMasterPHD() {
    let url = `${BASE_URLs}/Education/GetMasterPHD`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          PHD: data,
          EduPhd: data,
        });
        var drop = this.RemoveDuplicate(data);

        this.setState({ PHDdrop: drop });
      });
  }

  MasterPHDFilter = (e) => {
    const PHD = this.state.EduPhd.filter(
      (item) =>
        item.courseName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.specialization.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ PHD });
  };
  LetterPHDFilter = (letter, e) => {
    e.preventDefault();
    const PHD = this.state.EduPhd.filter(
      (item) =>
        item.courseName.toLowerCase().includes(letter.toLowerCase()) ||
        item.specialization.toLowerCase().includes(letter.toLowerCase())
    );
    this.setState({ PHD , showResults: true, FilterData: PHD});
  };
  componentDidMount() {
    this.GetMasterUG();
    this.GetMasterPG();
    this.GetMasterPHD();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    this.setState({ letters: alphabet });
  }

  RemoveDuplicate(array) {
    var newArray = [];
    var uniqueObject = {};
    for (var i in array) {
      let objTitle = array[i]["courseName"];
      uniqueObject[objTitle] = array[i];
    }
    for (var i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    return newArray;
  }

  myFunction() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("add-row-Ugtable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }
  myFunction1() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput1");
    filter = input.value.toUpperCase();
    table = document.getElementById("add-row-Pgtable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  myFunction2() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput2");
    filter = input.value.toUpperCase();
    table = document.getElementById("add-row-Phdtable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      '#tablepadding input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ; margin-top:30px;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top:30px;"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;

      if (document.getElementById("tablepadding").checked) {
        msg.innerHTML = "<span> " + count + " Education selected </span> ";
      } else {
        msg.innerHTML =
          "<span> " + checkboxes.length + " Education selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }

  checkAll(event) {
    var checkboxes = document.getElementsByName("UgID");
    let count = checkboxes.length;
    if (event.target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;margin-top:30px ");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);  margin-top: 30px;"

          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Education selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }
  checkAllPG(event) {
    var checkboxes = document.getElementsByName("PgID");
    let count = checkboxes.length;
    if (event.target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;margin-top: 30px;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Education selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  checkAllPHD(event) {
    var checkboxes = document.getElementsByName("PhdID");
    let count = checkboxes.length;
    if (event.target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;margin-top: 30px;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Education selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  Multidelete(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    fetch(`${BASE_URLs}/Education/Multidelete`, options)
      .then((response) => response.json())
      .then((data) => {
        this.GetMasterUG();
        this.GetMasterPG();
        this.GetMasterPHD();
        toast.success(data.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    document
      .getElementById("delete")
      .setAttribute("style", "visibility: hidden;");
    document
      .getElementById("deletecount")
      .setAttribute("style", "visibility: hidden;");
    var checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );

    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  }

  EditUG(Id, SName, desc) {
    var page = {
      pathname: "/EditMasterUG",
      state: {
        Id: Id,
        SName: SName,
        desc: desc,
      },
    };
    this.props.history.push(page);
  }

  EditPG(Id, SName, desc) {
    var page = {
      pathname: "/EditMasterPG",
      state: {
        Id: Id,
        SName: SName,
        desc: desc,
      },
    };
    this.props.history.push(page);
  }

  EditPHD(Id, SName, desc) {
    var page = {
      pathname: "/EditMasterphd",
      state: {
        Id: Id,
        SName: SName,
        desc: desc,
      },
    };
    this.props.history.push(page);
  }

  handleUpload = (event) => {
    this.setState({ showExcel: true });

    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document.getElementById("delete").setAttribute("style", "visibility: hidden;");
        document.getElementById("deletecount").setAttribute("style", "visibility: hidden;");
      }
    }

    // Get the uploaded file
    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx|\.xlsm)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);

    // Check if the file is allowed
    if (isAllowed) {
      // Read the file using FileReader
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        // Check if the parsed data is empty
        if (parsedData.length === 0) {
          toast.error("The uploaded Excel file is empty.",{ autoClose: 3200 });
          this.setState({ initialData: [], showExcel: false });
        } else {
          this.setState({ initialData: parsedData });
        }
      };
      reader.onerror = (error) => {
        // console.error("Error reading file:", error);
      };
      reader.readAsBinaryString(files[0]);

      return true; // Indicate successful file upload
    } else {
      alert("Invalid File Type.");
      return false; // Indicate failed file upload
    }
  };
  
  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}/Education/ImportEducation`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert("0  Records  inserted ");
          }
          if (resp === 0) {
            alert("Records added Successfully");
          } else {
            alert(+resp + " Records not inserted ");
          }
          this.GetMasterUG();
          this.GetMasterPG();
          this.GetMasterPHD();
          this.setState({ currentSheet: this.state.initialData });
          this.setState({ initialData: undefined });
       
        });
      var file = document.getElementById("files");
      var emptyFile = document.createElement("input");
      emptyFile.type = "file";
      file.files = emptyFile.files;
    } catch (error) {}
  };

  setFocus = () => {
    this.setState(
      (prevState) => ({
        Filterpop: !prevState.Filterpop,
      }),
      () => {
        if (this.state.Filterpop) {
          this.searchForDiscRef.current.focus();
        }
      }
    );
  };

  cancel = () => {
    var inputElement = document.getElementById("files");
    inputElement.value = "";

    this.setState({ showExcel: false });
  };

  render() {
    var username = JSON.parse(localStorage.uname);
    const {showExcel, edus, EduPG, PG, PHD, UG, EduPhd, UGdrop, PGdrop, PHDdrop ,Filterpop ,Filterpop1, Filterpop2, FilterData } =
      this.state;
    var UGfilename = `O_${username}_A_UG`;
    var PGfilename = `O_${username}_A_PG`;
    var PHDfilename = `O_${username}_A_PHD`;
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <Link onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </Link>{" "}
                          </div>
                          <h4 className="title">Education</h4>
                        </div>
                        <div className="card-header-right"></div>
                        
                          <div className="card-block">
                          <form onSubmit={this.handleSubmit}>
                            <div className="row">
                              <div className="col btnalign">
                                <ul className="menu" style={{paddingLeft:"revert"}}>
                                  <li
                                    className="dropdown dropdown-1"
                                    style={{ height: "40px", width: "200px",color:"#5035ce",backgroundColor:"white",fontSize:"15px",padding:"10px 30px" }}
                                  >
                                    Education Menus
                                    <ul className="dropdown_menu dropdown_menu-1">
                                      <li className="dropdown_item-1">
                                        <Link
                                          to="AddEducation"
                                          type="button"
                                          style={{
                                            width: "125px",
                                            background: "white",
                                          }}
                                          id="addRow"
                                          className="btn"
                                        >
                                          Add{" "}
                                        </Link>
                                      </li>
                                      <input
                                        type="hidden"
                                        name="Degree"
                                        value={this.state.Degree}
                                      />
                                      <li className="dropdown_item-2">
                                        <select
                                          onChange={this.MasterUGFilter}
                                          onFocus={(e) => {
                                            this.setState({
                                              UGbtn: true,
                                              PGbtn: false,
                                              PHDbtn: false,
                                              Degree: "UG",
                                            });
                                          }}
                                          id="myInput"
                                          className=" btn"
                                          style={{
                                            borderColor: "white",
                                            background: "white",
                                            height: "42px",
                                            padding: "inherit",
                                            textAlign: "left",
                                            width: "125px",
                                          }}
                                        >
                                          <option
                                            className="drop-options"
                                            value=""
                                          >
                                            UG
                                          </option>
                                          {UGdrop.map((edu) => (
                                            <option
                                              className="drop-options"
                                              value={edu.courseName}
                                            >
                                              {edu.courseName}
                                            </option>
                                          ))}
                                        </select>
                                      </li>

                                      <li className="dropdown_item-3">
                                        <select
                                          name="SkillName"
                                          className="btn"
                                          onFocus={(e) => {
                                            this.setState({
                                              UGbtn: false,
                                              PGbtn: true,
                                              PHDbtn: false,
                                              Degree: "PG",
                                            });
                                          }}
                                          id="myInput1"
                                          onChange={this.MasterPGFilter}
                                          style={{
                                            borderColor: "white",
                                            background: "white",
                                            height: "42px",
                                            padding: "inherit",
                                            textAlign: "left",
                                            width: "125px",
                                          }}
                                        >
                                          <option
                                            className="drop-options"
                                            value=""
                                          >
                                            PG
                                          </option>
                                          {PGdrop.map((PG) => (
                                            <option
                                              className="drop-options"
                                              value={PG.courseName}
                                            >
                                              {PG.courseName}
                                            </option>
                                          ))}
                                        </select>
                                      </li>

                                      <li className="dropdown_item-4">
                                        <select
                                          name="SkillName"
                                          className="btn"
                                          onFocus={(e) => {
                                            this.setState({
                                              UGbtn: false,
                                              PGbtn: false,
                                              PHDbtn: true,
                                              Degree: "PHD",
                                            });
                                          }}
                                          id="myInput2"
                                          onChange={this.MasterPHDFilter}
                                          style={{
                                            borderColor: "white",
                                            background: "white",
                                            height: "42px",
                                            padding: "inherit",
                                            textAlign: "left",
                                            width: "125px",
                                          }}
                                        >
                                          <option
                                            className="drop-options"
                                            value=""
                                          >
                                            PHD
                                          </option>
                                          {PHDdrop.map((PHD) => (
                                            <option
                                              className="drop-options"
                                              value={PHD.courseName}
                                            >
                                              {PHD.courseName}
                                            </option>
                                          ))}
                                        </select>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                </div>

                              {this.state.UGbtn ? (
                                <>
                                  <div className="col btnalign">
                                    <label
                                      htmlFor="files"
                                      className="btn"
                                      id="addBtn"
                                      name="ImportEducations"
                                    >
                                      Import From Excel 
                                    </label>{" "}
                                    &nbsp;&nbsp;
                                    <input
                                      id="files"
                                      type="file"
                                      accept=".xlsx"
                                      style={{ display: "none" }}
                                      onChange={this.handleUpload}
                                      name="ImportEducations"
                                    />{" "}
                                  </div>
                                  <div className="col btnalign">
                                    <ReactHTMLTableToExcel
                                      className="btn"
                                      table="add-row-Ugtable"
                                      filename={UGfilename}
                                      sheet="Sheet"
                                      buttonText="Export To Excel"
                                      id="exportxlUG"
                                      style={{
                                        display: "none",
                                        color: "white",
                                      }}
                                    />
                                  </div>
                                </>
                              ) : null}

                              {this.state.PGbtn ? (
                                <>
                                  <div className="col btnalign">
                                    <label
                                      htmlFor="files"
                                      className="btn"
                                      id="addBtn"
                                      name="ImportEducations"
                                    >
                                      Import From Excel
                                    </label>{" "}
                                    &nbsp;&nbsp;
                                    <input
                                      id="files"
                                      type="file"
                                      accept=".xlsx"
                                      style={{ display: "none" }}
                                      onChange={this.handleUpload}
                                      name="ImportEducations"
                                    />{" "}
                                  </div>
                                  <div className="col btnalign">
                                    <ReactHTMLTableToExcel
                                      className="btn"
                                      table="add-row-Pgtable"
                                      filename={PGfilename}
                                      sheet="Sheet"
                                      buttonText="Export To Excel"
                                      id="exportxlPG"
                                      style={{
                                        height: "42px",
                                        display: "none",
                                        color: "white",
                                      }}
                                    />
                                  </div>
                                </>
                              ) : null}

                              {this.state.PHDbtn ? (
                                <>
                                  <div className="col btnalign">
                                    <label
                                      htmlFor="files"
                                      className="btn"
                                      id="addBtn"
                                      name="ImportEducations"
                                    >
                                      Import From Excel
                                    </label>{" "}
                                    &nbsp;&nbsp;
                                    <input
                                      id="files"
                                      type="file"
                                      accept=".xlsx"
                                      style={{ display: "none" }}
                                      onChange={this.handleUpload}
                                      name="ImportEducations"
                                    />{" "}
                                  </div>
                                  <div className="col btnalign">
                                    <ReactHTMLTableToExcel
                                      className="btn"
                                      table="add-row-Phdtable"
                                      filename={PHDfilename}
                                      sheet="Sheet"
                                      buttonText="Export To Excel"
                                      id="exportxlPHD"
                                      style={{
                                        display: "none",
                                        color: "white",
                                        height: "42px",
                                      }}
                                    />
                                  </div>
                                </>
                              ) : null}

                              <div className="col btnalign">
                                <a href={Educationxl} className="btn">
                                <i
                                    title="Download Template"
                                    className="icofont icofont-download"
                                  ></i>{" "}
                                Download a template{" "}
                                </a>
                              </div>

                              <div
                                className="col btnalign"
                                style={{
                                  textAlign: "center",
                                  cursor: "pointer",
                                  visibility:"hidden",
                                }}
                              >
                                {/* Just for design */}
                              </div>

                             
                                {this.state.UGbtn ? (
                                   <div className="col btnalign">
                                  <input
                                    id="searchug"
                                    className="SearchEdu"
                                    placeholder=" Search"
                                    onKeyUp={this.MasterUGFilter}
                                    style={{ float: "right" }}
                                    onClick={() => {
                                      this.setState({
                                        // Filterpop: true,
                                        Filterpop: !this.state.Filterpop,
                                      });
                                    }}
                                    readOnly
                                  />
                                   </div>
                                ) : null}
                               
                                
                                {this.state.PGbtn ? (
                                  <div className="col btnalign">
                                  <input
                                    id="searchpg"
                                    className="SearchEdu"
                                    placeholder=" Search"
                                    onKeyUp={this.MasterPGFilter}
                                    style={{ float: "right" }}
                                    onClick={() => {
                                      this.setState({
                                        // Filterpop: true,
                                        Filterpop1: !this.state.Filterpop1,
                                      });
                                    }}
                                    readOnly
                                  />
                                   </div>
                                ) : null}
                               
                                <div className="col btnalign">
                                {this.state.PHDbtn ? (
                                  <input
                                    id="searchphd"
                                    className="SearchEdu"
                                    placeholder=" Search "
                                    onKeyUp={this.MasterPHDFilter}
                                    style={{ float: "right" }}
                                    onClick={() => {
                                      this.setState({
                                        // Filterpop: true,
                                        Filterpop2: !this.state.Filterpop2,
                                      });
                                    }}
                                    readOnly
                                  />
                                ) : null}
                              </div>
                            </div>

                            <center>
                            {showExcel && this.state.initialData !== undefined && (
                              <table className="table" id="tableDesign">
                              <thead >
                                <tr>
                                  {Object.keys(this.state.initialData[0]).map((key) => (
                                    <th key={key} id="team1design">{key}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.initialData.map((row, index) => (
                                  <tr key={index}>
                                    {Object.values(row).map((value, index) => (
                                      <td key={index} id="teamdesign">{value}</td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            )}
                            </center>
                            {showExcel && this.state.initialData !== undefined && (
                              <div className="savebtndiv">
                                <button
                                  className="btn"
                                  id=""
                                  type="submit"
                                  style={{ width: "150px" }}
                                >
                                  Save
                                </button>
                                <span
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px" }}
                                  onClick={this.cancel}
                                >
                                  Cancel
                                </span>
                              </div>
                            )}
                            <OutTable
                              data={this.state.rows}
                              columns={this.state.cols}
                              tableClassName="ExcelTable2007"
                              tableHeaderRowclassName="heading"
                            />

                            {/* //// */}
                            {/* <div className="row">
                              <div className="col-lg-4"></div>
                              <div className="col-lg-4">
                                <button
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px", display: "none" }}
                                >
                                  Save
                                </button>
                              </div>
                              <div className="col-lg-4"></div>
                            </div> */}
                            </form>
                          </div>
                        
                        <br />
                        <div className="card-block2">
                          <div
                            id="selectalldiv"
                            style={{ visibility: "hidden" }}
                          ></div>
                          <form
                            autoComplete="on"
                            encType="multipart/form-data"
                            onSubmit={this.Multidelete}
                            style={{marginTop:"-40px"}}
                          >
                            <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: "hidden" }}
                            >
                              <i
                                className="icofont icofont-delete-alt"
                                style={{ color: "darkgray" }}
                              ></i>
                            </button>
                            <span
                              id="deletecount"
                              className="multitrash"
                              style={{ visibility: "hidden", fontSize: "17px" }}
                            ></span>
                            <br />
                            <div
                              id="base-style_filter"
                              className="dataTables_filter"
                            >
                              <div
                                className="dt-responsive table-responsive"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {this.state.UGbtn ? (
                                  <table
                                    id="add-row-Ugtable"
                                    className="table nowrap MasterUgGrid"
                                  >
                                    <input
                                      name="status"
                                      value="ug"
                                      style={{ display: "none" }}
                                    />
                                    <thead id="tablepaddingA">
                                      <tr>
                                        <th>
                                          <input
                                            type="checkbox"
                                            id="selectall"
                                            name="ugselect"
                                            onClick={this.checkAll}
                                          />
                                        </th>
                                        <th>Course Name</th>
                                        <th>Specialization</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody id="tablepadding">
                                    {FilterData.length === 0 && edus.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                      {edus.map((edu) => (
                                        <tr>
                                          <td>
                                            <input
                                              defaultValue={edu.id}
                                              onClick={this.handlecheckbox}
                                              name="UgID"
                                              type="checkbox"
                                            />
                                          </td>
                                          <td>{edu.courseName}</td>
                                          <td>{edu.specialization}</td>
                                          <td>
                                            {/* <img alt="edit" onClick={()=>this.EditUG(edu.id,edu.courseName,edu.specialization)} src="https://img.icons8.com/android/22/000000/pencil.png"/> */}
                                            <button
                                              className="button-30 sk-edit"
                                              type="button"
                                              onClick={() =>
                                                this.EditUG(
                                                  edu.id,
                                                  edu.courseName,
                                                  edu.specialization
                                                )
                                              }
                                              //  src="https://img.icons8.com/android/22/000000/pencil.png"/
                                            >
                                              <i
                                                className="fa fa-edit"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : null}
                                {this.state.PGbtn ? (
                                  <table
                                    id="add-row-Pgtable"
                                    className="table nowrap "
                                  >
                                    <input
                                      name="status"
                                      value="pg"
                                      style={{ display: "none" }}
                                    />

                                    <thead className="MasterPgGrid" id="tablepaddingA">
                                      <tr>
                                        <th>
                                          <input
                                            type="checkbox"
                                            style={{ textAlign: "left" }}
                                            name="pgselect"
                                            id="selectall"
                                            onClick={this.checkAllPG}
                                          />{" "}
                                        </th>

                                        <th>Course Name</th>
                                        <th>Specialization</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody id="tablepadding">
                                    {FilterData.length === 0 && PG.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                      {PG.map((PG) => (
                                        <tr>
                                          {/* <td> <input type="checkbox" name="AddSkills" onChange={this.handlecheckbox} defaultValue= {PG.id}/></td> */}
                                          <td>
                                            <input
                                              defaultValue={PG.id}
                                              onClick={this.handlecheckbox}
                                              name="PgID"
                                              type="checkbox"
                                            />
                                          </td>
                                          <td>{PG.courseName}</td>
                                          <td>{PG.specialization}</td>
                                          <td>
                                            {/* <img alt="edit" onClick={()=>this.EditPG(PG.id,PG.courseName,PG.specialization)} src="https://img.icons8.com/android/22/000000/pencil.png"/> */}
                                            <button
                                              className="button-30 sk-edit"
                                              type="button"
                                              onClick={() =>
                                                this.EditPG(
                                                  PG.id,
                                                  PG.courseName,
                                                  PG.specialization
                                                )
                                              }
                                              //  src="https://img.icons8.com/android/22/000000/pencil.png"/
                                            >
                                              <i
                                                className="fa fa-edit"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                          </td>{" "}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : null}
                                {this.state.PHDbtn ? (
                                  <table
                                    id="add-row-Phdtable"
                                    className="table nowrap MasterPHDGrid"
                                  >
                                    <input
                                      name="status"
                                      value="phd"
                                      style={{ display: "none" }}
                                    />

                                    <thead id="tablepaddingA">
                                      <tr>
                                        <th>
                                          <input
                                            type="checkbox"
                                            id="selectall"
                                            name="phdselect"
                                            onClick={this.checkAllPHD}
                                          />
                                        </th>
                                        <th>Course Name</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody id="tablepadding">
                                    {FilterData.length === 0 && PHD.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}

                                      {PHD.map((PHD) => (
                                        <tr>
                                          {/* <td> <input type="checkbox" name="AddSkills" onChange={this.handlecheckbox} defaultValue= {PHD.id}/></td> */}
                                          <td>
                                            <input
                                              name="PhdID"
                                              onClick={this.handlecheckbox}
                                              defaultValue={PHD.id}
                                              type="checkbox"
                                            />
                                          </td>
                                          <td>{PHD.courseName}</td>
                                          <td>
                                            {/* <img alt="edit" onClick={()=>this.EditPHD(PHD.id,PHD.courseName,PHD.specialization)} src="https://img.icons8.com/android/22/000000/pencil.png"/> */}
                                            <button
                                              className="button-30 sk-edit"
                                              type="button"
                                              onClick={() =>
                                                this.EditPHD(
                                                  PHD.id,
                                                  PHD.courseName,
                                                  PHD.specialization
                                                )
                                              }
                                              //  src="https://img.icons8.com/android/22/000000/pencil.png"/
                                            >
                                              <i
                                                className="fa fa-edit"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                          </td>{" "}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : null}
                              </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {Filterpop && (
                                <div className="popSearchTeam">
                                  <div className="popSearchinnernormalTeam">
                                    <div
                                      className="popSearchinnertitle"
                                      id="popSearchinnernormal"
                                    >
                                      <button
                                        id="close"
                                        style={{
                                          float: "right",
                                          backgroundColor: "#a0a3a305",
                                          color: "#797070",
                                          border: "1px solid #a3a5a505",
                                          marginTop: "-20px",
                                          marginRight: "-20px",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            Filterpop: false,
                                          });
                                          this.GetMasterUG();
                                        }}
                                      >
                                        <CloseIcon />
                                      </button>

                                      <div className="checkboxes">
                                        <i
                                          className="fa fa-search"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "20px",
                                            color: "gray",
                                            marginLeft: "-1%",
                                            position: "absolute",
                                            marginTop: "0.5%",
                                          }}
                                        ></i>
                                        <input
                                          className="Searchbox"
                                          placeholder="Search"
                                          onKeyUp={this.MasterUGFilter}
                                          style={{
                                            float: "right",
                                            width: "80%",
                                          }}
                                        />
                                        <br></br>
                                        {this.state.letters.map(
                                          (letter, index) => (
                                            <button
                                              id="searchfordisc"
                                              className="filter"
                                              key={index}
                                              onClick={(event) =>
                                                this.LetterUGFilter(
                                                  letter,
                                                  event
                                                )
                                              }
                                            >
                                              {letter}
                                            </button>
                                          )
                                        )}
                                        <br></br>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {Filterpop1 && (
                                <div className="popSearchTeam">
                                  <div className="popSearchinnernormalTeam">
                                    <div
                                      className="popSearchinnertitle"
                                      id="popSearchinnernormal"
                                    >
                                      <button
                                        id="close"
                                        style={{
                                          float: "right",
                                          backgroundColor: "#a0a3a305",
                                          color: "#797070",
                                          border: "1px solid #a3a5a505",
                                          marginTop: "-20px",
                                          marginRight: "-20px",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            Filterpop1: false,
                                          });
                                          this.GetMasterPG();
                                        }}
                                      >
                                        <CloseIcon />
                                      </button>

                                      <div className="checkboxes">
                                        <i
                                          className="fa fa-search"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "20px",
                                            color: "gray",
                                            marginLeft: "-1%",
                                            position: "absolute",
                                            marginTop: "0.5%",
                                          }}
                                        ></i>
                                        <input
                                          className="Searchbox"
                                          placeholder="Search"
                                          onKeyUp={this.MasterPGFilter}
                                          style={{
                                            float: "right",
                                            width: "80%",
                                          }}
                                        />
                                        <br></br>
                                        {this.state.letters.map(
                                          (letter, index) => (
                                            <button
                                              id="searchfordisc"
                                              className="filter"
                                              key={index}
                                              onClick={(event) =>
                                                this.LetterPGFilter(
                                                  letter,
                                                  event
                                                )
                                              }
                                            >
                                              {letter}
                                            </button>
                                          )
                                        )}
                                        <br></br>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {Filterpop2 && (
                                <div className="popSearchTeam">
                                  <div className="popSearchinnernormalTeam">
                                    <div
                                      className="popSearchinnertitle"
                                      id="popSearchinnernormal"
                                    >
                                      <button
                                        id="close"
                                        style={{
                                          float: "right",
                                          backgroundColor: "#a0a3a305",
                                          color: "#797070",
                                          border: "1px solid #a3a5a505",
                                          marginTop: "-20px",
                                          marginRight: "-20px",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            Filterpop2: false,
                                          });
                                          this.GetMasterPHD();
                                        }}
                                      >
                                        <CloseIcon />
                                      </button>

                                      <div className="checkboxes">
                                        <i
                                          className="fa fa-search"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "20px",
                                            color: "gray",
                                            marginLeft: "-1%",
                                            position: "absolute",
                                            marginTop: "0.5%",
                                          }}
                                        ></i>
                                        <input
                                          className="Searchbox"
                                          placeholder="Search"
                                          onKeyUp={this.MasterPHDFilter}
                                          style={{
                                            float: "right",
                                            width: "80%",
                                          }}
                                        />
                                        <br></br>
                                        {this.state.letters.map(
                                          (letter, index) => (
                                            <button
                                              id="searchfordisc"
                                              className="filter"
                                              key={index}
                                              onClick={(event) =>
                                                this.LetterPHDFilter(
                                                  letter,
                                                  event
                                                )
                                              }
                                            >
                                              {letter}
                                            </button>
                                          )
                                        )}
                                        <br></br>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
