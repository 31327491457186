import React, { useState, useEffect } from "react";
import SideBar from "../../Common/Sidebar";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base";
function TrainingsDocuments(props) {
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [department, setDepartment] = useState([]);
  const [relatedRecords, setRelatedRecords] = useState([]);

  //  const handleChange = (e) => {
  //     setMessage(e.target.value);
  //   };

  //   const openPopup = () => {
  //     setIsOpen(true);
  //   };

  //   const closePopup = () => {
  //     setIsOpen(false);
  //   };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    let url = `${BASE_URLs}/Learning/GetLearningTable`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOptions(data);
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex(
              (item) => item.trainingitem === value.trainingitem
            ) === index
          );
        });
        setDepartment(filteredData);
        // // console.log("TrainingId",data[1].training_Id)
        // console.log("check", data);
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };

  const GetDocumentUsingDepartment = async () => {
    try {
      const response = await fetch("https://api.example.com/data");
      if (response.ok) {
        const jsonData = await response.json();
        setRelatedRecords(jsonData);
      } else {
        throw new Error("Error while fetching data");
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const handleSubmit = () => {
    var requestDocument = document.getElementById("CourseName").value;
    // var image = document.getElementById('file-input').value;

    const data = {
      documentsName: requestDocument,
    };
    axios({
      method: "POST",

      url: `${BASE_URLs}/Trainer/AddRequestDocument`,

      data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response.data);

        if (response.data.status === "Success") {
          alert("User Registered Successfully");
        } else if (response.data.status === "UserError") {
          alert("User Already Exist !");
        } else if (response.data.status === "Error") {
          // toast.error("User creation failed !", {

          //   position: "top-center",

          //   autoClose: 3200,

          //   hideProgressBar: false,

          //   closeOnClick: true,

          //   pauseOnHover: true,

          //   draggable: true,

          //   progress: undefined,

          //   className: 'toast-message'

          //   });
          alert("User Creation Failed");
          // setTimeout(() => {

          // }, 5000);
        }
      })

      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div>
      <SideBar active={window.location.pathname} />

      <div className="pcoded-content">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-header">
                <div className="page-header-title"></div>
              </div>
              <div className="page-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="glowBox">
                        <div style={{ float: "left" }}>
                          <a href onClick={() => window.history.back()}>
                            {" "}
                            <i
                              className="icofont icofont-arrow-left"
                              style={{ fontSize: "45px", color: "white" }}
                            />
                          </a>
                        </div>
                        <h4 style={{ marginRight: "4%" }} className="title">
                          {" "}
                          Training Documents
                        </h4>
                      </div>
                      <div className="card-header-right"></div>
                      <div className="card-block2">
                        <form autoComplete="off" encType="multipart/form-data">
                          <br />
                          <br />
                          <div className="container">
                            <div className="col-lg-12">
                              <div className="dt-responsive table-responsive">
                                {/* <button type="button"  className="but " onClick={openPopup} style={{ height: '39.5px', width: '150px', marginTop: "-0.3%" }}>Request Document</button>&nbsp;  &nbsp;  &nbsp; */}

                                <table
                                  id="add-row-table3"
                                  className="table nowrap"
                                >
                                  <thead>
                                    <tr>
                                      <th>Training Topic </th>
                                      <th> </th>{" "}
                                      {/* <th>Office Document </th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {options.map((item, index) => (
                                      <tr key={index}>
                                        <td> {item.trainingitem}</td>
                                        <td>
                                          <>
                                            &nbsp;&nbsp;{" "}
                                            <button
                                              className="button-30"
                                              type="button"
                                              style={{
                                                color: "rgb(94, 217, 135)",
                                              }}
                                            >
                                              &nbsp;{" "}
                                              <Link
                                                to={{
                                                  pathname:
                                                    "/TrainerTrainingDocuments",
                                                  state: {
                                                    trainerid: item.training_Id,
                                                  },
                                                }}
                                              >
                                                {" "}
                                                <i className="icofont icofont-info"></i>
                                              </Link>
                                            </button>{" "}
                                            &nbsp;&nbsp;
                                          </>
                                        </td>
                                        {/* <td>
                                  <a id="if" title="Iframe Example" style={{ width: '200px', height: '200px' }}></a>
                                </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <br />
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="styleSelector"></div>
      </div>

      {/* {isOpen && (
        <div className="pop">
          <div className="popinnernormal">
            <form autoComplete="off" encType="multipart/form-data" onSubmit={handleSubmit}>
              <button
                style={{ float: 'right', backgroundColor: '#a0a3a305', color: '#797070', border: '1px solid #a3a5a505' }}
                onClick={closePopup}
              >
                <CloseIcon />
              </button>

              <div className="row" style={{ padding: '8px' }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    className="form"
                    maxLength="50"
                    id="CourseName"
                    name="CourseName"
                    type="text"
                    value={message}
                    onChange={handleChange}
                  />
                  <span style={{ color: '#a29d9d', float: 'left' }}>Requesting Document</span>
                  <div style={{ color: 'red', float: 'left' }}> </div> */}
      {/* <input type="hidden" name="otherdepartment" className="form" id="departmenttext" /> */}
      {/* <div style={{ color: 'red', float: 'left' }}></div>
                  <span className="underline" />
                </div>
                <div className="col-sm-4"></div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: 'center' }}>
                  <button
                    type="submit"
                    className="but"
                    style={{ height: '39.5px', width: '130px', marginTop: '-0.3%' }}
                  >
                    Request to Admin
                  </button>
                </div>
              </div>
            </form>
            <br />
          </div>
        </div>
      )} */}
    </div>
  );
}
export default TrainingsDocuments;
