import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import CommonSuccess from "../../Common/CommonSuccess";
import { Link } from "react-router-dom";
import AddDepartmentpop from "../../Common/AddDepartmentpop";
import AddDesignationModel from "../../Common/AddDesignationModel";
import AddManagerPop from "../../Common/AddManagerPop";
import AddLocationModel from "../../Common/AddLocationModel";
import "../../Css/Addskills.css";
import Multiselect from "multiselect-react-dropdown";
import CloseIcon from "@material-ui/icons/Close";
import Filterpopstatustopic from "../../Common/Filterpopstatustopic";
import { BASE_URLs } from "../../Config/Base"
const options = [
  { value: "islamabad", label: "Islamabad" },
  { value: "lahore", label: "Lahore" },
  { value: "karachi", label: "Karachi" },
];
export default class AddTrainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Active: [],
      InActive: [],
      FirstName: "",
      LastName: "",
      Email: "",
      Mobile: "",
      Department: "",
      Designation: "",
      RoleName: "",
      formErrors: {},
      Notification: [],
      Departmentdrop: [],
      message: "",
      status: "",
      DepartModel: false,
      DesignationModel: false,
      ManagerModel: false,
      LocationModel: false,
      selectedOptions: [],
      selectedCheckboxValues: [],
      optionList: [
        { value: "Winner", label: "Winner" },
        { value: "AboutUs", label: "AboutUs" },
        { value: "Terms&Conditions", label: "Terms&Conditions" },
        { value: "PrivacyPolicy", label: "PrivacyPolicy" },
        { value: "Unfollow", label: "Unfollow" },
        { value: "Report", label: "Report" },
        { value: "Transaction", label: "Transaction" },
      ],
      Teams: [],
      ListOptions: [],
      Traineroption: [],
      selectedValues: [],
      department: [],
      selectedDepartment: "",
      trainerName: [],
      trainerData: [],
      FinaltrainerData: [],
      selectedTrainerName: "",
      trainerRole: "",
      selectedRole: "",
      trainerID: "",
      currentDate: null,
      AllRoles: [],
      isOpen: false,
      Trainers: [],
      Filterpopstatusadd: false,
      Search: [],
      design: "",
      dept: "",
      FinaltrainerData1: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangedept = this.handleChangedept.bind(this);
    this.SelectDepartment = this.SelectDepartment.bind(this);
  }
  GetDate() {
    const today = new Date();
    const formattedDate = today.toISOString().slice(0, 10);
    this.setState({ currentDate: formattedDate });
  }
  GetDepartment() {
    let url = `${BASE_URLs}/Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }
  handleSelect = (selectedList, selectedItem) => {
    this.setState({ selectedValues: selectedList });
  };

  Team() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });
        this.setState({
          department: filteredData,
          //selectedDepartment: filteredData[0].department,
        });
        this.fetchItems(data[0].department);
        // console.log("TeamsData", data);
        // console.log("TeamsDisplayName", data[0].displayName);
        // console.log("Department", data[0].department);
      });
  }

  fetchItems(departmentValue) {
    const optionVal = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(
      `${BASE_URLs}/Team/GetTeambyDepartment?Department=${departmentValue}`,
      optionVal
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            trainerName: data,
            // selectedTrainerName: data.firstName, // Select the first item by default
            trainerID: data[0].id,
            // trainerData: data,
          });
          // this.setState({
          //   FinaltrainerData: [],
          // });
        } else {
          this.setState({
            trainerName: [],
          });
        }
        // console.log("==", data);
      })
      .catch((error) => console.error(error));
  }
  fetchRole(TrainerName, TrainerID) {
    // console.log("cccrrrtt", TrainerID, TrainerName);
    const optionVal = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(
      `${BASE_URLs}/Team/GetRolebyTrainerName?Firstname=${TrainerName}`,
      optionVal
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("-----------------------------------", data);
        this.setState({
          // trainerName: data,
          // selectedRole: data,
          trainerRole: data[0].role,
        });

        // console.log("hhhhhhhhhhhhhhhhhh", data[0]);
        // console.log("TrainerRole", data[0].role);
        // console.log("SelectedRole", this.state.selectedrole.role);
      })
      .catch((error) => console.error(error));
  }

  handleChangedept = (event) => {
    const department = event.target.value;
    this.setState({ SelectedDepartment: event.target.value });

    // this.fetchItems(department);
    // console.log("deptselectsdf", department);

    if (event.target.value == "") {
      this.setState({ FinaltrainerData: this.state.FinaltrainerData1 });
    } else {
      let final = this.state.trainerName.filter((e) => {
        if (e.department === department) {
          return e;
        }
      });
      // this.setState({ trainerData: final });
      this.setState({ FinaltrainerData: final });
      // console.log("d department ", final);
    }
  };
  handleChangedesignation = (event) => {
    const designation = event.target.value;
    //  this.setState({ SelectedDepartment: event.target.value });

    // this.fetchItems(department);   FinaltrainerData1
    if (event.target.value == "") {
      this.setState({ FinaltrainerData: this.state.FinaltrainerData1 });
    } else {
      let final = this.state.FinaltrainerData1.filter((e) => {
        if (
          // e.department === this.state.SelectedDepartment &&
          e.designation === designation
        ) {
          return e;
        }
      });
      // this.setState({ trainerData: final });
      this.setState({ FinaltrainerData: final });
      // console.log("designation ", final);
    }
  };
  handleChange = (event) => {
    // console.log("dept", event.target.value);
    this.setState({ dept: event.target.value });
    // console.log("Clicked", event.target.dept);
    // console.log("value", this.state.Id);

    var dd = this.state.Trainers.forEach((e) => {
      var split = e.userId;
      // [5,''vjvd487348543, 'Arunachalam']
      //[]
      this.setState({ trainerid1: split });

      // // console.log("hi",split[1])
      // // console.log("hi",split[2])
      // data.append("FirstName",split[1]);
      // data.append("TrainerId",split[2]);
      // data.append("TrainingId",split[0]);
    });
    // console.log("deptt==", this.state.dept);
    // if (event.target.dept == 0) {
    //   // console.log("clicked");
    //   window.location.reload();
    // }
    // alert("Before");
    // this.DefaultMenu();

    // this.setState({
    //   catval: event.target.value
    // })
    let url = `${BASE_URLs}/Training/GetDeptbased?Department=${event.target.value}&UserId=${this.state.Id}`;
    // "https://api.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=0" +
    // // this.state.Sort.value +
    // //"&Sort=" +
    // //  this.state.Years.value +
    // "&CategoryID=" +
    // event.target.value;

    // let user = JSON.parse(localStorage.getItem("user"));
    // const accessToken = user;
    // // console.log(accessToken);

    //// console.log("hi");
    //alert("After");
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },

      //body:JSON.stringify(data)
    })
      // alert("After0")
      .then((response) => response.json())
      .then((data) => {
        //   this.DefaultMenu();
        //alert(data);

        this.setState({
          Search: data,
        });
        // alert("After1");
        //  this.DefaultMenu();
        // console.log("Filter", data);
        for (var i = 0; i < data.length; i++) {
          this.setState({ topic: data[i].trainingitem });
          // console.log("ropic", this.state.topic);
        }
        // // console.log(dets.profile_dateOfAnniversary);
      });
  };

  handleChangeDesign = (event) => {
    // console.log("design", event.target.value);
    //  this.setState({ design: event.target.value });

    var dd = this.state.Trainers.forEach((e) => {
      var split = e.userId;
      // [5,''vjvd487348543, 'Arunachalam']
      //[]
      this.setState({ trainerid1: split });

      // // console.log("hi",split[1])
      // // console.log("hi",split[2])
      // data.append("FirstName",split[1]);
      // data.append("TrainerId",split[2]);
      // data.append("TrainingId",split[0]);
    });
    // console.log("hi---------", this.state.Search.length);
    // if (event.target.dept == 0) {
    //   // console.log("clicked");
    //   window.location.reload();
    // }
    // alert("Before");
    // this.DefaultMenu();

    // this.setState({
    //   catval: event.target.value
    // })
    let url = `${BASE_URLs}/Training/Getdesignbased?Department=${this.state.dept}&Design=${event.target.value}&UserId=${this.state.Id}`;
    // "https://api.paropakari.com/api/GetProfile/Get_HomeCategoryFilter?id=0" +
    // // this.state.Sort.value +
    // //"&Sort=" +
    // //  this.state.Years.value +
    // "&CategoryID=" +
    // event.target.value;

    // let user = JSON.parse(localStorage.getItem("user"));
    // const accessToken = user;
    // console.log(url);

    //// console.log("hi");
    //alert("After");
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },

      //body:JSON.stringify(data)
    })
      // alert("After0")
      .then((response) => response.json())
      .then((data) => {
        //   this.DefaultMenu();
        //alert(data);

        this.setState({
          Search: data,
        });
        // alert("After1");
        //  this.DefaultMenu();
        // console.log("Filter", data);
        for (var i = 0; i < data.length; i++) {
          this.setState({ topic: data[i].trainingitem });
          // console.log("ropic", this.state.topic);
        }
        // // console.log(dets.profile_dateOfAnniversary);
      });
  };

  handleTrainerNameChange = (event) => {
    const name = event.target.value;
    //  this.setState({ SelectedDepartment: event.target.value });

    // this.fetchItems(department);
    // console.log("name ", name);
    // console.log("this.state.trainerName ", this.state.trainerName);

    let final = this.state.FinaltrainerData1.filter((item) =>
      item.firstName.toLowerCase().includes(name.toLowerCase())
    );
    this.setState({ FinaltrainerData: final });
    // console.log("designation ", final);

    // const trainerName = event.target.value;
    // // console.log("tname", trainerName);
    // let selected_id = this.state.trainerName.filter(
    //   (e) => e.firstName === trainerName.split("$$$")[0]
    // );
    // // console.log(selected_id[0].id);
    // this.setState({ selectedTrainerName: trainerName });
    // this.fetchRole(trainerName, selected_id[0].id);
  };
  componentDidMount() {
    this.GetDepartment();
    this.GetDate();
    this.Team();
    this.GetAllRole();
    this.GetTrainer();

    var role = JSON.parse(localStorage.Role);
    if (role.toString() === "Platform Admin") {
      document.getElementById("navright").setAttribute("style", " display:;");
      document.getElementById("mainorg").setAttribute("style", "  display:;");
    }
  }
  closePopup = () => {
    this.setState({ isOpen: false });
  };

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
    this.props.history.push("/TrainerSchedule");
  };
  SelectManager = (e) => {
    let dept = document.getElementById("Manager").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        ManagerModel: !prevState.ManagerModel,
      }));
      document.getElementById("Manager").value = "";
    }
  };
  SelectLocation = (e) => {
    let dept = document.getElementById("Locations").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        LocationModel: !prevState.LocationModel,
      }));
      document.getElementById("Locations").value = "";
    }
  };
  SelectDepartment = (e) => {
    let dept = document.getElementById("Department").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        DepartModel: !prevState.DepartModel,
      }));

      document.getElementById("Department").value = "";
      //this.props.history.push('/Department')
    }
  };
  SelectDesignation = (e) => {
    let dept = document.getElementById("Designation").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        DesignationModel: !prevState.DesignationModel,
      }));
      document.getElementById("Designation").value = "";
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    // var TrainerTopicID=document.getElementById('TrainerTopicID').value;

    // console.log("dddddddddd", this.state.selectedCheckboxValues);
    const data = new FormData(event.target);
    let dd = this.state.selectedCheckboxValues.forEach((e) => {
      let split = e.split("$$$");
      // [5,''vjvd487348543, 'Arunachalam']
      //[]
      // console.log("hi", split[1]);
      // console.log("hi", split[3]);
      // console.log("hi", split[4]);
      data.append("FirstName", split[1]);
      data.append("TrainerId", split[4]);
      data.append("TrainingTopicId", split[3]);
    });
    data.append("Trainer_UserID", this.state.Id);
    data.append("TrainerData", this.state.selectedCheckboxValues);
    for (const [key, iterator] of data.entries()) {
      // console.log(key, "rrrrr", iterator);
    }
    fetch(`${BASE_URLs}/Trainer/AddTrainer`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        this.setState({ status: resp.status });
        this.setState({ message: resp.message });
        this.SuccessModel();
      });
  };
  checkChangeDesignation = (event) => {
    // this.handlecheckbox();
    alert("called");
    const { name, checked, value } = event.target;

    // value1.indexOf(name) === -1

    //   ? value1.push(name)

    //   : value1.splice(value1.indexOf(name), 1);

    this.setState((prevState) => {
      const selectedCheckboxValues = checked
        ? [...prevState.selectedCheckboxValues, value]
        : prevState.selectedCheckboxValues.filter((val) => val !== value);

      // console.log("selectedCheckboxValues", selectedCheckboxValues);

      return { selectedCheckboxValues };
    });
  };
  DepartModel = () => {
    this.setState((prevState) => ({
      DepartModel: !prevState.DepartModel,
    }));
    this.GetDepartment();
  };
  DesignationModel = () => {
    this.setState((prevState) => ({
      DesignationModel: !prevState.DesignationModel,
    }));
    this.GetDesignation();
  };
  ManagerModel = () => {
    this.setState((prevState) => ({
      ManagerModel: !prevState.ManagerModel,
    }));
    this.GetManager();
  };
  LocationModel = () => {
    this.setState((prevState) => ({
      LocationModel: !prevState.LocationModel,
    }));
    this.GetLocation();
  };

  GetAllRole() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetAllJRole`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        // const filteredData = data.filter((value, index, self) => {
        //   return self.findIndex(item => item.jobRole === value.jobRole) === index;
        // });

        //       for (let i = 0; i < data.length; i++) {
        //         this.state.AllRoles.push(data[i].JobRole);
        //  }
        this.setState({
          AllRoles: data,
        });
      });
  }

  openThePopup = (department, userId, trainerName) => {
    this.setState({ Id: userId });
    // console.log("fvfvfvf", trainerName + "$$$" + userId);

    this.setState({ firstName: trainerName });
    // console.log("fvfvfvf", trainerName);

    this.setState({ isOpen: true });
    this.GetTrainingItems(department, userId);
  };

  GetTrainingItems = (Department, userId) => {
    const checking = Department;

    // console.log("passdept", checking);
    let url =
      `${BASE_URLs}/Training/GetTrainingItems?Department=` +
      Department +
      "&TrainingId=" +
      userId;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data-----------", data);
        this.setState({
          Trainers: data,
        });
        // console.log("89898989", data);
      });
  };
  GetTrainer = (Department, userId) => {
    const checking = Department;

    // console.log("passdept", checking);
    let url = `${BASE_URLs}/Trainer/GetFullTeam`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data-----------", data);
        this.setState({
          FinaltrainerData: data,
          FinaltrainerData1: data,
        });
        console.log(
          "89898989777777777777777777777777777777777777777777777777",
          data
        );
      });
  };

  render() {
    const {
      trainerData,
      FinaltrainerData,
      Department,
      Filterpopstatus,
      Filterpopstatus1,
      DepartmentGrouped,
      Trainers,
      Search,
      DesignSearch,
      Filterpopstatusadd,
      isOpen,
    } = this.state;
    // console.log("trainerData", trainerData);
    return (
      <div>
        <SideBar active={window.location.pathname} />
        {this.state.DepartModel ? (
          <AddDepartmentpop close={() => this.DepartModel()} />
        ) : null}
        {this.state.DesignationModel ? (
          <AddDesignationModel close={() => this.DesignationModel()} />
        ) : null}
        {this.state.ManagerModel ? (
          <AddManagerPop close={() => this.ManagerModel()} />
        ) : null}
        {this.state.LocationModel ? (
          <AddLocationModel close={() => this.LocationModel()} />
        ) : null}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            Add Trainer
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        {this.state.SuccessModel ? (
                          <CommonSuccess
                            id="Success"
                            message={this.state.message}
                            status={this.state.status}
                            close={() => this.SuccessModel()}
                          />
                        ) : null}
                        <div className="card-block2">
                          {/* <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          > */}
                          <div className="row" style={{ marginTop: "3%" }}>
                            <div className="container">
                              <form
                                autoComplete="off"
                                encType="multipart/form-data"
                                // style={{ width: "135%" }}
                                onSubmit={this.handleSubmit}
                              >
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="container">
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-4">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            // value={
                                            //   this.state.selectedDepartment
                                            // }
                                            name="Department"
                                            id="Department"
                                            onChange={this.handleChangedept}
                                          >
                                            <option value="">Select</option>
                                            {this.state.Departmentdrop.map(
                                              (Departmentdrop) => (
                                                <option
                                                  value={
                                                    Departmentdrop.department
                                                  }
                                                >
                                                  {Departmentdrop.department}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <span style={{ color: "#a29d9d" }}>
                                            Department
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </span>
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-4">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            // value={this.state.selectedJobRole}
                                            name="Role"
                                            id="JobRole"
                                            onChange={
                                              this.handleChangedesignation
                                            }
                                          >
                                            <option value="">Select</option>
                                            {this.state.AllRoles.map(
                                              (JobDrop) => (
                                                <option
                                                  value={JobDrop.designation}
                                                >
                                                  {JobDrop.designation}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <span style={{ color: "#a29d9d" }}>
                                            Designation
                                          </span>
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-4">
                                          <input
                                            className="effect-16"
                                            // value={
                                            //   this.state.selectedTrainerName
                                            // }
                                            placeholder="Search"
                                            type="text"
                                            name="TrainerName"
                                            id="TrainerName"
                                            onChange={
                                              this.handleTrainerNameChange
                                            }
                                          />

                                          <span style={{ color: "#a29d9d" }}>
                                            Trainer Name
                                            <span
                                              style={{ color: "red" }}
                                            ></span>
                                          </span>
                                          <span className="underline" />
                                        </div>
                                        {/* <div className="col-sm-4">
                                          <select
                                            className="effect-16"
                                            value={
                                              this.state.selectedTrainerName
                                            }
                                            type="text"
                                            name="TrainerName"
                                            id="TrainerName"
                                            onChange={
                                              this.handleTrainerNameChange
                                            }
                                          >
                                            <option value="">Select</option>
                                            {this.state.trainerData.map(
                                              (Departmentdrop) => (
                                                <option
                                                  value={
                                                    Departmentdrop.firstName +
                                                    "$$$" +
                                                    Departmentdrop.user_Id
                                                  }
                                                >
                                                  {Departmentdrop.firstName}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <span style={{ color: "#a29d9d" }}>
                                            Trainer Name
                                            <span style={{ color: "red" }}>
                                              * 
                                            </span>
                                          </span>
                                          <span className="underline" />
                                        </div> */}

                                        {/* /////Hidden date */}
                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            style={{ marginTop: "-6px" }}
                                            name="Trainersince"
                                            id="Trainersince"
                                            type="hidden"
                                            value={this.state.currentDate}
                                          />
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>

                                <div className="dt-responsive table-responsive table-res">
                                  {this.state.FinaltrainerData.length ===
                                  0 ? null : (
                                    <table
                                      id="DesiSheet"
                                      className="table nowrap"
                                      style={{ maxWidth: "70%" }}
                                    >
                                      <thead>
                                        <tr>
                                          <th></th>
                                          <th>Department</th>
                                          <th>Name</th>
                                          <th //style={{textAlign:"center"}}
                                          >
                                            Designation
                                          </th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {FinaltrainerData.map((trainerData) => (
                                          <tr>
                                            <td>
                                              <input
                                                type="checkbox"
                                                value={
                                                  trainerData.department +
                                                  "$$$" +
                                                  trainerData.firstName +
                                                  "$$$" +
                                                  trainerData.designation +
                                                  "$$$" +
                                                  trainerData.id +
                                                  "$$$" +
                                                  trainerData.user_Id
                                                }
                                                onChange={
                                                  this.checkChangeDesignation
                                                }
                                              />
                                            </td>
                                            <td>{trainerData.department}</td>
                                            <td>{trainerData.firstName}</td>
                                            <td>{trainerData.designation}</td>

                                            {/* <td style={{ textAlign: "center" }}>
                                            <button
                                              type="button"
                                              className="button-30 sk-edit"
                                              onClick={() =>
                                                this.openThePopup(
                                                  trainerData.department,
                                                  trainerData.user_Id,
                                                  trainerData.firstName
                                                )
                                              }

                                              // onClick={(e)=>{e.preventDefault();
                                              //     console.log(
                                              //       trainerData.firstName +
                                              //       "$$$" +
                                              //       trainerData.user_Id
                                              //      )
                                              //     //this.EditModel(trainerData.id,trainerData.designation)

                                              //   }}
                                              // src="https://img.icons8.com/android/22/000000/pencil.png"/
                                            >
                                              {" "}
                                              Select{" "}
                                            </button>{" "}
                                            &nbsp;&nbsp;&nbsp;
                                          </td> */}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>

                                {/* <div className="row">
                                  <div
                                    className="col-sm-12"
                                    style={{ textAlign: "center" }}
                                  >
                                    <button
                                      type="submit"
                                      className="but "
                                      style={{
                                        height: "39.5px",
                                        width: "86.36px",
                                        marginTop: "-0.3%",
                                      }}
                                    >
                                      Save
                                    </button>
                                    &nbsp; &nbsp; &nbsp;
                                    <button
                                      type="reset"
                                      className=" btn-Secondary"
                                      style={{ height: "39.5px" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div> */}
                                <div style={{ marginLeft: "35%" }}>
                                  <button
                                    type="submit"
                                    className="but "
                                    style={{
                                      height: "39.5px",
                                      width: "86.36px",
                                      marginTop: "-0.3%",
                                    }}
                                  >
                                    Save
                                  </button>
                                  &nbsp; &nbsp; &nbsp;
                                  <button
                                    type="reset"
                                    className=" btn-Secondary"
                                    style={{ height: "39.5px" }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                              </form>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="pop">
            <div className="popinnernormal">
              <form
                autoComplete="off"
                encType="multipart/form-data"
                style={{ width: "135%" }}
                onSubmit={this.handleSubmit}
              >
                {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}} onClick={this.props.close}  ><CloseIcon/></button> */}

                <button
                  style={{
                    marginLeft: "44%",
                    backgroundColor: "#a0a3a305",
                    color: "#797070",
                    border: "1px solid #a3a5a505",
                  }}
                  onClick={this.closePopup}
                >
                  <CloseIcon />
                </button>
                <div className="row" tyle={{ padding: "8px" }}>
                  <div className="col-sm-3">
                    <select
                      className="effect-16"
                      type="text"
                      name="Department"
                      value={this.state.dept}
                      id="Department"
                      onChange={this.handleChange}
                      style={{ width: "72%" }}
                    >
                      <option value="">Department</option>
                      {this.state.department.map((Departmentdrop) => (
                        <option value={Departmentdrop.department}>
                          {Departmentdrop.department}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-3">
                    <select
                      className="effect-16"
                      type="text"
                      name="JobRole"
                      style={{ width: "88%", marginLeft: "-30%" }}
                      id="JobRole"
                      onChange={this.handleChangeDesign}
                    >
                      <option value="">Designation</option>
                      {this.state.AllRoles.map((JobDrop) => (
                        <option value={JobDrop.designation}>
                          {JobDrop.designation}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-3">
                    {/* {Filterpopstatus1 ? (
        <Topic_FilterPop
          data={this.state.Depart}
          FilteredData={(newValue) => {
            this.setState({ Department: newValue });
          }}
          TogglePop={(e) => {
            this.setState({ Filterpopstatus1: !Filterpopstatus1 });
          }}
        />
      ) : null}
            <div className="col-lg-4">
                                <input
                                  className="Searchbox"
                                  placeholder="Search"
                                  onClick={() => {
                                    this.setState({ Filterpopstatus1: true });
                                  }}
                                  //onKeyUp={this.myFunction}
                                  style={{ float: "right" }}
                                />
                              </div> */}

                    <input
                      className="Searchbox"
                      placeholder="Search"
                      onClick={() => {
                        this.setState({ Filterpopstatusadd: true });
                      }}
                      //onKeyUp={this.myFunction}
                      // style={{ marginLeft: "144%" }}
                    />
                  </div>

                  <div className="col-sm-8">
                    <div className="dt-responsive table-responsive table-res">
                      {(() => {
                        if (this.state.Search.length === 0) {
                          return "There are no ' Topics '";
                        }
                        //                       else if (Search.length > 0) {
                        //                         return (
                        //                           <table
                        //                           id="DepSheet"
                        //                           className="table nowrap"
                        //                           style={{ maxWidth: "90%" }}
                        //                         >
                        //                           <thead>
                        //                             <tr>
                        //                               <th>
                        //                                 <input type="checkbox" hidden />
                        //                               </th>

                        //                               <th>Department</th>
                        //                               <th>Designation</th>
                        //                               <th>Topics</th>
                        //                               <th>Description</th>
                        //                               {/* <th>Action</th> */}
                        //                             </tr>
                        //                           </thead>
                        //                           <tbody>
                        //                           {/* {DepartmentGrouped.map(
                        //                                     (DepartmentGrouped, i) => (
                        //                                       <div>
                        //                                         <input type="text" value={DepartmentGrouped.TrainerUserId} />
                        //                                       </div>
                        //                                     )
                        //                           )} */}
                        //                             {Search.map((checkbox) => (
                        //                               <tr key={checkbox.training_Id}>
                        //                                   {/* <input type="text" value={checkbox.userId} id="TrainingId" /> */}
                        //                                  <td>
                        //                                  <input type="checkbox"

                        // value={checkbox.training_Id + "$$$" +  this.state.firstName  + "$$$" + this.state.Id}

                        //  onChange={this.checkChangeDesignation}

                        // />

                        //                                 </td>
                        //                             <td>
                        //                             {checkbox.department}
                        //                             </td>
                        //                             <td>
                        //                             {checkbox.designation}
                        //                             </td>
                        //                                 <td>{checkbox.trainingitem}</td>
                        //                                 <td>
                        //                             {checkbox.description}
                        //                             </td>

                        //                                 {/* <td>

                        //                                   <button
                        //                                     className="button-30"
                        //                                     type="button"
                        //                                     style={{ color: "rgb(94, 217, 135)" }}
                        //                                     onClick={() =>
                        //                                       this.fetchData(
                        //                                         this.state.firstName,
                        //                                         this.state.Id,
                        //                                         Trainers.training_Id
                        //                                       )
                        //                                     }
                        //                                   >
                        //                                     Assign&nbsp;
                        //                                     <i className="icofont icofont-edit"></i>
                        //                                   </button>
                        //                                 </td> */}
                        //                                 {/* <td>
                        //                                 <button onClick={() => this.handleSelectedRows()}>Perform Action</button>
                        //                                 </td> */}
                        //                               </tr>
                        //                             ))}
                        //                           </tbody>
                        //                         </table>
                        //                         );
                        //                       }
                        //                       else if (Search.length === 0 && this.state.Trainers.length === 0) {
                        //                         return (
                        //                          "No Results found"
                        //                         );
                        //                       }
                        else {
                          return (
                            <table
                              id="DepSheet"
                              className="table nowrap"
                              style={{ maxWidth: "90%" }}
                            >
                              <thead>
                                <tr>
                                  <th>
                                    <input type="checkbox" hidden />
                                  </th>

                                  <th>Department</th>
                                  <th>Designation</th>
                                  <th>Topics</th>
                                  <th>Description</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {/* {DepartmentGrouped.map(
                                                  (DepartmentGrouped, i) => (
                                                    <div>
                                                      <input type="text" value={DepartmentGrouped.TrainerUserId} />
                                                    </div>
                                                  )
                                        )} */}

                                {Search.length > 0 ? (
                                  <>
                                    {Search.map((checkbox) => (
                                      <tr key={checkbox.training_Id}>
                                        {/* <input type="text" value={checkbox.userId} id="TrainingId" /> */}
                                        <td>
                                          <input
                                            name="TrainerData"
                                            type="checkbox"
                                            value={
                                              checkbox.training_Id +
                                              "$$$" +
                                              this.state.firstName +
                                              "$$$" +
                                              this.state.Id
                                            }
                                            onChange={
                                              this.checkChangeDesignation
                                            }
                                          />
                                        </td>
                                        <td>{checkbox.department}</td>
                                        <td>{checkbox.designation}</td>
                                        <td>{checkbox.trainingitem}</td>
                                        <td>{checkbox.description}</td>

                                        {/* <td> 
                                                <button
                                                  className="button-30"
                                                  type="button"
                                                  style={{ color: "rgb(94, 217, 135)" }}
                                                  onClick={() =>
                                                    this.fetchData(
                                                      this.state.firstName,
                                                      this.state.Id,
                                                      Trainers.training_Id
                                                    )
                                                  }
                                                >
                                                  Assign&nbsp;
                                                  <i className="icofont icofont-edit"></i>
                                                </button>
                                              </td> */}
                                        {/* <td>
                                              <button onClick={() => this.handleSelectedRows()}>Perform Action</button>
                                              </td> */}
                                      </tr>
                                    ))}
                                  </>
                                ) : null}
                              </tbody>
                            </table>
                          );
                        }
                      })()}
                      {this.state.selectedOptions.map((option, index) => (
                        <li key={index}>{option}</li>
                      ))}
                    </div>
                  </div>
                </div>
                {Filterpopstatusadd ? (
                  <Filterpopstatustopic
                    data={this.state.topic}
                    FilteredData={(newValue) => {
                      this.setState({ Search: newValue });
                    }}
                    TogglePop={(e) => {
                      this.setState({
                        Filterpopstatusadd: !Filterpopstatusadd,
                      });
                    }}
                  />
                ) : null}
                <div className="row">
                  <div
                    className="col-sm-12"
                    style={{ textAlign: "center" }}
                  ></div>
                </div>
                {(() => {
                  if (this.state.Search.length === 0) {
                    return null;
                  } else {
                    return (
                      <div className="col-sm-4">
                        <button
                          className="button-30"
                          type="submit"
                          style={{
                            color: "rgb(83, 81, 171)",
                            marginLeft: "98%",
                          }}
                          // onClick={() =>
                          //   this.fetchData(
                          //     this.state.firstName,
                          //     this.state.Id,
                          //     Trainers.training_Id
                          //   )
                          // }
                        >
                          {/* Savesss&nbsp; */}
                        </button>
                      </div>
                    );
                  }
                })()}
              </form>
              <br />
              {/* <button onClick={this.closePopup}><CloseIcon/></button> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}
