import React, { Component } from "react";
import SideBar from "../Common/Sidebar";
import { Link } from "react-router-dom";
import PanConfirmpop from "../Common/PanConfirmPop";
import GstPop from "../Common/GstPop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrgActiveConfirmPop from "../Common/OrgActiveConifrmPop";
import Doc from "../Assets/images/document.png"
import { BASE_URLs } from "../Config/Base";
export default class Organization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Details: [],
      value: this.props.location.state,
      Active: [],
      InActive: [],
      All: [],
      renewel: [],
      comlogo: [],
      Pending: [],
      SuccessModel: false,
      GstModel: false,
      ActivateModel: false,
    };
    this.RejectOrg = this.RejectOrg.bind(this);
    this.PutStatusToOne = this.PutStatusToOne.bind(this);
  }

  PutStatusToOne(event) {
    event.preventDefault();
    fetch(`${BASE_URLs}/AddOrganization/Put1`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Status: "1",
        company_Id: event.target.value,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.toString() === "The Entity is Activated Successfully") {
            toast.success(result, {
              position: "top-center",
              autoClose: 3200,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(result, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        },
        (error) => {
          alert(error);
        }
      );
  }

  GstModel = () => {
    this.setState((prevState) => ({
      GstModel: !prevState.GstModel,
    }));
    if (this.state.GstModel === true) {
      if (document.getElementById("GstCheck")) {
        document.getElementById("GstCheck").checked = false;
      }
    }
    this.GetStatusOne();
  };

  ActivateModel = () => {
    this.setState((prevState) => ({
      ActivateModel: !prevState.ActivateModel,
    }));
    this.GetStatusOne();
  };

  ActiveOrg(company_Id, Email, status) {
    var page = {
      pathname: "/ActiveReson",
      state: {
        Id: company_Id,
        Email: Email,
        status: status,
      },
    };
    this.props.history.push(page);
  }

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
    if (this.state.SuccessModel === true) {
      if (document.getElementById("PanCheck")) {
        document.getElementById("PanCheck").checked = false;
      }
    }
    this.GetStatusOne();
  };

  RejectOrg(company_Id, Email, status) {
    var page = {
      pathname: "/RejectOrg",
      state: {
        Id: company_Id,
        Email: Email,
        status: status,
      },
    };
    this.props.history.push(page);
  }

  renewel() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(
      `${BASE_URLs}/AddOrganization/Renewel`,
      options
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ renewel: data });
      });
  }
  GetStatusOne() {
    var Id = this.props.location.state.Id;

    let url =
      `${BASE_URLs}/AddOrganization/detail?company_Id=` +
      Id;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Details: data,
        });
        // console.log("checking", data);
      });
  }
  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    this.props.history.push("/Login_form");
  };
  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }
  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push("./Organization");
    } else {
      this.GetStatusOne();
    }

    this.GetOrgLogo();

    this.renewel();
    this.GetUserInfo();
  }
  detailedit(company_Id) {
    var page = {
      pathname: "/EditOrganization",
      state: {
        Id: company_Id,
      },
    };

    this.props.history.push(page);
  }

  GetUserInfo() {
    if (
      this.getCookie("" + JSON.parse(localStorage.uname) + "role") ===
        undefined ||
      this.getCookie("" + JSON.parse(localStorage.uname) + "organization") ===
        undefined
    ) {
      let url =
        `${BASE_URLs}/Authenticate/GetValidation`;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: "Bearer " + accessToken,
          "Content-type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            User: data,
            Organization: data[0].organization,
            sidebar: data,
            role: data.role,
          });

          document.cookie =
            "" +
            JSON.parse(localStorage.uname) +
            "role = " +
            data[0].role +
            ";expires=" +
            new Date(new Date().getTime() + 1 * 60000).toUTCString() +
            " ";
          document.cookie =
            "" +
            JSON.parse(localStorage.uname) +
            "organization = " +
            parseInt(data[0].organization) +
            ";expires=" +
            new Date(new Date().getTime() + 1 * 60000).toUTCString() +
            "";
        });
    } else {
      var crole = this.getCookie("" + JSON.parse(localStorage.uname) + "role");

      var corg = this.getCookie(
        "" + JSON.parse(localStorage.uname) + "organization"
      );
      //  localStorage.setItem('Role', JSON.stringify(crole));
      //  localStorage.setItem('org', JSON.stringify(corg));
      this.setState({
        role: crole,
        Organization: corg,
      });
    }
  }

  getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  render() {
    var role = JSON.parse(localStorage.Role).toString();
    const { renewel } = this.state;
    const { Details } = this.state;

    return (
      <div>
        <div>
          <Link id="Dash" style={{ display: "none" }} to="DashBoard" />
          <ToastContainer
            position="top-center"
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <SideBar active={window.location.pathname} />
          {this.state.GstModel ? (
            <GstPop
              company_Id={this.props.location.state.Id}
              close={() => this.GstModel()}
            />
          ) : null}
          {this.state.SuccessModel ? (
            <PanConfirmpop
              companyId={this.props.location.state.Id}
              close={() => this.SuccessModel()}
            />
          ) : null}
          {this.state.ActivateModel ? (
            <OrgActiveConfirmPop
              Email={this.state.Details[0].email}
              company_Name={this.state.Details[0].company_Name}
              company_Id={this.props.location.state.Id}
              close={() => this.ActivateModel()}
            />
          ) : null}
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="page-header">
                    <div className="page-header-title"></div>
                  </div>
                  <div className="page-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="glowBox">
                            {" "}
                            <div style={{ float: "left" }}>
                              <Link to="/Organization">
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </Link>{" "}
                            </div>
                            <h4 style={{ marginRight: "4%" }} className="title">
                              {" "}
                              Entity Profile
                            </h4>
                          </div>

                          <br />

                          <div className="card-block">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-sm-5"></div>
                                  <div className="col-sm-6">
                                    {Details.map((Details) => (
                                      <img
                                        alt="companylogo"
                                        src={Details.com_logo}
                                        style={{
                                          height: "79px",
                                          width: "80px",
                                          marginLeft: "4.6%",
                                          borderRadius: "50px",
                                        }}
                                      />
                                    ))}{" "}
                                    <br />
                                  </div>
                                </div>
                                <br />

                                <h5 style={{ marginLeft: "25px" }}>
                                  ENTITY INFO{" "}
                                </h5>
                                <div className="card">
                                  <div className="col-lg-12">
                                    <div className="row" style={{marginTop:"10px"}}>
                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>Entity Name</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                            }}
                                          >
                                            {Details.company_Name}
                                          </label>
                                        ))}
                                      </div>

                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>Contact Person</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                            }}
                                          >
                                            {Details.personname}
                                          </label>
                                        ))}
                                      </div>

                                      {/* <div className="col-lg-6 " >
                                          <div className="row">
                                          <b> <span >Email</span></b> :
                                          <div className="col-lg-5" >
                                          {Details.map(Details => <label  style={{ color: 'rgb(128 125 125)' }}>{Details.email}</label>   )}
                                          </div>
                                          </div>
                                          </div> */}
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>Email</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3 ">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                            }}
                                          >
                                            {Details.email}
                                          </label>
                                        ))}
                                      </div>

                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>Primary Mobile Number</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3 ">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                            }}
                                          >
                                            {Details.mobile}
                                          </label>
                                        ))}
                                      </div>
                                    </div>

                                    <div className="row" style={{marginBottom:"10px"}}>
                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>Alternate Mobile Number</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3 ">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                            }}
                                          >
                                            {Details.lanline}
                                          </label>
                                        ))}
                                      </div>

                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>Secondary Email</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3 ">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                              wordBreak:"break-word"
                                            }}
                                          >
                                            {Details.sEmail}{" "}
                                          </label>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  
                                </div>

                                <h5 style={{ marginLeft: "25px" }}>
                                  ENTITY ADDRESS
                                </h5>
                                <div className="card">
                                  
                                  <div className="col-lg-12">
                                    <div className="row" style={{marginTop:"10px"}}>
                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>Address</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3 ">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            {Details.adrs1}
                                          </label>
                                        ))}
                                      </div>

                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>City</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3 ">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                            }}
                                          >
                                            {Details.city}{" "}
                                          </label>
                                        ))}
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>State</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3 ">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                            }}
                                          >
                                            {Details.state}
                                          </label>
                                        ))}
                                      </div>

                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>Country</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3 ">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                            }}
                                          >
                                            {Details.country}{" "}
                                          </label>
                                        ))}
                                      </div>
                                    </div>

                                    <div className="row" style={{marginBottom:"5px"}}>
                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>Pincode</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3 ">
                                        {Details.map((Details) => (
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                            }}
                                          >
                                            {Details.zip}
                                          </label>
                                        ))}
                                      </div>

                                      <div className="col-lg-3 ">
                                        <b>
                                          {" "}
                                          <span>Official Website</span>
                                        </b>
                                      </div>
                                      <div className="col-lg-3 ">
                                        {Details.map((Details) => (
                                          <a
                                            target="_blank"
                                            href={"http://" + Details.website}
                                            //onClick={() => { window.open(Details.website) }}

                                            style={{
                                              color: "rgb(128 125 125)",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {Details.website}
                                          </a>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* ///admin */}

                                {this.state.role === "Platform Admin" ? (
                                  <>
                                    <h5 style={{ marginLeft: "25px" }}>
                                      ENTITY DOCUMENTS
                                    </h5>
                                    <div className="card">
                                      <div className="col-lg-12">
                                        <div className="row" style={{marginTop:"10px",alignItems:"center"}}>
                                          <div className="col-lg-3 ">
                                            <b>
                                              {" "}
                                              <span>GST Number</span>
                                            </b>
                                          </div>
                                          <div className="col-lg-3 ">
                                            {Details.map((Details) => (
                                              <label
                                                style={{
                                                  color: "rgb(128 125 125)",
                                                }}
                                              >
                                                {Details.gsTno}
                                              </label>
                                            ))}
                                          </div>

                                          <div className="col-lg-3">
                                            <div className="row">
                                              <div className="col-lg-6 linkbox">
                                                {Details.map((Details) => (
                                                  <a
                                                    href={Details.gst}
                                                    name="gst"
                                                    readOnly
                                                    value={Details.gst}
                                                    title=""
                                                    target="_blank"
                                                    style={{
                                                      width: "200px",
                                                      height: "200px",
                                                    }}
                                                  >
                                                    <div className="">
                                                  {Details.gst.toLowerCase() === 'excel' || Details.gst.toLowerCase().endsWith('.xlsx') ? (
          <img src={Doc} alt="Excel document" style={{ width: '50px', height: '50px' }}  target="_blank" />
        ) : (
          Details.gst.toLowerCase() === 'word' || Details.gst.toLowerCase().endsWith('.docx')) ? (
            <img src={Doc} alt="Word document" style={{ width: '50px', height: '50px' }} target="_blank" />
          ) : (
          <embed
            src={Details.gst + "#toolbar=0"}
            value={Details.gst + "#toolbar=0"}
            title=""
            scrolling="no"
            target="_blank"
            style={{
              width: "100px",
              height: "100px",
              pointerEvents: "none",
            }}
          ></embed>
        )}
      </div>
                                                    
                                                  </a>
                                                ))}
                                              </div>
                                              <div className="col-lg-6"></div>
                                            </div>
                                          </div>
                                          {role === "Platform Admin" ? null : (
                                            <div
                                              id="gstmsg"
                                              className="col-lg-3"
                                            >
                                              {Details.map((Details) => (
                                                <div>
                                                  {Details.gstVerified ===
                                                  "1" ? (
                                                    <div
                                                      style={{
                                                        pointerEvents: "none",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {" "}
                                                        GST is verified
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{ color: "red" }}
                                                    >
                                                      <span>
                                                        {" "}
                                                        GST is not verified{" "}
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                              ))}{" "}
                                            </div>
                                          )}

                                          {role !== "Platform Admin" ? null : (
                                            <div className="col-sm-3">
                                              {Details.map((Details) => (
                                                <div>
                                                  {Details.gstVerified ===
                                                  null && Details.status !== 4 ? (
                                                    <div className="checkbox-fade fade-in-primary">
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          id="GstCheck"
                                                          value={
                                                            this.props.location.state.Id
                                                          }
                                                          onClick={() =>
                                                            this.GstModel()
                                                          }
                                                        />
                                                        <span className="cr">
                                                          <i className="cr-icon icofont icofont-ui-check txt-primary" />
                                                        </span>{" "}
                                                        <span>GST Verify </span>
                                                      </label>
                                                    </div>
                                                  ) : Details.status !== 4 && (
                                                    <div
                                                      style={{ color: "green" }}
                                                    >
                                                      <span>
                                                        {" "}
                                                        GST is verified{" "}
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    
                                    <div className="col-lg-12">
                                      <div className="row" style={{marginTop:"10px",alignItems:"center",marginBottom:"10px"}}>
                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>PAN Number</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.paNno}
                                            </label>
                                          ))}
                                        </div>

                                        <div className="col-lg-3">
                                          <div className="row">
                                            <div className="col-lg-6 linkbox">
                                              {Details.map((Details) => (
                                                <a
                                                  href={Details.pan}
                                                  name="pan"
                                                  value={Details.pan}
                                                  title=""
                                                  target="_blank"
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                  }}
                                                >
                                                  <div className=" ">
                                                  {Details.pan.toLowerCase() === 'excel' || Details.pan.toLowerCase().endsWith('.xlsx') ? (
          <img src={Doc} alt="Excel document" style={{ width: '50px', height: '50px' }}  target="_blank" />
        ) : (
            Details.pan.toLowerCase() === 'word' || Details.pan.toLowerCase().endsWith('.docx')) ? (
            <img src={Doc} alt="Word document" style={{ width: '50px', height: '50px' }} target="_blank"/>
          ) : (
                                                    <embed
                                                      src={
                                                        Details.pan +
                                                        "#toolbar=0"
                                                      }

                                                      id="Gstiframe"
                                                      value={
                                                        Details.pan +
                                                        "#toolbar=0"
                                                      }
                                                      width="100px"
                                                      height="100px"
                                                      title=""
                                                      scrolling="no"
                                                      target="_blank"
                                                      style={{
                                                        pointerEvents: "none",
                                                      }}
                                                    ></embed>
                                                    )}
                                                  </div>{" "}
                                                </a>
                                              ))}
                                            </div>
                                            <div className="col-lg-6"></div>
                                          </div>
                                        </div>

                                        {role === "Platform Admin" ? null : (
                                          <div id="panmsg" className="col-lg-3">
                                            {Details.map((Details) => (
                                              <div>
                                                {" "}
                                                {(() => {
                                                  if (
                                                    Details.panVerified === "1"
                                                  ) {
                                                    return (
                                                      <div
                                                        style={{
                                                          pointerEvents: "none",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color: "green",
                                                          }}
                                                        >
                                                          {" "}
                                                          PAN is verified
                                                        </span>
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div
                                                        style={{ color: "red" }}
                                                      >
                                                        <span>
                                                          {" "}
                                                          PAN is not verified
                                                        </span>
                                                      </div>
                                                    );
                                                  }
                                                })()}{" "}
                                              </div>
                                            ))}{" "}
                                          </div>
                                        )}

                                        {role !== "Platform Admin" ? null : (
                                          <div className="col-sm-3">
                                            {Details.map((Details) => (
                                              <div>
                                                {Details.panVerified ===
                                                null && Details.status !== 4 ? (
                                                  <div>
                                                    <div className="checkbox-fade fade-in-primary">
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          id="PanCheck"
                                                          onClick={() =>
                                                            this.SuccessModel()
                                                          }
                                                        />
                                                        <span className="cr">
                                                          <i className="cr-icon icofont icofont-ui-check txt-primary" />
                                                        </span>{" "}
                                                        <span> PAN Verify</span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                ) : Details.status !== 4 && (
                                                  <div
                                                    style={{ color: "green" }}
                                                  >
                                                    <span>
                                                      {" "}
                                                      PAN is verified{" "}
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            ))}
                                          </div>
                                        )}

                                        {/*platform  Admin Gst and Pan verify Message */}
                                        {/* super admin gst pan start */}

                                        {/* super admin gst pan end */}
                                      </div>
                                    </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <h5 style={{ marginLeft: "25px" }}>
                                      ENTITY DOCUMENTS
                                    </h5>

                                    <div className="card">
                                      <div className="col-lg-12">
                                        <div className="row" style={{marginTop:"10px"}}>
                                          <div className="col-lg-3 ">
                                            <b>
                                              {" "}
                                              <span>GST Number</span>
                                            </b>
                                          </div>
                                          <div className="col-lg-3 ">
                                            {Details.map((Details) => (
                                              <label
                                                style={{
                                                  color: "rgb(128 125 125)",
                                                }}
                                              >
                                                {Details.gsTno}
                                              </label>
                                            ))}
                                          </div>

                                          <div className="col-lg-3 ">
                                            <b>
                                              {" "}
                                              <span>PAN Number</span>
                                            </b>
                                          </div>
                                          <div className="col-lg-3 ">
                                            {Details.map((Details) => (
                                              <label
                                                style={{
                                                  color: "rgb(128 125 125)",
                                                }}
                                              >
                                                {Details.paNno}
                                              </label>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {/* ////Super admin */}

                                {Details.map((Details) => (
                                  <>
                                    {Details.status === 4 ||
                                    Details.status === 2 ? null : (
                                      <>
                                        {role === "Platform Admin" ? (
                                          <div className="activitydisplay">
                                            {/* {this.state.Organization===2 && this.state.Organization===4? */}

                                            <h5 style={{ marginLeft: "25px" }}>
                                              STATUS OF ENTITY
                                            </h5>
                                            <div className="card">
                                              <div className="col-lg-12">
                                                <div className="row" style={{marginTop:"10px"}}>
                                                  {Details.status === 1 ? (
                                                    <div className="col-lg-3 ">
                                                      <b>
                                                        {" "}
                                                        <span>
                                                          Active Since
                                                        </span>
                                                      </b>
                                                    </div>
                                                  ) : null}

                                                  {Details.status === 1 ? (
                                                    <div className="col-lg-3 ">
                                                      <label
                                                        style={{
                                                          color:
                                                            "rgb(128 125 125)",
                                                        }}
                                                      >
                                                        {Details.aDate}
                                                      </label>
                                                    </div>
                                                  ) : null}
                                                  {Details.status === 0 ? (
                                                    <div className="col-lg-3 ">
                                                      <b>
                                                        {" "}
                                                        <span>
                                                          Deactive Since
                                                        </span>
                                                      </b>{" "}
                                                    </div>
                                                  ) : null}

                                                  {Details.status === 0 ? (
                                                    <div className="col-lg-3 ">
                                                      <label
                                                        style={{
                                                          color:
                                                            "rgb(128 125 125)",
                                                        }}
                                                      >
                                                        {Details.dDate}
                                                      </label>
                                                    </div>
                                                  ) : null}

                                                  {Details.status === 0 ? (
                                                    <div className="col-lg-3 ">
                                                      <b>
                                                        {" "}
                                                        <span>
                                                          Deactivated Reason
                                                        </span>
                                                      </b>{" "}
                                                    </div>
                                                  ) : null}

                                                  {Details.status === 0 ? (
                                                    <div className="col-lg-3 ">
                                                      <label
                                                        style={{
                                                          color:
                                                            "rgb(128 125 125)",
                                                        }}
                                                      >
                                                        {Details.reason}
                                                      </label>
                                                    </div>
                                                  ) : null}
                                                  {Details.reason !== "-" ? (
                                                    <>
                                                      {/* {Details.status === 1 ? <div className="col-lg-3 ">

                                       <b> <span >Active Reason</span></b> </div> : null}



                                     {Details.status === 1 ? <div className="col-lg-3 ">



                                       <label style={{ color: 'rgb(128 125 125)' }}>{Details.reason}</label>
                                     </div>
                                       :
                                       <div></div>} */}
                                                    </>
                                                  ) : null}
                                                </div>
                                              </div>
                                              <br />
                                            </div>

                                            {/* :null}  */}
                                          </div>
                                        ) : null}
                                      </>
                                    )}
                                  </>
                                ))}

                             {Details.map((Details) => (
                                  <>
                                    {Details.status === 4 ? (
                                      <>
                                        {role === "Platform Admin" ? (
                                          <div className="activitydisplay">
                                            {/* {this.state.Organization===2 && this.state.Organization===4? */}

                                            <h5 style={{ marginLeft: "25px" }}>
                                              STATUS OF ENTITY
                                            </h5>
                                            <div className="card">
                                              <div className="col-lg-12">
                                                <div className="row" style={{marginTop:"10px"}}>
                                                  {Details.status === 4 ? (
                                                    <div className="col-lg-3 ">
                                                      <b>
                                                        {" "}
                                                        <span>
                                                          Rejected Reason
                                                        </span>
                                                      </b>
                                                    </div>
                                                  ) : null}

                                                  {Details.status === 4 ? (
                                                    <div className="col-lg-3 ">
                                                      <label
                                                        style={{
                                                          color:
                                                            "rgb(128 125 125)",
                                                        }}
                                                      >
                                                        {Details.reason}
                                                      </label>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                              <br />
                                            </div>

                                            {/* :null}  */}
                                          </div>
                                        ) : null}
                                      </>
                                    ):null}
                                  </>
                                ))}
                              </div>
                            </div>

                            {role !== "Platform Admin" ? null : (
                              <div
                                id="detailbtns"
                                className="j-response"
                                style={{
                                  textAlign: "center",
                                  marginBottom: "30px",
                                }}
                              >
                                <br />
                                {Details.map((Details) => (
                                  <>
                                    {(() => {
                                      if (
                                        Details.status === 2
                                      ) {
                                        return (
                                          <div
                                            className="col-sm-12"
                                            style={{ textAlign: "center" }}
                                          >
                                            <button
                                              value={
                                                this.props.location.state.Id
                                              }
                                              id="actbtn"
                                              className="but"
                                              onClick={() =>
                                                this.ActivateModel()
                                              }
                                              style={{ width: "auto" }}
                                            >
                                              Verify and Approved
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <a href id="inactbtn">
                                              <button
                                                onClick={() =>
                                                  this.RejectOrg(
                                                    this.props.location.state
                                                      .Id,
                                                    Details.email,
                                                    Details.status
                                                  )
                                                }
                                                className="but"
                                                style={{ width: "93.26px" }}
                                              >
                                                Reject Entity
                                              </button>
                                            </a>
                                            &nbsp;&nbsp;&nbsp;
                                            <button
                                              href
                                              onClick={() =>
                                                this.props.history.goBack()
                                              }
                                              id="Orgcancel"
                                              style={{ color: "white" }}
                                              type="reset"
                                              className=" btn-Secondary"
                                            >
                                              Cancel
                                            </button>{" "}
                                            &nbsp;&nbsp;
                                            <br /> <br />
                                          </div>
                                        );
                                      } else if (Details.status === 1) {
                                        return (
                                          <div
                                            className="col-sm-12"
                                            style={{ textAlign: "center" }}
                                          >
                                            <a href id="inactbtn">
                                              <button
                                                onClick={() =>
                                                  this.RejectOrg(
                                                    this.props.location.state
                                                      .Id,
                                                    Details.email,
                                                    Details.status
                                                  )
                                                }
                                                style={{ marginLeft: "-2px" }}
                                                className="but"
                                              >
                                                Deactivate
                                              </button>
                                            </a>
                                            &nbsp;&nbsp;
                                            <button
                                              href
                                              onClick={() =>
                                                this.props.history.goBack()
                                              }
                                              id="Orgcancel"
                                              style={{ color: "white" }}
                                              type="reset"
                                              className=" btn-Secondary"
                                            >
                                              Cancel
                                            </button>
                                            <br />
                                            <br />
                                          </div>
                                        );
                                      } else if (Details.status === 0) {
                                        return (
                                          <div
                                            className="col-sm-12"
                                            style={{ textAlign: "center" }}
                                          >
                                            <button
                                              value={
                                                this.props.location.state.Id
                                              }
                                              id="actbtn"
                                              className="but"
                                              onClick={() =>
                                                this.ActiveOrg(
                                                  this.props.location.state.Id,
                                                  Details.email,
                                                  Details.status
                                                )
                                              }
                                            >
                                              Activate
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button
                                              href
                                              onClick={() =>
                                                this.props.history.goBack()
                                              }
                                              id="Orgcancel"
                                              style={{ color: "white" }}
                                              type="reset"
                                              className=" btn-Secondary"
                                            >
                                              Cancel
                                            </button>
                                            <br />
                                            <br />
                                          </div>
                                        );
                                      }
                                    })()}
                                  </>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* End */}
        </div>
      </div>
    );
  }
}
