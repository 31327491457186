import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { BASE_URLs } from "../Config/Base";
export default class EditDesignationModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Department: [],
      ButtonDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var DepartmentName = document.getElementById("DepartmentName").value;

    if (!DepartmentName) {
      formIsValid = false;
      formErrors["DepartmentName"] = "Department Name  is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleSubmit(event) {
    var AddDepartment = document.getElementById("DepartmentName").value;
    var Id = document.getElementById("Id").value;
    event.preventDefault();
    if (this.Validation()) {
      this.setState({
        ButtonDisabled: true,
      });
      const data = new FormData();
      data.append("AddDepartment", AddDepartment);
      data.append("Id", Id);

      fetch(`${BASE_URLs}/Department/EditDepartment`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept': 'application/json',
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("Editpop").style.display = "none";

            setTimeout(function () {
              if (document.getElementById("close")) {
                document.getElementById("close").click();
              }
            }, 4000);
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("Editpop").style.display = "none";
          }
        });
      // document.getElementById("close").click();
    }
  }
  GetDepartment() {
    let url = `${BASE_URLs}/Department/GetMasterDepartment`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Department: data,
        });
      });
  }
  componentDidMount() {
    // this.RelationshipDropList();
    this.GetDepartment();
  }
  render() {
    var w = window.innerWidth;
    const { Department } = this.state;
    const { DepartmentName } = this.state.formErrors;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />{" "}
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="Editpop"
          >
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <button
                id="close"
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={this.props.close}
              >
                <CloseIcon />
              </button>

              <div className="row">
                <div className="col-sm-2"></div>

                <div className="col-sm-8">
                  {/* <select className="form"  id="DepartmentName" name="DepartmentName"  defaultChecked={this.props.values.DepartmentName} type="text"  placeholder="DepartmentName" >
        <option value="" >Select</option> 
        {Department.map(Department => <option selected={this.props.values.DepartmentName===Department.department} value={Department.department}>{Department.department}</option>)}
          </select> */}

                  <input
                    type="hidden"
                    id="Id"
                    name="Id"
                    onKeyDown={this._handleKeyDown}
                    defaultValue={this.props.values.id}
                  />
                  <input
                    className="form"
                    maxLength="50"
                    onKeyDown={this._handleKeyDown}
                    defaultValue={this.props.values.DepartmentName}
                    id="DepartmentName"
                    name="AddDepartment"
                    type="text"
                    onChange={this.handleChange}
                    placeholder="Department Name"
                  />
                  {DepartmentName == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Department Name
                      <span style={{ color: "red", float: "left" }}></span>
                    </span>
                  ) : (
                    <span
                      style={{ color: "red", float: "left" }}
                      className={DepartmentName ? " showError" : ""}
                    >
                      {DepartmentName}
                    </span>
                  )}
                </div>
                <span className="underline" />
              </div>
              <br />
              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>

              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
