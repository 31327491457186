import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../Common/Sidebar";
import AddManagerPop from "../../Common/AddManagerPop";
import AddTraineePop from "../../Common/AddTraineePop";
import { BASE_URLs } from "../../Config/Base";
export default class TrainerActiveTraining extends Component {
  constructor(props) {
    super(props);

    this.state = {
      EditModel: false,
      EditManagerValues: [],
      selectedOption: "",
      Learning: [],
      Learn: [],
      Trainer: [],
    };
  }
  handleSelectChange = (event) => {
    this.setState({ selectedOption: event.target });
  };

  DetailEdit22(
    Id,
    department,
    startDate,
    endDate,
    trainingitem,
    schedulingDuration,
    trainerName,
    trainees
  ) {
    // console.log("called", Id);
    var page = {
      pathname: "/EditActiveTrainer",
      state: {
        Id: Id,
        department: department,
        startDate: startDate,
        endDate: endDate,
        trainingitem: trainingitem,
        schedulingDuration: schedulingDuration,
        trainerName: trainerName,
        trainees: trainees,
      },
    };
    this.props.history.push(page);
  }

  DetailEdit(id) {
    // console.log("get or not", id);
    var page = {
      pathname: "/EditActiveTrainer",
      state: {
        id: id,
      },
    };
    this.props.history.push(page);
  }

  GetLearning = async (event) => {
    // console.log("beforevalid");

    let Email = JSON.parse(localStorage.getItem("email"));

    let url = `${BASE_URLs}/Training/Getloggeddet?Email=` + Email;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    // console.log(accessToken);
    await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("My Journey Data", data);
        localStorage.setItem(
          "deptofloggeduser",
          JSON.stringify(data[0].department)
        );
        localStorage.setItem(
          "roleofloggeduser",
          JSON.stringify(data[0].trainer_Info)
        );
        let roleofloggeduser = JSON.parse(
          localStorage.getItem("roleofloggeduser")
        );
        var Department = JSON.parse(localStorage.getItem("deptofloggeduser"));
        if (roleofloggeduser == 0) {
          localStorage.setItem("roleofloggeduser", "User");
        } else {
          localStorage.setItem("roleofloggeduser", "Trainer");
        }
      });
    var roleofloggeduser = localStorage.getItem("roleofloggeduser");
    var Department = JSON.parse(localStorage.getItem("deptofloggeduser"));

    if (Department != null && roleofloggeduser == "User") {
      let url =
        `${BASE_URLs}/Learning/GetLearningUser?Department=` + Department;

      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: "Bearer " + accessToken,
          "Content-type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Learning: data,
          });
          // console.log("Learning", data);
        });
    } else {
      let url =
        `${BASE_URLs}/Learning/GetLearningUser?Department=` + Department;

      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: "Bearer " + accessToken,
          "Content-type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Trainer: data,
          });
          // console.log("Trainer", data);
        });
    }
  };

  componentDidMount() {
    this.GetLearning();
  }
  render() {
    const { Learning, Trainer } = this.state;
    var Role = localStorage.roleofloggeduser;
    return (
      <div>
        <SideBar active={window.location.pathname} />

        {this.state.EditModel ? (
          <AddTraineePop
            values={this.state.EditManagerValues}
            close={() => this.EditModel("")}
          />
        ) : null}
        {this.state.ManagerModel ? (
          <AddManagerPop close={() => this.ManagerModel()} />
        ) : null}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              {" "}
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            {" "}
                            Active Training
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form autoComplete="off">
                            <div className="card-block">
                              <div className="row">
                                {/* <div className="col btnalign">
                                <Link to='/AddTraineePop'  type="button" style={{ width: "150px", background: "white" }} className="btn ">Add Trainees  </Link>
                              </div> */}
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="container">
                              <div className="col-lg-12">
                                <div className="dt-responsive table-responsive">
                                  {(() => {
                                    if (Role == "User") {
                                      return (
                                        <div>
                                          <table
                                            id="add-row-table3"
                                            className="table nowrap"
                                          >
                                            <thead>
                                              <tr>
                                                <th>Training Topic </th>
                                                <th> Department </th>
                                                <th>Trainers </th>
                                                <th>Start Date </th>
                                                <th>End Date </th>
                                                <th>Duration </th>
                                                <th>Action </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Learning.map((Learning) => (
                                                <tr>
                                                  <td>
                                                    {Learning.trainingitem}
                                                  </td>
                                                  <td>{Learning.department}</td>
                                                  <td>
                                                    {Learning.trainercount}
                                                  </td>
                                                  <td>
                                                    {Learning.startDate
                                                      ? Learning.startDate.slice(
                                                          0,
                                                          10
                                                        )
                                                      : null}
                                                  </td>
                                                  <td>
                                                    {Learning.endDate
                                                      ? Learning.endDate.slice(
                                                          0,
                                                          10
                                                        )
                                                      : null}
                                                  </td>
                                                  <td>
                                                    {
                                                      Learning.schedulingDuration
                                                    }
                                                  </td>
                                                  <td>
                                                    <Link to="/EditSchedule">
                                                      <button
                                                        className="button-30 sk-edit"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.DetailEdit(
                                                            Learning.id
                                                          );
                                                        }}
                                                        type="button"
                                                        style={{ color: "" }}
                                                      >
                                                        <i className="icofont icofont-info"></i>
                                                      </button>
                                                      &nbsp;&nbsp;
                                                    </Link>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div>
                                          <table
                                            id="add-row-table3"
                                            className="table nowrap"
                                          >
                                            <thead>
                                              <tr>
                                                <th>Training Topic </th>
                                                <th> Department </th>
                                                <th>Trainers </th>
                                                <th>Start Date </th>
                                                <th>End Date </th>
                                                <th>Duration </th>
                                                <th>Action </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Trainer.map((Training) => (
                                                <tr>
                                                  <td>
                                                    {Training.trainingitem}
                                                  </td>
                                                  <td>{Training.department}</td>
                                                  <td>
                                                    {Training.trainercount}
                                                  </td>
                                                  <td>
                                                    {Training.startDate
                                                      ? Training.startDate.slice(
                                                          0,
                                                          10
                                                        )
                                                      : null}
                                                  </td>
                                                  <td>
                                                    {Training.endDate
                                                      ? Training.endDate.slice(
                                                          0,
                                                          10
                                                        )
                                                      : null}
                                                  </td>
                                                  <td>
                                                    {
                                                      Training.schedulingDuration
                                                    }
                                                  </td>
                                                  <td>
                                                    <Link to="/EditSchedule">
                                                      <button
                                                        className="button-30 sk-edit"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.DetailEdit(
                                                            Training.id
                                                          );
                                                        }}
                                                        type="button"
                                                        style={{ color: "" }}
                                                      >
                                                        <i className="icofont icofont-info"></i>
                                                      </button>
                                                      &nbsp;&nbsp;
                                                    </Link>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                                <br />
                              </div>
                              <br />
                              <br />
                              <br />
                              <br />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
