import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class EditDesignationModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Designation: [],
      ButtonDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };
  handleSubmit(event) {
    var DesignationName = document.getElementById("DesignationName").value;
    var Id = document.getElementById("Id").value;

    event.preventDefault();

    if (this.handleFormValidation()) {
      this.setState({
        ButtonDisabled: true,
      });
      const data = new FormData();
      data.append("DesignationName", DesignationName);
      data.append("Id", Id);
      var role = JSON.parse(localStorage.Role);

      fetch(`${BASE_URLs}/Designation/EditDesignation`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("Editpop").style.display = "none";
            setTimeout(function () {
              if (document.getElementById("close")) {
                document.getElementById("close").click();
              }
            }, 4000);
            this.GetDesignation();
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("Editpop").style.display = "none";
            this.GetDesignation();
          }
        });
    }
  }
  handleFormValidation() {
    let designame = document.getElementById("DesignationName").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!designame) {
      formIsValid = false;
      formErrors["DesignationtNameErr"] = "Designation is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  GetDesignation() {
    let url = `${BASE_URLs}/Designation/GetMasterDesignation`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Designation: data,
        });
      });
  }

  componentDidMount() {
    this.GetDesignation();
  }

  render() {
    var w = window.innerWidth;
    const { DesignationtNameErr } = this.state.formErrors;
    const { DesignationName } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="Editpop"
          >
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>

            <form
              autoComplete="off"
              onSubmit={this.handleSubmit.bind(this)}
              style={{ marginTop: "-10px" }}
            >
              <div className="row" style={{ padding: "8px" }}>
                <div className="col-sm-3"></div>

                <div className="col-sm-8">
                  <input
                    type="hidden"
                    id="Id"
                    name="Id"
                    onKeyDown={this._handleKeyDown}
                    defaultValue={this.props.values.id}
                  />

                  {/* <select className="form"  id="DesignationName" name="DesignationName"  defaultChecked={this.props.values.DesignationName} type="text"  placeholder="Designtion Name" >
        <option value="" >Select</option> 
        {Designation.map(Designation => <option selected={this.props.values.DesigntionName===Designation.designation} value={Designation.designation}>{Designation.designation}</option>)}
          </select> */}

                  <input
                    className="form"
                    maxLength="50"
                    onKeyDown={this._handleKeyDown}
                    id="DesignationName"
                    defaultValue={this.props.values.DesigntionName}
                    name="DesignationName"
                    type="text"
                    onChange={this.handleChange}
                  />

                  {DesignationtNameErr == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      {" "}
                      Designation
                      <span style={{ color: "red", float: "left" }}></span>
                    </span>
                  ) : (
                    <span
                      style={{ color: "red", float: "left" }}
                      className={DesignationtNameErr ? " showError" : ""}
                    >
                      {DesignationtNameErr}
                    </span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>

              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
