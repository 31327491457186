import React, { Component } from "react";
import Department from "../../../images/Department1.png";

import SideBar from "../../Common/Sidebar";
export default class DepartmentDash extends Component {
  render() {
    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                {/*  Start */}
                <div className="page-body">
                <img src={Department} alt="Description of image" />
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
