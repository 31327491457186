import React from "react";
import "../Css/Test.css";
import $ from "jquery";
import logo from "../Assets/images/OATlogo.png";
import { Link } from "react-router-dom";
import { PureComponent } from "react";
import PopMsg from "../Common/PopMsg";
import AsAdminModel from "../Common/AsAdminModel";
import LogoutConfirmPop from "./LogoutConfirmPop";
import axios from "axios";
import { BASE_URLs } from "../Config/Base";
import SAprofile from "../Assets/images/profile.png"
const link1 = "/AssignTrainings";
const link2 = "/TrainerAssignTraining";

const link3 = "/TrainerActiveTraining";
const link4 = "/ActiveTraining";

const link5 = "/TrainingsDocuments";
const link6 = "/TrainingsDocuments";

$(document).ready(function () {
  $(".dummy").slideUp("fast");
  $("#dummy").click(function () {
    $(".dummy").slideToggle(500);
  });
});
document.addEventListener("DOMContentLoaded", function () {
  $(".dummy").slideUp("fast");
  $("#dummy").click(function () {
    $(".dummy").slideToggle(500);
  });
});

var slideOpen = true;
var heightChecked = false;
var initHeight = "auto";
export default class SideBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      Ulogo: [],
      User: [],
      Trainer: [],
      role: "",
      Organization: "",
      comlogo: [],
      Notification: [],
      Noticount: 0,
      Unreadcount: 0,
      Unreadcountuser: 0,
      UnreadAdNoticount: 0,
      notificationCount: 0,
      ProfileModel: false,
      AsAdminModel: false,
      sidebar: [],
      logoutpop: false,
      profile: [],
      Unreadcountannounce: 0,
      UnreadcountMsg: 0,
      SkillDetails: [],
      Team: [],
      UserTeam: [],
      Anncount: 0,
      Announcement: []
    };
    this.GetUserInfo = this.GetUserInfo.bind(this);
    this.Noticount = this.Noticount.bind(this);
    this.getNumberLength = this.getNumberLength.bind(this);
  }

  SkillDetailList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/UserSkillDetails/GetUserSkill`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SkillDetails: data });
      });
  }

  GetSPNotificationMsg() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Notification/GetNotificationMsg`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.length !== 0) {
          // // console.log("Message", data[0]);
          this.setState({ UnreadcountMsg: data[0].count });
        }
      });
  }

  Team() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetTeams`, options).then((data) => {
      this.setState({
        Team: data,
        Teams: data,
      });
      // // console.log("hess", data);
      console.clear();
    });
  }

  logoutpopfunctn = () => {
    this.setState((prevState) => ({
      logoutpop: !prevState.logoutpop,
    }));
  };

  slideToggle() {
    var mdiv = document.getElementById("mdiv");
    if (!heightChecked) {
      initHeight = mdiv.offsetHeight;
      heightChecked = true;
    }
    if (slideOpen) {
      slideOpen = false;
      mdiv.style.height = "0px";
    } else {
      slideOpen = true;
      mdiv.style.height = initHeight + "px";
    }
  }
  GetAnnouncementNotificationuser() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Notification/GetUserAnnoucementNoti`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.length !== 0) {
          // // console.log(data[0]);
          this.setState({
            Unreadcountannounce: data[0].column1,
          });

        }
      });
  }
  LogOut = (e) => {
    localStorage.clear();
    var allCookies = document.cookie.split(";");
    for (var i = 0; i < allCookies.length; i++) {
      document.cookie =
        allCookies[i] + "=;expires=" + new Date(0).toUTCString();
    }
  };

  GetUserInfo() {
    if (
      this.getCookie("" + JSON.parse(localStorage.uname) + "role") ===
      undefined ||
      this.getCookie("" + JSON.parse(localStorage.uname) + "organization") ===
      undefined
    ) {
      let url = `${BASE_URLs}/Authenticate/GetValidation`;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: "Bearer " + accessToken,
          "Content-type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            User: data,
            Organization: data[0].organization,
            sidebar: data,
          });

          document.cookie =
            "" +
            JSON.parse(localStorage.uname) +
            "role = " +
            data[0].role +
            ";expires=" +
            new Date(new Date().getTime() + 1 * 60000).toUTCString() +
            " ";
          document.cookie =
            "" +
            JSON.parse(localStorage.uname) +
            "organization = " +
            parseInt(data[0].organization) +
            ";expires=" +
            new Date(new Date().getTime() + 1 * 60000).toUTCString() +
            "";
        });
    } else {
      var crole = this.getCookie("" + JSON.parse(localStorage.uname) + "role");

      var corg = this.getCookie(
        "" + JSON.parse(localStorage.uname) + "organization"
      );
      localStorage.setItem("Role", JSON.stringify(crole));
      localStorage.setItem("org", JSON.stringify(corg));
      this.setState({
        User: [{ role: crole, organization: parseInt(corg) }],
        Organization: corg,
      });
    }
  }

  // callStatusapi = () => {
  //   const options = {
  //     method: "POST",

  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //       "Content-Type": "application/json",

  //       Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
  //     },

  //     body: JSON.stringify({
  //       message: "Message"
  //     }),
  //   };

  //   fetch(`${BASE_URLs}/Authenticate/close", options)
  //     .then((response) => response.json())

  //     .then((data) => {
  //       // Handle the response data if needed

  //       // console.log(data);
  //     })

  //     .catch((error) => {
  //       console.error("Error updating Seen field:", error);
  //     });
  // };

  CallData() {
    let url2 = `${BASE_URLs}/Team/UpdateInactiveStatus?Email=vtselvan@kdptec.com`;

    // // console.log("Url-----------Login", url2);

    fetch(url2, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    });
  }

  Notifycount = (Id) => {
    fetch(`${BASE_URLs}/Notification/Seen`, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Seen: 1,
        Id: Id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // // console.log("new");
        },
        (error) => { }
      );

    this.GetNotify();
  };

  GetNotify() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Notification/MessageNotification`, options)
      .then((response) => response.json())
      .then((data) => {
        //  this.setState({ Notify: data.filter(Tasks => (Tasks.type !== "c")) });
        this.setState({
          NotifyCount: data.filter((Tasks) => Tasks.seen === false).length,
        });

        //  this.setState({ ContactMessages: data.filter(Tasks => (Tasks.type === "c")) });
        //  this.setState({ ContactMessagesCount: (data.filter(Tasks => (Tasks.type === "c") )).filter(Tasks => (Tasks.seen === false)).length
        // });

        if (data.length !== 0) {
          this.setState({ Unreadcount: data[0].count });
        }
      });
  }

  UpdateLocalStorage() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Profile/GetUpdateAccessDetails`, options)
      .then((response) => response.json())
      .then((data) => {
        data.map((d) => {
          // // console.log("Access--------2", d.accessed_to)
          localStorage.setItem("org", d.org);
        });
      });
  }


  componentDidMount() {
    var mail = JSON.parse(localStorage.getItem("Entity"));
    var Id = JSON.parse(localStorage.getItem("adminId"));
    const data = {
      Email: mail,
      UserId: Id,
    };
    axios({
      method: "POST",
      url: `${BASE_URLs}/AddOrganization/GetLogo`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    }).then((response) => {
      this.setState({
        Ulogo: response.data,
      });
    });
    this.profilelist();
    this.GetUserInfo();
    this.GetOrgLogo();
    this.GetNotification();
    this.GetSPNotification();
    this.GetSPNotificationMsg();
    this.GetSPNotificationuser();
    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    async function fetchData() {
      try {
        // Fetch data from multiple sources concurrently
        await Promise.all([
          this.UserTeam(),
          this.fetchNotificationCount(),
          this.UpdateLocalStorage(),
          // this.callStatusapi(),
          this.GetAnnCount(),
          this.GetAnnouncementNotificationuser()
        ]);
        // console.log("Data fetched successfully");
      } catch (error) {
        // console.error("Error fetching data:", error);
        // Handle errors gracefully
      }
    }
    
    // Start fetching data
    fetchData.call(this);
    
    
    
    
    this.NotificationCountUser();

    this.SkillDetailList();
    this.Team();

  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.interval);
  }

  fetchNotificationCount = async () => {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    try {
      const response = await fetch(
        `${BASE_URLs}/Notification/Notification`,
        options
      );
      const data = await response.json();
      this.setState({ notificationCount: data.totalNotificationCount });
      // // console.log("countssss", data);
      if (this.state.notificationCount.length !== 0) {
        this.setState({
          Noticount: this.state.notificationCount[0].noticount,
        });
      }
    } catch (error) {
      // // console.log(error);
    }
    setInterval(1000);
  };


  Messagecount = async (Id) => {
    await fetch(`${BASE_URLs}/Notification/Messagecount`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Notification_Id: Id,
      }),
    }).then((result) => {
      result.json().then((resp) => {
        localStorage.setItem("success", JSON.stringify(resp.status));
        var a = localStorage.getItem("success");
        var b = JSON.parse(a);
        if (b === "Success") {
          localStorage.removeItem("success");
        }
      });
    });
    this.fetchNotificationCount();
    this.NotificationCountUser();
  };

  NotificationCountUser = async () => {
    try {
      const response = await fetch(
        `${BASE_URLs}/Notification/NotificationUser`
      );
      const data = await response.json();
      this.setState({ notificationUser: data.count });
      // // console.log(data);
      if (this.state.notificationUser.length !== 0) {
        this.setState({
          Noticount: this.state.notificationUser[0].noticount,
        });
      }
    } catch (error) {
      // // console.log(error);
    }
  };
  getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }
  ProfileModel = () => {
    this.setState((prevState) => ({
      ProfileModel: !prevState.ProfileModel,
    }));
  };

  AsAdminModel = () => {
    this.setState((prevState) => ({
      AsAdminModel: !prevState.AsAdminModel,
    }));
  };

  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }
  profilelist() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Profile/Get`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ profile: data });
        // // console.log("profilecheck", data);
      });
  }

  GetNotification() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/GetNotification`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Notification: data });
        if (this.state.Notification.length !== 0) {
          this.setState({
            Noticount: this.state.Notification[0].noticount,
          });
        }
      });
  }

  GetAnnCount() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/GetAdminAnnSeenCount`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Announcement: data });
        if (this.state.Announcement.length !== 0) {
          this.setState({
            Anncount: this.state.Announcement[0].anncount,
          });
        }
      });
  }
  GetSPNotification() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Notification/GetNotification`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.length !== 0) {
          // // console.log(data[0]);
          this.setState({ Unreadcount: data[0].count });
        }
      });
  }
  GetSPNotificationuser() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Notification/GetUserNotification`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.length !== 0) {
          // // console.log(data[0]);
          this.setState({
            Unreadcountuser: data[0].unreadcount,
          });
        }
      });
  }

  GetAdNotification() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/GetNotification`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.length !== 0) {
          this.setState({
            UnreadAdNoticount: data[0].noticount,
          });
        }
      });
  }

  Noticount = async (Id) => {
    await fetch(`${BASE_URLs}/Notification/Noticount`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Notification_Id: Id,
      }),
    }).then((result) => {
      result.json().then((resp) => {
        localStorage.setItem("success", JSON.stringify(resp.status));
        var a = localStorage.getItem("success");
        var b = JSON.parse(a);
        if (b === "Success") {
          localStorage.removeItem("success");
        }
      });
    });
    this.GetNotification();
  };

  getNumberLength(num) {
    if (num === 0) {
      return 1;
    }
    return Math.floor(Math.log10(Math.abs(num))) + 1;
  };

  UserTeam() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetTeamStatus`, options)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => parseInt(a.teamId) - parseInt(b.teamId));
        this.setState({
          UserTeam: data,
          UserTeams: data,
        });
        // // console.log("hess1", data);
      });
  }

  toggleclick (){
    var menu = document.getElementById("menuu");
    menu.classList.toggle("openmenu")
  }

  render() {
    var name = JSON.parse(localStorage.uname);

    var usernameA = name.toUpperCase();
    var username = name.replace(/\s/g, '');
    var Email = JSON.parse(localStorage.email);

    const Role = JSON.parse(localStorage.getItem("Role"));
    const { User, SkillDetails, Team, UserTeam } = this.state;
    const { comlogo } = this.state;
    const { Ulogo } = this.state;

    const { notificationCount, Trainer, profile } = this.state;
    return (
      <>
        {this.state.logoutpop ? (
          <LogoutConfirmPop close={() => this.logoutpopfunctn()} />
        ) : null}

        <input type="checkbox" id="menu" />
        <div className="header">
          <label htmlFor="menu" id="menuu" className="menu-bar">
            {/* <i className="ti-menu"></i> */}
            <div onClick={this.toggleclick}>
              <span className="line-1"></span>
              <span className="line-2"></span>
              <span className="line-3"></span>
            </div>
          </label>
          <div className="container-fluid">
            <Link className="navbar-brand logo" to="/Dashboard">
              <img className="logo" src={logo} alt="Logo" height="70" />
            </Link>
          </div>
          <input type="checkbox" style={{ display: "none" }} id="more" />
          {/* {User &&
            User.map((item) =>
              item.role === "User" ? null : ( */}
          <label htmlFor="more" className="more-bar">
            <i className="ti-more" />
          </label>
          {/* )
            )} */}

          {this.state.Organization !== "3" ? (
            <>
              <div id="navright">
                <div
                  // className="noti"
                  className="noti"
                >
                  {User &&
                    User.map((item) =>
                      item.role === "Platform Admin" ||
                        item.role === "User" ? null : (
                        this.props.active === "/AdminProfile" ?
                          null :
                          <Link to="/Chatbox">
                            {this.state.Organization === "3" ||
                              this.state.Organization === "4" ||
                              this.state.Organization === "2" ||
                              this.state.Organization === "0" ? null :
                              <>
                              
                                <div className={this.getNumberLength(notificationCount) === 2 ? "noti1" : ""}>
                                  <i
                                    className={
                                      this.state.Messagecount === 0 ||
                                        notificationCount === 0
                                        ? "far fa-comment notibell"
                                        : "far fa-comment notibell"
                                    }
                                    style={this.getNumberLength(notificationCount) === 2 ? { marginLeft: "-2px" } : { marginLeft: "50px" }}
                                  >

                                    {this.state.Noticount === 1 ||
                                      notificationCount === 0 ? null : (
                                      <span
                                        className={this.getNumberLength(notificationCount) === 2 ? "newbadgead" :
                                          "newbadges"}>
                                        {notificationCount}
                                      </span>
                                    )}
                                  </i>
                                </div>{" "}

                              </>}
                          </Link>
                      )
                    )}

                  {SkillDetails.length == 0
                    ? null
                    : User &&
                    User.map((item) =>
                      item.role === "Admin" || item.role === "" ? null : (
                        <Link to="/Userchating">
                          <>
                            <i
                              className={
                                this.state.Messagecount === 0 ||
                                  notificationCount === 0
                                  ? "far fa-comment notibell"
                                  : "far fa-comment notibell"
                              }

                              style={this.getNumberLength(notificationCount) === 2 ? { marginLeft: "-2px" } :
                                { marginLeft: "50px" }}

                            >

                              {this.state.Noticount === 1 ||
                                notificationCount === 0 ? null : (
                                <span
                                  className={this.getNumberLength(notificationCount) === 2 ? "newbadgead1" :
                                    "newbadges1"}>
                                  {notificationCount}
                                </span>
                              )}
                            </i>{" "}
                          </>
                        </Link>
                      )
                    )}

                  {SkillDetails.length == 0
                    ? null
                    : User &&
                    User.map((item) =>
                      item.role === "Admin" || item.role === "" ? null : (
                        <Link to="/UserNotifications">
                          <div className={this.getNumberLength(notificationCount) === 2 ? "noti2" : ""}>
                            <i
                              className={
                                this.state.Unreadcountuser === 0 ||
                                  this.state.Unreadcountuser.length === 0
                                  ? "ti-bell notibell"
                                  : "ti-bell notibell"
                              }

                              style={this.getNumberLength(notificationCount) === 2 ? { marginLeft: "-2px" } : {
                                fontSize: "20px",
                                // marginLeft: "15px",
                                // right: "90px",
                              }}

                            >

                              {this.state.Unreadcountuser === 0 ||
                                this.state.Unreadcountuser.length === 0 ? null : (
                                <span
                                  className={this.getNumberLength(notificationCount) === 2 ? "newbadgeadq" : "newbadge"}>
                                  {this.state.Unreadcountuser}
                                </span>
                              )}
                            </i>{" "}
                          </div>
                        </Link>
                      )
                    )}
                  {/* {User &&
                    User.map((item) =>
                      item.role === "User" ? (
                        <div
                          style={{
                            textAlign: "center",

                            display: "flex",

                            alignItems: "center",

                            color: "white",

                            marginTop: "10%",

                            // marginBottom: "10%",

                            //  marginLeft: "110%",
                          }}
                          id={
                            username.length <= 4
                              ? "NameB"
                              : username.length === 5
                                ? "NameC"
                                : username.length === 6
                                  ? "Name6"
                                  : "NameA"
                          }
                        >
                          <span

                            style={{ display: "inline-flex" }}
                          >
                            {" "}
                            <h6
                              id=""
                            // style={{
                            //   display: "flex",
                            //   alignItems: "center",
                            //   marginLeft: "auto",
                            //   marginRight: "auto",
                            //   position: "relative",
                            // }}
                            >
                              {username}
                            </h6>
                            &nbsp;&nbsp;
                          </span>
                        </div>
                      ) : null
                    )} */}

                  {User &&
                    User.map((item) =>
                      item.role === "Platform Admin" ||
                        item.role === "User" ? null : (
                        this.props.active === "/AdminProfile" ?
                          null :
                          <Link to="/AdminNotification">
                            <>
                              <div className={this.getNumberLength(notificationCount) === 2 ? "noti2" : ""}>
                                <i
                                  className={
                                    this.state.Noticount === 0 ||
                                      this.state.Notification.length === 0
                                      ? "ti-bell notibell"
                                      : "ti-bell notibell"
                                  }
                                  style={{ fontSize: "20px" }}
                                >
                                  {this.state.Noticount === 0 ||
                                    this.state.Notification.length === 0 ? null : (

                                    <span
                                      className={this.getNumberLength(notificationCount) === 2 ? "newbadgeadq" : "newbadge"}
                                    >



                                      {this.state.Noticount}
                                    </span>
                                  )}
                                </i></div>{" "}
                            </>
                          </Link>
                      )
                    )}

                  <h1>
                    {this.state.Organization !== 3 ||
                      this.state.Organization !== 4}
                  </h1>
                </div>

                {Role === "User" ||
                  window.location.pathname === "/UserDashboard" ? (
                  <div
                    id="entitylogo"
                    className="logocm1"
                    title={logo.company_Name}
                  // style={{
                  //   float: "left",
                  //   marginTop: "-32px",
                  //   marginLeft: "257px",
                  // }}
                  >
                    {Ulogo.map((logo) => (
                      <div
                        key={logo.id}
                        className="logocm1"
                        title={logo.company_Name}
                      // style={{
                      //   display: "flex",
                      //   alignItems: "center",
                      //   marginRight: "10px",
                      // }}
                      >
                        <img
                          className="user-img img-circle"
                          src={logo.com_logo}
                          title={logo.company_Name}
                          id="logocm"
                          style={{
                            borderRadius: "50%",
                            border: "2px solid white",
                            // height: "45px",
                            // width: "45px",
                          }}
                          alt="user-img"
                        />
                        <h6
                          id="logoN"
                        // style={{
                        //   color: "white",
                        //   marginLeft: "5px",
                        //   marginTop: "10px",
                        // }}
                        >
                          {logo.company_Name}
                        </h6>
                      </div>
                    ))}
                  </div>
                ) : null}

                {Role === "Admin" ||
                  window.location.pathname === "/Dashboard" ? (
                  <div
                    id="entitylogo"
                    className="logocm1"
                    title={logo.company_Name}
                  >
                    {Ulogo.map((logo) => (
                      <div
                        key={logo.id}
                        className="logocm1"
                        title={logo.company_Name}
                      >
                        <img
                        hidden
                          className="user-img img-circle"
                          src={logo.com_logo}
                          title={logo.company_Name}
                          id="logocm"
                          style={{
                            borderRadius: "50%",
                            border: "2px solid white",
                            // height: "45px",
                            // width: "45px",
                          }}
                          alt="user-img"
                        />
                        <h6
                          id="logoAdmin">
                          {logo.company_Name}
                        </h6>
                      </div>
                    ))}
                  </div>
                ) : null}


                {comlogo.length === 0 ||
                  this.props.active === "/AdminProfile" ? (
                  <div

                    id="cmlogo"
                    className="logouser"
                    title={username}
                  // style={{
                  //   float: "right",
                  //   marginTop: "-36px",
                  //   marginRight: "87px",
                  // }}
                  >
                    {profile.map((profile) => (
                      <div style={{ display: "grid", margin: '0 auto', textAlign: 'center', width: '50px', position: 'relative', whiteSpace: 'nowrap' }}>
                        <img
                          alt="companylogo"
                          src={profile.profile_Picture}
                          id="logocm"
                          style={{
                            borderRadius: "50%",
                            border: "2px solid white",
                            // height: "48px",
                            // width: "50px",
                          }}
                        />
                        <div id="more-details" style={{ color: "white", position: 'absolute', top: '100%', textAlign: 'center', left: '50%', transform: 'translateX(-50%)', wordWrap: 'break-word' }}>{username}</div>
                      </div>



                    ))}

                  </div>
                ) : (
                  <div

                    id="cmlogo"
                    className="logouser"
                    title={username}
                  // style={{
                  //   float: "right",
                  //   marginTop: "-32px",
                  //   marginRight: "87px",
                  // }}
                  >


                    {comlogo.map((comlogo) => (
                      <Link>
                        <div style={{ display: "grid", margin: '0 auto', textAlign: 'center', width: '50px', position: 'relative', whiteSpace: 'nowrap' }}>
                          <img
                            alt="companylogo"
                            src={comlogo.com_logo}
                            id="logocm"
                            style={{
                              borderRadius: "50%",
                              border: "2px solid white",
                              // height: "48px",
                              // width: "50px",
                            }}
                          />
                          <div id="more-details" style={{ color: "white", position: 'absolute', top: '100%', textAlign: 'center', left: '50%', transform: 'translateX(-50%)', wordWrap: 'break-word' }}>{username}</div>
                        </div>

                      </Link>
                    ))}
                  </div>
                )}
              </div>
              <span
                className="dropdown"
                //  id="power"

                id="power"
                style={{
                  background: 'none'
                }}
              >
                {" "}
                <Link to="/TeamDash" style={{ marginLeft: "-3px" }}></Link>
                &nbsp;&nbsp;
                <label className="label-text">
                  <i
                    className="icofont icofont-power"
                    style={{ color: "white" }}
                    onClick={() => this.logoutpopfunctn()}
                  />
                </label>
              </span>
            </>
          ) : null}



          {User &&
            User.map((item) =>
              item.role === "Platform Admin" ? (
                <div id="navright">

                  <Link>
                    <div className="noti" style={{ display: 'flex' }}>
                      <span>
                        <Link to="/Notifications">
                          <>
                            <div className={this.getNumberLength(notificationCount) === 2 ? "noti2" : ""}>
                              <i
                                className={
                                  this.state.Unreadcount === 0 ||
                                    this.state.Unreadcount.length === 0
                                    ? "ti-bell notibell"
                                    : "ti-bell notibell"
                                }
                                style={{ fontSize: "20px" }}
                              >
                                {this.state.Unreadcount === 0 ||
                                  this.state.Unreadcount.length === 0 ? null : (
                                  <span
                                    className={this.getNumberLength(notificationCount) === 2 ? "newbadgeadq" : "newbadge"}
                                  >
                                    {this.state.Unreadcount}
                                  </span>
                                )}
                              </i></div>{" "}
                          </>
                        </Link>
                      </span>

                      <span className="">
                        <Link to="/Messages">
                          <>
                            <div className={this.getNumberLength(notificationCount) === 2 ? "noti2" : ""}>
                              <i
                                className={
                                  this.state.UnreadcountMsg === 0 ||
                                    this.state.UnreadcountMsg.length === 0
                                    ? "ti-comment notibell"
                                    : "ti-comment notibell"
                                }
                                style={{ fontSize: "20px" }}
                              >
                                {this.state.UnreadcountMsg === 0 ||
                                  this.state.UnreadcountMsg.length === 0 ? null : (
                                  <span
                                    className={this.getNumberLength(notificationCount) === 2 ? "newbadgead" : "newbadge"}
                                  >



                                    {this.state.UnreadcountMsg}
                                  </span>
                                )}
                              </i></div>{" "}
                          </>
                        </Link>
                      </span>
                    </div>
                    <div
                      //  style={{
                      //   display: "grid", margin: '0 auto', textAlign: 'center'
                      //    , float: 'right', marginTop: '-34px', marginRight: '3%'
                      // }}
                      id="cmlogo"
                      className="logouser"
                      title={username}
                    >
                      <div style={{ display: "grid", margin: '0 auto', textAlign: 'center', width: '50px', position: 'relative', whiteSpace: 'nowrap' }}>
                        <img
                          alt="img"
                          src={SAprofile}
                          id="logocm"
                          style={{
                            borderRadius: "50%",
                            border: "2px solid white",
                            // height: "48px",
                            // width: "50px",
                          }}
                        />

                        <div id="more-details" style={{ color: "white", position: 'absolute', top: '100%', textAlign: 'center', left: '50%', transform: 'translateX(-50%)', wordWrap: 'break-word' }}>{username}</div>
                      </div>
                    </div>

                    <span
                      className="dropdown"
                      id="power"
                      style={{ background: 'none' }}
                    >
                      {" "}
                      <Link to="/TeamDash" style={{ marginLeft: "-3px" }}></Link>
                      &nbsp;&nbsp;
                      <label className="label-text">
                        <i
                          className="icofont icofont-power"
                          style={{ color: "white" }}
                          onClick={() => this.logoutpopfunctn()}
                        />
                      </label>
                    </span>
                  </Link></div>
              ) : null
            )}

          {/* {Role === "Platform Admin" ?
            <>

<div 
// id="cmlogo"  className="logouser"
//                     title={username}
id="SADiv"

                    >
                 
              <Link>
              <div className="SANamelen" style={{display:'flex'}}>
                <span>
                        <Link to="/Notifications">
                          <>
                            <div className={this.getNumberLength(notificationCount) === 2 ? "noti2" : ""}>
                              <i
                                className={
                                  this.state.Unreadcount === 0 ||
                                    this.state.Unreadcount.length === 0
                                    ? "ti-bell notibell"
                                    : "ti-bell notibell"
                                }
                                style={{ fontSize: "20px" }}
                              >
                                {this.state.Unreadcount === 0 ||
                                  this.state.Unreadcount.length === 0 ? null : (
                                  <span
                                    className={this.getNumberLength(notificationCount) === 2 ? "newbadgeadq" : "newbadge"}
                                  >
                                    {this.state.Unreadcount}
                                  </span>
                                )}
                              </i></div>{" "}
                          </>
                        </Link>
                </span>

                <span className="">
                        <Link to="/Messages">
                          <>
                            <div className={this.getNumberLength(notificationCount) === 2 ? "noti2" : ""}>
                              <i
                                className={
                                  this.state.UnreadcountMsg === 0 ||
                                    this.state.UnreadcountMsg.length === 0
                                    ? "ti-comment notibell"
                                    : "ti-comment notibell"
                                }
                                style={{ fontSize: "20px" }}
                              >
                                {this.state.UnreadcountMsg === 0 ||
                                  this.state.UnreadcountMsg.length === 0 ? null : (
                                  <span
                                    className={this.getNumberLength(notificationCount) === 2 ? "newbadgead" : "newbadge"}
                                  >



                                    {this.state.UnreadcountMsg}
                                  </span>
                                )}
                              </i></div>{" "}
                          </>
                        </Link>
                </span>
</div>
                <div style={{
                  display: "grid", margin: '0 auto', textAlign: 'center'
                   , float: 'right', marginTop: '-34px', marginRight: '3%'
                }}>
                  <img
                    alt="img"
                    src={SAprofile}
                    id="logocm"
                    style={{
                      borderRadius: "50%",
                      border: "2px solid white",
                      // height: "48px",
                      // width: "50px",
                    }}
                  />

                  <span id="more-details" style={{ color: "white", fontSize: '14px' }}>{username}</span>
                </div>

                <span
                  className="dropdown"
                  id="SApower"
                  style={{ background: 'none' }}
                >
                  {" "}
                  <Link to="/TeamDash" style={{ marginLeft: "-3px" }}></Link>
                  &nbsp;&nbsp;
                  <label className="label-text">
                    <i
                      className="icofont icofont-power"
                      style={{ color: "white" }}
                      onClick={() => this.logoutpopfunctn()}
                    />
                  </label>
                </span>
              </Link></div></> : null} */}
        </div>


        {/* sidebar */}
        {/* {User.map(User=>  */}
        <div className="side-menu">
          <div className="main-sidemenu">
            <div
              className="row"
              id="sidelogo"
              style={{ padding: "20px", paddingBottom: "0px" }}
            >
              <img
                src={logo}
                alt="oatlogo"
                style={{
                  height: "68px",
                  width: "68px",
                  marginTop: "-8px",
                  marginLeft: "0",
                }}
              />
              &nbsp;&nbsp;&nbsp;
              <h6 style={{ fontSize: "17px" }}>
                {/* Awesome Team{" "} */}
                {User &&
                  User.map((item) =>
                    item.role === "Platform Admin" ? (
                      <>
                        <h6 style={{ fontSize: "17px", marginTop: "10%" }}>
                          Awesome Team{" "}
                        </h6>
                        <h6 style={{ marginTop: "13px", textAlign: "center" }}>
                          Platform Admin
                        </h6>
                      </>
                    ) : (
                      <h5> </h5>
                    )
                  )}
              </h6>
            </div>
            <h6 style={{ fontSize: "17px" }}>
              {User &&
                User.map((item) =>
                  item.role === "Admin" ? (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "-17%",
                          marginBottom: "12%",
                          marginLeft: "32%",
                        }}
                      >
                        <h6 style={{ fontSize: "17px" }}>
                          Awesome Team{" "}
                        </h6>
                        {/* <span
                          id="more-details"
                          style={{ display: "inline-flex" }}
                        >
                          {" "}
                          <h6>{username}</h6>&nbsp;&nbsp;
                        </span> */}
                      </div>
                    </>
                  ) : null
                )}
            </h6>
            {User &&
              User.map((item) =>
                item.role === "User" ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "-20%",
                      marginBottom: "12%",
                      marginLeft: "32%",
                    }}
                  >
                    <h6 style={{ fontSize: "17px" }}>Awesome Team </h6>
                    {/* <span id="more-details" style={{ display: "inline-flex" }}>
                      {" "}
                      <h6>{username}</h6>&nbsp;&nbsp;
                    </span> */}
                  </div>
                ) : null
              )}
            {User &&
              User.map((item) =>
                item.role === "User" ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "-14%",
                      marginBottom: "5%",
                      marginLeft: "32%",
                    }}
                  >
                    <span id="more-details" style={{ display: "inline-flex" }}>
                      {" "}
                      {/* <h6>{username}</h6>&nbsp;&nbsp; */}
                    </span>
                  </div>
                ) : null
              )}

            <nav className="navmenu" style={{ height: "100vh" }}>
              {/* <div>     */}

              <>
                {" "}
                <ul className="dashboard-menu">
                  {User &&
                    User.map((item) =>
                      item.role === "User" ? (
                        <div style={{ lineHeight: "0" }}>
                          <li
                            className={
                              this.props.active === "/Userdashboard"
                                ? "sideactive"
                                : null
                            }
                          >
                            <Link className="sideline" to="/Userdashboard">
                              {" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span>
                                <i className="ti-home" />
                              </span>{" "}
                              &nbsp;&nbsp;
                              <span id="sidebarlist"> Home </span>
                              <span />
                            </Link>
                          </li>


                          {UserTeam.map((Teams) => {
                            if (Teams.email === Email) {

                              return <>

                                {(Teams.status == 0 || Teams.status == 3) ? null :
                                  <li
                                    id="sidebarChangePassword"
                                    className={
                                      this.props.active === "/Profile"
                                        ? "sideactive"
                                        : null || 
                                        this.props.active === "/ProfileGridDetails"
                                        ? "sideactive"
                                        : null
                                    }
                                  >
                                    <Link className="sideline" to="/ProfileGridDetails">
                                      {" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <span>
                                        <i className="ti-pencil-alt" />
                                      </span>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                      <span id="sidebarlist">Profile</span>
                                      <span />
                                    </Link>
                                  </li>
                                }</>
                            }
                          })}

{UserTeam.map((Teams) => {
                            if (Teams.email === Email) {

                              return <>

                                {(Teams.status == 0 || Teams.status == 3) ? null :
                                  <li
                                    id="sidebarChangePassword"
                                    className={
                                      this.props.active === "/UserTeam"
                                        ? "sideactive"
                                        : null || 
                                        this.props.active === "/Teamgriddetails"
                                        ? "sideactive"
                                        : null
                                    }
                                  >
                                    <Link className="sideline" to="/UserTeam">
                                      {" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <span>
                                        <i className="icofont icofont-people" />
                                      </span>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                      <span id="sidebarlist">Team</span>
                                      <span />
                                    </Link>
                                  </li>
                                }</>
                            }
                          })}
                          {UserTeam.map((Teams) => {
                            if (Teams.email === Email) {

                              return <>

                                {(Teams.status == 0 || Teams.status == 3) ? null :
                                  <>
                                    {SkillDetails.length == 0 ? null : (
                                      <li
                                        id="sidebarChangePassword"
                                        className={
                                          this.props.active === "/Userchating"
                                            ? "sideactive"
                                            : null
                                        }
                                      >
                                        <Link className="sideline" to="/Userchating">
                                          {" "}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <span>
                                            <i
                                              className="far fa-comment"
                                              aria-hidden="true"
                                            ></i>
                                          </span>{" "}
                                          &nbsp;&nbsp;
                                          <span id="sidebarlist"> Town Hall </span>
                                          {this.state.Noticount === 1 ||
                                            notificationCount === 0 ? null : (
                                            <span>({notificationCount})</span>
                                          )}
                                          {/* &nbsp;&nbsp;&nbsp;
                                <span> Discussions </span> */}
                                          <span />
                                        </Link>
                                      </li>
                                    )}
                                  </>
                                }</>
                            }
                          })}
                          {UserTeam.map((Teams) => {
                            if (Teams.email === Email) {

                              return <>

                                {(Teams.status == 0 || Teams.status == 3) ? null :
                                  <>
                                    {SkillDetails.length == 0 ? null : (
                                      <li
                                        id="sidebarChangePassword"
                                        className={
                                          this.props.active === "/UserCompanyDocument"
                                            ? "sideactive"
                                            : null
                                        }
                                      >
                                        <Link
                                          className="sideline"
                                          to="/UserCompanyDocument"
                                        >
                                          {" "}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <span>
                                          <i className="icofont icofont-file-document"></i>
                                          </span>{" "}
                                          &nbsp;&nbsp;&nbsp;
                                          <span id="sidebarlist">Office Documents</span>
                                          <span />
                                        </Link>
                                      </li>
                                    )}
                                  </>
                                }</>
                            }
                          })}
                          {UserTeam.map((Teams) => {
                            if (Teams.email === Email) {

                              return <>

                                {(Teams.status == 0 || Teams.status == 3) ? null :
                                  <>
                                    {SkillDetails.length == 0 ? null : (
                                      <li
                                        id="sidebarChangePassword"
                                        className={
                                          this.props.active === "/UserHoliday"
                                            ? "sideactive"
                                            : null
                                        }
                                      >
                                        <Link className="sideline" to="/UserHoliday">
                                          {" "}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <span>
                                            <i className="icofont icofont-beach" />
                                          </span>{" "}
                                          &nbsp;&nbsp;&nbsp;
                                          <span id="sidebarlist">Holidays</span>
                                          <span />
                                        </Link>
                                      </li>
                                    )}
                                  </>
                                }</>
                            }
                          })}
                         
                          {/* {UserTeam.map((Teams) => {
                            if (Teams.email === Email) {

                              return <>

                                {(Teams.status == 0 || Teams.status == 3) ? null :
                                  <>

                                    {SkillDetails.length == 0 ? null : (
                                      <li
                                        id="sidebartraining"
                                        className={
                                          this.props.active === "/TrainingCard"
                                            ? "sideactive"
                                            : null ||
                                              this.props.active === "/LearningSchedule"
                                              ? "sideactive"
                                              : null ||
                                                this.props.active === "/TrainingSchedule"
                                                ? "sideactive"
                                                : null
                                        }
                                      >
                                        <a href className="sideline">
                                          <span>
                                            <Link
                                              className="sideline"
                                              to="/TrainerDashboard"
                                            >
                                              {" "}
                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                              <span>
                                                <i className="icofont icofont-company"></i>
                                              </span>{" "}
                                              &nbsp;&nbsp;&nbsp;
                                              <span>Training</span>
                                              <span />
                                            </Link>
                                            &nbsp;&nbsp;
                                            <label
                                              className="label-text"
                                              htmlFor="open-Training"
                                              id="sidelidropdownIcon"
                                            >
                                              <i
                                                id="sidedrop"
                                                className="ti-angle-down"
                                              />
                                            </label>
                                          </span>
                                          <input
                                            id="open-Training"
                                            type="checkbox"
                                            className="open-Training"
                                          />
                                          <ul className="Trainingdown" id="listbottom">
                                            &nbsp;&nbsp;&nbsp;
                                            <li>
                                              <Link to={Trainer ? link2 : link1}>
                                                Assigned Training
                                              </Link>
                                            </li>
                                            &nbsp;&nbsp;&nbsp;
                                            <li>
                                              <Link to={Trainer ? link3 : link4}>
                                                Active Training
                                              </Link>
                                            </li>
                                            &nbsp;&nbsp;&nbsp;
                                            <li>
                                              <Link to={Trainer ? link5 : link6}>
                                                Training Documents
                                              </Link>
                                            </li>
                                          </ul>
                                        </a>
                                      </li>
                                    )}
                                  </>
                                }</>
                            }
                          })} */}
                          {UserTeam.map((Teams) => {
                            if (Teams.email === Email) {

                              return <>

                                {(Teams.status == 0 || Teams.status == 3) ? null :
                                  <>
                                    {SkillDetails.length == 0 ? null : (
                                      <li
                                        id="sidebarNotifications"
                                        className={
                                          this.props.active === "/UserNotifications" ||
                                            this.props.active === "/Contactmsg"
                                            ? "sideactive"
                                            : null
                                        }
                                      >
                                        <Link className="sideline" to="/UserNotifications">
                                          {" "}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <span>
                                            <i className="ti-bell" />
                                          </span>{" "}
                                          &nbsp;&nbsp;
                                          <span id="sidebarlist">
                                            {" "}
                                            {this.state.Unreadcountuser === 0 ? (
                                              "Notifications"
                                            ) : (
                                              <>
                                                {" "}
                                                Notifications ({this.state.Unreadcountuser})
                                              </>
                                            )}{" "}
                                          </span>
                                          <span />
                                        </Link>
                                      </li>
                                    )}
                                  </>
                                }</>
                            }
                          })}
                         
                          {UserTeam.map((Teams) => {
                            if (Teams.email === Email) {

                              return <>

                                {(Teams.status == 0 || Teams.status == 3) ? null :
                                  <>

                                    {SkillDetails.length == 0 ? null : (
                                      <li
                                        id="sidebarChangePassword"
                                        className={
                                          this.props.active === "/UserAnnouncement"
                                            ? "sideactive"
                                            : null
                                        }
                                      >
                                        <Link className="sideline" to="/UserAnnouncement">
                                          {" "}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <span>
                                            <i className="icofont icofont-mega-phone" />
                                          </span>{" "}
                                          &nbsp;&nbsp;
                                          <span id="sidebarlist">
                                            {" "}
                                            {this.state.Unreadcountannounce > 0 ? (
                                              <span>
                                                Announcements(
                                                {this.state.Unreadcountannounce})
                                              </span>
                                            ) : (
                                              <span> Announcements</span>
                                            )}{" "}
                                          </span>
                                          <span />
                                        </Link>
                                      </li>
                                    )}
                                  </>
                                }</>
                            }
                          })}

                          

                          {UserTeam.map((Teams) => {
                            if (Teams.email === Email) {

                              return <>

                                {(Teams.status == 0 || Teams.status == 3) ? null :
                                  <li
                                    id="sidebarChangePassword"
                                    className={
                                      this.props.active === "/ChangePwd"
                                        ? "sideactive"
                                        : null
                                    }
                                  >
                                    <Link className="sideline" to="/ChangePwd">
                                      {" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <span>
                                        <i className="icofont icofont-key" />
                                      </span>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                      <span id="sidebarlist">Change Password</span>
                                      <span />
                                    </Link>
                                  </li>
                                }</>
                            }
                          })}

                          <li onClick={() => this.logoutpopfunctn()}>
                            &nbsp;&nbsp;&nbsp;{" "}
                            <Link>
                              {" "}
                              <i className="icofont icofont-power" id="sidebarlist"></i>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span id="sidebarlist">Logout</span>
                            </Link>
                          </li>
                          {/* <li id="sidebarChangePassword" className={this.props.active === "#/Chatbox" ? "sideactive" : null}>
                      <Link className="sideline" to="/Chatbox">  &nbsp;&nbsp;&nbsp;&nbsp;
                        <span ><i className="fa fa-comment" aria-hidden="true"></i></span> &nbsp;&nbsp;&nbsp;
                        <span >  Chat </span>
                        <span />
                      </Link>
                    </li> */}
                          {/* <li
                            id="sidebarChangePassword"
                            className={
                              this.props.active === "#/Chatbox"
                                ? "sideactive"
                                : null
                            }
                          >
                            <Link className="sideline" to="/Chatbox">
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span>
                                <i className="fa fa-comment" aria-hidden="true"></i>
                              </span>{" "}
                              &nbsp;&nbsp;&nbsp;
                              <span> Chat </span>
                              <span />
                            </Link>
                          </li> */}
                        </div>
                      ) : null
                    )}

                  {User &&
                    User.map((item) =>
                      item.role === "Platform Admin" ? (
                        <div style={{ lineHeight: "0" }}>
                          <li
                            className={
                              this.props.active === "/Dashboard"
                                ? "sideactive"
                                : null
                            }
                          >
                            <Link className="sideline" to="/Dashboard">
                              {" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span>
                                <i className="ti-home" />
                              </span>{" "}
                              &nbsp;&nbsp;
                              <span id="sidebarlist">Home </span>
                              <span />
                            </Link>
                          </li>
                          <li
                            id="mainorg"
                            className={
                              this.props.active === "/Organization" ||
                                this.props.active === "/EditOrganization" ||
                                this.props.active === "/OrganizationDetail" ||
                                this.props.active === "/RejectOrg" ||
                                this.props.active === "/ActiveReson"
                                ? "sideactive"
                                : null
                            }
                          >
                            <Link to="/Organization">
                              <a href>
                                {" "}
                                &nbsp;&nbsp;&nbsp;
                                <span>
                                  <i
                                    className="icofont icofont-bank-alt"
                                    style={{ marginLeft: "4px" }}
                                  />
                                </span>{" "}
                                &nbsp;&nbsp;
                                <span id="sidebarlist">
                                  {item.role === "Platform Admin"
                                    ? "Entities"
                                    : "Entity Profile "}{" "}
                                </span>
                                <span />
                              </a>{" "}
                            </Link>
                          </li>

                          <li
                            id="sidebarDirectories"
                            className={
                              this.props.active === "/SACategory" ||
                                this.props.active === "/Gridskill" ||
                                this.props.active === "/SACourse" ||
                                this.props.active === "/SASpecialization" ||
                                this.props.active === "/SADegree" ||
                                this.props.active === "/SADegreeLevel" ||
                                this.props.active === "/SADesignation" ||
                                this.props.active === "/SADepartment" ||
                                this.props.active === "/Masterpage"
                                ? "sideactive"
                                : null
                            }
                          >
                            <Link className="sideline" to="/Masterpage">
                              {" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span>
                                <i className="icofont icofont-people" />
                              </span>{" "}
                              &nbsp;&nbsp;
                              <span id="sidebarlist">Categories</span>
                            </Link>
                          </li>

                          {/* <li
                            id="sidebarTraining"
                            className={
                              this.props.active === "/Train"
                                ? "sideactive"
                                : null
                            }
                          >
                            <a href className="sideline">
                              <span>
                                <Link className="sideline" to="/Train">
                                  {" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>
                                    <i
                                      className="icofont icofont-company"
                                      style={{ fontSize: "14px" }}
                                    />
                                  </span>{" "}
                                  &nbsp;&nbsp;
                                  <span>Trainings</span>
                                  <span />
                                </Link>
                                &nbsp;&nbsp;
                                <label
                                  className="label-text"
                                  htmlFor="open-team"
                                  id="sidelidropdownIcon"
                                >
                                  <i className="ti-angle-down" />
                                </label>
                              </span>
                              <input
                                id="open-team"
                                type="checkbox"
                                className="open-team"
                              />
                              <ul className="team" id="listbottom">
                                &nbsp;&nbsp;&nbsp;{" "}
                                <li>
                                  <Link to="/Train">Training Master</Link>
                                </li>
                              </ul>
                            </a>
                          </li> */}

                          <li
                            id="sidebarAnnouncements"
                            className={
                              this.props.active === "/AnnouncementPreviewSP"
                                ? "sideactive"
                                : null ||
                                  this.props.active === "/Announcement"
                                  ? "sideactive"
                                  : null
                            }
                          >
                            <Link className="sideline" to="/AnnouncementPreviewSP">
                              {" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span>
                                <i className="icofont icofont-mega-phone" />
                              </span>{" "}
                              &nbsp;&nbsp;
                              <span
                                style={{ marginLeft: "0px" }}
                                id="sidebarlist"
                              >
                                Announcements
                              </span>
                              <span />
                            </Link>
                          </li>

                          <li
                            id="sidebarNotifications"
                            className={
                              this.props.active === "/Notifications" ||
                                this.props.active === "/Contactmsg"
                                ? "sideactive"
                                : null
                            }
                          >
                            <Link className="sideline" to="/Notifications">
                              {" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span>
                                <i className="ti-bell" />
                              </span>
                              &nbsp;&nbsp;
                              <span id="sidebarlist">

                                {this.state.Unreadcount === 0 ? (
                                  "Notifications"
                                ) : (
                                  <> Notifications ({this.state.Unreadcount})</>
                                )}{" "}
                              </span>
                              <span />
                            </Link>
                          </li>

                          <li
                            id="sidebarNotifications"
                            className={
                              this.props.active === "/Messages" ||
                                this.props.active === "/Contactmsg"
                                ? "sideactive"
                                : null
                            }
                          >
                            <Link className="sideline" to="/Messages">
                              {" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span>
                                <i className="ti-comment" />
                              </span>{" "}
                              &nbsp;
                              <span id="sidebarlist">
                                {" "}
                                {this.state.UnreadcountMsg === 0 ? (
                                  "Messages"
                                ) : (
                                  <> Messages ({this.state.UnreadcountMsg})</>
                                )}{" "}
                              </span>
                              <span />
                            </Link>
                          </li>

                          <li
                            id="sidebarChangePassword"
                            className={
                              this.props.active === "/ChangePwd"
                                ? "sideactive"
                                : null
                            }
                          >
                            <Link className="sideline" to="/ChangePwd">
                              {" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span>
                                <i className="icofont icofont-key" />
                              </span>{" "}
                              &nbsp;&nbsp;
                              <span id="sidebarlist">Change Password</span>
                              <span />
                            </Link>
                          </li>

                          <li onClick={() => this.logoutpopfunctn()}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Link>

                              <i className="icofont icofont-power" id="sidebarlist"></i>
                              &nbsp;&nbsp;&nbsp;
                              <span id="sidebarlist">Logout</span>
                            </Link>
                          </li>
                        </div>
                      ) : null
                    )}

                  {/* Admin Sidebar */}

                  {User &&
                    User.map((item) =>
                      item.role === "Admin" ? (
                        <>
                          {this.state.Organization === "3" ||
                            this.state.Organization === "4" ||
                            this.state.Organization === "2" ||
                            this.state.Organization === "0" ? (
                            <>
                              <div style={{ lineHeight: "0" }}>
                                <li
                                  className={
                                    this.props.active === "/Dashboard" ||
                                      this.props.active === "/AdminHome"
                                      ? "sideactive"
                                      : null
                                  }
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <i className="ti-home"></i> &nbsp;&nbsp;&nbsp;&nbsp;
                                  {this.state.Organization === "3" ||
                                    this.state.Organization === "4" ? (
                                    <Link
                                      to="/AdminHome"
                                      style={{ marginLeft: "-3px" }}
                                      id="sidebarlist"
                                    >
                                      Home
                                    </Link>
                                  ) : (
                                    <Link
                                      to="/Dashboard"
                                      style={{ marginLeft: "-7px" }}
                                      id="sidebarlist"
                                    >
                                      Home
                                    </Link>
                                  )}{" "}
                                </li>

                                <li
                                  id="mainorg"
                                  className={
                                    this.props.active === "/Organization" ||
                                      this.props.active === "/AddOrganization" ||
                                      this.props.active === "/EditOrganization"
                                      ? "sideactive"
                                      : null
                                  }
                                >
                                  <a href>
                                    {" "}
                                    &nbsp;&nbsp;&nbsp;
                                    <span>
                                      <i
                                        className="icofont icofont-bank-alt"
                                        style={{
                                          marginLeft: "4px",
                                          fontSize: "15px",
                                        }}
                                      />
                                    </span>{" "}
                                    &nbsp;
                                    <span id="sidebarlist">
                                      {this.state.Organization === "3" ||
                                        this.state.Organization === "4" ? (
                                        <Link to="/AddOrganization">
                                          {" "}
                                          Entity Profile
                                        </Link>
                                      ) : (
                                        <Link to="/Organization">
                                          {" "}
                                          Entity Profile
                                        </Link>
                                      )}
                                    </span>
                                    <span />
                                  </a>
                                </li>

                                {this.state.Organization === "0" ?
                               
                                <li
                                      id="sidebarAnnouncements"
                                      className={
                                        this.props.active ===
                                          "/AnnouncementPreview"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/AdminAnnouncement"
                                            ? "sideactive"
                                            : null ||
                                              this.props.active ===
                                              "/AllAnnouncement"
                                              ? "sideactive"
                                              : null
                                      }
                                    >
                                      <Link
                                        className="sideline"
                                        to="/AnnouncementPreview"
                                      >
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>
                                          <i className="icofont icofont-mega-phone" />
                                        </span>{" "}
                                        &nbsp;&nbsp;&nbsp;
                                        <span
                                          style={{ marginLeft: "-5px" }}
                                          id="sidebarlist"
                                        >
                                          {this.state.Anncount === 0 ? (
                                            "Announcements"
                                          ) : (
                                            <>
                                             
                                              Announcements ({this.state.Anncount})
                                            </>
                                          )}

                                        </span>
                                        <span />
                                      </Link>
                                    </li>:null}

                                   
                                <li
                                  id="sidebarAnnouncements"
                                  className={
                                    this.props.active === "/AdminNotification"
                                      ? "sideactive"
                                      : null
                                  }
                                >
                                  <Link
                                    className="sideline"
                                    to="/AdminNotification"
                                  >
                                    {" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>
                                      <i className="ti-bell" />
                                    </span>{" "}
                                    &nbsp;&nbsp;&nbsp;
                                    <span
                                      style={{ marginLeft: "-5px" }}
                                      id="sidebarlist"
                                    >
                                     
                                      {this.state.Noticount === 0 ? (
                                        "Notifications"
                                      ) : (
                                        <>
                                         
                                          Notifications ({this.state.Noticount})
                                        </>
                                      )}

                                    </span>
                                    <span />
                                  </Link>
                                </li>
                                <li
                                  className={
                                    this.props.active === "/contact"
                                      ? "sideactive"
                                      : null
                                  }
                                >
                                  <a href>
                                    {" "}
                                    &nbsp;&nbsp;&nbsp;
                                    <span>
                                      <i
                                        className="icofont icofont-phone"
                                        style={{
                                          fontSize: "15px",
                                          marginLeft: "4px",
                                        }}
                                      />
                                    </span>{" "}
                                    &nbsp;
                                    <span id="sidebarlist">
                                      {" "}
                                      <Link
                                        to="/contact"
                                        style={{ marginLeft: "0px" }}
                                      >
                                        Contact Admin
                                      </Link>
                                    </span>
                                    <span />
                                  </a>
                                </li>
                                <li onClick={() => this.logoutpopfunctn()}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <Link>

                                    <i className="icofont icofont-power" id="sidebarlist"></i>
                                    &nbsp;&nbsp;&nbsp;
                                    <span id="sidebarlist">Logout</span>
                                  </Link>
                                </li>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.props.active === "/AdminProfile" ? (
                                <>
                                  <div style={{ lineHeight: "0" }}>
                                    <li
                                      className={
                                        this.props.active === "/Dashboard"
                                          ? "sideactive"
                                          : null
                                      }
                                    >
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <i className="ti-home"></i>{" "}
                                      &nbsp;&nbsp;&nbsp;{" "}
                                      <Link
                                        to="/Dashboard"
                                        style={{ marginLeft: "-7px" }}
                                        id="sidebarlist"
                                      >
                                        Home
                                      </Link>
                                    </li>

                                    <li
                                      id="mainorg"
                                      className={
                                        this.props.active === "/Organization"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/EditOrganization"
                                            ? "sideactive"
                                            : null ||
                                              this.props.active ===
                                              "/OrganizationDetail"
                                              ? "sideactive"
                                              : null
                                      }
                                    >
                                      <a href>
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;
                                        <span>
                                          <i
                                            className="icofont icofont-bank-alt"
                                            style={{ marginLeft: "4px" }}
                                          />
                                        </span>{" "}
                                        &nbsp;
                                        <span id="sidebarlist">
                                          {" "}
                                          <Link
                                            to
                                            onClick={() => this.AsAdminModel()}
                                          >
                                            {" "}
                                            Entity Profile{" "}
                                          </Link>
                                        </span>
                                        <span />
                                      </a>
                                    </li>
                                    <li
                                      className={
                                        this.props.active === "/AdminProfile"
                                          ? "sideactive"
                                          : null
                                      }
                                    >
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <i
                                        id="sidebarlist"
                                        className="ti-user"
                                      ></i>{" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <Link to style={{ marginLeft: "-7px" }}>
                                        <span id="sidebarlist">
                                          Personal Profile
                                        </span>
                                      </Link>{" "}
                                    </li>
                                    <li onClick={() => this.logoutpopfunctn()}>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <Link>

                                        <i className="icofont icofont-power" id="sidebarlist"></i>
                                        &nbsp;&nbsp;&nbsp;
                                        <span id="sidebarlist">Logout</span>
                                      </Link>
                                    </li>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div style={{
                                    lineHeight: "0",
                                    // height:'88vh',
                                    // background:'wheat'
                                    // transition: 'overflow-y 0.5s'  }}
                                  }}
                                    id="sidebarscroll">
                                    <li
                                      className={
                                        this.props.active === "/Dashboard"
                                          ? "sideactive"
                                          : null
                                      }
                                    >
                                      {/* &nbsp;&nbsp;&nbsp;&nbsp;
                                      <i className="ti-home"></i>{" "}
                                      &nbsp;&nbsp;&nbsp;{" "}
                                      <Link
                                        to="/Dashboard"
                                        style={{ marginLeft: "-7px" }}
                                      >
                                        HomeA
                                      </Link> */}
                                      <Link
                                        className="sideline"
                                        to="/Dashboard"
                                      >
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>
                                          <i className="ti-home" />
                                        </span>{" "}
                                        &nbsp;
                                        <span id="sidebarlist"> Home </span>
                                        <span />
                                      </Link>
                                    </li>

                                    <li
                                      id="mainorg"
                                      className={
                                        this.props.active === "/Organization"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/EditOrganization"
                                            ? "sideactive"
                                            : null ||
                                              this.props.active ===
                                              "/OrganizationDetail"
                                              ? "sideactive"
                                              : null
                                      }
                                    >
                                      <a href>
                                        <Link to="/Organization">
                                          {" "}
                                          &nbsp;&nbsp;&nbsp;
                                          <span>
                                            <i
                                              className="icofont icofont-bank-alt"
                                              style={{ marginLeft: "4px" }}
                                            />
                                          </span>{" "}
                                          &nbsp;&nbsp;
                                          <span id="sidebarlist">

                                            Entity Profile{" "}
                                          </span>
                                          <span />
                                        </Link>
                                      </a>
                                    </li>
                                    <li
                                      className={
                                        this.props.active === "/AdminProfile"
                                          ? "sideactive"
                                          : this.props.active === "/AdminProfileGrid"
                                          ? "sideactive"
                                          :null
                                      }
                                    >
                                      <Link
                                        to
                                        onClick={() => this.ProfileModel()}
                                      // style={{ marginLeft: "-7px" }}
                                      >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <i
                                          id="sidebarlist"
                                          className="ti-user"
                                          style={{ fontSize: "12px" }}
                                        ></i>
                                        &nbsp; &nbsp;{" "}
                                        <span id="sidebarlist">
                                          Personal Profile
                                        </span>
                                      </Link>{" "}
                                    </li>
                                    <li
                                      id="sidebarteam"
                                      className={
                                        this.props.active === "/Team"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active === "/AddTeam"
                                            ? "sideactive"
                                            : null ||
                                              this.props.active === "/EditTeam"
                                              ? "sideactive"
                                              : null ||
                                                this.props.active === "/TeamDash"
                                                ? "sideactive"
                                                : null ||
                                                  this.props.active === "/Teamdetail"
                                                  ? "sideactive"
                                                  : null||
                                                  this.props.active === "/Teamgriddetails"
                                                  ? "sideactive"
                                                  : null
                                      }
                                    >
                                      <a href className="sideline">
                                        {/* &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>
                                          <i
                                            className="icofont icofont-people"
                                            style={{ fontSize: "14px" }}
                                          ></i>
                                        </span>{" "}
                                        &nbsp;
                                        <span>
                                          {" "}
                                          <Link
                                            to="/TeamDash"
                                            style={{ marginLeft: "-3px" }}
                                          >
                                            Team
                                          </Link> */}
                                        <span>
                                          <Link
                                            className="sideline"
                                            to="/TeamDash"
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i className="icofont icofont-people" />
                                            </span>{" "}
                                            &nbsp;
                                            <span> Team</span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-team"
                                            id="sidelidropdownIcon"
                                          >
                                            <i className="ti-angle-down" />
                                          </label>
                                        </span>
                                        <input
                                          id="open-team"
                                          type="checkbox"
                                          className="open-team"
                                        />
                                        <ul className="team" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;{" "}
                                          <li>
                                            <Link to="/Team">Members</Link>
                                          </li>
                                        </ul>
                                      </a>
                                    </li>

                                    {/* <li
                                      id="sidebarskills"
                                      className={
                                        this.props.active === "/SkillMaster"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active === "/AddSkills"
                                            ? "sideactive"
                                            : null ||
                                              this.props.active === "/SkillGrid"
                                              ? "sideactive"
                                              : null ||
                                                this.props.active === "/SkillCard"
                                                ? "sideactive"
                                                : null ||
                                                  this.props.active === "/EditSkill"
                                                  ? "sideactive"
                                                  : null ||
                                                    this.props.active ===
                                                    "/MasterSkills"
                                                    ? "sideactive"
                                                    : null ||
                                                      this.props.active ===
                                                      "/CategoryMaster"
                                                      ? "sideactive"
                                                      : null
                                      }
                                    >
                                      <a href className="sideline">
                                        <span>
                                          <Link
                                            className="sideline"
                                            to="/SkillCard"
                                          >

                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i
                                                className="ti-pencil-alt"
                                                style={{ fontSize: "12px" }}
                                              />
                                            </span>
                                            &nbsp;&nbsp;&nbsp;
                                            <span> Skills</span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-skill"
                                            id="sidelidropdownIcon"
                                          >
                                            <i
                                              id="sidedrop"
                                              className="ti-angle-down"
                                            />
                                          </label>{" "}
                                        </span>
                                        <input
                                          id="open-skill"
                                          type="checkbox"
                                          className="open-skill"
                                        />
                                        <ul className="skilldown" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;
                                          <li>
                                            <Link to="/MasterSkills">
                                              Skill Master{" "}
                                            </Link>
                                          </li>
                                          &nbsp;&nbsp;&nbsp;
                                          <li className="skill-li">
                                            <Link to="/CategoryMaster">
                                              Category Master{" "}
                                            </Link>
                                          </li>{" "}
                                        </ul>
                                      </a>
                                    </li> */}

                                    <li
                                      id="sidebarEducation"
                                      className={
                                        this.props.active === "/Education"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active === "/EduDash"
                                            ? "sideactive"
                                            : null ||
                                              this.props.active ===
                                              "/AddEducation"
                                              ? "sideactive"
                                              : null ||
                                                this.props.active ===
                                                "/EducationGrid"
                                                ? "sideactive"
                                                : null ||
                                                  this.props.active ===
                                                  "/EditUserEducation"
                                                  ? "sideactive"
                                                  : null ||
                                                    this.props.active === "/CourseName"
                                                    ? "sideactive"
                                                    : null ||
                                                      this.props.active ===
                                                      "/Specialization"
                                                      ? "sideactive"
                                                      : null ||
                                                        this.props.active ===
                                                        "/MasterDegree"
                                                        ? "sideactive"
                                                        : null ||
                                                          this.props.active ===
                                                          "/MasterDegreeLevel"
                                                          ? "sideactive"
                                                          : null
                                      }
                                    >
                                      <a href className="sideline">
                                        <span>
                                          <Link
                                            className="sideline"
                                            to="/EduDash"
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i className="icofont icofont-education"></i>
                                            </span>{" "}
                                            &nbsp;&nbsp;
                                            <span>Education</span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-education"
                                            id="sidelidropdownIcon"
                                          >
                                            <i
                                              id="sidedrop"
                                              className="ti-angle-down"
                                            />
                                          </label>
                                        </span>
                                        <input
                                          id="open-education"
                                          type="checkbox"
                                          className="open-education"
                                        />
                                        <ul className="educationdown" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;{" "}
                                          <li>
                                            <Link to="/CourseName">
                                              Course Name Master
                                            </Link>
                                          </li>
                                          &nbsp;&nbsp;&nbsp;{" "}
                                          <li>
                                            <Link to="/Specialization">
                                              Specialization Master
                                            </Link>
                                          </li>
                                          &nbsp;&nbsp;&nbsp;{" "}
                                          <li>
                                            <Link to="/MasterDegree">
                                              Degree Master
                                            </Link>
                                          </li>
                                          &nbsp;&nbsp;&nbsp;{" "}
                                          <li>
                                            <Link to="/MasterDegreeLevel">
                                              Degree Level Master
                                            </Link>
                                          </li>
                                        </ul>
                                      </a>
                                    </li>

                                    <li
                                      id="sidebarDepartment"
                                      className={
                                        this.props.active === "/Department"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/DepartmentDash"
                                            ? "sideactive"
                                            : null
                                      }
                                    >
                                      <a href className="sideline">
                                        <span>
                                          <Link
                                            className="sideline"
                                            to="/DepartmentDash"
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i className="icofont icofont-building"></i>
                                            </span>{" "}
                                            &nbsp;&nbsp;
                                            <span>Departments</span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-department"
                                            id="sidelidropdownIcon"
                                          >
                                            {" "}
                                            <i
                                              id="sidedrop"
                                              className="ti-angle-down"
                                            />
                                          </label>
                                        </span>
                                        <input
                                          id="open-department"
                                          type="checkbox"
                                          className="open-department"
                                        />
                                        <ul className="departmentdown" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;
                                          <li>
                                            {" "}
                                            <Link
                                              to="/Department"
                                              style={{
                                                cursor: "pointer",
                                                color: "white",
                                              }}
                                            >
                                              Master{" "}
                                            </Link>
                                          </li>
                                        </ul>
                                      </a>
                                    </li>

                                    <li
                                      id="sidebarDesignation"
                                      className={
                                        this.props.active ===
                                          "/AdminDesignation"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/DesignationDash"
                                            ? "sideactive"
                                            : null
                                      }
                                    >
                                      <a href className="sideline">
                                        <span>
                                          <Link
                                            className="sideline"
                                            to="/DesignationDash"
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i className="icofont icofont-worker"></i>
                                            </span>{" "}
                                            &nbsp;&nbsp;
                                            <span>Designations</span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-designation"
                                            id="sidelidropdownIcon"
                                          >
                                            {" "}
                                            <i
                                              id="sidedrop"
                                              className="ti-angle-down"
                                            />
                                          </label>
                                        </span>
                                        <input
                                          id="open-designation"
                                          type="checkbox"
                                          className="open-designation"
                                        />
                                        <div className="designationdown" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;{" "}
                                          <li>
                                            {" "}
                                            <Link
                                              to="/AdminDesignation"
                                              style={{
                                                cursor: "pointer",
                                                color: "white",
                                              }}
                                            >
                                              Master{" "}
                                            </Link>
                                          </li>
                                        </div>
                                      </a>
                                    </li>
                                    <li
                                      id="sidebarChangePassword"
                                      className={
                                        this.props.active === "/Chatbox" || this.props.active === "/Settings"
                                          ? "sideactive"
                                          : null
                                      }
                                    >
                                      <Link className="sideline" to="/Chatbox">
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>
                                          <i
                                            className="far fa-comment"
                                            aria-hidden="true"
                                          ></i>
                                        </span>{" "}
                                        &nbsp;&nbsp;
                                        <span id="sidebarlist">

                                        Town Hall {" "}
                                        </span>
                                        {this.state.Noticount === 1 ||
                                          notificationCount === 0 ? null : (
                                          <span>({notificationCount})</span>
                                        )}
                                        &nbsp;&nbsp;&nbsp;
                                      </Link>
                                    </li>

                                    <li
                                      id="sidebarLocation"
                                      className={
                                        this.props.active === "/AdminLocation"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/OfficeLocationDash"
                                            ? "sideactive"
                                            : null
                                      }
                                    >
                                      <a href className="sideline">
                                        <span>
                                          <Link
                                            className="sideline"
                                            to="/OfficeLocationDash"
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i className="icofont icofont-location-pin"></i>
                                            </span>{" "}
                                            &nbsp;&nbsp;
                                            <span>Office Location</span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-Location"
                                            id="sidelidropdownIcon"
                                          >
                                            {" "}
                                            <i
                                              id="sidedrop"
                                              className="ti-angle-down"
                                            />
                                          </label>
                                        </span>
                                        <input
                                          id="open-Location"
                                          type="checkbox"
                                          className="open-Location"
                                        />
                                        <ul className="Locationdown" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;{" "}
                                          <li>
                                            {" "}
                                            <Link
                                              to="/AdminLocation"
                                              style={{
                                                cursor: "pointer",
                                                color: "white",
                                              }}
                                            >
                                              Master{" "}
                                            </Link>
                                          </li>
                                        </ul>
                                      </a>
                                    </li>

                                    <li
                                      id="sidebarManager"
                                      className={
                                        this.props.active === "/Managers"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/ManagerDash"
                                            ? "sideactive"
                                            : null
                                      }
                                    >
                                      <a href className="sideline">
                                        <span>
                                          <Link
                                            className="sideline"
                                            to="/ManagerDash"
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i className="icofont icofont-business-man"></i>
                                            </span>{" "}
                                            &nbsp;&nbsp;
                                            <span>Managers</span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-Manager"
                                            id="sidelidropdownIcon"
                                          >
                                            <i
                                              id="sidedrop"
                                              className="ti-angle-down"
                                            />
                                          </label>
                                        </span>
                                        <input
                                          id="open-Manager"
                                          type="checkbox"
                                          className="open-Manager"
                                        />
                                        <ul className="Managerdown" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;{" "}
                                          <li>
                                            {" "}
                                            <Link
                                              to="/Managers"
                                              style={{
                                                cursor: "pointer",
                                                color: "white",
                                              }}
                                            >
                                              Master{" "}
                                            </Link>
                                          </li>
                                        </ul>
                                      </a>
                                    </li>

                                    {/* <li
                                      id="sidebartraining"
                                      className={
                                        this.props.active === "/TrainingCard"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/LearningSchedule"
                                            ? "sideactive"
                                            : null ||
                                              this.props.active ===
                                              "/TrainerSchedule"
                                              ? "sideactive"
                                              : null ||
                                                this.props.active ===
                                                "/TrainingsLandingPage"
                                                ? "sideactive"
                                                : null ||
                                                  this.props.active ===
                                                  "/TrainingSchedule"
                                                  ? "sideactive"
                                                  : null
                                      }
                                    >
                                      <a href className="sideline">
                                        <span>
                                          <Link
                                            className="sideline"
                                            to="/TrainingsLandingPage"
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i className="icofont icofont-company"></i>
                                            </span>{" "}
                                            &nbsp;&nbsp;
                                            <span>Training</span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-Training"
                                            id="sidelidropdownIcon"
                                          >
                                            <i
                                              id="sidedrop"
                                              className="ti-angle-down"
                                            />
                                          </label>
                                        </span>
                                        <input
                                          id="open-Training"
                                          type="checkbox"
                                          className="open-Training"
                                        />
                                        <ul className="Trainingdown" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;
                                          <li>
                                            <Link to="/TrainingSchedule">
                                              Training Topics
                                            </Link>
                                          </li>
                                          &nbsp;&nbsp;&nbsp;
                                          <li>
                                            <Link to="/LearningSchedule">
                                              Training Class
                                            </Link>
                                          </li>
                                          &nbsp;&nbsp;&nbsp;
                                          <li>
                                            <Link to="/TrainerSchedule">
                                              Trainers
                                            </Link>
                                          </li>
                                        </ul>
                                      </a>
                                    </li> */}

                                    <li
                                      id="sidebarHolidays"
                                      className={
                                        this.props.active ===
                                          "/OfficialHolidays"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/OfficialHolidayDash"
                                            ? "sideactive"
                                            : null
                                      }
                                    >
                                      <a href className="sideline">
                                        <span>
                                          <Link
                                            className="sideline"
                                            to="/OfficialHolidayDash"
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i className="icofont icofont-beach"></i>
                                            </span>{" "}
                                            &nbsp;
                                            <span id="sidebarlist">
                                              {" "}
                                              Official Holidays
                                            </span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-Holidays"
                                            id="sidelidropdownIcon"
                                          >
                                            <i
                                              id="sidedrop"
                                              className="ti-angle-down"
                                            />
                                          </label>
                                        </span>
                                        <input
                                          id="open-Holidays"
                                          type="checkbox"
                                          className="open-Holidays"
                                        />
                                        <ul className="Holidaysdown" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;
                                          <li>
                                            {" "}
                                            <Link
                                              to="/OfficialHolidays"
                                              style={{
                                                cursor: "pointer",
                                                color: "white",
                                              }}
                                            >
                                              Master{" "}
                                            </Link>
                                          </li>
                                        </ul>
                                      </a>
                                    </li>

                                    <li
                                      id="sidebarDocuments"
                                      className={
                                        this.props.active ===
                                          "/AdminOfficeDocument"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/CompanyDocumentDash"
                                            ? "sideactive"
                                            : null
                                      }
                                    >
                                      <a href className="sideline">
                                        <span>
                                          <Link
                                            className="sideline"
                                            to="/CompanyDocumentDash"
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i className="icofont icofont-file-document"></i>
                                           
                                            </span>{" "}
                                            &nbsp;&nbsp;
                                            <span>Office Documents</span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-Documents"
                                            id="sidelidropdownIcon"
                                          >
                                            <i
                                              id="sidedrop"
                                              className="ti-angle-down"
                                            />
                                          </label>
                                        </span>
                                        <input
                                          id="open-Documents"
                                          type="checkbox"
                                          className="open-Documents"
                                        />
                                        <ul className="Documentsdown" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;
                                          <li>
                                            {" "}
                                            <Link
                                              to="/AdminOfficeDocument"
                                              style={{
                                                cursor: "pointer",
                                                color: "white",
                                              }}
                                            >
                                              Master{" "}
                                            </Link>
                                          </li>
                                        </ul>
                                      </a>
                                    </li>

                                    <li
                                      id="sidebarBusiness"
                                      className={
                                        this.props.active ===
                                          "/AdminBusiness" ||
                                          this.props.active === "/BusiUnitDash"
                                          ? "sideactive"
                                          : null
                                      }
                                    >
                                      <a href className="sideline">
                                        <span id="busi">
                                          <Link
                                            className="sideline"
                                            to="/BusiUnitDash"
                                          >
                                            {" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <i className="icofont icofont-unity-hand"></i>
                                            </span>{" "}
                                            &nbsp;
                                            <span> Business Unit</span>
                                            <span />
                                          </Link>
                                          &nbsp;&nbsp;
                                          <label
                                            className="label-text"
                                            htmlFor="open-Business"
                                            id="sidelidropdownIcon"
                                          >
                                            <i
                                              id="sidedrop"
                                              className="ti-angle-down"
                                            />
                                          </label>
                                        </span>
                                        <input
                                          id="open-Business"
                                          type="checkbox"
                                          className="open-Business"
                                        />
                                        <ul className="Businessdown" id="listbottom">
                                          &nbsp;&nbsp;&nbsp;{" "}
                                          <li>
                                            {" "}
                                            <Link
                                              to="/AdminBusiness"
                                              style={{
                                                cursor: "pointer",
                                                color: "white",
                                              }}
                                            >
                                              Master{" "}
                                            </Link>
                                          </li>
                                        </ul>
                                      </a>
                                    </li>

                                    <li
                                      id="sidebarAnnouncements"
                                      className={
                                        this.props.active ===
                                          "/AnnouncementPreview"
                                          ? "sideactive"
                                          : null ||
                                            this.props.active ===
                                            "/AdminAnnouncement"
                                            ? "sideactive"
                                            : null ||
                                              this.props.active ===
                                              "/AllAnnouncement"
                                              ? "sideactive"
                                              : null
                                      }
                                    >
                                      <Link
                                        className="sideline"
                                        to="/AnnouncementPreview"
                                      >
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>
                                          <i className="icofont icofont-mega-phone" />
                                        </span>{" "}
                                        &nbsp;&nbsp;&nbsp;
                                        <span
                                          style={{ marginLeft: "-5px" }}
                                          id="sidebarlist"
                                        >
                                          {this.state.Anncount === 0 ? (
                                            "Announcements"
                                          ) : (
                                            <>
                                             
                                              Announcements ({this.state.Anncount})
                                            </>
                                          )}

                                        </span>
                                        <span />
                                      </Link>
                                    </li>

                                    <li
                                      id="sidebarAnnouncements"
                                      className={
                                        this.props.active === "/AdminNotification"
                                          ? "sideactive"
                                          : null
                                      }
                                    >
                                      <Link
                                        className="sideline"
                                        to="/AdminNotification"
                                      >
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>
                                          <i className="ti-bell" />
                                        </span>{" "}
                                        &nbsp;&nbsp;&nbsp;
                                        <span
                                          style={{ marginLeft: "-5px" }}
                                          id="sidebarlist"
                                        >
                                      
                                          {this.state.Noticount === 0 ? (
                                            "Notifications"
                                          ) : (
                                            <>
                                             
                                              Notifications ({this.state.Noticount})
                                            </>
                                          )}{" "}

                                        </span>
                                        <span />
                                      </Link>
                                    </li>

                                    <li
                                      id="sidebarContact"
                                      className={
                                        this.props.active === "/Contact"
                                          ? "sideactive"
                                          : null ||
                                          this.props.active === "/ContactMessages"
                                          ? "sideactive"
                                          : null
                                      }
                                    >
                                      <Link className="sideline" to="ContactMessages">
                                        {" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className="pcoded-micon">
                                          <i className="icofont icofont-phone"></i>
                                        </span>
                                        &nbsp;&nbsp;&nbsp;
                                        <span
                                          className="pcoded-mtext"
                                          data-i18n="nav.dash.main"
                                          style={{ marginLeft: "-3px" }}
                                          id="sidebarlist"
                                        >
                                          Contact Admin
                                        </span>
                                        <span className="pcoded-mcaret" />
                                      </Link>
                                    </li>
                                   
                                    <li onClick={() => this.logoutpopfunctn()}>
                                      &nbsp;&nbsp;&nbsp;
                                      <Link>
                                        {" "}
                                        <i className="icofont icofont-power" id="sidebarlist"></i>
                                        &nbsp;&nbsp;&nbsp;
                                        <span id="sidebarlist">Logout</span>
                                      </Link>
                                    </li>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : null
                    )}
                  {/* <li onClick={() => this.logoutpopfunctn()}>
                    &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <Link>
                      {" "}
                      <i className="icofont icofont-power" id="sidebarlist"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span id="sidebarlist">Logout</span>
                    </Link>
                  </li> */}
                </ul>
              </>

              {/* </div>   */}
              <div></div>
            </nav>
          </div>
        </div>
        {this.state.ProfileModel ? (
          <PopMsg id="Success" close={() => this.ProfileModel()} />
        ) : null}

        {this.state.AsAdminModel ? (
          <AsAdminModel id="Success" close={() => this.AsAdminModel()} />
        ) : null}

        {/* footer */}
        <div className="footernew">
          <p style={{ marginTop: "10px" }}>
            Contact Us: contact@oneawesometeam.com{" "}
          </p>
        </div>
      </>
    );
  }
}
