import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import LoadingPage from "../../Common/LoadingPage";
import LogoCompo from "../../Common/LogoCompo";
import "../../Css/SigninStyle.css";
import { BASE_URLs } from "../../Config/Base";
export default class Signin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      PasswordL: "",
      formErrors: {},
      Eye: false,
      loading: false,
      passingemail: ``,
    };
  }
  componentDidMount = () => {
    const emailSuffix = window.location.href.split("Login/")[1];
    if (!emailSuffix.endsWith(".in") && !emailSuffix.endsWith(".org")) {
      this.setState({
        passingemail: emailSuffix + ".com",
      });
    } 
    else {
      this.setState({
        passingemail: emailSuffix,
      });
    }
  };

  handleChange = (e) => {
    var email = document.getElementById("Email").value;
    var PasswordL = document.getElementById("Password").value;

    // const {email,PasswordL}= this.state;
    let formErrors = {};
    let formIsValid = true;
    const { name, value } = e.target;
    this.setState({ [name]: value });
    //name name
    /*   if (!name) {    
        formIsValid = false;    
        formErrors["nameErr"] = "Name is required" ;    
    }     */
    //User name

    //Email
    if (!email || email.trim() == "") {
      formIsValid = false;
      formErrors["emailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      formIsValid = false;
      formErrors["emailErr"] = "Invalid Email Address";
    } else if (!PasswordL || PasswordL.trim() == "") {
      formIsValid = false;
      formErrors["passwordErr"] = "Password is required";
    }
    // else if(!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(PasswordL))){
    // formIsValid = false;
    // formErrors["passwordErr"] = "Invalid Password";

    // }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  handleFormValidation() {
    var email = document.getElementById("Email").value;
    var PasswordL = document.getElementById("Password").value;

    // const {email,PasswordL}= this.state;
    let formErrors = {};
    let formIsValid = true;

    //User name

    //Email
    if (!email || email.trim() == "") {
      formIsValid = false;
      formErrors["emailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      formIsValid = false;
      formErrors["emailErr"] = "Invalid Email";
    }
    if (!PasswordL || PasswordL.trim() == "") {
      formIsValid = false;
      formErrors["passwordErr"] = "Password is required";
    }
    // else if(!(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(PasswordL))){
    // formIsValid = false;
    // formErrors["passwordErr"] = "Invalid Password";
    // }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  togglePassword = () => {
    this.setState({ Eye: !this.state.Eye });
  };

  UserLogin = async (event) => {
    event.preventDefault();

    if (this.handleFormValidation()) {
      this.setState({ loading: true });
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Email: this.state.passingemail,
          Password: this.state.PasswordL,
        }),
      };
      const url = `${BASE_URLs}/Authenticate/UserLogin`;
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success("Logged In Successfully", {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        localStorage.setItem("user", JSON.stringify(result.token));
        localStorage.setItem("Role", JSON.stringify(result.role));
        localStorage.setItem("uname", JSON.stringify(result.uname));
        localStorage.setItem("org", JSON.stringify(result.org));
        localStorage.setItem("username", JSON.stringify(result.username));
        var role = JSON.parse(localStorage.Role);
        role = role.toString();
        return role === "User" && this.props.history.push("/UserReset");
      }
      if (result.title === "Unauthorized") {
        alert("Invalid Username or Password ");
        // document.getElementById("myForm").reset();
      }
      if (result.status === "Error") {
        alert(result.message);
      }
    }

    this.setState({ loading: false });
  };

  render() {
    var w = window.innerWidth;

    const { emailErr, passwordErr,} = this.state.formErrors;
    return (
      <div>
        <div>
          <div className="bgImg" />
          <div className="content">
            <br />
            <LogoCompo />
            <ToastContainer
              position="top-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div
              className={
                w < 992
                  ? "  h-100 align-items-center justify-content-center"
                  : " d-flex h-100 align-items-center justify-content-center"
              }
            >
              <div className="container">
                <div className="row">
                  <div
                    className={
                      w < 992
                        ? " col-sm-12 ml-auto mr-auto"
                        : "col-md-4 col-sm-6 ml-auto mr-auto"
                    }
                  >
                    <div className="card-copy">
                      <div className="card-body">
                        <form onSubmit={this.UserLogin}>
                          <br />
                          <br />
                          <br />{" "}
                          <div>
                            <h4 className="title1">
                              <span className="shadows">User Login</span>
                            </h4>
                          </div>
                          <div className="signUp">
                            <span>
                              <input
                                type="text"
                                className="form"
                                id="Email"
                                maxLength="30"
                                onChange={this.handleChange}
                                defaultValue={this.state.passingemail}
                                name="email"
                                placeholder="Email "
                                autoComplete="off"
                                style={{ cursor: "auto" }}
                                disabled
                              />
                              {emailErr && (
                                <span
                                  style={{ color: "red", paddingBottom: 10 }}
                                >
                                  {emailErr}
                                </span>
                              )}{" "}
                              <span className="underline" />
                            </span>

                            <>
                              <div style={{ display: "flex" }}>
                                <input
                                  type={!this.state.Eye ? "password" : "text"}
                                  className="form"
                                  id="Password"
                                  onChange={this.handleChange}
                                  name="PasswordL"
                                  maxLength="20"
                                  placeholder="Password"
                                  autoComplete="off"
                                />
                                <span
                                  className={
                                    !this.state.Eye
                                      ? "fa fa-eye eyelogoA"
                                      : "fa fa-eye-slash eyelogoA"
                                  }
                                  id="eye"
                                  onClick={this.togglePassword}
                                ></span>
                                {/* eye-blocked */}
                              </div>
                              <span className="underline" />
                              {passwordErr && (
                                <div>
                                  <span
                                    style={{ color: "red", paddingBottom: 10 }}
                                  >
                                    {passwordErr}
                                  </span>
                                  <br />
                                </div>
                              )}
                            </>
                          </div>
                          <div
                            className="text-center"
                            style={{ marginTop: "10px" }}
                          >
                            <button
                              type="submit" //onClick={() => {this.UserLogin()}}
                              className="but"
                              style={{ width: "83.36px" }}
                            >
                              Login
                            </button>
                          </div>
                        </form>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading === true ? <LoadingPage /> : null}
      </div>
    );
  }
}
