import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPopup1 from "../../Common/SuccessPopup1";
import UserChatPopUp from "../../Common/UserChatPopUp";
import UsertoUserPopUp from "../../Common/UsertoUserPopup";
import { Button } from "@material-ui/core";
import { BASE_URLs } from "../../Config/Base";
export default class EntityListUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      UserList: [],
      AdminList: [],
      AllUserList: [],
      AllAdminList: [],
      Team: [],
      Teams: [],
      showing: true,
      profile: [],
      EditSkillModel: false,
      EditSkillModelA: false,
      EditSkillValues: [],
      Id: "",
      SkillName: "",
      User: [],
      Alluser: [],
      AddSkillModel: false,
    };
    this.initialState = this.state; // this.handleContentClick = this.handleContentClick.bind(this);
  }

  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/status1`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ User: data, Alluser: data });
        // console.log("user data", data);
      });
  }
  AddSkillModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }

    this.setState((prevState) => ({
      AddSkillModel: !prevState.AddSkillModel,
    }));
  };
  EditSkillModel = (e, fn, em) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditSkillValues: {
        id: e,
        firstName: fn,

        email: em,
      },
    });
    this.setState((prevState) => ({
      EditSkillModel: !prevState.EditSkillModel,
    }));
    //   this.GetAddSkill();
  };

  EditSkillModelA = (e, fn, em) => {
    this.setState({
      EditSkillValues: {
        id: e,
        firstName: fn,

        email: em,
      },
    });
    this.setState((prevState) => ({
      EditSkillModelA: !prevState.EditSkillModelA,
    }));
    //   this.GetAddSkill();
  };

  GetUserList() {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(`${BASE_URLs}/UserBank/GetAdminUser`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          UserList: data,
          AllUserList: data,
        });

        // console.log("New", data);

        //
      });
  }

  GetAdminList() {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(`${BASE_URLs}/UserBank/GetAdminOnly`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          AdminList: data,
          AllAdminList: data,
        });

        // console.log("Admin", data);

        //
      });
  }
  LastChatModel = () => {
    this.setState((prevState) => ({
      LastChatModel: !prevState.LastChatModel,
    }));
  };
  myFunction = (e) => {
    const UserList = this.state.AllUserList.filter(
      (item) =>
        item.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ UserList });

    const AdminList = this.state.AllAdminList.filter(
      (item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ AdminList });
  };

  OATSearch = (e) => {
    const User = this.state.Alluser.filter((item) =>
      item.personname.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ User });
  };
  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  TeamUser() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Team: data,
          Teams: data,
        });
        // console.log("hess", data);
      });
  }
  componentDidMount() {
    this.GetUserList();
    this.GetAdminList();
    this.UserOrg();
    this.TeamUser();
  }
  handleButtonClick1 = () => {
    this.setState((prevState) => ({
      isButton2Visible: !prevState.isButton2Visible,
      isButton1Visible: true,
    }));
    document.getElementById("EntityListUser").style.display = "none";
    if (this.state.LastChatModel) {
      // alert("hai")
      document.getElementById("Chat-MainA").style.marginTop = "6vh";
    }
  };
  render() {
    var w = window.innerWidth;
    const { UserList, User, Team, AdminList } = this.state;

    return (
      <>
        <div>
          {this.state.EditSkillModel ? (
            <div className="row">
              <div className="col-lg-6">
                <UsertoUserPopUp
                  values={this.state.EditSkillValues}
                  close={() => this.EditSkillModel()}
                />
              </div>
            </div>
          ) : null}
          {this.state.EditSkillModelA ? (
            <UserChatPopUp
              id=""
              values={this.state.EditSkillValues}
              close={() => this.EditSkillModelA()}
            />
          ) : null}

          <div className="pcoded-content">
            <div className="pcoded-inner-content" id="EntityListUser">
              <div className="main-body chat-bg">
                <div className="">
                  <div id="" className="">
                    <div className="page-body">
                      <div className="row">
                        <div className="chat-box">
                          <div id="sidebar" className="users p-chat-user">
                            <div className="had-container">
                              <div
                                className="card card_main p-fixed users-main "
                                style={{ overflow: "scroll" }}
                              >
                                <div className="user-box">
                                  <div id="tsum-tabs">
                                    <main id="maintab">
                                      <input
                                        id="tab1"
                                        type="radio"
                                        name="tabs"
                                        defaultChecked
                                      />
                                      <label
                                        id="supertab"
                                        className="j-tabs-label"
                                        htmlFor="tab1"
                                        title="In Entity"
                                        color="white"
                                      >
                                        In Entity
                                      </label>

                                      <input
                                        id="tab2"
                                        type="radio"
                                        name="tabs"
                                      />
                                      <label
                                        id="activetab"
                                        className="j-tabs-label"
                                        htmlFor="tab2"
                                        title="In OAT"
                                        color="white"
                                      >
                                        In OAT
                                      </label>

                                      <div
                                        id="tabs-section-1"
                                        className="j-tabs-section"
                                      >
                                        <div className="right-icon-control">
                                          <input
                                            type="text"
                                            className="form-control  search-text"
                                            placeholder="Search Friend"
                                            onChange={this.myFunction}
                                          />
                                          <div className="form-icon">
                                            <i className="icofont icofont-search"></i>
                                          </div>
                                        </div>
                                        <br />
                                        <>
                                          {UserList.map((UserList, e) => (
                                            <>
                                              <div
                                                className="media userlist-box"
                                                data-id="1"
                                                data-status="online"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                key={
                                                  (UserList.id,
                                                  UserList.usermsgId)
                                                }
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.EditSkillModel(
                                                    UserList.id,
                                                    UserList.firstName,

                                                    UserList.email
                                                  );
                                                  this.handleButtonClick1();
                                                  this.LastChatModel();
                                                }}
                                              >
                                                <a className="media-left">
                                                  <img
                                                    className="media-object img-circle"
                                                    src="assets/images/avatar-1.png"
                                                    alt="Generic placeholder image"
                                                  />
                                                </a>
                                                <div
                                                  className="media-body"
                                                  style={{
                                                    textAlign: "initial",
                                                  }}
                                                >
                                                  <div className="f-13 chat-header">
                                                    {" "}
                                                    {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                                    <span
                                                      style={{
                                                        color: "black",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {UserList.firstName}
                                                    </span>
                                                    {/* <br />
                                                        <span
                                                          style={{
                                                            color: "Gray",
                                                            whiteSpace:
                                                              "nowrap",
                                                          }}
                                                        >
                                                          
                                                        </span> */}
                                                    <br />
                                                    <span
                                                      style={{
                                                        color: "Gray",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {UserList.email}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                          {AdminList.map((AdminList, e) => (
                                            <>
                                              <div
                                                className="media userlist-box"
                                                data-id="1"
                                                data-status="online"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                key={AdminList.id}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.EditSkillModelA(
                                                    AdminList.id,
                                                    AdminList.name,
                                                    AdminList.email
                                                  );
                                                  this.handleButtonClick1();
                                                  this.LastChatModel();
                                                }}
                                              >
                                                <a className="media-left">
                                                  <img
                                                    className="media-object img-circle"
                                                    src="assets/images/avatar-1.png"
                                                    alt="Generic placeholder image"
                                                  />
                                                </a>
                                                <div
                                                  className="media-body"
                                                  style={{
                                                    textAlign: "initial",
                                                  }}
                                                >
                                                  <div className="f-13 chat-header">
                                                    {" "}
                                                    {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                                    <span
                                                      style={{
                                                        color: "black",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {AdminList.name}
                                                    </span>
                                                    {/* <br />
                                                        <span
                                                          style={{
                                                            color: "Gray",
                                                            whiteSpace:
                                                              "nowrap",
                                                          }}
                                                        >
                                                          
                                                        </span> */}
                                                    <br />
                                                    <span
                                                      style={{
                                                        color: "Gray",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {AdminList.email}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ))}{" "}
                                          <br />
                                        </>
                                      </div>

                                      <div
                                        id="tabs-section-2"
                                        className="j-tabs-section"
                                      >
                                        <div className="right-icon-control">
                                          <input
                                            type="text"
                                            className="form-control  search-text"
                                            placeholder="Search Friend"
                                            onChange={this.OATSearch}
                                          />
                                          <div className="form-icon">
                                            <i className="icofont icofont-search"></i>
                                          </div>
                                        </div>
                                        <br />
                                        {User.map((user) => (
                                          <div
                                            className="media userlist-box"
                                            data-id="2"
                                            data-status="online"
                                            data-username="Josephin Doe"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title="Josephin Doe"
                                            // onClick={(e) => {
                                            //   e.preventDefault();
                                            //   this.EditSkillModel(
                                            //     UserList.id,
                                            //     UserList.firstName,
                                            //     UserList.allmessage,
                                            //     UserList.usermsgId,
                                            //     UserList.receivemsg,
                                            //     UserList.email,
                                            //     UserList.displayName
                                            //   );
                                            //   this.handleButtonClick1();
                                            //   this.LastChatModel();
                                            // }}
                                            onClick={() => {
                                              this.AddSkillModel();
                                              this.handleButtonClick1();
                                              this.LastChatModel();
                                            }}
                                          >
                                            <a className="media-left">
                                              <img
                                                className="media-object img-circle"
                                                src="assets/images/avatar-1.png"
                                                alt="Generic placeholder image"
                                              />
                                            </a>
                                            <div
                                              className="media-body"
                                              style={{ textAlign: "initial" }}
                                            >
                                              <div
                                                className="f-13 chat-header"
                                                style={{ color: "black" }}
                                              >
                                                {user.personname}&nbsp;&nbsp;
                                                {user.company_Name}
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </main>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="styleSelector"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
