import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "../../../src/Components/Css/MasterSkills.css";
import { BASE_URLs } from "../Config/Base";
export default class AssignedTraineePop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: "",
      isOpen: true,
      isOpenA: false,
    };
  }

  handleSelectChange = (event) => {
    // console.log("Value", event.target.value);
    this.setState({ selectedOption: event.target.value });
    var Others = document.getElementById("Not-Interest").value;

    if (Others == "Others") {
      this.setState({ isOpen: false });
      this.setState({ isOpenA: true });
    }

    const value = event.target.value;
    this.setState({
      //UserId: value,
      TrainerId: value,
      NotInterested: value,
      Reason: value,

      // Update the discussion name value
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      // UserId: " ",

      TrainerId: " ",
      NotInterested: " ",
      Reason: " ",
      // Update the discussion name value
    });
    //  const data ={

    //   userId:"1234",
    //   trainerId:"1234",
    //   notInterested: document.getElementById("Not-Interest").value

    //  }
    const data = new FormData(event.target);
    // console.log(data, "Not Interest Details");
    // const url = `${BASE_URLs}/Trainer/AssignTrainerDropdown"
    const url = `${BASE_URLs}/Training/Add_NotInterested`;
    //  fetch('${BASE_URLs}/Trainer/AssignTrainerDropdown', {
    //   method: 'POST',
    //   headers:{
    //     Accept:"application/json"
    //   },
    //   body: data,
    // }).then(res => res.json()).then((resp) => {

    //  alert("fhdy")
    //  })

    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    const response = await fetch(url, options);
    const result = await response.json();

    if (result.status === "Success") {
      toast.success(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(function () {
        document.getElementById("close").click();
      }, 4000);
    }
  };

  render() {
    var w = window.innerWidth;
    const { selectedOption } = this.state;

    let user = JSON.parse(localStorage.getItem("uname"));
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div>
          {/* <div className="JoinLink">
    <div className="JoinLinkinnernormalpop" > 
  

   <div className="row">
<div className="col-lg-12">
{this.state.isOpen && (<select name="Not-Interest" id="Not-Interest" style={{height:"36px"}} value={this.state.selectedOption} onChange={this.handleSelectChange}  >
  <option value="Not Interested on this current training">Not Interested on this current training</option>
  <option value="I don't want to be a Trainer">I don't want to be a Trainer</option>
  <option value="None of the Above">None of the Above</option>
  <option value="Others">Other</option>
</select>
        )}
{this.state.isOpenA && (<input placeholder='Reason...' style={{height:"36px"}}/>

        )}
 </div>


 </div> 
<br/>
 <div className="row">

 <div className="col-lg-12" style={{textAlign:"center"}}>   
 
 
           <button type="submit"  className="but" style={{height: '39.5px',width: '100px',marginTop:"-0.3%"}}>OK</button>                 
   </div>
 </div> 
 <br/> 
  
      

 </div>
</div>   */}

          <div className="pop">
            <div
              className={
                w > 768
                  ? "popinnernormal oneFieldPop"
                  : "popinnernormal oneFieldPopMobile"
              }
              style={{ padding: "1%" }}
            >
              <form
                autoComplete="off"
                encType="multipart/form-data"
                onSubmit={this.handleSubmit}
              >
                <button
                  id="close"
                  style={{
                    float: "right",
                    backgroundColor: "#a0a3a305",
                    color: "#797070",
                    border: "1px solid #a3a5a505",
                  }}
                  onClick={this.props.close}
                >
                  <CloseIcon />
                </button>

                <div className="row" style={{ padding: "8px" }}>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-8">
                    {this.state.isOpen && (
                      <>
                        <select
                          id="Not-Interest"
                          name="Description"
                          style={{ height: "35px", width: "270px" }}
                          value={this.state.selectedOption}
                          onChange={this.handleSelectChange}
                        >
                          <option value="Not Interested on this current training">
                            Not Interested on this current training
                          </option>
                          <option value="I don't want to be a Trainer">
                            I don't want to be a Trainer
                          </option>
                          <option value="None of the Above">
                            None of the Above
                          </option>
                          <option value="Others">Other</option>
                        </select>

                        <input
                          style={{ display: "none" }}
                          name="Reason"
                          defaultValue="null"
                          onChange={this.handleSelectChange}
                        />
                      </>
                    )}
                    {/* <input style={{display:"none"}} name="UserId" defaultValue={user} onChange={this.handleSelectChange}/> */}
                    <input
                      style={{ display: "none" }}
                      name="TrainerId"
                      defaultValue="1234"
                      onChange={this.handleSelectChange}
                    />

                    {this.state.isOpenA && (
                      <>
                        <input
                          placeholder="Reason..."
                          id="DropdownOther"
                          name="Reason"
                          onChange={this.handleSelectChange}
                        />
                        <input
                          style={{ display: "none" }}
                          id="Not-Interest"
                          name="Description"
                          defaultValue="Others"
                          onChange={this.handleSelectChange}
                        />
                      </>
                    )}

                    <span className="underline" />
                  </div>
                  <div className="col-sm-2"></div>
                </div>

                <div className="row">
                  <div className="col-sm-12" style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      className="but"
                      style={{
                        height: "39.5px",
                        width: "86.36px",
                        marginTop: "-0.3%",
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
                <br />
              </form>
            </div>
          </div>
        </div>{" "}
      </>
    );
  }
}
