import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { BASE_URLs } from "../Config/Base";
export default class EditDesignationModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Department: "",
      ButtonDisabled: false,
    };
    // this.handleSubmit=this.handleSubmit.bind(this);
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };

  handleSubmit = async (e) => {
    var CourseName = document.getElementById("CourseName").value;
    var Id = document.getElementById("Id").value;
    e.preventDefault();
    if (this.Validation()) {
      this.setState({
        ButtonDisabled: true,
      });
      const data = new FormData();
      data.append("CourseName", CourseName);
      data.append("Id", Id);
      var role = JSON.parse(localStorage.Role);
      const url = `${BASE_URLs}/Course/EditCourse`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        document.getElementById("Editpop").style.display = "none";
        setTimeout(function () {
          if (document.getElementById("close")) {
            document.getElementById("close").click();
          }
        }, 4000);
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      this.GetDepartment();
    }
  };
  Validation() {
    var CourseName = document.getElementById("CourseName").value;
    let formErrors = {};
    let formIsValid = true;

    if (!CourseName) {
      formIsValid = false;
      formErrors["CourseNameErr"] = "Course Name  is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }
  GetDepartment() {
    let url = `${BASE_URLs}/Department/GetMasterDepartment`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Department: data,
        });
      });
  }
  componentDidMount() {
    // this.RelationshipDropList();
    // this.GetDepartment();
  }
  render() {
    var w = window.innerWidth;

    const { Department } = this.state;
    const { CourseNameErr } = this.state.formErrors;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />{" "}
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="Editpop"
          >
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <button
                id="close"
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={this.props.close}
              >
                <CloseIcon />
              </button>

              <div className="row">
                <div className="col-sm-3"></div>

                <div className="col-sm-8">
                  <input
                    type="hidden"
                    id="Id"
                    name="Id"
                    defaultValue={this.props.values.id}
                    onKeyDown={this._handleKeyDown}
                  />
                  <input
                    className="form"
                    onKeyDown={this._handleKeyDown}
                    maxLength="50"
                    defaultValue={this.props.values.CourseName}
                    onChange={(e) => {
                      this.AutoCaps(e);
                    }}
                    id="CourseName"
                    name="CourseName"
                    type="text"
                  />
                  {/* {!CourseName? <span style={{color:'#a29d9d',float:"left"}}>Course Name<span style={{color:"red",float:"left"}}></span></span> : <span style={{ color: "red",float:"left" }} className={CourseName ? ' showError' : ''}>{CourseName}</span> }   */}
                  {!CourseNameErr ? (
                    <div style={{ color: "#a29d9d", textAlign: "left" }}>
                      Course Name
                    </div>
                  ) : (
                    <div style={{ color: "red", textAlign: "left" }}>
                      {CourseNameErr}
                    </div>
                  )}
                </div>
                <span className="underline" />
              </div>
              <br />

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
