import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { data } from "jquery";
import { BASE_URLs } from "../../Config/Base";
import Doc from "../../Assets/images/document.png";
export default class Teamdetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      FirstName: "",
      LastName: "",
      Email: "",
      Mobile: "",
      Department: "",
      Designation: "",
      RoleName: "",
      formErrors: {},
      Notification: [],
      Departmentdrop: [],
      Designationdrop: [],
      LocationDrop: [],
      ManagerDrop: [],
      comlogo: [],
      value: this.props.location.state,
      Details: [],
      TeamSts: "",
      BoxSts: "",
      EmployeeInfoDetails: true,
      emaildetailsforInfo: [],
      personalInfoDetails: [],
      FamilyDetails: [],
      EducationDetail: [],
      SkillDetails: [],
      Switchtabs: "Info",
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  EmployeeFunctionlity() {
    this.setState({
      EmployeeInfoDetails: true,
    });
  }

  PersonalFunctionlity() {
    this.setState({
      EmployeeInfoDetails: false,
    });
  }

  tabsfunctionlity(e) {
    this.setState({
      Switchtabs: e,
    });
  }

  Edit1(Id) {
    //localStorage.setItem("status","Pending")
    var page = {
      pathname: "/EditTeam",
      state: {
        Id: Id,
      },
    };
    localStorage.setItem("TeamId", Id);
    this.props.history.push(page);
  }

  checkAndNavigate = () => {
    this.props.history.push("/Team");
  };

  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }
  componentDidMount() {
    if (this.props.location.state && this.props.location.state.Id) {
      this.personalprofilelist();
      this.personalfamilylist();
      this.personalEducationlist();
      this.personalskilllist();
      this.GetLocation();
      this.GetOrgLogo();
      this.GetDepartment();
      this.GetDesignation();
      this.GetManager();
      this.Getdetail();
      // if (this.props.location.state === undefined) {
      //   this.props.history.push("./Team");
      // } else {
      //   this.Getdetail();
      // }
    } else {
      this.checkAndNavigate();
    }
  }

  GetDesignation() {
    let url = `${BASE_URLs}/Designation/GetMasterDesignation`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Designationdrop: data,
        });
      });
  }

  Getdetail() {
    var Id = this.props.location.state.Id;
    // var Id =  localStorage.getItem("foruserDetailId",Id)

    let url = `${BASE_URLs}/Team/GetTeamEdit?Id=` + Id;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Details: data,
          TeamSts: data[0].status,
          emaildetailsforInfo: data[0].email,
        });
        // console.log("check", data);
      });
  }
  GetDepartment() {
    let url = `${BASE_URLs}/Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }
  GetManager() {
    let url = `${BASE_URLs}/Manager/ManagerGet`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          ManagerDrop: data,
        });
      });
  }

  GetLocation() {
    let url = `${BASE_URLs}/AdminLocation/GetLocation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          LocationDrop: data,
        });
      });
  }

  Handledepartment() {
    var department = document.getElementById("department").value;

    if (department === "others") {
      document.getElementById("departmenttext").value = "";
      document.getElementById("departmenttext").type = "text";
    } else {
      document.getElementById("departmenttext").value = "None";
      document.getElementById("departmenttext").type = "hidden";
    }
  }
  Handledesignation() {
    var department = document.getElementById("Designation").value;

    if (department === "others") {
      document.getElementById("designationtext").value = "";
      document.getElementById("designationtext").type = "text";
    } else {
      document.getElementById("designationtext").value = "None";
      document.getElementById("designationtext").type = "hidden";
    }
  }
  Handleroles() {
    var department = document.getElementById("roles").value;

    if (department === "others") {
      document.getElementById("roletext").value = "";
      document.getElementById("roletext").type = "text";
    } else {
      document.getElementById("roletext").value = "None";
      document.getElementById("roletext").type = "hidden";
    }
  }

  handleTerminate = () => {
    if (document.getElementById("Deactive")) {
      document.getElementById("Deactive").checked = false;
    }
    if (document.getElementById("Active")) {
      document.getElementById("Active").checked = false;
    }
    if (document.getElementById("Terminate").checked) {
      this.setState({
        BoxSts: 3,
      });
    } else {
      this.setState({
        BoxSts: this.state.TeamSts,
      });
      if (this.state.TeamSts === 0) {
        document.getElementById("Deactive").checked = true;
      } else if (this.state.TeamSts === 1) {
        document.getElementById("Active").checked = true;
      } else if (this.state.TeamSts === 3) {
        document.getElementById("Terminate").checked = true;
      }
    }
  };
  handleDeactive = () => {
    if (document.getElementById("Deactive").checked) {
      this.setState({
        BoxSts: 0,
      });
    } else {
      if (this.state.TeamSts === 0) {
        document.getElementById("Deactive").checked = true;
      } else if (this.state.TeamSts === 1) {
        document.getElementById("Active").checked = true;
      } else if (this.state.TeamSts === 3) {
        document.getElementById("Terminate").checked = true;
      }

      this.setState({
        BoxSts: this.state.TeamSts,
      });
    }
    document.getElementById("Terminate").checked = false;
    document.getElementById("Active").checked = false;
  };
  handleActive = () => {
    document.getElementById("Deactive").checked = false;
    document.getElementById("Terminate").checked = false;

    if (document.getElementById("Active").checked) {
      this.setState({
        BoxSts: "",
      });
    } else {
      this.setState({
        BoxSts: this.state.TeamSts,
      });
      if (this.state.TeamSts === 0) {
        document.getElementById("Deactive").checked = true;
      } else if (this.state.TeamSts === 1) {
        document.getElementById("Active").checked = true;
      } else if (this.state.TeamSts === 3) {
        document.getElementById("Terminate").checked = true;
      }
    }
  };

  personalprofilelist() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    var Id = this.props.location.state.Id;
    // var Id =  localStorage.getItem("foruserDetailId",Id)
    fetch(`${BASE_URLs}/Team/GetTeamPersonal?Id=${Id}`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ personalInfoDetails: data });
      });
  }

  personalEducationlist() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    var Id = this.props.location.state.Id;
    // var Id =  localStorage.getItem("foruserDetailId",Id)
    fetch(
      `${BASE_URLs}/UserEducationDetails/GetAdminEducationInfo?Id=${Id}`,
      options
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ EducationDetail: data });

        // console.log("EducationDetailssss", data);
      });
  }

  personalfamilylist() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    var Id = this.props.location.state.Id;
    // var Id =  localStorage.getItem("foruserDetailId",Id)
    fetch(`${BASE_URLs}/Family/GetAdminFamilyInfo?Id=${Id}`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ FamilyDetails: data });
      });
  }

  personalskilllist() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    var Id = this.props.location.state.Id;
    // var Id =  localStorage.getItem("foruserDetailId",Id)
    fetch(
      `${BASE_URLs}/UserSkillDetails/GetAdminUserSkillsInfo?Id=${Id}`,
      options
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SkillDetails: data });
      });
  }

  render() {
    const { Details } = this.state;
    const { personalInfoDetails } = this.state;
    const { FamilyDetails } = this.state;
    const { EducationDetail } = this.state;
    const { SkillDetails } = this.state;

    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header"></div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>

                          {Details.map((d) => {
                            return (
                              <h4
                                style={{ marginRight: "3%" }}
                                className="title"
                              >
                                {d.displayName} Details
                              </h4>
                            );
                          })}
                        </div>
                        <div className="card-header-right">
                          <ToastContainer
                            id="toast"
                            position="top-center"
                            autoClose={1500}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                        </div>
                        {/* card block starting */}
                        <div className="card-block">
                          <div className="row">
                            <div className="col-lg-12">
                              <br />

                              <div
                                // style={{
                                //   display: "flex",
                                //   flexDirection: "row",
                                //   justifyContent: "flex-start",
                                // }}
                                style={{ marginLeft: "1.5%" }}
                                id="ButtonDiv"
                              >
                                <div
                                  className="btnalign"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.EmployeeFunctionlity();
                                  }}
                                >
                                  <label
                                    className="btn"
                                    style={{ cursor: "pointer" }}
                                    id="addBtn"
                                  >
                                    Employee Info
                                  </label>
                                </div>

                                <div
                                  className="btnalign"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.PersonalFunctionlity();
                                  }}
                                >
                                  <label
                                    className="btn"
                                    style={{ cursor: "pointer" }}
                                    id="addBtn"
                                  >
                                    Personal Info
                                  </label>
                                </div>
                              </div>

                              {/* <button >Employee Info</button>
                                                            <button >Personal Info</button> */}
                              <br />
                              <br />
                              <br />

                              {this.state.EmployeeInfoDetails === true ? (
                                <>
                                  {/* Employee design starting */}
                                  <h5 style={{ marginLeft: "2%" }}>
                                    EMPLOYEE INFO{" "}
                                  </h5>
                                  <div className="card">
                                    <div className="col-lg-12">
                                      <div className="row" style={{marginTop:"10px"}}>
                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Employee Id</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.teamId}
                                            </label>
                                          ))}
                                        </div>

                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Display Name</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.displayName}
                                            </label>
                                          ))}
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>First Name</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.firstName}
                                            </label>
                                          ))}
                                        </div>

                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Last Name</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.lastName}
                                            </label>
                                          ))}
                                        </div>
                                      </div>

                                      <div className="row" style={{marginBottom:"10px"}}>
                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Email</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                                wordBreak:"break-all"
                                              }}
                                            >
                                              {Details.email}
                                            </label>
                                          ))}
                                        </div>

                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Mobile</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.mobile}{" "}
                                            </label>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Employee design ending */}
                                  <br />
                                  {/* professional design starting */}
                                  <h5 style={{ marginLeft: "2%" }}>
                                    PROFESSIONAL INFO{" "}
                                  </h5>
                                  <div className="card">
                                    <div className="col-lg-12">
                                      <div className="row" style={{marginTop:"10px"}}>
                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Department</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.department}
                                            </label>
                                          ))}
                                        </div>

                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Designation</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.designation}
                                            </label>
                                          ))}
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Role</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.role}
                                            </label>
                                          ))}
                                        </div>

                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Reporting Manager</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.report_Manager}
                                            </label>
                                          ))}
                                        </div>
                                      </div>

                                      <div className="row" style={{marginBottom:"10px"}}>
                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Locations</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {Details.primary_Ofc}
                                            </label>
                                          ))}
                                        </div>

                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Join Date</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          {Details.map((Details) => (
                                            <label
                                              style={{
                                                color: "rgb(128 125 125)",
                                              }}
                                            >
                                              {new Date(
                                                Details.joining_Date
                                              ).toLocaleDateString()}{" "}
                                            </label>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* professional design ending */}
                                  <br />
                                  {/* status design */}
                                  <h5 style={{ marginLeft: "2%" }}>STATUS</h5>
                                  <div className="card">
                                    <div className="col-lg-12">
                                      <div className="row" style={{marginTop:"10px"}}>
                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Status</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          <label
                                            style={{
                                              color: "rgb(128 125 125)",
                                            }}
                                          >
                                            {this.state.TeamSts === 2 ? (
                                              "Pending"
                                            ) : (
                                              <>
                                                {this.state.TeamSts === 1 ? (
                                                  "Active"
                                                ) : (
                                                  <>
                                                    {this.state.TeamSts ===
                                                    0 ? (
                                                      "Deactive"
                                                    ) : (
                                                      <>
                                                        {this.state.TeamSts ===
                                                        3
                                                          ? "Terminate"
                                                          : ""}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <h5 style={{ marginLeft: "2%" }}>
                                    TRAINING EXPERIENCE
                                  </h5>
                                  <div className="card">
                                    <div className="col-lg-12">
                                      <div className="row" style={{marginTop:"10px"}}>
                                        <div className="col-lg-3 ">
                                          <b>
                                            {" "}
                                            <span>Trainer Experience</span>
                                          </b>
                                        </div>
                                        <div className="col-lg-3 ">
                                          {Details.map((Details) =>
                                             Details.yes === "1" ? (
                                              <label
                                                style={{
                                                  color: "rgb(128 125 125)",
                                                }}
                                              >
                                                {Details.experience}
                                              </label>
                                            ) : (
                                              <label
                                                style={{
                                                  color: "rgb(128 125 125)",
                                                }}
                                              >
                                                No Trainer
                                              </label>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {Details.map((d) => {
                                    return (
                                      <center>
                                        <div style={{ width: "10%" }}>
                                          <button
                                            className="button-30 sk-edit"
                                            type="button"
                                            style={{ color: "" }}
                                            onClick={() => this.Edit1(d.id)}
                                          >
                                            Edit&nbsp;
                                            <i className="icofont icofont-edit"></i>
                                          </button>
                                        </div>
                                      </center>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <div
                                    id="TeamDetails"
                                  >
                                    <div
                                      className="btnalign"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.tabsfunctionlity("Info")
                                      }
                                    >
                                      <label
                                        className={
                                          this.state.Switchtabs === "Info"
                                            ? "Activebtn"
                                            : "Newbtn"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        Info
                                      </label>
                                    </div>

                                    <div
                                      className="btnalign"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.tabsfunctionlity("Banking")
                                      }
                                    >
                                      <label
                                        className={
                                          this.state.Switchtabs === "Banking"
                                            ? "Activebtn"
                                            : "Newbtn"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        Banking
                                      </label>
                                    </div>
                                    <div
                                      className="btnalign"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.tabsfunctionlity("Family")
                                      }
                                    >
                                      <label
                                        className={
                                          this.state.Switchtabs === "Family"
                                            ? "Activebtn"
                                            : "Newbtn"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        Family
                                      </label>
                                    </div>
                                    <div
                                      className="btnalign"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.tabsfunctionlity("Education")
                                      }
                                    >
                                      <label
                                        className={
                                          this.state.Switchtabs === "Education"
                                            ? "Activebtn"
                                            : "Newbtn"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        Education
                                      </label>
                                    </div>
                                    <div
                                      className="btnalign"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.tabsfunctionlity("Skills")
                                      }
                                    >
                                      <label
                                        className={
                                          this.state.Switchtabs === "Skills"
                                            ? "Activebtn"
                                            : "Newbtn"
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        Skills
                                      </label>
                                    </div>
                                  </div>

                                  {this.state.Switchtabs === "Info" ? (
                                    // INFO DETAILS

                                    <>
                                      {/* Employee design starting */}
                                      <h5 style={{ marginLeft: "2%" }}>
                                        PERSONAL INFO{" "}
                                      </h5>
                                      <div className="card">
                                        <div className="col-lg-12">
                                          <div className="row" style={{marginTop:"10px"}}>
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Name</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.firstName}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Birth Date</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {this.formatDate(
                                                      Details.dob
                                                    )}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Gender</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.gender}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Marital Status</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.maritalStatus}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row" style={{marginBottom:"10px"}}>
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Blood Group</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.bloodGroup}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* Employee design ending */}
                                      <br />
                                      {/* Contact design starting */}
                                      <h5 style={{ marginLeft: "2%" }}>
                                        CONTACT INFO{" "}
                                      </h5>
                                      <div className="card">
                                        <div className="col-lg-12">
                                          <div className="row" style={{marginTop:"10px"}}>
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Official Email</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.email}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Personal Email</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.officialEmail}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Home Number</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.homeNumber}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Mobile Number</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.phoneNumber}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row" style={{marginBottom:"10px"}}>
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>
                                                  Alternate Phone Number
                                                </span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.alternatePhoneNo}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* Contact design ending */}
                                      <br />
                                      {/* Address design starting */}
                                      <h5 style={{ marginLeft: "2%" }}>
                                        ADDRESS INFO{" "}
                                      </h5>
                                      <div className="card">
                                        <div className="col-lg-12">
                                          <div className="row" style={{marginTop:"10px"}}>
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Current Address</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                      width: "80%",
                                                      wordWrap: "break-word", // Use camelCase for CSS properties in inline styles
                                                      whiteSpace: "pre-wrap", // To also handle newlines correctly
                                                    }}
                                                  >
                                                    {Details.currentAddress}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Permanant Address</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                      width: "80%",
                                                      wordWrap: "break-word", // Use camelCase for CSS properties in inline styles
                                                      whiteSpace: "pre-wrap", // To also handle newlines correctly
                                                    }}
                                                  >
                                                    {Details.permanentAddress}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>City</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {
                                                      Details.currentAddress_City
                                                    }
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>City</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {
                                                      Details.permanentAddress_City
                                                    }
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>State</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {
                                                      Details.currentAddress_State
                                                    }
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>State</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {
                                                      Details.permanentAddress_State
                                                    }
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Country</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {
                                                      Details.currentAddress_Country
                                                    }
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Country</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {
                                                      Details.permanentAddress_Country
                                                    }
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Pincode</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {
                                                      Details.currentAddress_Pincode
                                                    }
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Pincode</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {
                                                      Details.permanentAddress_Pincode
                                                    }
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row" style={{marginBottom:"10px"}}>
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>House Type</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.houseType}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Staying Since</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {this.formatDate(
                                                      Details.stayingSince
                                                    )}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* Address design Ending*/}

                                      {/* Social Media design starting */}
                                      <h5 style={{ marginLeft: "2%" }}>
                                        SOCIAL MEDIA INFO{" "}
                                      </h5>
                                      <div className="card">
                                        <div className="col-lg-12">
                                          <div className="row" style={{marginTop:"10px"}}>
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>LinkedIn</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.linkedIn}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Skype</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.skype}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Whatsapp</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.whatsappNumber}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Facebook</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.faceBook}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Twitter</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.twitter}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : this.state.Switchtabs === "Banking" ? (
                                    // BANKING DETAILS
                                    <>
                                      <h5 style={{ marginLeft: "2%" }}>
                                        BANKING INFO{" "}
                                      </h5>
                                      <div className="card">
                                        <div className="col-lg-12">
                                          <div className="row" style={{marginTop:"10px"}}>
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Account Number</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.acNo}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>IFSC Code</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.ifsc}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Account Type</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.acType}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Branch Name</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.branchname}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>

                                          <div className="row" style={{marginBottom:"10px"}}>
                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Phone Number</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.phoneNo}
                                                  </label>
                                                )
                                              )}
                                            </div>

                                            <div className="col-lg-3 ">
                                              <b>
                                                {" "}
                                                <span>Address</span>
                                              </b>
                                            </div>
                                            <div className="col-lg-3 ">
                                              {personalInfoDetails.map(
                                                (Details) => (
                                                  <label
                                                    style={{
                                                      color: "rgb(128 125 125)",
                                                    }}
                                                  >
                                                    {Details.branchaddress}
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : this.state.Switchtabs === "Family" ? (
                                    // FAMILY INFO

                                    <>
                                      <h5 style={{ marginLeft: "2%" }}>
                                        FAMILY INFO{" "}
                                      </h5>
                                      <div className="">
                                        <div className="dt-responsive table-responsive">
                                          {FamilyDetails.length !== 0 ? (
                                            <table
                                              id="add-row-table"
                                              className="table  nowrap"
                                              style={{
                                                width:'98%',marginLeft:'10px'
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th>Name</th>
                                                  <th>Contact Number </th>
                                                  <th>Email</th>
                                                  <th>Relationship</th>
                                                  <th>Emergency Contact</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {FamilyDetails.map(
                                                  (FamilyDetails) => (
                                                    <tr
                                                      id={
                                                        FamilyDetails.id +
                                                        FamilyDetails.firstName
                                                      }
                                                    >
                                                      <td>
                                                        {
                                                          FamilyDetails.firstName
                                                        }{" "}
                                                        {
                                                          FamilyDetails.middleName
                                                        }{" "}
                                                        {FamilyDetails.lastName}
                                                      </td>
                                                      <td>
                                                        {FamilyDetails.contact}
                                                      </td>
                                                      <td>
                                                        {FamilyDetails.email}
                                                      </td>
                                                      <td>
                                                        {
                                                          FamilyDetails.relationship
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          FamilyDetails.emergencyContact
                                                        }
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          ) : null}{" "}
                                        </div>
                                      </div>
                                    </>
                                  ) : this.state.Switchtabs === "Education" ? (
                                    // EDUCATION INFO

                                    <>
                                      <h5 style={{ marginLeft: "2%" }}>
                                        EDUCATION INFO{" "}
                                      </h5>
                                      <div className="">
                                        <div className="dt-responsive table-responsive">
                                          {EducationDetail.length !== 0 ? (
                                            <table
                                              id="add-row-table"
                                              className="table nowrap "
                                              style={{
                                                width:'98%',marginLeft:'10px'
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th>Qualification Type</th>
                                                  <th>Name</th>
                                                  <th>Type</th>
                                                  <th>Stream</th>
                                                  <th>Start Date</th>
                                                  <th>End Date</th>
                                                  <th>College Name</th>
                                                  <th>University Name</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {EducationDetail.map(
                                                  (EducationDetail) => (
                                                    <tr>
                                                      <td>
                                                        {
                                                          EducationDetail.qualificationType
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          EducationDetail.courseName
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          EducationDetail.courseType
                                                        }{" "}
                                                      </td>
                                                      <td>
                                                        {EducationDetail.stream}{" "}
                                                      </td>
                                                      <td>
                                                        {
                                                          EducationDetail.courseStartDate
                                                        }{" "}
                                                      </td>
                                                      <td>
                                                        {
                                                          EducationDetail.courseEndDate
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          EducationDetail.collegeName
                                                        }
                                                      </td>
                                                      <td>
                                                        {
                                                          EducationDetail.universityName
                                                        }
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          ) : null}{" "}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    // SKILLS Info

                                    <>
                                      <h5 style={{ marginLeft: "2%" }}>
                                        SKILLS INFO{" "}
                                      </h5>
                                      <div className="">
                                        <div className="dt-responsive table-responsive">
                                          {SkillDetails.length !== 0 ? (
                                            <table
                                              id="add-row-table"
                                              className="table nowrap "
                                              style={{
                                                width:'98%',marginLeft:'10px'
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  {/* <th>Category</th> */}
                                                  <th>Skill Name</th>
                                                  {/* <th>Description</th> */}
                                                  <th>Acquired Year</th>
                                                  <th>Trainer</th>
                                                  <th>Skill CourseName</th>
                                                  <th>Skill Start Date</th>
                                                  <th>Skill End Date</th>
                                                  <th>Skill Certificate</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {SkillDetails.map(
                                                  (SkillDetails) => (
                                                    <tr>
                                                      {/* <td>
                                                        {SkillDetails.category}
                                                      </td> */}
                                                      <td>
                                                        {SkillDetails.skillName}
                                                      </td>
                                                      {/* <td>
                                                        {
                                                          SkillDetails.skillDescription
                                                        }
                                                      </td> */}
                                                      <td>
                                                        {this.formatDate(
                                                          SkillDetails.experience
                                                        )}
                                                      </td>
                                                      <td>
                                                        {SkillDetails.trainer}
                                                      </td>
                                                      <td>
                                                        {SkillDetails.skillCoursename}
                                                      </td>
                                                      <td>
                                                        {SkillDetails.skillStartdate}
                                                      </td>
                                                      <td>
                                                        {SkillDetails.skillEnddate}
                                                      </td>
                                                      <td>
                                                        {SkillDetails.skillCertificate != "-" ? 
                                                       <a href={SkillDetails.skillCertificate} target="_blank"> <img src={Doc} alt="Excel document" style={{ width: '30px', height: '30px' }} />
                                                       </a>
                                                        : "-"}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          ) : null}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* card block starting */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
