import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import AddBusinessPop from "./Components/Common/AddBusinessPop";
import AddDepartmentpop from "./Components/Common/AddDepartmentpop";
import AddDesignationModel from "./Components/Common/AddDesignationModel";
import AddHolidaysPop from "./Components/Common/AddHolidaysPop";
import AddLocationModel from "./Components/Common/AddLocationModel";
import AddManagerPop from "./Components/Common/AddManagerPop";
import AddOfficeDocumentModel from "./Components/Common/AddOfficeDocumentModel";
import AnnouncementPopup from "./Components/Common/AnnouncementPopup";
import Chat from "./Components/Common/Chat";
import CommonSuccess from "./Components/Common/CommonSuccess";
import DashPie from "./Components/Common/DashPie";
import EditAdminEduModel from "./Components/Common/EditAdminEduModel";
import EditAdminFamilyModel from "./Components/Common/EditAdminFamilyModel";
import EditAnnouncementpop from "./Components/Common/EditAnnouncementpop";
import EditBusinessPop from "./Components/Common/EditBusinessPop";
import { default as EditDepartPop, default as EditHolidayPop } from "./Components/Common/EditDepartPop";
import EditEmergencyModel from "./Components/Common/EditEmergencyModel";
import EditFamilyModel from "./Components/Common/EditFamilyModel";
import EditSkillModel from "./Components/Common/EditSkillModel";
import Export from "./Components/Common/Export";
import GroupPop from "./Components/Common/GroupPop";
import LastChatpop from "./Components/Common/LastChatpop";
import LastGroup from "./Components/Common/LastGroup";
import LoadingPage from "./Components/Common/LoadingPage";
import LogoCompo from "./Components/Common/LogoCompo";
import LogoutConfirmPop from "./Components/Common/LogoutConfirmPop";
import OTPmsgpop from "./Components/Common/OTPmsgpop";
import Online from "./Components/Common/Online";
import Pie from "./Components/Common/Pie";
import RegMsgPop from "./Components/Common/RegMsgPop";
import Sidebar from "./Components/Common/Sidebar";
import SuccessPopup from "./Components/Common/SuccessPopup";
import Tempfile from "./Components/Common/Tempfile";
import AddOrganization from "./Components/Screen/AddOrganization";
import AddEducation from "./Components/Screen/Admin/AddEducation";
import AddSkills from "./Components/Screen/Admin/AddSkills";
import AdminAnnouncement from "./Components/Screen/Admin/AdminAnnouncement";
import AdminBusiness from "./Components/Screen/Admin/AdminBusiness";
import AdminDesignation from "./Components/Screen/Admin/AdminDesignation";
import AdminHome from "./Components/Screen/Admin/AdminHome";
import AdminLocation from "./Components/Screen/Admin/AdminLocation";
import AdminOfficeDocument from "./Components/Screen/Admin/AdminOfficeDocument";
import AdminOtp from "./Components/Screen/Admin/AdminOtp";
import AdminProfile from "./Components/Screen/Admin/AdminProfile";
import AllAnnouncement from "./Components/Screen/Admin/AllAnnouncement";
import BusiUnitDash from "./Components/Screen/Admin/BusiUnitDash";
import CompanyDocument from "./Components/Screen/Admin/CompanyDocument";
import CompanyDocumentDash from "./Components/Screen/Admin/CompanyDocumentDash";
import Department from "./Components/Screen/Admin/Department";
import DepartmentDash from "./Components/Screen/Admin/DepartmentDash";
import DesignationDash from "./Components/Screen/Admin/DesignationDash";
import EditAdSkil from "./Components/Screen/Admin/EditAdSkil";
import EditOrganization from "./Components/Screen/Admin/EditOrganization";
import EditPG from "./Components/Screen/Admin/EditPG";
import EditPHD from "./Components/Screen/Admin/EditPHD";
import EditSkill from "./Components/Screen/Admin/EditSkill";
import EditTeam from "./Components/Screen/Admin/EditTeam";
import EditUserEducation from "./Components/Screen/Admin/EditUserEducation";
import EduDash from "./Components/Screen/Admin/EduDash";
import Education from "./Components/Screen/Admin/Education";
import EduDropDown from "./Components/Screen/Admin/EducationGrid";
import ManagerDash from "./Components/Screen/Admin/ManagerDash";
import Managers from "./Components/Screen/Admin/Managers";
import MasterSkills from "./Components/Screen/Admin/MasterSkills";
import OfficeLocationDash from "./Components/Screen/Admin/OfficeLocationDash";
import OfficialHolidayDash from "./Components/Screen/Admin/OfficialHolidayDash";
import OfficialHolidays from "./Components/Screen/Admin/OfficialHolidays";
import SkillCard from "./Components/Screen/Admin/SkillCard";
import SkillGrid from "./Components/Screen/Admin/SkillGrid";
import SkillMaster from "./Components/Screen/Admin/SkillMaster";
import Team from "./Components/Screen/Admin/Team";
import TeamDash from "./Components/Screen/Admin/TeamDash";
import Teamadd from "./Components/Screen/Admin/Teamadd";
import ChangePwd from "./Components/Screen/ChangePwd";
import Contact from "./Components/Screen/Contact";
import DashBoard from "./Components/Screen/DashBoard";
import Dummyfntn from "./Components/Screen/Dummyfntn";
import Forgot from "./Components/Screen/Forgot";
import InviteDashboard from "./Components/Screen/InviteDashboard";
import LandingPage from "./Components/Screen/LandingPage";
import Login_form from "./Components/Screen/Login_form";
import NewPassword from "./Components/Screen/NewPassword";
import OTP from "./Components/Screen/OTP";
import Organization from "./Components/Screen/Organization";
import OrganizationDetail from "./Components/Screen/OrganizationDetail";
import Piechart from "./Components/Screen/Piechart";
import SignUp from "./Components/Screen/Sign-Up";
import ActiveReson from "./Components/Screen/SuperAdmin/ActiveReson";
import Announcement from "./Components/Screen/SuperAdmin/Announcement";
import EditMasterPG from "./Components/Screen/SuperAdmin/EditMasterPG";
import EditMasterPHD from "./Components/Screen/SuperAdmin/EditMasterPHD";
import EditMasterUG from "./Components/Screen/SuperAdmin/EditMasterUG";
import EditSuperEducation from "./Components/Screen/SuperAdmin/EditSuperEducation";
import EditSuperSkill from "./Components/Screen/SuperAdmin/EditSuperSkill";
import GridEducation from "./Components/Screen/SuperAdmin/GridEducation";
import Gridskil from "./Components/Screen/SuperAdmin/Gridskil";
import Masterpage from "./Components/Screen/SuperAdmin/Masterpage";
import Messages from "./Components/Screen/SuperAdmin/Messages";
import Notifications from "./Components/Screen/SuperAdmin/Notifications";
import RejectOrg from "./Components/Screen/SuperAdmin/RejectOrg";
import SuperEducation from "./Components/Screen/SuperAdmin/SuperEducation";
import LoginUser from "./Components/Screen/User/LoginUser";
import Profile from "./Components/Screen/User/Profile";
import TestDrop from "./Components/Screen/User/TestDrop";
import UserAnnouncement from "./Components/Screen/User/UserAnnouncement";
import UserCompanyDocument from "./Components/Screen/User/UserCompanyDocument";
import UserDashboard from "./Components/Screen/User/UserDashboard";
import UserHoliday from "./Components/Screen/User/UserHoliday";
import UserRequest from "./Components/Screen/User/UserRequest";
import UserReset from "./Components/Screen/User/UserReset";
import Userchating from "./Components/Screen/User/Userchating";
import dummy from "./Components/Screen/dummy";
import Lifestyle from "./Components/Screen/SuperAdmin/Lifestyle";
import Personality from "./Components/Screen/SuperAdmin/Personality";
import Intrest from "./Components/Screen/SuperAdmin/Intrest";
import Activities from "./Components/Screen/SuperAdmin/Activities";
// import FBTest from './Components/FBTest';
// import Fileupload from './Components/Screen/Admin/Fileupload'
import AdminSkillPop from "./Components/Common/AdminSkillPop";
import Aspuserlistchat from "./Components/Common/Aspuserlistchat";
import ChatGrupPop from "./Components/Common/ChatGrupPop";
import Chatbox from "./Components/Common/Chatbox";
import EntityList from "./Components/Common/EntityList";
import Planet from "./Components/Common/Planet";
import SPEditAnnouncement from "./Components/Common/SPEditAnnouncement";
import AdminAnnouncmentpop from "./Components/Screen/Admin/AdminAnnouncmentpop";
import AdminNotification from "./Components/Screen/Admin/AdminNotification";
import AnnouncementPreview from "./Components/Screen/Admin/AnnouncementPreview";
import CategoryMaster from "./Components/Screen/Admin/CategoryMaster";
import CourseName from "./Components/Screen/Admin/CourseName";
import MasterDegree from "./Components/Screen/Admin/MasterDegree";
import MasterDegreeLevel from "./Components/Screen/Admin/MasterDegreeLevel";
import Specialization from "./Components/Screen/Admin/Specialization";
import Teamdetail from "./Components/Screen/Admin/Teamdetail";
import SASpecialization from "./Components/Screen/SuperAdmin//SASpecialization";
import AnnouncementPreviewSP from "./Components/Screen/SuperAdmin/AnnouncementPreviewSP";
import Contactmsg from "./Components/Screen/SuperAdmin/Contactmsg";
import Gridskill from "./Components/Screen/SuperAdmin/Gridskil";
import SACategory from "./Components/Screen/SuperAdmin/SACategory";
import SACourse from "./Components/Screen/SuperAdmin/SACourse";
import SADegree from "./Components/Screen/SuperAdmin/SADegree";
import SADegreeLevel from "./Components/Screen/SuperAdmin/SADegreeLevel";
import SADepartment from "./Components/Screen/SuperAdmin/SADepartment";
import SADesignation from "./Components/Screen/SuperAdmin/SADesignation";
import UserAnnouncementpopup from "./Components/Screen/User/UserAnnouncementpopup";
import UserMessageNotification from "./Components/Screen/User/UserMessageNotification";
import UserNotifications from "./Components/Screen/User/UserNotifications";
// import Messagebox from './Components/Common/Messagebox';
// import ButtonandInput from './Components/Common/ButtonandInput';
// import ListView from './Components/Common/ListView';
// test
import AddLearning from "./Components/Common/AddLearning";
import AddTrainerpop from "./Components/Common/AddTrainerpop";
import AddTrainingpopDesign from "./Components/Common/AddTrainingPopDesign";
import AddTrainingpop from "./Components/Common/AddTrainingpop";
import Chatconfirm from "./Components/Common/Chatconfirm";
import EditSchedule from "./Components/Common/EditSchedule";
import EditTrainerpop from "./Components/Common/EditTrainerpop";
import EditTrainingpop from "./Components/Common/EditTrainingpop";
import SearchPopup from "./Components/Common/SearchPopup";
import TrainingEditTraineesPop from "./Components/Common/TrainingEditTraineesPop";
import UserChatPopUp from "./Components/Common/UserChatPopUp";
import AdminMessageNotification from "./Components/Screen/Admin/AdminMessageNotification";
import AssignTraining from "./Components/Screen/Admin/AssignTraining";
import LearningSchedule from "./Components/Screen/Admin/LearningSchedule";
import TrainerList from "./Components/Screen/Admin/TrainerList";
import TrainingCard from "./Components/Screen/Admin/TrainingCard";
import TrainingSchedule from "./Components/Screen/Admin/TrainingSchedule";
import Train from "./Components/Screen/SuperAdmin/Train";
import EntityListUser from "./Components/Screen/User/EntityListUser";

import TrainingEditTrainerPop from "./Components/Common/TrainingEditTrainerPop";
import AddTrainer from "./Components/Screen/Admin/AddTrainer";
import TrainerDetails from "./Components/Screen/Admin/TrainerDetails";
import TrainerSchedule from "./Components/Screen/Admin/TrainerSchedule";
import UserActiveTraining from "./Components/Screen/Admin/UserActiveTraining";
import UserAssignTraining from "./Components/Screen/Admin/UserAssignTraining";
import UserTrainingDocument from "./Components/Screen/Admin/UserTrainingDocument";

import AddTraineeExistingPop from "./Components/Common/AddTraineeExistingPop";
import AddTraineePop from "./Components/Common/AddTraineePop";
import Settings from "./Components/Common/Settings";
import TrainingClassPopup from "./Components/Common/TrainingClassPopup";
import TrainingsLandingPage from "./Components/Screen/Admin/TrainingsLandingPage";
import AllAnnouncementSA from "./Components/Screen/SuperAdmin/AllAnnouncementSA";
import Attendees from "./Components/Screen/User/Attendees";
import EditActiveTrainer from "./Components/Screen/User/EditActiveTrainer";
import MeetingLink from "./Components/Screen/User/MeetingLink";
import TrainerActiveTraining from "./Components/Screen/User/TrainerActiveTraining";
import TrainerAssign from "./Components/Screen/User/TrainerAssign";
import TrainerAssignTraining from "./Components/Screen/User/TrainerAssignTraining";
import TrainerTrainingDocuments from "./Components/Screen/User/TrainerTrainingDocuments";
import TrainingsDocuments from "./Components/Screen/User/TrainingsDocuments";
import MyComponent from "./Components/Screen/dummy";
import ContactMessages from "./Components/Screen/Admin/ContactMessages";
import MessagePrev from "./Components/Screen/Admin/MessagePrev";
import EditMessagepop from "./Components/Common/EditMessagepop";
import Teamgriddetails from "./Components/Common/Teamgriddetails";
import UserTeam from "./Components/Screen/User/UserTeam";
import ProfileGridDetails from "./Components/Common/ProfileGridDetails";
import AdminProfileGrid from "./Components/Screen/Admin/AdminProfileGrid";

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/Sidebar" component={Sidebar} />
          <Route exact path="/" component={LandingPage} />
          <Route path="/Sign-Up" component={SignUp} />
          <Route path="/Teamgriddetails" component={Teamgriddetails} />
          <Route path="/DashBoard" component={DashBoard} />
          <Route path="/GroupPop" component={GroupPop} />
          <Route path="/AddOrganization" component={AddOrganization} />
          <Route path="/Organization" component={Organization} />
          <Route path="/Userchating" component={Userchating} />
          <Route path="/Sign-up" component={SignUp} />
          <Route path="/LastChatpop" component={LastChatpop} />
          <Route path="/LastGroup" component={LastGroup} />
          <Route path="/Login_form" component={Login_form} />
          <Route path="/EducationGrid" component={EduDropDown} />
          <Route path="/Profile" component={Profile} />
          <Route path="/OrganizationDetail" component={OrganizationDetail} />
          <Route path="/EditOrganization" component={EditOrganization} />
          <Route path="/announcement" component={Announcement} />
          <Route path="/Notifications" component={Notifications} />
          <Route path="/Messages" component={Messages} />
          <Route path="/RejectOrg" component={RejectOrg} />
          <Route path="/Team" component={Team} />
          <Route path="/UserTeam" component={UserTeam} />
          <Route path="/ProfileGridDetails" component={ProfileGridDetails} />
          <Route path="/AdminProfileGrid" component={AdminProfileGrid} />
          <Route path="/AddTeam" component={Teamadd} />
          <Route path="/AdminAnnouncmentpop" component={AdminAnnouncmentpop} />
          <Route path="/SkillMaster" component={SkillMaster} />
          <Route path="/AddSkills" component={AddSkills} />
          <Route path="/Education" component={Education} />
          <Route path="/AddEducation" component={AddEducation} />
          <Route path="/Editskill" component={EditSkill} />
          <Route path="/Contact" component={Contact} />
          <Route path="/EduDash" component={EduDash} />
          <Route path="/Gridskil" component={Gridskil} />
          <Route path="/GridEducation" component={GridEducation} />
          <Route path="/SkillCard" component={SkillCard} />
          <Route path="/Login/:email" component={LoginUser} />
          <Route path="/Teamdash" component={TeamDash} />
          <Route path="/Piechart" component={Piechart} />
          <Route path="/Pie" component={Pie} />
          <Route path="/DashPie" component={DashPie} />
          <Route path="/Masterpage" component={Masterpage} />
          <Route path="/Settings" component={Settings}/>
          <Route path="/EditSuperSkill" component={EditSuperSkill} />
          <Route path="/EditSuperEducation" component={EditSuperEducation} />
          <Route path="/SuperEducation" component={SuperEducation} />
          <Route path="/Export" component={Export} />
          <Route path="/SkillGrid" component={SkillGrid} />
          <Route path="/EditUserEducation" component={EditUserEducation} />
          <Route path="/EditTeam" component={EditTeam} />
          <Route path="/ChangePwd" component={ChangePwd} />
          <Route path="/UserDashboard" component={UserDashboard} />
          <Route path="/Forgot" component={Forgot} />
          <Route path="/OTP" component={OTP} />
          <Route path="/NewPassword" component={NewPassword} />
          <Route path="/EditFamilyModel" component={EditFamilyModel} />
          <Route
            path="/EditAdminFamilyModel"
            component={EditAdminFamilyModel}
          />
          <Route path="/ContactMessages" component={ContactMessages} />
          <Route path="/MessagePrev" component={MessagePrev} />
          <Route path="/EditMessagepop" component={EditMessagepop} />
          <Route path="/EditEmergencyModel" component={EditEmergencyModel} />
          <Route path="/EditSkillModel" component={EditSkillModel} />
          <Route path="/EditAdminEduModel" component={EditAdminEduModel} />
          <Route path="/InviteDashboard" component={InviteDashboard} />
          <Route path="/UserReset" component={UserReset} />
          <Route path="/AdminOtp" component={AdminOtp} />
          <Route path="/LandingPage" component={LandingPage} />
          <Route path="/AdminHome" component={AdminHome} />
          <Route path="/Department" component={Department} />
          <Route path="/Managers" component={Managers} />
          <Route path="/EditAnnouncementpop" component={EditAnnouncementpop} />
          <Route path="/AddDepartmentpop" component={AddDepartmentpop} />
          <Route path="/AddManagerPop" component={AddManagerPop} />
          <Route path="/OfficialHolidays" component={OfficialHolidays} />
          <Route path="/AddHolidaysPop" component={AddHolidaysPop} />
          <Route path="/AdminDesignation" component={AdminDesignation} />
          <Route path="/AdminLocation" component={AdminLocation} />
          <Route path="/AdminOfficeDocument" component={AdminOfficeDocument} />
          <Route path="/CompanyDocumentDash" component={CompanyDocumentDash} />
          <Route path="/OfficialHolidayDash" component={OfficialHolidayDash} />
          <Route path="/ManagerDash" component={ManagerDash} />
          <Route path="/OfficeLocationDash" component={OfficeLocationDash} />
          <Route path="/DesignationDash" component={DesignationDash} />
          <Route path="/DepartmentDash" component={DepartmentDash} />
          <Route
            path="/AddOfficeDocumentModel"
            component={AddOfficeDocumentModel}
          />
          <Route path="/AddDesignationModel" component={AddDesignationModel} />
          <Route path="/AddLocationModel" component={AddLocationModel} />
          <Route path="/EditDepartPop" component={EditDepartPop} />
          <Route path="/EditHolidayPop" component={EditHolidayPop} />
          <Route path="/AdminBusiness" component={AdminBusiness} />
          <Route path="/AddBusinessPop" component={AddBusinessPop} />
          <Route path="/EditBusinessPop" component={EditBusinessPop} />
          <Route path="/AdminProfile" component={AdminProfile} />
          <Route path="/CompanyDocument" component={CompanyDocument} />
          <Route path="/UserRequest" component={UserRequest} />
          <Route path="/UserHoliday" component={UserHoliday} />
          <Route path="/UserCompanyDocument" component={UserCompanyDocument} />
          <Route path="/AnnouncementPopup" component={AnnouncementPopup} />
          <Route path="/LogoCompo" component={LogoCompo} />
          <Route path="/AllAnnouncement" component={AllAnnouncement} />
          <Route path="/AllAnnouncementSA" component={AllAnnouncementSA} />
          <Route path="/AdminAnnouncement" component={AdminAnnouncement} />
          <Route path="/UserAnnouncement" component={UserAnnouncement} />
          <Route path="/EditPG" component={EditPG} />
          <Route path="/EditPHD" component={EditPHD} />
          <Route path="/EditMasterUG" component={EditMasterUG} />
          <Route path="/EditMasterPG" component={EditMasterPG} />
          <Route path="/EditMasterPHD" component={EditMasterPHD} />
          <Route path="/BusiUnitDash" component={BusiUnitDash} />
          <Route path="/SuccessPopup" component={SuccessPopup} />
          <Route path="/RegMsgPop" component={RegMsgPop} />
          <Route path="/OTPmsgpop" component={OTPmsgpop} />
          <Route path="/CommonSuccess" component={CommonSuccess} />
          <Route path="/Dummy" component={dummy} />
          <Route path="/Dummyfntn" component={Dummyfntn} />
          <Route path="/MasterSkills" component={MasterSkills} />
          <Route path="/EditMasterSkill" component={EditAdSkil} />
          <Route path="/ActiveReson" component={ActiveReson} />
          <Route path="/Loadingpage" component={LoadingPage} />
          <Route path="/LogoutConfirm" component={LogoutConfirmPop} />
          <Route path="/TestDrop" component={TestDrop} />
          <Route path="/Testimg" component={Tempfile} />
          <Route path="/Online" component={Online} />
          <Route path="/Chat" component={Chat} />
          {/* <Route path="/FB" component={FBTest}  /> */}
          <Route path="/SPEditAnnouncement" component={SPEditAnnouncement} />
          <Route path="/Planet" component={Planet} />
          <Route path="/AdminNotification" component={AdminNotification} />
          <Route path="/AdminSkillPop" component={AdminSkillPop} />
          <Route path="/CategoryMaster" component={CategoryMaster} />
          <Route path="/MasterDegree" component={MasterDegree} />
          <Route path="/MasterDegreeLevel" component={MasterDegreeLevel} />
          <Route path="/CourseName" component={CourseName} />
          <Route path="/Specialization" component={Specialization} />
          <Route path="/SACategory" component={SACategory} />
          <Route path="/SACourse" component={SACourse} />
          <Route path="/SASpecialization" component={SASpecialization} />
          <Route path="/SADegree" component={SADegree} />
          <Route path="/SADegreeLevel" component={SADegreeLevel} />
          <Route path="/Gridskill" component={Gridskill} />
          <Route path="/Lifestyle" component={Lifestyle} />
          <Route path="/Personality" component={Personality} />
          <Route path="/Intrest" component={Intrest} />
          <Route path="/Activities" component={Activities} />
          <Route path="/SADesignation" component={SADesignation} />
          <Route path="/SADepartment" component={SADepartment} />
          <Route path="/Contactmsg" component={Contactmsg} />
          <Route path="/regmsg" component={CommonSuccess} />
          <Route path="/UserNotifications" component={UserNotifications} />
          <Route path="/Teamdetail" component={Teamdetail} />
          <Route
            path="/UserAnnouncementpopup"
            component={UserAnnouncementpopup}
          />
          <Route path="/AnnouncementPreview" component={AnnouncementPreview} />
          <Route
            path="/AnnouncementPreviewSP"
            component={AnnouncementPreviewSP}
          />
          <Route path="/Chatbox" component={Chatbox} />
          <Route path="/EntityList" component={EntityList} />
          <Route path="/Aspuserlistchat" component={Aspuserlistchat} />
          <Route path="/SearchPopup" component={SearchPopup}></Route>
          <Route path="/ChatGrupPop" component={ChatGrupPop}></Route>
          <Route path="/Chatconfirm" component={Chatconfirm}></Route>
          <Route path="/UserChatPopUp" component={UserChatPopUp}></Route>

          {/* <Route path='/Messagebox' component={Messagebox}></Route>
           <Route path='/ButtonandInput' component={ButtonandInput}></Route>
           <Route path='/ListView' component={ListView}></Route> */}
          <Route path="/EntityListUser" component={EntityListUser}></Route>
          <Route path="/EditTrainingpop" component={EditTrainingpop} />
          <Route path="/AddTrainingpop" component={AddTrainingpop} />
          <Route
            path="/AddTrainingpopDesign"
            component={AddTrainingpopDesign}
          />
          <Route path="/Train" component={Train} />
          <Route path="/TrainingCard" component={TrainingCard} />
          <Route path="/LearningSchedule" component={LearningSchedule} />
          <Route path="/TrainingSchedule" component={TrainingSchedule} />
          <Route path="/TrainerList" component={TrainerList} />
          <Route path="/AddTrainerpop" component={AddTrainerpop} />
          <Route path="/EditTrainerpop" component={EditTrainerpop} />
          <Route path="/AssignTraining" component={AssignTraining} />
          <Route path="/AddLearning" component={AddLearning} />
          <Route path="/EditSchedule" component={EditSchedule} />
          <Route
            path="/TrainingEditTraineesPop"
            component={TrainingEditTraineesPop}
          />
          <Route
            path="/TrainingEditTrainerPop"
            component={TrainingEditTrainerPop}
          />
          <Route path="/AddTrainer" component={AddTrainer} />
          <Route path="/TrainerSchedule" component={TrainerSchedule} />
          <Route path="/TrainerDetails" component={TrainerDetails} />
          <Route path="/UserAssignTraining" component={UserAssignTraining} />
          <Route path="/UserActiveTraining" component={UserActiveTraining} />
          <Route
            path="/UserTrainingDocument"
            component={UserTrainingDocument}
          />
          <Route
            path="/TrainerAssignTraining"
            component={TrainerAssignTraining}
          />
          <Route path="/TrainerAssign" component={TrainerAssign} />
          <Route
            path="/TrainerActiveTraining"
            component={TrainerActiveTraining}
          />
          <Route path="/EditActiveTrainer" component={EditActiveTrainer} />
          <Route path="/AddTraineePop" component={AddTraineePop} />
          <Route
            path="/TrainerTrainingDocuments"
            component={TrainerTrainingDocuments}
          />
          <Route path="/Attendees" component={Attendees} />
          <Route path="/MeetingLink" component={MeetingLink} />
          <Route path="/TrainingsDocuments" component={TrainingsDocuments} />
          <Route
            path="/AddTraineeExistingPop"
            component={AddTraineeExistingPop}
          />
          <Route
            path="/TrainingsLandingPage"
            component={TrainingsLandingPage}
          />
          <Route path="/dm" component={MyComponent} />
          <Route
            path="/AdminMessageNotification"
            component={AdminMessageNotification}
          ></Route>
          <Route
            path="/UserMessageNotification"
            component={UserMessageNotification}
          ></Route>

          <Route
            path="/TrainingClassPopup"
            component={TrainingClassPopup}
          ></Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}
export default App;
