import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import SideBar from "../Common/Sidebar";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import PlusOne from "@material-ui/icons/Add";
import Add from "@material-ui/icons/Add";
import axios from "axios";
import BookmarkAddIcon from "@material-ui/icons/PostAdd";
import SchoolIcon from "@material-ui/icons/School";
import AddTrainingAddTrainer from "./AddTrainingAddTrainer";
import AddTrainingAddDesignation from "./AddTrainingAddDesignation";
import { BASE_URLs } from "../Config/Base";
export default class EditTrainingpop extends Component {
  fileInputRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Training: [],
      FileDocs: [],
      Departmentdrop: [],
      enabled: false,
      imageName: "",
      AssignedTraineePopModel: false,
      isOpen: false,
      Open: false,
      opens: false,
      Trainers: [],
      TrainersName: [],
      TrainingsName: [],
      selectedFiles: [],
      selectedFiless: [],
      selectedTrainer: [],
      selectedTrainerID: [],
      selectAll: false,
      showTick: false,
      files: [],
      message: "",
      error: "",
      showInput: false,
      isChecked: false,
      selectedCheckboxValues: [],
      selectedCheckboxValues1: [],
      selectedCheckboxValues2: [],
      selectedCheckboxValues3: [],
      DesignationCheckBoxValue: [],
      Designation: [],
      AddedDesignation: [],
      AddedDesignationNames: [],
      DesignationPopCheckBoxValue: [],
      TrainerPopCheckBoxValue: [],
      isAddTrainerOpen: false,
      selectedDesignation: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTrainersCheckbox = this.handleTrainersCheckbox.bind(this);
  }
  handleButtonsClick = () => {
    // Trigger the click event of the file input element
    this.fileInputRef.current.click();
  };

  handleTrainerssCheckbox(event) {
    const { defaultValue } = event.target;
    this.setState({
      isChecked: event.target.checked,
      defaultValue: defaultValue,
    });
  }

  openAddTrainerPopup = (e) => {
    e.preventDefault();
    this.setState({ isAddTrainerOpen: !this.state.isAddTrainerOpen });
  };

  // fetchData = async (firstname, TrainerId) => {
  //   // console.log("qwertyuiop", this.props.location.state);

  //   // console.log("TrainerId and FirstName", firstname, TrainerId);

  //   const data = new FormData();
  //   for (const [key, value] of data.entries()) {
  //     // console.log("------------------------------", key, value);
  //   }
  //   const url = `${BASE_URLs}/Training/EditAssignedTrainers?FirstName=${firstname}&TrainerId=${TrainerId}&TrainingId=${this.props.location.state.training_Id}`;
  //   // console.log(url);
  //   // const url = `${BASE_URLs}/Training/EditAssignedTrainers?FirstName=${firstname}&TrainingId=${this.props.location.state.training_Id}`;
  //   const options = {
  //     method: "POST",
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
  //     },
  //     body: data,
  //   };
  //   const response = await fetch(url, options);
  //   const result = await response.json();

  //   if (result.status === "Success") {
  //     toast.success(result.message, {
  //       position: "top-center",
  //       autoClose: 3200,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   } else {
  //     toast.error(result.message, {
  //       position: "top-center",
  //       autoClose: 3200,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  //   setTimeout(() => {
  //     this.GetTrainersNameFromAssignedTable();
  //   }, 1000);
  //   this.setState({ Open: false });

  //   setTimeout(() => {
  //     this.GetTrainersName();
  //   }, 1000);
  // };

  fetchData = async () => {
    const data = new FormData();

    let dd = this.state.TrainerPopCheckBoxValue.forEach((e) => {
      let split = e.split("$$$");

      data.append("TrainerId", split[1]);
      data.append("FirstName", split[0]);
      data.append("TrainingId", this.props.location.state.training_Id);
    });

    for (const [key, value] of data.entries()) {
      // console.log("FormData", key, value);
    }
    var is_selected = [];

    const url = `${BASE_URLs}/Training/EditAssignedTrainers`;

    // console.log(url);

    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    const response = await fetch(url, options);
    const result = await response.json();

    if (result.status === "Success") {
      toast.success(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // setTimeout(() => {
    //   this.GetTrainersNameFromAssignedTable();
    // }, 1000);
    setTimeout(() => {
      this.GetTrainersNameFromAssignedTable();
    }, 1000);
    this.setState({ Open: false });

    setTimeout(() => {
      this.GetTrainersName();
    }, 1000);
  };

  PrinCheckBoxValue = (event) => {
    const isChecked = event.target.checked;
    this.setState({ isChecked }, () => {
      // console.log("Checkbox value:", this.state.isChecked);
    });
  };

  handleButtonClick = () => {
    this.setState((prevState) => ({
      showInput: !prevState.showInput,
    }));
  };

  handleSelectChange = (event) => {
    this.setState({ files: event.target.files });
    this.AddSelectedFiles();
  };

  AddSelectedFiles = async () => {
    try {
      const formData = new FormData();
      for (let i = 0; i < this.state.files.length; i++) {
        formData.append("FileName", this.state.files[i]);
      }

      const response = await fetch(
        `${BASE_URLs}/Training/Addmoredocuments?TrainingId=${this.props.location.state.training_Id}`,
        {
          method: "POST",
          body: formData,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            // Add any required headers, such as authentication token
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        this.setState({ message: data.Message, error: "" });
      } else {
        const errorData = await response.json();
        this.setState({ error: errorData.Message, message: "" });
      }
    } catch (error) {
      // console.error("Error:", error);
    }
  };

  handleCheckboxChange = (event, file) => {
    const { checked } = event.target;
    const { selectedFiles, selectAll } = this.state;

    if (file === "all") {
      // If "Check All" checkbox is clicked
      this.setState({ selectAll: checked });
      if (checked) {
        // Select all files
        this.setState({ selectedFiles: this.props.files });
      } else {
        // Deselect all files
        this.setState({ selectedFiles: [] });
      }
    } else {
      if (checked) {
        // Add the file to the selectedFiles array
        const updatedSelectedFiles = [...selectedFiles, file];
        this.setState({ selectedFiles: updatedSelectedFiles });
      } else {
        // Remove the file from the selectedFiles array
        const updatedSelectedFiles = selectedFiles.filter(
          (selectedFile) => selectedFile !== file
        );
        this.setState({ selectedFiles: updatedSelectedFiles });
      }
    }
    // console.log("checked", this.props.upload_id);
  };

  handleDeleteClick = () => {
    const { selectedFiles } = this.state;
    // Perform the delete operation for the selected files

    // Clear the selectedFiles array
    this.setState({ selectedFiles: [] });
  };

  openPopup = () => {
    this.setState({ isOpen: true });
  };

  closePopup = () => {
    this.setState({ isOpen: false });
  };

  openThePopup = () => {
    this.setState({ Open: true });
  };

  closeThePopup = (event) => {
    event.preventDefault();
    this.setState({ Open: false });
  };

  openThePopups = (e) => {
    e.preventDefault();
    this.setState({ opens: true });
  };

  closeThePopups = (e) => {
    e.preventDefault();
    this.setState({ opens: false });
    this.setState({ DesignationPopCheckBoxValue: [] });
  };
  // Validation() {

  //   let formErrors = {};
  //   let formIsValid = true;
  //   var TrainingName = document.getElementById("TrainingName").value

  //   if (!TrainingName) {
  //     formIsValid = false;
  //     formErrors["TrainingName"] = "Training Name  is required";
  //   }

  //   this.setState({ formErrors: formErrors });
  //   return formIsValid;

  //}

  imageHandleChange(e) {
    if (e.target.files) {
      this.setState({
        imageName: [...e.target.files],
        showTick: true,
      });
    }
    // console.log("image", this.state.imageName);
  }

  handleSubmit(event) {
    event.preventDefault();
    // Code By Arun
    const { selectedTrainer, selectedDesignation } = this.state;
    // Code By Arun

    // if (this.Validation()) {
    const data = new FormData(event.target);
    // console.log(data);
    data.append("default_file_name", this.state.imageName);

    for (let i = 0; i < this.state.imageName.length; i++) {
      data.append("default_file_name", this.state.imageName[i]);
      // console.log("filearray", this.state.imageName[i]);
    }

    // Code By Arun
    if (selectedTrainer.length > 0) {
      selectedTrainer
        .filter((e) => e.check === true)
        .forEach((ee) => {
          data.append("Training_Id", this.props.location.state.training_Id);
          data.append("Trainer", ee.trainerName);
          data.append("TrainerId", ee.trainer_UserId);
        });
    }
    // Code By Arun

    // Code By Balaji
    if (selectedDesignation.length > 0) {
      selectedDesignation.forEach((value) => {
        data.append("Designation", value.designation);
      });
    }

    // Code By Balaji

    fetch(`${BASE_URLs}/Training/SAEditTraining`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === "Success") {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 1200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(function () {
            if (document.getElementById("close")) {
              document.getElementById("close").click();
            }
          }, 4000);
        } else {
          toast.error(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    this.SuccessModel();
    // }
  }
  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));

    this.props.history.push("/TrainingSchedule");
  };

  GetDepartment() {
    let url = `${BASE_URLs}/Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }
  GetFileDocs() {
    var Id = this.props.location.state.training_Id;
    let url = `${BASE_URLs}/Training/GetFileDocs?Training_Id=` + Id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          FileDocs: data,
        });
        // console.log("HHHHHHHH", data);
      });
  }

  GetTrainersName = (department) => {
    var Id = this.props.location.state.training_Id;

    let url =
      `${BASE_URLs}/Training/GetTrainersNameFromTrainerInfoTable?Department=` +
      department +
      "&TrainingId=" +
      Id +
      "";
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data", data);
        this.setState({
          Trainers: data,
        });
        // console.log("TrainersssNameee", data);
      });
  };

  Getdetail() {
    var Id = this.props.location.state.training_Id;

    let url = `${BASE_URLs}/Training/detail?Training_Id=` + Id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((result) => result.json())
      .then((data) => {
        this.setState({
          Training: data,
        });

        this.GetTrainersName(data[0].department);

        // console.log("-------------fdfdffdf-------", data[0].department);
      });
  }
  emailundisableTxt() {
    document.getElementById("department").disabled = false;
    document.getElementById("Trainingitem").disabled = false;
    document.getElementById("description").disabled = false;
    // document.getElementById("Ref_Docs").disabled = false;
    document.getElementById("Updatebtn").style.display = "inline-block";
    document.getElementById("EditBtn").style.display = "none";
  }

  handlingenable = (e) => {
    e.preventDefault();
    this.setState({ enabled: true });
  };
  handlingdisable = (e) => {
    e.preventDefault();
    this.setState({ enabled: false });
    document.getElementById("description").readOnly = true;
    document.getElementById("Trainingitem").readOnly = true;
    document.getElementById("department").disabled = true;
  };

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push("/TrainingSchedule");
    } else {
      this.GetAddedDesignation();
      this.GetDepartment();
      this.GetFileDocs();
      this.Getdetail();
      this.GetTrainersName();
      this.GetTrainersNameFromAssignedTable();

      this.GetDesignation();
    }
  }

  GetTrainersNameFromAssignedTable() {
    var Id = this.props.location.state.training_Id;
    // console.log("Training_ID", Id);
    let url =
      `${BASE_URLs}/Training/GetTrainersNameFromAssignedTrainerTable?id=` + Id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          TrainersName: data,
        });
        // console.log("Naaameeeeee", data);
      });
  }

  AssignedTraineePopModel = () => {
    this.setState((prevState) => ({
      AssignedTraineePopModel: !prevState.AssignedTraineePopModel,
    }));
  };

  DeleteMutipleFiles = () => {
    var Id = this.state.selectedCheckboxValues;

    let url = `${BASE_URLs}/Training/DeleteMutipleFiles`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    const data = {
      trainingIds: [Id],
    };

    const formData = new FormData();

    Id.forEach((e) => {
      formData.append("TrainingId", e);
    });
    for (const [key, value] of formData.entries()) {
      // console.log("------------------------------", key, value);
    }

    // console.log("HHHHHHHHHHHHHHH", Id);
    fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
      body: formData,
    })
      .then((result) => result.json())
      .then((responseData) => {
        // Handle the response here
        if (responseData.status === "Success") {
          toast.success(responseData.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            //   this.props.history.push("/TrainingSchedule");
            this.GetFileDocs();
            document
              .getElementById("delete")
              .setAttribute("style", "visibility: hidden;");
            document
              .getElementById("deletecount")
              .setAttribute("style", "visibility: hidden;");
          }, 500);
        } else {
          toast.error(responseData.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        // console.log("Message", responseData);
        // Update the state or perform any necessary actions after successful deletion
      });
  };

  DeleteMutipleTrainers = () => {
    var Ids = this.state.selectedCheckboxValues3;
    var UserId = this.state.selectedCheckboxValues2;

    let url = `${BASE_URLs}/Training/DeleteMultipleTrainers`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    const data = {
      trainingIds: [Ids],
    };

    const data1 = {
      UserId: [UserId],
    };

    const formData = new FormData();

    Ids.forEach((e) => {
      formData.append("TrainingId", e);
    });

    UserId.forEach((f) => {
      formData.append("UserId", f);
    });

    for (const [key, value] of formData.entries()) {
      // console.log("============", key, value);
    }

    // console.log("tgtgtgtgtgt", Ids, UserId);
    fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
      body: formData,
    })
      .then((result) => result.json())
      .then((responseData) => {
        // Handle the response here
        if (responseData.status === "Success") {
          toast.success(responseData.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            this.GetTrainersNameFromAssignedTable();
            document
              .getElementById("deletes")
              .setAttribute("style", "visibility: hidden;");
            document
              .getElementById("deletecounts")
              .setAttribute("style", "visibility: hidden;");
          }, 500);
        } else {
          toast.error(responseData.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        // console.log("Message", responseData);
        // Update the state or perform any necessary actions after successful deletion
      });
  };

  DeleteMutipleDesignation = () => {
    var Id = this.state.DesignationCheckBoxValue;

    let url = `${BASE_URLs}/Training/DeleteMutipleDesignations`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    const data = {
      trainingIds: [Id],
    };

    const formData = new FormData();

    Id.forEach((e) => {
      formData.append("TrainingId", e);
    });
    for (const [key, value] of formData.entries()) {
      // console.log("------------------------------", key, value);
    }

    // console.log("HHHHHHHHHHHHHHH", Id);
    fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
      body: formData,
    })
      .then((result) => result.json())
      .then((responseData) => {
        // Handle the response here
        if (responseData.status === "Success") {
          toast.success(responseData.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            this.GetAddedDesignation();
            document
              .getElementById("deletesss")
              .setAttribute("style", "visibility: hidden;");
            document
              .getElementById("deletecountsss")
              .setAttribute("style", "visibility: hidden;");
          }, 500);
        } else {
          toast.error(responseData.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        // console.log("Message", responseData);
        // Update the state or perform any necessary actions after successful deletion
      });
  };

  handleFileChange = (event) => {
    // const files = event.target.files;
    // if (files.length > 0) {
    //   // console.log("Files", files);
    //   // Call your API function here, passing the 'files' variable
    //   this.AddMoreDocuments(files);
    // }

    const selectedFiless = Array.from(event.target.files);
    this.setState({ selectedFiless });
    // console.log("=-=-=-=-=", selectedFiless);
  };

  handleDeleteClicks = (index) => {
    // Remove the first value from displayedValues
    const [firstValue, ...remainingValues] = this.state.selectedFiless;

    // Update the state with the remaining values
    this.setState({ selectedFiless: remainingValues });
  };
  AddMoreDocuments = (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("FileName", files[i]);
      // console.log("FileName", files[i].name);
      // console.log("Token", JSON.parse(localStorage.user));
    }
    axios
      .post(
        `${BASE_URLs}/Training/Addmoredocuments?TrainingId=${this.props.location.state.training_Id}`,
        formData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            // "Content-Type": "application/json-patch+json",
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
        }
      )
      .then((response) => {
        // Handle successful API response
        if (response.status === "Success") {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(response.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setTimeout(() => {
          this.GetFileDocs();
        }, 1000);
        // console.log(response.data);
      });
    // .catch((error) => {
    //   // Handle API call error
    //   console.error(error);
    // });
  };

  checkAll(e) {
    var checkboxes = document.getElementsByName("deletcateg");
    var selectedValues = [];

    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        selectedValues.push(checkboxes[i].value);
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    this.setState({
      selectedCheckboxValues: selectedValues,
    });
    // console.log(selectedValues);
  }

  checkAllDesignation(e) {
    var checkboxes = document.getElementsByName("deletcategsss");
    var selectedValues = [];

    var sa = document.getElementById("checkAllDesignations");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        selectedValues.push(checkboxes[i].value);
        document
          .getElementById("deletesss")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecountsss")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecountsss");
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("deletesss")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecountsss")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    this.setState({
      selectedCheckboxValues: selectedValues,
    });
    // console.log(selectedValues);
  }

  checkAllTrainers() {
    var checkboxes = document.getElementsByClassName("deletcategs");
    var selectedValues = [];
    var selectNames = [];

    var sa = document.getElementById("checkAllTrainers");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        selectedValues.push(checkboxes[i].value);
        selectNames.push(checkboxes[i].name);
        document
          .getElementById("deletes")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecounts")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecounts");
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("deletes")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecounts")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      selectedCheckboxValues3: selectedValues,
    });
    this.setState({
      selectedCheckboxValues2: selectNames,
    });

    // console.log(selectedValues, selectNames);
  }

  handlecheckbox() {
    let checkboxes = document.querySelectorAll('input[id="selectall"]:checked');
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;
      if (document.getElementById("selectall").checked) {
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      } else {
        msg.innerHTML =
          checkboxes.length === 1
            ? "<span> " + checkboxes.length + " Record is selected </span> "
            : "<span> " + checkboxes.length + " Records are selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }

  handleDesignationCheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[id="desigidcheck"]:checked'
    );
    checkboxes.forEach((checkbox) => {
      // console.log("CheckBoxValue", checkbox.value);
    });
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldivsss")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldivsss")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("deletesss")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecountsss")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecountsss");
      let count = checkboxes.length - 1;
      if (document.getElementById("checkAllDesignations").checked) {
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      } else {
        msg.innerHTML =
          checkboxes.length === 1
            ? "<span> " + checkboxes.length + " Record is selected </span> "
            : "<span> " + checkboxes.length + " Records are selected </span> ";
      }
    } else {
      document
        .getElementById("deletesss")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecountsss")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("checkAllDesignations").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("checkAllDesignations").checked = true;
      }
    } else {
      if (document.getElementById("checkAllDesignations").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("checkAllDesignations").checked = false;
        }
      }
    }
  }

  handleTrainersCheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[id="trainerscheckid"]:checked'
    );
    checkboxes.forEach((checkbox) => {
      // console.log("CheckBoxValue", checkbox.value);
    });
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldivs")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldivs")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("deletes")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecounts")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecounts");
      let count = checkboxes.length - 1;
      if (document.getElementById("checkAllTrainers").checked) {
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      } else {
        msg.innerHTML =
          checkboxes.length === 1
            ? "<span> " + checkboxes.length + " Record is selected </span> "
            : "<span> " + checkboxes.length + " Records are selected </span> ";
      }
    } else {
      document
        .getElementById("deletes")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecounts")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("checkAllTrainers").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("checkAllTrainers").checked = true;
      }
    } else {
      if (document.getElementById("checkAllTrainers").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("checkAllTrainers").checked = false;
        }
      }
    }
  }

  checkChange = (event) => {
    this.handlecheckbox();

    const { name, checked, value } = event.target;
    // value1.indexOf(name) === -1
    //   ? value1.push(name)
    //   : value1.splice(value1.indexOf(name), 1);

    this.setState((prevState) => {
      const selectedCheckboxValues = checked
        ? [...prevState.selectedCheckboxValues, value]
        : prevState.selectedCheckboxValues.filter((val) => val !== value);

      // console.log("SelectedDocumentValue", selectedCheckboxValues);
      return { selectedCheckboxValues };
    });
  };

  checkChangeDesignation = (event) => {
    this.handleDesignationCheckbox();

    const { name, checked, value } = event.target;
    // value1.indexOf(name) === -1
    //   ? value1.push(name)
    //   : value1.splice(value1.indexOf(name), 1);

    this.setState((prevState) => {
      const DesignationCheckBoxValue = checked
        ? [...prevState.DesignationCheckBoxValue, value]
        : prevState.DesignationCheckBoxValue.filter((val) => val !== value);

      // console.log("SelectedDesignation", DesignationCheckBoxValue);
      return { DesignationCheckBoxValue };
    });
  };

  checkChangePopDesignation = (event) => {
    //this.handleDesignationCheckbox();

    const { name, checked, value } = event.target;
    // value1.indexOf(name) === -1
    //   ? value1.push(name)
    //   : value1.splice(value1.indexOf(name), 1);

    this.setState((prevState) => {
      const DesignationPopCheckBoxValue = checked
        ? [...prevState.DesignationPopCheckBoxValue, value]
        : prevState.DesignationPopCheckBoxValue.filter((val) => val !== value);

      // console.log("SelectedDesignation", DesignationPopCheckBoxValue);
      return { DesignationPopCheckBoxValue };
    });
  };

  checkChangePopTrainers = (event) => {
    //this.handleDesignationCheckbox();

    const { name, checked, value } = event.target;
    // value1.indexOf(name) === -1
    //   ? value1.push(name)
    //   : value1.splice(value1.indexOf(name), 1);

    this.setState((prevState) => {
      const TrainerPopCheckBoxValue = checked
        ? [...prevState.TrainerPopCheckBoxValue, value]
        : prevState.TrainerPopCheckBoxValue.filter((val) => val !== value);

      // console.log("SelectedDesignation", TrainerPopCheckBoxValue);
      return { TrainerPopCheckBoxValue };
    });
  };

  checkChangeTrainers = (event) => {
    this.handleTrainersCheckbox();

    const { name, checked, value } = event.target;
    // value2.indexOf(value) === -1
    //   ? value2.push(value)
    //   : value2.splice(value2.indexOf(value), 1);
    // value3.indexOf(name) === -1
    //   ? value3.push(name)
    //   : value3.splice(value3.indexOf(name), 1);
    this.setState((prevState) => {
      const selectedCheckboxValues2 = checked
        ? [...prevState.selectedCheckboxValues2, name]
        : prevState.selectedCheckboxValues2.filter((value) => value !== name);

      // console.log("ValuesSelected", selectedCheckboxValues2);
      return { selectedCheckboxValues2 };
    });

    this.setState((prevState) => {
      const selectedCheckboxValues3 = checked
        ? [...prevState.selectedCheckboxValues3, value]
        : prevState.selectedCheckboxValues2.filter((value) => value !== value);

      // console.log("SelectedTrainingId", selectedCheckboxValues3);
      return { selectedCheckboxValues3 };
    });
  };

  componentWillUnmount() {
    this.setState({
      // Reset the state to its initial values here
      selectedCheckboxValues: [],
    });
    this.setState({
      // Reset the state to its initial values here
      selectedCheckboxValues2: [],
    });

    this.setState({
      // Reset the state to its initial values here
      selectedCheckboxValues3: [],
    });
  }

  GetDesignation() {
    let url = `${BASE_URLs}/Designation/GetMasterDesignation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        let final = data.filter(
          (obj) => !this.state.AddedDesignationNames.includes(obj.designation)
        );

        // console.log(final.length);

        final = final.map((e) => {
          return { ...e, ...{ check: false } };
        });

        this.setState({ Designation: final });

        this.setState({
          // Designation: data,
          Desi: data,
        });
        //
      });
  }
  handleTrainerChange = (event, s) => {
    let selectedID = s
      .map((d) => {
        if (event.includes(d.trainerName)) {
          return d.trainer_UserId;
        }
      })
      .filter(function (x) {
        return x !== undefined;
      });
    this.setState({ ...(this.state.selectedTrainer = event) });
    this.setState({ ...(this.state.selectedTrainerID = selectedID) });
  };
  DeleteTrainer = (array, id) => {
    // console.log("fffffffff", array, id);
    let changes = this.state.Trainers.map((e, index) => {
      if (e.trainer_UserId === id) return { ...e, ...{ check: false } };
      return { ...e };
    });
    this.setState({ selectedTrainer: changes.filter((e) => e.check === true) });
    this.setState({ Trainers: changes });
  };
  GetAddedDesignation() {
    let url = `${BASE_URLs}/Training/GetAddedDesignation?id=${this.props.location.state.training_Id}`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          AddedDesignation: data,
        });
        this.setState({
          AddedDesignationNames: data.map((e) => e.designation),
        });

        //
      });
  }

  // handleButtonClicks = async () => {

  //   const { selectedCheckboxValues } = this.state;

  //   for (const value of selectedCheckboxValues) {

  //     var index0 = value.split('$$$')[0]
  //     var index1 = value.split('$$$')[1]

  //     try {
  //       const response = await fetch(`${BASE_URLs}/Training/AddMultipleDesignation?Designation=${index1}&DesignationId=${index0}&TrainingId=${this.props.location.state.training_Id}`, {
  //         method: 'POST',
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ value }),
  //       });

  //       if (response.ok) {
  //         // console.log(`Posted value: ${value}`);
  //       } else {
  //         console.error(`Failed to post value: ${value}`);
  //       }
  //     } catch (error) {
  //       console.error(`Error posting value: ${value}`, error);
  //     }
  //   }
  // };

  // handleSelectedRows = () => {
  //   // Do something with this.state.selectedRows

  //   // console.log(this.state.selectedRows);
  // };
  fetchDesigData = async (desigantion, desigantionId, trainingId) => {
    // console.log("TrainerId and Topic", desigantion);

    // console.log("TrainerId and Topic", desigantionId);

    // console.log("TrainerId and Topic", trainingId);

    const data = new FormData();

    let dd = this.state.DesignationPopCheckBoxValue.forEach((e) => {
      let split = e.split("$$$");

      data.append("Designation", split[1]);
      data.append("DesignationId", split[0]);
      data.append("TrainingId", this.props.location.state.training_Id);
    });

    for (const [key, value] of data.entries()) {
      // console.log("FormData", key, value);
    }
    var is_selected = [];

    const url = `${BASE_URLs}/Training/AddMultipleDesignation`;

    // console.log(url);

    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    const response = await fetch(url, options);
    const result = await response.json();

    if (result.status === "Success") {
      toast.success(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    this.GetAddedDesignation();

    this.setState({ opens: false });
  };

  DeleteDesignation = (array, id) => {
    // console.log("fff", id, this.state.Designation);
    let changes = this.state.Designation.map((e) => {
      if (e.id === id) {
        return { ...e, ...{ check: false } };
      }
      return { ...e };
    });

    this.setState({
      selectedDesignation: changes.filter((e) => e.check === true),
    });

    let arr = changes.filter((e) => e.check === true).map((e) => e.designation);
    let final = changes.map((obj) => {
      if (!arr.includes(obj.designation)) {
        return { ...obj, ...{ check: false } };
      }
      return { ...obj };
    });
    this.setState({ Designation: final });
  };

  render() {
    const { selectedFiles, selectAll } = this.state;
    const { files } = this.props;
    const { showInput } = this.state;
    const { isChecked, defaultValue } = this.state;

    var w = window.innerWidth;
    const {
      Training,
      Departmentdrop,
      enabled,
      FileDocs,
      TrainingsName,
      Trainers,
      TrainersName,
      Designation,
      AddedDesignation,
      selectedFiless,
      isAddTrainerOpen,
      selectedTrainer,
    } = this.state;
    const { TrainingName } = this.state.formErrors;

    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          {Training.map((Training) => (
                            <h4 style={{ marginRight: "3%" }} className="title">
                              {Training.trainingitem}
                            </h4>
                          ))}
                        </div>
                        <div className="card-header-right"></div>

                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row" style={{ marginTop: "3%" }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="container">
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        {Training.map((Training) => (
                                          <div>
                                            <input
                                              type="hidden"
                                              name="Training_Id"
                                              defaultValue={
                                                Training.training_Id
                                              }
                                            />
                                          </div>
                                        ))}
                                        <div className="col-sm-6">
                                          {Training.map((Training) => (
                                            <select
                                              className="effect-16"
                                              id="department"
                                              defaultValue={Training.department}
                                              name="Department"
                                              type="text"
                                            >
                                              <option value="">Select</option>
                                              {Departmentdrop.map(
                                                (Departmentdrop) => (
                                                  <option
                                                    value={
                                                      Departmentdrop.department
                                                    }
                                                  >
                                                    {Departmentdrop.department}{" "}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          ))}
                                          <span style={{ color: "#a29d9d" }}>
                                            Department
                                          </span>{" "}
                                          <span style={{ color: "red" }}></span>
                                        </div>
                                        <div className="col-sm-6">
                                          {Training.map((Training) => (
                                            <input
                                              className="effect-16"
                                              name="Trainingitem"
                                              id="Trainingitem"
                                              defaultValue={
                                                Training.trainingitem
                                              }
                                              type="text"
                                              maxLength="50"
                                            />
                                          ))}
                                          <span style={{ color: "#a29d9d" }}>
                                            Training Topic
                                          </span>{" "}
                                          <span style={{ color: "red" }}></span>
                                        </div>
                                        <div className="col-sm-6">
                                          {Training.map((Training) => (
                                            <input
                                              className="effect-16"
                                              name="description"
                                              defaultValue={
                                                Training.description
                                              }
                                              id="description"
                                              type="text"
                                              //maxLength="50"
                                            />
                                          ))}
                                          <span style={{ color: "#a29d9d" }}>
                                            Training Description
                                          </span>{" "}
                                          <span style={{ color: "red" }}></span>
                                        </div>

                                        <div className="col-sm-6">
                                          {Training.map((Training) => (
                                            // <input
                                            //   className="effect-16"
                                            //   name="Duration"
                                            //   defaultValue={Training.duration}
                                            //   id="Duration"
                                            //   type="text"
                                            //   maxLength="50"
                                            // />
                                            <select
                                              className="effect-16"
                                              name="Duration"
                                              id="duration"
                                              type="text"
                                            >
                                              <option
                                                hidden
                                                value={Training.duration}
                                              >
                                                {" "}
                                                {Training.duration}
                                              </option>

                                              <option
                                                value="1 Hour"
                                                id="duration"
                                              >
                                                1 Hour
                                              </option>
                                              <option
                                                value="1.5 Hour"
                                                id="duration"
                                              >
                                                1.5 Hour
                                              </option>
                                              <option
                                                value="2 Hours"
                                                id="duration"
                                              >
                                                2 Hours
                                              </option>
                                              <option
                                                value="2.5 Hour"
                                                id="duration"
                                              >
                                                2.5 Hour
                                              </option>
                                              <option
                                                value="3 Hours"
                                                id="duration"
                                              >
                                                3 Hours
                                              </option>
                                              <option
                                                value="3.5 Hour"
                                                id="duration"
                                              >
                                                3.5 Hour
                                              </option>
                                              <option
                                                value="4 Hours"
                                                id="duration"
                                              >
                                                4 Hours
                                              </option>
                                              <option
                                                value="4.5 Hour"
                                                id="duration"
                                              >
                                                4.5 Hour
                                              </option>
                                              <option
                                                value="5 Hours"
                                                id="duration"
                                              >
                                                5 Hours
                                              </option>
                                              <option
                                                value="5.5 Hour"
                                                id="duration"
                                              >
                                                5.5 Hour
                                              </option>
                                              <option
                                                value="6 Hours"
                                                id="duration"
                                              >
                                                6 Hours
                                              </option>
                                              <option
                                                value="6.5 Hour"
                                                id="duration"
                                              >
                                                6.5 Hour
                                              </option>
                                              <option
                                                value="7 Hours"
                                                id="duration"
                                              >
                                                7 Hours
                                              </option>
                                              <option
                                                value="7.5 Hour"
                                                id="duration"
                                              >
                                                7.5 Hour
                                              </option>
                                              <option
                                                value="8 Hours"
                                                id="duration"
                                              >
                                                8 Hours
                                              </option>
                                              <option
                                                value="8.5 Hour"
                                                id="duration"
                                              >
                                                8.5 Hour
                                              </option>
                                              <option
                                                value="9 Hour"
                                                id="duration"
                                              >
                                                9 Hour
                                              </option>
                                              <option
                                                value="9.5 Hour"
                                                id="duration"
                                              >
                                                9.5 Hour
                                              </option>
                                            </select>
                                          ))}
                                          <span style={{ color: "#a29d9d" }}>
                                            Duration Of Training
                                          </span>{" "}
                                          <span style={{ color: "red" }}></span>
                                        </div>

                                        <div className="col-sm-6"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container">
                              <div className="row">
                                {/* Documents */}
                                <div className="col-lg-4">
                                  <div
                                    style={{
                                      margin: "10px",
                                      padding: "20px",
                                      overflow: "auto",
                                      height: "250px",
                                      backgroundColor: "white",
                                      borderTop: "5px solid whitesmoke",
                                      borderBottom: "5px solid whitesmoke",
                                      borderLeft: "5px solid whitesmoke",
                                      borderRight: "5px solid whitesmoke",
                                      boxShadow: "10px 10px 5px lightblue",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      onClick={this.handleButtonsClick}
                                      style={{ width: "40px", height: "30px" }}
                                    >
                                      <PlusOne />
                                    </button>
                                    <input
                                      id="Ref_Docs"
                                      hidden
                                      onChange={this.handleFileChange}
                                      name="Ref_Docs"
                                      multiple
                                      type="file"
                                      ref={this.fileInputRef}
                                    />

                                    <span
                                      id="deletecount"
                                      className="multitrash"
                                      style={{
                                        visibility: "hidden",
                                        fontSize: "17px",
                                      }}
                                    ></span>
                                    <div
                                      id="selectalldiv"
                                      style={{ visibility: "hidden" }}
                                    >
                                      &nbsp;&nbsp;
                                      <button
                                        type="button"
                                        className="multitrash"
                                        id="delete"
                                        onClick={this.DeleteMutipleFiles}
                                        style={{
                                          float: "right",
                                          visibility: "hidden",
                                        }}
                                      >
                                        <i
                                          className="icofont icofont-delete-alt"
                                          style={{ color: "darkgray" }}
                                        ></i>
                                      </button>
                                    </div>

                                    <table
                                      id="skillsheet"
                                      className="table nowrap SkillGrid"
                                    >
                                      <thead style={{ textAlign: "left" }}>
                                        <tr>
                                          <th>
                                            <input
                                              type="checkbox"
                                              id="selectall"
                                              onClick={() => {
                                                this.checkAll(this);
                                              }}
                                            />
                                          </th>
                                          <th>Add Documents</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.FileDocs.map(
                                          (value, index) => (
                                            <tr>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  className="deletcateg"
                                                  id="selectall"
                                                  name="deletcateg"
                                                  onChange={this.checkChange}
                                                  defaultValue={value.file_id}
                                                />
                                              </td>
                                              <td>{value.file_name}</td>
                                            </tr>
                                          )
                                        )}

                                        {this.state.selectedFiless.map(
                                          (value, index) => (
                                            <tr key={index}>
                                              <td>
                                                <BookmarkAddIcon
                                                  style={{ color: "grey" }}
                                                />
                                              </td>
                                              <td>{value.name}</td>
                                              <td>
                                                <button
                                                  onClick={() =>
                                                    this.handleDeleteClicks(
                                                      index
                                                    )
                                                  }
                                                  type="submit"
                                                  className="multitrash"
                                                  id="deletes"
                                                >
                                                  <i
                                                    className="icofont icofont-delete-alt"
                                                    style={{ color: "red" }}
                                                  ></i>
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                {/* Trainers */}
                                <div className="col-lg-4">
                                  <div
                                    style={{
                                      margin: "10px",
                                      padding: "20px",
                                      overflow: "auto",
                                      height: "250px",
                                      backgroundColor: "white",
                                      borderTop: "5px solid whitesmoke",
                                      borderBottom: "5px solid whitesmoke",
                                      borderLeft: "5px solid whitesmoke",
                                      borderRight: "5px solid whitesmoke",
                                      boxShadow: "10px 10px 5px lightblue",
                                    }}
                                  >
                                    {/* Rest of the code */}
                                    <button
                                      style={{ width: "40px", height: "30px" }}
                                      onClick={(e) =>
                                        this.openAddTrainerPopup(e)
                                      }
                                    >
                                      {" "}
                                      <Add />
                                    </button>
                                    <span
                                      id="deletecounts"
                                      className="multitrash"
                                      style={{
                                        visibility: "hidden",
                                        fontSize: "17px",
                                      }}
                                    ></span>
                                    <div
                                      id="selectalldivs"
                                      style={{ visibility: "hidden" }}
                                    >
                                      &nbsp;&nbsp;
                                      <button
                                        className="multitrash"
                                        type="button"
                                        id="deletes"
                                        onClick={this.DeleteMutipleTrainers}
                                        style={{
                                          float: "right",
                                          visibility: "hidden",
                                        }}
                                      >
                                        <i
                                          className="icofont icofont-delete-alt"
                                          style={{ color: "darkgray" }}
                                        ></i>
                                      </button>
                                    </div>
                                    <table
                                      id="skillsheet"
                                      className="table   nowrap SkillGrid"
                                    >
                                      <thead style={{ textAlign: "left" }}>
                                        <tr>
                                          <th>
                                            {" "}
                                            <input
                                              type="checkbox"
                                              id="checkAllTrainers"
                                              onClick={() => {
                                                this.checkAllTrainers(this);
                                              }}
                                            />{" "}
                                          </th>
                                          <th>Assign Trainers &nbsp;&nbsp;</th>

                                          {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.TrainersName.map(
                                          (value) => (
                                            <tr>
                                              <td>
                                                {" "}
                                                <input
                                                  type="checkbox"
                                                  className="deletcategs"
                                                  name={value.userId}
                                                  defaultValue={
                                                    value.trainingTopicId
                                                  }
                                                  id="trainerscheckid"
                                                  onChange={
                                                    this.checkChangeTrainers
                                                  }
                                                />
                                              </td>
                                              <td>{value.trainerName}</td>
                                              {/* <td style={{ textAlign: "center" }}>
                                            <button
                                              name="delete"
                                              className="button-30 sk-del"
                                              //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                              // onClick={(e) => {
                                              //   e.preventDefault();
                                              //   this.DeleteButton(
                                              //     FileDocs.upload_id,
                                              //   );
                                              // }}
                                            >
                                              <i
                                                className="fa fa-trash-o"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </td> */}
                                            </tr>
                                          )
                                        )}

                                        {this.state.selectedTrainer.map(
                                          (value, index) => (
                                            <tr key={index}>
                                              <td style={{ fontSize: "24px" }}>
                                                {" "}
                                                <i
                                                  className="icofont icofont-business-man-alt-3"
                                                  style={{ color: "grey" }}
                                                ></i>{" "}
                                              </td>
                                              <td>{value.trainerName}</td>
                                              <td>
                                                <button
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.DeleteTrainer(
                                                      value,
                                                      value.trainer_UserId
                                                    );
                                                  }}
                                                  type="submit"
                                                  className="multitrash"
                                                  id="deletes"
                                                >
                                                  <i
                                                    className="icofont icofont-delete-alt"
                                                    style={{ color: "red" }}
                                                  ></i>
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                {/* Designation */}

                                <div className="col-lg-4">
                                  <div
                                    style={{
                                      margin: "10px",
                                      padding: "20px",
                                      overflow: "auto",
                                      height: "250px",
                                      backgroundColor: "white",
                                      borderTop: "5px solid whitesmoke",
                                      borderBottom: "5px solid whitesmoke",
                                      borderLeft: "5px solid whitesmoke",
                                      borderRight: "5px solid whitesmoke",
                                      boxShadow: "10px 10px 5px lightblue",
                                    }}
                                  >
                                    {/* Rest of the code */}
                                    <button
                                      style={{ width: "40px", height: "30px" }}
                                      onClick={this.openThePopups}
                                    >
                                      {" "}
                                      <Add />
                                    </button>
                                    <span
                                      id="deletecountsss"
                                      className="multitrash"
                                      style={{
                                        visibility: "hidden",
                                        fontSize: "17px",
                                      }}
                                    ></span>
                                    <div
                                      id="selectalldivsss"
                                      style={{ visibility: "hidden" }}
                                    >
                                      &nbsp;&nbsp;
                                      <button
                                        className="multitrash"
                                        id="deletesss"
                                        type="button"
                                        onClick={this.DeleteMutipleDesignation}
                                        style={{
                                          float: "right",
                                          visibility: "hidden",
                                        }}
                                      >
                                        <i
                                          className="icofont icofont-delete-alt"
                                          style={{ color: "darkgray" }}
                                        ></i>
                                      </button>
                                    </div>
                                    <table
                                      id="skillsheet"
                                      className="table   nowrap SkillGrid"
                                    >
                                      <thead style={{ textAlign: "left" }}>
                                        <tr>
                                          <th>
                                            {" "}
                                            <input
                                              type="checkbox"
                                              id="checkAllDesignations"
                                              onClick={() => {
                                                this.checkAllDesignation(this);
                                              }}
                                            />{" "}
                                          </th>
                                          <th>
                                            Assign to Designation &nbsp;&nbsp;
                                          </th>

                                          {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.AddedDesignation.map(
                                          (value) => (
                                            <tr>
                                              <td>
                                                {" "}
                                                <input
                                                  type="checkbox"
                                                  //className="deletcategsss"
                                                  name="deletcategsss"
                                                  id="desigidcheck"
                                                  defaultValue={value.desigId}
                                                  onChange={
                                                    this.checkChangeDesignation
                                                  }
                                                />
                                              </td>
                                              <td>{value.designation}</td>
                                              {/* <td style={{ textAlign: "center" }}>
                                            <button
                                              name="delete"
                                              className="button-30 sk-del"
                                              //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                              // onClick={(e) => {
                                              //   e.preventDefault();
                                              //   this.DeleteButton(
                                              //     FileDocs.upload_id,
                                              //   );
                                              // }}
                                            >
                                              <i
                                                className="fa fa-trash-o"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </td> */}
                                            </tr>
                                          )
                                        )}
                                        {this.state.selectedDesignation.map(
                                          (value) => (
                                            <tr>
                                              <td>
                                                <SchoolIcon
                                                  style={{ color: "grey" }}
                                                />
                                              </td>
                                              <td>{value.designation}</td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                <button
                                                  type="button"
                                                  name="delete"
                                                  className="multitrash"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.DeleteDesignation(
                                                      value,
                                                      value.id
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    className="icofont icofont-delete-alt"
                                                    style={{ color: "red" }}
                                                  ></i>
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ textAlign: "center" }}
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <button
                                    type="submit"
                                    className="but"
                                    style={{ height: "39.5px" }}
                                  >
                                    Save
                                  </button>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <button
                                    type="button"
                                    className=" but"
                                    style={{ height: "39.5px" }}
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </div>
                            </div>
                          </form>
                          <br />
                        </div>

                        {/* <div
                            className="flex-container"
                            style={{
                              display: "flex",
                              background:
                                "linear-gradient(135deg, #a45bec, #7367f0)",
                            }}
                          >
                            
                            <div
                              style={{
                                margin: "10px",
                                width: "50%",
                                padding: "20px",
                                overflow: "auto",
                                backgroundColor: "white",
                                borderTop: "5px solid whitesmoke",
                                borderBottom: "5px solid whitesmoke",
                                borderLeft: "5px solid whitesmoke",
                                borderRight: "5px solid whitesmoke",
                                boxShadow: "10px 10px 5px lightblue",
                              }}
                            >
     
                              <label
                                for="Ref_Docs"
                                style={{
                                  height: "30.5px",
                                  width: "10%",
                                  padding: "4px 12px",
                                  marginRight: "400px",
                                }}
                                className=" btn-Secondary"
                              >
                                <PlusOne style={{fontWeight:"bold",color:"black"}}/>
                              </label>

                              <input
                                id="Ref_Docs"
                                hidden
                                // onChange={this.handleSelectChange}
                                name="Ref_Docs"
                                multiple
                                type="file"
                              />
                              <span
                                id="deletecount"
                                className="multitrash"
                                style={{
                                  visibility: "hidden",
                                  fontSize: "17px",
                                }}
                              ></span>
<div id="selectalldiv" style={{visibility:"hidden"}}>
          &nbsp;&nbsp;
        <button type="submit" className="multitrash" id="delete" style={{float:"right",visibility:"hidden"}}><i className="icofont icofont-delete-alt" style={{ color: "darkgray" }}></i></button> 

        </div>

                              <table
                                id="skillsheet"
                                className="table   nowrap SkillGrid"
                              >
                                <thead style={{ textAlign: "left" }}>
                                  <tr>
                                    <th>
                                      {" "}
                                      <input
                                        type="checkbox"
                                        id="selectall"
                                        onClick={() => {
                                          this.checkAll(this);
                                        }}
                                      />{" "}
                                    </th>
                                    <th>Documents &nbsp;&nbsp;</th>

                                    <th style={{ textAlign: "center" }}>
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {FileDocs.map((FileDocs) => (
                                    <tr>
                                      <td>
                                        {" "}
                                        <input
                                          type="checkbox"
                                          name="deletcateg"
                                          defaultValue={FileDocs.upload_id}
                                          onChange={this.handlecheckbox}
                                        />
                                      </td>
                                      <td>{FileDocs.file_name}</td>
                                      <td style={{ textAlign: "center" }}>
                                        <button
                                          name="delete"
                                          className="button-30 sk-del"
                                          //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                          // onClick={(e) => {
                                          //   e.preventDefault();
                                          //   this.DeleteButton(
                                          //     FileDocs.upload_id,
                                          //   );
                                          // }}
                                        >
                                          <i
                                            className="fa fa-trash-o"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div
                              style={{
                                // backgroundColor: "#f1f1f1",
                                margin: "10px",
                                width: "50%",
                                padding: "20px",
                                overflow: "auto",
                                backgroundColor: "white",
                                borderTop: "5px solid whitesmoke",
                                borderBottom: "5px solid whitesmoke",
                                borderLeft: "5px solid whitesmoke",
                                borderRight: "5px solid whitesmoke",
                                boxShadow: "10px 10px 5px lightblue",
                              }}
                            >
                              <span
                                id="deletecounts"
                                className="multitrash"
                                style={{
                                  visibility: "hidden",
                                  fontSize: "17px",
                                }}
                              ></span>
 <div id="selectalldivs" style={{visibility:"hidden"}}>
          &nbsp;&nbsp;
          <button type="submit" className="multitrash" id="deletes" style={{float:"right",visibility:"hidden"}}><i className="icofont icofont-delete-alt" style={{ color: "darkgray" }}></i></button> 

        </div>
                              <table
                                id="skillsheet"
                                className="table   nowrap SkillGrid"
                              >
                                <thead style={{ textAlign: "left" }}>
                                  <tr>
                                    <th>
                                      {" "}
                                      <input
                                        type="checkbox"
                                        id="checkAllTrainers"
                                        onClick={() => {
                                          this.checkAllTrainers(this);
                                        }}
                                      />{" "}
                                    </th>
                                    <th>Trainers &nbsp;&nbsp;</th>

                                    <th style={{ textAlign: "center" }}>
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Trainers.map((Trainers) => (
                                    <tr>
                                      <td>
                                        {" "}
                                        <input
                                          type="checkbox"
                                          name="deletcategs"
                                          // defaultValue={FileDocs.upload_id}
                                          onChange={this.handleTrainersCheckbox}
                                        />
                                      </td>
                                      <td>{Trainers.trainerName}</td>
                                      <td style={{ textAlign: "center" }}>
                                        <button
                                          name="delete"
                                          className="button-30 sk-del"
                                          //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                          // onClick={(e) => {
                                          //   e.preventDefault();
                                          //   this.DeleteButton(
                                          //     FileDocs.upload_id,
                                          //   );
                                          // }}
                                        >
                                          <i
                                            className="fa fa-trash-o"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div> */}

                        {/* {FileDocs.map(FileDocs => 
                                <div className="container" 
                                style={{
                                  background: "#4CAF50",
                                  color: "white",
                                  padding: "15px",
                                  width: "50%",
                                  height: "100px",
                                  overFlow: "auto",
                                  border: "1px solid #ccc",
                                }}
                                >

    {FileDocs.file_name} 
    </div>)} */}

                        {/* <div style={{float: "left", width: "50%"}}>Left Div</div>
    <div style={{float: "right", width: "50%"}}>Right Div</div> */}

                        <div className="card-block">
                          <div className="dt-responsive table-responsive table-res">
                            {/* {this.state.Training.length === 0 ? null :

                              <table id="DepSheet" className="table nowrap" style={{ maxWidth: "90%" }}>
                                <thead>
                                  <tr>
                                    <th>Reference Document</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {FileDocs.map(FileDocs => <tr>
                                    <td>{FileDocs.file_name}</td>
                                  </tr>)}
                                </tbody>
                              </table>
                            } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.isOpen && (
          <div className="pop">
            <div className="popinnernormal">
              <form autoComplete="off" encType="multipart/form-data">
                {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}} onClick={this.props.close}  ><CloseIcon/></button> */}
                <button
                  style={{
                    float: "right",
                    backgroundColor: "#a0a3a305",
                    color: "#797070",
                    border: "1px solid #a3a5a505",
                  }}
                  onClick={this.closePopup}
                >
                  <CloseIcon />
                </button>

                <div className="row" tyle={{ padding: "8px" }}>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-8">
                    <div className="dt-responsive table-responsive table-res">
                      {this.state.Training.length === 0 ? null : (
                        <table
                          id="DepSheet"
                          className="table nowrap"
                          style={{ maxWidth: "90%" }}
                        >
                          <thead>
                            <tr>
                              <th>Reference Document</th>
                            </tr>
                          </thead>
                          <tbody>
                            {FileDocs.map((FileDocs) => (
                              <tr>
                                <td>{FileDocs.file_name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4"></div>
                </div>

                <div className="row">
                  <div
                    className="col-sm-12"
                    style={{ textAlign: "center" }}
                  ></div>
                </div>
              </form>
              <br />
              {/* <button onClick={this.closePopup}><CloseIcon/></button> */}
            </div>
          </div>
        )}

        {this.state.isOpen && (
          <div className="pop">
            <div className="popinnernormal">
              <form autoComplete="off" encType="multipart/form-data">
                {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}} onClick={this.props.close}  ><CloseIcon/></button> */}
                <button
                  style={{
                    float: "right",
                    backgroundColor: "#a0a3a305",
                    color: "#797070",
                    border: "1px solid #a3a5a505",
                  }}
                  onClick={this.closePopup}
                >
                  <CloseIcon />
                </button>

                <div className="row" tyle={{ padding: "8px" }}>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-8">
                    <div className="dt-responsive table-responsive table-res">
                      {this.state.Training.length === 0 ? null : (
                        <table
                          id="DepSheet"
                          className="table nowrap"
                          style={{ maxWidth: "90%" }}
                        >
                          <thead>
                            <tr>
                              <th>Reference Document</th>
                            </tr>
                          </thead>
                          <tbody>
                            {FileDocs.map((FileDocs) => (
                              <tr>
                                <td>{FileDocs.file_name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4"></div>
                </div>

                <div className="row">
                  <div
                    className="col-sm-12"
                    style={{ textAlign: "center" }}
                  ></div>
                </div>
              </form>
              <br />
              {/* <button onClick={this.closePopup}><CloseIcon/></button> */}
            </div>
          </div>
        )}

        {isAddTrainerOpen ? (
          <AddTrainingAddTrainer
            closeThePopup={(e) => this.openAddTrainerPopup(e)}
            array={this.state.Trainers}
            state={(state) => {
              this.setState({ Trainers: state });
              this.setState({
                selectedTrainer: state.filter((e) => e.check === true),
              });
            }}
          />
        ) : null}

        {this.state.opens ? (
          <AddTrainingAddDesignation
            closeThePopup={(e) => this.closeThePopups(e)}
            array={this.state.Designation}
            state={(state) => {
              this.setState({ Designation: state });
              console.log(
                "selected",
                state.filter((e) => e.check === true)
              );

              this.setState({
                selectedDesignation: state.filter((e) => e.check === true),
              });
            }}
          />
        ) : null}
      </div>
    );
  }
}
