import { Details } from "@material-ui/icons";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
export default class TrainerAssignTraining extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props.match.params.id);
    this.state = {
      Details: [],
      Trainer: [],
      FileCount: [],
      TrainerCount: [],
    };
  }
  Edit1(training_Id) {
    // console.log(training_Id);
    // console.log("fileCount", this.state.FileCount);
    // console.log("TrainerCount", this.state.TrainerCount);
    var page = {
      pathname: "/EditTrainingpop1",
      state: {
        training_Id: training_Id,
        FileCount: this.state.FileCount,
        TrainerCount: this.state.TrainerCount,
      },
    };
    this.props.history.push(page);
    this.Getloggeddet = this.Getloggeddet.bind(this);
  }

  componentDidMount() {
    window.addEventListener("load", this.Getloggeddet);
    this.Getloggeddet();
  }

  Getloggeddet = async (event) => {
    // event.preventDefault();
    // console.log("beforevalid");

    let Email = JSON.parse(localStorage.getItem("email"));

    let url = `${BASE_URLs}/Training/Getloggeddet?Email=` + Email;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    // console.log(accessToken);
    await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("My Journey Data", data);
        localStorage.setItem(
          "deptofloggeduser",
          JSON.stringify(data[0].department)
        );
        localStorage.setItem(
          "roleofloggeduser",
          JSON.stringify(data[0].trainer_Info)
        );
        let roleofloggeduser = JSON.parse(
          localStorage.getItem("roleofloggeduser")
        );
        if (roleofloggeduser == 0) {
          localStorage.setItem("roleofloggeduser", "User");
        } else {
          localStorage.setItem("roleofloggeduser", "Trainer");
        }
      });

    let Department = JSON.parse(localStorage.getItem("deptofloggeduser"));
    let roleofloggeduser = localStorage.getItem("roleofloggeduser");

    if (Department != null && roleofloggeduser == "User") {
      // console.log("USER");
      let url =
        `${BASE_URLs}/Training/Getloggedassigndet?Department=` + Department;

      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      // console.log(accessToken);
      await fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
            FileCount: data[0].filecount,
            TrainerCount: data[0].trainercount,
          });

          // console.log("My Traingn Data", data);
          //  localStorage.setItem('deptofloggeduser', JSON.stringify(data[0].department));
          //localStorage.setItem('roleofloggeduser', JSON.stringify(data[0].trainer_Info));
        });
    } else {
      let url =
        `${BASE_URLs}/Training/GetloggedTrainer?Department=` + Department;
      // console.log("TRAINER");
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      // console.log(accessToken);
      await fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Trainer: data,
          });

          // console.log("My Traingn Data", data);
          //  localStorage.setItem('deptofloggeduser', JSON.stringify(data[0].department));
          //localStorage.setItem('roleofloggeduser', JSON.stringify(data[0].trainer_Info));
        });
    }
  };

  render() {
    const { Details, Trainer } = this.state;
    var Role = localStorage.roleofloggeduser;
    return (
      <div>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              {" "}
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            {" "}
                            Assigned Training
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form autoComplete="off">
                            <br />
                            <br />
                            <div className="container">
                              <div className="col-lg-12">
                                <div className="dt-responsive table-responsive">
                                  {(() => {
                                    if (Role == "User") {
                                      return (
                                        <div>
                                          <table
                                            id="add-row-table3"
                                            className="table nowrap"
                                          >
                                            <thead>
                                              <tr>
                                                <th>Department </th>
                                                <th>Training Topic </th>
                                                <th>Description </th>
                                                <th>Docs </th>
                                                <th>Trainers </th>
                                                <th>Action </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Details.map((detail) => (
                                                <tr>
                                                  <td>{detail.department}</td>
                                                  <td>{detail.trainingitem}</td>
                                                  <td>{detail.description}</td>
                                                  <td> {detail.filecount}</td>
                                                  <td>
                                                    {" "}
                                                    {detail.trainercount}
                                                  </td>
                                                  {/* <td>{detail.ref_Doc} </td>  */}
                                                  {/* <td>{detail.trainingitem}</td> */}
                                                  <td>
                                                    <>
                                                      &nbsp;&nbsp;&nbsp;
                                                      {/* <Link to="/TrainerAssign">  */}
                                                      <button
                                                        className="button-30 sk-edit"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.Edit1(
                                                            detail.training_Id
                                                          );
                                                        }}
                                                        // src="https://img.icons8.com/android/22/000000/pencil.png"
                                                      >
                                                        <i className="icofont icofont-info"></i>
                                                      </button>
                                                      {/* <Link to={"/EditTra/"+item.group_Id} title="Edit" 
                        className="btn btn-secondary" style={{color:"black"}} >{edit.result}</Link>&nbsp; */}
                                                      {/* </Link> */}
                                                      &nbsp;&nbsp;
                                                    </>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div>
                                          <table
                                            id="add-row-table3"
                                            className="table nowrap"
                                          >
                                            <thead>
                                              <tr>
                                                <th>Department </th>
                                                <th>Training Topic </th>
                                                {/* <th>Description </th>
                                                        <th>Docs </th> */}
                                                {/* <th>Trainers </th> */}
                                                <th>Action </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Trainer.map((Train) => (
                                                <tr>
                                                  <td>{Train.department}</td>
                                                  <td>{Train.trainingItem}</td>
                                                  {/* <td>{Train.description}</td>
                                  <td>{Train.ref_Doc} </td>  */}
                                                  {/* <td>{detail.trainingitem}</td> */}
                                                  <td>
                                                    <>
                                                      &nbsp;&nbsp;&nbsp;
                                                      {/* <Link to="/TrainerAssign">  */}
                                                      <button
                                                        className="button-30 sk-edit"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.Edit1(Train.id);
                                                        }}
                                                        // src="https://img.icons8.com/android/22/000000/pencil.png"
                                                      >
                                                        <i className="icofont icofont-info"></i>
                                                      </button>
                                                      {/* <Link to={"/EditTra/"+item.group_Id} title="Edit" 
                        className="btn btn-secondary" style={{color:"black"}} >{edit.result}</Link>&nbsp; */}
                                                      {/* </Link> */}
                                                      &nbsp;&nbsp;
                                                    </>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                                <br />
                              </div>
                              <br />
                              <br />
                              <br />
                              <br />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
