import React, { Component } from "react";
import SideBar from "../Common/Sidebar";
import "../Css/Addskills.css";
import Multiselect from "multiselect-react-dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import TrainingClassPopup from "../Common/TrainingClassPopup";
import { BASE_URLs } from "../Config/Base";
export default class AddLearning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Departmentdrop: [],
      seperateDept: [],
      selectpanadhu: [],
      trainer: [],
      trainerDrop: [],
      trainerlist: [],
      trainerlist1: [],
      traineelist: [],
      memberDrop: [],
      selectedDepartment: "",
      selectedStrtdate: [],
      department: [],
      selectedItem: [],
      selectedOptions: [],
      selectedOtherOption: [],
      selectedTrainee: [],
      Training: [],
      description: [],
      selectedNames: [],
      filteredArray: [],
      selectedOption: [],
      isPopupVisible: false,
      Train: [],
      TopicDropDownFiltered: [],
      desigFilter: "0",
      deptFilter: "0",
      topicFilter: "0",
      Training_Props: [],
      Designationdrop: [],
    };
    this.handleChangesss = this.handleChangesss.bind(this);
    this.handleChangesssone = this.handleChangesssone.bind(this);
  }

  removeDuplicatesByField(data, fieldName) {
    const uniqueData = [];
    const uniqueValues = new Set();

    data.forEach((item) => {
      if (!uniqueValues.has(item[fieldName])) {
        uniqueValues.add(item[fieldName]);
        uniqueData.push(item);
      }
    });

    return uniqueData;
  }

  GetDesignation() {
    let url = `${BASE_URLs}/Designation/GetMasterDesignation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Designationdrop: data,
          //Desi:data,
        });
      });
  }

  GetDepartment() {
    // // console.log("hjdsfj",this.state.selectedOtherOption);
    //  // console.log("nkjfchskd",this.state.selectedOption);
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });
        this.setState({
          department: filteredData,
        });
      });
  }

  GetDepartments() {
    let url = `${BASE_URLs}/Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }

  GetTrainingData() {
    let url = `${BASE_URLs}/Training/GetAdminTraining`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueData = this.removeDuplicatesByField(data, "description");

        this.setState({
          Train: uniqueData,
          TopicDropDownFiltered: uniqueData,
        });
        // console.log("NewResult", uniqueData);
      });
  }

  GetTrainers = async (department) => {
    // // console.log("dfnkdfnkjdsnfjkndfjksnd");
    // let url = `${BASE_URLs}/Trainer/GetManagerBasedTrainer?Department=${this.state.selectedDepartment}&TrainerInfo=${1}&StartDate=${startDate}&EndDate=${dateFormat}`;
    let url = `${BASE_URLs}/Trainer/GetdeptBasedTrainer?Department=${department}&TrainerInfo=${1}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    const reqData = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    });
    const resData = await reqData.json();
    const obj = {};
    // console.log("---------", resData);
    this.getValueone(resData);
    // for (let i = 0; i < resData.length; i++) {
    //   //    this.state.trainerDrop.push(resData[i].label);
    //   //     // obj['value'] = JSON.stringify(resData[i].user_Id);
    //   //     // obj['label'] = JSON.stringify(resData[i].label);
    //   //     // this.state.trainerDrop.push(obj);
    //   this.state.trainerDrop.push({
    //     Id: resData[i].user_Id,
    //     label: resData[i].label,
    //   });
    // }

    this.setState({ trainerlist: resData });
    this.setState({ trainerlist1: resData });
    // // console.log("resData", resData);
    //// console.log("this.state.trainerlist", this.state.trainerlist);
    this.GetMembers(this.state.department);
  };

  GetMembers = async (department) => {
    let url = `${BASE_URLs}/Trainer/GetManagerBasedMember?Department=${
      this.state.selectedDepartment
    }&TrainerInfo=${0}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    const reqData = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    });
    const resData = await reqData.json();
    for (let i = 0; i < resData.length; i++) {
      this.state.memberDrop.push(resData[i].label);
    }
    this.setState({ traineelist: resData });
    //   // console.log("resData", resData);
    // // console.log("this.state.traineelist", this.state.traineelist);
  };
  handleChange = (event) => {
    const department = event.target.value;
    this.setState({ selectedDepartment: department });
    this.setState({ selectpanadhu: [] });
    this.setState({ selectValue: event.target.value });
    this.GetTraining(department);
    this.GetTrainers(department);
  };

  //  Sundar Written start
  handleddChange = (event) => {
    const { Train, deptFilter, desigFilter } = this.state;

    const Topic = event.target.value;

    // console.log("ddddddd", Topic);

    this.setState({ topicFilter: Topic });
    if (Topic === "select") {
      return this.setState({ TopicDropDownFiltered: Train });
    }
    const valueFilter = Train.filter((item) => {
      return (
        (Topic === "0" || item.trainingitem === Topic) &&
        (deptFilter === "0" || item.department === deptFilter) &&
        (desigFilter === "0" || item.designation === desigFilter)
      );
    });

    this.setState({ TopicDropDownFiltered: valueFilter });
  };

  handleddChange1 = (event) => {
    const { Train, desigFilter, topicFilter } = this.state;

    const Dept = event.target.value;

    this.setState({ deptFilter: Dept });
    if (Dept === "select1") {
      return this.setState({ TopicDropDownFiltered: Train });
    }
    const valueFilter = Train.filter((item) => {
      return (
        (Dept === "0" || item.department === Dept) &&
        (topicFilter === "0" || item.trainingitem === topicFilter) &&
        (desigFilter === "0" || item.designation === desigFilter)
      );
    });

    this.setState({ TopicDropDownFiltered: valueFilter });
  };

  handleddChange2 = (event) => {
    const { Train, deptFilter, topicFilter } = this.state;

    const Desig = event.target.value;

    this.setState({ desigFilter: Desig });
    if (Desig === "select2") {
      return this.setState({ TopicDropDownFiltered: Train });
    }
    const valueFilter = Train.filter((item) => {
      return (
        (Desig === "0" || item.designation === Desig) &&
        (deptFilter === "0" || item.department === deptFilter) &&
        (topicFilter === "0" || item.trainingitem === topicFilter)
      );
    });

    this.setState({ TopicDropDownFiltered: valueFilter });
  };

  //  Sundar Written End

  handleChangeItem = (event) => {
    const TrainingItem = event.target.value;
    this.setState({ selectedItem: TrainingItem });

    this.setState({ selectValue: event.target.value });
    this.Getdescrp(TrainingItem);
  };
  handleStartDateChange = (event) => {
    const startDate = document.getElementById("StartDate").value;
    //  const StartDate1 = event.target.value;
    this.setState({ selectedStrtdate: startDate });

    //this.setState({ selectValue: event.target.value });

    //  // console.log("StartDate1",StartDate1);
    //// console.log("StartDate", startDate);
  };

  handleDateChange = (event) => {
    this.setState({ selectValue: event.target.value });
  };
  componentDidMount() {
    this.GetDepartment();
    this.GetDepartments();
    this.GetDesignation();
    this.GetTrainingData();
    //  this.GetTrainers();
  }
  GetTraining(department) {
    //  // console.log("department", department);
    let url =
      `${BASE_URLs}/Training/GetAdminTrainingTopic?Department=` + department;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Training: data,
          Train: data,
        });
        // console.log("traiinignnitem", data);
      });
  }
  Getdescrp(TrainingItem) {
    const Department = document.getElementById("Department").value;
    //  // console.log("department", Department);
    //// console.log("trainingItem", TrainingItem);
    let url =
      `${BASE_URLs}/Training/GetAdminTrainingdescrp?Department=` +
      Department +
      "&TrainingItem=" +
      TrainingItem;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.getValues(data);
        this.getValuesOptions(data);
        this.setState({
          description: data,
        });
        // // console.log("descrrrr", data  );
      });
  }

  myDate = () => {
    // if(this.state.Training == null)
    // {
    // // console.log("GetItem or not", this.state.Training);
    // }
    const startDate = document.getElementById("StartDate").value;
    const duration = document.getElementById("duration").value;
    const dept = this.state.department;

    if (this.state.selectValue === "1 Week") {
      const date = new Date(startDate);
      // // console.log(duration);

      const newDate = this.addDays1(date, duration);
      // // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // // console.log(dateFormat);

      document.getElementById("EndDate").value = dateFormat;
      if (startDate != "" && dateFormat != "") {
        //   // console.log("Working");
        this.GetTrainers();
      }

      //  // console.log("EndDate", dateFormat);
    } else if (this.state.selectValue === "2 Week") {
      const date = new Date(startDate);
      ////// console.log(duration);

      const newDate = this.addDays2(date, duration);
      // // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // // console.log(dateFormat);

      document.getElementById("EndDate").value = dateFormat;
      //  this.GetTrainers(department,startDate,dateFormat);
    } else if (this.state.selectValue === "3 Week") {
      const date = new Date(startDate);
      //  // console.log(duration);

      const newDate = this.addDays3(date, duration);
      // // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      //  // console.log(dateFormat);

      document.getElementById("EndDate").value = dateFormat;
      // this.GetTrainers(department,startDate,dateFormat);
    } else if (this.state.selectValue === "4 Week") {
      const date = new Date(startDate);
      //  // console.log(duration);

      const newDate = this.addDays4(date, duration);
      //  // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // // console.log(dateFormat);
      document.getElementById("EndDate").value = dateFormat;
      //this.GetTrainers(department,startDate,dateFormat);
    }
  };

  addDays1 = (date) => {
    date.setDate(date.getDate() + 7);
    return date;
  };

  addDays2 = (date) => {
    date.setDate(date.getDate() + 14);
    return date;
  };
  addDays3 = (date) => {
    date.setDate(date.getDate() + 21);
    return date;
  };
  addDays4 = (date) => {
    date.setDate(date.getDate() + 28);
    return date;
  };

  check = () => {};

  handleSubmit = (event) => {
    event.preventDefault();
    return true;
    const {
      selectedOption,
      selectedOtherOption,
      filteredArray,
      selectedNames,
    } = this.state;
    const { selectedTrainee } = this.state;

    const data = new FormData(event.target);

    selectedOption.forEach((option) => {
      data.append("TrainerId", option.user_Id);
      data.append("TrainerName", option.label);
    });
    data.append("Department", this.state.selectedDepartment);

    data.append("Trainingitem", this.state.selectedItem);

    selectedTrainee.forEach((option) => {
      data.append("TraineesId", option.user_Id);
      data.append("Trainees", option.label);
    });
    if (this.state.selectedDepartment != "" && this.state.selectedItem != "") {
      alert("fetch");
      fetch(
        `${BASE_URLs}/Learning/SP_AlreadyExistornot?Department=` +
          this.state.selectedDepartment +
          "&Trainingitem=" +
          this.state.selectedItem,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
          body: "data",
        }
      )
        .then((res) => res.json())
        .then((resp) => {
          this.setState({ status: resp.Status });

          if (resp.status == 400) {
            toast.error("Training Topic already Exists", {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            const { selectedOption, selectedOtherOption, filteredArray } =
              this.state;
            const { selectedTrainee } = this.state;

            const data = new FormData(event.target);
            // // console.log("post", data);
            selectedOption.forEach((option) => {
              data.append("TrainerId", option.user_Id);
              data.append("TrainerName", option.label);
              //  // console.log("trainerrrr------", this.state.selectedOption);
            });
            data.append("Department", this.state.selectedDepartment);
            //  // console.log("post", this.state.selectedDepartment);
            data.append("Trainingitem", this.state.selectedItem);
            //data.append('SchedulingDuration', this.state.description[0].schedulingDuration);
            selectedTrainee.forEach((option) => {
              data.append("TraineesId", option.user_Id);
              data.append("Trainees", option.label);
            });
            fetch(`${BASE_URLs}/Learning/AdminAddLearning`, {
              method: "POST",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PUT, DELETE, OPTIONS",
                Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
              },
              body: data,
            })
              .then((res) => res.json())
              .then((resp) => {
                this.setState({ status: resp.Status });
                this.setState({ message: resp.Message });
                // this.SuccessModel();
                //   // console.log("inputvalues", resp.status);
                if (resp.status == "Success") {
                  this.props.history.push("/LearningSchedule");
                }
              });
          }
        });
    }
  };

  // handleSubmit = (event) => {

  //   // console.log("Add Learning Submit");
  //   event.preventDefault();
  //   const { selectedOption, selectedOtherOption, filteredArray, selectedNames } =
  //     this.state;
  //   const { selectedTrainee } = this.state;

  //   const data = new FormData(event.target);

  //   selectedOption.forEach((option) => {
  //     data.append("TrainerId", option.user_Id);
  //     data.append("TrainerName", option.label);
  //   });
  //   data.append("Department", this.state.selectedDepartment);

  //   data.append("Trainingitem", this.state.selectedItem);

  //   selectedTrainee.forEach((option) => {
  //     data.append("TraineesId", option.user_Id);
  //     data.append("Trainees", option.label);
  //   });
  //   if (this.state.selectedDepartment != "" && this.state.selectedItem != "") {
  //     const { selectedOption, selectedOtherOption, filteredArray } = this.state;
  //     const { selectedTrainee } = this.state;

  //     const data = new FormData(event.target);
  //     // // console.log("post", data);
  //     selectedOption.forEach((option) => {
  //       data.append("TrainerId", option.user_Id);
  //       data.append("TrainerName", option.label);
  //       //  // console.log("trainerrrr------", this.state.selectedOption);
  //     });
  //     data.append("Department", this.state.selectedDepartment);
  //     //  // console.log("post", this.state.selectedDepartment);
  //     data.append("Trainingitem", this.state.selectedItem);
  //     data.append('SchedulingDuration', "2");
  //     selectedTrainee.forEach((option) => {
  //       data.append("TraineesId", option.user_Id);
  //       data.append("Trainees", option.label);
  //     });
  //     fetch(`${BASE_URLs}/Learning/AdminAddLearning", {
  //       method: "POST",
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //         Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
  //       },
  //       body: data,
  //     })
  //       .then((res) => res.json())
  //       .then((resp) => {
  //         this.setState({ status: resp.Status });
  //         this.setState({ message: resp.Message });
  //         // this.SuccessModel();
  //         //   // console.log("inputvalues", resp.status);
  //         if (resp.status == "Success") {
  //           this.props.history.push("/LearningSchedule");
  //         }
  //       });
  //   }
  // };

  onRemove = () => {
    var action_list = { ...this.state.selectedOptions };
    // // console.log("selected one", ...this.state.selectedOptions);

    // Remember selected items.
    // var is_selected = [];
    // for (var i = 0; i < action_list.options.length; ++i)
    // {
    //     is_selected[i] = action_list.options[i].selected;
    // }

    // // Remove selected items.
    // i = action_list.options.length;
    // while (i--)
    // {
    //     if (is_selected[i])
    //     {
    //         action_list.remove(i);
    //     }
    // }
  };
  getValueone(apiValues) {
    if (apiValues != 0) {
      //   alert("called");
      //  // console.log("apiValues",apiValues)
      let previousValue = [];
      let names = apiValues[0].label.split(",");
      // // console.log("jkfbskjvbdskjf",names);
      let ids = apiValues[0].user_Id.split(",");
      let index = 0;

      if (names.length === ids.length) {
        names.forEach((item) => {
          let data = { value: ids[index].toString(), label: item };
          index++;
          previousValue.push(data);
        });
      }
      // this.setState({ selectpanadhu: previousValue });
      //   // console.log("GETVALUESONE SETSTATE",this.state.selectpanadhu)
    }
  }
  getValues(apiValues) {
    if (apiValues != 0) {
      //alert("called");
      //// console.log("apiValues",apiValues)
      let previousValue = [];
      let names = apiValues[0].assignedTrainer.split(",");
      // // console.log("jkfbskjvbdskjf",names);
      let ids = apiValues[0].assignedTrainerId.split(",");
      let index = 0;

      if (names.length === ids.length) {
        names.forEach((item) => {
          let data = { value: ids[index].toString(), label: item };
          index++;
          previousValue.push(data);
        });
      }
      this.setState({ selectedOption: previousValue });
      //  // console.log("selebndkjbk",this.state.selectedOption)
    }
  }

  getValuesOptions(apiValues) {
    if (apiValues != 0) {
      // // console.log("apiValues",apiValues)
      let previousValue = [];
      let names = apiValues[0].trainerName.split(",");
      // // console.log("jkfbskjvbdskjf",names);
      let ids = apiValues[0].trainerId.split(",");
      let index = 0;

      if (names.length === ids.length) {
        names.forEach((item) => {
          let data = { value: ids[index].toString(), label: item };
          index++;
          previousValue.push(data);
        });
      }
      this.setState({ selectedOtherOption: previousValue });
      //// console.log("selebndkjbk",this.state.selectedOtherOption)
    }
  }
  handleMultiselectChangetwo = (event) => {
    this.setState({ ...(this.state.selectpanadhu = event) });
    // // console.log("selectOptions", ...this.state.selectpanadhu);
  };
  handleMultiselectChange = (event) => {
    this.setState({ ...(this.state.selectedOptions = event) });
    //  // console.log("selectOptions", ...this.state.selectedOptions);
  };
  handleMultiselectTrainee = (event) => {
    this.setState({ ...(this.state.selectedTrainee = event) });
    // // console.log("selectedTrainee", ...this.state.selectedTrainee);
  };
  //   handleMultiselectChange = (event) => {
  //     alert("Handlemulti")
  //     this.setState({ ...this.state.selectedOption = event });
  //     // console.log("selectOptionin handle multisleect", ...this.state.selectedOption);
  // };
  handleMultiselectChangeone = (event) => {
    //alert("Handlemultione")
    //  this.setState({ ...this.state.selectedOptions = event });
    //// console.log("selectOptionin handle multisleect", ...this.state.selectedOptions);

    this.setState({ ...(this.state.selectpanadhu = event) });
    // // console.log("HANDLEMULTISELECTCHANGEONE SET STATE", ...this.state.selectpanadhu);

    let selectedNames = this.state.selectpanadhu.map((e) => e.label);
    // // console.log("SELECTED NAMES MAP THE SELECT PANADHU",this.state.selectedNames);

    // let final=total.filter(e=>selected.indexOf(e))

    var filteredArray1 = this.state.trainerlist.filter(
      (value) => !selectedNames.includes(value.label)
    );
    this.setState({ ...(this.state.filteredArrayone = filteredArray1) });
    // // console.log("FILTERED ONE",this.state.filteredArrayone)
    // // console.log("this.state.selectedNames=====",filteredArray1)
  };
  handleMultiselectChange = (event) => {
    // alert("Handlemulti")
    this.setState({ ...(this.state.selectedOption = event) });
    // // console.log("selectOptionin handle multisleect", ...this.state.selectedOption);
  };

  handleChangesssone(value, actionDetails) {
    //  alert("handlechangess");
    let items = [...this.state.selectpanadhu];
    // // console.log("items",...this.state.selectpanadhu);

    if (actionDetails.action === "remove-value") {
      if (items) {
        var index = -1;
        items.forEach((item, i) => {
          if (
            item.label === actionDetails.removedValue.label &&
            item.value === actionDetails.removedValue.value
          ) {
            index = i;
            return;
          }
        });
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    } else {
      items.push(actionDetails.option);
    }

    this.setState({ selectpanadhu: items });
    //  // console.log("itemshandlechnagesssss",...this.state.selectpanadhu);
  }

  handleChangesss(value, actionDetails) {
    alert("handlechangess");
    let items = [...this.state.selectedOption];
    // // console.log("items",...this.state.selectedOption);

    if (actionDetails.action === "remove-value") {
      if (items) {
        var index = -1;
        items.forEach((item, i) => {
          if (
            item.label === actionDetails.removedValue.label &&
            item.value === actionDetails.removedValue.value
          ) {
            index = i;
            return;
          }
        });
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    } else {
      items.push(actionDetails.option);
    }

    this.setState({ selectedOption: items });
    // // console.log("itemshandlechnagesssss",...this.state.selectedOption);
  }
  GetTrainernames = () => {
    //// console.log("selectevd option3333333333333333333333333",this.state.selectedOptions);
    if (this.state.trainerlhandleChangeist != 0) {
      let selectedNames = this.state.selectedOptions.map((e) => e.label);
      // console.log("selectevd option", this.state.selectedOptions);

      // let final=total.filter(e=>selected.indexOf(e))

      var filteredArray1 = this.state.trainerlist.filter(
        (value) => !selectedNames.includes(value.label)
      );
      // // console.log("this.state.trainerlist=====",this.state.trainerlist)
      //  // console.log("this.state.selectedNames=====",this.state.selectedNames)
    }
  };
  handleChangenew = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  togglePopup = (Training) => {
    this.setState({ Training_Props: Training });

    this.setState((prevState) => ({
      isPopupVisible: !prevState.isPopupVisible,
    }));
  };
  togglePopupclose = (Training) => {
    if (this.state.isPopupVisible === true) {
      this.props.history.push("/LearningSchedule");
    }

    this.setState({ Training_Props: Training });

    this.setState((prevState) => ({
      isPopupVisible: !prevState.isPopupVisible,
    }));
  };
  render() {
    const {
      plainArray,
      Departmentdrop,
      trainerDrop,
      memberDrop,
      trainer,
      trainerlist,
      traineelist,
      selectedOptions,
      Training,
      description,
      Train,
      TopicDropDownFiltered,
      Designationdrop,
    } = this.state;
    // let optionsfrnd = trainerDrop.map(function (item) {
    //     return { value: (item.user_Id), label:  (item.label) };
    //   })
    // let selectedValue = [];
    // //  let selectedLabel = [];
    //   this.state.selectedOption && this.state.selectedOption.forEach((item) => {
    //     selectedValue.push(item.value);
    //     selectedLabel.push(item.label);
    //   });
    let formatOptionLabel = ({ value, label, image }) => (
      <div className="grp-flex" style={{ display: "flex" }}>
        <img className="grp-edit-img2" src={image} />
        <span>{label}</span>
      </div>
    );
    if (this.state.trainerlist != 0) {
      let selectedNames1 = this.state.selectpanadhu.map((e) => e.label);
      //   // console.log("selectedNames1",selectedNames1);

      // let final=total.filter(e=>selected.indexOf(e))

      var filteredArray1 = this.state.trainerlist.filter(
        (value) => !selectedNames1.includes(value.label)
      );
      //   // console.log("filteredArray1asdasdasdasdsadas",filteredArray1);
    }
    if (this.state.selectedOtherOption != 0) {
      let selectedNames = this.state.selectedOption.map((e) => e.label);
      // // console.log("selectevd option",this.state.selectedOption);
      var filteredArray = this.state.selectedOtherOption.filter(
        (value) => !selectedNames.includes(value.label)
      );
      // // console.log("filteredArray",filteredArray)
    }

    //// console.log("selectevd option",this.state.selectedOption);
    //// console.log("other option",this.state.selectedOtherOption)
    const colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles) => ({
        ...styles,
        textAlign: "center",
        display: "flex",
        backgroundColor: "white",
        "text-align-last": "center",
        color: "black",
      }),

      // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //  // const color = chroma(data.color);
      //   return {
      //     ...styles,
      //     backgroundColor: isDisabled ? 'white' : "red",
      //   //  color: 'white',
      //     textAlign:"left",
      //     cursor: isDisabled ? 'not-allowed' : 'default',

      //   };
      // },
    };

    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            Add
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            {/* Sundar Written Start */}
                            <div className="row" style={{ marginTop: "3%" }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="container">
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-4">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            name="Trainingitem"
                                            id="Trainingitem"
                                            value={this.state.topicFilter}
                                            onChange={this.handleddChange}
                                            // onChange={this.handleChangeItem}
                                          >
                                            <option value="select">
                                              Select
                                            </option>
                                            {Train.map((Training) => (
                                              <option
                                                value={Training.trainingitem}
                                              >
                                                {Training.trainingitem}
                                              </option>
                                            ))}
                                          </select>
                                          {/* <input className="effect-16" name="Trainingitem" id="Trainingitem" type="text" maxLength="50" /> */}
                                          <span style={{ color: "#a29d9d" }}>
                                            Training Topic
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>

                                        <div className="col-sm-4">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            value={this.state.deptFilter}
                                            name="Department"
                                            id="Department"
                                            onChange={this.handleddChange1}
                                          >
                                            <option value="select1">
                                              Select
                                            </option>
                                            {Departmentdrop.map(
                                              (Departmentdrop) => (
                                                <option
                                                  value={
                                                    Departmentdrop.department
                                                  }
                                                >
                                                  {Departmentdrop.department}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <span style={{ color: "#a29d9d" }}>
                                            Department
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>

                                        <div className="col-sm-4">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            name="Trainingitem"
                                            id="Trainingitem"
                                            value={this.state.desigFilter}
                                            onChange={this.handleddChange2}
                                          >
                                            <option value="select2">
                                              Select
                                            </option>
                                            {Designationdrop.map((Training) => (
                                              <option
                                                value={Training.designation}
                                              >
                                                {Training.designation}
                                              </option>
                                            ))}
                                          </select>
                                          {/* <input className="effect-16" name="Trainingitem" id="Trainingitem" type="text" maxLength="50" /> */}
                                          <span style={{ color: "#a29d9d" }}>
                                            Designation
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>

                                        <div className="dt-responsive table-responsive table-res">
                                          {this.state.Train.length ===
                                          0 ? null : (
                                            <table
                                              id="DepSheet"
                                              className="table nowrap"
                                              style={{ maxWidth: "100%" }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th>Department</th>
                                                  <th>Training Topic</th>
                                                  <th>Description</th>
                                                  <th>Duration</th>
                                                  <th>Documents</th>
                                                  <th>Trainers</th>
                                                  <th>Designation</th>
                                                  <th
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  ></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {TopicDropDownFiltered.map(
                                                  (Training) => (
                                                    <tr>
                                                      <td>
                                                        {Training.department}
                                                      </td>
                                                      <td>
                                                        {Training.trainingitem}
                                                      </td>
                                                      <td>
                                                        {Training.description}
                                                      </td>
                                                      <td>
                                                        {Training.duration}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {Training.filecount}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {Training.trainercount}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {
                                                          Training.designationcount
                                                        }
                                                      </td>
                                                      <button
                                                        className="button-30 sk-edit"
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                        onClick={() =>
                                                          this.togglePopup(
                                                            Training
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className="icofont icofont-plus"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </button>
                                                      {this.state
                                                        .isPopupVisible && (
                                                        <TrainingClassPopup
                                                          TrainingState={
                                                            this.state
                                                              .Training_Props
                                                          }
                                                          onClose={
                                                            this.togglePopup
                                                          }
                                                          togglePopupclose={
                                                            this
                                                              .togglePopupclose
                                                          }
                                                          trainingID={
                                                            Training.training_Id
                                                          }
                                                        />
                                                      )}
                                                      &nbsp;&nbsp;&nbsp;
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="row">
                                  <div
                                    className="col-sm-12"
                                    style={{ textAlign: "center" }}
                                  >
                                    <button
                                      type="submit"
                                      className="but "
                                      style={{
                                        height: "39.5px",
                                        width: "86.36px",
                                        marginTop: "-0.3%",
                                      }}
                                    >
                                      Save
                                    </button>
                                    &nbsp;&nbsp;&nbsp;
                                    <button
                                      type="reset"
                                      className=" btn-Secondary"
                                      style={{ height: "39.5px" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div> */}
                                <br />
                                <br />
                                <br />
                                <br />
                              </div>
                            </div>
                            {/* Sundar Written End*/}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
