import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class EditSkillModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SkillDetails: [],
      formErrors: {},
      SKillAdminCategoryDrop: [],
      SKillAdminNameDrop: [],
      isButtonDisabled: false,
      isChrome: false,
      UploadCertificate: false
    };
  }
  GetUserSkillDrop() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/UserSkillDetails/GetUserSkillDrop`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SKillAdminCategoryDrop: data });
      });
  }

  GetUserSkillName() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/UserSkillDetails/GetUserSkillName`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SKillAdminNameDrop: data });
        // console.log(data, "SKillName");
      });
  }

  SkillEditsubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    if (this.SkillValidation()) {
      this.setState({ isButtonDisabled: true });
      const url = `${BASE_URLs}/UserSkillDetails/EditUserSkill`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          document.getElementById("close").click();
        }, 4000);
      }

      else if (result.status === "Error") {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          document.getElementById("close").click();
        }, 4000);
      }
    }
  };

  // formatDate = (inputDate) => {
  //   const date = new Date(inputDate);
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();
  //   if (day === "NaN") {
  //     const date = new Date(this.props.value.exp);
  //     const day = String(date.getDate()).padStart(2, "0");
  //     const month = String(date.getMonth() + 1).padStart(2, "0");
  //     const year = date.getFullYear();
  //     return `${day}-${month}-${year}`;
  //   } else {
  //     return `${day}-${month}-${year}`;
  //   }
  // };
  SkillValidation() {

    let formErrors = {};
    let formIsValid = true;

    // var Category = document.getElementById("Category").value;
    var SkillName = document.getElementById("skillname").value;
    // var SkillDescription = document.getElementById("SkillDescription").value;
    var Experience = document.getElementById("Experience").value;
    //var Relationship = document.getElementById("Relationship").value

    var uploadcertificate =
    document.getElementById("UploadCertificate").checked;

    // if (!Category) {
    //   formIsValid = false;
    //   formErrors["Category"] = "Category  is required";
    // }
    if (!SkillName) {
      formIsValid = false;
      formErrors["SkillName"] = "Skill Name  is required";
    }
    // if (!SkillDescription) {
    //   formIsValid = false;
    //   formErrors["SkillDescription"] = "Skill Description  is required";
    // }

    if (!Experience) {
      formIsValid = false;
      formErrors["Experience"] = "Experience is required";
    }
    // else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)))   {
    //   formIsValid = false;
    //   formErrors["Email"] = "Enter valid Email!.";
    // }
    //  if ( !Relationship) {
    //   formIsValid = false;
    //   formErrors["Relationship"] = "Relationship is required" ;
    // }
    if (this.props.value.coursename !== "-") {
      var skillcoursename = document.getElementById("skillcourseName").value;
      var skillCoursestartdate = document.getElementById(
        "skillcoursestartdate"
      ).value;
      var skillCourseEnddate =
        document.getElementById("skillCourseenddate").value;
      if (!skillcoursename) {
        formIsValid = false;
        formErrors["skillcoursename"] = "Course Name is required";
      }else if (skillcoursename.length < 3) {
        formIsValid = false;
        formErrors["skillcoursename"] = "Minimum 3 Characters Required";
      }

      if (!skillCoursestartdate) {
        formIsValid = false;
        formErrors["skillCoursestartdate"] = "Start Date is required";
      }

      if (!skillCourseEnddate) {
        formIsValid = false;
        formErrors["skillCourseEnddate"] = "End Date is required";
      }
    }

    if (uploadcertificate && this.props.value.coursename === "-") {
      var skillcoursename = document.getElementById("skillcoursename").value;
      var skillCoursestartdate = document.getElementById(
        "skillCoursestartdate"
      ).value;
      var skillCourseEnddate =
        document.getElementById("skillCourseEnddate").value;
      var SkillCertificate = document.getElementById("SkillCertificate").value;
      //Email
      if (!skillcoursename) {
        formIsValid = false;
        formErrors["skillcoursename"] = " Course Name is required";
      } else if (skillcoursename.length < 3) {
        formIsValid = false;
        formErrors["skillcoursename"] = "Minimum 3 Characters Required";
      }

      if (!skillCoursestartdate) {
        formIsValid = false;
        formErrors["skillCoursestartdate"] = "Start From is required";
      }
      if (!skillCourseEnddate) {
        formIsValid = false;
        formErrors["skillCourseEnddate"] = "End is required";
      }
      if (!SkillCertificate) {
        formIsValid = false;
        formErrors["SkillCertificate"] = "Certificate is required";
      }
      var St_date = document.getElementById("skillCoursestartdate").value;
      var End_date = document.getElementById("skillCourseEnddate").value;
      var skillCertificate = document.getElementById("SkillCertificate").value;

      if (new Date(St_date).getTime() >= new Date(End_date).getTime()) {
        formIsValid = false;

        formErrors["skillCourseEnddate"] =
          " Start From Should less then End";
      }

      if (!skillCertificate) {
        formIsValid = false;
        formErrors["skillCertificate"] = " Certificate is required";
      }
    }

    if (this.props.value.coursename !== "-") {
      var skillcoursename = document.getElementById("skillcourseName").value;
      var skillCoursestartdate = document.getElementById(
        "skillcoursestartdate"
      ).value;
      var skillCourseEnddate =
        document.getElementById("skillCourseenddate").value;
      var SkillCertificate = document.getElementById("SkillCertificate").value;
      //Email
      if (!skillcoursename) {
        formIsValid = false;
        formErrors["skillcoursename"] = " Course Name is required";
      } else if (skillcoursename.length < 3) {
        formIsValid = false;
        formErrors["skillcoursename"] = "Minimum 3 Characters Required";
      }

      if (!skillCoursestartdate) {
        formIsValid = false;
        formErrors["skillCoursestartdate"] = "Start From is required";
      }
      if (!skillCourseEnddate) {
        formIsValid = false;
        formErrors["skillCourseEnddate"] = "End is required";
      }
      if (!SkillCertificate) {
        formIsValid = false;
        formErrors["SkillCertificate"] = "Certificate is required";
      }
      var St_date = document.getElementById("skillcoursestartdate").value;
      var End_date = document.getElementById("skillCourseenddate").value;
      var skillCertificate = document.getElementById("SkillCertificate").value;

      if (new Date(St_date).getTime() >= new Date(End_date).getTime()) {
        formIsValid = false;

        formErrors["skillCourseEnddate"] =
          " Start From Should less then End";
      }

      if (!skillCertificate) {
        formIsValid = false;
        formErrors["skillCertificate"] = " Certificate is required";
      }
    }

    this.setState({ formErrors: formErrors });

    //document.getElementById("add-row").style.display = "none";
    return formIsValid;
  }

  
  handleSkillChange = (e) => {

    let formErrors = {};
    let formIsValid = true;
    var uploadcertificate =
      document.getElementById("UploadCertificate").checked;

    var skillName = document.getElementById("skillname").value;
    var Experience = document.getElementById("Experience").value;

    if (!skillName) {
      formIsValid = false;
      formErrors["SkillName"] = "Name is required";
    }
    // else if (!SkillDescription) {
    //   formIsValid = false;
    //   formErrors["SkillDescriptionErr"] = "Description is required";
    // }
    // else if (SkillDescription.length < 2) {
    //   formIsValid = false;
    //   formErrors["SkillDescriptionErr"] = "A Minimum 3 Characters is required";
    // }

    // else if (!Category) {
    //   formIsValid = false;
    //   // formErrors["CategoryErr"] = "Category is required";
    // }
    else if (!Experience) {
      formIsValid = false;
      formErrors["Experience"] = "Experience is required";
    }
    if (this.props.value.coursename !== "-") {
      var skillcoursename = document.getElementById("skillcourseName").value;
      var skillCoursestartdate = document.getElementById(
        "skillcoursestartdate"
      ).value;
      var skillCourseEnddate =
        document.getElementById("skillCourseenddate").value;
      if (!skillcoursename) {
        formIsValid = false;
        formErrors["skillcoursename"] = "Course Name is required";
      }else if (skillcoursename.length < 3) {
        formIsValid = false;
        formErrors["skillcoursename"] = "Minimum 3 Characters Required";
      }

     else if (!skillCoursestartdate) {
        formIsValid = false;
        formErrors["skillCoursestartdate"] = "Start Date is required";
      }

     else if (!skillCourseEnddate) {
        formIsValid = false;
        formErrors["skillCourseEnddate"] = "End Date is required";
      }
    }

    if (uploadcertificate && this.props.value.coursename === "-") {
      var skillcoursename = document.getElementById("skillcoursename").value;
      var skillCoursestartdate = document.getElementById(
        "skillCoursestartdate"
      ).value;
      var skillCourseEnddate =
        document.getElementById("skillCourseEnddate").value;
      var SkillCertificate = document.getElementById("SkillCertificate").value;
      //Email
      if (!skillcoursename) {
        formIsValid = false;
        formErrors["skillcoursename"] = " Course Name is required";
      } else if (skillcoursename.length < 3) {
        formIsValid = false;
        formErrors["skillcoursename"] = "Minimum 3 Characters Required";
      }

      if (!skillCoursestartdate) {
        formIsValid = false;
        formErrors["skillCoursestartdate"] = "Start From is required";
      }
      if (!skillCourseEnddate) {
        formIsValid = false;
        formErrors["skillCourseEnddate"] = "End is required";
      }
      if (!SkillCertificate) {
        formIsValid = false;
        formErrors["SkillCertificate"] = "Certificate is required";
      }
      var St_date = document.getElementById("skillCoursestartdate").value;
      var End_date = document.getElementById("skillCourseEnddate").value;
      var skillCertificate = document.getElementById("SkillCertificate").value;

      if (new Date(St_date).getTime() >= new Date(End_date).getTime()) {
        formIsValid = false;

        formErrors["skillCourseEnddate"] =
          " Start From Should less then End";
      }

      if (!skillCertificate) {
        formIsValid = false;
        formErrors["skillCertificate"] = " Certificate is required";
      }
    }

    if (this.props.value.coursename !== "-") {
      var skillcoursename = document.getElementById("skillcourseName").value;
      var skillCoursestartdate = document.getElementById(
        "skillcoursestartdate"
      ).value;
      var skillCourseEnddate =
        document.getElementById("skillCourseenddate").value;
      var SkillCertificate = document.getElementById("SkillCertificate").value;
      //Email
      if (!skillcoursename) {
        formIsValid = false;
        formErrors["skillcoursename"] = " Course Name is required";
      } else if (skillcoursename.length < 3) {
        formIsValid = false;
        formErrors["skillcoursename"] = "Minimum 3 Characters Required";
      }

      if (!skillCoursestartdate) {
        formIsValid = false;
        formErrors["skillCoursestartdate"] = "Start From is required";
      }
      if (!skillCourseEnddate) {
        formIsValid = false;
        formErrors["skillCourseEnddate"] = "End is required";
      }
      if (!SkillCertificate) {
        formIsValid = false;
        formErrors["SkillCertificate"] = "Certificate is required";
      }
      var St_date = document.getElementById("skillcoursestartdate").value;
      var End_date = document.getElementById("skillCourseenddate").value;
      var skillCertificate = document.getElementById("SkillCertificate").value;

      if (new Date(St_date).getTime() >= new Date(End_date).getTime()) {
        formIsValid = false;

        formErrors["skillCourseEnddate"] =
          " Start From Should less then End";
      }

      if (!skillCertificate) {
        formIsValid = false;
        formErrors["skillCertificate"] = " Certificate is required";
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };



  // handleChange() {
  //   let formErrors = {};
  //   let formIsValid = true;

  //   // var Category = document.getElementById("Category").value;
  //    var SkillName = document.getElementById("skillname").value;
  //   // var SkillDescription = document.getElementById("SkillDescription").value;
  //   var Experience = document.getElementById("Experience").value;

  //     if (!SkillName || SkillName==='Skill Name') {
  //       formIsValid = false;
  //       formErrors["SkillName"] = "Skill Name is required";
  //     }

  //     if (!Experience) {
  //       formIsValid = false;
  //       formErrors["Experience"] = "Acquired From is required";
  //     }



  //   this.setState({ formErrors: formErrors });

  //   return formIsValid;
  // }

  HandleSkillCertificate = (e) => {
    e.preventDefault();
    var uploadcertificate =
      document.getElementById("UploadCertificate").checked;
    if (uploadcertificate === true) {
      this.setState({
        UploadCertificate: true,
      });
    } else {
      this.setState({
        UploadCertificate: false,
      });
    }
  };

  componentDidMount() {
    this.GetUserSkillDrop();
    this.GetUserSkillName();
    const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    this.setState({ isChrome });
  }

  render() {
    const { SKillAdminCategoryDrop, SKillAdminNameDrop,UploadCertificate } = this.state;
    const { isChrome } = this.state;

    const {
      Category,
      SkillName,
      SkillDescription,
      Experience,
      skillcoursename,
      skillCoursestartdate,
      skillCourseEnddate,
      skillCertificate
    } = this.state.formErrors;

    let expDate = this.props.value.exp;

    if (!(expDate instanceof Date)) {

      expDate = new Date(expDate);
    }

    // const DateFormat = `${expDate.getDate()}-${expDate.getMonth() + 1}-${expDate.getFullYear()}`;

    const day = expDate.getDate();
    const month = expDate.getMonth() + 1;
    const year = expDate.getFullYear();

    const DateFormat = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;

    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />{" "}
        <div className="pop">
          <div className="popinnernormal">
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>
            <h1 className="popinnertitle">Edit </h1>
            <div id="add-row">
              <form id="skillform" onSubmit={this.SkillEditsubmit}>
                <div
                  className="row  justify-content-center"
                  style={{ width: "100%" }}
                >
                  <input
                    style={{ display: "none" }}
                    type="hidden"
                    name="CreatedDate"
                    value={this.props.value.created_Date}
                  />
                  {/* <div className="col-lg-5">
                    <select
                      className="effect-16"
                      name="Category"
                      id="Category"
                      onChange={this.handleChange}
                      type="text"
                      placeholder="City"
                    >
                      <option hidden >{this.props.value.categ}</option>
                      <option value=""> Category</option>
                      {SKillAdminCategoryDrop.map((SKillAdminCategoryDrop) => (
                        <option
                          selected={
                            SKillAdminCategoryDrop.category ===
                            this.props.value.categ
                          }
                          value={SKillAdminCategoryDrop.category}
                        >
                          {SKillAdminCategoryDrop.category}
                        </option>
                      ))}
                    </select>
                    {Category == null ? (
                      <span style={{ color: "#a29d9d", float: "left" }}>
                        Category
                      </span>
                    ) : (
                      <div style={{ color: "red", float: "left" }}>
                        {Category}
                      </div>
                    )}
                  </div> */}

                  <div className="col-lg-5">
                  <input
                    type="hidden"
                    name="OldSkillName"
                    defaultValue={
                      this.props.value.Skillname
                    }
                  />
                    <select
                      className="effect-16"
                      name="SkillName"
                      onChange={this.handleSkillChange}
                      type="text"
                      placeholder="SkillName"
                      // id="skillname"
                      style={{
                        ...(isChrome && { marginLeft: '-8px' })
                      }}
                      id="skillname"
                    >
                      <option hidden>{this.props.value.Skillname}</option>
                      <option value=""> Skill Name</option>
                      {SKillAdminNameDrop.map((SKillAdminNameDrop) => (
                        <option
                          selected={
                            SKillAdminNameDrop.skillName ===
                            this.props.value.Skillname
                          }
                          value={SKillAdminNameDrop.skillName}
                        >
                          {SKillAdminNameDrop.skillName}
                        </option>
                      ))}
                    </select>
                    {/* <input
                      type="hidden"
                      name="Id"
                      defaultValue={this.props.value.id}
                    />
                    <input
                      className="form"
                      defaultValue={this.props.value.Skillname}
                      id="SkillName"
                      name="SkillName"
                      maxLength="20"
                      type="text"
                      onChange={this.handleChange}
                      placeholder="Skill Name"
                    /> */}
                    {SkillName == null ? (
                      <span style={{ color: "#a29d9d", float: "left" }}>
                        Name
                      </span>
                    ) : (
                      <div style={{ color: "red", float: "left" }}>
                        {SkillName}
                      </div>
                    )}
                  </div>
                  {/* </div>  */}
                  <br />
                  {/* <div className="row  justify-content-center" style={{ width: "100%" }}> */}
                  {/* <div className="col-lg-5">
                    <input
                      className="form"
                      defaultValue={this.props.value.Skilldescrp}
                      id="SkillDescription"
                      name="SkillDescription"
                      type="text"
                      onChange={this.handleChange}
                      placeholder="Skill Description"
                    />
                    {SkillDescription == null ? (
                      <span style={{ color: "#a29d9d", float: "left" }}>
                        Skill Description
                      </span>
                    ) : (
                      <div style={{ color: "red", float: "left" }}>
                        {SkillDescription}
                      </div>
                    )}
                  </div> */}

                  <div className="col-lg-5">
                    {/* <select className="effect-16"  type="text" name="Experience" id="Experience" onChange={this.handleSkillChange} defaultValue={this.props.value.exp}  >
    <option value="SelectValue" >Select</option> 
          <option value="0-1 year">0-1 year</option>
          <option value="1-2 year">1-2 year</option>
          <option value="2-3 year">2-3 year</option>
          <option value="3-4 year">3-4 year</option>
          <option value="4-5 year">4-5 year</option>
          <option value="More">More</option>
          </select> */}
                    <input
                      className="form"
                      max={new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        new Date().getDate() + 1
                      )
                        .toJSON()
                        .slice(0, 10)}
                      name="Experience"
                      id="Experience"
                      defaultValue={DateFormat}

                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => {
                        e.target.type = "text";

                        // e.target.value = (

                        //     e.target.value

                        // );
                      }}
                      onChange={this.handleSkillChange}
                      placeholder={DateFormat}
                    />
                    {/* <input
                      className="form"
                      max={new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        new Date().getDate() + 1
                      )
                        .toJSON()
                        .slice(0, 10)}
                      name="Experience"
                      id="Experience"
                      defaultValue={(this.props.value.exp)}
                      type="text"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      onChange={this.handleChange}
                       placeholder={(this.props.value.exp)}
                    /> */}
                    {Experience == null ? (
                      <span style={{ color: "#a29d9d", float: "left" }}>
                        Acquired From
                      </span>
                    ) : (
                      <div style={{ color: "red", float: "left" }}>
                        {Experience}
                      </div>
                    )}
                  </div>
                </div>{" "}
                <br />
                <div
                  className="row  justify-content-center"
                  style={{ width: "100%" }}
                >
                  <div
                    className="col-0"
                    style={{
                      marginTop: "2%",
                      marginLeft: "15px",
                      color: "#495057",
                    }}
                  >
                    <span style={{ color: "rgb(162, 157, 157)" }}>Trainer</span>
                    &nbsp; &nbsp;
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="Trainer"
                        defaultChecked={
                          this.props.value.trainer === "Yes" ? true : false
                        }
                        name="Trainer"
                      />
                      <span className="slider round" />
                    </label>
                    &nbsp; &nbsp;
                      <span
                        style={{
                          color: "#a29d9d",
                        }}
                      >
                        {" "}
                        Certificate Details
                      </span>{" "}
                      &nbsp; &nbsp;
                      <label className="switch">
                        <input
                          type="checkbox"
                          id="UploadCertificate"
                          defaultChecked={
                            this.props.value.certificates === "Yes" ? true : false
                          }
                          onInputCapture={
                            this.HandleSkillCertificate
                          }
                          name="Certificate"
                        />
                        <span className="slider round" />
                      </label>
                  </div>
                </div>
                {UploadCertificate === true ? (
                  <>

                    <div
                      className="row"
                      style={{ padding: "8px" }}
                    >
                      <div
                        className="col-sm-6"

                      >
                        <input
                          className="form"
                          onFocus={(e) => e.target.select()}
                          name="SkillCoursename"
                          type="text"
                          id="skillcoursename"
                          onChange={this.handleSkillChange}
                        />
                        {!skillcoursename ? (
                          <span
                            style={{
                              color: "#a29d9d",
                              float: "left",
                            }}
                          >
                            Course Name
                            <span style={{ color: "red" }}>
                              *
                            </span>
                          </span>
                        ) : (
                          <div
                            style={{
                              color: "red",
                              float:'left'

                            }}
                          >
                            {skillcoursename}
                          </div>
                        )}
                        <span className="underline" />
                      </div>
                      <div
                        className="col-sm-6"

                      >
                        <input
                          className="form"
                          onFocus={(e) => {e.target.select();
                            (e.target.type = "month")
                          }}
                          max={new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() + 1
                          )
                            .toJSON()
                            .slice(0, 7)}
                          min={new Date(
                            new Date().getFullYear() - 60,
                            new Date().getMonth() + 1
                          )
                            .toJSON()
                            .slice(0, 7)}
                          name="SkillStartdate"
                          id="skillCoursestartdate"
                          type="text"
                          // onFocus={(e) =>
                          //   (e.target.type = "month")
                          // }
                          onChange={this.handleSkillChange}
                        />
                        {!skillCoursestartdate ? (
                          <span
                            style={{
                              color: "#a29d9d",
                              float: "left",
                            }}
                          >
                            Start From
                            <span style={{ color: "red" }}>
                              *
                            </span>
                          </span>
                        ) : (
                          <div
                            style={{
                              color: "red",
                              float:'left'
                            }}
                          >
                            {skillCoursestartdate}
                          </div>
                        )}
                        <span className="underline" />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ padding: "8px" }}
                    >
                      <div
                        className="col-sm-6"
                        style={{

                          color: "#495057",

                        }}
                      >
                        <input
                          className="form"
                          onFocus={(e) => {e.target.select();
                            (e.target.type = "month")
                          }}
                          name="SkillEnddate"
                          id="skillCourseEnddate"
                          type="text"
                          max={new Date(
                            new Date().getFullYear(),
                            new Date().getMonth() + 1
                          )
                            .toJSON()
                            .slice(0, 7)}
                          min={new Date(
                            new Date().getFullYear() - 60,
                            new Date().getMonth() + 1
                          )
                            .toJSON()
                            .slice(0, 7)}
                          // onFocus={(e) =>
                          //   (e.target.type = "month")
                          // }
                          onChange={this.handleSkillChange}
                        />
                        {!skillCourseEnddate ? (
                          <span
                            style={{
                              color: "#a29d9d",
                              float: "left",
                            }}
                          >
                            End
                            <span style={{ color: "red" }}>
                              *
                            </span>
                          </span>
                        ) : (
                          <div
                            style={{
                              color: "red",
                              float:'left'
                            }}
                          >
                            {skillCourseEnddate}
                          </div>
                        )}
                        <span className="underline" />
                      </div>

                      <div className="col-sm-6">
                        <input
                          className="effect-16"
                          name="SkillCertificate"
                          type="file"
                          id="SkillCertificate"
                          onChange={
                            this.handleSkillCertificate
                          }
                        />
                        {!skillCertificate ? (
                          <span
                            style={{
                              color: "#a29d9d",
                              float: "left",
                            }}
                          >
                            Certificate upload
                            <span style={{ color: "red" }}>
                              *
                            </span>
                          </span>
                        ) : (
                          <div
                            style={{
                              color: "red",
                              float:'left'
                            }}
                          >
                            {skillCertificate}
                          </div>
                        )}
                        <span className="underline" />
                      </div>
                    </div>
                  </>
                ) : null}
                {this.props.value.certificates === "No" || this.props.value.coursename === "-" ? null : (
                  <>
                    <div className="row" id="Certificatestyle" style={{ marginTop: "1%" }}>
                      <div className="col-lg-6">
                        <input
                          className="form"
                          name="SkillCoursename"
                          defaultValue={this.props.value.coursename}
                          type="text"
                          id="skillcourseName"
                          onChange={this.handleSkillChange}
                          placeholder="Course name"
                        />
                        {skillcoursename == null ? (
                          <span style={{ color: "#a29d9d", float: "left" }}>
                            Course Name
                          </span>
                        ) : (
                          <div style={{ color: "red", float: "left" }}>
                            {skillcoursename}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form"
                          name="SkillStartdate"
                          defaultValue={this.props.value.startdate}
                          id="skillcoursestartdate"
                          type="text"
                          onFocus={(e) => (e.target.type = "month")}
                          onChange={this.handleSkillChange}
                          placeholder="Start Date"
                        />
                        {skillCoursestartdate == null ? (
                          <span style={{ color: "#a29d9d", float: "left" }}>
                            Start Date
                          </span>
                        ) : (
                          <div style={{ color: "red", float: "left" }}>
                            {skillCoursestartdate}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form"
                          name="SkillEnddate"
                          defaultValue={this.props.value.enddate}
                          id="skillCourseenddate"
                          type="text"
                          onFocus={(e) => (e.target.type = "month")}
                          onChange={this.handleSkillChange}
                          placeholder="End Date"
                        />
                        {skillCourseEnddate == null ? (
                          <span style={{ color: "#a29d9d", float: "left" }}>
                            End Date
                          </span>
                        ) : (
                          <div style={{ color: "red", float: "left" }}>
                            {skillCourseEnddate}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6">
                      <input
                          className="form"
                          name="OldSkillCertificate"
                          type="hidden"
                          defaultValue={this.props.value.certificate}
                        />
                        <input
                          className="form"
                          name="SkillCertificate"
                          type="text"
                          id="SkillCertificate"
                          defaultValue={this.props.value.certificate}
                          onChange={
                            this.handleSkillChange
                          }
                          onFocus={(e) => (e.target.type = "file")}
                        />
                          {skillCertificate == null ? (
                          <span style={{ color: "#a29d9d", float: "left" }}>
                            Certificate upload
                          </span>
                        ) : (
                          <div style={{ color: "red", float: "left" }}>
                            {skillCertificate}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <br />
                <div className="col-lg-12">
                  <br />
                  <div className="row  justify-content-center">
                    <button
                      type="submit"
                      className="button-30 sk-edit"
                      style={{
                        color: "#8e75ef",
                        fontWeight: "bold",
                        height: "39.5px",
                        width: "83.36px",
                      }}
                      disabled={this.state.isButtonDisabled}
                    >
                      Update
                    </button>
                    <button
                      type="reset"
                      className="button-30 sk-edit"
                      style={{
                        color: "#716b6b",
                        fontWeight: "bold",
                        marginLeft: "2%",
                        height: "39.5px",
                      }}
                      onClick={() => this.setState({ formErrors: "",UploadCertificate: false })}
                    >
                      &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
