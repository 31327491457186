import React, { Component } from "react";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import $ from "jquery";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../Css/Team.css";
import Templateexcel from "../../Assets/Files/TeamExl.xlsx";
import LoadingPage from "../../Common/LoadingPage";
import CloseIcon from "@material-ui/icons/Close";
import { BASE_URLs } from "../../Config/Base";
import DataTable from 'react-data-table-component';
import "../../Css/excel.css";
import axios from "axios";
import Profile from "../../Assets/images/Avatar.png";
var XLSX = require("xlsx");
export default class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      rows: [],
      cols: [],
      Team: [],
      Teams: [],
      XlTeam: [],
      Notification: [],
      Id_delete: "",
      ExistEmail: [],
      currentSheet: {},
      comlogo: [],
      initialData: undefined,
      xlmty: "",
      SelectedNames: [],
      loading: false,
      loader:true,
      dispinvite: false,
      cancelOption: false,
      Filterpop: false,
      ShowInvite:false,
      letters: [],
      FilterData: "",
      filterValue: '',
      showSelectAll: false,
      showDelete: false,
      showInvite: false,
      inviteCount: 0,
      showDeleteCount: false,
      dispinvite: false,
      selectAllChecked: false,
      delay: false,
      UserLogo: [],
      AllUserLogo: [],
      shouldClickButton3: false,
      Allteamdetailsdata: [],
      Allteamdetailsdata1: [],
      NonAccessdata: [],
      currentPage: 1,
      itemsPerPage: 8,
      Filterpop1:false
    };
    this.initialState = this.state;
  }
  handleClickPage = (event, pageNumber) => {
    event.preventDefault();
    this.setState({ currentPage: pageNumber });
  };
  handleClickLink = () => {
    this.setState({ shouldClickButton3: true });
  };
  changeHandler = (event) => {
    let fileObj = event.target.files[0];

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
      } else {
        this.setState({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  handleItemsPerPageChange = (e) => {
    const itemsPerPage = parseInt(e.target.value, 10);
    this.setState({ itemsPerPage, currentPage: 1 });
  };

  handleSelectAllChange = (event) => {
    const { checked } = event.target;
    this.setState({ selectAllChecked: checked });
  };

  openteamgrid(Id, aspnetId, email) {
    var page = {
      pathname: "/Teamgriddetails",
      state: {
        Id: Id,
        aspnetId: aspnetId,
        Email : email
      },
    };
    this.props.history.push(page);
  }

  containsEmailAddress(element) {
    // Traverse up the DOM tree to check for email address in element or its ancestors
    while (element) {
        // Check if the element's content or attribute contains an email pattern
        if (element.innerText && /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i.test(element.innerText)) {
            return true; // Found an email address
        }
        element = element.parentElement; // Move up to the parent element
    }
    return false; // No email address found
}


  Team() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
       const Idfilterdata = data.sort((a, b) => parseInt(a.teamId) - parseInt(b.teamId));
        const firstnamefilterdata =  Idfilterdata.sort((a, b) => {
          const teamIdA = a.firstName.toUpperCase();
          const teamIdB = b.firstName.toUpperCase();
    
          if (teamIdA < teamIdB) {
            return -1;
          }
          if (teamIdA > teamIdB) {
            return 1;
          }
          return 0;
        });
        this.setState({
          Team: firstnamefilterdata,
          Teams: firstnamefilterdata,
        });

        console.log("Team", firstnamefilterdata)
      });
  }

  TopId = () => {
    const sortedData = [...this.state.Team].sort((a, b) => parseInt(a.teamId) - parseInt(b.teamId));
    this.setState({ Team: sortedData });
  };

  DownId = () => {
    const sortedData = [...this.state.Team].sort((a, b) => parseInt(b.teamId) - parseInt(a.teamId));
    this.setState({ Team: sortedData });
  };

  DownName = () => {
    const sortedData = [...this.state.Team].sort((a, b) => b.firstName.localeCompare(a.firstName));
    this.setState({ Team: sortedData });
  };

  TopName = () => {
    const sortedData = [...this.state.Team].sort((a, b) => a.firstName.localeCompare(b.firstName));
    this.setState({ Team: sortedData });
  };

  TopStatus = () => {
    const sortedData = [...this.state.Team].sort((a, b) => parseInt(a.invite) - parseInt(b.invite));
    this.setState({ Team: sortedData });
  };

  DownStatus = () => {
    const sortedData = [...this.state.Team].sort((a, b) => parseInt(b.invite) - parseInt(a.invite));
    this.setState({ Team: sortedData });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  onKeyUp = (e) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("invite")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    this.handleFilter(e, e.target.value);
  };


  onKeyUp1 = (e) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("invite")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    this.handleFilter1(e, e.target.value);
  };

  componentWillMount() {
    $(document).ready(function () {
      $("#add-row").click(function () {
        $("#add-row").hide();
      });
      $("#addBtn").click(function () {
        $("#add-row").show();
      });
    });

    $(document).ready(function () {
      $("#add-row").hide();
    });
    // Search User columns

    $("#searchorg").keyup(function () {
      // Search Text
      var search = $(this).val();

      // Hide all table tbody rows
      $("table tbody tr").hide();

      // Count total search result
      var len = $(
        'table tbody tr:not(.notfound) td:contains("' + search + '")'
      ).length;

      if (len > 0) {
        // Searching text in columns and show match row
        $('table tbody tr:not(.notfound) td:contains("' + search + '")').each(
          function () {
            $(this).closest("tr").show();
          }
        );
      } else {
        $(".notfound").show();
      }
    });
  }

  GetExistEmail() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/Team/GetExistEmail`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ ExistEmail: data });
      });
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}/AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }

  componentDidMount() {

    setTimeout(() => {
      this.setState({ delay: true })
    }, 2000);

    setTimeout(() => {
      this.setState({ loader:false })
    }, 2000);

    $("#add-row").click(function () {
      $("#add-row").hide();
    });
    $("#addBtn").click(function () {
      $("#add-row").show();
    });
    // this.GetNonAccess();
    this.GetOrgLogo();
    this.GetUserLogo();
    this.Team();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  componentWillMount() {
    $(document).ready(function () {
      $("#add-row").click(function () {
        $("#add-row").hide();
      });

      $("#addBtn").click(function () {
        $("#add-row").show();
      });
    });

    $(document).ready(function () {
      $("#add-row").hide();
    });
  }

  submit = (Email, firstName, lastName, Id, dn) => {
    const { SelectedNames } = this.state;

    SelectedNames.forEach((row) => {
      this.setState({ loading: true });
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: JSON.stringify({
          Email: row.email,
          firstName: row.firstName,
          lastName: row.lastName,
          Id: row.id,
          displayName: row.displayName,
        }),
      };
      fetch(`${BASE_URLs}/Team/SendEmail`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Error") {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ loading: false, showInvite:true });
          } else {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ loading: false, showInvite:true,dispinvite:false });
          }
          this.Team();
        });
    });

    // console.log(
    //   "Email, firstName, lastName, Id, dn",
    //   Email,
    //   firstName,
    //   lastName,
    //   Id,
    //   dn
    // );
  };

  Edit1(Id) {
    //localStorage.setItem("status","Pending")
    var page = {
      pathname: "/EditTeam",
      state: {
        Id: Id,
      },
    };
    this.props.history.push(page);
  }
  Detail1(Id) {
    //localStorage.setItem("status","Pending")
    var page = {
      pathname: "/Teamdetail",
      state: {
        Id: Id,
      },
    };
    // console.log("checking", Id);
    this.props.history.push(page);
  }

  reSubmit = (Email, firstName, lastName, Id, dn) => {
    this.setState({ loading: true });
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Email: Email,
        firstName: firstName,
        lastName: lastName,
        Id: Id,
        displayName: dn,
      }),
    };
    fetch(`${BASE_URLs}/Team/ReSendEmail`, options)
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === "Error") {
          toast.error(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ loading: false });
        } else {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ loading: false });
        }
        this.Team();
      });
  };

  checkAll(e) {
    var sa = document.getElementById("selectall");
    var checkboxes = document.getElementsByName("Id_delete");

    // if()
    let SelectedNames = [...this.state.SelectedNames];

    for (var j = 0; j < this.state.Team.length; j++) {
      if (sa.checked) {
        let obj = SelectedNames.includes(this.state.Team[j].firstName);
        if (!obj) {
          SelectedNames.push(this.state.Team[j].firstName);
        }
      } else {
        SelectedNames.splice(j);
      }
      this.setState({ SelectedNames });
    }

    let inviteCount = [];
    this.state.Team.map((d) => {
      if (d.invite === 1) {
        return null;
      } else {
        return inviteCount.push(d);
      }
    });

    let count = checkboxes.length;
    let countinvite = inviteCount.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("invite")
          .setAttribute("style", "visibility: visible;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7); margin-top: -1px;"
          );
        var msg = document.getElementById("deletecount");

        // document.getElementById("invite").setAttribute('style', 'visibility:visible ;');
        // document.getElementById("invitecount").setAttribute('style', 'visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);');
        // var msg = document.getElementById("invitecount")

        if (countinvite === 0) {
          msg.innerHTML = "<span> " + count + " selected </span> ";
          this.setState({
            dispinvite: false,
          });
        } else {
          msg.innerHTML =
            "<span> " +
            count +
            " selected </span> " +
            "<br />" +
            "<span> " +
            countinvite +
            " Invite </span> ";
          this.setState({
            dispinvite: true,
          });
        }
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("invite")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }
  onesubmit = (Email, firstName, lastName, Id, dn) => {
    this.setState({ loading: true });
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Email: Email,
        firstName: firstName,
        lastName: lastName,
        Id: Id,
        displayName: dn,
      }),
    };
    fetch(`${BASE_URLs}/Team/SendEmail`, options)
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === "Error") {
          toast.error(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ loading: false, showInvite:true });
        } else {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ loading: false });
          this.setState({showInvite: true});
        }
        this.Team();
      });
  };

  // handleFilter = (letter, event) => {
  //   event.preventDefault();
  //   const Team = this.state.Teams.filter((item) => {
  //     return (
  //       item.displayName.charAt(0).toLowerCase() === letter.toLowerCase() ||
  //       item.email.charAt(0).toLowerCase() === letter.toLowerCase() ||
  //       item.department.charAt(0).toLowerCase() === letter.toLowerCase() ||
  //       item.designation.charAt(0).toLowerCase() === letter.toLowerCase() ||
  //       item.role.charAt(0).toLowerCase() === letter.toLowerCase()
  //     );
  //   });
  //   this.setState({ Team, showResults: true, FilterData: Team });
  // };
  handleFilter = (event, letters) => {
    const { Teams } = this.state;
    const filterValue = Teams.filter((row) => 
      row.firstName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    this.setState({ Team: filterValue });
  }

  handleLetterFilter = (event, letters) => {
    const { Teams } = this.state;
    const filterValue = Teams.filter(row => {
      return letters.split('').every(letter => row.firstName.toLowerCase().startsWith(letter.toLowerCase()));
    });
    this.setState({ Team: filterValue });
  }

  handleFilter1 = (event, letters) => {
    const { Teams } = this.state;
    const filterValue1 = Teams.filter((row) => 
      row.firstName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    this.setState({ Team: filterValue1 });
  }


  handleLetterFilter1 = (event, letters) => {
    const { Teams } = this.state; 
    const filterValue1 = Teams.filter(row => {
      return letters.split('').every(letter => row.invite === 0 && row.firstName.toLowerCase().startsWith(letter.toLowerCase()));
    });
    this.setState({ Team: filterValue1 });
  }

  handlecheckbox = (state) => {
    const selectedRows = state.selectedRows;

    this.setState({ SelectedNames: selectedRows });

    // Calculate counts based on selected rows
    const inviteCount = selectedRows.filter(row => row.invite !== 1).length;
    const hasCommonValue = inviteCount > 0;

    this.setState({
      showSelectAll: selectedRows.length >= 2,
      // showDelete: selectedRows.length >= 1,
      // showInvite: selectedRows.length >= 1,
      inviteCount: inviteCount,
      showDeleteCount: selectedRows.length > 0,
      dispinvite: hasCommonValue
    });
  };





  // handlecheckbox(name) {
  //   //
  //   let SelectedNames = [...this.state.SelectedNames];
  //   let obj = SelectedNames.includes(name);
  //   // Add item to it
  //   if (!obj) {
  //     SelectedNames.push(name);
  //   } else {
  //     var index = SelectedNames.indexOf(name);
  //     SelectedNames.splice(index, 1);
  //   }
  //   // Set state

  //   this.setState({ SelectedNames });

  //   let checkboxes = document.querySelectorAll(
  //     '#tablepadding input[type="checkbox"]:checked'
  //   );
  //   let Total = document.querySelectorAll('input[type="checkbox"]');
  //   // selectalldiv
  //   if (checkboxes.length >= 2) {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:visible ;");
  //   } else {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:hidden ;");
  //   }
  //   if (checkboxes.length >= 1) {
  //     let inviteCount = [];
  //     this.state.Team.map((d) => {
  //       if (d.invite === 1) {
  //         return null;
  //       } else {
  //         return inviteCount.push(d);
  //       }
  //     });
  //     //   const checkboxes = document.querySelectorAll('input[type="checkbox"]');
  //     const checkedValues = [];

  //     checkboxes.forEach((checkbox) => {
  //       if (checkbox.checked) {
  //         checkedValues.push(checkbox.value);
  //       }
  //     });

  //     let inviteUserId = [];

  //     inviteCount.map((d) => {
  //       return inviteUserId.push(d.id.toString());
  //     });

  //     let hasCommonValue = false;

  //     for (let i = 0; i < inviteUserId.length; i++) {
  //       if (checkedValues.includes(inviteUserId[i])) {
  //         hasCommonValue = true;
  //         break; // Exit the loop as soon as a common value is found
  //       }
  //     }

  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility:visible ;");
  //     document
  //       .getElementById("invite")
  //       .setAttribute("style", "visibility: visible;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute(
  //         "style",
  //         "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
  //       );
  //     let countinvite = inviteCount.length;
  //     var msg = document.getElementById("deletecount");
  //     let count = checkboxes.length - 1;

  //     if (document.getElementById("tablepadding").checked) {
  //       msg.innerHTML = "<span> " + count + " selected </span> ";
  //     } else {
  //       if (countinvite !== 0 && hasCommonValue === true) {
  //         msg.innerHTML =
  //           "<span> " +
  //           checkboxes.length +
  //           " selected </span> " +
  //           "<br />" +
  //           "<span> " +
  //           inviteUserId.filter((element) => checkedValues.includes(element))
  //             .length +
  //           " Invite </span> ";

  //         this.setState({
  //           dispinvite: true,
  //         });
  //       } else {
  //         msg.innerHTML = "<span> " + checkboxes.length + " selected </span> ";
  //         this.setState({
  //           dispinvite: false,
  //         });
  //       }
  //     }
  //   } else {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility: hidden;");
  //     document
  //       .getElementById("invite")
  //       .setAttribute("style", "visibility: hidden;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute("style", "visibility: hidden;");
  //   }
  //   if (document.getElementById("selectall").checked === false) {
  //     if (checkboxes.length === Total.length - 1) {
  //       document.getElementById("selectall").checked = true;
  //     }
  //   } else {
  //     if (document.getElementById("selectall").checked) {
  //       if (checkboxes.length - 2 !== Total.length) {
  //         document.getElementById("selectall").checked = false;
  //       }
  //     }
  //   }
  // }

  Multidelete = (event) => {
    event.preventDefault();

    // Construct FormData with row IDs
    const data = new FormData();
    this.state.SelectedNames.forEach((row) => {
      data.append('id_delete[]', row.id); // Assuming 'id' is the key for the row's identifier
    });

    // console.log("FormData:", data);

    // Calculate the value to display in the confirmation dialog
    let value = this.state.SelectedNames.length === 1 ? this.state.SelectedNames[0].displayName : this.state.SelectedNames.length;

    // Show confirmation dialog
    if (window.confirm("Are you sure you want to delete " + value + " records permanently?")) {
      // Prepare options for the fetch request
      const options = {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };

      // Send the delete request to the backend
      fetch(`${BASE_URLs}/Team/Multidelete`, options)
        .then((response) => response.json())
        .then((data) => {
          // Handle success response (if needed)
          this.Team();
          toast.success(data.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              window.location.reload(true);
            }
          });
        })
        .catch((error) => {
          // Handle error response (if needed)
          // console.error('Error:', error);
        });

      // Reset state after deletion
      this.setState({
        showDelete: false,
        showInvite: false,
        showDeleteCount: false,
        SelectedNames: [],
        selectAllChecked: false, // Reset the select all checkbox state
      });
    }

    // Reload the page
    // window.location.reload(true);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    // if (!userPassword) {
    //   alert("Please fill Password");
    //   return;
    // }
    this.state.Teams.map((d) => {
      if (/\d/.test(d.firstName)) {
        return alert(`The firstName ${d.firstName} contains a number.`);
      }
    })
    // else
    // {
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}/Team/Excelroot`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (typeof resp === "string") {
            alert(resp);
          } else {
            if (resp === undefined) {
              alert("0  Records  inserted ");
            }
            if (resp === 0) {

              alert("Records added Successfully");
            } else {
              alert(
                resp > 1 ?
                  +resp + " Records not inserted, Email or Id is already exists"
                  : +resp + " Record not inserted, Email or Id is already exists"
              );
            }
          }

          this.Team();
          this.setState({ initialData: undefined });
          this.setState({ currentSheet: {} });
          var file = document.getElementById("files");
          var emptyFile = document.createElement("input");
          emptyFile.type = "file";
          file.files = emptyFile.files;
        });
    } catch (error) { }
    // }
    // })

    //   this.state.Teams.map((d) => {
    //     if (/\d/.test(d.firstName)) {
    //         // Do something if the firstName contains a number
    //         // console.log(`The firstName ${d.firstName} contains a number.`);
    //     } else {
    // try {
    //   const data = new FormData(event.target);
    //   fetch(`${BASE_URLs}/Team/Excelroot`, {
    //     method: "POST",
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    //       "Access-Control-Allow-Origin": "*",
    //       Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
    //     },
    //     body: data,
    //   })
    //     .then((res) => res.json())
    //     .then((resp) => {
    //       if (typeof resp === "string") {
    //         alert(resp);
    //       } else {
    //         if (resp === undefined) {
    //           alert("0  Records  inserted ");
    //         }
    //         if (resp === 0) {
    //           alert("Records added Successfully");
    //         } else {
    //           alert(
    //             +resp + " Records not inserted, Email or Id is already exists"
    //           );
    //         }
    //       }

    //       this.Team();
    //       this.setState({ initialData: undefined });
    //       this.setState({ currentSheet: {} });
    //       var file = document.getElementById("files");
    //       var emptyFile = document.createElement("input");
    //       emptyFile.type = "file";
    //       file.files = emptyFile.files;
    //     });
    // } catch (error) {}
    // }
    // });

  };
  handleUpload = (event) => {
    this.setState({ showExcel: true });

    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document.getElementById("delete").setAttribute("style", "visibility: hidden;");
        document.getElementById("deletecount").setAttribute("style", "visibility: hidden;");
      }
    }

    // Get the uploaded file
    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx|\.xlsm)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);

    // Check if the file is allowed
    if (isAllowed) {
      // Read the file using FileReader
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        // Check if the parsed data is empty
        if (parsedData.length === 0) {
          toast.error("The uploaded Excel file is empty.", { autoClose: 3200 });
          this.setState({ initialData: [], showExcel: false });
        } else {
          this.setState({ initialData: parsedData });
        }
      };
      reader.onerror = (error) => {
        // console.error("Error reading file:", error);
      };
      reader.readAsBinaryString(files[0]);

      return true; // Indicate successful file upload
    } else {
      alert("Invalid File Type.");
      return false; // Indicate failed file upload
    }
  };

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("emp");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  GetUserLogo() {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    axios.get(`${BASE_URLs}/Team/GetUsersLogo`, options)
      .then((response) => {
        this.setState({
          UserLogo: response.data,
          AllUserLogo: response.data,
        });
        // console.log("UsersLogo", response.data);
      })
      .catch((error) => {
        // console.error("Error fetching", error);
      });
  }

  cancel = () => {

    var inputElement = document.getElementById("files");
    inputElement.value = "";

    this.setState({ showExcel: false });

  };

  // excelvalidation(){
  //   var fstn=document.getElementById("fn").value;
  //   var lstn=document.getElementById("ln").value;
  //   var eml= document.getElementById("el").value;
  //   var mbl= document.getElementById("mb").value;
  //   var rol= document.getElementById("ro").value;
  //   var dpmt= document.getElementById("dpt").value;
  //   var dsgn= document.getElementById("dsg").value;
  //   var rptm= document.getElementById("rm").value;
  //   var office= document.getElementById("ofl").value;
  //   var jod= document.getElementById("jd").value;
  //   var dpln= document.getElementById("dn").value;

  //   if(fstn==null){
  //     alert("firstname is empty");
  //     return false;
  //   }
  //   else if(fstn.length>0){
  //     return true;
  //   }
  //   else if(lstn==null){
  //     alert("lastname is empty");
  //     return false;
  //   }
  //   else if(lstn.length>0){
  //     return true;
  //   }
  //   else if(eml==null){
  //     alert("email is empty");
  //     return false;
  //   }
  //   else if(eml.length>0){
  //     return true;
  //   }

  // }
  DeleteButton = (id) => {
    const data = new FormData();
    data.append("Id_delete", id);
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          //'Accept': 'application/json',

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}/Team/Multidelete`, options)
        .then((response) => response.json())
        .then((data) => {
          this.Team();
          toast.success(data.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              window.location.reload(true);
            }
          });
        });
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });

    }
    // window.location.reload(true);
  };

  conditionalRowStyles = (row, rowIndex) => {
    return {
      display: row.displayName ? 'none' : 'table-row' // Hide rows with a value in the firstName column
    };
  };

  render() {
    const customStyles = {
      headCells: {
        style: {
          flex: "0.5"
        }
      },
      header: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0"  // Make header text bold
        },
      },
      headCells: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0",
          borderBottom: "1px solid #ccc",
          wordBreak: "normal"
        },
      },

      columns: {
        idHeader: {
          style: {
            fontWeight: 'bold',
            fontSize: "14px",
            flex: "0"
          },
        },
      },
    };

    const columns = [
      {
        selector: row => row.teamId,
        id: "isheader",
        grow: 0.5,
        sortable: false,
        name: (
          <div>
            Id{" "}
            <span>
              <i
                onClick={this.TopId}
                title="A-Z for Id"
                className="icofont icofont-arrow-up"
              ></i>{" "}
              <i
                title="Z-A for Id"
                onClick={this.DownId}
                className="icofont icofont-arrow-down"
              ></i>
            </span>
          </div>
        ),
        cell: row => (
          <div onClick={() =>{}
            // this.Detail1(row.id)
          }>
            {row.teamId} {/* Assuming you want to display the teamId */}
          </div>
        )
      },
      {
        name: (
          <div style={{ display: "contents" }}>
            Name{" "}
            <span
              style={{ display: "flex", wordBreak: "normal" }}
            >
              <i
                onClick={this.TopName}
                title="A-Z for Id"
                className="icofont icofont-arrow-up"
              ></i>{" "}
              <i
                title="Z-A for Id"
                onClick={this.DownName}
                className="icofont icofont-arrow-down"
              ></i>
            </span>
          </div>
        ),
        selector: row => row.firstName,
        grow: 0.5,
        sortable: false,
        cell: row => (
          <div onClick={() =>{}
            // this.Detail1(row.id)
          } style={{ textTransform: "uppercase" }}>
            {row.firstName} {/* Assuming you want to display the teamId */}
          </div>
        )
      },
      {
        name: 'Email',
        selector: row => row.email,
        grow: 2,
        sortable: false,
        cell: row => <span style={{ textTransform: "uppercase", wordBreak: "breakWord", display: "contents" }}>{row.email}</span>,

      },
      {
        name: 'Role',
        grow: 0.5,
        selector: row => row.role,
        sortable: false,
        cell: row => <span style={{ textTransform: "uppercase" }}>{row.role}</span>,

      },
      {
        name: (
          <div style={{ display: "contents" }}>
            Status{" "}
            <span style={{ display: "flex", wordBreak: "normal" }}>
              <i
                onClick={this.TopStatus}
                title="A-Z for Id"
                className="icofont icofont-arrow-up"
              ></i>{" "}
              <i
                title="Z-A for Id"
                onClick={this.DownStatus}
                className="icofont icofont-arrow-down"
              ></i>
            </span>
          </div>
        ),
        selector: row => row.status,
        sortable: false,
        grow: 1,
        cell: row => (
          <b>
            {row.status === 1
              ? 'Active'
              : row.status === 2 && row.invite === 0
                ? <>
                  <span
                    className="invitebtn"
                    onClick={() =>
                      this.onesubmit(
                        row.email,
                        row.firstName,
                        row.lastName,
                        row.id,
                        row.displayName
                      )
                    }
                    
                  >
                    Invite
                  </span>
                </>
                : row.invite === 1 && row.status === 2
                  ? <>
                    <span>Pending</span> /{" "}
                    <span
                      className="pendingbtn"
                      id="Resend"
                      onClick={() =>
                        this.reSubmit(
                          row.email,
                          row.firstName,
                          row.lastName,
                          row.id,
                          row.displayName
                        )
                      }
                    >
                      Resend
                    </span>
                  </>
                  : row.status === 3
                    ? 'Terminate'
                    : 'Inactive'}
          </b>
        )
      },
      // {
      //   name: 'Action',
      //   grow: 0,
      //   cell: row => (
      //     <div>
      //       <button
      //         className="button-30 sk-del"
      //         onClick={(e) => {
      //           e.preventDefault();
      //           this.DeleteButton(row.id);
      //         }}
      //         title="Delete"
      //         style={{ marginLeft: "-10px" }}
      //       >
      //         <i className="fa fa-minus" aria-hidden="true"></i>
      //       </button>
      //       &nbsp;&nbsp;
      //       {" "}
      //       <button
      //         className="button-30 sk-details"
      //         title="Details"
      //         type="button"
      //         onClick={() => this.Detail1(row.id)}
      //         style={{ color: "rgb(94,217,135)" }}
      //       >
      //         <i className="fa fa-info-circle" aria-hidden="true"></i>
      //       </button>
      //     </div>
      //   )
      // }
    ];
    const { Teams, comlogo, UserLogo, Allteamdetailsdata, currentPage, itemsPerPage, loader } = this.state;
    const data = Teams.filter(team => team.status !== 3);
    var username = JSON.parse(localStorage.uname);
    var filename = `OAT_${username}_Team_${new Date().toDateString()}`;
    const { delay, Team, Filterpop,Filterpop1, FilterData, filterValue,filterValue1, showExcel,showInvite } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Team.slice(indexOfFirstItem, indexOfLastItem);
    var User_Id = JSON.parse(localStorage.userId);
    
    const renderItems = loader ? (
      <div>
        <p>Loading...</p>
      </div>
    ) : currentItems.length === 0 ? (
      <div>
        <p>No Members</p>
      </div>
    ) : (
      !showInvite &&
      currentItems.map((Team) => (
        <div className="col-md-3" key={Team.id}>
          <div className="card" style={{ boxShadow: "0 20px 20px 5px rgba(0,0,0,0.25)", borderRadius: "0.5rem" }}
            onClick={(event) => {
              const clickedElement = event.target.closest("#clicked");
              const clickedElement1 = event.target.closest("#message");
              if (!clickedElement && !clickedElement1) {
                if (!this.containsEmailAddress(event.target)) {
                  this.openteamgrid(Team.id, Team.particularId, Team.email, event);
                } else {
                  this.openteamgrid(Team.id, Team.particularId, Team.email, event);
                }
              }
            }}
          >
            <div>
              <div className="container1">
                {comlogo.map((comlogo) => (
                  <div className="image-container" style={{ width: "100%", height: "100px" }} key={comlogo.id}>
                    <img src={comlogo.com_logo} alt="Company Logo" />
                  </div>
                ))}
                {UserLogo.map((logo) => (
                  <div key={logo.id} className="round-image" style={{ cursor: "pointer" }}>
                    {logo.email === Team.email && <img src={logo.profile_Picture} alt="User Profile" />}
                  </div>
                ))}
                {Team.invite === 0 && Team.status === 2 ? 
                <div className="round-image" style={{ cursor: "pointer" }}>
                <img src={Profile} alt="User Profile" /> </div> : null}
              </div>
              <br /><br /><br /><br />
              <div className="card_content">
                <div style={{ textAlign: "center", marginTop: "5px", cursor: "pointer" }}><b>{Team.firstName}</b></div>
                <div style={{ textAlign: "center", marginTop: "5px" }}>{Team.designation}</div>
                <div style={{ textAlign: "center", marginTop: "5px" }}><b>{Team.department}</b></div>
                <p style={{ textAlign: "center", marginTop: "5px", wordWrap: "break-word" }}>
                  {Team.status === 1 ?
                    <div id="clicked">
                      <Link
                        to={{
                          pathname: '/Chatbox',
                          state: {
                            shouldClickButton3: true,
                            FirstName: Team.firstName,
                            UserName: Team.email,
                            pic: Team.profile_Picture,
                            senderId: JSON.parse(localStorage.getItem("userId")),
                            particularId: Team.id
                          }
                        }}> {Team.email}</Link>
                    </div> : <p>{Team.email}</p>}
                </p>
                {Team.userId === User_Id ? <p style={{ textAlign: "center", marginTop: "5px" }} className="card_text">{Team.mobile}</p> : <p style={{ textAlign: "center", marginTop: "5px" }} className="card_text">{Team.mobile}</p>}
              </div>
            </div>
            <div style={{ textAlign: "center", marginBottom: "20px" }} id="message">
              <Link
                to={{
                  pathname: '/Chatbox',
                  state: {
                    shouldClickButton3: true,
                    FirstName: Team.firstName,
                    UserName: Team.email,
                    pic: Team.profile_Picture,
                    senderId: JSON.parse(localStorage.getItem("userId")),
                    particularId: Team.id
                  }
                }}
              >
                {Team.status === 1 ? <i className="fa fa-comment" aria-hidden="true"></i> : null}
              </Link>&nbsp;&nbsp;&nbsp;&nbsp;
              <b>
                {Team.status === 1
                  ? 'Active'
                  : Team.invite === 1 && Team.status === 2
                    ? <>
                      <span>Pending</span> /{" "}
                      <span
                        className="pendingbtn"
                        id="Resend"
                        onClick={() =>
                          this.reSubmit(
                            Team.email,
                            Team.firstName,
                            Team.lastName,
                            Team.id,
                            Team.displayName
                          )
                        }
                      >
                        Resend
                      </span>
                    </>
                    : null}
              </b>
            </div>
          </div>
        </div>
      ))
    );


    const totalPages = Math.ceil(Team.length / itemsPerPage);
    let pageNumbers = [];
    if (currentPage > 1) {
      pageNumbers.push(
        !showInvite && (
          <li className="page-item" key="prev">
            <a href="#" className="page-link" onClick={(e) => this.handleClickPage(e, currentPage - 1)}>
              Previous
            </a>
          </li>
        )
      );
    }
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        !showInvite && (
          <li className={`page-item ${currentPage === i ? 'active' : ''}`} key={i}>
            <a href="#" className="page-link" onClick={(e) => this.handleClickPage(e, i)}>
              {i}
            </a>
          </li>
        )
      );
    }
  
    if (currentPage < totalPages) {
      pageNumbers.push(
        !showInvite && (
          <li className="page-item" key="next">
            <a href="#" className="page-link" onClick={(e) => this.handleClickPage(e, currentPage + 1)}>
              Next
            </a>
          </li>
        )
      );
    }
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {this.state.loading ? <LoadingPage /> : null}
        {this.state.loader ?  null : <div style={{ visibility: 'hidden' }}></div>}
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content ">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                {/*  Start */}
                <div className="page-body ">
                  {/*  */}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          </div>
                          <h4 className="title" style={{ marginRight: "83px" }}>
                            Team
                          </h4>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                          {/* <div className="col-xl-12 " style={{marginBottom:"95px"}}> */}
                          <div className="card-block">
                            <div className="row" style={{ display: "flex" }}>
                              <div className="col  btnalign">
                                <Link
                                  to="/AddTeam"
                                  // to="/EntityList"
                                  style={{ width: "150px" }}
                                  id="addBtn"
                                  className="btn"
                                  onKeyPress={this.handleKeyPress}
                                >
                                  {" "}
                                  Add{" "}
                                </Link>
                              </div>

                              <div className="col btnalign">
                                <label
                                  htmlFor="files"
                                  className="btn"
                                  id="addBtn"
                                  name="Teams"
                                >
                                  Import From Excel
                                </label>{" "}
                                &nbsp;&nbsp;
                                <input
                                  id="files"
                                  type="file"
                                  accept=".xlsx"
                                  style={{ display: "none" }}
                                  onChange={this.handleUpload}
                                  name="Teams"
                                />
                              </div>
                              <div style={{ padding: "7px" }}></div>
                              <div className="col btnalign">
                                {/* <ReactHTMLTableToExcel
                                  className="btn"
                                  table="emp"
                                  filename={filename}
                                  sheet="Sheet"
                                  buttonText="Export to excel" id="exportxl" /> */}
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={() => this.exportXlsx(filename)}
                                  onKeyPress={this.handleKeyPress}
                                >
                                  Export to excel
                                </button>
                              </div>
                              <div className="col btnalign">
                                <a
                                  className="btn"
                                  href={Templateexcel}
                                  type="button"
                                  style={{ color: "#5035ce" }}
                                >
                                  <i
                                    title="Download Template"
                                    className="icofont icofont-download"
                                  ></i>{" "}
                                  Download a template
                                </a>
                              </div>

                              <div className="col btnalign" style={{ flex: '2 1' }}>
                               {!showInvite ? <label
                                  className="btn"
                                  id="addBtn"
                                  onClick={() => {
                                    this.setState({
                                      showInvite: !this.state.showInvite,
                                    });
                                  }}
                                  style={{cursor:"pointer"}}
                                >
                                  Invite
                                </label>:<label
                                  className="btn"
                                  id="addBtn"
                                  onClick={() => {
                                    this.setState({
                                      showInvite: !this.state.showInvite,
                                    });
                                  }}
                                  style={{cursor:"pointer"}}
                                >
                                  Team
                                </label>}
                                &nbsp;&nbsp;
                              </div>

                              <div
                                // className="col-lg-1"
                                style={{
                                  overflow: "scroll",
                                  height: "73px",
                                  textAlign: "center",
                                  cursor: "pointer",
                                  visibility:"hidden",
                                }}
                              >
                                {/* Just for design */}
                              </div>

                              <div
                              >
                                {!showInvite ? <input
                                  className="Searchbox"
                                  id="CNSearch"
                                  placeholder="Search"
                                  onKeyUp={this.onKeyUp}
                                  type="text"
                                  value={filterValue}
                                  // style={{ float: "right" }}
                                  onKeyPress={this.handleKeyPress}
                                  onClick={() => {
                                    this.setState({
                                      // Filterpop: true,
                                      Filterpop: !this.state.Filterpop,
                                    });
                                  }}
                                  readOnly
                                />:<input
                                className="Searchbox"
                                id="CNSearch"
                                placeholder="Search"
                                onKeyUp={this.onKeyUp}
                                type="text"
                                value={filterValue1}
                                // style={{ float: "right" }}
                                onKeyPress={this.handleKeyPress}
                                onClick={() => {
                                  this.setState({
                                    // Filterpop: true,
                                    Filterpop1: !this.state.Filterpop1,
                                  });
                                }}
                                readOnly
                              />}
                              </div>

                              {/* {this.state.Teams.length === 0 ? null : (
                                <div className="col-lg-4">
                                  <input
                                    className="Searchbox"
                                    placeholder="Search"
                                    onKeyUp={this.onKeyUp}
                                    style={{ float: "right" }}
                                    onKeyPress={this.handleKeyPress}
                                  />
                                </div>
                              )} */}
                            </div>
                            {/* </div> */}
                            <div
                            >
                              <input
                                className="Searchbox"
                                id="CNSearch1"
                                placeholder="Search"
                                onKeyPress={this.handleKeyPress}
                                onKeyUp={this.myFunction}
                                value={filterValue}
                                onClick={() => {
                                  this.setState({
                                    Filterpop: !this.state.Filterpop,
                                  });
                                }}
                                readOnly
                              />
                            </div>
                            <center>
                              {showExcel && this.state.initialData !== undefined ? (
                                <table className="table" id="tableDesign">
                                  <thead>
                                    <tr>
                                      {Object.keys(this.state.initialData[0]).map((key) => (
                                        <th key={key} id="team1design">{key}</th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.initialData
                                      .filter(row => Object.values(row).some(value => value !== "")) // Filter out empty rows
                                      .map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                          {Object.values(row).map((value, cellIndex) => (
                                            <td key={cellIndex} id="teamdesign">{value}</td>
                                          ))}
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              ) : null}
                            </center>
                            {showExcel && this.state.initialData !== undefined ? (
                              <div className="savebtndiv" style={{ marginBottom: "10px" }}>
                                <button
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px" }}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px" }}
                                  onClick={this.cancel}
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : null}

                          </div>
                        </form>{" "}
                        <div
                        // style={{ margin: "-3.5% 7%" }}
                        >
                          <OutTable
                            data={this.state.rows}
                            columns={this.state.cols}
                            tableClassName="ExcelTable2007"
                            tableHeaderRowclassName="heading"
                          />
                        </div>
                        <form
                          id="multidelfm"
                          onSubmit={this.Multidelete}
                          onKeyPress={this.handleKeyPress}
                          style={{ marginTop: "-3%", zIndex: 2 }}
                        >
                          <button
                            type="submit"
                            className="multitrash"
                            id="delete"
                            style={{ float: "right", visibility: this.state.showDelete ? 'visible' : 'hidden' }}
                          >
                            <i
                              className="icofont icofont-delete-alt"
                              style={{ color: "darkgray" }}
                            ></i>
                          </button>
                          <div>
                            <span
                              id="deletecount"
                              className="multitrash"
                              style={{marginTop:"-3px"}}
                            >
                              {/* {this.state.showDeleteCount && (
                                <span style={{ backgroundColor: "#007bff", background: "linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)" }}>{this.state.SelectedNames.length} selected</span>
                              )} */}
                              {this.state.dispinvite && (
                                <>
                                  <br />
                                  <span style={{ backgroundColor: "#007bff", background: "linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)" }}>{this.state.inviteCount} Invite</span>
                                </>
                              )}
                            </span>
                          </div>
                         {showInvite && ( <>
                          {currentItems.map((Team) => (
            <div
              key={Team.id}
              style={{
                backgroundColor: "red",
                position: "relative",
                top: 23,
              }}
            >
              <span
                type="submit"
                className="invitemultitrash"
                id="invite"
                style={{
                  float: "right",
                  visibility: this.state.showInvite ? 'visible' : 'hidden',
                }}
                onClick={() => this.handlecheckbox({ selectedRows: [Team] })}
                defaultValue={Team.id}
              >
                {this.state.dispinvite ? (
                  <i
                    className="fa fa-user-plus"
                    style={{ color: "darkgray" }}
                    onClick={() =>
                      this.submit(
                        Team.email,
                        Team.firstName,
                        Team.lastName,
                        Team.id,
                        Team.displayName
                      )
                    }
                  ></i>
                ) : null}
              </span>
            </div>
          ))}
          <div id="selectalldiv" style={{ visibility: this.state.showSelectAll ? 'hidden' : 'hidden' }}>
        <input type="checkbox" id="selectall" checked={this.state.selectAllChecked} onChange={this.handleSelectAllChange} />&nbsp;&nbsp;
      </div>

                          {/* <button className="multitrash"  id="invite" style={{ float: "right", visibility: "hidden", marginTop:'10%' }}><i className="fa fa-user-plus" style={{ color: "darkgray" }}></i></button>
                          <div>  <span id="invitecount" className="multitrash" style={{ visibility: "hidden", fontSize: "17px" }}></span></div>
                          <div id="invitealldiv" style={{ visibility: "hidden" }}>
                            &nbsp;&nbsp;                            
                          </div> */}

       <div className="dt-responsive table-responsive">
        <DataTable
          columns={columns}
          data={Team.filter(team => team.invite === 0)}
          selectableRows
          onSelectedRowsChange={this.handlecheckbox} // Assuming handlecheckbox is a method to handle row selection
          pagination
          customStyles={customStyles}
          //  noDataComponent={Team.length===0 && delay?<div style={{  textAlign: 'center' }}>No Record</div>:null}
           noDataComponent={Team.length===0 && delay?null:"No Members"}
        />
      </div>

      
      


                         
                          </>)}
                          <br /><br /><div className="container">
                            <div className="row">
                              {renderItems}
                            </div>
                            <nav>
                            {( currentItems.length !== 0 && !showInvite)  && 
                              <>
                            <div className="items-per-page-selector">
        <label htmlFor="itemsPerPage">Items per page: </label>
        <select id="itemsPerPage" value={itemsPerPage} onChange={this.handleItemsPerPageChange}>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
                              <ul className="pagination">
                                {pageNumbers}
                              </ul></>}
                            </nav>
                          </div>
                        </form>
                         {/* hidden the Table for Exporting Excel  */}

                         <table id="emp" className="table" hidden>
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>FirstName</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Display Name </th>
                                <th>Mobile Number</th>
                                <th>Role </th>
                                <th>Department</th>
                                <th>Designation</th>
{/* 
                                <th>Reporting Manager</th> */}
                                <th>Locations</th>
                                {/* <th>Joining Date</th> */}
                                
                              </tr>
                            </thead>
                            <tbody>
                              {Team.map((Team) => (
                                <tr style={{}}>
                                  <td>{Team.teamId}</td>
                                  <td>{Team.firstName}</td>
                                  <td>{Team.lastName}</td>
                                  <td>{Team.email}</td>
                                  <td>{Team.displayName}</td>
                                  <td>{Team.mobile}</td>
                                  <td style={{ textTransform: "uppercase" }}>
                                    {Team.role}
                                  </td>
                                  <td>{Team.department}</td>
                                  <td style={{ textTransform: "uppercase" }}>
                                    {Team.designation}
                                  </td>

                                  {/* <td>{Team.report_Manager}</td> */}
                                  <td>{Team.primary_Ofc}</td>
                                  {/* <td>{new Date(Team.joining_Date).toDateString()}</td> */}
                                 

                                  {/* {(() => {
                                    if (Team.invite === 1) {
                                      return (
                                        <td ><b>Invited</b></td>
                                      )
                                    }

                                    if (Team.invite === 0) {
                                      return (
                                        <td><b>Not Invited</b></td>
                                      )
                                    }
                                  })()} */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {Filterpop && (
                <div className="popSearchTeam">
                  <div className="popSearchinnernormalTeam">
                    <div
                      className="popSearchinnertitle"
                      id="popSearchinnernormal"
                    >
                      <button
                        id="close"
                        style={{
                          float: "right",
                          backgroundColor: "#a0a3a305",
                          color: "#797070",
                          border: "1px solid #a3a5a505",
                          marginTop: "-20px",
                          marginRight: "-20px",
                        }}
                        onClick={() => {
                          this.setState({ Filterpop: false });
                          this.Team();
                        }}
                      >
                        <CloseIcon />
                      </button>

                      <div className="checkboxes">
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{
                            fontSize: "20px",
                            color: "gray",
                            marginLeft: "-1%",
                            position: "absolute",
                            marginTop: "0.5%",
                          }}
                        ></i>
                        <input
                          className="Searchbox"
                          placeholder="Search"
                          onKeyUp={this.onKeyUp}
                          style={{ float: "right", width: "80%" }}
                        />
                        <br></br>
                        {this.state.letters.map((letter, index) => (
                          <button
                            className="filter"
                            key={index}
                            onClick={(event) => this.handleLetterFilter(event, letter)}
                          >
                            {letter}
                          </button>
                        ))}
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              )}

{Filterpop1 && (
                <div className="popSearchTeam">
                  <div className="popSearchinnernormalTeam">
                    <div
                      className="popSearchinnertitle"
                      id="popSearchinnernormal"
                    >
                      <button
                        id="close"
                        style={{
                          float: "right",
                          backgroundColor: "#a0a3a305",
                          color: "#797070",
                          border: "1px solid #a3a5a505",
                          marginTop: "-20px",
                          marginRight: "-20px",
                        }}
                        onClick={() => {
                          this.setState({ Filterpop1: false });
                          this.Team();
                        }}
                      >
                        <CloseIcon />
                      </button>

                      <div className="checkboxes">
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{
                            fontSize: "20px",
                            color: "gray",
                            marginLeft: "-1%",
                            position: "absolute",
                            marginTop: "0.5%",
                          }}
                        ></i>
                        <input
                          className="Searchbox"
                          placeholder="Search"
                          onKeyUp={this.onKeyUp1}
                          style={{ float: "right", width: "80%" }}
                        />
                        <br></br>
                        {this.state.letters.map((letter, index) => (
                          <button
                            className="filter"
                            key={index}
                            onClick={(event) => this.handleLetterFilter1(event, letter)}
                          >
                            {letter}
                          </button>
                        ))}
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
