import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPopup1 from "./SuccessPopup1";
import { Button } from "@material-ui/core";
import $ from "jquery";
import { Announcement } from "@material-ui/icons";
import { Link } from "react-router-dom";
import SearchPopup from "./SearchPopup";
import msg from "../Assets/images/msg.png";
import Background from "../Assets/images/msgbg.png";
import { BASE_URLs } from "../Config/Base";
export default class LastChatpop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcements: [],
      AllAnnouncement: [],
      showing: true,
      profile: [],
      EditSkillModel: false,
      EditSkillValues: [],
      SearchModel: false,
      SearchValues: [],
      Id: "",
      firstName: "",
      isButton1Visible: true,
      isButton2Visible: true,
      allmessage: "",
      usermsgId: "",
      receivemsg: "",
      Skillname: "",
      User: [],
      Alluser: [],
      childVisible: false,
      lastMessage: null,
      notificationCount: 0,
    };
    this.initialState = this.state;
  }
  onClick() {
    this.setState({ childVisible: !this.state.childVisible });
  }

  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/AddOrganization/status1`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          User: data,
          Alluser: data,
        });
        // // console.log("user data", data);
      });
  }

  handleButtonClick1 = () => {
    this.setState((prevState) => ({
      isButton2Visible: !prevState.isButton2Visible,
      isButton1Visible: false,
    }));
  };

  EditSkillModel = (e, fn, al, uid, rmsg, em, mb, userName) => {
    //  document.getElementById("EntityList").style.display = "none";
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditSkillValues: {
        id: e,
        firstName: fn,
        allmessage: al,
        usermsgId: uid,
        receivemsg: rmsg,
        email: em,
        displayName: mb,
        userName: userName,
      },
    });
    this.setState((prevState) => ({
      EditSkillModel: !prevState.EditSkillModel,
    }));
  };
  SearchModel = (e, fn) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      SearchValues: {
        id: e,
        Skillname: fn,
      },
    });
    this.setState((prevState) => ({
      SearchModel: !prevState.SearchModel,
    }));
  };
  GetLastChat(Id) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}/UserBank/GetAllUserPop`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcements: data,
          AllAnnouncement: data,
        });
        // console.log("lastchat", data);
      });
  }
  // myFunction = (e) => {
  //   const Announcements = this.state.AllAnnouncement.filter(
  //     (item) =>
  //     item.firstName.toLowerCase().includes(e.target.value.toLowerCase())
  //   );
  //   this.setState({Announcements});
  // };
  myFunction = (e) => {
    const Announcements = this.state.AllAnnouncement.filter((item) =>
      item.firstName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Announcements });
  };
  OATSearch = (e) => {
    const User = this.state.Alluser.filter((item) =>
      item.company_Name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ User });
  };
  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }
  fetchLastMessage = () => {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    // Make an API call to fetch the last message from the server
    // Here, you can use libraries like axios or fetch to make the request
    fetch(`${BASE_URLs}/contact/GetLastMessage`, options)
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        const lastMessage = data.message;
        this.setState({ lastMessage });
        // console.log(lastMessage);
      })
      .catch((error) => {
        // console.error("Error fetching last message:", error);
      });
  };

  fetchNotificationCount = async () => {
    try {
      const response = await fetch(`${BASE_URLs}/Notification/Notification`);
      const data = await response.json();
      this.setState({ notificationCount: data.count });
      // console.log(data);
      if (this.state.notificationCount.length !== 0) {
        this.setState({
          Noticount: this.state.notificationCount[0].noticount,
        });
      }
    } catch (error) {
      // console.log(error);
    }
    setInterval(1000);
  };

  markMessageAsSeen = (senderId) => {
    const options = {
      method: "POST",

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },

      body: JSON.stringify({
        SenderId: senderId,
      }),
    };

    fetch(`${BASE_URLs}/Notification/Msgcount`, options)
      .then((response) => response.json())

      .then((data) => {
        // Handle the response data if needed

        // console.log(data);
      })

      .catch((error) => {
        // console.error("Error updating Seen field:", error);
      });
  };

  componentDidMount() {
    this.GetLastChat();
    this.UserOrg();
    this.fetchLastMessage();
    this.fetchNotificationCount();
  }

  render() {
    var w = window.innerWidth;
    var username = JSON.parse(localStorage.uname);

    const {
      Announcements,
      AllAnnouncement,
      User,
      isButton1Visible,
      isButton2Visible,
      lastMessage,
      notificationCount,
    } = this.state;
    return (
      <>
        <div>
          {this.state.EditSkillModel ? (
            <div className="row">
              <div className="col-lg-6">
                <SuccessPopup1
                  id=""
                  values={this.state.EditSkillValues}
                  close={() => this.EditSkillModel()}
                />
              </div>
            </div>
          ) : null}
          {/* {this.state.SearchModel ? (
            <SearchPopup id="" close={() => this.SearchModel()} />
          ) : null} */}
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body chat-bg">
                <div className="">
                  <div id="" className="">
                    <div className="page-body">
                      <div className="row">
                        <div className="chat-boxs">
                          <div id="sidebar" className="users p-chat-users">
                            <div>
                              <div
                              // className="card card_main p-fixed users-mains "
                              // style={{ overflow: "scroll" }}
                              >
                                <div>
                                  <div>
                                    <main
                                      id="maintab"
                                      style={{
                                        marginTop: "-140px",
                                        marginLeft: "-38px",
                                        backgroundColor: "#dddd",
                                        width: "300%",
                                        height: "70vh",
                                        overflow: "auto",
                                      }}
                                    >
                                      <div>
                                        <div className="col-lg-12">
                                          <Link
                                            id="addRow"
                                            className="btn chatbtncolor"
                                            onClick={() => {
                                              this.AddSkillModel();
                                              this.handleButton1Click();
                                              //this.LastChatModel();
                                            }}
                                          >
                                            <span className="chatButtonTextColor">
                                              {" "}
                                              Open Discussion
                                            </span>{" "}
                                            &nbsp;&nbsp;{" "}
                                            <i
                                              className="fa fa-comments-o chatButtonTextColor"
                                              aria-hidden="true"
                                            ></i>
                                          </Link>
                                          <Link
                                            id="addRow"
                                            className="btn chatbtncolor"
                                            onClick={() => {
                                              this.AddSkillModel();
                                              this.handleButton1Click();
                                              //this.LastChatModel();
                                            }}
                                          >
                                            <span className="chatButtonTextColor">
                                              {" "}
                                              Close Discussion
                                            </span>{" "}
                                            &nbsp;&nbsp;{" "}
                                            <i
                                              className="fa fa-comments-o chatButtonTextColor"
                                              aria-hidden="true"
                                            ></i>
                                          </Link>
                                          <input
                                            type="text"
                                            className="Searchbox"
                                            placeholder="Search"
                                            onChange={this.myFunction}
                                            style={{
                                              float: "right",
                                              backgroundColor: "#dddd",
                                            }}
                                          />
                                          {/* <div className="form-icon">
                                            <i className="icofont icofont-search"></i>
                                          </div> */}
                                        </div>
                                        <br />

                                        <>
                                          {Announcements.length === 0 ? (
                                            <div
                                              style={{
                                                textAlign: "center",
                                                marginTop: "140px",
                                              }}
                                            >
                                              <img
                                                className="logo"
                                                src={msg}
                                                alt="Logo"
                                                width="60"
                                                height="70"
                                              />

                                              <p
                                                style={{
                                                  textAlign: "center",
                                                  color: "black",
                                                  fontSize: "24px",
                                                }}
                                              >
                                                <b>
                                                  You have No Recent Messages
                                                </b>
                                              </p>
                                            </div>
                                          ) : (
                                            Announcements.map(
                                              (Announcement) => (
                                                <>
                                                  <div
                                                    className="media userlist-box"
                                                    data-id="1"
                                                    data-status="online"
                                                    data-toggle="tooltip"
                                                    data-placement="left"
                                                    // key={Announcement.id,Announcement.usermsgId}
                                                    key={[
                                                      Announcement.id,
                                                      Announcement.usermsgId,
                                                    ].join("-")}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.EditSkillModel(
                                                        Announcement.id,
                                                        Announcement.firstName,
                                                        Announcement.allmessage,
                                                        Announcement.usermsgId,
                                                        Announcement.receivemsg,
                                                        Announcement.email,
                                                        Announcement.displayName,
                                                        Announcement.userName
                                                      );
                                                      this.handleButtonClick1();
                                                    }}
                                                  >
                                                    <a className="media-left">
                                                      <img
                                                        className="media-object img-circle"
                                                        src="assets/images/avatar-1.png"
                                                        alt="Generic placeholder image"
                                                        style={{
                                                          height: "50px",
                                                          width: "50px",
                                                          padding: "1px 1px",
                                                          marginLeft: "20px",
                                                        }}
                                                      />
                                                    </a>
                                                    <div
                                                      className="media-body"
                                                      style={{
                                                        textAlign: "initial",
                                                        padding: "2px 2px",
                                                      }}
                                                    >
                                                      <div className="f-13 chat-header">
                                                        {" "}
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            whiteSpace:
                                                              "nowrap",
                                                          }}
                                                        >
                                                          <b>
                                                            {
                                                              Announcement.firstName
                                                            }
                                                          </b>
                                                        </span>
                                                        <br />
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            whiteSpace:
                                                              "nowrap",
                                                          }}
                                                        >
                                                          <div
                                                            onClick={() =>
                                                              this.markMessageAsSeen(
                                                                Announcement.senderId
                                                              )
                                                            }
                                                          >
                                                            Last Message:{" "}
                                                            {
                                                              Announcement.message
                                                            }{" "}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {Announcement.counts >
                                                            0 ? (
                                                              <p
                                                                className="badge badge-primary"
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                  backgroundColor:
                                                                    "#563ccf",
                                                                }}
                                                              >
                                                                {
                                                                  Announcement.counts
                                                                }
                                                              </p>
                                                            ) : null}
                                                          </div>
                                                        </span>
                                                        <br />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                            )
                                          )}
                                        </>
                                      </div>
                                    </main>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="styleSelector"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
