import { Title } from "@material-ui/icons";
import React, { Component } from "react";
import LoadingPage from "../../Common/LoadingPage";
import SideBar from "../../Common/Sidebar";
import SuccesAnnouncement from "../../Common/SuccesAnnouncement";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base";
export default class Announcement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allNotify: [],
      formErrors: {},
      Announcement: [],
      SuccessModel: false,
    };
    this.handleFormValidation = this.handleFormValidation.bind(this);
  }

  GetAnnouncement() {
    let url = `${BASE_URLs}/Announcement/GetAllAnouce`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcement: data,
        });

        //
      });
  }

  componentDidMount() {
    this.GetAnnouncement();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  handleFormValidation() {
    var roles = document.getElementById("roles").value;
    var Message = document.getElementById("Message").value;
    var fileName = document.getElementById("fileName").value;
    // var VdoName = document.getElementById("VdoName").value;
    var Title = document.getElementById("Title").value;
    let formErrors = {};
    let formIsValid = true;
    // var reg = /(.*?)\.(jpg|jpeg|png|mp4|x-m4v|mkv|)$/;
    // var reg = /(.*?)\.(jpg|jpeg|png)$/;
    // var vdo = /(.*?)\.(mp4|x-m4v|mkv|)$/;
    //Email
    if (!roles) {
      formIsValid = false;
      formErrors["rolesErr"] = "Select Entity ";
    }
    if (!Message) {
      formIsValid = false;
      formErrors["MessageErr"] = "Description is required";
    }
    if (!Title) {
      formIsValid = false;
      formErrors["TitleErr"] = "Title is required";
    }

    // if (fileName) {
    //   if (!fileName.match(reg)) {
    //     formIsValid = false;
    //     formErrors["ImageErr"] = " Only Image can be Added";
    //   }
    // }
    // if (VdoName) {
    //   if (!VdoName.match(vdo)) {
    //     formIsValid = false;
    //     formErrors["VdoErr"] = "Only Video can be Added";
    //   }
    // }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleChange = (e) => {
    const { name, value, files } = e.target;
    this.setState({ [name]: value });
    var roles = document.getElementById("roles").value;
    var Message = document.getElementById("Message").value;
    var Title = document.getElementById("Title").value;
    var fileName = document.getElementById("fileName").value;
    // var VdoName = document.getElementById("VdoName").value;
    let formErrors = {};
    let formIsValid = true;
    // var reg = /(.*?)\.(jpg|jpeg|png|mp4|x-m4v|mkv|)$/;
    // var reg = /(.*?)\.(jpg|jpeg|png)$/;
    // var vdo = /(.*?)\.(mp4|x-m4v|mkv|)$/;

    const maxFileSizeMB = 10; // Set your desired maximum file size limit in megabytes
    const selectedFile = files && files.length > 0 ? files[0] : null;
    //Email
    if (!roles) {
      formIsValid = false;
      formErrors["rolesErr"] = "Select Entity ";
    }
    if (!Message) {
      formIsValid = false;
      formErrors["MessageErr"] = "Description is required";
    }
    if (!Title) {
      formIsValid = false;
      formErrors["TitleErr"] = "Title is required";
    }

    // if (fileName) {
    //   if (!fileName.match(reg)) {
    //     formIsValid = false;
    //     formErrors["ImageErr"] = " Only Image can be Added";
    //   }
    // }
    // if (VdoName) {
    //   if (!VdoName.match(vdo)) {
    //     formIsValid = false;
    //     formErrors["VdoErr"] = "only video can be Added";
    //   }
    // }

    // File size validation
    //  if (selectedFile) {
    //   const fileSizeMB = selectedFile.size / (1024 * 1024); // Convert to megabytes
    //   if (fileSizeMB > maxFileSizeMB) {
    //     // alert(`File size exceeds the maximum limit of ${maxFileSizeMB} MB.`);
    //     formIsValid = false;
    //     formErrors["VdoErr"] = `File size exceeds the maximum limit of ${maxFileSizeMB} MB.`;
    //     e.target.value = null;
    //   }
    // }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
  
    if (this.handleFormValidation()) {
      // setTimeout(() => {
      //   this.setState({ loading: true });
      // }, 500);
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}/Announcement/AddAnnouncement`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          // this.setState({ loading: false });
          this.SuccessModel();

          event.target.reset();
        });
        setTimeout(() => {
          var page = {
            pathname: "/AnnouncementPreviewSP",
            
          };
          this.props.history.push(page);
        }, 2000);

       
    }

  };

  render() {
    const { rolesErr, MessageErr, ImageErr, VdoErr, TitleErr } =
      this.state.formErrors;

    return (
      <>
        <SideBar active={window.location.pathname} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card" id="AdminAnnFullcontainer">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          </div>
                          <h4 className="title" style={{ marginRight: '56px' }}>Create New Announcements</h4>
                        </div>

                        {this.state.SuccessModel ? (
                          <SuccesAnnouncement
                            id="Success"
                            close={() => this.SuccessModel()}
                          />
                        ) : null}
                        <div className="card-block">
                          <form
                            id="Announceform"
                            className="contactForm"
                            autoComplete="on"
                            // encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="container">
                              {/* <b>
                                <Link
                                  style={{ cursor: "pointer", float: "right" }}
                                  to="AnnouncementPreviewSP"
                                  id="view"
                                >
                                  View Previous Announcements
                                </Link>
                              </b>
                              <br /> <br /> */}
                              {/* <center style={{fontSize:'15px',color:'#5035ce'}}>Create New Announcements</center>
                              <br /> */}
                              <div
                                className="row"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: '50px'
                                }}
                              >
                                <div className="col-lg-4 input-effect">
                                  <textarea
                                    className="form"
                                    id="Title"
                                    style={{ height: "37px" }}
                                    name="Title"
                                    onChange={this.handleChange}
                                    placeholder="Subject"

                                  />

                                  {!TitleErr ? (
                                    <span style={{ color: 'rgb(162, 157, 157)' }}>Subject</span>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {TitleErr}
                                    </div>
                                  )}
                                  <span className="underline" />
                                </div>

                                <div className="col-lg-4 input-effect">
                                  <textarea
                                    className="form"
                                    id="Message"
                                    style={{ height: "37px" }}
                                    name="Message"
                                    onChange={this.handleChange}
                                    placeholder="Description"
                                  />

                                  {!MessageErr ? (
                                    <span style={{ color: 'rgb(162, 157, 157)' }}>Description</span>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {MessageErr}
                                    </div>
                                  )}
                                  <span className="underline" />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="col-lg-4 input-effect">
                                  <select
                                    className="form selectleftalign"
                                    id="roles"
                                    name="Role"
                                    onChange={this.handleChange}
                                  >
                                    <option value="">Select</option>
                                    {/* <option value="1">All Entities  </option> */}
                                    <option value="All Entities">All Entities</option>
                                    <option value="Active Entities">Active Entities</option>
                                    <option value="InActive Entities">InActive Entities</option>

                                    {/* <option value="5">All Users</option> */}
                                  </select>

                                  {!rolesErr ? (
                                    <span style={{ color: 'rgb(162, 157, 157)' }}>Entities</span>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {rolesErr}
                                    </div>
                                  )}
                                  <span className="underline" />
                                </div>

                                <div className="col-lg-4 ">
                                  <input
                                    type="file"
                                    accept=".jpg, .jpeg, .pdf, .xlsx, .xls, .mp4, .avi, .mov"
                                    name="Image"
                                    onFocus={(e) => (e.target.type = "file")}
                                    style={{ width: "100%" }}
                                    className="form"
                                    id="fileName"
                                    onChange={this.handleChange}
                                    placeholder="Image"
                                    multiple
                                  />

                                  {!ImageErr ? (

                                    <span style={{ color: 'rgb(162, 157, 157)' }}>Upload Related Files&nbsp;(.jpg/.jpeg./.png/.mp4/.xlsx/.pdf)</span>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {ImageErr}
                                    </div>
                                  )}
                                  <span className="underline" />
                                </div>

                                {/* <div className="col-lg-4 input-effect" >      
    <input type="text"  className="form-control" onFocus={(e) => e.target.type = 'file'}  id="VdoName" style={{width:"100%"}} name="Video" onChange={this.handleChange}  placeholder="Video"  //style={{width:"123%",marginLeft:"-22%"}}
    />
     
       {!VdoErr? <span className="underline" />:<div style={{ color: "red"}}>{VdoErr}</div>}
    </div> */}
                              </div>
                              {/* <div
                                className="row"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="col-lg-4 input-effect">
                                  <input
                                    type="file"
                                    className="form"
                                    onFocus={(e) => (e.target.type = "file")}
                                    id="VdoName"
                                    style={{ width: "100%" }}
                                    name="Video"
                                    onChange={this.handleChange}
                                    placeholder="Video" //style={{width:"123%",marginLeft:"-22%"}}
                                  />
                                 
                                  {!VdoErr ? (
                                    <span style={{color:'rgb(162, 157, 157)'}}>Video</span>
                                  ) : (
                                    <div style={{ color: "red" }}>{VdoErr}</div>
                                  )}
                                  <span className="underline" />
                                </div>
                                <div className="col-lg-4 input-effect">
                               
                                </div>
                              </div> */}
                              <div className="col-lg-12">
                                <div className="row  justify-content-center" style={{ marginTop: '20px' }}>
                                  <button
                                    type="submit"
                                    title="Send Message"
                                    className="but"
                                  >
                                    Submit
                                  </button>
                                  <button
                                    type="reset"
                                    className=" btn-Secondary"
                                    style={{
                                      marginLeft: "1%",
                                      height: "39.5px",
                                    }}
                                    onClick={() =>
                                      this.setState({ formErrors: "" })
                                    }
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.loading === true ? <LoadingPage /> : null}
      </>
    );
  }
}
