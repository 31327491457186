import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactTimeAgo from "react-time-ago";
import SPEditAnnouncement from "../../Common/SPEditAnnouncement";
import { BASE_URLs } from "../../Config/Base";
import CloseIcon from "@material-ui/icons/Close";
import fileImg from "../../Assets/images/document.png";

export default class AnnouncementPreviewSP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcement: [],
      AllAnnouncement: [],
      SaAnnouncement: [],
      EditModel: false,
      EditBusinessValues: [],
      SkillModel: false,
      isOpen:false,
      delay:false,
      Filterpop: false,
      letters: [],
      FilterData: "",
      clickedContents:
        JSON.parse(localStorage.getItem("clickedContents")) || {},
    };
    this.handleContentClick = this.handleContentClick.bind(this);
  }
  popupclose=()=>{
    this.setState({
      isOpen:false
    })
  }
  GetParticulerAnnouncement(id) {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    this.handleContentClick(id);
    this.setState({
      isOpen:true
    })
    // var Id = this.props.location.state.Id;
    fetch(`${BASE_URLs}/Announcement/GetActiveAnnounceid?Id=` + id, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          SaAnnouncement: data,
        });

        // console.log("Announcemt", data);

        //
      });
  }
  GetAnnouncement() {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(`${BASE_URLs}/Announcement/GetSApreview`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcement: data,
          AllAnnouncement: data,
        });

        // console.log("New", data);

        //
      });
  }
  // AnnouncementView(Id) {
  //   ;

  //   var page = {
  //     pathname: "/AdminAnnouncmentpop",
  //     state: {
  //       Id: Id

  //     }
  //   }
  //   this.props.history.push(page);

  // }
  SkillModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState((prevState) => ({
      SkillModel: !prevState.SkillModel,
    }));

    this.GetAnnouncement();
  };
  EditModel = (e, fn,desc,rol,img,time) => {

    
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditBusinessValues: {
        id: e,
        title: fn,
        message:desc,
        role:rol,
        image:img,
        createdDate:time
        
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    this.GetAnnouncement();
  };
  AnnouncementView(Id) {
    var page = {
      pathname: "/AdminAnnouncmentpop",
      state: {
        Id: Id,
      },
    };
    this.props.history.push(page);
  }
  DeleteButton = (createdDate) => {
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        // body: data
      };
      fetch(
        `${BASE_URLs}/Announcement/SADeletePreviewannounce?CreatedDate=` + createdDate,
        options
      )
        .then((res) => res.json())
        .then((resp) => {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.GetAnnouncement();
        });
    }
  };

  componentDidMount() {
    this.GetAnnouncement();

    setTimeout(() => {
      this.setState({
        delay:true
      })
    }, 500);
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  handleContentClick(id) {
    const clickedContents = { ...this.state.clickedContents };
    clickedContents[id] = true;
    localStorage.setItem("clickedContents", JSON.stringify(clickedContents));
    this.setState({ clickedContents });
  }
  bothonclick(id) {
    this.handleContentClick(id);
    this.AnnouncementView(id);
  } truncateTitle(title, maxLength) {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  }

  filterfunction = (e) => {
    const Announcement = this.state.AllAnnouncement.filter(
      (item) =>
        item.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.role.toLowerCase().includes(e.target.value.toLowerCase()) 
    );
    this.setState({ Announcement, showResults: true ,Announcementfilter: Announcement });
  }

  
  handleFilter = (letter, event) => {
    event.preventDefault();
    const Announcement = this.state.AllAnnouncement.filter((item) => {
    return (
      item.title.charAt(0).toLowerCase() === letter.toLowerCase() ||
      item.role.charAt(0).toLowerCase() === letter.toLowerCase() 
    );
  });
    this.setState({ Announcement, showResults: true, FilterData: Announcement });
  };
  getFileType(path) {
    const extension = path.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'avi', 'mov', 'wmv', 'mkv'].includes(extension)) {
      return 'video';
    } else if (['pdf', 'xls', 'xlsx'].includes(extension)) {
      return 'document';
  } else {
      return 'file';
  }
  }
  
  handleImageClick = (url) => {
    window.open(url, '_blank');
     };

  render() {
    const { Announcement,isOpen,SaAnnouncement,delay,Filterpop } = this.state;
    return (
      <div>

<ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.pathname} />
        {this.state.EditModel ? (
          <SPEditAnnouncement
            values={this.state.EditBusinessValues}
            close={() => this.EditModel()}
          />
        ) : null}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body"style={{width:'97.9%',marginLeft:'12px'}}>
              <div className="page-wrapper">
              <div className="page-header">
                <div className="page-header-title"></div>
              </div>
                <div className="page-body">
                  <div className="">
                    <div className="">
                      <div className="card">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          </div>
                          <h4 className="title" style={{ marginRight: "50px" }}>
                            Announcements
                          </h4>
                        </div>
                        { isOpen?
                        <div className="col-lg-12">
                        <div className="popup" style={{textAlign:'left'}}>
                                <div className="popup-content">
                         <div className="Announcedetailspop" >
                         <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  style={{
                                    color: "gray",
                                    marginLeft: "90%",
                                    fontSize: "17px",
                                    cursor:'pointer'
                                  }}
                                  onClick={this.popupclose}
                                ></i>
                                <br />
                                <center><h6><b>Announcement Details</b></h6></center>
 <div className="card-block">

 {SaAnnouncement &&
                                      SaAnnouncement.map &&
                                      SaAnnouncement.map((Announcement) => (
                                        <>
                                          {Announcement.image === "-" &&
                                            Announcement.video === "-" ? (
                                            <div >
                                              <div
                                              >
                                                <span
                                                  key={Announcement.id}
                                                  style={{ margin: "0%" }}
                                                >

                                                  <b>Subject:</b> &nbsp; <span
                                                    style={{ overflowWrap: "break-word", width: "50%", }}
                                                  > {Announcement.title}</span><br /><br />
                                                  <b>Description:</b> &nbsp; <span
                                                    style={{ overflowWrap: "break-word", width: "50%", }}> {Announcement.message}</span>

                                                </span>
                                              </div>
                                            </div>
                                          ) : (
                                            
                                                    <>
                                                    {Announcement.image.split(',').map((imageUrl, index) => (
    <div  style={{display:"inline-block", marign:"10px" }} key={index}>
        {index === 0 && (
          <>
           <span style={{ margin: "38px" }}>
      <b>Subject:</b> &nbsp;
      <span style={{ overflowWrap: "break-word", width: "50%" }}>
        {Announcement.title}
      </span>
    </span>
    <br /><br />
    <span style={{ margin: "38px" }}>
      <b>Description:</b> &nbsp;
      <span style={{ overflowWrap: "break-word", width: "50%" }}>
        {Announcement.message}
      </span>
    </span>
    <br /><br />
                </> 
        )}


         <div style={{margin:"10px"}}>
        {this.getFileType(imageUrl) === 'image' ? (
            
            <span style={{margin:"30px"}}>
            <b>Image:&nbsp;</b>

{imageUrl.split(',').map((Url, index) => (
<img
key={index}
alt={`Image ${index}`}
src={imageUrl.trim()} 
style={{ width: "100px", height: "100px" }}
onClick={() => this.handleImageClick(Url.trim())}
/>
))}
            </span>
        ) : this.getFileType(imageUrl) === 'video' ? (
            <span style={{display:"flex",margin:"30px"}}>
               <span><b>video:</b></span>
                <video
                    controls
                    style={{ width: "125px", height: '118px', marginTop: '-61px' }}
                >
                    <source src={imageUrl} type="video/mp4" />
                    <source src={imageUrl} type="video/ogg" />
                </video>
            </span>
        ) : (
            <span style={{margin:"30px"}}>
              <b>File:</b> 
                <img
                    src={fileImg}
                    style={{ height: "30px", width: "30px" }}
                />
                &nbsp; 
                <a
                    href={imageUrl}
                    name="picture"
                    readOnly
                    value={imageUrl}
                    title=""
                    target="_blank"
                >
                    <span>
                        {imageUrl.slice("https://localhost:44369/Images/Announcement_Image_".length)}
                    </span>
                </a>
            </span>
           
        )}
        </div>
    </div>
))}
                                                  </>
                                                  )}
                                                </>
                                              ))}
                          <div>
                           
                          </div>
                        </div>
                         </div> </div> </div></div> :null}
                         <div className="card-block">
                        <div
                          className="row"
                          // style={{
                          //   display: "flex",
                          //   justifyContent: "flex-start",
                          //   alignItems: "baseline",
                          //   float: "left",
                          //   marginLeft: "30px",
                          //   // marginBottom: "3%",
                          // }}
                        >
                          
                          <Link
                            to="/Announcement"
                            style={{ width: "inherit", background: "white" }}
                            // id="addRow"
                            className="btn"
                          >
                            {" "}
                            <i className="fa-solid fa-plus"></i>Create New Announcements
                          </Link>
                         
                          <div
                            className="col-lg-3">
                        </div>
                        <div
                            className="col-lg-3">
                        </div>
                        <div
                            className="col-lg-1">
                        </div>

                          <div >
                            <input
                              type="text"
                              className="Searchbox"
                              id="CNSearch"
                              placeholder="Search"
                              style={{float:"right"}}
                              // onChange={this.filterfunction}
                         
                            // onKeyPress={this.handleKeyPress}
                            onClick={() => {
                            this.setState({
                            Filterpop: true,
                            Filterpop: !this.state.Filterpop,
                            });
                            }}
                            readOnly
                            />
                          </div>
                        </div>
                       
                       
                       

                           <div className="dt-responsive table-responsive"
                            id={Announcement.length <= 5 ? "UserNoAnnLength" : "Above10"}
                           >
                            { Announcement.length === 0 && delay ? 
                                <center>
                             
                            <p>No Record</p>
                          </center> 
                                          : null}
                           { Announcement.length !== 0 && delay ?    <table
                                id="add-row-table3"
                                className="table nowrap "
                                style={Filterpop?{ marginTop: "48px" }:{marginTop:'20px'}}
                              >
                               <thead id='tablepadding'><tr>
                              
                                <th>Announcement Details</th>
                                <th>Entities</th>
                                <th><span style={{ marginLeft: "12px" }}>Actions</span></th></tr>
                              </thead>
                              
                              <tbody id='tablepadding'>
                          
                          <>
                              {Announcement.map((Announcement) => (
                              <>
                              <tr>
                             
                                          <td> 
                                          <span>Subject:&nbsp;</span>
                                          <a href="#" onClick={(event) => {
                                     event.preventDefault(); 
                              this.GetParticulerAnnouncement(Announcement.id) 
                                  }}>{this.truncateTitle(Announcement.title, 85)}</a>
                                   &nbsp;<span
                                              style={{ cursor: 'pointer',fontSize:'12px' }}
                                              onClick={() =>

                                                this.GetParticulerAnnouncement(Announcement.id) 
                                              }>See More</span>
                                    &nbsp;&nbsp;&nbsp; <ReactTimeAgo
                                          date={Announcement.createdDate}
                                          locale="en-US"
                                              style={{ color: "gray", fontSize: "12px" }}
                                            />
                                  
                                        {/* <Link
                                        onClick={() =>
                                          this.bothonclick(Announcement.id)
                                        }
                                        style={{
                                          color: this.state.clickedContents[
                                            Announcement.id
                                          ]
                                            ? "gray"
                                            : "black",
                                          cursor: "pointer",
                                        }}
                                      >
                                         <b>{this.truncateTitle(Announcement.title, 85)}</b>
                                        &nbsp;&nbsp;&nbsp;
                                        <ReactTimeAgo
                                          date={Announcement.createdDate}
                                          locale="en-US"
                                          style={{ color: "gray" }}
                                        />
                                      
                                      </Link> */}
                                      </td> 
                                      
                                      <td>{Announcement.role}</td>
                                       <td>
                                      {/* <button
                                                  className="button-30"
                                                  type="button"
                                                  style={{
                                                    color: "rgb(94, 217, 135)",
                                                  }}
                                                  onClick={() =>
                                          // this.bothonclick(Announcement.id)
                                        
                                          this.GetParticulerAnnouncement(Announcement.id)
                                        }
                                                  title="Details"
                                                >
                                               
                                                  <i className="fa fa-info-circle"></i>
                                                </button>
                                     
                                      &nbsp;&nbsp;&nbsp;{" "} */}
                                      <button
                                        className="Annbuttons sk-del"
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.DeleteButton(Announcement.createdDate);
                                        }}
                                      >
                                        <i
                                          className="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </button> &nbsp;&nbsp;
                                      <button
                                        className="Annbuttons sk-edit"
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.EditModel(
                                            Announcement.id,
                                            Announcement.title,
                                            Announcement.message,
                                            Announcement.role,
                                            Announcement.image,
                                            Announcement.createdDate
                                          );
                                        }}
                                      >
                                        <i
                                          className="fa fa-edit"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </button>
                                     
                                     
                                        </td>
                                     
                                        </tr>
                              </>
                            ))}
                            </>
                          </tbody>
                          </table>:null}
                        </div>
                       
                        </div>
                        {/* <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <button
                              href
                              onClick={() => this.props.history.goBack()}
                              type="button"
                              style={{
                                width: "130px",
                                backgroundColor: "#7f63efde",
                                color: "white",
                              }}
                              className="dropdown_item-2"
                              className="btn "
                            >
                              Back
                            </button>{" "}
                          </div>
                        </div> */}
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {Filterpop && (
                              <div className="popSearchTeam">
                                <div className="popSearchinnernormalTeam">
                                  <div
                                    className="popSearchinnertitle"
                                    id="popSearchinnernormal"
                                  >
                                    <button
                                      id="close"
                                      style={{
                                        float: "right",
                                        backgroundColor: "#a0a3a305",
                                        color: "#797070",
                                        border: "1px solid #a3a5a505",
                                        marginTop: "-20px",
                                        marginRight: "-20px",
                                      }}
                                      onClick={() => {
                                        this.setState({ Filterpop: false });
                                        this.GetAnnouncement();
                                      }}
                                    >
                                      <CloseIcon />
                                    </button>

                                    <div className="checkboxes">
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "20px",
                                          color: "gray",
                                          marginLeft: "-1%",
                                          position: "absolute",
                                          marginTop: "0.5%",
                                        }}
                                      ></i>
                                      <input
                                        className="Searchbox"
                                        placeholder="Search"
                                        onChange={this.filterfunction}
                                        style={{ float: "right", width: "80%" }}
                                      />
                                      <br></br>
                                      {this.state.letters.map(
                                        (letter, index) => (
                                          <button
                                            className="filter"
                                            key={index}
                                            onClick={(event) =>
                                              this.handleFilter(
                                                letter,
                                                event
                                              )
                                            }
                                          >
                                            {letter}
                                          </button>
                                        )
                                      )}
                                      <br></br>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
