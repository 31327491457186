import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class AddDesignationModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Business: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };
  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var DesignationName = document.getElementsByName("DesignationName");
    var OtherDesignationName = document.getElementById("designationtext").value;
    if (!DesignationName[0].value) {
      formIsValid = false;
      formErrors["DesignationName"] = "Designation Name  is required";
    } else if (DesignationName[0].value === "Others") {
      if (!OtherDesignationName) {
        formIsValid = false;
        formErrors["OtherDesignationName"] = "Designation is required";
      }
    }

    this.setState({ formErrors: formErrors });
    //document.getElementById("add-row").style.display = "none";
    return formIsValid;
  }

  GetDesignation() {
    let url = `${BASE_URLs}/Designation/GetMasterDesignation`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Business: data,
        });
      });
  }
  componentDidMount() {
    this.GetDesignation();
  }
  Handledesignation() {
    var department = document.getElementsByName("DesignationName");
    if (department[0].value === "Others") {
      document.getElementById("designationtext").value = "";
      document.getElementById("designationtext").type = "text";
    } else {
      document.getElementById("designationtext").value = "None";
      document.getElementById("designationtext").type = "hidden";
    }
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.Validation()) {
      var DesignationName = document.getElementById("DesignationName").value;
      const data = new FormData();
      data.append("DesignationName", DesignationName);
      var role = JSON.parse(localStorage.Role);
      const url =
        role.toString() === "Platform Admin"
          ? `${BASE_URLs}/SuperAdmin/SADesignation`
          : `${BASE_URLs}/Designation/AddDesignation`;

      fetch(url, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("Addpop").style.display = "none";
            setTimeout(function () {
              if (document.getElementById("close")) {
                document.getElementById("close").click();
              }
            }, 4000);
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("Addpop").style.display = "none";
            setTimeout(function () {
              if (document.getElementById("close")) {
                document.getElementById("close").click();
              }
            }, 4000);
          }
        });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  render() {
    var w = window.innerWidth;
    const { DesignationName, OtherDesignationName } = this.state.formErrors;
    const { Business } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="Addpop"
          >
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <button
                id="close"
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={this.props.close}
              >
                <CloseIcon />
              </button>

              <div className="row" style={{ padding: "8px" }}>
                <div className="col-sm-3"></div>

                <div className="col-sm-8">
                  {/* <Select
       closeMenuOnSelect={false}
      onInputChange={this.Handledesignation} 
      id="DesignationName"
      name="DesignationName"
      isMulti
      options={Business.map(e => ({ label: e.designation, value: e.designation}))}   //Department.map(e => ({ label: e.department, value: e.department}))
      //options={  ({ label: "Others", value:"Others"}) }   //Department.map(e => ({ label: e.department, value: e.department}))
    /> */}
                  <input
                    type="text"
                    maxLength="50"
                    id="DesignationName"
                    className="form"
                    name="DesignationName"
                    onKeyPress={this.handleKeyPress}
                    onKeyDown={this.handleKeyDown}
                  />
                  <input
                    type="hidden"
                    Name="otherdesignation"
                    className="form"
                    id="designationtext"
                  />
                  {DesignationName == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Add Designation{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {DesignationName}
                    </div>
                  )}
                  {OtherDesignationName == null ? null : (
                    <div style={{ color: "red", float: "left" }}>
                      {OtherDesignationName}
                    </div>
                  )}
                  <span className="underline" />
                </div>
                <div className="col-sm-4"></div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>

                <br />
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
