import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useDropzone } from "react-dropzone";
import "../Css/Chat.css";
import Picker from "emoji-picker-react";
import { BASE_URLs } from "../Config/Base";
const thumb = {
  display: "inline-flex",
  borderRadius: 10,
  border: "6px dotted #eaeaea",
  marginBottom: 20,
  marginTop: 80,
  marginRight: 4,
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  height: 200,
  padding: 4,
  boxSizing: "border-box",
};

const DragDrop = (props) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [showEmoji, setShowEmoji] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleEmoji = () => {
    setShowEmoji((prevShowEmoji) => !prevShowEmoji);
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    setShowEmoji(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      if (!message.toString().trim() && files.length === 0) {
        alert("Please enter a message, select an image, or choose a document.");
      } else {
        const ids = files.map(file => file.path);
        const filteredResponse = files.filter(
          ({ path }, index) => !ids.includes(path, index + 1)
        );
    
        filteredResponse.forEach((file) => {
          const formData = new FormData();
          setInputDisabled(true)
          formData.append("Message", message);
          formData.append("Group_Name", props.values.GroupName);
          formData.append("Group_id", props.values.GroupId);
          formData.append("GroupMember", props.AllMembers);
          formData.append("Document_Path", file);
          formData.append("Profile", props.values.Profile);
    
          const options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
              Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            },
          };
    
          axios.post(`${BASE_URLs}/contact/GroupChatMessage`, formData, options)
            .then((res) => {
              setFiles([]);
              setMessage("");
              setInputDisabled(false)
              props.handleClose();
            })
            .catch((error) => {
              // console.error("There was an error sending the message!", error);
            });
        });
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (!message.toString().trim() && files.length === 0) {
      alert("Please enter a message, select an image, or choose a document.");
    } else {
      const ids = files.map(file => file.path);
      const filteredResponse = files.filter(
        ({ path }, index) => !ids.includes(path, index + 1)
      );
  
      filteredResponse.forEach((file) => {
        const formData = new FormData();
        setInputDisabled(true)
        formData.append("Message", message);
        formData.append("Group_Name", props.values.GroupName);
        formData.append("Group_id", props.values.GroupId);
        formData.append("GroupMember", props.AllMembers);
        formData.append("Document_Path", file);
        formData.append("Profile", props.values.Profile);
  
        const options = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        };
  
        axios.post(`${BASE_URLs}/contact/GroupChatMessage`, formData, options)
          .then((res) => {
            setFiles([]);
            setMessage("");
            setInputDisabled(false)
            props.handleClose();
          })
          .catch((error) => {
            // console.error("There was an error sending the message!", error);
          });
      });
    }
  };;

  const handleTextChange = (event) => {
    setMessage(event.target.value);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
  });

  const handleFileDelete = (index) => {
    const ids =files.map(file => file.path);
  const filteredResponse = files.filter(
  ({ path}, index) => !ids.includes(path, index + 1)
  )
  const updatedFiles = [...filteredResponse];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const uniqueFileList = [...new Set(files.map(file => file.path))];
  const fileList = uniqueFileList.map((path, index) => {

    return (
      <li key={path}>
        <br />
        {path} {index}&nbsp;&nbsp;
        <button className="GTbuttons1" onClick={() => handleFileDelete(index)}>
          x
        </button>
      </li>
    );
  });

  return (
    <div className="container">
      <form id="contactForm" autoComplete="off" encType="multipart/form-data">
        <div {...getRootProps({ className: "dropzone" })}>
          <span
            className="GTclose-icon"
            onClick={props.handleClose}
            style={{ marginTop: "20px" }}
          >
            x
          </span>

          <input {...getInputProps()} />
          <h4 style={thumb} onClick={open}>
            Drag & drop some files here....
          </h4>
        </div>
        <aside>
          <h5>File Name:</h5>
          <div
            id="fileList"
            // style={{ height: "200px", overflow: "scroll" }}
          >
            <ul>{fileList}</ul>
          </div>
        </aside>
        <div id="dropInput">
          <div
            className="input-group write-msg"
            id="input-group write-msg"
            style={{
              border: "1px solid #bfbfbe",
              padding: "2px 2px",
              backgroundColor:"white",
            }}
            onKeyPress={handleKeyPress}
          >
            <input
              type="text"
              className="form-control input-value"
              placeholder="Type a Message"
              value={message}
              onChange={handleTextChange}
              name="Message"
              style={{ border: "none" }}
              disabled={inputDisabled}
            />
            <span
              className="fa fa-smile-o"
              aria-hidden="true"
              style={{ marginTop: "10px", fontSize: "18px", backgroundColor:"white" }}
              onClick={toggleEmoji}
            ></span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {showEmoji && (
              <div
                style={{
                  position: "absolute",
                  // top: "-360px",
                  // marginLeft: "650px",
                }}
                id="DragpopEmoji"
              >
                {windowWidth < 400 ? (
        <Picker
          onEmojiClick={handleEmojiClick}
          height={297}
          width={260} // Adjusted width for windowWidth < 300
        />
      ) : windowWidth < 750 ? (
        <Picker
          onEmojiClick={handleEmojiClick}
          height={297}
          width={300} // Adjusted width for windowWidth < 750
        />
      ) : (
        <Picker
          onEmojiClick={handleEmojiClick}
          height={375}
          width={315}
        />
      )}
              </div>
            )}
            <span className="input-group-btn">
              <button
                id="paper-btn"
                className="btn btn-secondary"
                type="submit"
                onClick={handleSubmit}
                disabled={inputDisabled}
              >
                <i className="icofont icofont-paper-plane"></i>
              </button>
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};
export default DragDrop;
